import { Document, Link, Page, StyleSheet, Text, pdf } from '@react-pdf/renderer'
import { Button, DatePicker, Modal, Radio, message } from 'antd'
import { registerEnglishFont } from 'components/fonts'
import CompanyDetails from 'components/ledger/company'
import DocumentTitle from 'components/ledger/documentTitle'
import LedgerItems from 'components/ledger/ledgerItems'
import Party from 'components/ledger/party'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { getAPI } from 'services/jwt'
const { RangePicker } = DatePicker
dayjs.extend(utc)
const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    paddingTop: 25,
    paddingHorizontal: 30,
    paddingBottom: 40,
    lineHeight: 1.3,
    flexDirection: 'column',
  },
  logo: {
    width: 'auto',
    height: '45',
    position: 'relative',
    marginRight: 'auto',
    textAlign: 'left',
    marginBottom: 5,
  },
  footer: {
    alignContent: 'bottom',
    position: 'absolute',
    fontFamily: 'helmed_English',
    bottom: 20,
    left: 30,
  },
  footerNote: {
    fontSize: 8.1,
    bottom: 10,
    left: 30,
    alignContent: 'bottom',
    position: 'absolute',
    fontFamily: 'helmed_English',
    marginTop: 0,
  },
})
export default class ExportPDF extends Component {
  dateFormat = 'DD-MM-YYYY'
  constructor(props) {
    super(props)
    this.state = {
      flag: false,
      value: 'All Time',
      date:
        dayjs()
          .subtract(10, 'year')
          .month(0)
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .add(10, 'year')
          .month(0)
          .startOf('month')
          .format(this.dateFormat),
      loading: false,
      pdfFlag: false,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
    registerEnglishFont('English')
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  handleDirectDownload(start_date, end_date) {
    this.setState(
      {
        date: start_date + ' - ' + end_date,
      },
      () => {
        this.handleDownload()
      },
    )
  }
  showModal = () => {
    this.setState({ flag: true })
  }

  onModalChange = e => {
    var date = ''

    if (e.target.value == 'All Time') {
      var date =
        dayjs()
          .subtract(10, 'year')
          .month(0)
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .add(10, 'year')
          .month(0)
          .startOf('month')
          .format(this.dateFormat)
    }

    if (e.target.value == 'Today') {
      var date = dayjs().format(this.dateFormat) + ' - ' + dayjs().format(this.dateFormat)
    }

    if (e.target.value == 'Yesterday') {
      var date =
        dayjs()
          .subtract(1, 'days')
          .startOf('day')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .subtract(1, 'days')
          .startOf('day')
          .format(this.dateFormat)
    }

    if (e.target.value == 'Previous Month') {
      var date =
        dayjs()
          .subtract(1, 'months')
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .subtract(1, 'months')
          .endOf('month')
          .format(this.dateFormat)
    }

    if (e.target.value == 'Current Month') {
      var date =
        dayjs()
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .endOf('month')
          .format(this.dateFormat)
    }

    this.setState({
      value: e.target.value,
      date: date,
    })
  }

  onRangeChange = (date, datesString) => {
    var s = datesString[0].split('-')
    var e = datesString[1].split('-')
    var start_date = s[2] + '-' + s[1] + '-' + s[0]
    var end_date = e[2] + '-' + e[1] + '-' + e[0]

    if (datesString[0] != '') {
      this.setState({ date: start_date + ' - ' + end_date })
    } else {
      this.setState({ date: '' })
    }
  }

  handleDownload = async () => {
    if (this.props.menuName != '') {
      this.setState({ loading: true })
      var req = {}
      var module_name = this.props.menuName

      if (this.props.menuName == 'customer/view_ledger') {
        var api_name = 'view_ledger'
        var menu_name = 'customer'
        req = {
          id: this.props.id,
          num_records: 200,
          page: 0,
          show_pending_docs: this.props.show_pending_docs || false,
        }
      }

      if (this.props.menuName == 'customer/ledger') {
        var api_name = 'ledger'
        var menu_name = 'customer'
        req = {
          id: this.props.id,
          num_records: 200,
          page: 0,
          show_pending_docs: this.props.show_pending_docs || false,
        }
      }

      if (this.props.menuName == 'vendor/view_ledger') {
        var api_name = 'view_ledger'
        var menu_name = 'vendor'
        req = {
          id: this.props.id,
          num_records: 200,
          page: 0,
          show_pending_docs: this.props.show_pending_docs || false,
        }
      }

      if (this.props.menuName == 'vendor/ledger') {
        var api_name = 'ledger'
        var menu_name = 'vendor'
        req = {
          id: this.props.id,
          num_records: 200,
          page: 0,
          show_pending_docs: this.props.show_pending_docs || false,
        }
      }

      if (this.state.date == '') {
        message.error('Please select Date')
      } else {
        const data = await getAPI(menu_name, api_name, { date: this.state.date, ...req })
        if (data) {
          this.setState({ flag: false, loading: false }, () => {
            if (this.state.value == 'All Time') {
              //remove date range from data
              data.start_date = ''
              data.end_date = ''
            }
            if (isMobile) {
              this.directDownloadPDF(data)
            } else {
              this.props.onAPICalled(data)
            }
          })
        }
      }

      this.setState({ loading: false })
    }
  }
  directDownloadPDF = async data => {
    const pdfBlob = await pdf(
      <Document>
        <Page size="A4" style={styles.page}>
          <DocumentTitle title={'Ledger'} color={data.company_details.color} />
          <CompanyDetails invoice={data} />
          <Party invoice={data} />
          <LedgerItems invoice={data} />
          {/* <Link
            href={data.company_details.website}
            target="_blank"
            fixed
            style={{ ...styles.footer, color: data.company_details.color }}
          >
            {data.company_details.invoice_footer}
          </Link> */}
          <Text fixed style={styles.footerNote}>
            This is a computer generated document and requires no signature.
          </Text>
        </Page>
      </Document>,
    ).toBlob()
    const url = window.URL.createObjectURL(pdfBlob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${data.company_details.company_name}_ledger`)
    document.body.appendChild(link)
    link.click()
  }
  render() {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    }
    return (
      <Modal
        title="Export Data"
        open={this.state.flag}
        onCancel={() => this.setState({ flag: false })}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        footer={[
          <Button key="back" onClick={() => this.setState({ flag: false })}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={this.handleDownload}
            loading={this.state.loading}
          >
            {isMobile ? 'Download' : 'View'}
          </Button>,
        ]}
      >
        <div className="col-12">
          <h5 className="font-weight-bold">Data Range</h5>
          <Radio.Group onChange={this.onModalChange} value={this.state.value}>
            <Radio style={radioStyle} value={'All Time'}>
              All Time
            </Radio>
            <Radio style={radioStyle} value={'Today'}>
              Today
            </Radio>
            <Radio style={radioStyle} value={'Yesterday'}>
              Yesterday
            </Radio>
            <Radio style={radioStyle} value={'Current Month'}>
              Current Month
            </Radio>
            <Radio style={radioStyle} value={'Previous Month'}>
              Previous Month
            </Radio>
            <Radio style={radioStyle} value={'custom'}>
              Custom
            </Radio>
            {this.state.value == 'custom' && (
              <RangePicker
                style={{
                  left: '25px',
                  borderRadius: '5px',
                  marginTop: '0.45rem',
                  cursor: 'pointer',
                }}
                onChange={this.onRangeChange}
              />
            )}
          </Radio.Group>
        </div>
      </Modal>
    )
  }
}

export class EnhancedExportPDF extends React.Component {
  render() {
    return <ExportPDF {...this.props} />
  }
}
