import { Button, Dropdown, Popover, Select, Tabs, Tag, Tooltip } from 'antd'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import '@ag-grid-community/styles/ag-grid.css' // Mandatory CSS required by the grid
import '@ag-grid-community/styles/ag-theme-quartz.css' // Optional Theme applied to the grid

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'

import store from 'store'
import { AgGridReact } from '@ag-grid-community/react'

const TabPane = { Tabs }

class ReportsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  async componentDidMount() {}

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return
    }
  }

  render() {
    let gridColumns = this.props.columns.map((column, index) => {
      column = {
        ...column,
        field: column.field || column.dataIndex,
        headerName: column.title,
      }

      return column
    })

    return (
      <>
        <div>
          {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
          {/* AG-Grid */}

          <div style={{ width: '100%', height: '100%' }}>
            <div style={{ width: '100%', height: '60vh' }} className={'ag-theme-quartz'}>
              <AgGridReact
                rowData={this.props.data}
                rowStyle={{
                  alignItems: 'center',
                }}
                columnDefs={gridColumns}
                reactiveCustomComponents={true}
                defaultColDef={{
                  flex: 1,
                  minWidth: 250,
                  autoHeight: true,
                  wrapText: false,
                  editable: true,
                }}
                pagination={true}
                paginationPageSize={12}
                paginationPageSizeSelector={[12, 20, 30, 40, 50, 100]}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ReportsTable
