import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  Space,
  Steps,
  Tabs,
  Tag,
  Typography,
  message,
} from 'antd'
import EnhancedEwayBill from 'pages/ewayBills/ewaybill'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { getAPI, getAPIData } from 'services/jwt'
import { trackEvent } from 'utils/netcore'

const { Text } = Typography

const { Step } = Steps

const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
    md: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
    md: { span: 16 },
  },
}

const steps = [
  {
    title: 'Add GSP Details',
  },
  {
    title: 'E-way Bill GSP Login',
  },
  {
    title: 'Done',
  },
]

export default class NICCreds extends Component {
  dateFormat = 'DD-MM-YYYY'
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loading: false,
      show_otp: false,
      txn: '',
      showEwayBill: false,
      new_hash_id: '',
      menuName: 'sales',
      document_type: 'invoice',
      current: 0,
      verified: false,
      is_backup: false,
      activeKey: '1',
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  verifyAccount = async () => {
    const data = await getAPIData('ewaybills', 'is_verified')
    if (data?.verified) {
      await new Promise(resolve => {
        this.setState({ verified: true }, resolve)
      })
    } else {
      // message.error(
      //   'Your account is not verified. Please connect to E-Way Bill Portal using top right button',
      // )
    }
  }

  showDrawer = (visible, new_hash_id, document_type) => {
    this.setState({ visible: visible, new_hash_id: new_hash_id, document_type: document_type })
    this.verifyAccount()
  }

  showDrawerLogin = (visible, is_backup = false) => {
    this.setState({ visible: visible, is_backup, activeKey: is_backup ? '2' : '1' }, async () => {
      await this.verifyAccount()
      trackEvent({
        eventName: 'page_view',
        eventAttributes: {
          page_name: 'ewaybill_nic_creds',
          is_account_verified: this.state.verified ? 1 : 0,
        },
      })
    })
  }

  next = () => {
    this.setState({ current: this.state.current + 1 })
  }

  prev = () => {
    this.setState({ current: this.state.current - 1 })
  }

  onFinish = async values => {
    var req = {
      ...values,
    }

    let module = this.state.is_backup ? 'ewaybills' : 'user'
    let apiName = this.state.is_backup ? 'nic_authenticate_mastersindia' : 'nic_authenticate'
    const data = await getAPI(module, apiName, req)

    if (data && data.success) {
      message.success(data.message)
      this.setState({ verified: true })
      if (this.state.new_hash_id != '') {
        this.setState({ visible: false })
        this.setState({ loading: false, showEwayBill: true }, () => {
          this.ewaybill.showModal(this.state.document_type, this.state.new_hash_id)
        })
      } else {
        this.next()
      }
    } else {
      this.setState({ loading: false })
    }
  }

  render() {
    const { current } = this.state

    const renderTabs = () => (
      <>
        <Card>
          <Steps current={this.state.current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Card>
        <Card className="mt-3">
          <div className="steps-content pl-2">
            {this.state.current == 0 && (
              <>
                <Space direction="vertical" className="mt-4 pl-2 font-size-16">
                  <Text className="font-size-18">
                    Follow below steps to register
                    <Text type="" className="font-size-18 font-weight-bold">
                      {' '}
                      {this.state.is_backup
                        ? 'Masters India Private Limited'
                        : 'Tera Software Limited'}
                    </Text>{' '}
                    as your GST Suvidha Provider(GSP) on <b>E-way Bill Portal</b>
                  </Text>
                  <br />
                  <Text type="" className="mt-4 pt-4 pb-0 font-weight-bold">
                    STEP - 1
                  </Text>

                  <hr className="mt-0 pt-0" />
                  <Text>
                    1. Login to the{' '}
                    <Link
                      to={{ pathname: 'https://docs.ewaybillgst.gov.in/' }}
                      target="_blank"
                      className="font-weight-bold"
                    >
                      E-way Bill Portal
                    </Link>
                  </Text>
                  <Text>
                    2. Click on <b>Registration</b> in the left menu and select <b>For GSP</b>
                  </Text>
                  <Text>3. Click Send OTP</Text>
                  <Text>
                    4. <Text className="font-weight-bold">Verify OTP</Text>
                  </Text>
                  <br />
                  <Text type="" className="mt-4 pt-4 pb-0 font-weight-bold">
                    STEP - 2
                  </Text>

                  <hr className="mt-0 pt-0" />
                  <Text>
                    1. Click the <b>Add/New Button</b>
                  </Text>
                  <Text>
                    2. Select{' '}
                    <Text type="" className="font-size-18 font-weight-bold">
                      {' '}
                      {this.state.is_backup
                        ? 'Masters India Private Limited'
                        : 'Tera Software Limited'}
                    </Text>{' '}
                    in the GSP Name dropdown
                  </Text>
                  <Text>
                    3. Enter <b>3 letter Suffix ID</b> and a password
                  </Text>
                  <Text>
                    4. Re-enter the User Name and Password and <b>Click ADD</b>
                  </Text>
                </Space>
                <Button type="action" size="large" onClick={() => this.next()} className="mt-4">
                  Proceed to E-way Bill GSP Login <i className="fa fa-arrow-right ml-2" />
                </Button>
              </>
            )}

            {this.state.current == 1 && (
              <div className="mt-4 font-size-18">
                <Tag
                  color="orange"
                  className="mb-4 font-size-18 font-weight-bold"
                  style={{ borderRadius: '5px' }}
                >
                  Enter GSP Username and Password from the E-way Bill Portal
                </Tag>

                <Form
                  {...formItemLayout}
                  labelAlign="left"
                  ref={this.formRef}
                  name="GSTCredsForm"
                  onFinish={this.onFinish}
                  size="large"
                >
                  <Form.Item
                    name="username"
                    label="GSP Username"
                    size="large"
                    rules={[{ required: true, message: 'Please fill Username' }]}
                  >
                    <Input placeholder="GSP Username" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="GSP Password"
                    size="large"
                    rules={[{ required: true, message: 'Please fill Password' }]}
                  >
                    <Input.Password placeholder="GSP Password" />
                  </Form.Item>

                  <Button
                    type="primary"
                    size="large"
                    className="float-right"
                    onClick={() => this.formRef.current.submit()}
                  >
                    Proceed to Generate E-way Bill <i className="fa fa-arrow-right ml-2" />
                  </Button>
                </Form>
              </div>
            )}

            {this.state.current == 2 && (
              <>
                <div className="flex flex-col justify-center items-center">
                  <div>
                    <span>
                      <i
                        className="fa fa-check-circle text-success"
                        style={{
                          fontSize: '5rem',
                        }}
                      />
                    </span>
                  </div>
                  <div className="mt-2">
                    <div>
                      <span className="font-size-22">
                        <span className="font-weight-bold text-gray-500 ">Congratulations!</span>{' '}
                        You have successfully connected to E-way Bill Portal.
                      </span>
                    </div>
                  </div>
                  {!this.state.is_backup && (
                    <div>
                      <Button
                        type="primary"
                        size="medium"
                        className="mt-4"
                        onClick={() =>
                          this.setState({ current: 0, activeKey: '2', is_backup: true })
                        }
                      >
                        Add Backup GSP Provider
                      </Button>
                    </div>
                  )}
                  {!this.state.is_backup && (
                    <div>
                      <span className="font-size-12 text-gray-400">
                        Backup GSP Provider is required in case of any issues with the E-way Bill
                        creation with the primary GSP Provider.
                      </span>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="steps-action mt-4">
            {current > 0 && current < 2 && (
              <Button size="medium" style={{ margin: '0 8px' }} onClick={() => this.prev()}>
                <i className="fa fa-arrow-left mr-2" /> Go Back
              </Button>
            )}
          </div>
          {this.state.showEwayBill && (
            <EnhancedEwayBill
              onRef={ref => (this.ewaybill = ref)}
              onCancel={() => {
                this.setState({ showEwayBill: false })
              }}
            />
          )}
        </Card>
      </>
    )

    return (
      <Drawer
        title="Connecting to E-way Bill Portal"
        width={isMobile ? '100%' : '55%'}
        height={isMobile ? '90%' : '100%'}
        placement={isMobile ? 'top' : 'right'}
        closable={true}
        closeIcon={<i className="fa-solid fa-lg fa-xmark" />}
        onClose={() => this.setState({ visible: false })}
        open={this.state.visible}
        footer={[
          <Button type="danger" onClick={() => this.setState({ visible: false })}>
            Close
          </Button>,
        ]}
      >
        <Tabs
          defaultActiveKey="1"
          activeKey={this.state.activeKey}
          onChange={key => {
            this.setState({ activeKey: key, is_backup: key == '2', current: 0 })
          }}
          destroyInactiveTabPane
        >
          <Tabs.TabPane tab="E-way Bill GSP Login" key="1">
            {renderTabs()}
          </Tabs.TabPane>
          {this.state.verified && (
            <Tabs.TabPane tab="Back-up GSP Login" key="2">
              {renderTabs()}
            </Tabs.TabPane>
          )}
        </Tabs>
      </Drawer>
    )
  }
}

export class EnhancedNICCreds extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <NICCreds {...this.props} />
  }
}
