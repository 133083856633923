import queryString from 'query-string'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { State } from '../redux/types'

const SoftwareSuggest: React.FC = () => {
  const location = useLocation()
  const isNewUser = useSelector((state: State) => state.user?.new_user)
  const defaultUrl = useSelector((state: State) => state.permissions.default_url)
  const url = queryString.parseUrl(defaultUrl ?? '')?.url

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const campaignName = params.get('_campaignname')

    if (campaignName === 'SS' && isNewUser && location.pathname === url) {
      const scriptContent = `
        var cmtrackScriptLoaded = false;
        var cmtoolsScriptLoaded = false;

        function ssCMtrackTrigger() {
            if (cmtrackScriptLoaded && cmtoolsScriptLoaded) {
                ssCMtrack();
            }
        }

        var sscmtrackingId = "WTJ4cFkydHRZV2RwWXkweE5UTXdORFk0";
        var cmScripturl = 'https://softwaresuggest-cdn.s3.ap-southeast-1.amazonaws.com/static-frontend/cm-js/cm.tracking.v.0.5.js';
        var cmtrackScript = document.createElement('script');
        cmtrackScript.src = cmScripturl;
        cmtrackScript.onload = function() {
            cmtrackScriptLoaded = true;
            ssCMtrackTrigger();
        }
        document.body.appendChild(cmtrackScript);

        var cmtools = { uid: '176983' };
        var cmtoolsScript = document.createElement('script');
        var cmtoolsScripturl = '//cdn.clickmagick.com/misc/js/cmtools.js';
        cmtoolsScript.src = cmtoolsScripturl;
        cmtoolsScript.onload = function() {
            cmtoolsScriptLoaded = true;
            ssCMtrackTrigger();
        }
        document.head.appendChild(cmtoolsScript);
      `
      const scriptTag = document.createElement('script')
      scriptTag.innerHTML = scriptContent
      if (!document.body.contains(scriptTag)) {
        document.body.appendChild(scriptTag)
      }

      return () => {
        document.body.removeChild(scriptTag)
      }
    }
  }, [window.location, isNewUser])

  return null
}

export default SoftwareSuggest
