import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  AutoComplete,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  message,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import PurchaseConfirm from 'components/modal/purchaseConfirm'
import { getPriceWithTax } from 'components/other/calculation'
import SectionHeader from 'components/other/sectionHeader'
import { formItemLayout } from 'components/other/utility'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import InventoryManager from 'managers/inventory'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { get_inventory_categories, get_warehouses } from 'redux/warehouses/actions'
import { getAPI } from 'services/jwt'

dayjs.extend(utc)

const { Panel } = Collapse

const emptyInventoryData = {
  product_id: '',
  product_name: '',
  product_qty: 0,
  unit_id: 0,
  is_bom: false,
}

class InventoryForm extends Component {
  formRef = React.createRef()
  purchaseConfirmRef = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  panelRef = React.createRef() // This ref is for the panel you want to scroll to
  dateTimeFormat = 'YYYY-MM-DD hh:mm a'
  constructor(props) {
    super(props)
    this.panelRef = React.createRef()
    this.state = {
      isFirstTime: false,
      addFlag: false,
      has_access: true,
      expiry_date: dayjs(),
      loading: false,
      visible: false,
      selectedData: { ...emptyInventoryData },
      record_time: dayjs().format(this.dateFormat),
      bom_products: [],
      is_bom: false,
      quantity: 0,
      selected_warehouse_id: -1,
      dataSource: [],
      is_purchase_confirm_shown: false,
      is_update_purchase_columns: false,
      products: [],
      autocompleteData: [],
      itemAutoCompleteData: [],
      product_search: '',
      selectedItem: { name: '', id: -1, variant_name: '' },
      item_search: '',
      document_type: 'manual',
      unit_id: undefined,
      initialValuesObject: {},
      selectedBatch: { id: -1 },
      selectedProductBatch: undefined,
    }

    this._onFinish = this._onFinish.bind(this)
    this.onFinish = _.throttle(this._onFinish, 2000)
  }

  handleCollapseChange = key => {
    const isPanelOpen = Array.isArray(key) ? key.includes('1') : key === '1'

    if (isPanelOpen) {
      // Set a timeout to allow for the panel content to render and expand
      setTimeout(() => {
        if (this.panelRef.current) {
          this.panelRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }, 300) // This delay needs to be greater than the duration of the expand animation
    }
  }

  getFieldValues() {
    if (this.formRef.current) {
      return this.formRef.current.getFieldsValue()
    }
  }

  componentDidMount() {
    this.props.onRef(this)
    if (this.props.warehouses == undefined) {
      this.props.dispatch(get_warehouses())
    }
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  _onFinish = async values => {
    if (values.category?.length > 0) {
      values.category = values.category[0]
    }

    if (
      this.props.document_settings.purchase_update_columns != '' &&
      !this.state.is_purchase_confirm_shown &&
      this.state.addFlag
    ) {
      this.showPurchaseConfirmModal()
      return
    }
    this.setState({ loading: true })

    // if (values.unit_id == undefined) {
    //   values.unit_id = 0
    // }
    if (
      values.purchase_price == null ||
      values.purchase_price == undefined ||
      isNaN(values.purchase_price)
    ) {
      values.purchase_price = this.state.selectedData.purchase_price
    }
    if (
      values.selling_price == null ||
      values.selling_price == undefined ||
      isNaN(values.selling_price)
    ) {
      values.selling_price = this.state.selectedData.price_with_tax
    }
    if (
      values.stock_value == null ||
      values.stock_value == undefined ||
      isNaN(values.stock_value)
    ) {
      values.stock_value = 0
    }
    let purchase_update_columns = {}
    if (this.state.addFlag) {
      if (this.props.document_settings.purchase_update_columns.includes('price_with_tax')) {
        purchase_update_columns.price_with_tax = values.selling_price
        delete values.selling_price
      }
      if (this.props.document_settings.purchase_update_columns.includes('purchase_price')) {
        purchase_update_columns.purchase_price = values.purchase_price
      }
      if (this.props.document_settings.purchase_update_columns.includes('price_with_tax')) {
        purchase_update_columns.discount = values.product_discount
        delete values.product_discount
      }
      if (this.props.document_settings.purchase_update_columns.includes('custom_columns')) {
        purchase_update_columns.custom_columns = {}
        this.props.custom_columns.map(item => {
          if (item.is_active == 1 && item.default_key == '') {
            if (item.field_type == 'date') {
              values[item.name] = values[item.name] ? values[item.name].format(this.dateFormat) : ''
            } else {
              values[item.name] = values[item.name] ? values[item.name] : ''
            }
            purchase_update_columns.custom_columns[item.name] = values[item.name]
            delete values[item.name]
          }
        })
      }
    }

    if (this.state.is_bom) {
      let bom_products = this.state.bom_products
      for (let i = 0; i < bom_products.length; i++) {
        if (bom_products[i].conversion_rate == undefined || bom_products[i].unit == undefined) {
          message.error('Please select unit for this sub product: ' + bom_products[i].product_name)
          this.setState({ loading: false })
          return
        }
      }
    }

    var reqData = {
      ...values,
      product_id: this.state.selectedData.product_id,
      variant_id: this.state.selectedData.variant_id,
      qty: values.quantity,
      expiry_date:
        values.expiry_date == undefined ? '' : dayjs(values.expiry_date).format(this.dateFormat),
      created_date: this.state.record_time,
      unit_id: values.unit_id,
      is_bom: this.state.is_bom,
      bom_products: this.state.is_bom ? this.state.bom_products : [],
      is_update_purchase_columns: this.state.is_update_purchase_columns,
      purchase_update_columns,
      unit_id: this.state.unit_id == undefined ? 0 : this.state.unit_id,
      identifier: this.props?.identifier,
    }
    var url = 'stock_in'
    if (!this.state.addFlag) {
      url = 'stock_out'
    }

    const data = await getAPI('inventory', url, reqData)

    if (data) {
      this.setState({
        visible: false,
        loading: false,
        quantity: 0,
        selectedData: {},
        selectedBatch: { id: -1 },
      })
      this.props.onAPICalled()
      if (this.props.onUpdateBatchModal) {
        this.props.onUpdateBatchModal(values.quantity, this.state.selectedProductBatch)
      }
      this.formRef.current?.resetFields()
      message.success(data.message)
    }
    this.setState({ loading: false })
  }

  onFill = (data, flag) => {
    const getCategories = async () => {
      if (
        this.props.inventory_categories == undefined ||
        this.props.inventory_categories?.length == 0
      ) {
        await this.props.dispatch(get_inventory_categories())
      }
    }

    getCategories()

    if (data?.warehouse_id == undefined) {
      const stock_in_warehouse_ids =
        this.props.stock_in_warehouses?.map(item => item.warehouse_id) ?? []
      const stock_out_warehouse_ids =
        this.props.stock_out_warehouses?.map(item => item.warehouse_id) ?? []
      let default_warehouse_id = undefined
      if (flag && stock_in_warehouse_ids.includes(this.props.default_warehouse_id)) {
        default_warehouse_id = this.props.default_warehouse_id
      }
      if (!flag && stock_out_warehouse_ids.includes(this.props.default_warehouse_id)) {
        default_warehouse_id = this.props.default_warehouse_id
      }
      data.warehouse_id = default_warehouse_id
      this.setState({ selected_warehouse_id: default_warehouse_id })
    } else {
      this.setState({ selected_warehouse_id: data?.warehouse_id })
    }
    data.master_data = {
      price_with_tax: data.price_with_tax,
      unit_price: data.unit_price,
      discount: data.discount,
      purchase_price: data.purchase_price,
      purchase_unit_price: data.purchase_unit_price,
    }
    data.selling_price = data.price_with_tax
    data.product_discount = data.discount
    let custom_columns = { ...data.custom_columns }
    this.props.custom_columns?.map((item, index) => {
      if (item.is_active == 1 && item.default_key == '') {
        if (item.field_type == 'date') {
          const dateFormatRegex = /^\d{2}-\d{2}-\d{4}/
          if (custom_columns[item.name] && !dateFormatRegex.test(custom_columns[item.name])) {
            custom_columns[item.name] = undefined
          } else {
            custom_columns[item.name] = custom_columns[item.name]
              ? dayjs(custom_columns[item.name], 'DD-MM-YYYY')
              : undefined
          }
        } else {
          data[item.name] = data[item.name] == '' ? undefined : data[item.name]
        }
      }
    })
    data = { ...data, ...custom_columns }
    if (data.document_type == undefined) {
      data.document_type = 'manual'
    }
    if (this.state.isFirstTime) {
      this.setState(
        {
          selectedData: { ...data },
          is_bom: data.has_bom && flag,
          addFlag: flag,
          visible: true,
          document_type: data.document_type,
          unit_id: data?.unit_id,
        },
        () => {
          var priceKey = this.state.addFlag ? 'purchase_price' : 'selling_price'
          var priceValue = this.state.selectedData?.product_name ? data[priceKey] ?? 0 : undefined
          var initialQty = this.state.selectedData?.product_name ? 0 : undefined

          this.setState({
            has_access:
              (this.props.warehouses?.length == 0 &&
                this.props.warehouse_permissions[-1][
                  this.state.addFlag ? 'stock_in' : 'stock_out'
                ] == 1) ||
              (this.props.warehouses?.length > 0 &&
                (this.state.addFlag
                  ? this.props.stock_in_warehouses?.length > 0
                  : this.props.stock_out_warehouses?.length > 0)),

            initialValuesObject: {
              category: undefined,
              quantity: initialQty,
              [priceKey]: priceValue,
              warehouse_id: this.state.selected_warehouse_id,
            },
          })
          this.formRef?.current?.setFieldsValue({
            ...data,
            quantity: 0,
            expiry_date: dayjs(),
            created_date: dayjs(),
            remarks:
              data.variant_id > 0
                ? flag
                  ? data.name + ' - Variant Stock In'
                  : data.name + ' - Variant Stock Out'
                : '',
            stock_value: 0,
          })
          if (data.has_bom) {
            this.getBOM()
          }
        },
      )
    } else {
      this.setState(
        {
          selectedData: { ...data },
          addFlag: flag,
          is_bom: data.has_bom && flag,
          isFirstTime: true,
          unit_id: data?.unit_id,
        },
        () => {
          var priceKey = this.state.addFlag ? 'purchase_price' : 'selling_price'
          var priceValue = this.state.selectedData?.product_name ? data[priceKey] ?? 0 : undefined
          var initialQty = this.state.selectedData?.product_name ? 0 : undefined

          this.setState({
            visible: true,
            has_access:
              (this.props.warehouses?.length == 0 &&
                this.props.warehouse_permissions[-1][
                  this.state.addFlag ? 'stock_in' : 'stock_out'
                ] == 1) ||
              (this.props.warehouses?.length > 0 &&
                (this.state.addFlag
                  ? this.props.stock_in_warehouses?.length > 0
                  : this.props.stock_out_warehouses?.length > 0)),
            initialValuesObject: {
              category: undefined,
              quantity: initialQty,
              [priceKey]: priceValue,
              warehouse_id: this.state.selected_warehouse_id,
            },
          })
          if (data.has_bom) {
            this.getBOM()
          }
        },
      )
    }
  }

  getBOM = async () => {
    const products = await InventoryManager.getBOM(
      this.state.selectedData.product_id,
      this.state.selectedData.variant_id,
      this.state.selected_warehouse_id,
    )
    this.setState({
      bom_products: products,
    })
  }
  showPurchaseConfirmModal = () => {
    if (this.formRef.current?.getFieldValue() != undefined) {
      let data = { ...this.formRef.current.getFieldValue() }
      data.price_with_tax = data.purchase_price
      this.props.custom_columns.map(item => {
        if (item.field_type == 'date' && data[item.name] != undefined) {
          data[item.name] = data[item.name] ? data[item.name].format(this.dateFormat) : ''
        }
      })
      // return [{ ...data }]
      data.master_data = { ...this.state.selectedData.master_data }
      this.setState({ dataSource: [data] }, () => {
        this.purchaseConfirmRef.current.showModal()
      })
    } else {
      return []
    }
  }

  onChange = (value, item, type) => {
    const index = this.state.bom_products.findIndex(product => product.id == item.id)
    if (index > -1) {
      let dataSource = [...this.state.bom_products]
      if (type == 'qty') {
        dataSource[index].qty = value
      } else if (type == 'unit_id') {
        let prev_conversion_rate = dataSource[index]['conversion_rate']
        dataSource[index]['unit_id'] = value
        if (dataSource[index]['unit_id'] == 0 || dataSource[index]['unit_id'] == null) {
          dataSource[index]['conversion_rate'] = 1
        } else {
          dataSource[index]['conversion_rate'] = dataSource[index]['units']
            ? dataSource[index]['units'].find(item => item.unit_id == value)?.conversion_rate
            : 1
        }
        dataSource[index]['price'] =
          (dataSource[index]['price'] * prev_conversion_rate) / dataSource[index]['conversion_rate']
        dataSource[index]['unit_price'] = dataSource[index]['price']
        dataSource[index]['price_with_tax'] = getPriceWithTax(dataSource[index])

        dataSource[index]['unit'] =
          dataSource[index]['unit'] == '' || dataSource[index]['unit'] == null
            ? ''
            : dataSource[index]['units']
            ? dataSource[index]['units'].filter(
                item => item.unit_id == dataSource[index]['unit_id'],
              )?.[0].alternative_unit
            : dataSource[index]['unit']
      }

      this.setState({
        bom_products: dataSource,
      })
    }
  }
  onPurchaseUpdateConfirm = keys => {
    this.setState(
      { is_update_purchase_columns: keys.length > 0, is_purchase_confirm_shown: true },
      () => {
        this.formRef.current.submit()
      },
    )
  }
  onItemSearch = async searchText => {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.itemSearchAPI(searchText)
    }, 100)
  }
  itemSearchAPI = async searchText => {
    this.setState({ selectedItem: '', item_search: searchText })
    if (searchText.length > 0) {
      const req = { query: searchText }
      const data = await getAPI('product', 'search', req)
      if (data) {
        const allData = data.products.map(value => {
          return {
            ...value,
            value: value.product_name + ' ' + (value.variant_name ? value.variant_name : ''),
            name: value.product_name + ' ' + (value.variant_name ? value.variant_name : ''),
            id: value.product_id,
            key: value.product_id + '_' + (value.variant_id ? String(value.variant_name) : '0'),
            variant_name: value.variant_name ? value.variant_name : '',
          }
        })
        this.setState({
          itemAutoCompleteData: [...allData],
          products: data.products,
        })
      }
    } else {
      this.setState(
        {
          selectedItem: { name: '', id: -1, variant_name: '' },
          itemAutoCompleteData: [],
          // page: 0,
        },
        // () => this.callData(),
      )
    }
  }
  onItemSelect = (data, option) => {
    this.setState(
      {
        selectedItem: option,
        itemAutoCompleteData: [],
        page: 0,
        item_search: option.name,
      },
      // ,() => this.callData(),
    )
    let flag = true
    if (!this.state.addFlag) {
      flag = false
    }
    this.onFill({ ...option }, flag)
  }

  onClose = () => {
    let currentData = this.formRef?.current?.getFieldsValue()

    if (currentData?.warehouse_id == undefined) {
      currentData.warehouse_id = this.state.initialValuesObject.warehouse_id
    }

    let isValuesChanged = false
    for (let key in this.state.initialValuesObject) {
      if (currentData[key] !== this.state.initialValuesObject[key]) {
        isValuesChanged = true
        break
      }
    }
    if (this.state.selectedData?.has_batches == 1 && this.state.selectedBatch?.id == -1) {
      isValuesChanged = false
    }

    if (isValuesChanged) {
      Modal.confirm({
        title: 'Do you want to exit?',
        content: 'You will lose your unsaved data.',
        icon: <ExclamationCircleOutlined />,
        okText: 'Confirm',
        onOk: () => {
          this.setState({
            visible: false,
            bom_products: [],
            is_bom: false,
            quantity: 0,
            selectedData: {},
            selectedBatch: { id: -1 },
          })
          this.formRef.current?.resetFields()
        },
        onCancel: () => {},
      })
    } else {
      this.setState({
        visible: false,
        bom_products: [],
        is_bom: false,
        quantity: 0,
        selectedData: {},
        selectedBatch: { id: -1 },
      })
      this.formRef.current?.resetFields()
    }
  }

  render() {
    const selectUnits = data => (
      <span className="select-borderless">
        <Select
          suffixIcon={<i className="fa-regular fa-chevron-down fa-lg"></i>}
          placeholder="Unit"
          optionFilterProp="children"
          style={{ maxWidth: '90px' }}
          dropdownStyle={{ minWidth: '300px' }}
          disabled={!this.state.is_bom}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={data.units?.filter(item => item.unit_id == data?.unit_id)?.[0]?.unit_id}
          value={data?.unit_id == 0 || data?.unit_id == null ? 0 : data?.unit_id}
          onChange={value => {
            this.onChange(value, data, 'unit_id')
          }}
          // disabled={}
        >
          {data.units?.map((e, i) => (
            <Option key={i} value={e.unit_id}>
              {e.alternative_unit}{' '}
              {data.unit != '' && e.primary_unit == undefined
                ? ` - (1 ${data.units?.[0].alternative_unit} = ${e.conversion_rate} ${e.alternative_unit})`
                : ''}
            </Option>
          ))}
        </Select>
      </span>
    )

    let bom_columns = [
      {
        title: 'Product Name',
        dataIndex: 'product_name',
        key: 'product_name',
        width: '20%',
        render: (text, record) => (
          <span>
            {record.product_name}{' '}
            {record.variant_name == '' || record.variant_name == null
              ? ''
              : ' ' + record.variant_name}
          </span>
        ),
      },
      {
        title: 'Current Qty',
        dataIndex: '',
        key: 'total_qty',
        width: '20%',
        render: (text, record) => <span>{record.total_qty}</span>,
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        width: '20%',
        render: (text, record) => (
          <InputNumber
            min={0}
            disabled={!this.state.is_bom}
            defaultValue={record.qty}
            value={record.qty}
            className="w-full"
            onChange={value => this.onChange(value, record, 'qty')}
            addonAfter={record.has_alternative_units == 0 ? record.unit : selectUnits(record)}
          />
        ),
      },
      {
        title: this.state.addFlag ? 'Total Stock Out' : 'Total Stock In',
        dataIndex: '',
        key: 'total_quantity',
        width: '20%',
        render: (text, record) => (
          <span>
            {record.qty * parseFloat(this.state.quantity)}

            {record.unit != '' && record.unit != null && record.unit != undefined
              ? ` ${record.unit}`
              : ''}
          </span>
        ),
      },
    ]

    const { permissions } = this.props
    return (
      <Drawer
        title={
          this.state.selectedData?.product_name == '' ||
          this.state.selectedData?.product_name == undefined
            ? this.state.addFlag
              ? 'Stock In'
              : 'Stock Out'
            : this.state.selectedData.product_name
        }
        width={isMobile ? '100%' : '50%'}
        height={isMobile ? '90%' : '100%'}
        placement={isMobile ? 'top' : 'right'}
        onClose={this.onClose}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        open={this.state.visible}
        footer={
          <Button
            disabled={!this.state.has_access}
            onClick={() => {
              this.formRef.current.submit()
            }}
            type={
              !this.state.has_access ? 'disabled' : this.state.addFlag ? 'success-3' : 'danger-3'
            }
            className="mt-2 font-weight-bold"
            loading={this.state.loading}
          >
            {this.state.addFlag ? (
              <span>
                <i className="fa-regular fa-circle-plus mr-1"></i> Add Quantity
              </span>
            ) : (
              <span>
                <i className="fa-regular fa-circle-minus mr-1"></i> Remove Quantity
              </span>
            )}
          </Button>
        }
        extra={
          <Button
            disabled={!this.state.has_access}
            onClick={() => {
              this.formRef.current.submit()
            }}
            type={
              !this.state.has_access ? 'disabled' : this.state.addFlag ? 'success-3' : 'danger-3'
            }
            className="font-weight-bold"
            loading={this.state.loading}
          >
            <span>
              {this.state.addFlag ? (
                <>
                  <i className="fa-solid fa-circle-plus mr-1"></i> Add quantity
                </>
              ) : (
                <>
                  <i className="fa-regular fa-circle-minus mr-1"></i> Remove Quantity
                </>
              )}
            </span>
          </Button>
        }
      >
        {this.state.has_access ? (
          <Form
            {...formItemLayout}
            labelAlign="left"
            ref={this.formRef}
            name="InventoryForm"
            onFinish={this.onFinish}
            initialValues={{
              ...this.state.selectedData,
              quantity: 0,
              expiry_date: dayjs(),
            }}
          >
            <>
              {this.props.warehouses != undefined && this.props.warehouses.length > 0 && (
                <>
                  {/*<Card className="mb-4">*/}
                  <Col span={24} className="mb-4">
                    <Form.Item
                      name="warehouse_id"
                      label="Select Warehouse"
                      rules={[{ required: true, message: 'Please Select Warehouse' }]}
                      hidden={
                        this.props.warehouses != undefined && this.props.warehouses.length == 0
                      }
                    >
                      <Select
                        placeholder="Select Warehouse"
                        suffixIcon={<i className="fa-regular fa-chevron-down fa-lg"></i>}
                      >
                        {this.props[
                          this.state.addFlag ? 'stock_in_warehouses' : 'stock_out_warehouses'
                        ]?.map((item, index) => {
                          return (
                            <Select.Option key={index} value={item.warehouse_id}>
                              {item.name}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/*</Card>*/}
                </>
              )}

              {this.state.selectedData?.has_batches == 1 && (
                <Col span={24} className="mb-4">
                  <Form.Item
                    name="batch_id"
                    label="Select Batch"
                    rules={[{ required: true, message: 'Please select Batch' }]}
                  >
                    <Select
                      suffixIcon={<i className="fa-regular fa-chevron-down fa-lg"></i>}
                      placeholder="Select Batch"
                      onChange={e => {
                        let batchData = this.state.selectedData?.batches?.find(
                          item => item.batch_id == e,
                        )
                        this.setState(
                          {
                            selectedProductBatch: e,
                            selectedData: {
                              ...this.state.selectedData,
                              master_data: {
                                ...this.state.selectedData.master_data,
                                purchase_price: batchData?.purchase_price,
                                purchase_unit_price: batchData?.purchase_unit_price,
                                price_with_tax: batchData?.price_with_tax,
                                unit_price: batchData?.unit_price,
                              },
                            },
                            selectedBatch: batchData,
                          },
                          () => {
                            this.formRef.current.setFieldsValue({
                              selling_price: batchData?.price_with_tax,
                              purchase_price: batchData?.purchase_price,
                            })
                          },
                        )
                      }}
                    >
                      {this.state?.selectedData?.batches?.map((item, index) => (
                        <Select.Option key={index} value={item.batch_id}>
                          {item.batch_no}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {this.state.selectedData?.product_name == '' ||
                (this.state.selectedData?.product_name == undefined && (
                  <Col span={24} className="mb-4">
                    <Form.Item
                      name="product_name"
                      label="Select product"
                      rules={[{ required: true, message: 'Please select the product first.' }]}
                    >
                      {/* <Input placeholder="" disabled /> */}
                      {/* <Select mode='single' placeholder="Select Product" onChange={this.changeProduct}>
                      {
                        this.state?.products?.map((item,index)=>(
                          <Option key={index} value={item.product_name}>
                          {item.product_name}
                        </Option>
                        ))
                      }
                    </Select> */}
                      <AutoComplete
                        options={this.state.itemAutoCompleteData}
                        className=""
                        style={{ borderRadius: '5px' }}
                        onSelect={this.onItemSelect}
                        onSearch={e => {
                          // clearTimeout(this.state.timeout)
                          // this.setState({
                          //   product_search: e,
                          //   timeout: setTimeout(() => this.onItemSearch(e), 1200),
                          // })
                          this.onItemSearch(e)
                        }}
                        placeholder={'Type to search product'}
                        disabled={permissions?.serial_number == 1}
                      />
                    </Form.Item>
                  </Col>
                ))}

              {this.state.selectedData?.product_name != '' &&
                this.state.selectedData?.product_name != undefined && (
                  <>
                    <SectionHeader
                      title="Quantity information"
                      link={!this.state.addFlag}
                      linkText="Clear Stock"
                      linkTo={e => {
                        var item_type = 'selectedData'
                        if (this.state.selectedData?.has_batches == 1) {
                          item_type = 'selectedBatch'
                        }
                        this.formRef.current.setFieldsValue({
                          quantity:
                            this.state[item_type].qty < 0
                              ? -this.state[item_type].qty
                              : this.state[item_type].qty,
                        })
                      }}
                      icon="fa-circle-minus"
                    />
                    <Card className="mb-4">
                      <Row gutter={18}>
                        {/*<Col span={24}>
                  <Form.Item name="product_name" label="Item Name">
                    <AutoComplete
                      options={this.state.itemAutoCompleteData}
                      className="mt-2"
                      style={{ borderRadius: '5px' }}
                      onSelect={this.onItemSelect}
                      onSearch={e => {
                        // clearTimeout(this.state.timeout)
                        // this.setState({
                        //   product_search: e,
                        //   timeout: setTimeout(() => this.onItemSearch(e), 1200),
                        // })
                        this.onItemSearch(e)
                      }}
                      placeholder={'Select Product'}
                      disabled={permissions?.serial_number == 1}
                    />
                  </Form.Item>
                </Col>*/}

                        <Col span={12}>
                          <Form.Item
                            name="quantity"
                            label="Quantity"
                            rules={[
                              { required: true, message: 'Please enter quantity' },
                              {
                                type: 'number',
                                min: 0.001,
                                message: 'Quantity must be greater than 0',
                              },
                            ]}
                            onChange={e => {
                              var purchase_price = this.formRef.current.getFieldValue(
                                'purchase_price',
                              )
                                ? parseFloat(this.formRef.current.getFieldValue('purchase_price'))
                                : 0

                              var qty = this.formRef.current.getFieldValue('quantity')
                                ? parseFloat(this.formRef.current.getFieldValue('quantity'))
                                : 0
                              var selling_price = this.formRef.current.getFieldValue(
                                'selling_price',
                              )
                                ? parseFloat(this.formRef.current.getFieldValue('selling_price'))
                                : 0

                              this.formRef.current.setFieldsValue({
                                stock_value: this.state.addFlag
                                  ? purchase_price * qty
                                  : selling_price * qty,
                              })
                              this.setState({
                                quantity: this.formRef.current.getFieldValue('quantity'),
                              })
                            }}
                          >
                            <InputNumber
                              placeholder=""
                              autoFocus
                              style={{ width: '100%' }}
                              addonAfter={
                                // !this.state.addFlag ? (
                                //   <Button
                                //     onClick={() => {
                                //       this.formRef.current.setFieldsValue({
                                //         quantity:
                                //           this.state.selectedData.qty < 0
                                //             ? -this.state.selectedData.qty
                                //             : this.state.selectedData.qty,
                                //       })
                                //     }}
                                //     type="link"
                                //     style={{ padding: 0, marginRight: '4px' }}
                                //   >
                                //     Clear Stock
                                //   </Button>
                                // ) : null

                                this.state.selectedData?.has_alternative_units == 0 ? (
                                  this.state.selectedData.unit
                                ) : (
                                  <Select
                                    suffixIcon={
                                      <i className="fa-regular fa-chevron-down fa-lg"></i>
                                    }
                                    placeholder="Unit"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                      0
                                    }
                                    value={
                                      this.state.unit_id == 0 || this.state.unit_id == undefined
                                        ? 0
                                        : this.state.unit_id
                                    }
                                    onChange={e => {
                                      this.setState({ unit_id: e })
                                    }}
                                  >
                                    {this.state.selectedData?.units?.map((item, index) => (
                                      <Select.Option key={index} value={item.unit_id}>
                                        {item.alternative_unit}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                )
                              }
                            />
                          </Form.Item>
                        </Col>

                        {/* <Col span={12}>
                  <Form.Item
                    name="unit_id"
                    label="Unit"
                    rules={[{ required: true, message: 'Please Select Unit' }]}
                  >
                    <Select
                      placeholder="Select Unit"
                      suffixIcon={<i className="fa-regular fa-chevron-down fa-lg"></i>}
                    >
                      {this.state?.selectedData?.units?.map((item, index) => (
                        <Select.Option key={index} value={item.unit_id}>
                          {item.alternative_unit}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}

                        <Col span={12}>
                          <Form.Item name="created_date" label="Record Time">
                            <DatePicker
                              format={this.dateFormat}
                              defaultValue={dayjs()}
                              use12Hours={true}
                              onChange={(date, dateString) => {
                                this.setState({
                                  record_time: dateString,
                                })
                              }}
                              value={this.state.record_time}
                              allowClear={false}
                              className="mb-2 w-full"
                              disabled={this.state.document_type != 'manual'}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12} className="mb-2">
                          <Form.Item
                            name="category"
                            label="Select Category"
                            extra={
                              'Choose the category or type your own category that describes the reason for this stock ' +
                              (this.state.addFlag ? 'in' : 'out') +
                              '.'
                            }
                          >
                            <Select
                              mode="tags"
                              maxTagCount={1}
                              showSearch
                              allowClear
                              placeholder="Select category or type your own"
                              suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                              filterOption={(input, option) =>
                                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={e => {
                                this.formRef.current.setFieldsValue({
                                  category:
                                    e == undefined || e?.length == 0 ? '' : [e[e.length - 1]],
                                })
                              }}
                            >
                              {[
                                ...(this.state.addFlag
                                  ? this.props.stock_in_categories
                                  : this.props.stock_out_categories),
                                ...this.props.inventory_categories?.filter(
                                  item =>
                                    item !== '' &&
                                    !this.props.stock_in_categories?.includes(item) &&
                                    !this.props.stock_out_categories?.includes(item),
                                ),
                              ].map((item, index) => (
                                <Select.Option key={index} value={item}>
                                  {item}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            name="remarks"
                            label="Remarks"
                            extra="Add notes here to help you remember important details."
                          >
                            <TextArea />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>

                    <SectionHeader title="Price details" optional={true} link={false} />
                    <Card className="mb-4">
                      <Row gutter={18}>
                        {this.state.addFlag && (
                          <Col span={12}>
                            <Form.Item
                              name="purchase_price"
                              label="Purchase Price"
                              onChange={e => {
                                var purchase_price = this.formRef.current.getFieldValue(
                                  'purchase_price',
                                )
                                  ? parseFloat(this.formRef.current.getFieldValue('purchase_price'))
                                  : 0

                                var qty = this.formRef.current.getFieldValue('quantity')
                                  ? parseFloat(this.formRef.current.getFieldValue('quantity'))
                                  : 0
                                this.formRef.current.setFieldsValue({
                                  stock_value: purchase_price * qty,
                                })
                              }}
                              rules={[
                                {
                                  validator: (rule, value) => {
                                    if (value < 0) {
                                      return Promise.reject('Price cannot be negative')
                                    }
                                    return Promise.resolve()
                                  },
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                placeholder="Purchase Price"
                                style={{
                                  width: '100%',
                                }}
                              />
                            </Form.Item>
                          </Col>
                        )}
                        {!this.state.addFlag && (
                          <Col span={12}>
                            <Form.Item
                              name="selling_price"
                              label="Selling Price"
                              onChange={e => {
                                var selling_price = this.formRef.current.getFieldValue(
                                  'selling_price',
                                )
                                  ? parseFloat(this.formRef.current.getFieldValue('selling_price'))
                                  : 0

                                var qty = this.formRef.current.getFieldValue('quantity')
                                  ? parseFloat(this.formRef.current.getFieldValue('quantity'))
                                  : 0
                                this.formRef.current.setFieldsValue({
                                  stock_value: selling_price * qty,
                                })
                              }}
                              rules={[
                                {
                                  validator: (rule, value) => {
                                    if (value < 0) {
                                      return Promise.reject('Price cannot be negative')
                                    }
                                    return Promise.resolve()
                                  },
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                placeholder="Selling Price"
                                style={{
                                  width: '100%',
                                }}
                              />
                            </Form.Item>
                          </Col>
                        )}

                        <Col span={12}>
                          <Form.Item
                            name="stock_value"
                            label={`Stock ${this.state.addFlag ? 'In' : 'Out'} Value`}
                            onChange={e => {
                              var stock_value = this.formRef.current.getFieldValue('stock_value')
                                ? parseFloat(this.formRef.current.getFieldValue('stock_value'))
                                : 0

                              var qty = this.formRef.current.getFieldValue('quantity')
                                ? parseFloat(this.formRef.current.getFieldValue('quantity'))
                                : 0
                              if (this.state.addFlag) {
                                this.formRef.current.setFieldsValue({
                                  purchase_price: stock_value / qty,
                                })
                              } else {
                                this.formRef.current.setFieldsValue({
                                  selling_price: stock_value / qty,
                                })
                              }
                            }}
                            rules={[
                              {
                                validator: (rule, value) => {
                                  if (value < 0) {
                                    return Promise.reject('Stock Value cannot be negative')
                                  }
                                  return Promise.resolve()
                                },
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              placeholder="Stock Value"
                              style={{
                                width: '100%',
                              }}
                              defaultValue={0}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>

                    {this.state.addFlag &&
                      (this.props.document_settings?.purchase_update_columns.includes(
                        'custom_columns',
                      ) ||
                        this.props.document_settings?.purchase_update_columns.includes(
                          'price_with_tax',
                        ) ||
                        this.props.document_settings?.purchase_update_columns.includes(
                          'discount',
                        )) && (
                        <Collapse
                          className="bg-card-orange-dark pb-0"
                          bordered={false}
                          defaultActiveKey={['1']}
                          onChange={this.handleCollapseChange}
                          expandIcon={({ isActive }) => (
                            <span className="">
                              <i
                                className={`fa-solid fa-chevron-down collapse-align-center ${!isActive &&
                                  'fa-rotate-270'}`}
                              />
                            </span>
                          )}
                        >
                          <Panel
                            header={
                              <>
                                <span className="font-size-16 font-weight-bold">
                                  Update Product Details?
                                </span>
                                <p className="text-gray-900 font-size-14 font-weight-normal mb-0">
                                  You have the option to update product details while processing
                                  Stock In.
                                </p>
                              </>
                            }
                            key="1"
                            className=""
                          >
                            <div ref={this.panelRef}>
                              <Card className="mb-4">
                                <Row gutter={18}>
                                  {this.props.document_settings?.purchase_update_columns.includes(
                                    'price_with_tax',
                                  ) && (
                                    <Col span={24}>
                                      <Form.Item
                                        name="selling_price"
                                        label={`Selling Price`}
                                        min={0}
                                      >
                                        <Input
                                          type="number"
                                          placeholder="Selling Price"
                                          style={{
                                            width: '100%',
                                          }}
                                          defaultValue={0}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {this.props.document_settings?.purchase_update_columns.includes(
                                    'discount',
                                  ) && (
                                    <Col span={24}>
                                      <Form.Item
                                        name="product_discount"
                                        label={`Product Discount`}
                                        min={0}
                                      >
                                        <Input
                                          type="number"
                                          placeholder="Product Discount"
                                          style={{
                                            width: '100%',
                                          }}
                                          defaultValue={0}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {this.props.document_settings?.purchase_update_columns.includes(
                                    'custom_columns',
                                  ) &&
                                    this.props.custom_columns?.map(
                                      (item, index) =>
                                        item.is_active == 1 &&
                                        item.default_key == '' && (
                                          <Col span={12}>
                                            <Form.Item name={item.name} label={item.name} min={0}>
                                              {item.field_type == 'number' ? (
                                                <InputNumber
                                                  style={{ width: '100%' }}
                                                  placeholder={item.name}
                                                />
                                              ) : item.field_type == 'date' ? (
                                                <DatePicker
                                                  style={{ width: '100%' }}
                                                  placeholder={item.name}
                                                  format={this.dateFormat}
                                                />
                                              ) : item.field_type == 'datetime' ? (
                                                <DatePicker
                                                  showTime
                                                  style={{ width: '100%' }}
                                                  placeholder={item.name}
                                                  format={this.dateTimeFormat}
                                                />
                                              ) : item.field_type == 'bigtext' ? (
                                                <TextArea
                                                  placeholder={item.name}
                                                  type="text"
                                                  rows={1}
                                                  className="pr-4 mr-2"
                                                  style={{ width: '100%', borderRadius: '5px' }}
                                                />
                                              ) : (
                                                <Input
                                                  type="text"
                                                  placeholder={item.name}
                                                  style={{
                                                    width: '100%',
                                                  }}
                                                  defaultValue={0}
                                                />
                                              )}
                                            </Form.Item>
                                          </Col>
                                        ),
                                    )}
                                </Row>
                              </Card>
                            </div>
                          </Panel>
                        </Collapse>
                      )}

                    {this.state.selectedData.has_bom == 1 && (
                      <Card className="mb-3">
                        <Row gutter={16}>
                          <Col span={24}>
                            <span className="mr-5 font-weight-bold">
                              {this.state.addFlag
                                ? 'Stock Out Raw Material'
                                : 'Stock In Raw Material'}
                            </span>
                            <Switch
                              style={
                                this.state.is_bom
                                  ? { backgroundColor: '#15be53' }
                                  : { backgroundColor: '#e11900' }
                              }
                              checked={this.state.is_bom}
                              checkedChildren={'Yes'}
                              unCheckedChildren={'No'}
                              onChange={checked => {
                                this.setState({
                                  is_bom: checked,
                                })
                              }}
                            />
                          </Col>

                          <Col span={24} className="mt-3">
                            <h6 className="mb-1">
                              Raw Material for{' '}
                              <span className="font-weight-bold">
                                {this.state.selectedData.product_name}
                              </span>
                            </h6>
                            <Table
                              columns={bom_columns}
                              dataSource={this.state.bom_products}
                              pagination={false}
                              rowKey="id"
                            />
                          </Col>
                        </Row>
                      </Card>
                    )}
                  </>
                )}
            </>
            {/* <Button onClick={() => this.showPurchaseConfirmModal()}>dsfsdfsdf</Button> */}
          </Form>
        ) : (
          <Card>
            <p>You don't have access to {this.state.addFlag ? 'Stock In ' : 'Stock Out'}</p>
          </Card>
        )}
        {/* {} */}
        <PurchaseConfirm
          ref={this.purchaseConfirmRef}
          purchase_update_columns={this.props.document_settings.purchase_update_columns}
          products={this.state.dataSource}
          onPurchaseUpdateConfirm={this.onPurchaseUpdateConfirm}
        />
      </Drawer>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  const stock_in_warehouses =
    state.warehouse.warehouses?.filter(
      item => state.warehouse.warehouse_permissions?.[item.warehouse_id]?.['stock_in'] == 1,
    ) ?? []
  const stock_out_warehouses =
    state.warehouse.warehouses?.filter(
      item => state.warehouse.warehouse_permissions?.[item.warehouse_id]?.['stock_out'] == 1,
    ) ?? []

  return {
    document_settings: state.user.document_settings,
    custom_columns: state.document.custom_column_data.custom_columns,
    warehouses: state.warehouse.warehouses,
    permissions: state.permissions.permission,
    warehouse_permissions: state.warehouse.warehouse_permissions,
    stock_in_warehouses,
    stock_out_warehouses,
    inventory_categories: state.warehouse.inventory_categories ?? [],
    default_warehouse_id: state.warehouse.default_warehouse_id,
    stock_in_categories: state.warehouse.stock_in_categories ?? [],
    stock_out_categories: state.warehouse.stock_out_categories ?? [],
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(InventoryForm)
