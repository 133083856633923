import { LoadingOutlined, MenuOutlined } from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  Button,
  Card,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Switch,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd'
import InfoComponent from 'components/badges/InfoComponent'
import NewBadge from 'components/badges/new'
import CustomDeleteModal from 'components/modal/delete/customFieldDelete'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import SectionHeader from 'components/other/sectionHeader'
import { capitalizeFirstLetters, formItemLayout } from 'components/other/utility'
import * as dayjs from 'dayjs'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { Mention, MentionsInput } from 'react-mentions'
import { connect } from 'react-redux'
import { get_top_custom_fields, set_custom_columns } from 'redux/document/actions'
import { getAPI, getAPIData } from 'services/jwt'
import store from 'store'

const { TabPane } = Tabs

const { TextArea } = Input

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
    disabled: children[0]?.props?.record?.allow_sorting == 0,
  })
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          // zIndex: 9999,
        }
      : {}),
  }
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, child => {
        if (child.key === 'sort' && child.props.record.allow_sorting == 1) {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'pointer',
                }}
                {...listeners}
              />
            ),
          })
        }
        return child
      })}
    </tr>
  )
}
class CustomColumnsForm extends Component {
  formRef = React.createRef()
  formRef2 = React.createRef()
  formRef3 = React.createRef()
  formRef4 = React.createRef()
  item_ref = React.createRef()
  partyFormRef = React.createRef()
  company_form_ref = React.createRef()
  custom_links_ref = React.createRef()
  deleteModalRef = React.createRef()
  subscriptionPlanRef = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  document_types = [
    { name: 'All', document_type: 'All' },
    { name: 'Invoices', document_type: 'invoice' },
    { name: 'Purchases', document_type: 'purchase' },
    { name: 'Sales Returns', document_type: 'sales_return' },
    { name: 'Purchase Returns', document_type: 'purchase_return' },
    { name: 'Purchase Orders', document_type: 'purchase_order' },
    { name: 'Quotation', document_type: 'estimate' },
    { name: 'Pro Forma Invoice', document_type: 'pro_forma_invoice' },
    { name: 'Delivery Challan', document_type: 'delivery_challan' },
    { name: 'Packing List', document_type: 'packing_list' },
  ]

  constructor(props) {
    super(props)
    this.state = {
      states: [],
      settingDataCustomColumns: '',
      visible: false,
      loading: false,
      custom_headers: [],
      selected_header: {},
      show_custom_header_form: false,
      defaultActiveKey: '1',
      activeKey: '1',
      customer_custom_fields: [],
      party_type: 'customer',
      show_party_Fields_drawer: false,
      show_company_field_drawer: false,
      selectedCompanyField: {},
      selected_party_field: -1,
      show_type: 'all',
      company_custom_fields: [],
      hide_default_values: false,
      linked_fields: [
        { id: undefined, operator: undefined },
        { id: undefined, operator: undefined },
      ],
      show_item_custom_fields_form: false,
      item_custom_fields_extra: [],
      is_total_amount_linked: false,
      is_net_amount_linked: false,
      customer_not_allowed_fields: [
        'balance',
        'billing_address',
        'billing_address_1',
        'billing_address_2',
        'billing_city',
        'billing_pincode',
        'billing_state',
        'company_id',
        'company_name',
        'custom_values',
        'customer_id',
        'dial_code',
        'discount',
        'email',
        'export_customer',
        'gst',
        'gstin',
        'hash_id',
        'id',
        'is_same',
        'is_tds',
        'login_with',
        'name',
        'notes',
        'opening_balance',
        'pan',
        'phone',
        'profile_image',
        'record_time',
        'shipping_address',
        'shipping_address_1',
        'shipping_address_2',
        'shipping_city',
        'shipping_pincode',
        'shipping_state',
        'tags',
        'tds_section_id',
      ],
      vendor_not_allowed_fields: [
        'balance',
        'billing',
        'company_id',
        'company_name',
        'custom_values',
        'dial_code',
        'email',
        'gst',
        'gstin',
        'hash_id',
        'id',
        'is_tds',
        'name',
        'notes',
        'opening_balance',
        'pan',
        'phone',
        'record_time',
        'tds_section_id',
        'vendor_id',
      ],
      product_not_allowed_fields: [
        'avg_purchase_price',
        'barcode_id',
        'cess',
        'company_id',
        'custom_column_names',
        'custom_column_values',
        'description',
        'discount',
        'free_qty',
        'hsn_code',
        'id',
        'image',
        'is_price_with_tax',
        'item_prices',
        'price',
        'price_lists',
        'price_with_tax',
        'product_category',
        'product_id',
        'product_images',
        'product_name',
        'product_type',
        'purchase_price',
        'qty',
        'show_online',
        'tax',
        'unit',
        'unit_price',
        'url',
        'variants',
        'Quantity',
      ],
      drop_down_item_columns: [],
      custom_links: [],
      top_custom_fields: [],
      top_fields: [],
      suggestion_columns: [],
      new_expression: '',
      display_id_dict: {},
      charge_type: 1,
      charge_with_tax: 1,
      formula_warning: false,
      custom_field_type: '',
      changed_custom_field_type: '',
      change_formula: false,
      is_packing_list: false,
      dateFlag: false,
      custom_header_date: dayjs(),
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
    if (this.props.top_fields.length == 0) {
      this.props.dispatch(get_top_custom_fields())
    }
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  onAddFromProducts = async () => {
    this.setState(
      {
        show_type: 'products',
      },
      () => {
        this.onAdd()
      },
    )
  }
  columnDropDownValue = item => {
    if (item.default_key == 'qty') {
      return 'qty'
    } else if (item.default_key == 'total_amount') {
      return 'total_amount'
    } else if (item.default_key == 'net_amount') {
      return 'net_amount'
    } else {
      return item.id
    }
  }
  get_suggested_columns = (custom_columns, id) => {
    let suggestion_columns = []
    suggestion_columns = custom_columns.filter(item => {
      if (
        ['qty', 'unit_price', 'price_with_tax', ''].includes(item.default_key) &&
        (item.field_type == 'formula' ||
          item.field_type == 'number' ||
          item.field_type == 'currency') &&
        item.document_types[0] == 'All'
      ) {
        return true
      }
    })
    suggestion_columns = suggestion_columns.filter(item => item.id != id)
    suggestion_columns = suggestion_columns.map(item => {
      return {
        id: item.default_key != '' ? item.default_key : item.id,
        display: item.name,
      }
    })
    return suggestion_columns
  }

  onAdd = async () => {
    this.setState({ loading: false })
    var data = await getAPIData('v2/custom_fields', 'item')

    if (data) {
      this.props.dispatch(
        set_custom_columns(
          data.custom_columns.map(item => {
            return {
              ...item,
              is_active: item.default_key != '' ? false : item.is_active,
              show_in_pdf: item.default_key != '' ? false : item.show_in_pdf,
            }
          }),
        ),
      )

      let is_total_amount_linked = false
      let is_net_amount_linked = false
      data.custom_columns.map(item => {
        if (item.default_key == 'total_amount') {
          is_total_amount_linked = item.field_type == 'formula'
        }
        if (item.default_key == 'net_amount') {
          is_net_amount_linked = item.field_type == 'formula'
        }
      })

      let dropDownColumns = data.custom_columns.filter(
        item =>
          item.default_key != 'total_amount' &&
          item.default_key != 'net_amount' &&
          (item.field_type == 'formula' || item.field_type == 'number'),
      )

      let suggestion_columns = this.get_suggested_columns(data.custom_columns, -1)
      let display_id_dict = {}
      data.custom_columns.map(item => {
        display_id_dict[item.default_key != '' ? item.default_key : item.id] = item.name
      })

      this.setState({
        is_total_amount_linked,
        is_net_amount_linked,
        visible: true,
        settingDataCustomColumns: data,
        item_custom_columns: data.custom_columns,
        defaultActiveKey: '2',
        activeKey: '2',
        drop_down_item_columns: dropDownColumns,
        suggestion_columns,
        display_id_dict,
      })

      let top_custom_fields = this.props.top_fields.filter(item => {
        if (item.type == 'item') {
          return true
        }
      })

      top_custom_fields = top_custom_fields.filter(item => {
        if (data.custom_columns.find(column => column.name == item.name)) {
          return false
        } else {
          return true
        }
      })
      this.setState({ top_custom_fields: top_custom_fields })
    }
  }

  onAddCustomHeaders = async () => {
    this.setState({ loading: false })
    var data = await getAPIData('v2/custom_fields', 'custom_headers')
    if (data) {
      this.setState({
        visible: true,
        custom_headers: data.custom_headers,
        // defaultActiveKey: '1',
        activeKey: '1',
      })
      // iterate through the custom headers, if field_type=date, then convert the date to dayjs object
      data.custom_headers.map(header => {
        if (header.field_type == 'date') {
          header.placeholder =
            header.placeholder !== '' && header.placeholder
              ? dayjs(header.placeholder, this.dateFormat)
              : undefined
        }
      })
      let top_custom_fields = this.props.top_fields.filter(item => {
        if (item.type == 'header') {
          return true
        }
      })

      top_custom_fields = top_custom_fields.filter(item => {
        if (data.custom_headers.find(column => column.label == item.name)) {
          return false
        } else {
          return true
        }
      })
      this.setState({ top_custom_fields: top_custom_fields })
    }
  }
  onEditCompanyField = async field => {
    this.setState({
      selectedCompanyField: field,
      show_company_field_drawer: true,
    })
  }

  onAddCustomAdditionalCharges = async () => {
    this.setState({ loading: false })
    var data = await getAPIData('utils', 'custom_additional_charges')
    if (data) {
      this.setState({
        visible: true,
        custom_additional_charges: data.custom_additional_charges,
        defaultActiveKey: '3',
        activeKey: '3',
      })
    }
  }
  onChangeExpression = (expression, e) => {
    if (this.state.change_formula || this.state.custom_field_type != 'formula') {
      let lastChar = expression.slice(-1)
      let prevChar = this.state.new_expression.slice(-2, -1)
      if ('+-*/()'.includes(lastChar) && lastChar != prevChar) {
        expression += ' '
      }
      this.setState({ new_expression: expression })
    } else {
      this.setState({
        formula_warning: true,
      })
    }
  }

  format_expression = (expression, like_mentions = false) => {
    if (like_mentions) {
      expression = expression.replace(/[-+*/]/g, ' $& ')
      let regx = /\{[^\}]+\}/g
      let matches = expression.match(regx)
      if (matches) {
        for (let i = 0; i < matches.length; i++) {
          let match = matches[i]
          let id = match.slice(1, -1)
          let name = this.state.display_id_dict[id]
          expression = expression.replace(match, ` @[${name}](${id}) `)
        }
        expression.replace(/ {2,}/g, ' ')
        expression = expression.trim()

        return expression
      }
    } else {
      let regx = /@\[[^\]]+\]\([^)]+\)/g
      let matches = expression.match(regx)
      if (matches) {
        for (let i = 0; i < matches.length; i++) {
          let match = matches[i]
          let id = match.match(/\(([^)]+)\)/)[1]
          // let name = match.match(/\[([^\]]+)\]/)[1]
          expression = expression.replace(match, `{${id}}`)
        }

        return expression
      }
    }
    return ''
  }

  showCustomHeader = async header_id => {
    var selected_header_index = this.state.custom_headers.findIndex(
      x => x['header_id'] == header_id,
    )
    var selected_header = this.state.custom_headers[selected_header_index]
    // console.log(selected_header);
    selected_header.document_types = selected_header.document_type.split(',')
    this.setState({
      selected_header: selected_header,
      show_custom_header_form: true,
      dateFlag: false,
      custom_header_date: dayjs(),
    })
  }

  showCustomAdditionalCharge = async additional_charge_id => {
    var selected_additional_charge_index = this.state.custom_additional_charges.findIndex(
      x => x['additional_charge_id'] == additional_charge_id,
    )
    var selected_additional_charge = this.state.custom_additional_charges[
      selected_additional_charge_index
    ]
    this.setState({
      selected_additional_charge: {
        ...selected_additional_charge,
        amount:
          selected_additional_charge.is_price_with_tax == 1
            ? selected_additional_charge.amount
            : selected_additional_charge.unit_price,
        document_types:
          selected_additional_charge.document_type != ''
            ? selected_additional_charge.document_type.split(',')
            : [''],
      },
      charge_type: selected_additional_charge.type,
      charge_with_tax: selected_additional_charge.is_price_with_tax,
      show_custom_additional_charge_form: true,
    })
  }

  customHeaderStatusChange = async (show_in_pdf, is_active, is_required, header_id) => {
    var selected_header_index = this.state.custom_headers.findIndex(
      x => x['header_id'] == header_id,
    )
    var req = {
      ...this.state.custom_headers[selected_header_index],
      document_types: this.state.custom_headers[selected_header_index].document_type,
      show_in_pdf: show_in_pdf,
      is_active: is_active,
      is_required: is_required,
    }
    var data = await getAPI('v2/custom_fields', 'custom_headers', req)
    if (data?.success) {
      message.success('Status Updated')
      this.onAddCustomHeaders()
    }
  }

  customAdditionalChargeStatusChange = async (is_default, additional_charge_id) => {
    var selected_additional_charge_index = this.state.custom_additional_charges.findIndex(
      x => x['additional_charge_id'] == additional_charge_id,
    )
    var req = {
      ...this.state.custom_additional_charges[selected_additional_charge_index],
      is_default: is_default,
      amount:
        this.state.custom_additional_charges[selected_additional_charge_index].is_price_with_tax ==
        0
          ? this.state.custom_additional_charges[selected_additional_charge_index].unit_price
          : this.state.custom_additional_charges[selected_additional_charge_index].amount,
      document_types: this.state.custom_additional_charges[selected_additional_charge_index]
        .document_type,
    }
    var data = await getAPI('utils', 'custom_additional_charges', req)
    if (data?.success) {
      message.success(data.message)
      this.onAddCustomAdditionalCharges()
    }
  }
  onEditItemField = async item => {
    let expression_link = []
    const item_custom_fields_extra = [
      this.state.item_custom_fields_extra,
      'qty',
      'total_amount',
      'net_amount',
    ]
    let new_expression = ''
    if (item.expression != '') {
      new_expression = this.format_expression(item.expression, true)
    }
    let suggestion_columns = this.get_suggested_columns(this.state.item_custom_columns, item.id)
    if (item.default_key == 'net_amount') {
      suggestion_columns.push({ id: 'net_amount', display: item.name })
    } else if (item.default_key == 'total_amount') {
      suggestion_columns.push({ id: 'total_amount', display: item.name })
    }
    // if (item.default_key == 'net_amount' || item.default_key == 'total_amount') {
    //   suggestion_columns = suggestion_columns.filter(item => item.id != 'qty')
    // }

    // if (item.expression != '') {
    //   const expression_list = item.expression.split(',')
    //   for (var i = 0; i < expression_list.length; i++) {
    //     if (i != expression_list.length - 1) {
    //       expression_link.push({
    //         id: !item_custom_fields_extra.includes(expression_list[i])
    //           ? parseInt(expression_list[i])
    //           : expression_list[i],
    //         operator: expression_list[i + 1],
    //       })
    //       i++
    //     } else {
    //       expression_link.push({
    //         id: !item_custom_fields_extra.includes(expression_list[i])
    //           ? parseInt(expression_list[i])
    //           : expression_list[i],
    //         operator: undefined,
    //       })
    //     }
    //   }
    // } else {
    //   expression_link = [
    //     { id: undefined, operator: undefined },
    //     { id: undefined, operator: undefined },
    //   ]
    // }
    if (item.default_value == '') {
      item.default_value = undefined
    }

    this.setState({
      selected_item_column: item,
      show_item_custom_fields_form: true,
      linked_fields: expression_link,
      new_expression,
      suggestion_columns,
      hide_default_values:
        item.field_type == 'formula' && this.props.integrations?.custom_columns_link == 1,
      custom_field_type: item.field_type,
    })
  }
  onCustomHeaderFinish = async values => {
    if (values.document_types.length == 0) {
      message.error('Please select atleast one document type')
      return
    }
    var req = {
      ...values,
      placeholder:
        values.field_type == 'date' && values.placeholder
          ? dayjs(values.placeholder).format(this.dateFormat)
          : values.placeholder,
      document_types: values.document_types.join(','),
    }
    var data = await getAPI('v2/custom_fields', 'custom_headers', req)
    if (data?.success) {
      message.success(data.message)
      this.setState({ show_custom_header_form: false })
      this.onAddCustomHeaders()
    }
  }
  onAddNewItemColumn = () => {
    this.setState(
      {
        selected_item_column: {
          is_active: 1,
          is_delete: 0,
          id: -1,
          name: '',
          default_value: undefined,
          is_link: 0,
          is_default: 0,
          default_key: '',
          show_in_pdf: 1,
          field_type: 'text',
          applicable_for: 'product',
        },
        hide_default_values: false,
        linked_fields: [
          { id: undefined, operator: undefined },
          { id: undefined, operator: undefined },
        ],
        show_item_custom_fields_form: true,
      },
      () => {
        this.item_ref.current.setFieldsValue({
          document_types: ['All'],
        })
      },
    )
  }
  onItemCustomFieldFinish = async values => {
    if (values.default_value != undefined && values.default_value.length > 0) {
      values.default_value = values.default_value[0]
    } else {
      values.default_value = ''
    }
    const fields = this.state.linked_fields

    values = {
      ...values,
      expression: this.format_expression(this.state.new_expression).replace(/\s+/g, ''),
      is_link: values.field_type == 'formula',
    }
    if (this.state.custom_field_type == 'formula' && values.field_type != 'formula') {
      this.setState({
        formula_warning: true,
      })
      return
    } else {
      await this.onStatusChangeItemColumn(values)
    }
  }
  onStatusChangeItemColumn = async data => {
    if ('is_delete' in data && data.is_delete == true) {
      for (
        let index = 0;
        index < this.state.settingDataCustomColumns.custom_columns.length;
        index++
      ) {
        const element = this.state.settingDataCustomColumns.custom_columns[index]
        if (element.expression.includes(data.id)) {
          message.error("You can't delete this column as it is linked with " + element.name)
          return true
        }
      }
    }
    const req = {
      ...data,
      document_types: this.state.is_packing_list ? ['packing_list'] : data.document_types,
    }
    var data = await getAPI('v2/custom_fields', 'item', req)
    if (data?.success) {
      message.success(data.message)
      this.setState({ show_item_custom_fields_form: false, change_formula: false })
      this.onAdd()
    }
  }

  onCustomAdditionalChargeFinish = async values => {
    if (values.document_types?.length == 0 || values.document_types == undefined) {
      message.error('Please select atleast one document type')
      return
    }

    console.log(values)
    var req = {
      ...values,
      sac_code: this.state.charge_type == 1 ? values.sac_code : '',
      is_price_with_tax: this.state.charge_with_tax,
      document_types: values.document_types?.join(','),
      is_default: values.additional_charge_id == -1 ? 0 : values.is_default,
    }
    var data = await getAPI('utils', 'custom_additional_charges', req)
    if (data?.success) {
      message.success(data.message)
      this.setState({ show_custom_additional_charge_form: false })
      this.onAddCustomAdditionalCharges()
    }
  }

  onDeleteCustomHeader = async ({ header_id }) => {
    var selected_header_index = this.state.custom_headers.findIndex(
      x => x['header_id'] == header_id,
    )

    var req = {
      ...this.state.custom_headers[selected_header_index],
      placeholder:
        this.state.custom_headers[selected_header_index]?.field_type == 'date'
          ? dayjs(this.state.custom_headers[selected_header_index]?.placeholder)?.format(
              this.dateFormat,
            )
          : this.state.custom_headers[selected_header_index]?.placeholder,
      is_delete: 1,
    }

    var data = await getAPI('utils', 'custom_headers', req)
    if (data?.success) {
      message.success(data.message)
      this.setState({ show_custom_header_form: false })
      this.onAddCustomHeaders()
    }
  }

  onDeleteCustomAdditionalCharge = async ({ additional_charge_id }) => {
    var selected_additional_charge_index = this.state.custom_additional_charges.findIndex(
      x => x['additional_charge_id'] == additional_charge_id,
    )
    var req = {
      ...this.state.custom_additional_charges[selected_additional_charge_index],
      is_delete: 1,
    }
    var data = await getAPI('utils', 'custom_additional_charges', req)
    if (data?.success) {
      message.success(data.message)
      this.setState({ show_custom_additional_charge_form: false })
      this.onAddCustomAdditionalCharges()
    }
  }

  onAddNewCustomHeader = async () => {
    this.setState({
      show_custom_header_form: true,
      selected_header: {
        ...this.state.selected_header,
        header_id: -1,
        is_active: 1,
        is_delete: 0,
        document_types: ['All'],
      },
    })
  }

  onAddNewCustomAdditionalCharge = async () => {
    this.setState({
      show_custom_additional_charge_form: true,
      selected_additional_charge: {
        ...this.state.selected_additional_charge,
        additional_charge_id: -1,
        is_active: 1,
        is_default: 0,
        is_delete: 0,
      },
    })
  }
  onAddCustomerCustomField = async field => {
    this.setState({
      show_party_Fields_drawer: true,
      selected_party_field: field,
    })
  }
  onCustomerCustomFields = async (party_type = this.state.party_type) => {
    const req = { party_type: party_type }
    if (party_type == 'customer') {
      this.setState({
        defaultActiveKey: '4',
        activeKey: '4',
        visible: true,
        party_type: 'customer',
      })
    } else {
      this.setState({ defaultActiveKey: '5', activeKey: '5', visible: true, party_type: 'vendor' })
    }
    const data = await getAPI('v2/custom_fields', 'party', req)
    if (data?.success) {
      let top_custom_fields = this.props.top_fields.filter(item => {
        if (item.type == party_type) {
          return true
        }
      })

      top_custom_fields = top_custom_fields.filter(item => {
        if (data.custom_fields.find(column => column.name == item.name)) {
          return false
        } else {
          return true
        }
      })
      this.setState({
        top_custom_fields: top_custom_fields,
        customer_custom_fields: data.custom_fields,
      })
    }
  }
  onCompanyCustomFields = async () => {
    this.setState({ defaultActiveKey: '6', activeKey: '6', visible: true })

    const company_fields = await getAPIData('v2/company', 'get_custom_fields')

    if (company_fields.success) {
      let top_custom_fields = this.props.top_fields.filter(item => {
        if (item.type == 'company') {
          return true
        }
      })
      top_custom_fields = top_custom_fields.filter(item => {
        if (company_fields.custom_fields.find(column => column.field_name == item.name)) {
          return false
        } else {
          return true
        }
      })
      this.setState({
        company_custom_fields: company_fields.custom_fields,
        top_custom_fields: top_custom_fields,
      })
    }
  }

  add_top_field = async field => {
    if (!this.state.loading) {
      this.setState({ loading: true })
      if (field.type == 'item') {
        this.onStatusChangeItemColumn({
          id: -1,
          name: field.name,
          is_active: 1,
          is_delete: 0,
          field_type: 'text',
          default_value: '',
        })
      }
      if (field.type == 'packing_list') {
        this.onStatusChangeItemColumn({
          id: -1,
          name: field.name,
          is_active: 1,
          is_delete: 0,
          field_type: 'text',
          default_value: '',
          document_types: ['packing_list'],
        })
      }
      if (field.type == 'header') {
        this.onCustomHeaderFinish({
          header_id: -1,
          label: field.name,
          is_active: 1,
          is_delete: 0,
          document_types: ['All'],
        })
      }
      if (field.type == 'customer' || field.type == 'vendor') {
        this.onPartyFieldsFinish({
          custom_field_id: -1,
          name: field.name,
          is_active: 1,
          is_delete: 0,
        })
      }
      if (field.type == 'company') {
        this.companyFieldChange({
          field_id: -1,
          field_name: field.name,
          is_active: 1,
          is_delete: 0,
        })
      }
      this.setState({ loading: false })
    }
  }

  onTabChange = async key => {
    this.setState({ top_custom_fields: [], is_packing_list: false })
    if (key == '2') {
      await this.onAdd()
      this.formRef2.current?.setFieldsValue({
        ...this.state.settingDataCustomColumns,
      })
    } else if (key == '3') {
      this.onAddCustomAdditionalCharges()
    } else if (key == '4') {
      this.setState({ party_type: 'customer' }, async () => {
        await this.onCustomerCustomFields('customer')
      })
    } else if (key == '5') {
      this.setState({ party_type: 'vendor' }, async () => {
        await this.onCustomerCustomFields('vendor')
      })
    } else if (key == '6') {
      await this.onCompanyCustomFields()
    } else if (key == 'packing_list') {
      let recommended_fields = [
        {
          name: 'Container',
          type: 'packing_list',
        },
        {
          name: 'Seal No',
          type: 'packing_list',
        },
      ]
      recommended_fields = recommended_fields.filter(item => {
        if (
          this.state.settingDataCustomColumns?.custom_columns?.find(
            column => column.name == item.name,
          )
        ) {
          return false
        } else {
          return true
        }
      })
      this.setState({ is_packing_list: true, top_custom_fields: recommended_fields })
    } else {
      await this.onAddCustomHeaders()
    }
  }
  onPartyFieldsFinish = async values => {
    const req = {
      ...this.state.selected_party_field,
      ...values,
      party_type: this.state.party_type,
    }
    if (this.state.party_type == 'customer') {
      const index = this.state.customer_not_allowed_fields.findIndex(x => x == values.name)
      if (index != -1) {
        message.error(`${values.name} is not allowed`)
        return false
      }
    } else {
      const index = this.state.vendor_not_allowed_fields.findIndex(x => x == values.name)
      if (index != -1) {
        message.error(`${values.name} is not allowed`)
        return false
      }
    }
    const data = await getAPI('v2/custom_fields', 'party/update', req)
    if (data?.success) {
      message.success('Field added Success fully')
    } else {
      return
    }
    this.setState({ show_party_Fields_drawer: false })
    this.onCustomerCustomFields()
  }
  partyFieldStatusChange = async item => {
    const data = await getAPI('v2/custom_fields', 'party/update', item)
    if (data?.success) {
      message.success(data.message)
    }
    this.onCustomerCustomFields()
  }
  companyFieldChange = async item => {
    const data = await getAPI('v2/company', 'add_custom_field', item)
    if (data?.success) {
      message.success(data.message)
      this.setState({
        show_company_field_drawer: false,
      })
    }
    this.onCompanyCustomFields()
  }
  onAddCompanyField = async () => {
    this.setState({
      show_company_field_drawer: true,
      selectedCompanyField: {
        field_id: -1,
        field_name: '',
        is_active: 1,
        is_delete: 0,
      },
    })
  }

  onEditCompanyField = async field => {
    this.setState({
      selectedCompanyField: field,
      show_company_field_drawer: true,
    })
  }

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    let applicable_types = [
      { label: 'Product', value: 'product' },
      { label: 'Variant', value: 'variant' },
    ]

    if (this.props.integrations?.batching == 1) {
      applicable_types.push({ label: 'Batch', value: 'batch' })
    }
    const customStyle = {
      ul: {
        borderRadius: 10,
      },
      suggestions: {
        list: {
          border: '1px solid rgba(0,0,0,0.15)',
          fontSize: 14,
          // padding: 5,
          borderRadius: 10,
        },
        item: {
          padding: '5px 15px',
          borderRadius: 10,
          // borderBottom: '1px solid rgba(0,0,0,0.15)',
          // '&focused': {
          //   backgroundColor: '#cee4e5',
          // },
        },
      },
    }

    const title = [
      'Document Fields',
      'Item',
      'Additional Charges',
      'Customer Fields',
      'Vendor Fields',
    ]

    const default_key_list = [
      'id',
      'product_name',
      'unit_price',
      'price_with_tax',
      'tax_amount',
      'net_amount',
      'total_amount',
      'batch_no',
      'expiry_date',
      'mfg_date',
      'qty',
    ]
    const itemColumns = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        key: 'sort',
        width: '5%',
        align: 'center',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        className: 'drag-visible',
        width: '45%',
        render: (text, record) => {
          return (
            <span>
              {text}
              {' \n'}
              {record.default_key != '' && (
                <span className="text-gray-500">
                  ({capitalizeFirstLetters(record.default_key.split('_').join(' '))})
                </span>
              )}
              <br />
              <span className="text-gray-400 font-size-12">
                {record.document_types.map((item, index) => {
                  return (
                    <span key={index}>
                      {capitalizeFirstLetters(item.replace('_', ' '))}
                      {index != record.document_types.length - 1 ? ', ' : ''}
                    </span>
                  )
                })}
              </span>
            </span>
          )
        },
      },
      {
        title: 'Show in PDF',
        dataIndex: 'show_in_pdf',
        className: 'drag-visible',
        width: '10%',
        render: (text, record) => {
          return record.show_pdf_toggle == 1 ? (
            <Switch
              checked={record.show_in_pdf}
              onChange={() =>
                this.onStatusChangeItemColumn({ ...record, show_in_pdf: !record.show_in_pdf })
              }
              // disabled={record.default_key != 'qty' && record.default_key != ''}
            />
          ) : (
            <></>
          )
        },
      },
      {
        title: 'Active',
        dataIndex: 'is_active',
        className: 'drag-visible',
        width: '10%',
        render: (text, record) => {
          return record.show_active_toggle == 1 ? (
            <Switch
              checked={record.is_active}
              onChange={() =>
                this.onStatusChangeItemColumn({ ...record, is_active: !record.is_active })
              }
              // disabled={default_key_list.includes(record.default_key)}
            />
          ) : (
            <></>
          )
        },
      },
      {
        title: 'Actions',
        align: 'right',
        className: 'drag-visible',
        width: '30%',
        render: (text, record) => {
          return (
            <div className="flex justify-end">
              <span
                className="text-warning font-weight-bold font-size-14 ml-2"
                style={{ cursor: 'pointer' }}
                onClick={e => this.onEditItemField(record)}
              >
                <Button size="small" type="warning">
                  <i className="fa-solid fa-edit mx-1"></i>
                </Button>
              </span>

              {record.allow_delete == 1 ? (
                <span
                  className="text-danger font-weight-bold font-size-14 ml-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.deleteModalRef?.current?.showDeleteModal(record, true)}
                >
                  <Button size="small" type="danger">
                    <i className="fa fa-trash-o mx-1"></i>
                  </Button>
                </span>
              ) : (
                <span style={{ width: '30px' }} className="ml-2">
                  {' '}
                </span>
              )}
            </div>
          )
        },
      },
    ]
    const documentColumns = [
      {
        title: 'Label',
        dataIndex: 'label',
        width: '50%',
        key: 'id',
      },
      {
        title: 'Show in PDF',
        dataIndex: 'show_in_pdf',
        width: '10%',
        render: (text, record) => {
          return (
            <Switch
              checked={record.show_in_pdf}
              onChange={(checked, e) =>
                this.customHeaderStatusChange(
                  checked,
                  record.is_active,
                  record.is_required,
                  record.header_id,
                )
              }
            />
          )
        },
      },
      {
        title: 'Active',
        dataIndex: 'is_active',
        width: '10%',
        key: 'id',
        render: (text, data) => {
          return (
            <Switch
              checked={data.is_active}
              onChange={(checked, e) =>
                this.customHeaderStatusChange(
                  data.show_in_pdf,
                  checked,
                  data.is_required,
                  data.header_id,
                )
              }
            />
          )
        },
      },
      {
        title: 'Required',
        dataIndex: 'is_required',
        width: '10%',
        key: 'id',
        render: (text, data) => {
          return (
            <Switch
              checked={data.is_required}
              onChange={(checked, e) =>
                this.customHeaderStatusChange(
                  data.show_in_pdf,
                  data.is_active,
                  checked,
                  data.header_id,
                )
              }
            />
          )
        },
      },
      {
        title: 'Actions',
        key: 'id',
        width: '30%',
        align: 'right',
        render: (text, item) => (
          <div>
            <span
              className="text-warning font-weight-bold font-size-14 ml-2"
              style={{ cursor: 'pointer' }}
              onClick={e => this.showCustomHeader(item.header_id)}
            >
              <Button size="small" type="warning">
                <i className="fa-solid fa-edit mx-1"></i>
              </Button>
            </span>

            <span
              className="text-danger font-weight-bold font-size-14 ml-2"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                this.deleteModalRef?.current?.showDeleteModal(
                  {},
                  undefined,
                  undefined,
                  item.header_id,
                )
              }
            >
              <Button size="small" type="danger">
                <i className="fa fa-trash-o mx-1"></i>
              </Button>
            </span>
          </div>
        ),
      },
    ]
    const additionalChargesColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '50%',
        key: 'id',
        render: (text, item) => (
          <span>
            {text}
            <Tooltip
              className={`ml-2 ${item.type == -1 ? 'text-danger' : 'text-forest'}`}
              title={item.type == -1 ? 'Deduction' : 'Addition'}
            >
              ({item.type == -1 ? '-' : '+'})
            </Tooltip>
          </span>
        ),
      },
      {
        title: 'Default',
        dataIndex: 'is_default',
        key: 'id',
        width: '20%',
        render: (text, item) => (
          <Switch
            checked={item.is_default}
            style={{ color: 'blue' }}
            onChange={(checked, e) =>
              this.customAdditionalChargeStatusChange(checked, item.additional_charge_id)
            }
          />
        ),
      },
      {
        title: 'Actions',
        key: 'id',
        width: '30%',
        align: 'right',
        render: (text, item) => (
          <div>
            <span
              className="text-warning font-weight-bold font-size-14 ml-2"
              style={{ cursor: 'pointer' }}
              onClick={e => {
                this.showCustomAdditionalCharge(item.additional_charge_id)
              }}
            >
              <Button size="small" type="warning">
                <i className="fa-solid fa-edit mx-1"></i>
              </Button>
            </span>

            {!(item.name == 'Packaging Charges' || item.name == 'Delivery/ Shipping Charges') && (
              <span
                className="text-danger font-weight-bold font-size-14 ml-2"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  this.deleteModalRef?.current?.showDeleteModal(
                    {},
                    undefined,
                    undefined,
                    item.header_id,
                    item.additional_charge_id,
                  )
                }
              >
                <Button size="small" type="danger">
                  <i className="fa fa-trash-o mx-1"></i>
                </Button>
              </span>
            )}
          </div>
        ),
      },
    ]
    const partyColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '40%',
        key: 'id',
      },
      {
        title: 'Active',
        dataIndex: 'is_active',
        width: '20%',
        key: 'id',
        align: 'right',
        render: (text, item) => (
          <Switch
            checked={item.is_active}
            style={{ color: 'blue' }}
            onChange={(checked, e) => {
              this.partyFieldStatusChange({
                ...item,
                party_type: this.state.party_type,
                is_active: checked,
              })
            }}
          />
        ),
      },
      {
        title: 'Required',
        dataIndex: 'is_required',
        width: '20%',
        key: 'id',
        align: 'center',
        render: (text, item) => (
          <Switch
            checked={item.is_required}
            onChange={(checked, e) =>
              this.partyFieldStatusChange({
                ...item,
                party_type: this.state.party_type,

                is_required: checked,
              })
            }
          />
        ),
      },
      {
        title: 'Actions',
        key: 'id',
        width: '20%',
        align: 'right',
        render: (text, item) => (
          <div>
            <span
              className="text-warning font-weight-bold font-size-14 ml-2"
              style={{ cursor: 'pointer' }}
              onClick={e => this.onAddCustomerCustomField(item)}
            >
              <Button size="small" type="warning">
                <i className="fa-solid fa-edit mx-1"></i>
              </Button>
            </span>
            <span
              className="text-danger font-weight-bold font-size-14 ml-2"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                this.deleteModalRef?.current?.showDeleteModal(item, true, this.state.party_type)
              }
            >
              <Button size="small" type="danger">
                <i className="fa fa-trash-o mx-1"></i>
              </Button>
            </span>
          </div>
        ),
      },
    ]
    const companyColumns = [
      {
        title: 'Name',
        dataIndex: 'field_name',
        width: '50%',
        key: 'id',
      },
      {
        title: 'Active',
        dataIndex: 'is_active',
        width: '20%',
        key: 'id',
        render: (text, item) => (
          <Switch
            checked={item.is_active}
            style={{ color: 'blue' }}
            onChange={(checked, e) => {
              this.companyFieldChange({
                ...item,
                is_active: checked,
              })
            }}
          />
        ),
      },
      {
        title: 'Actions',
        key: 'id',
        width: '30%',
        align: 'right',
        render: (text, item) => (
          <div>
            <span
              className="text-warning font-weight-bold font-size-14 ml-2"
              style={{ cursor: 'pointer' }}
              onClick={e => this.onEditCompanyField(item)}
            >
              <Button size="small" type="warning">
                <i className="fa-solid fa-edit mx-1"></i>
              </Button>
            </span>

            <span
              className="text-danger font-weight-bold font-size-14 ml-2"
              style={{ cursor: 'pointer' }}
              onClick={() => this.deleteModalRef?.current?.showDeleteModal(item, 1)}
            >
              <Button size="small" type="danger">
                <i className="fa fa-trash-o mx-1"></i>
              </Button>
            </span>
          </div>
        ),
      },
    ]
    const onDragEnd = ({ active, over }) => {
      if (active.id != over?.id) {
        let dataSource = this.state.settingDataCustomColumns.custom_columns.slice()
        const activeIndex = dataSource.findIndex(i => i.index == active.id)
        const overIndex = dataSource.findIndex(i => i.index == over?.id)
        if (
          dataSource[activeIndex]?.allow_sorting == 0 ||
          dataSource[overIndex]?.allow_sorting == 0 ||
          overIndex == -1
        )
          return
        dataSource = arrayMove(dataSource, activeIndex, overIndex)
        this.setState(
          {
            settingDataCustomColumns: {
              ...this.state.settingDataCustomColumns,
              custom_columns: dataSource,
            },
          },
          async () => {
            await getAPI('v2/custom_fields', 'item/order', { columns: dataSource })
            this.onAdd()
          },
        )
      }
    }

    const renderItemCustomColumns = is_packing_list => {
      let itemCustomColumns = []
      if (is_packing_list) {
        itemCustomColumns = this.state.settingDataCustomColumns.custom_columns?.filter(i =>
          i.document_types?.includes('packing_list'),
        )

        if (itemCustomColumns?.length == 0) {
          getAPIData('doc/packing_list', 'get_packing_utils')
        }
      } else {
        itemCustomColumns = this.state.settingDataCustomColumns.custom_columns?.filter(
          i => !i.document_types?.includes('packing_list'),
        )
      }

      return (
        <>
          {itemCustomColumns != undefined && (
            <>
              <div className="flex justify-end items-center mb-2">
                <Button
                  type="link"
                  size="header"
                  onClick={() =>
                    store.get('paid') == 0
                      ? this.subscriptionPlanRef.current.openModal('custom_fields')
                      : this.onAddNewItemColumn()
                  }
                  className="font-weight-bold header-add-button"
                >
                  <i className="fa-sharp fa-regular fa-circle-plus mr-2"></i>
                  New Custom Field
                </Button>
              </div>
              <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext
                  items={itemCustomColumns.map(i => i.index)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    pagination={false}
                    dataSource={itemCustomColumns}
                    columns={itemColumns}
                    rowKey="index"
                    components={{
                      body: {
                        row: Row,
                      },
                    }}
                    footer={() => (
                      <>
                        <div className="">
                          <Button
                            type="link-2"
                            className="text-left arrow-transition"
                            onClick={() =>
                              store.get('paid') == 0
                                ? this.subscriptionPlanRef.current.openModal('custom_fields')
                                : this.onAddNewItemColumn()
                            }
                            block
                          >
                            <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                            <span className="font-weight-bold">New Custom Field</span>
                            <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                          </Button>
                        </div>
                      </>
                    )}
                  />
                  <CustomDeleteModal
                    ref={this.deleteModalRef}
                    onFinish={this.onStatusChangeItemColumn}
                  />
                </SortableContext>
              </DndContext>
            </>
          )}
        </>
      )
    }

    return (
      <div>
        <Drawer
          title={
            <>
              <div className="flex items-center">
                <div className="mr-auto">
                  <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                    {'Custom Fields'}
                  </span>
                </div>
              </div>
            </>
          }
          placement="right"
          width={isMobile ? '90%' : '60%'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          closable={() => this.setState({ visible: false, settingDataCustomColumns: '' })}
          onClose={() =>
            this.setState({ visible: false, settingDataCustomColumns: '' }, () =>
              this.props.onAPICalled(),
            )
          }
          open={this.state.visible}
          maskClosable={false}
          destroyOnClose={true}
          footer={
            <>
              <Button
                onClick={() => this.setState({ visible: false }, () => this.props.onAPICalled())}
                type="danger"
              >
                Close
              </Button>
            </>
          }
        >
          {/*<Card size="form" bordered={true} className="border-radius-small mb-4">*/}

          <div className="mb-4">
            <InfoComponent
              description="Only active fields will be shown in the documents."
              type="info"
              learnMore="link"
              learnMoreText="about Settlements"
            />
          </div>

          <Tabs
            defaultActiveKey={this.state.defaultActiveKey}
            onChange={this.onTabChange}
            destroyInactiveTabPane={true}
            key={this.state.defaultActiveKey}
          >
            {this.state.show_type != 'products' && (
              <TabPane tab="Document" key="1">
                <div className="flex justify-between items-center">
                  <Typography.Text
                    style={{ color: '#000', backgroundColor: '#ffe3ac' }}
                    className="p-1"
                  >
                    Note: Active fields will be shown in the Invoice.
                  </Typography.Text>
                  <Button
                    type="link"
                    size="header"
                    onClick={() =>
                      store.get('paid') == 0
                        ? this.subscriptionPlanRef.current.openModal('custom_fields')
                        : this.onAddNewCustomHeader()
                    }
                    className="font-weight-bold header-add-button"
                  >
                    <i className="fa-sharp fa-regular fa-circle-plus mr-2"></i>
                    New Custom Field
                  </Button>
                </div>
                <Table
                  pagination={false}
                  dataSource={this.state.custom_headers}
                  columns={documentColumns}
                  rowKey="index"
                  className="mt-2"
                  footer={() => (
                    <>
                      <div className="">
                        <Button
                          type="link-2"
                          className="text-left arrow-transition"
                          onClick={() =>
                            store.get('paid') == 0
                              ? this.subscriptionPlanRef.current.openModal('custom_fields')
                              : this.onAddNewCustomHeader()
                          }
                          block
                        >
                          <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                          <span className="font-weight-bold">New Custom Field</span>
                          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                        </Button>
                      </div>
                    </>
                  )}
                />
                <CustomDeleteModal ref={this.deleteModalRef} onFinish={this.onDeleteCustomHeader} />
              </TabPane>
            )}
            <TabPane tab="Items" key="2">
              {renderItemCustomColumns()}
            </TabPane>

            {(store.get('is_export') == 1 || this.props?.integrations?.packing_list == 1) && (
              <TabPane tab="Packing List" key="packing_list">
                {renderItemCustomColumns(true)}{' '}
              </TabPane>
            )}

            {this.state.show_type != 'products' && (
              <TabPane tab="Additional Charges" key="3">
                <div className="flex justify-between items-center">
                  <Typography.Text
                    style={{ color: '#000', backgroundColor: '#ffe3ac' }}
                    className="p-1"
                  >
                    Note: Active fields will be shown in the Invoice.
                  </Typography.Text>
                  <Button
                    type="link"
                    size="header"
                    onClick={() =>
                      store.get('paid') == 0
                        ? this.subscriptionPlanRef.current.openModal('custom_fields')
                        : this.onAddNewCustomAdditionalCharge()
                    }
                    className="font-weight-bold header-add-button"
                  >
                    <i className="fa-sharp fa-regular fa-circle-plus mr-2"></i>
                    New Custom Additional Charge
                  </Button>
                </div>
                <Table
                  className="mt-2"
                  pagination={false}
                  dataSource={this.state.custom_additional_charges}
                  columns={additionalChargesColumns}
                  footer={() => (
                    <>
                      <div className="center">
                        <Button
                          type="link-2"
                          className="text-left arrow-transition"
                          onClick={() =>
                            store.get('paid') == 0
                              ? this.subscriptionPlanRef.current.openModal('custom_fields')
                              : this.onAddNewCustomAdditionalCharge()
                          }
                          block
                        >
                          <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                          <span className="font-weight-bold">New Custom Additional Charge</span>
                          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                        </Button>
                      </div>
                    </>
                  )}
                />
                <CustomDeleteModal
                  ref={this.deleteModalRef}
                  onFinish={this.onDeleteCustomAdditionalCharge}
                />
                {/* <div className="center mt-4 mb-4">
                  <Button
                    type="dashed"
                    style={{ borderColor: 'blue' }}
                    onClick={() =>
                      store.get('paid') == 0
                        ? this.subscriptionPlanRef.current.openModal("ewaybill", 4)
                        : this.onAddNewCustomAdditionalCharge()
                    }
                    block
                    icon={<PlusOutlined className="font-weight-bold" style={{ color: 'blue' }} />}
                  >
                    <span className="font-weight-bold" style={{ color: 'blue' }}>
                      New Custom Additional Charge in Document
                    </span>
                  </Button>
                </div>*/}
              </TabPane>
            )}

            {this.state.show_type != 'products' && (
              <TabPane tab="Customer" key="4">
                <div className="flex justify-between items-center">
                  <Typography.Text
                    style={{ color: '#000', backgroundColor: '#ffe3ac' }}
                    className="p-1"
                  >
                    Note: Active fields will be shown customer form.
                  </Typography.Text>
                  <Button
                    type="link"
                    size="header"
                    onClick={() =>
                      store.get('paid') == 0
                        ? this.subscriptionPlanRef.current.openModal('custom_fields')
                        : this.onAddCustomerCustomField({ custom_field_id: -1 })
                    }
                    className="font-weight-bold header-add-button"
                  >
                    <i className="fa-sharp fa-regular fa-circle-plus mr-2"></i>
                    New Custom Field
                  </Button>
                </div>
                <Table
                  className="mt-2"
                  pagination={false}
                  dataSource={this.state.customer_custom_fields}
                  columns={partyColumns}
                  footer={() => (
                    <>
                      <div className="">
                        <Button
                          type="link-2"
                          className="text-left arrow-transition"
                          onClick={() =>
                            store.get('paid') == 0
                              ? this.subscriptionPlanRef.current.openModal('custom_fields')
                              : this.onAddCustomerCustomField({ custom_field_id: -1 })
                          }
                          block
                        >
                          <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                          <span className="font-weight-bold">New Custom Field</span>
                          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                        </Button>
                      </div>
                    </>
                  )}
                />
                <CustomDeleteModal
                  ref={this.deleteModalRef}
                  onFinish={this.partyFieldStatusChange}
                />
                {/*<div className="center mt-4 mb-4">
                  <Button
                    type="dashed"
                    style={{ borderColor: 'blue' }}
                    onClick={() =>
                      store.get('paid') == 0
                        ? this.subscriptionPlanRef.current.openModal('custom_fields')
                        : this.onAddCustomerCustomField({ custom_field_id: -1 })
                    }
                    block
                    icon={<PlusOutlined className="font-weight-bold" style={{ color: 'blue' }} />}
                  >
                    <span className="font-weight-bold" style={{ color: 'blue' }}>
                      New Customer Custom Field
                    </span>
                  </Button>
                </div>*/}
              </TabPane>
            )}

            {this.state.show_type != 'products' && (
              <TabPane tab="Vendor" key="5">
                <div className="flex justify-between items-center">
                  <Typography.Text
                    style={{ color: '#000', backgroundColor: '#ffe3ac' }}
                    className="p-1"
                  >
                    Note: Active fields will be shown vendor form.
                  </Typography.Text>
                  <Button
                    type="link"
                    size="header"
                    onClick={() =>
                      store.get('paid') == 0
                        ? this.subscriptionPlanRef.current.openModal('custom_fields')
                        : this.onAddCustomerCustomField({ custom_field_id: -1 })
                    }
                    className="font-weight-bold header-add-button"
                  >
                    <i className="fa-sharp fa-regular fa-circle-plus mr-2"></i>
                    New Custom Field
                  </Button>
                </div>
                <Table
                  className="mt-2"
                  pagination={false}
                  dataSource={this.state.customer_custom_fields}
                  columns={partyColumns}
                  footer={() => (
                    <>
                      <div className="">
                        <Button
                          type="link-2"
                          className="text-left arrow-transition"
                          onClick={() =>
                            store.get('paid') == 0
                              ? this.subscriptionPlanRef.current.openModal('custom_fields')
                              : this.onAddCustomerCustomField({ custom_field_id: -1 })
                          }
                          block
                        >
                          <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                          <span className="font-weight-bold">New Custom Field</span>
                          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                        </Button>
                      </div>
                    </>
                  )}
                />
                <CustomDeleteModal
                  ref={this.deleteModalRef}
                  onFinish={this.partyFieldStatusChange}
                />
                {/*<div className="center mt-4 mb-4">
                  <Button
                    type="dashed"
                    style={{ borderColor: 'blue' }}
                    onClick={() =>
                      store.get('paid') == 0
                        ? this.subscriptionPlanRef.current.openModal("ewaybill", 4)
                        : this.onAddCustomerCustomField({ custom_field_id: -1 })
                    }
                    block
                    icon={<PlusOutlined className="font-weight-bold" style={{ color: 'blue' }} />}
                  >
                    <span className="font-weight-bold" style={{ color: 'blue' }}>
                      New Vendor Custom Field
                    </span>
                  </Button>
                </div>*/}
              </TabPane>
            )}
            {this.state.show_type != 'products' && (
              <TabPane tab="Company" key="6">
                <div className="flex justify-between items-center">
                  <Typography.Text
                    style={{ color: '#000', backgroundColor: '#ffe3ac' }}
                    className="p-1"
                  >
                    Note: Active fields will be shown company form.
                  </Typography.Text>
                  <Button
                    type="link"
                    size="header"
                    onClick={() =>
                      store.get('paid') == 0
                        ? this.subscriptionPlanRef.current.openModal('custom_fields')
                        : this.onAddCompanyField()
                    }
                    className="font-weight-bold header-add-button"
                  >
                    <i className="fa-sharp fa-regular fa-circle-plus mr-2"></i>
                    New Custom Field
                  </Button>
                </div>
                <Table
                  pagination={false}
                  dataSource={this.state.company_custom_fields}
                  columns={companyColumns}
                  className="mt-2"
                  footer={() => (
                    <>
                      <div className="">
                        <Button
                          type="link-2"
                          className="text-left arrow-transition"
                          onClick={() =>
                            store.get('paid') == 0
                              ? this.subscriptionPlanRef.current.openModal('custom_fields')
                              : this.onAddCompanyField()
                          }
                          block
                        >
                          <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                          <span className="font-weight-bold">New Custom Field</span>
                          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                        </Button>
                      </div>
                    </>
                  )}
                />
                <CustomDeleteModal ref={this.deleteModalRef} onFinish={this.companyFieldChange} />
                {/*<div className="center mt-4 mb-4">
                  <Button
                    type="dashed"
                    style={{ borderColor: 'blue' }}
                    onClick={() =>
                      store.get('paid') == 0
                        ? this.subscriptionPlanRef.current.openModal("ewaybill", 4)
                        : this.onAddCompanyField()
                    }
                    block
                    icon={<PlusOutlined className="font-weight-bold" style={{ color: 'blue' }} />}
                  >
                    <span className="font-weight-bold" style={{ color: 'blue' }}>
                      New company Custom Field
                    </span>
                  </Button>
                </div>*/}
              </TabPane>
            )}
          </Tabs>

          {/*</Card>*/}
          {this.state.top_custom_fields.length > 0 && this.state.loading == false && (
            <div className="mt-4">
              <SectionHeader title="Recommended Fields" />
              {this.state.top_custom_fields.map((item, index) => (
                <Tag
                  icon={<i className="fa fa-plus mr-2 text-primary" />}
                  className="cursor-pointer tag-large bg-white"
                  onClick={() => this.add_top_field(item)}
                >
                  {item.name}
                </Tag>
              ))}
            </div>
          )}

          <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
          <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
          <Drawer
            title={
              <>
                <div className="flex justify-between items-center">
                  <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                    Add/Edit Custom Fields
                  </span>
                  <Button
                    onClick={() => this.formRef3.current.submit()}
                    type="primary"
                    className="mr-2 arrow-transition"
                    loading={this.state.loading}
                  >
                    Save
                    <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                  </Button>
                </div>
              </>
            }
            placement="right"
            width={isMobile ? '90%' : '40%'}
            closable={() =>
              this.setState({
                show_custom_header_form: false,
                selected_header: {},
                defaultActiveKey: '1',
                activeKey: '1',
              })
            }
            onClose={() =>
              this.setState({
                show_custom_header_form: false,
                selected_header: {},
                defaultActiveKey: '1',
                activeKey: '1',
              })
            }
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            open={this.state.show_custom_header_form}
            destroyOnClose={true}
            footer={
              <>
                <Button
                  onClick={() => this.formRef3.current.submit()}
                  type="primary"
                  className="mr-2 arrow-transition "
                  loading={this.state.loading}
                >
                  Save
                  <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                </Button>

                <Button
                  onClick={() =>
                    this.setState({ show_custom_header_form: false, selected_header: {} })
                  }
                  type="danger"
                >
                  Close
                </Button>
              </>
            }
          >
            <Card size="form" bordered={true} className="border-radius-small mb-4 pb-5">
              <Form
                {...formItemLayout}
                labelAlign="left"
                ref={this.formRef3}
                name="customHeaderForm"
                onFinish={values =>
                  this.onCustomHeaderFinish({
                    ...values,
                    is_active: this.state.selected_header.is_active,
                    is_delete: this.state.selected_header.is_delete,
                    header_id: this.state.selected_header.header_id,
                    show_in_pdf: this.state.selected_header.show_in_pdf,
                  })
                }
                initialValues={this.state.selected_header}
                size="default"
              >
                <Form.Item
                  name={'label'}
                  label="Label"
                  rules={[{ required: true, message: 'Please fill the label' }]}
                >
                  <Input type="text" placeholder="Enter Label" />
                </Form.Item>
                <Form.Item
                  name={'field_type'}
                  label="Type"
                  rules={[{ required: true, message: 'Please fill the Field Name' }]}
                >
                  <Select
                    placeholder="Select Type"
                    onChange={value => {
                      this.formRef3.current.setFieldsValue({
                        placeholder: '',
                      })
                      if (value === 'date') {
                        this.setState({
                          dateFlag: true,
                          custom_header_date: dayjs().format(this.dateFormat),
                        })
                      } else {
                        this.setState({
                          dateFlag: false,
                        })
                      }
                    }}
                  >
                    <Option value="text">Text</Option>
                    <Option value="number">Number</Option>
                    <Option value="date">Date</Option>
                    <Option value="bigtext">Text Area</Option>
                  </Select>
                </Form.Item>

                <Form.Item name={'placeholder'} label="Default Value">
                  {this.state.dateFlag || this.state.selected_header.field_type === 'date' ? (
                    <DatePicker
                      format={this.dateFormat}
                      allowClear={false}
                      value={dayjs(
                        this.state.custom_header_date !== '0' ||
                          this.state.custom_header_date !== undefined
                          ? this.state.custom_header_date
                          : dayjs(),
                        this.dateFormat,
                      )}
                      size="default"
                      style={{
                        borderRadius: '5px',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      onChange={(date, dateString) => {
                        this.setState({
                          // custom_header_date: dateString,
                          custom_header_date: dayjs(dateString, this.dateFormat),
                        })
                      }}
                    />
                  ) : (
                    <TextArea placeholder="Default Value" />
                  )}
                </Form.Item>
                <Form.Item
                  name={'document_types'}
                  label="Document Types"
                  extra={
                    <span className="text-gray-500">
                      Note: This field will be visible only in selected document types. If you
                      select All, it will be visible for all document types. You can select multiple
                      document types.
                    </span>
                  }
                >
                  <Select
                    mode="multiple"
                    placeholder="Select Document Types"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={value => {
                      if (value[value.length - 1] == 'All') {
                        this.formRef3.current.setFieldsValue({
                          document_types: ['All'],
                        })
                        return
                      }
                      if (value.includes('All') && value.length > 1) {
                        value = value.filter(item => item !== 'All')
                        this.formRef3.current.setFieldsValue({
                          document_types: value,
                        })
                        return
                      }
                      if (value.length == 9) {
                        this.formRef3.current.setFieldsValue({
                          document_types: ['All'],
                        })
                        return
                      }
                    }}
                  >
                    {this.document_types.map((item, index) => (
                      <Option key={index} value={item.document_type}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Card>
          </Drawer>
          <Drawer
            title={
              <>
                <div className="flex justify-between items-center">
                  <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                    Add/Edit Custom Fields
                  </span>
                  <Button
                    onClick={() => this.item_ref.current.submit()}
                    type="primary"
                    className="mr-2 arrow-transition"
                    loading={this.state.loading}
                  >
                    Save
                    <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                  </Button>
                </div>
              </>
            }
            placement="right"
            width={isMobile ? '90%' : '40%'}
            closable={() =>
              this.setState({
                show_item_custom_fields_form: false,
              })
            }
            onClose={() =>
              this.setState({
                show_item_custom_fields_form: false,
              })
            }
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            open={this.state.show_item_custom_fields_form}
            destroyOnClose={true}
            footer={
              <>
                <Button
                  onClick={() => this.item_ref.current.submit()}
                  type="primary"
                  className="mr-2 arrow-transition"
                  loading={this.state.loading}
                >
                  Save
                  <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                </Button>

                <Button
                  onClick={() => this.setState({ show_item_custom_fields_form: false })}
                  type="danger"
                >
                  Close
                </Button>
              </>
            }
          >
            <Card size="form" bordered={true} className="border-radius-small mb-4">
              <Form
                {...formItemLayout}
                labelAlign="left"
                ref={this.item_ref}
                name="customHeaderForm"
                onFinish={values => {
                  this.onItemCustomFieldFinish({
                    ...values,
                    is_active: this.state.selected_item_column.is_active,
                    is_delete: this.state.selected_item_column.is_delete,
                    id: this.state.selected_item_column.id,
                    default_value: values.default_value,
                    is_default: this.state.selected_item_column.default_key != '',
                    default_key: this.state.selected_item_column.default_key,
                    show_in_pdf: this.state.selected_item_column.show_in_pdf,
                  })
                }}
                initialValues={this.state.selected_item_column}
                size="default"
              >
                <Form.Item
                  name={'name'}
                  label="Field Name"
                  rules={[{ required: true, message: 'Please fill the Name' }]}
                >
                  <Input placeholder="Field Name" />
                </Form.Item>
                {this.props.integrations.is_beta &&
                this.state.selected_item_column?.default_key == '' &&
                !this.state.is_packing_list ? (
                  <Form.Item
                    name={'document_types'}
                    label="Document Types"
                    extra={
                      <span className="text-gray-500">
                        Note: This field will be visible only in selected document types. If you
                        select All, it will be visible for all document types. You can select
                        multiple document types.
                      </span>
                    }
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Document Types"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={value => {
                        if (value[value.length - 1] == 'All') {
                          this.item_ref.current.setFieldsValue({
                            document_types: ['All'],
                          })
                          return
                        }
                        if (value.includes('All') && value.length > 1) {
                          value = value.filter(item => item !== 'All')
                          this.item_ref.current.setFieldsValue({
                            document_types: value,
                          })
                          return
                        }
                        if (value.length == 9) {
                          this.item_ref.current.setFieldsValue({
                            document_types: ['All'],
                          })
                          return
                        }
                      }}
                    >
                      {this.document_types.map(
                        (item, index) =>
                          item.document_type != 'packing_list' && (
                            <Option key={index} value={item.document_type}>
                              {item.name}
                            </Option>
                          ),
                      )}
                    </Select>
                  </Form.Item>
                ) : (
                  <></>
                )}

                <Form.Item
                  name="field_type"
                  label="Type"
                  rules={[{ required: true, message: 'Please fill the Type' }]}
                >
                  <Select
                    placeholder="Select Type"
                    defaultValue={'text'}
                    disabled={
                      this.state.selected_item_column?.default_key != '' &&
                      !['qty', 'total_amount', 'net_amount'].includes(
                        this.state.selected_item_column?.default_key,
                      )
                    }
                    onChange={e => {
                      this.setState({
                        hide_default_values: e == 'formula',
                        linked_fields: [
                          { id: undefined, operator: undefined },
                          { id: undefined, operator: undefined },
                        ],
                        changed_custom_field_type: e,
                      })
                    }}
                  >
                    {!['qty', 'total_amount', 'net_amount'].includes(
                      this.state.selected_item_column?.default_key,
                    ) && <Option value="text">Text</Option>}
                    <Option value="number">Number</Option>
                    {this.state.selected_item_column?.default_key == '' && (
                      <Option value="date">Date</Option>
                    )}
                    {/* <Option value="select">Select</Option>
                     */}
                    {/* {this.state.selected_item_column?.default_key == '' && (
                      <Option value="datetime">Date Time</Option>
                    )} */}
                    {this.state.selected_item_column?.default_key == '' && (
                      <Option value="bigtext">Text Area</Option>
                    )}
                    {this.state.selected_item_column?.default_key == '' && (
                      <Option value="currency">Currency</Option>
                    )}
                    {this.props.integrations.custom_columns_link &&
                      !this.state.is_packing_list &&
                      (this.state.selected_item_column?.default_key == 'qty' ||
                        this.state.selected_item_column?.default_key == '' ||
                        (this.state.selected_item_column?.default_key == 'net_amount' &&
                          !this.state.is_total_amount_linked) ||
                        (this.state.selected_item_column?.default_key == 'total_amount' &&
                          !this.state.is_net_amount_linked)) && (
                        <Option value="formula">Formula</Option>
                      )}
                  </Select>
                </Form.Item>

                {this.state.hide_default_values == 1 &&
                  this.props.integrations?.custom_columns_link == 1 && (
                    <>
                      <span className="label mt-3 ">Expression:</span>
                      <MentionsInput
                        style={customStyle}
                        className="mention-input"
                        value={this.state.new_expression}
                        onChange={e => {
                          this.onChangeExpression(e.target.value, e)
                        }}
                        placeholder='Enter expression like "qty * rate". Write "@" to include the columns'
                      >
                        <Mention
                          trigger={'@'}
                          displayTransform={(id, username) => `${username}`}
                          data={this.state.suggestion_columns}
                          onAdd={(...args) => {}}
                          className="text-white bg-white"
                          appendSpaceOnAdd
                        />
                      </MentionsInput>
                    </>
                  )}

                <Form.Item
                  name={'applicable_for'}
                  label="Applicable For"
                  hidden={
                    this.state.selected_item_column?.default_key != '' ||
                    this.props.integrations?.variant_custom_columns == 0 ||
                    this.state.is_packing_list
                  }
                  extra={
                    <span className="text-gray-500">
                      Note: if variant is selected, this field will be shown only for the variants
                      and the products which do not have variants and similar for the batches.
                    </span>
                  }
                >
                  <Radio.Group
                    // defaultValue={this.state.selected_item_column?.applicable_for}
                    name="radiogroup"
                    options={applicable_types}
                    className="mt-2"
                  />
                </Form.Item>
              </Form>
              <Modal
                title={
                  <>
                    <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>
                    <span className="text-gray-500">Warning</span>{' '}
                  </>
                }
                centered
                okText="Yes"
                cancelText="No"
                visible={this.state.formula_warning}
                onCancel={() =>
                  this.setState({
                    formula_warning: false,
                    change_formula: false,
                  })
                }
                destroyOnClose
                closeIcon={<i className="fa-solid fa-xmark"></i>}
                onOk={async () => {
                  this.setState({
                    formula_warning: false,
                    custom_field_type: this.state.changed_custom_field_type,
                    change_formula: true,
                  })
                  // await this.onStatusChangeItemColumn(this.state.custom_field_values)
                }}
              >
                <span className="font-size-16">
                  Changing field type from formula to other type will delete the formula and old
                  document calculations will be affected. Are you sure you want to change the field
                  type?
                </span>
              </Modal>
            </Card>
          </Drawer>

          <Drawer
            title={
              <>
                <div className="flex justify-between items-center">
                  <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                    Add/Edit Additional Charges
                  </span>
                  <Button
                    onClick={() => this.formRef4.current.submit()}
                    type="primary"
                    className="mr-2 arrow-transition"
                    loading={this.state.loading}
                  >
                    Save
                    <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                  </Button>
                </div>
              </>
            }
            placement="right"
            width={isMobile ? '90%' : '40%'}
            closable={() =>
              this.setState({
                show_custom_additional_charge_form: false,
                selected_additional_charge: {},
                defaultActiveKey: '1',
                activeKey: '1',
              })
            }
            onClose={() =>
              this.setState({
                show_custom_additional_charge_form: false,
                selected_additional_charge: {},
              })
            }
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            open={this.state.show_custom_additional_charge_form}
            destroyOnClose={true}
            footer={
              <>
                <Button
                  onClick={() => this.formRef4.current.submit()}
                  type="primary"
                  className="mr-2 arrow-transition"
                  loading={this.state.loading}
                >
                  Save
                  <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                </Button>

                <Button
                  onClick={() => this.setState({ show_custom_additional_charge_form: false })}
                  type="danger"
                >
                  Close
                </Button>
              </>
            }
          >
            <Card size="form" bordered={true} className="border-radius-small mb-4">
              <Form
                {...formItemLayout}
                labelAlign="left"
                ref={this.formRef4}
                name="customAdditionalChargeForm"
                onFinish={this.onCustomAdditionalChargeFinish}
                initialValues={this.state.selected_additional_charge}
                size="default"
              >
                <Form.Item
                  name={'name'}
                  label="Additional Charge Name"
                  rules={[{ required: true, message: 'Please fill the Name' }]}
                >
                  <Input
                    placeholder="Additional Charge Name"
                    disabled={
                      this.state.selected_additional_charge?.name == 'Delivery/ Shipping Charges' ||
                      this.state.selected_additional_charge?.name == 'Packaging Charges'
                    }
                  />
                </Form.Item>

                <Form.Item
                  name={'type'}
                  label={
                    <>
                      Type <NewBadge />
                    </>
                  }
                  required
                >
                  <Select
                    placeholder="Select Type"
                    defaultValue={this.state.charge_type}
                    onChange={e => this.setState({ charge_type: e })}
                  >
                    <Option value={1}>Addition</Option>
                    <Option value={-1}>Deduction</Option>
                  </Select>
                </Form.Item>

                {this.state.charge_type == 1 && (
                  <Form.Item name={'sac_code'} label="SAC Code">
                    <Input placeholder="SAC Code" />
                  </Form.Item>
                )}
                <Form.Item name={'additional_charge_id'} label="Additional Charge ID" hidden>
                  <Input placeholder="additional_charge_id" />
                </Form.Item>

                <Form.Item name={'is_default'} label="is Default" hidden>
                  <Input placeholder="is_default" />
                </Form.Item>

                <Form.Item
                  name="tax"
                  label="Tax %"
                  hidden={this.props.company_details?.gstin?.length != 15}
                >
                  <Select
                    showSearch
                    className="font-weight-bold"
                    placeholder="Select Tax"
                    suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (
                        option.children.props.children[0].props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }}
                  >
                    {this.props.tax_rates.map((e, i) => (
                      <Option key={i} value={e}>
                        <div className="flex">
                          <span
                            className="font-weight-bold"
                            style={{
                              width: '30px',
                            }}
                          >
                            {e}
                          </span>
                          <span className="font-size-14">
                            ({e / 2}% CGST & {e / 2}% SGST, {e}% IGST)
                          </span>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name={'amount'} label="Amount">
                  <Input
                    type="number"
                    placeholder="amount"
                    addonAfter={
                      this.props.company_details.gstin.length == 15 && (
                        <Select
                          placeholder="Select With Tax"
                          value={this.state.charge_with_tax}
                          onChange={e => this.setState({ charge_with_tax: e })}
                        >
                          <Option value={1}>With Tax</Option>
                          <Option value={0}>Without Tax</Option>
                        </Select>
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={'document_types'}
                  label="Document Types"
                  extra={
                    <span className="text-gray-500">
                      Note: This field will be visible only in selected document types. If you
                      select All, it will be visible for all document types. You can select multiple
                      document types.
                    </span>
                  }
                >
                  <Select
                    mode="multiple"
                    placeholder="Select Document Types"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={value => {
                      if (value[value.length - 1] == 'All') {
                        this.formRef4?.current?.setFieldsValue({
                          document_types: ['All'],
                        })
                        return
                      }
                      if (value.includes('All') && value.length > 1) {
                        value = value.filter(item => item !== 'All')
                        this.formRef4?.current?.setFieldsValue({
                          document_types: value,
                        })
                        return
                      }
                      if (value.length == 9) {
                        this.formRef4?.current?.setFieldsValue({
                          document_types: ['All'],
                        })
                        return
                      }
                    }}
                  >
                    {this.document_types.map((item, index) => (
                      <Option key={index} value={item.document_type}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Charge Applied below Amount"
                  name={'charge_amount'}
                  className=""
                  hidden
                >
                  <InputNumber placeholder="Amount" />
                </Form.Item>

                <Form.Item name={'is_delete'} label="is Delete" hidden>
                  <Input placeholder="is_delete" />
                </Form.Item>
              </Form>
            </Card>
          </Drawer>

          <Drawer
            title={
              <>
                <div className="flex justify-between items-center">
                  <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                    {this.state.party_type == 'customer'
                      ? 'Add/Edit Customer Fields'
                      : 'Add/Edit Vendor Fields'}
                  </span>
                  <Button
                    onClick={() => this.partyFormRef.current.submit()}
                    type="primary"
                    className="mr-2 arrow-transition"
                    loading={this.state.loading}
                  >
                    Save
                    <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                  </Button>
                </div>
              </>
            }
            placement="right"
            width={isMobile ? '90%' : '40%'}
            closable={() =>
              this.setState({
                show_party_Fields_drawer: false,
              })
            }
            onClose={() =>
              this.setState({
                show_party_Fields_drawer: false,
              })
            }
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            open={this.state.show_party_Fields_drawer}
            destroyOnClose={true}
            footer={
              <>
                <Button
                  onClick={() => this.partyFormRef.current.submit()}
                  type="primary"
                  className="mr-2 arrow-transition"
                  loading={this.state.loading}
                >
                  Save
                  <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                </Button>

                <Button
                  onClick={() => this.setState({ show_party_Fields_drawer: false })}
                  type="danger"
                >
                  Close
                </Button>
              </>
            }
          >
            <Card size="form" bordered={true} className="border-radius-small mb-4">
              <Form
                {...formItemLayout}
                labelAlign="left"
                ref={this.partyFormRef}
                name="customAdditionalChargeForm"
                onFinish={this.onPartyFieldsFinish}
                initialValues={this.state.selected_party_field}
                size="default"
              >
                <Form.Item
                  name={'name'}
                  label="Field Name"
                  rules={[{ required: true, message: 'Please fill the Field Name' }]}
                >
                  <Input placeholder="Field Name" />
                </Form.Item>
                <Form.Item
                  name={'field_type'}
                  label="Type"
                  rules={[{ required: true, message: 'Please fill the Field Name' }]}
                >
                  <Select placeholder="Select Type">
                    <Option value="text">Text</Option>
                    <Option value="number">Number</Option>
                    <Option value="date">Date</Option>
                    <Option value="bigtext">Text Area</Option>
                    <Option value="latlong">Latitude and Longitude</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Card>
          </Drawer>
          <Drawer
            title={
              <>
                <div className="flex justify-between items-center">
                  <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                    Add/Edit Company Field
                  </span>
                  <Button
                    onClick={() => this.company_form_ref.current.submit()}
                    type="primary"
                    className="mr-2 arrow-transition"
                    loading={this.state.loading}
                  >
                    Save
                    <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                  </Button>
                </div>
              </>
            }
            placement="right"
            width={isMobile ? '90%' : '40%'}
            closable={() =>
              this.setState({
                show_company_field_drawer: false,
              })
            }
            onClose={() =>
              this.setState({
                show_company_field_drawer: false,
              })
            }
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            visible={this.state.show_company_field_drawer}
            destroyOnClose={true}
            footer={
              <>
                <Button
                  onClick={() => this.company_form_ref.current.submit()}
                  type="primary"
                  className="mr-2 arrow-transition"
                  loading={this.state.loading}
                >
                  Save
                  <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                </Button>

                <Button
                  onClick={() => this.setState({ show_company_field_drawer: false })}
                  type="danger"
                >
                  Close
                </Button>
              </>
            }
          >
            <Card size="form" bordered={true} className="border-radius-small mb-4">
              <Form
                {...formItemLayout}
                labelAlign="left"
                ref={this.company_form_ref}
                name="companyFieldsForm"
                onFinish={values =>
                  this.companyFieldChange({ ...this.state.selectedCompanyField, ...values })
                }
                initialValues={this.state.selectedCompanyField}
                size="default"
              >
                <Form.Item
                  name={'field_name'}
                  label="Field Name"
                  rules={[{ required: true, message: 'Please fill the Field Name' }]}
                >
                  <Input placeholder="Field Name" />
                </Form.Item>
              </Form>
            </Card>
          </Drawer>
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    integrations: state.permissions.integrations,
    tax_rates: state.document.tax_rates,
    company_details: state.permissions.company_details,
    top_fields: state.document.top_custom_fields,
  }
}
export default connect(mapStateToProps)(CustomColumnsForm)
