import { Modal, Table } from 'antd'

export default function SelectBatchConvert({ products, changeBatchQty, open, onClose }) {
  let columns = [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span className="font-weight-bold">{record.product_name + ' ' + record.variant_name}</span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              changeBatchQty(record, 1)
            }}
            className="text-primary font-weight-bold"
          >
            Select
          </a>
        )
      },
    },
  ]
  return (
    <Modal
      title="Select Batches / Serial Numbers"
      open={open}
      onCancel={() => onClose()}
      footer={null}
      centered
    >
      <div>
        <Table
          dataSource={products.filter(
            product =>
              (product.has_batches == 1 && product.batch_id == 0) ||
              (product.has_batches == 2 && product?.serial_numbers?.length == 0),
          )}
          columns={columns}
          pagination={false}
        />
      </div>
    </Modal>
  )
}
