import { Image, Text, View } from '@react-pdf/renderer'
import React, { Fragment } from 'react'
import { PlaceOfSupply_2 } from '../InvoicePlaceOfSupply'
import { getStateName } from '../export_condition'
import { styles as getStyles } from './styles'
import { dispatch_to_doc_types } from '../utils'

const CompanyDetails = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  var data = invoice.gst_details.id == undefined ? invoice.company : invoice.gst_details
  var tax_id_label = invoice.company.tax_id_label
  let export_labels_1 = [
    {
      conversion_factor: 'Conversion Rate',
      is_show: invoice.is_export || invoice.is_multi_currency,
    },
    {
      currency_code: 'Currency',
      is_show: invoice.is_export || invoice.is_multi_currency,
    },
    {
      export_type: 'Export Type',
      is_show: invoice.is_export,
    },

    { name: 'Country Name', is_show: invoice.is_export },
    { shipping_date: 'Shipping Bill Date', is_show: invoice.is_export },
    { shipping_bill_number: 'Shipping bill #', is_show: invoice.is_export },

    { shipping_port_code: 'Shipping Port Code', is_show: invoice.is_export },
  ]
  invoice.invoice_settings.show_conversion_factor != 1 && export_labels_1.shift()

  try {
    if (invoice.is_multi_currency == 1) {
      export_labels_1 = export_labels_1.filter(item => {
        return (
          invoice.export_details[0][Object.keys(item)[0]] != '' &&
          invoice.export_details[0][Object.keys(item)[0]] != null &&
          item.is_show == true
        )
      })
    }
    if (invoice.company.gstin.length != 15) {
      export_labels_1 = export_labels_1.filter(item => Object.keys(item)[0] != 'export_type')
    }

    if (
      (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
      invoice.invoice_settings.show_both_currencies != true
    ) {
      export_labels_1 = export_labels_1.filter(item => {
        return (
          invoice.export_details[0][Object.keys(item)[0]] != '' &&
          invoice.export_details[0][Object.keys(item)[0]] != null &&
          item.is_show == true
        )
      })
    }
  } catch (e) {
    console.log(e)
  }

  var data = invoice.gst_details.id == undefined ? invoice.company : invoice.gst_details
  return (
    <View fixed={invoice.invoice_settings.repeat_header == 1}>
      <View style={styles.company_column}>
        <View style={styles.company_blockC}>
          {
            <View style={[styles.company_blockCA, styles.company_borderRight]}>
              <View style={styles.company_blockC}>
                {invoice.company.logo != null && invoice.company.logo != '' && (
                  <View>
                    <Image
                      style={styles.company_logo}
                      src={{
                        uri: invoice.company.logo,
                        method: 'GET',
                        headers: { 'Cache-Control': 'no-cache' },
                      }}
                      crossorigin="anonymous"
                    />
                  </View>
                )}
                <View style={styles.company_blockC2}>
                  <Text
                    style={[
                      data.company_name.length > 35
                        ? {
                            fontSize:
                              (9.9 * 30) / data.company_name.length >= 8
                                ? (9.9 * 25) / data.company_name.length
                                : 9,
                          }
                        : styles.company_large,
                      styles.company_bold,
                    ]}
                  >
                    {data.company_name}
                  </Text>
                  {data.gstin.length == 15 && (
                    <>
                      <Text>
                        <Text style={styles.company_label}>{tax_id_label}: </Text>
                        <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                          {data.gstin}
                        </Text>
                      </Text>
                    </>
                  )}
                  {data.pan_number != '' && (
                    <>
                      <Text>
                        <Text style={styles.company_label}>PAN: </Text>
                        <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                          {data.pan_number}
                        </Text>
                      </Text>
                    </>
                  )}
                  {'custom_fields' in data && data.custom_fields.length > 0 && (
                    <>
                      {data.custom_fields.map((field, index) => {
                        if (field.value != '' && field.value != null && field.value != undefined) {
                          return (
                            <Text key={index}>
                              <Text style={styles.company_label}>{field.field_name}: </Text>
                              <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                                {field.value}
                              </Text>
                            </Text>
                          )
                        }
                      })}
                    </>
                  )}
                  <Text style={styles.company_address}>
                    {data.address_1} {data.address_2 != '' && ', ' + data.address_2}
                  </Text>
                  <Text style={styles.company_address}>
                    {data.city == ''
                      ? ''
                      : data.city +
                        (data.state == '' ||
                        data.state == undefined ||
                        data.state == null ||
                        data.state == '97-OTHER TERRITORY' ||
                        data.state == '97-OTHERTERRITORY' ||
                        data.state == 'OTHERTERRITORY'
                          ? ''
                          : ',')}{' '}
                    {data.state == '' ||
                    data.state == '97-OTHER TERRITORY' ||
                    data.state == '97-OTHERTERRITORY' ||
                    data.state == 'OTHERTERRITORY'
                      ? ''
                      : getStateName(data.state) +
                        (data.pincode == '' || data.pincode == undefined || data.pincode == null
                          ? ''
                          : ', ')}
                    {invoice.is_export == 1 && `${data.country}${data.pincode != '' ? ', ' : ''}`}
                    {data.pincode}
                  </Text>
                  {/*<Text style={styles.company_text}>
                  {data.city == ''
                    ? ''
                    : data.city +
                      (data.pincode == '' || data.pincode == undefined || data.pincode == null
                        ? ''
                        : ',')}{' '}
                  {data.pincode}{' '}
                </Text>*/}
                  <Text>
                    {data.mobile != '' && data.mobile != 'None' && (
                      <>
                        <Text style={styles.company_label}>Mobile: </Text>
                        <Text style={styles.company_invoiceDate}>
                          {`+${data.dial_code} ${data.mobile}`}

                          {data.alt_contact == '' ? '   ' : ', '}
                          {data.alt_contact}
                          {data.alt_contact != '' && '   '}
                        </Text>
                      </>
                    )}
                  </Text>
                  <Text>
                    {data.email != '' && (
                      <>
                        <Text style={styles.company_label}>Email: </Text>
                        <Text style={styles.company_invoiceDate}>{data.email}</Text>
                      </>
                    )}
                  </Text>

                  {data.website != '' && (
                    <>
                      <Text>
                        <Text style={styles.company_label}>Website: </Text>
                        <Text style={styles.company_invoiceDate}>{data.website}</Text>
                      </Text>
                    </>
                  )}
                </View>
              </View>
            </View>
          }

          <View style={[styles.company_blockCB, styles.company_borderRight]}>
            {invoice.customer.name != null && invoice.customer.name != '' && (
              <View style={styles.company_block}>
                {invoice.document_type == 'purchases' ||
                invoice.document_type == 'purchase_orders' ||
                invoice.document_type == 'purchase_returns' ? (
                  <Text
                    style={styles.company_label}
                  >{`${invoice.invoice_settings.labels.vendor_details}:`}</Text>
                ) : (
                  <Text
                    style={styles.company_label}
                  >{`${invoice.invoice_settings.labels.customer_details}:`}</Text>
                )}
                {invoice.customer.name != invoice.customer.company_name ? (
                  <>
                    <Text style={[styles.company_text, styles.company_bold]}>
                      {invoice.customer.name}
                    </Text>
                    <Text style={[styles.company_text, styles.company_bold]}>
                      {invoice.customer.company_name}
                    </Text>
                  </>
                ) : (
                  <Text style={[styles.company_text, styles.company_bold]}>
                    {invoice.customer.company_name}
                  </Text>
                )}

                {invoice.customer.gstin != null && invoice.customer.gstin != '' && (
                  <View style={styles.company_row}>
                    <Text style={styles.company_labels}>{tax_id_label}: </Text>
                    <Text style={styles.company_info}>{invoice.customer.gstin}</Text>
                  </View>
                )}

                <PlaceOfSupply_2
                  invoice={invoice}
                  styles={{
                    block: styles.company_row,
                    title: styles.company_labels,
                    text: styles.company_info,
                  }}
                  in_new_line={false}
                />

                {invoice.customer.billing != undefined &&
                  invoice.customer.billing.address_1 != null &&
                  invoice.customer.billing.address_1 != '' && (
                    <>
                      {invoice.document_type != 'purchases' &&
                        invoice.document_type != 'purchase_orders' && (
                          <Text
                            style={styles.company_label}
                          >{`${invoice.invoice_settings.labels.bill_to}:`}</Text>
                        )}
                      <Text style={styles.company_text}>{invoice.customer.billing.address_1}</Text>
                      <Text style={styles.company_text}>{invoice.customer.billing.address_2}</Text>
                      <Text style={styles.company_text}>
                        {invoice.customer.billing.city != '' && invoice.customer.billing.city}
                        {invoice.is_export != 1 &&
                          invoice.customer.billing.state != '' &&
                          invoice.customer.billing.state != undefined &&
                          invoice.customer.billing.state != null &&
                          (getStateName(invoice.customer.billing.state) != 'OTHERTERRITORY' ||
                            getStateName(invoice.customer.billing.state) != '97-OTHERTERRITORY' ||
                            getStateName(invoice.customer.billing.state) != 'OTHER TERRITORY') &&
                          (invoice.customer.billing.city != '' ? ', ' : '') +
                            getStateName(invoice.customer.billing.state)}
                        {invoice.is_export == 1 &&
                          invoice.customer.billing.country &&
                          invoice.customer.billing.country != 'India' &&
                          '\n' + invoice.customer.billing.country}
                        {invoice.customer.billing.pincode != '' &&
                          invoice.customer.billing.pincode != null &&
                          invoice.customer.billing.pincode != 'None' &&
                          invoice.customer.billing.pincode != undefined &&
                          (invoice.customer.billing.city != '' ||
                          invoice.customer.billing.state != ''
                            ? ', '
                            : '') + invoice.customer.billing.pincode}
                      </Text>
                    </>
                  )}

                {!dispatch_to_doc_types.includes(invoice.document_type) &&
                  invoice.customer.shipping != undefined &&
                  invoice.customer.shipping.address_1 != null &&
                  invoice.customer.shipping.address_1 != '' && (
                    <>
                      <Text
                        style={styles.company_label}
                      >{`${invoice.invoice_settings.labels.ship_to}:`}</Text>
                      {invoice.customer.shipping.title != '' && (
                        <Text style={styles.company_text}>{invoice.customer.shipping.title}</Text>
                      )}
                      <Text style={styles.company_text}>{invoice.customer.shipping.address_1}</Text>
                      <Text style={styles.company_text}>{invoice.customer.shipping.address_2}</Text>
                      <Text style={styles.company_text}>
                        {invoice.customer.shipping.city != '' && invoice.customer.shipping.city}
                        {invoice.is_export != 1 &&
                          invoice.customer.shipping.state != '' &&
                          invoice.customer.shipping.state != undefined &&
                          invoice.customer.shipping.state != null &&
                          (getStateName(invoice.customer.shipping.state) != 'OTHERTERRITORY' ||
                            getStateName(invoice.customer.shipping.state) != '97-OTHERTERRITORY' ||
                            getStateName(invoice.customer.shipping.state) != 'OTHER TERRITORY') &&
                          (invoice.customer.shipping.city != '' ? ', ' : '') +
                            getStateName(invoice.customer.shipping.state)}
                        {invoice.is_export == 1 &&
                          invoice.customer.shipping.country &&
                          invoice.customer.shipping.country != 'India' &&
                          '\n' + invoice.customer.shipping.country}
                        {invoice.customer.shipping.pincode != '' &&
                          invoice.customer.shipping.pincode != null &&
                          invoice.customer.shipping.pincode != 'None' &&
                          invoice.customer.shipping.pincode != undefined &&
                          (invoice.customer.shipping.city != '' ||
                          invoice.customer.shipping.state != ''
                            ? ', '
                            : '') + invoice.customer.shipping.pincode}
                        {invoice.customer.shipping.notes != '' &&
                          '\n' + invoice.customer.shipping.notes}
                      </Text>
                    </>
                  )}

                <Text style={[styles.company_text]}>
                  {invoice.customer.phone == ''
                    ? ''
                    : 'Ph: ' +
                      (invoice.customer.dial_code != '91'
                        ? '+' + invoice.customer.dial_code + ' '
                        : '') +
                      invoice.customer.phone}{' '}
                  {invoice.customer.email == '' ||
                  invoice.customer.email == undefined ||
                  invoice.customer.email == null
                    ? ''
                    : invoice.customer.email}
                </Text>

                {'custom_party_values' in invoice &&
                  invoice.custom_party_values.map((item, index) => (
                    <View style={styles.company_row}>
                      <Text style={styles.company_labels}>{item.name}: </Text>
                      <Text style={styles.company_info}>{item.value}</Text>
                    </View>
                  ))}
              </View>
            )}
          </View>

          <View style={[styles.company_blockCC]}>
            <View style={styles.company_row}>
              <Text style={styles.company_labels}>{invoice.document_title} #: </Text>
              <Text style={[styles.company_info, styles.company_bold]}>
                {invoice.serial_number}
              </Text>
            </View>

            <View style={styles.company_row}>
              <Text style={styles.company_labels}>{invoice.document_title} Date: </Text>
              <Text style={[styles.company_info, styles.company_bold]}>
                {invoice.document_date}
              </Text>
            </View>
            {invoice.invoice_settings.show_due_date == 1 && (
              <View style={styles.company_row}>
                <Text style={styles.company_labels}>Due Date: </Text>
                <Text style={styles.company_info}>{invoice.document_due_date}</Text>
              </View>
            )}

            {invoice.document_type == 'purchases' && invoice.supplier_invoice_serial_number != '' && (
              <View>
                <View style={styles.company_row}>
                  <Text style={styles.company_labels}>Supplier Invoice #: </Text>
                  <Text style={[styles.company_info, styles.company_bold]}>
                    {invoice.supplier_invoice_serial_number}
                  </Text>
                </View>

                <View style={styles.company_row}>
                  <Text style={styles.company_labels}>Supplier Invoice Date: </Text>
                  <Text style={[styles.company_info, styles.company_bold]}>
                    {invoice.supplier_invoice_date_string}
                  </Text>
                </View>
              </View>
            )}

            {invoice.reference != '' && (
              <View style={styles.company_row}>
                <Text style={styles.company_labels}>Reference: </Text>
                <Text style={styles.company_info}>{invoice.reference}</Text>
              </View>
            )}

            {Object.keys(invoice).includes('eway_bill') && invoice.eway_bill.length > 0 && (
              <>
                <View style={styles.company_row}>
                  <Text style={styles.company_labels}>Eway Bill #: </Text>
                  <Text style={[styles.company_info, styles.company_bold]}>
                    {invoice.eway_bill[0].serial_number}
                  </Text>
                </View>

                <View style={styles.company_row}>
                  <Text style={styles.company_labels}>Vehicle Number: </Text>
                  <Text style={[styles.company_info, styles.company_bold]}>
                    {invoice.eway_bill[0].vehicle_number}
                  </Text>
                </View>
              </>
            )}

            {Object.keys(invoice).includes('document_custom_headers') &&
              invoice.document_custom_headers.length > 0 && (
                <Fragment>
                  {invoice.document_custom_headers.map((item, index) => (
                    <View style={styles.company_row}>
                      <Text style={styles.company_labels}>{item.label}: </Text>
                      <Text style={styles.company_info}>{item.value}</Text>
                    </View>
                  ))}
                </Fragment>
              )}
            {'export_details' in invoice && invoice.export_details.length > 0 && (
              <Fragment>
                {export_labels_1.map((item, index) => (
                  <>
                    {Object.keys(invoice.export_details[0]).includes(Object.keys(item)[0]) &&
                      invoice.export_details[0][Object.keys(item)[0]] != '' &&
                      invoice.export_details[0][Object.keys(item)[0]] != '' &&
                      invoice.export_details[0][Object.keys(item)[0]] != null && (
                        <View style={styles.company_row}>
                          <Text style={styles.company_labels}>{item[Object.keys(item)[0]]}: </Text>
                          <Text style={styles.company_info}>
                            {' '}
                            {invoice.export_details[0][Object.keys(item)[0]]}
                          </Text>
                        </View>
                      )}
                  </>
                ))}
              </Fragment>
            )}

            {invoice.gst_details.id == undefined &&
              invoice.company.shipping_address.address_1 != '' &&
              invoice.invoice_settings.show_dispatch_address === 1 && (
                <>
                  <View>
                    <Text style={styles.company_labels}>
                      {dispatch_to_doc_types.includes(invoice.document_type)
                        ? invoice.invoice_settings.labels.dispatch_to
                        : invoice.invoice_settings.labels.dispatch_from}
                      :
                    </Text>

                    <Text style={styles.company_text}>
                      {invoice.company.shipping_address.title != '' &&
                        invoice.company.shipping_address.title + '\n'}
                      {invoice.company.shipping_address.address_1 != '' &&
                        invoice.company.shipping_address.address_1 + '\n'}
                      {invoice.company.shipping_address.address_2 != '' &&
                        invoice.company.shipping_address.address_2 + '\n'}
                      {invoice.company.shipping_address.city != '' &&
                        invoice.company.shipping_address.city}
                      {invoice.company.shipping_address.state != '' &&
                        invoice.company.shipping_address.state != undefined &&
                        invoice.company.shipping_address.state != null &&
                        (getStateName(invoice.company.shipping_address.state) != 'OTHERTERRITORY' ||
                          getStateName(invoice.company.shipping_address.state) !=
                            '97-OTHERTERRITORY' ||
                          getStateName(invoice.company.shipping_address.state) !=
                            'OTHER TERRITORY') &&
                        (invoice.company.shipping_address.city != '' ? ', ' : '') +
                          getStateName(invoice.company.shipping_address.state)}
                      {invoice.is_export == 1 &&
                        invoice.company.shipping_address.country &&
                        invoice.company.shipping_address.country != 'India' &&
                        '\n' + invoice.company.shipping_address.country}
                      {invoice.company.shipping_address.pincode != '' &&
                        invoice.company.shipping_address.pincode != null &&
                        invoice.company.shipping_address.pincode != 'None' &&
                        invoice.company.shipping_address.pincode != undefined &&
                        (invoice.company.shipping_address.city != '' ||
                        invoice.company.shipping_address.state != ''
                          ? ', '
                          : '') + invoice.company.shipping_address.pincode}
                        {invoice.company.shipping_address.notes != '' &&
                          '\n' + invoice.company.shipping_address.notes}
                    </Text>
                  </View>
                </>
              )}
          </View>
        </View>
      </View>
    </View>
  )
}

export default CompanyDetails
