import React, { useImperativeHandle, forwardRef, useState } from 'react'
import { Drawer, Button } from 'antd'
import { isMobile } from 'react-device-detect'
import CustomNotes from './customNotesForm'

const NotesTermsDrawer = forwardRef((props, ref) => {
  const [showdocumentDrawer, setShowdocumentDrawer] = useState(false)
  const [doc_type, setDocType] = useState('')
  const [is_notes, setIsNotes] = useState(false)
  const showDrawer = (document_type = 'invoice', is_notes = 1) => {
    console.log('props', props)
    setShowdocumentDrawer(true)
    setDocType(document_type)
    setIsNotes(is_notes)
  }
  useImperativeHandle(ref, () => ({
    showDrawer,
  }))
  const closeDrawer = () => {
    setShowdocumentDrawer(false)
    props.refreshNotesTerms()
  }
  return (
    <Drawer
      title={
        <>
          <div className="flex items-center">
            <div className="mr-auto">
              <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                Document Notes
              </span>
            </div>
          </div>
        </>
      }
      placement="right"
      width={isMobile ? '90%' : '50%'}
      closable={() => closeDrawer()}
      onClose={() => closeDrawer()}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      open={showdocumentDrawer}
      destroyOnClose={true}
      footer={
        <>
          <Button onClick={() => closeDrawer()} type="primary" className="mr-2 arrow-transition">
            Save
            <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
          </Button>

          <Button onClick={() => closeDrawer()} type="danger">
            Close
          </Button>
        </>
      }
    >
      <CustomNotes
        doc_type={doc_type}
        is_notes={is_notes}
        showdocumentDrawer={showdocumentDrawer}
      />
    </Drawer>
  )
})

export default NotesTermsDrawer
