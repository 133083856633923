import { all, put, takeLatest } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
import store from 'store'
import actions from './actions'

const mapAuthProviders = {
  currentAccount: jwt.currentAccount,
}

export function* COMPANY_DETAILS_ADDED() {
  yield store.set('company_details_added', true)
  yield put({
    type: 'welcome/UPDATE_WELCOME_DETAILS',
    payload: {
      company_details_added: true,
    },
  })
}

export function* BANK_DETAILS_ADDED() {
  yield store.set('bank_details_added', true)
  yield put({
    type: 'welcome/UPDATE_WELCOME_DETAILS',
    payload: {
      bank_details_added: true,
    },
  })
}

export function* PRODUCTS_ADDED() {
  yield store.set('products_added', true)
  yield put({
    type: 'welcome/UPDATE_WELCOME_DETAILS',
    payload: {
      products_added: true,
    },
  })
}

export function* INVOICES_CREATED() {
  yield store.set('invoices_created', true)
  yield put({
    type: 'welcome/UPDATE_WELCOME_DETAILS',
    payload: {
      invoices_created: true,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.COMPANY_DETAILS_ADDED, COMPANY_DETAILS_ADDED),
    takeLatest(actions.BANK_DETAILS_ADDED, BANK_DETAILS_ADDED),
    takeLatest(actions.PRODUCTS_ADDED, PRODUCTS_ADDED),
    takeLatest(actions.INVOICES_CREATED, INVOICES_CREATED),
  ])
}
