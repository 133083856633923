import { PDFViewer } from '@react-pdf/renderer'
import Ledger from 'components/ledger/index'
import React, { useEffect, useState } from 'react'

export function LedgerDocument({ data }) {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(true)
  }, [])

  return (
    <>
      {isReady ? (
        <PDFViewer key={Math.random()} className="w-full p-0" style={{ height: '85vh' }}>
          <Ledger data={{ ...data }} />
        </PDFViewer>
      ) : (
        ''
      )}
    </>
  )
}
