// eslint-disable-next-line no-unused-vars
import { Divider, Layout } from 'antd'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import { getAPI } from 'services/jwt'

const PaymentLinkLayout = ({ children }) => {
  const [is_paid, setIsPaid] = React.useState(true)
  const [company_id, setCompanyID] = React.useState(0)

  useEffect(() => {
    let url = window.location.href
    url = url.split('/')
    get_paid_status(url[url.length - 1])
  }, [])
  const get_paid_status = async id => {
    if (id != '') {
      const data = await getAPI('utils', 'paid_status_public', { hash_id: id })
      if (data) {
        setIsPaid(data.paid_status == 1)
        setCompanyID(data.company_id)
      }
    }
  }

  return (
    <Layout className="bg-gray-50">
      <Layout.Content
        className="bg-gray-50 mt-0 mb-4 pt-md-5"
        style={{ position: 'relative', overflowX: 'hidden' }}
      >
        <div className="cui__utils__content">{children}</div>
      </Layout.Content>

      <div className="flex justify-center items-center mb-16 mt-4">
        <Link
          to={{ pathname: 'https://getswipe.in?_campaignname=DocLinkPaid' }}
          target="_blank"
          className="flex items-center p-0 font-size-13"
        >
          <span className="mr-1 text-gray-300 font-weight-medium">Powered by</span>
          <img
            src="/resources/images/logo_swipe_gray.svg"
            className="h-5 align-bottom mr-1"
            alt="Swipe"
          />
        </Link>
        <Divider type="vertical" />
        <p className="font-size-13 mb-0 text-gray-300 ml-1">
          <Link
            to={{ pathname: 'https://getswipe.in/terms' }}
            target="_blank"
            className="font-weight-medium text-gray-300"
          >
            {' '}
            <FormattedMessage id="terms" />
          </Link>{' '}
          &{' '}
          <Link
            to={{ pathname: 'https://getswipe.in/policy' }}
            target="_blank"
            className="font-weight-medium text-gray-300"
          >
            <FormattedMessage id="Privacy" />
          </Link>
        </p>
      </div>
    </Layout>
  )
}

export default withRouter(PaymentLinkLayout)
