import { Card, Spin, Button } from 'antd'

export default function CouponsList({ data, loading, onApply, total_amount }) {
  return (
    <div>
      <div className="w-full">
        <div className="pb-2">
          <div>
            {loading && (
              <Spin
                size="large"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                indicator={<i className="fa fa-spinner fa-spin fa-3x fa-fw" />}
              />
            )}
            {!loading && (
              <div className="flex flex-wrap mt-2 py-2">
                {data?.length === 0 && (
                  <div className="w-full p-2">
                    <Card className="p-4 w-full flex flex-col justify-center items-center shadow-sm border-0 rounded">
                      <i
                        className="fa fa-badge-percent text-5xl text-red-500"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                      <div className="mt-2 text-center text-lg font-bold">No coupons available</div>
                    </Card>
                  </div>
                )}

                {data?.map((item, index) => (
                  <div className="w-full p-2" key={index}>
                    <CouponCard coupon={item} onApply={onApply} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export function CouponCard(props) {
  const { coupon, onApply } = props
  return (
    <Card
      className={`mb-4 py-2 w-full shadow-sm border-0 rounded ${
        coupon.status == 'expired' ? 'bg-gray-100' : ''
      }`}
    >
      <div className="flex justify-between">
        <div className="text-lg font-bold">
          <span>
            <i
              className={`fa-regular fa-badge-percent mr-1 ${
                coupon.status == 'expired' ? 'text-red-500' : 'text-green-500'
              }`}
            />
          </span>
          {coupon.coupon_code}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-sm text-gray-500 truncate">{coupon.description}</div>
      </div>

      <div className="flex justify-between mt-3">
        <div>
          {props.name != '4' && (
            <div className="text-lg text-red-500 font-bold">
              <span className="cursor-pointer">
                <Button
                  type={coupon.disabled ? 'default' : 'primary'}
                  onClick={() => onApply(coupon)}
                  className="mr-2 font-bold"
                  disabled={coupon.disabled}
                >
                  Apply
                </Button>
              </span>
            </div>
          )}
          {coupon.error != '' && <div className="text-sm text-red-500">{coupon.error}</div>}
        </div>
      </div>
    </Card>
  )
}
