import React from 'react'
import { Text, View, Image } from '@react-pdf/renderer'
import { export_terms } from '../export_condition'
import { styles as getStyles } from './styles'

const InvoiceNotes = ({ invoice, type }) => {
  var styles = getStyles(invoice)

  return (
    <View style={[styles.invoiceNotes_blockC, styles.global_borderTop]} wrap={false}>
      <View style={[styles.invoiceNotes_blockC75, styles.global_borderRight]}>
        {invoice.rcm == 1 && (
          <View style={styles.global_mb}>
            <Text style={styles.invoiceNotes_label}>*Amount of Tax subject to Reverse Charge</Text>
          </View>
        )}
        {invoice.immovable_tax_type == 1 && (
          <View style={styles.global_mb}>
            <Text style={styles.invoiceNotes_label}>
              *Place of Supply is determined u/s 12(3) of the IGST Act 2017.
            </Text>
          </View>
        )}

        {invoice.is_export == 1 && invoice.company.gstin.length == 15 && (
          <View style={styles.global_mb}>
            <Text style={styles.invoiceNotes_label}>
              *{export_terms[invoice.export_details[0].export_type]}
            </Text>
          </View>
        )}
        {invoice.notes != '' && (
          <View style={styles.global_mb}>
            <Text style={styles.invoiceNotes_label}>Notes: </Text>
            <Text style={styles.invoiceNotes_text}>{invoice.notes}</Text>
          </View>
        )}

        {invoice.terms != '' && type != 'Delivery Challan' && (
          <View style={styles.global_mb}>
            <Text style={styles.invoiceNotes_label}>Terms and Conditions:</Text>
            <Text style={styles.invoiceNotes_text}>{invoice.terms}</Text>
          </View>
        )}

        {invoice.invoice_settings.show_receivers_signature == 1 && (
          <View style={styles.invoiceNotes_receiveSignBlock}>
            <Text
              style={{
                ...styles.invoiceNotes_receiveSignText,
              }}
            >
              Receiver's Signature{' '}
            </Text>
          </View>
        )}
      </View>

      <View style={[styles.invoiceNotes_blockC25]}>
        <View style={styles.invoiceNotes_block}>
          <Text
            style={
              invoice.company.company_name.length > 40
                ? {
                    ...styles.invoiceNotes_label2,
                    fontSize: (6.66 * 40) / invoice.company.company_name.length,
                  }
                : styles.invoiceNotes_label2
            }
          >
            For {invoice.company.company_name}{' '}
          </Text>

          {invoice.signature != '' && invoice.signature != null && (
            <Image
              style={styles.invoiceNotes_signature}
              src={{
                uri: invoice.signature,
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
              }}
              crossorigin="anonymous"
            />
          )}

          <Text
            style={{
              ...styles.invoiceNotes_label2,
              marginTop: invoice.signature != '' ? 4.5 : 45,
            }}
          >
            Authorized Signatory{' '}
          </Text>
        </View>
      </View>
    </View>
  )
}

export default InvoiceNotes
