import {
  GET_CATEGORIES,
  GET_CUSTOMER_COLUMNS,
  GET_VENDOR_COLUMNS,
  SET_BULK_EDIT_LOADING,
  SET_CATEGORIES,
  SET_CUSTOMER_COLUMNS,
  SET_VENDOR_COLUMNS,
} from './types'

const intitalState = {
  customer_columns: [],
  vendor_columns: [],
  categories: [],
  customer_columns_loading: false,
  vendor_columns_loading: false,
}

export default function bulkEditReducer(state = intitalState, action) {
  switch (action.type) {
    case SET_CUSTOMER_COLUMNS:
      return {
        ...state,
        customer_columns: action.payload,
        customer_columns_loading: false,
      }
    case SET_VENDOR_COLUMNS:
      return {
        ...state,
        vendor_columns: action.payload,
        vendor_columns_loading: false,
      }

    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      }

    case SET_BULK_EDIT_LOADING:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
