import React, { Fragment } from 'react'
import { Text, View, Image } from '@react-pdf/renderer'
import { getStateName } from '../export_condition'
import { styles as getStyles } from './styles'
import { HIDE_PRICES } from '../utils'

const CompanyDetails = ({ invoice, type, color, title }) => {
  var styles = getStyles(invoice)
  var data = invoice.gst_details.id == undefined ? invoice.company : invoice.gst_details
  var tax_id_label = invoice.company.tax_id_label
  return (
    <View fixed={invoice.invoice_settings.repeat_header == 1}>
      <View>
        {invoice.company.is_composite == 1 &&
          (invoice.document_type == 'sales' || invoice.document_type == 'pos') && (
            <Text style={[styles.company_dec]}>
              Composition taxable person, not eligible to collect tax on supplies
            </Text>
          )}
      </View>
      <View style={styles.company_headerContainer}>
        {invoice.invoice_settings.show_company_details == 1 && (
          <View style={styles.company_blockC}>
            {invoice.company.logo != null && invoice.company.logo != '' && (
              <View>
                <Image
                  style={styles.company_logo}
                  src={{
                    uri: invoice.company.logo,
                    method: 'GET',
                    headers: { 'Cache-Control': 'no-cache' },
                  }}
                  crossorigin="anonymous"
                />
              </View>
            )}
            <View style={styles.company_blockC2}>
              <Text
                style={[
                  data.company_name.length > 50
                    ? { fontSize: (11.7 * 40) / data.company_name.length }
                    : styles.company_large,
                  styles.company_bold,
                ]}
              >
                {data.company_name}
              </Text>
              <Text>
                {data.gstin.length == 15 && (
                  <>
                    <Text style={styles.company_label}>{tax_id_label} </Text>
                    <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                      {data.gstin} {'   '}
                    </Text>
                  </>
                )}
                {data.pan_number != '' && (
                  <>
                    <Text style={styles.company_label}>PAN </Text>
                    <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                      {data.pan_number}
                    </Text>
                  </>
                )}
              </Text>
              {'custom_fields' in data && data.custom_fields.length > 0 && (
                <>
                  {data.custom_fields.map((field, index) => {
                    if (field.value != '' && field.value != null && field.value != undefined) {
                      return (
                        <Text key={index}>
                          <Text style={styles.company_label}>{field.field_name} </Text>
                          <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                            {field.value}
                          </Text>
                        </Text>
                      )
                    }
                  })}
                </>
              )}
              {data.mobile != '' && data.mobile != 'None' && (
                <Text>
                  <Text style={styles.company_label}>Mobile </Text>
                  <Text style={styles.company_invoiceDate}>
                    {`+${data.dial_code} ${data.mobile}`}

                    {data.alt_contact == '' ? '   ' : ', '}
                    {data.alt_contact}
                    {data.alt_contact != '' && '   '}
                  </Text>
                </Text>
              )}

              {data.email != '' && (
                <Text>
                  <Text style={styles.company_label}>Email </Text>
                  <Text style={styles.company_invoiceDate}>{data.email}</Text>
                </Text>
              )}
              {data.website != '' && (
                <>
                  <Text>
                    <Text style={styles.company_label}>Website </Text>
                    <Text style={styles.company_invoiceDate}>{data.website}</Text>
                  </Text>
                </>
              )}
            </View>
          </View>
        )}
        <View style={styles.company_blockI}>
          <Text
            style={{
              ...styles.company_reportTitle,
              color: color,
              textAlign: invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
            }}
          >
            {title}
          </Text>
          {type != HIDE_PRICES && (
            <Text
              style={{
                ...styles.company_reportType,
                textAlign: invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
              }}
            >
              {type}
            </Text>
          )}
          <View>
            <Text>
              <Text
                style={{
                  ...styles.company_labels,
                  textAlign: invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
                }}
              >
                {invoice.document_title} #:{' '}
              </Text>
              <Text style={[styles.company_info, styles.company_bold]}>
                {invoice.serial_number}
              </Text>
            </Text>

            <Text>
              <Text
                style={{
                  ...styles.company_labels,
                  textAlign: invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
                }}
              >
                {invoice.document_title} Date:{' '}
              </Text>
              <Text style={[styles.company_info, styles.company_bold]}>
                {invoice.document_date}
              </Text>
            </Text>
            {invoice.invoice_settings.show_due_date == 1 && (
              <Text>
                <Text
                  style={{
                    ...styles.company_labels,
                    textAlign:
                      invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
                  }}
                >
                  Due Date:{' '}
                </Text>
                <Text style={styles.company_info}>{invoice.document_due_date}</Text>
              </Text>
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

export default CompanyDetails
