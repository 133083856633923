import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { Fragment } from 'react'

const styles = StyleSheet.create({
  headerContainer: {
    marginBottom: 4.5,
    fontFamily: 'helreg_English',
    lineHeight: 1.4,
  },
  billTo: {
    marginTop: 0,
    marginBottom: 1.8,
    color: '#333333',
    // fontFamily: 'helitalic',
    fontFamily: 'helbold_English',
    fontSize: 12,
  },
  blockA: {
    width: '100%',
    marginRight: '2%',
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 18,
  },
  blockB: {
    width: '90%',
    marginHorizontal: '5%',
    fontSize: 12,
    flexDirection: 'row',
    textAlign: 'center',
    borderWidth: 0.72,
    borderColor: '#C1C9D2',
    marginBottom: 18,
  },
  block1: {
    width: '25%',
    fontSize: 12,
    paddingVertical: 5.4,
    paddingHorizontal: 2.7,
    borderRightWidth: 0.36,
    borderRightColor: '#C1C9D2',
  },
  block2: {
    width: '25%',
    fontSize: 12,
    padding: 5.4,
    borderRightWidth: 0.36,
    borderRightColor: '#C1C9D2',
  },
  block3: {
    width: '25%',
    fontSize: 12,
    padding: 5.4,
    borderRightWidth: 0.36,
    borderRightColor: '#C1C9D2',
  },
  block4: {
    width: '25%',
    padding: 5.4,
    fontSize: 12,
  },
  block5: {
    width: '66%',
    marginRight: 0,
    fontSize: 12,
  },
  mb: {
    marginBottom: 1.8,
  },
  mb1: {
    marginBottom: 0,
  },
  smallFont: {
    fontSize: 10.8,
    marginBottom: 1.8,
  },
  xs_font: {
    fontSize: 8.82,
    marginBottom: 1.8,
  },
  bold: {
    fontFamily: 'helbold_English',
  },
})

const Party = ({ invoice }) => {
  var sum = 0
  invoice.ledger.forEach(item => {
    sum += item.payment_type == 'in' ? item.total_amount : 0
  })

  var total_credit = sum

  var sum2 = 0
  invoice.ledger.forEach(item => {
    sum2 += item.payment_type == 'out' ? -item.total_amount : 0
  })
  var total_debit = sum2

  return (
    <Fragment>
      <View style={styles.headerContainer}>
        {invoice.party_details.name != null && invoice.party_details.name != '' && (
          <View style={styles.blockA}>
            <Text style={styles.billTo}></Text>
            {invoice.party_details.name != invoice.party_details.company_name && (
              <Text style={[styles.smallFont]}>{invoice.party_details.name}</Text>
            )}
            <Text style={styles.bold}>{invoice.party_details.company_name}</Text>
            <Text style={[styles.mb1, styles.smallFont, styles.bold]}>
              {invoice.party_details.gstin}
            </Text>
            <Text style={[styles.mb1, styles.smallFont]}>
              {invoice.party_details.phone == '' ? '' : 'Ph: ' + invoice.party_details.phone}
              {invoice.party_details.email == '' ||
              invoice.party_details.email == undefined ||
              invoice.party_details.email == null
                ? ''
                : invoice.party_details.phone != ''
                ? ', ' + invoice.party_details.email
                : invoice.party_details.email}
            </Text>
            {invoice.start_date != '' && invoice.end_date != '' ? (
              <Text style={[styles.smallFont]}>
                ({invoice.start_date} - {invoice.end_date}){' '}
              </Text>
            ) : null}
          </View>
        )}

        {!invoice.show_pending_docs && (
          <View style={styles.blockB}>
            <View style={styles.block1}>
              <Text style={[styles.smallFont]}>
                Previous Balance{/*{invoice.ledger[0].serial_number}*/}
              </Text>
              {invoice.ledger.length > 0 && (
                <Text style={styles.bold}>
                  {`${invoice.company_details.currency_symbol} ${invoice.ledger[
                    invoice.ledger[0].payment_date == '' ? 0 : invoice.ledger.length - 1
                  ].total_amount.toFixed(2)}
                `}
                </Text>
              )}
            </View>
            <View style={styles.block2}>
              <Text style={[styles.smallFont]}>Total Debit (-)</Text>
              <Text style={{ ...styles.bold, color: 'red' }}>
                {invoice.company_details.currency_symbol} {Math.abs(total_debit).toFixed(2)}
              </Text>
            </View>
            <View style={styles.block3}>
              <Text style={[styles.smallFont]}>Total Credit (+)</Text>
              <Text style={{ ...styles.bold, color: 'green' }}>
                {invoice.company_details.currency_symbol} {Math.abs(total_credit).toFixed(2)}
              </Text>
            </View>
            <View style={styles.block4}>
              <Text style={[styles.smallFont]}>Closing Balance</Text>
              <Text
                style={{
                  ...styles.bold,
                  color: `${invoice.closing_balance >= 0 ? 'green' : 'red'}`,
                }}
              >
                {invoice.company_details.currency_symbol}{' '}
                {Math.abs(invoice.closing_balance).toFixed(2)}
              </Text>
            </View>
          </View>
        )}
      </View>
    </Fragment>
  )
}

export default Party
