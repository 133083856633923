import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { State } from '../redux/types'

const BingAd: React.FC = () => {
  const isNewUser = useSelector((state: State) => state.user?.new_user)

  useEffect(() => {
    if (isNewUser) {
      const scriptContent = `
        function uet_report_conversion() {
            window.uetq = window.uetq || [];window.uetq.push('event', 'signup', {});
        }   
        uet_report_conversion();
      `
      const scriptTag = document.createElement('script')
      scriptTag.innerHTML = scriptContent
      if (!document.head.contains(scriptTag)) {
        document.head.appendChild(scriptTag)
      }

      return () => {
        document.head.removeChild(scriptTag)
      }
    }
  }, [isNewUser])

  return null
}

export default BingAd
