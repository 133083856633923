import { Modal } from 'antd'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

const SaveChangesModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)
  const [formData, setFormData] = useState({})
  const showModal = values => {
    setVisible(true)
    setFormData(values)
  }
  useImperativeHandle(ref, () => ({
    showModal,
  }))
  const onCancel = () => {
    setVisible(false)
    props.onClose()
  }
  return (
    <Modal
      maskClosable={false}
      open={visible}
      closable
      title={
        <>
          <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>
          Warning
        </>
      }
      okText="Yes"
      cancelText="No"
      okButtonProps={{ type: 'primary' }}
      cancelButtonProps={{ type: 'danger' }}
      destroyOnClose
      onOk={() => {
        props.onFinish(formData)
        setVisible(false)
      }}
      onCancel={onCancel}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
    >
      <p>
        <span className="text-gray-700 font-size-16">
          These changes will be reflected in your old documents as well. Are you sure you want to
          save these changes?
        </span>{' '}
      </p>
    </Modal>
  )
})

export default SaveChangesModal
