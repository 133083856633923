import { getAPI } from 'services/jwt'
import store from 'store'
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging'
import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config'
import { getFirebaseApp } from './config'

const config = getRemoteConfig(getFirebaseApp())
config.settings = {
  minimumFetchIntervalMillis: 36000,
}
config.defaultConfig = {
  web_logo: '/resources/images/logo-w.svg',
  user_updates: [],
  web_version: {
    version: '1.0.0',
    important: false,
    success: false,
  },
}

fetchAndActivate(config)
  .then(() => {
    console.log('Remote Config fetched and activated')
  })
  .catch(error => {
    console.error('Error fetching and activating Remote Config:', error)
    if (error.code === 'remoteconfig/storage-open') {
      console.error('IndexedDB error:', error.message)
      // Handle the error, e.g., use local storage as a fallback
    }
  })

export let messaging = null
if (await isSupported()) {
  messaging = getMessaging(getFirebaseApp())
}

const pub_key =
  'BCvwQ-l6wDDqP7DFw7QQZCcDwcioixs9pbuCZyJPkXlZ_BqaGKOzW_w5vMOxliLllW24N46clVJd_5I9SAyUAr4'

export const getMessagingToken = setTokenFound => {
  if (!messaging) return
  return getToken(messaging, { vapidKey: pub_key })
    .then(async currentToken => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken)
        setTokenFound(true)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        const req = {
          device_token: currentToken,
        }
        const data1 = await getAPI('user', 'add_device_token', req)
        // console.log(data1)
      } else {
        console.log('No registration token available. Request permission to generate one.')
        setTokenFound(false)
        // shows on the UI that permission is required
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err)
      // catch error while creating client token
    })
}

// export const requestPermissionFirebase = setTokenFound => {
//   if (!messaging) return
//   return requestPermission(messaging)
//     .then(function(e) {
//       console.log('Notification permission granted.')
//       // TODO(developer): Retrieve a Instance ID token for use with FCM.
//       // ...
//       // getToken(setTokenFound)
//       alert('Click on the Lock Icon beside the url to Disable notifications')
//     })
//     .catch(function(err) {
//       console.log('Unable to get permission to notify. ', err)
//       alert('Click on the Lock Icon beside the url to Allow notifications')
//     })
// }

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage((messaging, payload) => {
      // console.log('inside message')
      resolve(payload)
    })
  })

export const fetchFromRemoteConfig = async () => {
  await fetchAndActivate(config).catch(error => {
    console.error(error)
  })
}

export const GetLogo = async () => {
  await fetchFromRemoteConfig()
  if (store.get('app.settings.theme') == 'swipedark') {
    return getValue(config, 'web_logo_dark')._value
  }
  return getValue(config, 'web_logo')._value
}

export const GetBeaconValue = async () => {
  await fetchFromRemoteConfig()
  return getValue(config, 'web_beacon_value')._value
}

export const GetVersion = async () => {
  try {
    await fetchFromRemoteConfig()
    let data = getValue(config, 'web_version')._value
    data = JSON.parse(data)
    return data
  } catch (e) {
    console.log(e)
  }
  return { version: '1.0.0', important: false, success: false }
}

export const fetchUserUpdates = async () => {
  var updates = []
  try {
    await fetchFromRemoteConfig()
    // console.log(config.getValue('user_updates_dev')._value)
    updates =
      process.env.REACT_APP_STAGE == 'development'
        ? getValue(config, 'user_updates_dev')._value
        : getValue(config, 'user_updates_web')._value // for prod
    updates = JSON.parse(updates)
  } catch (error) {
    console.log(error)
  }
  return updates
}
