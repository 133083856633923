import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import actions from './actions'

export function* GET_DATA() {
  const selected_company_details = yield select(state => {
    return state.user?.selectedCompany
  })

  const menuData = yield call(getMenuData, selected_company_details?.country_code)
  let filteredMenuData = menuData.filter(item => item.show != false)
  filteredMenuData = filteredMenuData.map(parent => ({
    ...parent,
    children: parent?.children?.filter(child => child.show != false),
  }))

  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData: filteredMenuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_DATA, GET_DATA),
    // GET_DATA(), // run once on app load to fetch menu data
  ])
}
