import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { Fragment } from 'react'

const styles = StyleSheet.create({
  headerContainer: {
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    '@media min-width: 400': {
      flexDirection: 'row',
    },
    marginBottom: 20,
    fontFamily: 'helreg_English',
    lineHeight: 1.26,
  },
  blockC: {
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    paddingVertical: 5.4,
  },
  label: {
    width: '30%',
    color: '#515154',
    fontFamily: 'helbold_English',
    paddingRight: 36,
    fontSize: 10.8,
  },
  text: {
    width: '70%',
    fontFamily: 'helbold_English',
    fontSize: 10.8,
    textAlign: 'left',
  },
  invoiceContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  invoiceDate: {
    fontSize: 12,
    color: '#141414',
  },
  // label: {
  //   fontSize: 12,
  //   color: '#333333',
  //   textAlign: 'left',
  //   fontFamily: 'helbold',
  // },
  address: {
    fontSize: 10,
  },
  bold: {
    fontFamily: 'helbold_English',
  },
  large: {
    fontSize: 13.5,
  },
  logo: {
    width: 'auto',
    height: '54',
    position: 'relative',
    textAlign: 'center',
    marginHorizontal: 'auto',
    marginRight: 9,
  },
  stamp: {
    width: 'auto',
    height: '81',
    position: 'relative',
    marginRight: '10',
    marginLeft: 'auto',
    textAlign: 'right',
    marginBottom: 10,
  },
})

const CompanyDetails = ({ invoice }) => {
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  return (
    <Fragment>
      <View style={styles.headerContainer}>
        <View style={styles.blockC}>
          <Text style={[styles.large, styles.bold]}>{invoice.company_details.company_name}</Text>
          <Text style={styles.address}>{invoice.company_details.address_1}</Text>
          <Text style={styles.address}>{invoice.company_details.address_2}</Text>
          <Text style={styles.address}>
            {invoice.company_details.city == ''
              ? ''
              : invoice.company_details.city +
                (invoice.company_details.state == '' ||
                invoice.company_details.state == undefined ||
                invoice.company_details.state == null
                  ? ''
                  : ',')}{' '}
            {invoice.company_details.state == ''
              ? ''
              : invoice.company_details.state +
                (invoice.company_details.pincode == '' ||
                invoice.company_details.pincode == undefined ||
                invoice.company_details.pincode == null
                  ? ''
                  : ',')}{' '}
            {invoice.company_details.pincode}{' '}
          </Text>
          <Text>
            <Text style={styles.label}>Mobile </Text>
            <Text style={styles.invoiceDate}>
              {invoice.company_details.mobile}
              {invoice.company_details.alt_contact == '' ? '   ' : ', '}
              {invoice.company_details.alt_contact}
              {invoice.company_details.alt_contact != '' && '   '}
            </Text>
            <Text style={styles.label}>Email </Text>
            <Text style={styles.invoiceDate}>{invoice.company_details.email}</Text>
          </Text>
          <Text>
            {invoice.company_details.website != '' && (
              <>
                <Text style={styles.label}>Website </Text>
                <Text style={styles.invoiceDate}>{invoice.company_details.website}</Text>
              </>
            )}
          </Text>
        </View>

        {invoice.company_details.logo != null && invoice.company_details.logo != '' && (
          <View style={styles.blockI}>
            <Image
              style={styles.logo}
              src={{
                uri: invoice.company_details.logo,
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
              }}
              crossorigin="anonymous"
            />
          </View>
        )}
      </View>

      <View style={styles.block2}>
        <View style={styles.row}>
          <Text style={styles.label}>Payment #: </Text>
          <Text style={styles.text}>{invoice.payment_details.serial_number}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Payment Date: </Text>
          <Text style={styles.text}>{invoice.payment_details.document_date}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Status: </Text>
          <Text style={styles.text}>{invoice.payment_details.status}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Mode: </Text>
          <Text style={styles.text}>{invoice.payment_details.payment_mode}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Amount: </Text>
          <Text style={styles.text}>
            {'\u00A0'}
            {invoice.company_details.currency_symbol}
            {'\u00A0'}
            {Number(invoice.payment_details.amount.toFixed(2)).toLocaleString('en-IN', options)}
          </Text>
        </View>

        {invoice?.payment_in_settled_invoices?.map((item, index) => (
          <View style={styles.row}>
            <Text style={styles.label}>Invoice #: </Text>
            <Text style={styles.text}>
              {item.invoice_serial_number}
              {', '}
              {'\u00A0'}
              {invoice.company_details.currency_symbol}
              {'\u00A0'}
              {item.amount_paid}
            </Text>
          </View>
        ))}

        {invoice?.payment_out_settled_pos?.map((item, index) => (
          <View style={styles.row}>
            <Text style={styles.label}>Invoice #: </Text>
            <Text style={styles.text}>
              {item.po_serial_number}
              {', '}
              {'\u00A0'}
              {invoice.company_details.currency_symbol}
              {'\u00A0'}
              {item.amount_paid}
            </Text>
          </View>
        ))}

        <View style={styles.row}>
          <Text style={styles.label}>Notes: </Text>
          <Text style={styles.text}>
            {invoice.payment_details.notes != '' ? invoice.payment_details.notes : '-'}
          </Text>
        </View>
      </View>

      <Image style={styles.stamp} src="/paid_stamp.png" crossorigin="anonymous" />
    </Fragment>
  )
}

export default CompanyDetails
