import React, { Component } from 'react'
// import QRCode from 'qrcode.react'
import { QRCodeCanvas } from '@cheprasov/qrcode'
import { Image } from '@react-pdf/renderer'

export class QRCodeFile extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = { imagesrc: '' }
  }

  componentDidMount() {
    const config = {
      level: this.props.level, // use high error correction level
      padding: 0, // do not use padding around qr code data,
      // image: {
      //     source: 'swipe.png', // or data:image/jpeg;base64,...
      //     width: '10%',
      //     height: '10%',
      //     x: 'center',
      //     y: 'center',
      // }
    }
    const qrSVG = new QRCodeCanvas(this.props.value, config)
    const dataUrlWithSVGQRCode = qrSVG.toDataUrl()

    this.setState({ imagesrc: dataUrlWithSVGQRCode })
  }

  render() {
    return <Image src={this.state.imagesrc} />
  }
}

QRCodeFile.defaultProps = {
  level: 'H',
}

export class EnhancedQRCode extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <QRCodeFile {...this.props} />
  }
}
