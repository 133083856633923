import React, { Component } from 'react'

import { Button, Modal, Table } from 'antd'
import { FormattedMessage } from 'react-intl'
import ProductBarcode from '../barcode'

export default class BulkUpload extends Component {
  constructor(props) {
    super(props)
  }

  getStatusClass = data => {
    if (data < 0) {
      return '#e11900'
    } else if (data == 0 && data < 5) {
      return '#757575'
    } else {
      return '#09864A'
    }
  }

  render() {
    return (
      <>
        <Modal
          open={this.props.confirmFlag}
          maskClosable={false}
          title={this.props.title}
          width={'75vw'}
          closeIcon={<i className="fa-solid fa-xmark"></i>}
          onCancel={() => this.props.onClose()}
          footer={
            <div className="">
              <Button
                loading={this.props.loadingCancel}
                onClick={e => this.props.callConfirmAPI(false)}
                className="mr-2"
                type="danger"
              >
                <i className="fa fa-times mr-2" /> <FormattedMessage id="button.cancel" />
              </Button>
              <Button
                loading={this.props.loading}
                onClick={() => this.props.callConfirmAPI(true)}
                type="success"
                className="font-weight-bold"
              >
                <i className="fa-solid fa-circle-check mr-2" />{' '}
                <FormattedMessage id="button.confirm" />
              </Button>
            </div>
          }
        >
          <Table
            columns={this.props.columns}
            dataSource={this.props.data}
            rowClassName={(record, index) =>
              record.status == 'edit'
                ? 'ant-table-tbody-edit'
                : record.status == 'new'
                ? 'ant-table-tbody-add'
                : 'ant-table-tbody-delete'
            }
            pagination={{ size: 'small', showSizeChanger: true, showQuickJumper: true }}
            size="small"
          />
          <ProductBarcode onRef={ref => (this.barcode = ref)} menu="barcode" />
        </Modal>
      </>
    )
  }
}

export class EnhancedBulkUpload extends React.Component {
  render() {
    return <BulkUpload {...this.props} />
  }
}
