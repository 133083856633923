import { getPerformance, PerformanceTrace, trace } from 'firebase/performance'
import { getFirebaseApp } from 'pages/firebasejs/config'

export const startTrace = (type: string, name: string) => {
  if (process.env.REACT_APP_STAGE === 'production') {
    try {
      const firebase_trace = trace(getPerformance(getFirebaseApp()), type + ':' + name)
      firebase_trace.start()
      return firebase_trace
    } catch (e) {}
  }
  return undefined
}
export const stopTrace = (trace: PerformanceTrace | undefined) => {
  if (process.env.REACT_APP_STAGE === 'production' && trace) {
    try {
      trace.stop()
    } catch (e) {}
  }
}
