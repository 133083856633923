import { Text, View } from '@react-pdf/renderer'
import { EnhancedQRCode } from 'components/other/qrCodeFile'
import React from 'react'
import { export_terms } from '../export_condition'
import { styles as getStyles } from './styles'

const invoiceNotes = ({ invoice, type }) => {
  var styles = getStyles(invoice)

  return (
    <View style={[styles.invoiceNotes_titleContainer, styles.invoiceNotes_borderTop]}>
      {/* {type != 'Delivery Challan' && ( */}
      <View style={styles.invoiceNotes_blockC}>
        <View style={[styles.invoiceNotes_blockCA, styles.invoiceNotes_borderBottom]}>
          <View style={[styles.invoiceNotes_blockCAA]}>
            <View style={styles.invoiceNotes_blockC}>
              <View style={styles.invoiceNotes_blockC2}>
                {Object.keys(invoice).includes('einvoice') && invoice.einvoice.length > 0 && (
                  <View style={styles.invoiceNotes_mb}>
                    <Text style={styles.invoiceNotes_irn}>IRN: {invoice.einvoice[0].irn}</Text>
                    <Text style={styles.invoiceNotes_irn}>
                      Acknowledgement Number: {invoice.einvoice[0].ack_no}, Date:{' '}
                      {invoice.einvoice[0].ack_date}
                    </Text>
                  </View>
                )}
                {invoice.rcm == 1 && (
                  <View style={styles.invoiceNotes_mb}>
                    <Text style={styles.invoiceNotes_heading}>
                      *Amount of Tax subject to Reverse Charge
                    </Text>
                  </View>
                )}
                {invoice.immovable_tax_type == 1 && (
                  <View style={styles.invoiceNotes_mb}>
                    <Text style={styles.invoiceNotes_heading}>
                      *Place of Supply is determined u/s 12(3) of the IGST Act 2017.
                    </Text>
                  </View>
                )}
                {invoice.is_export == 1 && invoice.company.gstin.length == 15 && (
                  <View style={styles.invoiceNotes_mb}>
                    <Text style={styles.invoiceNotes_heading}>
                      *{export_terms[invoice.export_details[0].export_type]}
                    </Text>
                  </View>
                )}
                {invoice.notes != '' && (
                  <View style={styles.invoiceNotes_block}>
                    <Text style={styles.invoiceNotes_heading}>Notes:</Text>
                    <Text style={styles.invoiceNotes_customerNotes}>{invoice.notes}</Text>
                  </View>
                )}
                {invoice.terms != '' && type != 'Delivery Challan' && (
                  <View style={[styles.invoiceNotes_block, styles.invoiceNotes_mt]}>
                    <Text style={styles.invoiceNotes_heading}>Terms and Conditions:</Text>
                    <Text style={styles.invoiceNotes_terms}>{invoice.terms}</Text>
                  </View>
                )}
                {invoice.invoice_settings.show_receivers_signature == 1 && (
                  <View style={styles.invoiceNotes_receiveSignBlock}>
                    <Text
                      style={{
                        ...styles.invoiceNotes_receiveSignText,
                      }}
                    >
                      Receiver's Signature{' '}
                    </Text>
                  </View>
                )}
              </View>
              {Object.keys(invoice).includes('einvoice') && invoice.einvoice.length > 0 && (
                <View style={styles.invoiceNotes_qrcode}>
                  <EnhancedQRCode value={`${invoice.einvoice[0].qrcode}`} level="L" />
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default invoiceNotes
