import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { Fragment } from 'react'

const styles = StyleSheet.create({
  headerContainer: {
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    '@media min-width: 400': {
      flexDirection: 'row',
    },
    marginBottom: 8,
    fontFamily: 'helreg_English',
    lineHeight: 1.26,
  },
  blockC: {
    width: '70%',
    flexDirection: 'row',
    paddingRight: 10,
  },
  blockC1: {
    width: '32%',
    paddingVertical: 4.5,
  },
  blockC2: {
    paddingVertical: 4.5,
    paddingRight: 5.4,
  },
  blockI: {
    width: '30%',
    paddingRight: 4.5,
  },
  block: {
    width: '162',
    marginRight: 40,
  },
  block2: {
    width: '34%',
    marginRight: 0,
  },
  block3: {
    marginRight: 75,
  },
  invoiceContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  invoiceDate: {
    fontSize: 9.9,
    color: '#141414',
  },
  label: {
    fontSize: 9,
    color: '#333333',
    textAlign: 'left',
    fontFamily: 'helbold_English',
    // fontFamily: 'helitalic',
  },
  address: {
    fontSize: 9,
  },
  bold: {
    fontFamily: 'helbold_English',
  },
  large: {
    fontSize: 15.3,
  },
  logo: {
    maxWidth: '99',
    maxHeight: '99',
    borderRadius: 9,
    position: 'relative',
    textAlign: 'center',
    marginHorizontal: 'auto',
    marginRight: 9,
  },
  brandLogo: {
    maxWidth: '99',
    maxHeight: '99',
    borderRadius: 9,
    position: 'relative',
    textAlign: 'center',
    marginHorizontal: 'auto',
    marginRight: 0,
  },
})

const CompanyDetails = ({ invoice, type, color, title }) => {
  var data = invoice.company_details
  var tax_id_label = invoice.company_details.tax_id_label
  return (
    <Fragment>
      <View style={styles.headerContainer}>
        <View style={styles.blockC}>
          {invoice.company_details.logo != null && invoice.company_details.logo != '' && (
            <View>
              <Image
                style={styles.logo}
                src={{
                  uri: invoice.company_details.logo,
                  method: 'GET',
                  headers: { 'Cache-Control': 'no-cache' },
                }}
                crossorigin="anonymous"
              />
            </View>
          )}
          <View style={styles.blockC2}>
            <Text
              style={[
                data.company_name.length > 40
                  ? { fontSize: (15.3 * 40) / data.company_name.length }
                  : styles.large,
                styles.bold,
              ]}
            >
              {data.company_name}
            </Text>
            {data.gstin.length == 15 && (
              <>
                <Text>
                  <Text style={styles.label}>{tax_id_label} </Text>
                  <Text style={[styles.invoiceDate, styles.bold]}>{data.gstin}</Text>
                </Text>
              </>
            )}
            <Text style={styles.address}>{data.address_1}</Text>
            <Text style={styles.address}>{data.address_2}</Text>
            <Text style={styles.address}>
              {data.city == ''
                ? ''
                : data.city +
                  (data.state == '' || data.state == undefined || data.state == null
                    ? ''
                    : ',')}{' '}
              {data.state == ''
                ? ''
                : data.state +
                  (data.pincode == '' || data.pincode == undefined || data.pincode == null
                    ? ''
                    : ',')}{' '}
              {data.pincode}{' '}
            </Text>
            <Text>
              {data.mobile != '' && (
                <>
                  <Text style={styles.label}>Mobile </Text>
                  <Text style={styles.invoiceDate}>
                    {data.mobile}
                    {data.alt_contact == '' ? '   ' : ', '}
                    {data.alt_contact}
                    {data.alt_contact != '' && '   '}
                  </Text>
                </>
              )}
            </Text>
            <Text>
              {data.email != '' && (
                <>
                  <Text style={styles.label}>Email </Text>
                  <Text style={styles.invoiceDate}>{data.email}</Text>
                </>
              )}
            </Text>
            {data.website != '' && (
              <>
                <Text>
                  <Text style={styles.label}>Website </Text>
                  <Text style={styles.invoiceDate}>{data.website}</Text>
                </Text>
              </>
            )}
          </View>
        </View>
        <View style={styles.blockI}>
          {data.paid == 0 && (
            <a src="https://getswipe.in" target="_blank">
              <Image
                style={styles.brandLogo}
                src={{
                  uri: '/resources/images/swipe_logo.png',
                  method: 'GET',
                  headers: { 'Cache-Control': 'no-cache' },
                }}
                crossorigin="anonymous"
              />
            </a>
          )}
        </View>
      </View>
    </Fragment>
  )
}

export default CompanyDetails
