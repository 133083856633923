import { Modal } from 'antd'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

const CustomDeleteModal = forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false)
  const [records, setRecords] = useState({})
  const [isDelete, setIsDelete] = useState(false)
  const [partyType, setPartyType] = useState()
  const [headerId, setHeaderId] = useState(-1)
  const [additionalChargeId, setAdditionalChargeId] = useState(-1)
  const [addressType, setAddressType] = useState('')
  const [addrId, setAddrId] = useState(-1)
  const showDeleteModal = (
    record,
    is_delete = false,
    party_type,
    header_id,
    additional_charge_id,
    address_type,
    addr_id,
  ) => {
    setIsVisible(true)
    setRecords({ ...record })
    setIsDelete(is_delete)
    setPartyType(party_type)
    setHeaderId(header_id)
    setAdditionalChargeId(additional_charge_id)
    setAddressType(address_type)
    setAddrId(addr_id)
  }
  useImperativeHandle(ref, () => ({
    showDeleteModal,
  }))
  const onCloseCustomFieldsModal = () => {
    setIsVisible(false)
  }
  return (
    <Modal
      title={
        <>
          <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>
          <span className="text-gray-500">Are you sure want to delete</span>{' '}
        </>
      }
      centered
      okText="Yes"
      cancelText="No"
      visible={isVisible}
      onCancel={onCloseCustomFieldsModal}
      destroyOnClose
      closeIcon={<i className="fa-solid fa-xmark"></i>}
      onOk={() => {
        props.onFinish({
          ...records,
          is_delete: isDelete,
          party_type: partyType,
          header_id: headerId,
          additional_charge_id: additionalChargeId,
          address_type: addressType,
          addr_id: addrId,
        })
        setIsVisible(false)
      }}
    >
      <></>
    </Modal>
  )
})

export default CustomDeleteModal
