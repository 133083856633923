import React from 'react'

const PageLoading = () => {
  return (
    <div id="root">
      <div className="initial__loading"></div>
    </div>
  )
}

export default PageLoading
