import {
  Affix,
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
import BizBadge from 'components/badges/biz'
import _ from 'lodash'
import _isEqual from 'lodash/isEqual'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import {
  ExclamationCircleOutlined,
  InboxOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import ProductPriceList from 'components/PriceList'
import InfoComponent from 'components/badges/InfoComponent'
import BetaBadge from 'components/badges/beta'
import ProBadge from 'components/badges/pro'
import FeatureAction from 'components/cleanui/cards/featureAction'
import CustomColumnsForm from 'components/forms/customColumns'
import AddPriceList from 'components/modal/addPriceList'
import AIModal, { AIButton } from 'components/modal/ai'
import ProductBarcode from 'components/modal/barcode'
import { BillOfMaterials } from 'components/modal/bom'
import ConvertToBatchSN from 'components/modal/convertToBatchSN'
import ExistingProductModal from 'components/modal/existingProductModal'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import SubscriptionUpdateModal from 'components/modal/subscriptionsUpdateModal'
import VisibilityModal from 'components/modal/visibility'
import AuditHistory from 'components/other/Activity'
import Batches from 'components/other/batches'
import MyEditor from 'components/other/editor'
import SectionHeader from 'components/other/sectionHeader'
import { capitalizeFirstLetters, getBase64 } from 'components/other/utility'
import Visibility from 'components/other/visibility'
import dayjs from 'dayjs'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'
import { history } from 'index'
import { DisabledInventoryTooltip } from 'pages/allproducts/product'
import queryString from 'query-string'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import {
  get_all_doc_counts,
  get_categories,
  get_product_options,
  get_top_custom_fields,
  get_units,
  set_all_doc_counts,
  set_custom_columns,
} from 'redux/document/actions'
import { getProductSettings } from 'redux/permissions/actions'
import { getAPI, getAPIData, getMultipartAPI } from 'services/jwt'
import store from 'store'
import { ADMIN } from 'utils/constants'
import { EnhancedAlternativeUnits } from './alternativeUnitsForm'
import CategoryForm from './categoryForm'
import InventoryForm from './inventoryForm'
import VariantForm from './variantForm'
import MultipleTaxRateForm from './MultipleTaxDrawer'
const { TabPane } = Tabs
const { Option } = Select
const { TextArea } = Input
const { Panel } = Collapse

const emptyData = {
  company_id: '',
  description: '',
  hsn_code: undefined,
  id: '',
  key: '',
  price: '',
  discount: 0,
  discount_amount: 0,
  is_price_with_tax: true,
  product_category: '',
  product_id: '',
  product_name: '',
  product_type: 'Product',
  tax: 0,
  barcode_id: '',
  avg_purchase_price: '',
  purchase_price: '',
  purchase_unit_price: 0,
  image: '',
  show_online: true,
  product_id: '',
  has_alternative_units: false,
  product_unit: '',
  cess: 0,
  not_for_sale: false,
  discount: 0,
  discountAmount: 0,
  show_discount_in: 0,
  has_batches: 0,
  opening_qty: 0,
  opening_purchase_price: 0,
  opening_value: 0,
  cess_on_qty: 0,
  low_stock: 0,
  cess_non_advl_rate: undefined,
  branch_price_confirmation_showed: false,
}

const priceListEmpty = {
  price_list_id: -1,
  name: '',
  price: 0,
  discount: 0,
  tax: 0,
  price_with_tax: 0,
}

const MAX_FILE_SIZE = 30 // MB
class ProductForm extends Component {
  formRef = React.createRef()
  innerFormRef = React.createRef()
  priceListForm = React.createRef()
  customFieldsForm = React.createRef()
  productAttachmentsForm = React.createRef()
  categoryForm = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  dateTimeFormat = 'DD-MM-YYYY hh:mm a'
  subscriptionPlanRef = React.createRef()
  ai = React.createRef()
  visibilityRef = React.createRef()
  existingProductRef = React.createRef()
  taxRateFormRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      isFirstTime: false,
      isEdit: false,
      formFlag: false,
      formData: { categories: [], product_types: [], taxes: [] },
      companyDetails: { gstin: '' },
      hsn_codes: [],
      units: [],
      hsn_code: '',
      unit: '',
      selectedData: {
        ...emptyData,
      },
      priceListData: {
        ...priceListEmpty,
      },
      product_type: 'Product',
      customFieldsData: {},
      fileList: [],
      productAttachments: [],
      price_lists: [],
      // updated_price_lists: [],
      previewVisible: false,
      activeKey: '1',
      previewImage: '',
      previewTitle: '',
      editorState: EditorState.createEmpty(),
      hsn_drawer_visble: false,
      selectedHSN: false,
      custom_columns: [],
      custom_columns_active: false,
      item_prices: [],
      loading: false,
      price_list_drawer: false,
      price_loading: false,
      saved: false,
      variants: [],
      has_alternative_units: false,
      alternative_units: [],
      primary_unit: '',
      product_details: {},
      selling_price: 0,
      discount: 0,
      discountAmount: 0,
      not_for_sale: false,
      show_online: true,
      is_purchase_price_with_tax: false,
      purchase_price: 0,
      purchase_unit_price: 0,
      loading: false,
      additional_cess_rates: [],
      batch_option_disabled: false,
      has_batches: 0,
      hsn_suggestions: [],
      custom_column_suggestions: [],
      variants_copy: [],
      is_duplicate: false,
      suggested_fields: [],
      product_audit_history: [],
      is_update_prices: true,
      branch_price_confirmation_showed: false,
      convertToBatchSnVisible: false,
      convertType: 0,
      is_batch_serial_conversion: false,
      has_multiple_tax: 0,
      multiple_tax_rates: [],
    }
    this.handelCloseDrawer = this.handelCloseDrawer.bind(this)
    this._onFinish = this._onFinish.bind(this)
    this.onFinish = _.throttle(this._onFinish, 2000)
  }

  async componentDidMount() {
    this.props.onRef && this.props.onRef(this)

    if (this.props.product_options == undefined) {
      this.props.dispatch(get_product_options())
    }
    if (!this.props.all_doc_counts) {
      this.props.dispatch(get_all_doc_counts())
    }
    if (this.props.top_custom_fields.length == 0) {
      this.props.dispatch(get_top_custom_fields())
    }
    if (this.props.is_branch && Object.keys(this.props.product_settings).length == 0) {
      this.props.dispatch(getProductSettings())
    }

    this.refreshCustomColumns()
    if (this.props.units.length == 0) {
      this.props.dispatch(get_units())
    }
  }

  getSuggestedColumns = async () => {
    const data = await getAPIData('v2/custom_fields', 'item')
    this.props.dispatch(
      set_custom_columns(
        data?.custom_columns?.map(item => {
          return {
            ...item,
            is_active: item.default_key != '' ? false : item.is_active,
            show_in_pdf: item.default_key != '' ? false : item.show_in_pdf,
          }
        }),
      ),
    )
    if (data) {
      let top_custom_fields = this.props.top_custom_fields.filter(item => {
        if (item.type == 'item') {
          return true
        }
      })
      top_custom_fields = top_custom_fields.filter(item => {
        if (data.custom_columns.find(column => column.name == item.name)) {
          return false
        } else {
          return true
        }
      })
      this.setState({ suggested_fields: top_custom_fields })
    }
    this.refreshCustomColumns()
  }
  addNewVariant = () => {
    let variants = [...this.state.variants]
    let min_id = 0
    variants.map(variant => {
      if (variant.id < min_id) {
        min_id = variant.id
      }
    })
    min_id -= 1
    variants.push({
      company_id: 1,
      barcode_id: '',
      discount: 0,
      discount_amount: 0,
      id: min_id,
      image: '',
      is_price_with_tax: 1,
      is_purchase_price_with_tax: 1,
      name: '',
      price: 0,
      price_with_tax: 0,
      purchase_price: 0,
      purchase_unit_price: 0,
      qty: 0,
      unit_price: 0,
      v2_qty: 0,
      has_batches: 0,
    })

    this.setState({ variants: variants })
  }
  onVariantChange = (field_name, value, variant_id) => {
    let variants = [...this.state.variants]
    let variant = variants.find(item => item.id == variant_id)
    let tax = parseFloat(this.formRef.current.getFieldValue('tax'))
    let cess = parseFloat(this.formRef.current.getFieldValue('cess'))
    if (
      (field_name == 'price_with_tax' ||
        field_name == 'unit_price' ||
        field_name == 'purchase_price' ||
        field_name == 'purchase_unit_price') &&
      value < 0
    ) {
      message.error(capitalizeFirstLetters(field_name.split('_').join(' ')) + ' cannot be negative')
      value = 0
    }
    variant[field_name] = value
    this.setState({ variants: variants })
  }

  handleMultipleTaxRatesChange = tax => {
    let updated_multiple_tax_rates = this.state.multiple_tax_rates
    const findIndex = updated_multiple_tax_rates.findIndex(item => item.tax == tax)

    // If a record with the same tax rate exists, set it as default
    // Otherwise overwrite the current default tax record with the updated tax_rate
    if (findIndex != -1) {
      updated_multiple_tax_rates = updated_multiple_tax_rates.map((item, idx) => ({
        ...item,
        is_default: idx == findIndex,
      }))
    } else {
      updated_multiple_tax_rates = updated_multiple_tax_rates.map(item => ({
        ...item,
        tax: item.is_default ? tax : item.tax,
      }))
    }

    this.setState({ multiple_tax_rates: updated_multiple_tax_rates })
  }

  ontaxChange = () => {
    var cess = parseFloat(
      this.formRef.current.getFieldValue('cess') ? this.formRef.current.getFieldValue('cess') : 0,
    )
    var tax = parseFloat(
      this.formRef.current.getFieldValue('tax') ? this.formRef.current.getFieldValue('tax') : 0,
    )

    // Change default tax in the MultipleTaxDrawer as well
    this.handleMultipleTaxRatesChange(tax)

    let purchase_price = this.state.purchase_price
    let purchase_unit_price = this.state.purchase_unit_price
    if (this.state.is_purchase_price_with_tax) {
      purchase_unit_price = purchase_price / (1 + (tax + cess) / 100)
    } else {
      purchase_price = purchase_unit_price * (1 + (tax + cess) / 100)
    }
    let variants = [...this.state.variants]

    this.setState(
      {
        purchase_price,
        purchase_unit_price,
      },
      () => {
        this.formRef.current.setFieldsValue({
          purchase_unit_price: this.state.is_purchase_price_with_tax
            ? this.state.purchase_price
            : this.state.purchase_unit_price,
          tax_type: this.props?.zero_tax_types?.[0]?.value,
        })
      },
    )
  }
  removeVaiant = variant_id => {
    let variants = [...this.state.variants]
    variants = variants.filter(item => item.id != variant_id)
    this.setState({ variants: variants })
  }

  refreshCustomColumns = async () => {
    let empty_columns_data = {}
    this.props.custom_columns_active.map(item => {
      empty_columns_data[item.name] = undefined
    })
    this.setState({
      empty_columns_data,
    })
    // await this.getSuggestedColumns()
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(null)
    this.onFinish.cancel()
  }

  onInnerFinish = async values => {
    this.setState({ selectedHSN: values.hsn_code, hsn_drawer_visble: false }, () => {
      const existing_data = this.formRef.current.getFieldValue('hsn_code')
      this.formRef.current.setFieldsValue({
        hsn_code: values.hsn_code,
      })
    })
  }

  onGSTINFinish = async values => {
    this.setState({ loading: true })
    var data = await getAPI('user', 'update_gstin', values)
    if (data?.success) {
      message.success(data.message)

      this.props.dispatch(get_product_options())
    }
    this.setState({ loading: false })
  }
  generateBarCode = () => {
    const barcode_size = store.get('barcode_size') || 10

    // generate barcode with barcode_size digits
    var barcode = Math.floor(Math.random() * Math.pow(10, barcode_size))
    barcode = barcode
      .toString()
      .substring(0, barcode_size)
      .padStart(barcode_size, '0')

    if (barcode.includes('-')) {
      barcode = barcode.replace('-', '')
    }
    this.formRef.current?.setFieldsValue({ barcode_id: barcode })
  }

  getStatusClass = data => {
    if (data < 0) {
      return 'bg-negative'
    } else if (data == 0 && data < 5) {
      return 'bg-gray-50'
    } else {
      return 'bg-positive'
    }
  }

  onProductAttachmentsFinish = async values => {
    if (this.state.selectedData.product_id) {
      var form_data = new FormData()

      for (var key in values) {
        if (key == 'file') {
          var file = values[key]
          if (file != undefined) {
            await Promise.all(
              values[key]['fileList'].map(item =>
                (async _ => {
                  var ext = item.name.split('.').pop()
                  if ('url' in item) {
                    //do nothing
                  } else {
                    form_data.append('files[]', item.originFileObj)
                  }
                  return
                })(),
              ),
            )
          } else {
            form_data.set(key, values[key])
          }
        }
      }

      form_data.append('product_id', this.state.selectedData.product_id)
      const data = await getAPI('product', 'add_product_attachments', form_data)
      if (data?.success) {
        message.success(data.message)
        this.updateAttachments()
      }
    } else {
      message.error('Please save the product first')
    }
  }

  _onFinish = async values => {
    if (this.state.discount > 100) {
      message.error('Discount% cannot be more than 100')
      return
    }

    if (values.product_type == 'Product' && values.hsn_code && values.hsn_code.startsWith('99')) {
      message.error('HSN code for product should not start with 99')
      return
    }
    if (values.product_type == 'Service' && values.hsn_code && !values.hsn_code.startsWith('99')) {
      message.error('HSN code for service should start with 99')
      return
    }
    if (
      this.props.is_branch &&
      !this.state.branch_price_confirmation_showed &&
      this.props.permissions.role == ADMIN
    ) {
      this.setState(
        {
          is_update_prices: this.props.product_settings.update_branch_prices == 1,
          branch_price_confirmation_showed: true,
        },
        () => {
          if (this.state.formFlag) {
            Modal.confirm({
              title: `Are you sure you want to update prices in all branches?`,
              icon: <ExclamationCircleOutlined />,
              content: (
                <>
                  <Checkbox
                    defaultChecked={this.state.is_update_prices}
                    onChange={e => {
                      this.setState({ is_update_prices: e.target.checked })
                    }}
                  >
                    Update prices in all branches
                  </Checkbox>
                </>
              ),
              okText: 'Submit',
              cancelButtonProps: { style: { display: 'none' } },
              onOk: () => {
                this._onFinish(values)
              },
            })
          } else {
            this.visibilityRef.current.showModal(values)
          }
        },
      )
      return
    }

    values = {
      visibility: this.props.product_settings?.visibility,
      ...values,
      is_price_with_tax: this.state.is_price_with_tax,
      is_purchase_price_with_tax: this.state.is_purchase_price_with_tax,
      not_for_sale: this.state.not_for_sale,
      purchase_price: this.state.purchase_price,
      purchase_unit_price: this.state.purchase_unit_price,
      discount_amount: this.state.discountAmount,
      discount: this.state.discount,
      show_discount_in: this.state.show_discount_in,
      has_batches: this.state.has_batches,
      is_update_prices:
        this.props.permissions.role == ADMIN
          ? this.state.is_update_prices
          : this.props.product_settings?.update_branch_prices,
    }
    let custom_fields = {}
    this.get_columns_list().map(item => {
      if (item.name && this.props.column_id_dict[item.name]) {
        if (item.field_type == 'date' || item.field_type == 'datetime') {
          custom_fields[this.props.column_id_dict[item.name]] = values[item.name]
            ? values[item.name].format(
                item.field_type == 'date' ? this.dateFormat : 'DD-MM-YYYY HH:mm:ss',
              )
            : ''
        } else {
          values[item.name] = values[item.name] ? values[item.name] : ''
          custom_fields[this.props.column_id_dict[item.name]] = values[item.name]
        }
      }
    })

    values.custom_fields = JSON.stringify(custom_fields)

    let cess = parseFloat(this.formRef?.current?.getFieldValue('cess'))
    let tax = parseFloat(this.formRef?.current?.getFieldValue('tax'))
    let variants = [...this.state.variants]
    variants = variants.map(variant => {
      if (!variant.is_price_with_tax) {
        variant.unit_price = parseFloat(variant.price_with_tax)
        variant.price_with_tax = variant.unit_price * (1 + (tax + cess) / 100)
      } else {
        variant.unit_price = parseFloat(variant.price_with_tax) / (1 + (tax + cess) / 100)
      }
      if (!variant.is_purchase_price_with_tax) {
        variant.purchase_unit_price = parseFloat(variant.purchase_price)
        variant.purchase_price = variant.purchase_unit_price * (1 + (tax + cess) / 100)
      } else {
        variant.purchase_unit_price = parseFloat(variant.purchase_price) / (1 + (tax + cess) / 100)
      }
      return variant
    })

    values.variants = variants

    if (values.price == '') {
      values.price = 0
    }

    if (isNaN(values.purchase_price) || values.purchase_price == null) {
      values.purchase_price = 0
    }
    if (isNaN(values.purchase_unit_price) || values.purchase_unit_price == null) {
      values.purchase_unit_price = 0
    }
    if (isNaN(values.discount_amount) || values.discount_amount == null) {
      values.discount_amount = 0
    }
    if (
      isNaN(values.opening_purchase_price) ||
      values.opening_purchase_price == null ||
      values.opening_purchase_price == ''
    ) {
      values.opening_purchase_price = 0
    }
    if (isNaN(values.opening_value) || values.opening_value == null || values.opening_value == '') {
      values.opening_value = 0
    }

    this.setState({ loading: true })

    var form_data = new FormData()

    for (var key in this.state.selectedData) {
      form_data.set(key, this.state.selectedData[key])
    }

    for (var key in values) {
      if (key == 'file') {
        var file = values[key]
        if (file != undefined) {
          await Promise.all(
            values[key]['fileList'].map(item =>
              (async _ => {
                if ('url' in item) {
                  //do nothing
                } else {
                  form_data.append('files[]', item.originFileObj)
                }
                return
              })(),
            ),
          )
        } else {
          form_data.set(key, values[key])
        }
      } else {
        form_data.set(key, values[key])
      }
    }

    form_data.set('has_alternative_units', this.state.has_alternative_units)
    form_data.set('has_multiple_tax', this.state.has_multiple_tax)
    form_data.set('multiple_tax_rates', JSON.stringify(this.state.multiple_tax_rates))
    form_data.set('variants', JSON.stringify(this.state.variants))
    if (this.state.has_alternative_units) {
      form_data.set('alternative_units', JSON.stringify(this.state.alternative_units))
    }

    form_data.set('description', stateToHTML(this.state.editorState.getCurrentContent()))

    // var url = 'add_products'
    var url = 'add'

    if (this.state.formFlag) {
      form_data.append('product_id', this.state.selectedData.product_id)
      form_data.append(
        'is_batch_serial_conversion',
        this.state.is_batch_serial_conversion == true ? 1 : 0,
      )
      url = 'update'
    }

    const data = await getMultipartAPI('product', url, form_data)
    this.setState({ loading: false })
    if (data && data.success) {
      try {
        message.success(data.message)
        this.props.dispatch({
          type: 'welcome/PRODUCTS_ADDED',
        })
      } catch (err) {}

      var editor_state = EditorState.createWithContent(
        stateFromHTML(data.product_details.description),
      )

      this.setState(
        {
          editorState: editor_state ? editor_state : EditorState.createEmpty(),
          variants: [],
          is_duplicate: false,
          has_multiple_tax: 0,
          multiple_tax_rates: [],
        },
        () => {
          const newData = {
            ...data.product_details,
            variants: values?.variants,
            discount_value: !this.state.show_discount_in
              ? (
                  (data.product_details.price_with_tax * data.product_details.discount) /
                  100
                ).toFixed(2)
              : this.state.discountAmount,
            id: data.product_id,
            qty: 1,
            qtyinstock: data.product_details.qty,
            editorState: EditorState.createWithContent(
              stateFromHTML(data.product_details.description),
            ),
          }
          this.props.onAPICalled(
            {
              ...newData,
              with_tax: this.props.company_details?.gstin?.length == 15,
            },
            url == 'add',
          )
          this.setState(
            {
              loading: false,
              file: undefined,
              visible: false,
              editorState: EditorState.createEmpty(),
              fileList: [],
              saved: false,
              batch_option_disabled: false,
              has_batches: 0,
            },
            () => this.formRef?.current?.resetFields(),
          )
        },
      )
      const payload = {
        ...this.props.all_doc_counts,
        products: 2,
      }

      this.props.dispatch(set_all_doc_counts(payload))

      if (
        this.props.invoice_settings.update_subscription_item_price &&
        this.state.formFlag &&
        data?.is_change_in_prices
      ) {
        // Subscriptions update confirmation
        let req = {
          product_id: this.state.selectedData.product_id,
          variant_id: 0,
          batch_id: 0,
        }
        const subscriptionData = await getAPI('utils', 'get_active_subscriptions_product', req)

        if (subscriptionData && subscriptionData.count > 0) {
          // show subscription update modal
          this.setState({
            showSubscriptionUpdateModal: true,
            activeSubscriptions: subscriptionData.active_subscriptions,
          })
        }
      }
    }

    this.setState({
      loading: false,
      is_duplicate: false,
      branch_price_confirmation_showed: false,
      is_batch_serial_conversion: false,
    })
  }

  onAdd = async (product_name = '', qty = 1, details = {}) => {
    this.setState(
      {
        visible: true,
        formFlag: false,
        saved: false,
        selectedData: emptyData,
        fileList: [],
        alternative_units: [],
        has_alternative_units: false,
        is_price_with_tax: true,
        product_type: this.props.invoice_settings.product_type,
        show_online: true,
        purchase_price: 0,
        purchase_unit_price: 0,
        show_discount_in: 0,
        editorState: EditorState.createEmpty(),
        discount: 0,
        discountAmount: 0,
        variants_copy: [],
        variants: [],
      },
      () => {
        var price_list_data = {}
        this.props.price_lists.map((item, index) => {
          price_list_data[item.name] = ''
        })
        this.formRef?.current?.setFieldsValue({
          ...this.state.empty_columns_data,
          ...emptyData,
          ...price_list_data,
          product_name: product_name,
          // discount: 0,
          // discount_amount: 0,
          is_price_with_tax: true,
          product_type: this.props.invoice_settings.product_type,
          product_category: undefined,
          tax: details?.tax ?? 0,
          tax_type: details?.tax_type ?? this.props?.zero_tax_types?.[0]?.value,
          unit_price: details.unit_price ?? '',
          purchase_price: details.purchase_price ?? '',
          purchase_unit_price: details.purchase_unit_price ?? '',
          price: details.price ?? '',
        })
        this.setState({
          product_copy: {
            ...this.formRef?.current?.getFieldsValue(),
            is_price_with_tax: true,
            product_type: 'Product',
            show_online: true,
            purchase_price: 0,
            purchase_unit_price: 0,
            show_discount_in: 0,
            editorState: EditorState.createEmpty(),
            discount: 0,
            discountAmount: 0,
          },
        })
      },
    )
    await this.getSuggestedColumns()
  }

  updateAttachments = async () => {
    if (this.state.selectedData.product_id) {
      const data = await getAPI('product', 'get_product_attachments', {
        product_id: this.state.selectedData.product_id,
      })
      if (data?.success) {
        this.setState({
          productAttachments: data.attachments,
        })
      }
    } else {
      message.error('Please save the product first')
    }
  }

  refreshPriceList = async () => {
    await this.props.dispatch(get_product_options())
  }

  onFill = async (data, is_duplicate = false) => {
    var req = {
      product_id: data.product_id,
    }
    var product_data = await getAPI('product', 'get_details', req)
    if (!product_data?.success || !product_data?.['product_details']?.length) {
      return
    }
    var editor_state = EditorState.createWithContent(
      stateFromHTML(product_data?.['product_details']?.[0]?.['description']),
    )
    if (product_data?.['product_details']?.length) {
      product_data['product_details'][0].variants = product_data[
        'product_details'
      ]?.[0]?.variants?.map(variant => ({
        ...variant,
        id: is_duplicate ? -1 : variant.id,
        variant_id: is_duplicate ? -1 : variant.id,
        product_name: product_data['product_details'][0].product_name + ' ' + variant.name,
        units: product_data['product_details'][0].units,
        price_with_tax: variant.is_price_with_tax ? variant.price_with_tax : variant.unit_price,
        purchase_price: variant.is_purchase_price_with_tax
          ? variant.purchase_price
          : variant.purchase_unit_price,
        barcode_id: is_duplicate ? '' : variant.barcode_id,
      }))
    }
    if (product_data?.['product_details']?.[0]?.['hsn_code'] == '') {
      product_data['product_details'][0]['hsn_code'] = undefined
    }
    let custom_fields = {}
    product_data['product_details'][0]['custom_fields'].map(item => {
      if (item.value == '') {
        item.value = undefined
      }
      if (item.field_type == 'date' || item.field_type == 'datetime') {
        const dateFormatRegex = /^\d{2}-\d{2}-\d{4}/
        if (item.value && !dateFormatRegex.test(item.value)) {
          item.value = undefined
        } else {
          item.value = item.value ? dayjs(item.value, 'DD-MM-YYYY') : undefined
        }
      }

      custom_fields[item.name] = item.value
    })

    product_data['product_details'][0] = {
      ...product_data['product_details'][0],
      ...custom_fields,
      barcode_id: is_duplicate ? '' : product_data['product_details'][0].barcode_id,
    }

    data = product_data['product_details'][0]
    let variants = [...data['variants']]
    variants = variants.map(variant => ({
      ...variant,
    }))
    this.setState(
      {
        is_duplicate: is_duplicate,
        visible: true,
        formFlag: !is_duplicate,
        saved: true,
        selectedData: data,
        fileList: data['product_images'],
        price_lists: data['price_lists'],
        variants: [...variants],
        variants_copy: _.cloneDeep(variants),
        editorState: editor_state ? editor_state : EditorState.createEmpty(),
        product_id: is_duplicate ? -1 : data.product_id,
        company_id: data.company_id,
        primary_unit: data['unit'],
        product_unit: data['unit'],
        alternative_units: data['units'],
        has_alternative_units: data['has_alternative_units'],
        has_multiple_tax:
          data['has_multiple_tax'] || (data['multiple_tax_rates'].length > 0 ? 1 : 0) || 0,
        product_details: data,
        selling_price: data.price,
        discount: data.discount,
        is_price_with_tax: data['is_price_with_tax'],
        is_purchase_price_with_tax: data['is_purchase_price_with_tax'] == 1,
        multiple_tax_rates: data['multiple_tax_rates'] || [],
        product_type: product_data.product_details[0].product_type,
        not_for_sale: product_data.product_details[0].not_for_sale == 1,
        show_online: product_data.product_details[0].show_online == 1,
        purchase_unit_price: data['purchase_unit_price'],
        purchase_price: data['purchase_price'],
        show_discount_in: data['show_discount_in'],
        discountAmount: data['discount_amount'],
        has_batches: data['has_batches'],
        batch_option_disabled: data['has_batches'] != 0,
      },
      () => {
        this.formRef?.current?.setFieldsValue({
          ...product_data['product_details'][0],
          ...product_data['product_details'][0]['item_prices'],
          ...product_data['product_details'][0],
          product_category:
            product_data['product_details'][0]['product_category'] != ''
              ? product_data['product_details'][0]['product_category']
              : undefined,
          file: null,
          discount: this.state.show_discount_in ? this.state.discountAmount : this.state.discount,
          description: this.state.editorState.getCurrentContent(),
          purchase_unit_price: this.state.is_purchase_price_with_tax
            ? product_data['product_details'][0]['purchase_price']
            : product_data['product_details'][0]['purchase_unit_price'],

          has_batches: product_data['product_details'][0]['has_batches'],
          ...custom_fields,
        })
        this.setState({
          product_copy: {
            ...this.formRef?.current?.getFieldsValue(),
            selling_price: product_data['product_details'][0].price,
            discount: product_data['product_details'][0].discount,
            is_price_with_tax: product_data['product_details'][0]['is_price_with_tax'],
            product_type: product_data.product_details[0].product_type,
            not_for_sale: product_data.product_details[0].not_for_sale == 1,
            show_online: product_data.product_details[0].show_online == 1,
            purchase_unit_price: product_data['product_details'][0]['purchase_unit_price'],
            purchase_price: product_data['product_details'][0]['purchase_price'],
            show_discount_in: product_data['product_details'][0]['show_discount_in'],
            discountAmount: product_data['product_details'][0]['discount_amount'],
            has_batches: product_data['product_details'][0]['has_batches'],
            batch_option_disabled: product_data['product_details'][0]['has_batches'] != 0,
          },
        })
      },
    )
  }

  customRequest = async ({ onSuccess, onError, file }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }
    this.setState({
      file: file,
      selectedData: { ...this.state.selectedData, image: file.url || file.preview },
    })
  }

  handleSelect = () => {
    const existing_data = this.formRef.current.getFieldValue('product_category')
    if (existing_data.length > 1) {
      this.formRef.current.setFieldsValue({
        product_category: existing_data[existing_data.length - 1],
      })
    }
  }

  handleHSNSearch = async value => {
    if (value && value != '') {
      var req = {
        query: value,
      }
      var data = await getAPI('utils', 'search_hsn_codes', req)
      this.setState({ hsn_codes: data?.hsn_codes ?? [] })
    } else {
      this.setState({ hsn_codes: [] })
    }
  }

  handleRemoveProductImage = async file => {
    if (this.state.selectedData.product_id && file.url) {
      const req = {
        product_id: this.state.selectedData.product_id,
        id: file.uid,
        url: file.url,
      }
      const data = await getAPI('product', 'delete_product_image', req)
      if (data && data.success) {
        message.success('Image deleted successfully')
        this.setState({ fileList: this.state.fileList.filter(item => item.uid != file.uid) })
      } else {
        message.error('Something went wrong! Try again later')
      }
    }
  }

  onRemoveProductAttachment = async file => {
    if (this.state.selectedData.product_id && file.url) {
      const req = {
        product_id: this.state.selectedData.product_id,
        id: file.uid,
        url: file.url,
      }
      const data = await getAPI('product', 'delete_product_attachment', req)
      if (data?.success) {
        message.success('Attachment deleted successfully')
      } else {
        message.error('Something went wrong! Try again later')
      }
    }
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  handleChange = ({ fileList, file }) => {
    if (file.status != 'removed') {
      if (file.size / 1024 / 1024 < 3) {
        this.setState({ fileList: fileList })
      } else {
        message.error('Image must smaller than 3MB')
      }
    } else {
      const newFiles = this.state.fileList.filter(file1 => file1.uid !== file.uid)
      this.setState({ fileList: newFiles })
    }
  }

  handleAttachmentChange = ({ fileList }) => {
    let total_attachments_size = fileList
      .map(file => file.size)
      .reduce((a, b) => a * 0.000001 + b * 0.000001, 0)
    if (total_attachments_size > MAX_FILE_SIZE) {
      message.error('Total attachments size should not be more than 30MB')
    } else {
      this.setState({ productAttachments: fileList })
    }
  }

  showHSNSearchDrawer = () => {
    this.setState({
      hsn_drawer_visble: true,
    })
  }

  onHSNSearchDrawerClose = () => {
    this.setState({
      hsn_drawer_visble: false,
    })
  }

  setDiscountAmount(value) {
    if (this.state.selling_price) {
      if (!this.state.show_discount_in) {
        this.setState({
          discount: value,
          discountAmount: (value * this.state.selling_price) / 100,
        })
      } else {
        this.setState({
          discount: (value / this.state.selling_price) * 100,
          discountAmount: value,
        })
      }
    } else {
      this.setState({
        discount: value,
        discountAmount: 0,
      })
    }
  }

  showGSTDrawer = () => {
    this.setState({ gst_drawer: true })
  }

  closeGSTDrawer = () => {
    this.setState({ gst_drawer: false })
  }

  purchasePriceChange = e => {
    var tax = parseFloat(
      this.formRef.current.getFieldValue('tax') ? this.formRef.current.getFieldValue('tax') : 0,
    )
    var cess = parseFloat(
      this.formRef.current.getFieldValue('cess') ? this.formRef.current.getFieldValue('cess') : 0,
    )
    tax = tax + cess
    let value = parseFloat(e)
    let purchase_price = 0
    let purchase_unit_price = 0
    if (this.state.is_purchase_price_with_tax) {
      purchase_price = e
      purchase_unit_price = parseFloat(value / (1 + tax / 100))
    } else {
      purchase_unit_price = e
      purchase_price = parseFloat(value * (1 + tax / 100))
    }
    this.setState(
      {
        purchase_unit_price: purchase_unit_price,
        purchase_price: purchase_price,
      },
      () => {
        purchase_price = this.state.is_purchase_price_with_tax
          ? this.state.purchase_price
          : this.state.purchase_unit_price

        this.formRef.current.setFieldsValue({
          purchase_unit_price: purchase_price,
        })
      },
    )
  }

  storeAlternativeUnits = async units => {
    this.setState({ alternative_units: units })
  }

  storeMultipleTaxRates = taxRates => {
    this.setState({ multiple_tax_rates: taxRates, has_multiple_tax: 1 }, async () => {
      await this.formRef.current.setFieldsValue({
        tax: taxRates.find(item => item.is_default).tax,
      })

      this.ontaxChange()
    })
  }

  onTabChange(key) {
    this.setState(
      {
        activeKey: key,
      },
      () => {
        if (key == '4') {
          this.updateAttachments()
        }
        if (key == 'audit_history') {
          this.getAuditHistory()
        }
      },
    )
  }
  getAuditHistory = async () => {
    this.setState({ loading: true })
    const data = await getAPIData(
      'product',
      'get_history?' +
        queryString.stringify({
          product_id: this.state.selectedData.product_id,
        }),
    )
    if (data?.success) {
      this.setState({ product_audit_history: data.audit_history })
    }
    this.setState({ loading: false })
  }
  renderCustomColumnOptions = header_id => {
    let options = []
    this.props.custom_column_suggestions?.[header_id]?.map((item, index) => {
      options.push({
        value: item,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {item}
          </div>
        ),
      })
    })
    return options
  }
  handleAddBOM = async item => {
    const data = await getAPI('product', 'check_bom', {
      product_id: item.product_id,
      variant_id: item.variant_id,
    })
    if (data?.success) {
      if (data.exists) {
        notification.open({
          message: 'Error',
          description: data.message,
          duration: 10,
          icon: <i className="fa fa-exclamation-circle text-danger mr-2" />,
          style: {
            borderRadius: '10px',
            backgroundColor: '#ffffff',
          },
        })
      } else {
        this.bom.addBOM(item)
      }
    }
  }

  handleAddBatches = async item => {
    this.batches.onShow(item)
  }
  get_columns_list = () => {
    // if (this.state.formFlag) return this.state.product_details.custom_fields
    if (this.state.variants.length > 0) {
      return this.props.custom_columns_active.filter(
        item => item.applicable_for == 'product' && !item.document_types.includes('packing_list'),
      )
    } else if (this.state.selectedData.has_batches != 0) {
      return this.props.custom_columns_active.filter(
        item => item.applicable_for == 'product' && !item.document_types.includes('packing_list'),
      )
    }
    return this.props.custom_columns_active.filter(
      item => !item.document_types.includes('packing_list'),
    )
  }
  onChangeInventory = e => {
    if (e == 0) {
      this.setState({ is_batch_serial_conversion: false })
    }
    notification.destroy()

    if (this.state.saved && e != 0) {
      // if converting a product with qty to batch/serial
      this.setState({
        convertToBatchSnVisible: true,
        convertType: e,
      })
      return
    }

    this.setState({
      has_batches: e,
    })
  }
  handelCloseDrawer = () => {
    const obj1 = {
      ...this.formRef.current.getFieldsValue(),
      selling_price: this.state.selling_price,
      discount: this.state.discount,
      is_price_with_tax: this.state.is_price_with_tax,
      product_type: this.state.product_type,
      not_for_sale: this.state.not_for_sale,
      show_online: this.state.show_online,
      purchase_unit_price: this.state.purchase_unit_price,
      purchase_price: this.state.purchase_price,
      show_discount_in: this.state.show_discount_in,
      discountAmount: this.state.discountAmount,
      has_batches: this.state.has_batches,
      batch_option_disabled: this.state.batch_option_disabled,
    }
    const obj2 = this.state.product_copy

    const sharedKeys = _.intersection(Object.keys(obj1), Object.keys(obj2))

    const sharedObj1 = _.pick(obj1, sharedKeys)
    const sharedObj2 = _.pick(obj2, sharedKeys)

    const areSharedPairsEqual = _isEqual(sharedObj1, sharedObj2)

    const commonObjects = this.state.variants.filter(obj1 =>
      this.state.variants_copy.some(obj2 => _isEqual(obj1, obj2)),
    )
    if (commonObjects.length != this.state.variants_copy.length || !areSharedPairsEqual) {
      Modal.confirm({
        title: 'Do you want to exit?',
        content: 'You will lose your unsaved data.',
        icon: <ExclamationCircleOutlined />,
        okText: 'Confirm',
        onOk: () => {
          this.setState(
            {
              visible: false,
              item_prices: [],
              variants: [],
              batch_option_disabled: false,
              has_batches: 0,
              activeKey: '1',
              product_audit_history: [],
              is_batch_serial_conversion: false,
              multiple_tax_rates: [],
              has_multiple_tax: 0,
            },
            () => this.formRef?.current?.resetFields(),
          )
        },
        onCancel: () => {},
      })
    } else {
      this.setState(
        {
          visible: false,
          item_prices: [],
          variants: [],
          batch_option_disabled: false,
          has_batches: 0,
          activeKey: '1',
          product_audit_history: [],
          is_batch_serial_conversion: false,
          multiple_tax_rates: [],
          has_multiple_tax: 0,
        },
        () => this.formRef?.current?.resetFields(),
      )
    }
  }
  addRecommededField = async data => {
    const req = {
      ...data,
    }
    await getAPI('v2/custom_fields', 'item', req)
    await this.getSuggestedColumns()
    if (this.state.formFlag) {
      this.onFill(this.state.selectedData)
    }
  }

  onAIContinue = (key, value) => {
    if (key == 'editorState') {
      this.setState({
        editorState: EditorState.createWithContent(stateFromHTML(value)),
      })
    } else {
      this.setState({ [key]: value })
    }
  }
  checkExistingProduct = async e => {
    var req = {
      product_name: e,
      product_id: this.state.selectedData.product_id,
    }
    const data = await getAPI('product', 'check_existing_product', req)
    if (data) {
      if (data.warning) {
        this.existingProductRef?.current?.showModal(data.product_details)
      }
    }
  }

  handleAddMultipleTaxes = e => {
    e.preventDefault()

    let initialTaxRates = this.state.multiple_tax_rates

    if (initialTaxRates.length == 0) {
      var tax = parseFloat(
        this.formRef.current.getFieldValue('tax') ? this.formRef.current.getFieldValue('tax') : '0',
      )
      initialTaxRates = [{ tax, is_default: true }]
    }

    this.taxRateFormRef.current?.openDrawer(initialTaxRates)
  }

  render() {
    const { variants } = this.state
    const formItemLayout = {
      layout: 'vertical',
      labelCol: {
        sm: { span: 12 },
        md: { span: 24 },
      },
      wrapperCol: {
        sm: { span: 12 },
        md: { span: 24 },
      },
    }

    const innnerFormItemLayout = {
      labelCol: {
        xs: { span: 0 },
        sm: { span: 0 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )
    const uploadAttachmentsButton = (
      <div>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text text-gray-500">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint text-gray-500">
          Support for a single or multiple files upload. (Max 5 Attachments)
        </p>
      </div>
    )

    const normFile = e => {
      if (Array.isArray(e)) {
        return e
      }
      return e && e.fileList
    }

    let record = this.state.product_details

    const dropdownMenuVarinats = currentData => {
      return (
        <Menu>
          {this.props.permissions.bom == 1 && (
            <Menu.Item
              key={5}
              onClick={e => {
                e.domEvent.stopPropagation()
                this.handleAddBOM({
                  ...currentData,
                  product_name: record.product_name,
                  product_id: record.product_id,
                  variant_id: currentData.id,
                })
              }}
            >
              <i className="fa-regular fa-object-group fa-fw mr-3"></i>
              <span className="hidden sm:inline font-weight-bold">
                {currentData.has_bom ? 'Edit Group' : <FormattedMessage id="button.bom" />}
              </span>
            </Menu.Item>
          )}

          {(this.props.integrations.batching == 1 || this.props.integrations.serial_number == 1) &&
            currentData.has_batches != 0 && (
              <Menu.Item
                key={2}
                onClick={e => {
                  e.domEvent.stopPropagation()
                  this.handleAddBatches({
                    ...currentData,
                    product_name: record.product_name + '_' + currentData.name,
                    product_id: record.product_id,
                    variant_id: currentData.id,
                  })
                }}
              >
                <i className="fa-duotone fa-boxes-stacked mr-3"></i>
                <span className="hidden sm:inline font-weight-bold">
                  <FormattedMessage id="button.batches" />
                </span>
              </Menu.Item>
            )}

          {this.props.permissions.inventory_edit == 1 && (
            <Menu.Item
              key={3}
              size="small"
              className="font-weight-bold mr-2 my-1"
              onClick={e => {
                e.domEvent.stopPropagation()
                if (currentData.has_batches == 2) {
                  this.handleAddBatches({
                    ...currentData,
                    product_name: record.product_name + '_' + currentData.name,
                    product_id: record.product_id,
                    variant_id: currentData.id,
                  })
                  return
                }
                this.inventoryVariantForm.onFill(
                  { ...currentData, units: record.units, variant_id: currentData.id },
                  true,
                )
              }}
              disabled={
                record.product_type === 'Service' &&
                this.props.invoice_settings.track_stock_for_services === 0
              }
            >
              <Tooltip
                title={
                  record.product_type === 'Service' &&
                  this.props.invoice_settings.track_stock_for_services === 0 ? (
                    <DisabledInventoryTooltip />
                  ) : null // No tooltip when the item is not disabled
                }
                placement="right" // Adjust the tooltip placement as needed
              >
                <div>
                  <i className="fa-regular fa-circle-arrow-down fa-lg text-forest mr-3 " />
                  <span className="hidden sm:inline font-weight-bold">
                    <FormattedMessage id="button.stockIn" />
                  </span>
                </div>
              </Tooltip>
            </Menu.Item>
          )}

          {this.props.permissions.inventory_edit == 1 && (
            <Menu.Item
              key={4}
              size="small"
              className="font-weight-bold my-1"
              onClick={e => {
                e.domEvent.stopPropagation()
                if (currentData.has_batches == 2) {
                  this.handleAddBatches({
                    ...currentData,
                    product_name: record.product_name + '_' + currentData.name,
                    product_id: record.product_id,
                    variant_id: currentData.id,
                  })
                  return
                }
                this.inventoryVariantForm.onFill(
                  { ...currentData, units: record.units, variant_id: currentData.id },
                  false,
                )
              }}
              disabled={
                record.product_type === 'Service' &&
                this.props.invoice_settings.track_stock_for_services === 0
              }
            >
              <Tooltip
                title={
                  record.product_type === 'Service' &&
                  this.props.invoice_settings.track_stock_for_services === 0 ? (
                    <DisabledInventoryTooltip />
                  ) : null // No tooltip when the item is not disabled
                }
                placement="right" // Adjust the tooltip placement as needed
              >
                <div>
                  <i className="fa-regular fa-circle-arrow-up fa-lg text-danger mr-3 " />
                  <span className="hidden sm:inline font-weight-bold">
                    <FormattedMessage id="button.stockOut" />
                  </span>
                </div>
              </Tooltip>
            </Menu.Item>
          )}
          <Menu.Item
            key={5}
            onClick={e => {
              e.domEvent.stopPropagation()
              this.setState({ show_share: true, selectedProduct: currentData })
            }}
          >
            <i className="fa-solid fa-share fa-fw mr-3 " />
            <span className="hidden sm:inline font-weight-bold">
              <FormattedMessage id="button.share" />
            </span>
          </Menu.Item>
          {this.props.permissions.product_delete == 1 && (
            <Menu.Item
              key={6}
              onClick={e => {
                e.domEvent.stopPropagation()

                this.variantForm.handleDelete(
                  currentData.id,
                  currentData.company_id,
                  currentData.product_id,
                )
              }}
            >
              <a className="text-danger">
                <i className="fa-solid fa-trash fa-fw mr-3" />
                <span className="font-weight-bold">Delete </span>
              </a>
            </Menu.Item>
          )}
        </Menu>
      )
    }
    let variant_columns = [
      {
        title: <FormattedMessage id="table.name" />,
        dataIndex: 'name',
        key: 'name',
        width: '200px',
        render: (text, item) => {
          return (
            <span className="text-gray-900 font-weight-medium">
              <Input
                type="text"
                value={text}
                onChange={e => this.onVariantChange('name', e.target.value, item.id)}
              />

              {item.has_batches != 0 && (
                <span
                  onClick={e => {
                    e.stopPropagation()
                    this.handleAddBatches({
                      ...item,
                      variant_id: item.id,
                      name: '',
                    })
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <Tooltip
                    placement="top"
                    title={'This Product has batches. Click to view the batches'}
                  >
                    <span>
                      <i className="fa-light fa-cubes  mx-2"></i>
                    </span>
                  </Tooltip>
                </span>
              )}
            </span>
          )
        },
      },
      {
        title: <FormattedMessage id="table.quantity" />,
        dataIndex: 'qty',
        width: '68px',
        key: 'qty',
        render: (text, item) => {
          return {
            props: {
              className: this.getStatusClass(item.qty),
            },
            children: !(
              record.product_type === 'Service' &&
              this.props.invoice_settings.track_stock_for_services === 0
            ) ? (
              <span className="font-weight-bold">
                {item.qty}
                {record.unit !== 'OTH' && (
                  <span className="font-size-12 font-weight-bold text-purple ml-2">
                    {record.unit}
                  </span>
                )}
              </span>
            ) : (
              <Tooltip title={<DisabledInventoryTooltip />}>
                <span className="font-weight-bolder"> - </span>
              </Tooltip>
            ),
          }
        },
      },
      {
        title: 'Selling Price',
        dataIndex: 'price_with_tax',
        key: 'price_with_tax',
        width: '250px',
        render: (text, item) => {
          return (
            <span>
              <span className="font-weight-bold">
                <Input
                  type="number"
                  value={item.price_with_tax}
                  onChange={e => this.onVariantChange('price_with_tax', e.target.value, item.id)}
                  addonAfter={
                    this.props.company_details?.gstin?.length == 15 && (
                      <Select
                        style={{ width: 120 }}
                        onChange={e => {
                          this.onVariantChange('is_price_with_tax', e, item.id)
                        }}
                        value={item.is_price_with_tax == true}
                      >
                        <Option value={true}>with Tax</Option>
                        <Option value={false}>without Tax</Option>
                      </Select>
                    )
                  }
                />
              </span>
              {/* {item.discount != 0 && (
                <span className="text-green"> ({parseFloat(item.discount).toFixed(2)}%)</span>
              )} */}
            </span>
          )
        },
      },
      {
        title: 'Purchase Price',
        dataIndex: 'purchase_price',
        key: 'purchase_price',
        width: '250px',
        render: (text, item) => {
          return (
            <span>
              <Input
                type="number"
                value={item.purchase_price}
                onChange={e => this.onVariantChange('purchase_price', e.target.value, item.id)}
                addonAfter={
                  this.props.company_details.gstin.length == 15 && (
                    <Select
                      style={{ width: 120 }}
                      onChange={e => {
                        this.onVariantChange('is_purchase_price_with_tax', e, item.id)
                      }}
                      value={item.is_purchase_price_with_tax == true}
                    >
                      <Option value={true}>with Tax</Option>
                      <Option value={false}>without Tax</Option>
                    </Select>
                  )
                }
              />
            </span>
          )
        },
      },
      {
        title: 'Barcode',
        dataIndex: 'barcode_id',
        key: 'barcode_id',
        width: '200px',
        render: (text, item) => {
          return (
            <span className="text-gray-900 font-weight-medium">
              <Input
                type="text"
                value={text}
                onChange={e => this.onVariantChange('barcode_id', e.target.value, item.id)}
              />
            </span>
          )
        },
      },
      {
        title: 'Actions',
        align: 'right',
        key: 'id',
        render: (text, item) => {
          return (
            <>
              {/* {this.state.edit && ( */}
              <span className="float-right flex">
                {item.barcode_id != '' && item.id > 0 && (
                  <Button
                    type="action-3"
                    size="small"
                    onClick={event => {
                      this.barcode.showModal({
                        ...item,
                        product_name: record.product_name,
                      })
                    }}
                    className="mr-2 font-weight-bold"
                    style={{ width: '34.28px' }}
                  >
                    <i className="fa fa-barcode mx-1" />
                  </Button>
                )}

                {item.id > 0 && (
                  <Button
                    type="warning"
                    size="small"
                    onClick={e => {
                      e.stopPropagation() || this.variantForm.onEditClick(item, record)
                    }}
                    className="mr-2"
                  >
                    <i className="fa-solid fa-edit mr-1" />
                    <span className="hidden sm:inline font-weight-bold">
                      <FormattedMessage id="button.edit" />
                    </span>
                  </Button>
                )}
                {item.id > 0 && (
                  <Dropdown
                    overlay={dropdownMenuVarinats(item)}
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <Button size="small" type="info">
                      <i className="fa-solid fa-chevron-down font-weight-bolder" />
                    </Button>
                  </Dropdown>
                )}
                {item.id < 0 && (
                  <Button
                    size="small"
                    type="danger"
                    className="mr-2"
                    onClick={() => this.removeVaiant(item.id)}
                  >
                    <i className="fa fa-trash mr-1 ml-1" />
                  </Button>
                )}
              </span>
              {/* )} */}
            </>
          )
        },
      },
    ]
    if (!this.state.formFlag) {
      variant_columns = variant_columns.filter(item => item.key != 'qty')
    }

    let batchOptions = [
      {
        title: 'Default',
        key: '0',
        value: 0,
        hidden: !this.props.integrations.batching && !this.props.integrations.serial_number,
      },
      {
        title: 'Batches',
        key: '1',
        value: 1,
        hidden: !this.props.integrations.batching,
      },
      {
        title: 'Serial Number or IMEI',
        key: '2',
        value: 2,
        hidden: !this.props.integrations.serial_number,
      },
    ]
    batchOptions = batchOptions.filter(item => !item.hidden)
    return (
      <>
        <Drawer
          title={this.state.formFlag ? 'Update Item' : 'Add Item'}
          width={isMobile ? '100%' : '60%'}
          height={isMobile ? '90%' : '100%'}
          placement={isMobile ? 'top' : 'right'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          destroyOnClose
          maskClosable={false}
          onClose={
            () => this.handelCloseDrawer()
            // this.setState(
            //   {
            //     visible: false,
            //     item_prices: [],
            //     variants: [],
            //     batch_option_disabled: false,
            //     has_batches: 0,
            //     activeKey: '1',
            //   },
            //   () => this.formRef?.current?.resetFields(),
            // )
          }
          open={this.state.visible}
          // destroyOnClose={true}
          extra={
            <Space>
              <Button
                onClick={() => this.formRef.current.submit()}
                type="primary"
                className="font-weight-bold"
                loading={this.state.loading}
              >
                {this.state.formFlag ? 'Update Item' : 'Add Item'}
              </Button>
            </Space>
          }
          footer={
            <>
              {this.state.activeKey == '1' && (
                <Button
                  onClick={() => this.formRef.current.submit()}
                  type="primary"
                  loading={this.state.loading}
                >
                  {this.state.formFlag ? 'Update Item' : 'Add Item'}
                </Button>
              )}

              {this.state.activeKey == '4' && (
                <Button
                  onClick={() => this.productAttachmentsForm.current.submit()}
                  type="primary"
                  loading={this.state.loading}
                  disabled={!this.state.saved}
                >
                  Save Attachments
                </Button>
              )}
            </>
          }
        >
          <Tabs
            size="form"
            onChange={key => {
              this.onTabChange(key)
            }}
            tabBarExtraContent={
              <>
                {this.state.variants.length == 0 &&
                  this.state.activeKey == '1' &&
                  this.state.has_batches == 0 && (
                    <a
                      onClick={e => {
                        store.get('paid') == 0
                          ? this.subscriptionPlanRef.current.openModal('variants')
                          : this.addNewVariant()
                      }}
                      className="font-weight-bold mr-2"
                    >
                      <i className="fa fa-plus-circle mr-2" />
                      Variants
                      {store.get('paid') == 0 && <ProBadge />}
                    </a>
                  )}

                {this.state.saved &&
                  this.state.activeKey == '1' &&
                  this.state.variants.length == 0 &&
                  this.state.has_batches != 0 && (
                    <a
                      onClick={e => {
                        store.get('paid') == 0
                          ? this.subscriptionPlanRef.current.openModal('batches')
                          : this.handleAddBatches({
                              ...this.state.selectedData,
                              variant_id: 0,
                              name: '',
                            })
                      }}
                      className="font-weight-bold mr-2"
                    >
                      <i className="fa fa-plus-circle mr-2" />
                      Batches
                      {store.get('paid') == 0 && <ProBadge />}
                    </a>
                  )}
              </>
            }
          >
            <TabPane tab="Details" className="pt-0" key="1">
              <Drawer
                title={`Search ${this.props?.user?.selectedCompany?.labels?.hsn_code}`}
                width={isMobile ? '100%' : '50%'}
                closable={true}
                closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
                onClose={this.onHSNSearchDrawerClose}
                open={this.state.hsn_drawer_visble}
                footer={[
                  <Button type="danger" onClick={this.onHSNSearchDrawerClose}>
                    Close
                  </Button>,
                ]}
              >
                <Form
                  {...innnerFormItemLayout}
                  labelAlign="left"
                  ref={this.innerFormRef}
                  name="hsnForm"
                  onFinish={this.onInnerFinish}
                >
                  <Card>
                    <Form.Item name="hsn_code">
                      <Select
                        showSearch
                        allowClear
                        placeholder={`${this.props?.user?.selectedCompany?.labels?.hsn_code} (Optional)`}
                        onSearch={this.handleHSNSearch}
                        showArrow={false}
                        filterOption={false}
                        notFoundContent={null}
                        defaultActiveFirstOption={false}
                        optionLabelProp="label"
                        className="mb-2"
                      >
                        {this.state.hsn_codes.map(d => (
                          <Option key={d.id} value={d.hsn_code} label={d.hsn_code}>
                            <div style={{ whiteSpace: 'normal' }}>
                              <span className="mr-2 font-weight-bold">{d.hsn_code}</span>
                              {d.description}
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {/*<Button type="primary" onClick={() => this.innerFormRef.current.submit(u)}>*/}
                    <Button type="primary" onClick={() => this.innerFormRef.current.submit()}>
                      Use this {this.props?.user?.selectedCompany?.labels?.hsn_code}
                    </Button>
                  </Card>
                </Form>
              </Drawer>

              <Modal
                title={`Add ${this.props?.user?.selectedCompany?.tax_id_label}`}
                closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
                onCancel={this.closeGSTDrawer}
                open={this.state.gst_drawer}
                footer={null}
              >
                <Form
                  {...innnerFormItemLayout}
                  labelAlign="left"
                  ref={this.innerFormRef}
                  name="gstForm"
                  onFinish={this.onGSTINFinish}
                >
                  <Form.Item name="gstin">
                    <Input
                      placeholder={`Enter 15 digit ${this.props?.user?.selectedCompany?.tax_id_label}`}
                      style={{ borderRadius: '5px', fontSize: '1.08rem', fontWeight: 700 }}
                      onChange={e => {
                        if (e.target.value.length == 15) {
                          this.innerFormRef.current.submit()
                        }
                      }}
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    className="mt-2 font-weight-bold"
                    onClick={() => this.innerFormRef.current.submit()}
                    loading={this.state.loading}
                  >
                    Add {this.props?.user?.selectedCompany?.tax_id_label}
                  </Button>
                </Form>
              </Modal>

              <Modal
                open={this.state.previewVisible}
                title={this.state.previewTitle}
                footer={null}
                onCancel={this.handleCancel}
                closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
              >
                <img alt="Product Image" style={{ width: '100%' }} src={this.state.previewImage} />
              </Modal>

              {/*Product form start*/}
              {this.state?.variants?.length > 0 && (
                <Collapse
                  className="bg-white mb-4"
                  bordered={false}
                  defaultActiveKey={['2']}
                  expandIcon={({ isActive }) => (
                    <span className="">
                      <i
                        className={`fa-solid fa-chevron-down collapse-align-center fa-xl ml-1 ${!isActive &&
                          'fa-rotate-270'}`}
                      />
                    </span>
                  )}
                >
                  <Collapse.Panel
                    header={
                      <>
                        <span className="font-size-18 font-weight-bold">Variants</span>
                        <p className="font-size-12 text-gray-400">
                          You can add discount, opening stock, and images to variants once they are
                          created.
                        </p>
                      </>
                    }
                    key="2"
                  >
                    <Table
                      columns={variant_columns}
                      dataSource={[...variants]}
                      pagination={false}
                      footer={() => (
                        <div className="flex justify-end">
                          <Button
                            type="action-o"
                            size="medium"
                            className="mr-2 float-right"
                            onClick={e => {
                              e.stopPropagation()
                              if (this.props.permissions.product_edit == 1) {
                                // store.get('paid') == 0
                                //   ? this.subscriptionPlanRef.current.openModal("ewaybill", 4)
                                //   : this.variantForm.onEditClick({}, this.state.selectedData)
                                this.addNewVariant()
                              } else {
                                message.error(
                                  "You don't have an edit access. Please contact admin.",
                                )
                              }
                            }}
                          >
                            <i className="fa fa-plus-circle mr-2" />
                            <span className="hidden sm:inline font-weight-medium">Add Variant</span>
                          </Button>
                        </div>
                      )}
                    />
                  </Collapse.Panel>
                </Collapse>
              )}
              <Form
                {...formItemLayout}
                labelAlign="left"
                ref={this.formRef}
                name="productForm"
                className="add-product-form"
                onFinish={this.onFinish}
                onFinishFailed={() => message.error('Please Check all the details and try again')}
                scrollToFirstError={{ type: 'smooth' }}
                onClose={() => this.setState({ product_id: '', is_duplicate: false })}
              >
                {this.state.is_duplicate && (
                  <div className="mb-4">
                    <InfoComponent
                      description="This item is cloned from an existing item. Please review and update the details as necessary."
                      type="info"
                      learnMore=""
                      learnMoreText=""
                    />
                  </div>
                )}
                <SectionHeader
                  title="Basic Details"
                  link={true}
                  linkTo={e => {
                    store.get('paid') == 0
                      ? this.subscriptionPlanRef.current.openModal('custom_fields')
                      : this.custom_column.onAddFromProducts()
                  }}
                  linkText={
                    <Tooltip title="Use this to add new fields to your item.">
                      <span>Add Custom fields</span>
                    </Tooltip>
                  }
                />
                <Card size="form" bordered={true} className="border-radius-small mb-4">
                  <Row gutter={18}>
                    <Col span={12}>
                      <Form.Item name="product_type">
                        <Radio.Group
                          name="radiogroup"
                          defaultValue={1}
                          optionType="button"
                          buttonStyle="solid"
                          onChange={e =>
                            this.setState({
                              product_type: e.target.value,
                            })
                          }
                        >
                          {this.props.product_types.map((e, i) => (
                            <Radio key={i} value={e}>
                              {e}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    {/*<Col span={18}>
                    <Form.Item
                      name="file"
                      //label={<span>Product Image</span>}
                      valuePropName="file"
                      className="float-right"
                      //extra="Product images must be PNG or JPEG, recommended 1024 px by 1024 px or 1:1 aspect ratio."
                    >
                      <Upload
                        beforeUpload={() => false}
                        name="avatar"
                        listType="picture-card"
                        maxCount={5}
                        fileList={this.state.fileList}
                        multiple
                        onPreview={this.handlePreview}
                        onChange={this.handleChange}
                        onRemove={this.handleRemoveProductImage}
                        accept={'image/*'}
                      >
                        {this.state.fileList.length >= 5 ? null : uploadButton}
                      </Upload>
                    </Form.Item>
                  </Col>*/}

                    <Col span={24}>
                      <Form.Item
                        name="product_name"
                        label={
                          <>
                            <span className="mr-2">Item Name</span>
                          </>
                        }
                        rules={[{ required: true, message: 'Please fill item name' }]}
                      >
                        <Input.TextArea
                          placeholder={
                            'Enter ' +
                            (this.state.product_type == 'Product' ? 'Item' : 'Service') +
                            ' Name'
                          }
                          autoFocus
                          autoSize={{ minRows: 1, maxRows: 3 }}
                          maxLength={500}
                          onBlur={e => this.checkExistingProduct(e.target.value)}
                        />
                      </Form.Item>
                    </Col>

                    {(this.props.integrations.batching == 1 ||
                      this.props.integrations.serial_number == 1) &&
                      this.state.variants.length == 0 &&
                      this.state.product_type == 'Product' && (
                        <Col span={24} clas>
                          <Form.Item
                            name="has_batches"
                            label={
                              <>
                                Manage Inventory Using
                                <BetaBadge />
                              </>
                            }
                            extra={
                              this.state.batch_option_disabled
                                ? 'Your Inventory is managed using above selected option and it cannot be changed once updated.'
                                : ''
                            }
                          >
                            {/* <Radio.Group
                            name="radiogroup"
                            defaultValue={0}
                            optionType="default"
                            value={this.state.has_batches}
                            onChange={e => {
                              this.onChangeInventory(e)
                            }}
                            disabled={this.state.batch_option_disabled}
                          >
                            {batchOptions.map((e, i) => (
                              <Radio key={i} value={e.value}>
                                {e.title}
                              </Radio>
                            ))} */}
                            {this.props.integrations.batching == 1 && (
                              <Switch
                                disabled={this.state.batch_option_disabled}
                                checked={this.state.has_batches == 1}
                                checkedChildren="Batches"
                                unCheckedChildren="Batches"
                                onChange={checked => this.onChangeInventory(checked ? 1 : 0)}
                                className="mr-3"
                              />
                            )}
                            {this.props.integrations.serial_number == 1 && (
                              <Switch
                                disabled={this.state.batch_option_disabled}
                                checkedChildren="Serial Number/ IMEI"
                                unCheckedChildren="Serial Number/ IMEI"
                                checked={this.state.has_batches == 2}
                                onChange={checked => this.onChangeInventory(checked ? 2 : 0)}
                              />
                            )}
                            {/* </Radio.Group> */}
                          </Form.Item>
                        </Col>
                      )}
                    {this.state.variants.length == 0 && this.state.has_batches != 1 && (
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name="price"
                          label={
                            <>
                              <span className="mr-2">Selling Price</span>
                              {/* {this.props.company_details.gstin.length == 15 && (
                              <Switch
                                onChange={e => this.setState({ is_price_with_tax: e })}
                                checked={this.state.is_price_with_tax}
                                checkedChildren="with Tax"
                                unCheckedChildren="without Tax"
                                size="small"
                              />
                            )}*/}
                            </>
                          }
                          extra={
                            <span className="font-weight-bold">
                              {this.state.is_price_with_tax == true
                                ? 'Inclusive of Taxes'
                                : 'Exclusive of Taxes'}
                            </span>
                          }
                          //rules={[{ required: true, message: 'Please fill price' }]}
                          hidden={this.state.variants.length > 0}
                        >
                          <Input
                            type="number"
                            placeholder="Enter Selling Price"
                            addonAfter={
                              this.props.company_details?.gstin?.length == 15 && (
                                <Select
                                  suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                  style={{ width: 120 }}
                                  onChange={e => {
                                    this.setState({ is_price_with_tax: e })
                                  }}
                                  value={this.state.is_price_with_tax}
                                >
                                  <Option value={true}>with Tax</Option>

                                  <Option value={false}>without Tax</Option>
                                </Select>
                              )
                            }
                            onChange={e => {
                              this.setState({ selling_price: e.target.value }, () => {
                                this.setDiscountAmount(
                                  this.state.show_discount_in
                                    ? this.state.discountAmount
                                    : this.state.discount,
                                )
                              })
                            }}
                            prefix={
                              <span className="text-gray-500">
                                {this.props?.user?.selectedCompany?.currency_symbol}
                              </span>
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {this.props.company_details?.gstin?.length != 15 &&
                      this.state.selectedData.tax == 0 && (
                        <Col span={12}>
                          <div className="mt-4 pt-2">
                            <a
                              className="font-weight-bold mt-4 ml-1 text-primary"
                              onClick={() =>
                                store.get('role') == 'admin'
                                  ? history.push('/user?tab=company')
                                  : ''
                              }
                            >
                              Add {this.props?.user?.selectedCompany?.tax_id_label}
                            </a>
                            {store.get('role') == 'admin' ? (
                              <p>
                                Add {this.props?.user?.selectedCompany?.tax_id_label} to add tax for
                                the products.{' '}
                              </p>
                            ) : (
                              <p className="text-danger">Only Admin can add the GST Number. </p>
                            )}
                          </div>
                        </Col>
                      )}

                    {(this.props.company_details?.gstin?.length == 15 ||
                      (this.props.company_details?.gstin?.length != 15 &&
                        this.state.selectedData.tax > 0)) && (
                      <Col span={this.formRef?.current?.getFieldValue('tax') != 0 ? 12 : 7}>
                        <Form.Item
                          name="tax"
                          label="Tax %"
                          rules={[{ required: true, message: 'Please fill tax' }]}
                          extra={
                            this.props.integrations?.multi_tax ? (
                              <button
                                type="button"
                                className="font-weight-bold text-blue-600"
                                onClick={e => this.handleAddMultipleTaxes(e)}
                              >
                                + Add multiple taxes
                              </button>
                            ) : null
                          }
                        >
                          <Select
                            showSearch
                            className="font-weight-bold"
                            placeholder="Select Tax"
                            suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return (
                                option.children.props.children[0].props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              )
                            }}
                            onChange={e => {
                              this.ontaxChange(e)
                            }}
                          >
                            {this.props.tax_rates?.map((e, i) => (
                              <Option key={i} value={e}>
                                <div className="flex justify-between">
                                  <span
                                    className="font-weight-bold"
                                    style={{
                                      width: '30px',
                                    }}
                                  >
                                    {e}
                                  </span>
                                  {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                                    <span className="text-gray-500 mr-2">
                                      ({e / 2}% CGST & {e / 2}% SGST, {e}% IGST)
                                    </span>
                                  )}
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    {this.props.company_details?.gstin?.length == 15 &&
                      this.formRef?.current?.getFieldValue('tax') == 0 && (
                        <Col span={5}>
                          <Form.Item name="tax_type" label="Tax Type">
                            <Select
                              showSearch
                              className="font-weight-bold"
                              placeholder="Select Tax Type"
                              suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return (
                                  option.children.props.children[0].props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                )
                              }}
                              onChange={e => {
                                this.setState({ tax_type: e })
                              }}
                            >
                              {this.props.zero_tax_types?.map((e, i) => (
                                <Option key={i} value={e.value}>
                                  {e.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}

                    <Col span={12}>
                      <Form.Item
                        name="unit"
                        label={<span>Primary Unit</span>}
                        extra={
                          this.props?.user?.selectedCompany?.labels?.country_code == 'IN' && (
                            <span>
                              {/*Primary unit cannot be changed if alternative units are there. For OTH
                          unit, it can be changed. Click here to check{' '}*/}
                              <Link
                                to={{
                                  pathname: 'https://einvoice1.gst.gov.in/Others/MasterCodes',
                                }}
                                target="_blank"
                                className="font-weight-medium text-gray-400"
                              >
                                List of GST approved UQC codes.
                              </Link>
                            </span>
                          )
                        }
                      >
                        <Select
                          showSearch
                          allowClear
                          disabled={
                            this.state.alternative_units.length > 0 &&
                            this.state.saved &&
                            this.state.product_unit != 'OTH' &&
                            this.state.product_unit != '' &&
                            this.state.product_unit != null
                          }
                          suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                          placeholder="Select Unit"
                          // onFocus={this.handleUnitSearchOnFocus}
                          showArrow={false}
                          notFoundContent={'No Units Found'}
                          defaultActiveFirstOption={false}
                          optionLabelProp="label"
                          onChange={e => {
                            this.setState({ primary_unit: e })
                          }}
                          filterOption={(input, option) => {
                            return option.label.toLowerCase().includes(input.toLowerCase())
                          }}
                        >
                          {this.props.units.map(d => (
                            <Option key={d.id} value={d.code} label={d.code + ' ' + d.name}>
                              <div style={{ whiteSpace: 'normal' }}>
                                <span className="mr-2 font-weight-bold">{d.code}</span>
                                {d.name}
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      {/* has alternative units checkbox field */}
                      {this.state.primary_unit && this.state.primary_unit != 'OTH' && (
                        <Form.Item
                          name="has_alternative_units"
                          valuePropName="checked"
                          label={
                            <span>
                              Enable Alternative Units{' '}
                              {store.get('paid') == 0 ? <ProBadge /> : <BetaBadge />}
                            </span>
                          }
                        >
                          <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={e => {
                              if (store.get('paid') == 0) {
                                this.subscriptionPlanRef.current.openModal('alternative_units')
                                this.formRef.current.setFieldsValue({
                                  has_alternative_units: false,
                                })
                                return
                              } else {
                                this.setState({ has_alternative_units: e })
                              }
                            }}
                          />
                        </Form.Item>
                      )}
                    </Col>

                    {this.state.has_alternative_units == 1 &&
                      this.state.alternative_units?.length == 0 && (
                        <Col span={12}>
                          <Button
                            onClick={e => {
                              store.get('paid') == 0
                                ? this.subscriptionPlanRef.current.openModal('alternative_units')
                                : this.alternative_units.onAdd(
                                    this.props.units,
                                    this.state.primary_unit,
                                  )
                            }}
                            type="primary-add"
                            className="font-weight-bold ml-1"
                          >
                            <i className="fa fa-plus-circle mr-2" />
                            Add Alternative Units
                            {store.get('paid') == 0 && <ProBadge />}
                          </Button>
                        </Col>
                      )}

                    {this.state.has_alternative_units == 1 &&
                      this.state.alternative_units?.length > 0 && (
                        <Col span={12}>
                          <Button
                            onClick={e => {
                              store.get('paid') == 0
                                ? this.subscriptionPlanRef.current.openModal('alternative_units')
                                : this.alternative_units.onFill(
                                    this.state.alternative_units,
                                    this.props.units,
                                    this.state.primary_unit,
                                  )
                            }}
                            type="primary-light"
                            className="font-weight-bold ml-1 mt-2 mb-2"
                            size=""
                          >
                            <i className="fa fa-plus-circle mr-2" />
                            Add/Edit Alternative Units
                            {store.get('paid') == 0 && <ProBadge />}
                          </Button>
                        </Col>
                      )}
                  </Row>
                </Card>

                {/*custom columns*/}

                <Visibility type="custom_fields" className="mb-4" visible="true" />

                {this.props.custom_columns_active.length > 0 && (
                  <>
                    <SectionHeader title="Custom Fields" optional={true} />
                    <Card bordered={false} size="form" className="border-radius-small mb-3">
                      <Row gutter={18}>
                        {this.get_columns_list()?.map((item, index) => (
                          <Col span={12}>
                            <Form.Item name={item.name} key={index} label={item.name}>
                              {item.field_type == 'number' || item.field_type == 'currency' ? (
                                <Input
                                  type="number"
                                  style={{ width: '100%' }}
                                  placeholder={item.name}
                                />
                              ) : item.field_type == 'date' ? (
                                <DatePicker
                                  style={{ width: '100%' }}
                                  placeholder={item.name}
                                  format={this.dateFormat}
                                />
                              ) : item.field_type == 'datetime' ? (
                                <DatePicker
                                  showTime
                                  style={{ width: '100%' }}
                                  placeholder={item.name}
                                  format={this.dateTimeFormat}
                                />
                              ) : item.field_type == 'bigtext' ? (
                                <TextArea
                                  placeholder={item.name}
                                  type="text"
                                  rows={1}
                                  className="pr-4 mr-2"
                                  style={{ width: '100%', borderRadius: '5px' }}
                                />
                              ) : (
                                <AutoComplete
                                  popupClassName="certain-category-search-dropdown"
                                  popupMatchSelectWidth={250}
                                  style={{
                                    width: '100%',
                                  }}
                                  placeholder={item.name}
                                  options={
                                    this.renderCustomColumnOptions(item.id).length > 0
                                      ? [
                                          {
                                            label: <span>Recently Used </span>,
                                            options: [...this.renderCustomColumnOptions(item.id)],
                                          },
                                        ]
                                      : []
                                  }
                                ></AutoComplete>
                              )}
                            </Form.Item>
                          </Col>
                        ))}
                      </Row>
                    </Card>
                    <Button
                      type="secondary"
                      className="mb-4"
                      onClick={() => {
                        store.get('paid') == 0
                          ? this.subscriptionPlanRef.current.openModal('custom_fields')
                          : this.custom_column.onAddFromProducts()
                      }}
                    >
                      <i className="fa-sharp fa-solid fa-circle-plus mr-2" />
                      <span className="font-weight-bold">Add Custom Fields</span>
                    </Button>
                  </>
                )}

                {store.get('paid') == 0 && this.state.suggested_fields.length > 0 && (
                  <div className="mt-4 mb-4 ml-3">
                    <SectionHeader
                      title="Recommended Fields"
                      link={false}
                      linkText={'Clear'}
                      linkTo={() => {
                        this.setState({ show_recommended_fields: false })
                      }}
                    />

                    {this.state.suggested_fields.map((item, index) => (
                      <Tag
                        icon={<i className="fa fa-plus mr-2 text-primary" />}
                        className="cursor-pointer tag-large bg-white"
                        onClick={() => {
                          store.get('paid') == 0
                            ? this.subscriptionPlanRef.current.openModal('custom_fields')
                            : this.addRecommededField({
                                id: -1,
                                name: item.name,
                                is_active: 1,
                                is_delete: 0,
                                field_type: 'text',
                                default_value: '',
                              })
                        }}
                      >
                        {item.name}
                      </Tag>
                    ))}
                  </div>
                )}
                <SectionHeader title="Additional Information" optional={true} />
                <Card bordered={false} size="form" className="border-radius-small mb-4">
                  <Row gutter={18}>
                    {/* {this.props.permissions.show_purchase_price != 0 ||
                    (this.state.variants.length == 0 && ( */}
                    <Col span={12}>
                      <Form.Item
                        name="hsn_code"
                        label={<span>{this.props?.user?.selectedCompany?.labels?.hsn_code}</span>}
                        extra={
                          this.props?.user?.selectedCompany?.country_code == 'IN' && (
                            <span>
                              {this.props.company_details?.gstin?.length == 15 && ' '}
                              <Link
                                to={{
                                  pathname: 'https://einvoice1.gst.gov.in/Others/MasterCodes',
                                }}
                                target="_blank"
                                className="font-weight-medium text-gray-400"
                              >
                                Click here to check GST approved HSN/SAC codes.
                              </Link>
                            </span>
                          )
                        }
                        rules={[
                          {
                            validator: (_, value) => {
                              if (this.state.product_type === 'Product') {
                                if (value && value.toString().startsWith('99')) {
                                  return Promise.reject(
                                    new Error('HSN code starting with 99 is reserved for services'),
                                  )
                                }
                                return Promise.resolve()
                              } else if (this.state.product_type === 'Service') {
                                if (value && !value.toString().startsWith('99')) {
                                  return Promise.reject(
                                    new Error('HSN code for service should start with 99'),
                                  )
                                }
                                return Promise.resolve()
                              }
                              return Promise.resolve()
                            },
                          },
                          { max: 8, message: 'HSN code cannot be more than 8 digits' },
                        ]}
                      >
                        <AutoComplete
                          popupClassName="certain-category-search-dropdown"
                          // popupMatchSelectWidth={400}
                          style={{
                            width: '100%',
                          }}
                          placeholder={this.props?.user?.selectedCompany?.labels?.hsn_code}
                          options={
                            this.props.hsn_suggestions.length > 0
                              ? [
                                  {
                                    label: (
                                      <p className="flex justify-between mb-0 pb-0">
                                        <span>Frequently Used </span>
                                        <span
                                          className="cursor-pointer text-primary"
                                          onClick={this.showHSNSearchDrawer}
                                        >
                                          Search{' '}
                                          {this.props?.user?.selectedCompany?.labels?.hsn_code}s{' '}
                                        </span>
                                      </p>
                                    ),
                                    options: [
                                      ...this.props.hsn_suggestions.map(hsn_code => {
                                        return {
                                          value: hsn_code,
                                          label: (
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              {hsn_code}
                                            </div>
                                          ),
                                        }
                                      }),
                                    ],
                                  },
                                ]
                              : []
                          }
                        ></AutoComplete>
                      </Form.Item>
                    </Col>

                    {this.state.variants.length == 0 && this.state.has_batches != 1 && (
                      <Col span={12}>
                        <Form.Item
                          label="Purchase Price"
                          name="purchase_unit_price"
                          className=""
                          hidden={
                            this.props.permissions.show_purchase_price == 0 ||
                            this.state.variants.length > 0
                          }
                        >
                          <Input
                            type="number"
                            addonAfter={
                              <Select
                                suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                style={{ width: 120 }}
                                onChange={e => {
                                  this.setState({ is_purchase_price_with_tax: e }, () => {
                                    let purchase_price = this.state.is_purchase_price_with_tax
                                      ? this.state.purchase_price
                                      : this.state.purchase_unit_price

                                    this.formRef.current.setFieldsValue({
                                      purchase_unit_price: purchase_price,
                                    })
                                  })
                                }}
                                value={this.state.is_purchase_price_with_tax}
                              >
                                <Option value={true}>with Tax</Option>
                                <Option value={false}>without Tax</Option>
                              </Select>
                            }
                            className="mr-2"
                            disabled={this.state.variants.length > 0}
                            onChange={e => this.purchasePriceChange(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {/* {this.props.permissions.show_purchase_price != 0 ||
                    (this.state.variants.length == 0 && ( */}
                    {/* <Col span={12}>
                    <Form.Item
                      label=" "
                      name="purchase_price"
                      style={{
                        width: '100%',
                      }}
                      hidden={
                        this.props.permissions.show_purchase_price == 0 ||
                        this.state.variants.length > 0
                      }
                    >
                      <Input
                        placeholder="Purchase Price with Tax"
                        type="number"
                        addonAfter={'with Tax'}
                        onChange={e => {
                          var tax = this.formRef.current.getFieldValue('tax')
                            ? parseFloat(this.formRef.current.getFieldValue('tax'))
                            : 0
                          var cess = this.formRef.current.getFieldValue('cess')
                            ? this.formRef.current.getFieldValue('cess')
                            : 0
                          this.setState({
                            purchase_unit_price:
                              parseFloat(e.target.value) / (1 + (tax + cess) / 100),
                          })
                        }}
                      />
                    </Form.Item>
                  </Col> */}
                    {/* ))} */}

                    {this.state.variants.length == 0 && (
                      <Col span={12}>
                        <Form.Item
                          name="barcode_id"
                          label="Barcode"
                          hidden={this.state.variants.length > 0}
                          rules={[
                            {
                              validator: (rule, value) => {
                                if (value && value.length > 18) {
                                  return Promise.reject('Barcode should be less than 18 digits')
                                }
                                return Promise.resolve()
                              },
                            },
                          ]}
                        >
                          <Input
                            addonAfter={
                              <span style={{ cursor: 'pointer' }} onClick={this.generateBarCode}>
                                <i className="fa-solid fa-wand-magic-sparkles mr-1" /> Auto Generate
                              </span>
                            }
                            placeholder="2273546838467"
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {/* {this.state.formFlag == false && (
                    <Col span={12}>
                      <Form.Item
                        name="qty"
                        label="Opening stock"
                        extra="*Quantity of the product available in your existing inventory"
                      >
                        <Input placeholder="eg. 10" disabled={this.state.variants.length > 0} />
                      </Form.Item>
                    </Col>
                  )} */}

                    <Col span={12}>
                      <Form.Item name="product_category" label={<span>Category</span>}>
                        <Select
                          suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                          mode="tags"
                          maxTagTextLength={30}
                          maxTagCount={1}
                          placeholder="Select Category"
                          onSelect={this.handleSelect}
                          filterOption={(input, option) =>
                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          dropdownRender={menu => (
                            <>
                              {menu}
                              <div className="bg-gray-75">
                                <Button
                                  block
                                  size=""
                                  type="link-2"
                                  className="font-weight-bolder text-left my-2 "
                                  onClick={() => this.categoryForm.onAdd()}
                                >
                                  <i className="fa-solid fa-circle-plus mr-2"></i>
                                  New Category
                                </Button>
                              </div>
                            </>
                          )}
                        >
                          {this.props.categories &&
                            this.props.categories.map((e, i) => {
                              if (e != ' ' && e != '' && e != null) {
                                return (
                                  <Option key={i} value={e.category_name}>
                                    {e.category_name}
                                  </Option>
                                )
                              }
                            })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name="file"
                        label={<span>Product Image</span>}
                        valuePropName="file"
                        extra="Upto 5 images. Product images must be PNG or JPEG, recommended 1024 px by 1024 px or 1:1 aspect ratio."
                      >
                        <Upload
                          beforeUpload={() => false}
                          name="avatar"
                          listType="picture-card"
                          maxCount={5}
                          fileList={this.state.fileList}
                          multiple
                          onPreview={this.handlePreview}
                          onChange={this.handleChange}
                          onRemove={this.handleRemoveProductImage}
                          accept={'image/*'}
                        >
                          {this.state.fileList.length >= 5 ? null : uploadButton}
                        </Upload>
                      </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                    <Form.Item
                      name={'not_for_sale'}
                      valuePropName="checked"
                      label="Not for Sale"
                      extra={
                        'If the switch is enabled this product will not be displayed while creating sale documents'
                      }
                    >
                      <Switch />
                    </Form.Item>
                  </Col> */}

                    <Col span={24}>
                      <Form.Item name="description" label={<span>Description</span>}>
                        {this.state.editorState ? (
                          <>
                            <MyEditor
                              editorState={this.state.editorState}
                              onChange={(text, editorState) =>
                                this.setState({ editorState: editorState })
                              }
                            />
                            {this.state.editorState && (
                              <div className="mt-2">
                                <AIButton
                                  keyProp="editorState"
                                  context={() => {
                                    const product_name =
                                      this.formRef?.current?.getFieldValue('product_name') || ''
                                    return `Generating description for this product/service: \n Name: ${product_name}`
                                  }}
                                  initialText={this.state.editorState
                                    ?.getCurrentContent()
                                    ?.getPlainText()}
                                  aimodalRef={this.ai}
                                  featureCategory="description"
                                  textType="HTML"
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <span>Loading...</span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                {/* {this.state.product_type == 'Product' && !this.state.formFlag && ( */}
                {this.props.warehouses?.length == 0 && (
                  <>
                    {this.state.variants.length == 0 && this.state.has_batches == 0 && (
                      <SectionHeader
                        title="Opening Stock"
                        optional={true}
                        link={
                          store.get('paid') == 0 &&
                          this.state.activeKey == '1' &&
                          this.state.has_batches == 0
                        }
                        linkText={'Add batches'}
                        linkTo={() => {
                          this.subscriptionPlanRef.current.openModal('batches')
                        }}
                        icon="fa-lock-keyhole"
                      />
                    )}
                    {/* )} */}
                    <Card
                      className="mb-4"
                      hidden={this.state.variants.length > 0 || this.state.has_batches != 0}
                    >
                      <Row gutter={18}>
                        <Col span={12}>
                          <Form.Item
                            name="opening_qty"
                            label="Opening Quantity"
                            extra="*Quantity of the product available in your existing inventory"
                            hidden={this.state.product_type != 'Product'}
                            rules={[
                              {
                                validator: (rule, value) => {
                                  if (value < 0 && !this.state.formFlag) {
                                    return Promise.reject('Quantity cannot be negative')
                                  }
                                  return Promise.resolve()
                                },
                              },
                            ]}
                            onChange={e => {
                              var opening_purchase_price = this.formRef.current.getFieldValue(
                                'opening_purchase_price',
                              )
                                ? parseFloat(
                                    this.formRef.current.getFieldValue('opening_purchase_price'),
                                  )
                                : 0

                              var opening_qty = this.formRef.current.getFieldValue('opening_qty')
                                ? parseFloat(this.formRef.current.getFieldValue('opening_qty'))
                                : 0
                              this.formRef.current.setFieldsValue({
                                opening_value: opening_purchase_price * opening_qty,
                              })
                            }}
                          >
                            <Input placeholder="eg. 10" type="number" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="opening_purchase_price"
                            label="Opening Purchase Price (with tax)"
                            hidden={this.state.product_type != 'Product'}
                            rules={[
                              {
                                validator: (rule, value) => {
                                  if (value < 0) {
                                    return Promise.reject('Purchase Price cannot be negative')
                                  }
                                  return Promise.resolve()
                                },
                              },
                            ]}
                            onChange={e => {
                              var opening_purchase_price = this.formRef.current.getFieldValue(
                                'opening_purchase_price',
                              )
                                ? parseFloat(
                                    this.formRef.current.getFieldValue('opening_purchase_price'),
                                  )
                                : 0

                              var opening_qty = this.formRef.current.getFieldValue('opening_qty')
                                ? parseFloat(this.formRef.current.getFieldValue('opening_qty'))
                                : 0
                              this.formRef.current.setFieldsValue({
                                opening_value: opening_purchase_price * opening_qty,
                              })
                            }}
                          >
                            <Input placeholder="eg. 100.00" type="number" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="opening_value"
                            label="Opening Stock Value (with tax)"
                            hidden={this.state.product_type != 'Product'}
                            rules={[
                              {
                                validator: (rule, value) => {
                                  if (value < 0) {
                                    return Promise.reject('Opening Value cannot be negative')
                                  }
                                  return Promise.resolve()
                                },
                              },
                            ]}
                            onChange={e => {
                              var opening_purchase_price = this.formRef.current.getFieldValue(
                                'opening_value',
                              )
                                ? parseFloat(this.formRef.current.getFieldValue('opening_value'))
                                : 0

                              var opening_qty = this.formRef.current.getFieldValue('opening_qty')
                                ? parseFloat(this.formRef.current.getFieldValue('opening_qty'))
                                : 0
                              this.formRef.current.setFieldsValue({
                                opening_purchase_price: opening_purchase_price / opening_qty,
                              })
                            }}
                          >
                            <Input placeholder="eg. 100.00" type="number" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </>
                )}
                {this.state.formFlag == 1 && (
                  <p className="text-darkred ml-2">
                    *Note: Any changes mades will be applied to new documents and old documents
                    won't be affected
                  </p>
                )}

                <Affix offsetBottom={72}>
                  <div className="upward-collapse-wrapper">
                    <Collapse
                      className="custom-upward-collapse bg-card-orange-dark pb-0"
                      bordered={false}
                      defaultActiveKey
                      expandIcon={({ isActive }) => (
                        <span className="">
                          <i
                            className={`fa-solid fa-chevron-down collapse-align-center ${!isActive &&
                              'fa-rotate-270'}`}
                          />
                        </span>
                      )}
                    >
                      <Panel
                        header={
                          <>
                            <span className="font-size-16 font-weight-bold">More Details?</span>
                            <p className="text-gray-900 font-size-14 font-weight-normal mb-0">
                              {this.props?.user?.selectedCompany?.country_code == 'IN'
                                ? 'Cess, Show Online'
                                : ''}
                              Discount, Inventory tracking, Low stock alerts etc..{' '}
                            </p>
                          </>
                        }
                        key="1"
                        className="custom-collapse-panel"
                      >
                        <Row gutter={18}>
                          <Col span={12}>
                            <Form.Item
                              label="Discount"
                              name="discount"
                              className=""
                              hidden={this.state.variants.length > 0}
                              extra={
                                'Discount will be calculated based on the selected option. In Online Store, discount will be shown as per the selected option.'
                              }
                            >
                              <Input
                                type="number"
                                addonAfter={
                                  <Select
                                    suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                    style={{ width: 150 }}
                                    onChange={e => {
                                      this.setState({ show_discount_in: e }, () => {
                                        let discount = this.state.show_discount_in
                                          ? this.state.discountAmount
                                          : this.state.discount

                                        this.formRef.current.setFieldsValue({
                                          discount: discount,
                                        })
                                      })
                                    }}
                                    value={this.state.show_discount_in}
                                  >
                                    <Option value={0}>Percentage (%)</Option>
                                    <Option value={1}>
                                      Amount ({this.props.user.selectedCompany?.currency_symbol})
                                    </Option>
                                  </Select>
                                }
                                className="mr-2"
                                disabled={this.state.variants.length > 0}
                                onChange={e => this.setDiscountAmount(e.target.value)}
                              />
                            </Form.Item>

                            <Form.Item
                              name="show_discount_in"
                              label="Show Discount In"
                              extra={
                                'Discount will be calculated based on the selected option.In Online Store, discount will be shown as per the selected option.'
                              }
                              hidden={this.state.variants.length == 0}
                              value={this.state.show_discount_in}
                            >
                              <Radio.Group
                                options={[
                                  { label: 'Percentage', value: 0 },
                                  { label: 'Amount', value: 1 },
                                ]}
                                className="flex mb-0 p-0"
                                onChange={e => {
                                  this.setState({ show_discount_in: e.target.value })
                                }}
                              />
                            </Form.Item>
                          </Col>

                          {this.props.company_details?.gstin?.length == 15 &&
                            this.props?.user?.selectedCompany?.country_code == 'IN' && (
                              <Col span={12}>
                                <Form.Item name={'cess'} label={'Cess %'}>
                                  <Input
                                    type="number"
                                    addonAfter="%"
                                    onChange={e => {
                                      this.purchasePriceChange(
                                        this.state.is_purchase_price_with_tax
                                          ? this.state.purchase_price
                                          : this.state.purchase_unit_price,
                                      )
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            )}

                          {this.props.company_details?.gstin?.length == 15 &&
                            this.props.permissions.additional_cess != 0 &&
                            this.props?.user?.selectedCompany?.country_code == 'IN' && (
                              <>
                                <Col span={12}>
                                  <Form.Item
                                    name={'cess_on_qty'}
                                    label={
                                      <>
                                        Cess on Quantity (in Rs.) <BetaBadge />
                                      </>
                                    }
                                    extra={
                                      'Additional Cess / GST Compensation Cess on Quantity. (Available in web only)'
                                    }
                                  >
                                    <Input
                                      type="number"
                                      addonAfter={'Per ' + this.state.primary_unit}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name={'cess_non_advl_rate'}
                                    label={'Cess on Quantity comes under Rate (in Rs.)'}
                                    extra={
                                      "This doesn't involve in the calculation of cess on quantity. It is used for GST Reports and Ewaybills."
                                    }
                                  >
                                    <Select
                                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                    >
                                      {this.props.additional_cess_rates.map((rate, index) => (
                                        <Option key={index} value={rate.value}>
                                          {rate.value != -1 ? rate.value + ' - Ex: ' : ''}{' '}
                                          {rate.desc}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </>
                            )}

                          <Col span={12}>
                            <Form.Item
                              name="low_stock"
                              label="Low Stock Alert at"
                              className="font-weight-normal"
                              extra={
                                'You will be notified once the stock reaches the minimum stock qty. (BETA)'
                              }
                            >
                              <Input placeholder="eg. 10" />
                            </Form.Item>
                          </Col>

                          {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                            <Col span={12}>
                              <Form.Item
                                name="show_online"
                                label="Show in Online Store"
                                valuePropName="checked"
                                extra="Show or hide the product in catalogue/ online store"
                              >
                                <Switch
                                  style={
                                    this.state.show_online
                                      ? { backgroundColor: '#15be53' }
                                      : { backgroundColor: '#e11900' }
                                  }
                                  checked={this.state.show_online}
                                  onChange={e => this.setState({ show_online: e })}
                                  checkedChildren={<span className="font-weight-bold"></span>}
                                  unCheckedChildren={<span className="font-weight-bold"></span>}
                                />
                              </Form.Item>
                            </Col>
                          )}

                          {this.state.variants.length == 0 && (
                            <Col span={12}>
                              <Form.Item
                                name="not_for_sale"
                                label="Not For sale"
                                valuePropName="checked"
                                extra={
                                  'Hides the item for sale and shows only while making a purchase. eg. Office equipment'
                                }
                              >
                                <Switch
                                  onChange={e => this.setState({ not_for_sale: e })}
                                  checked={this.state.not_for_sale}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </Row>

                        {/*<Form.Item name="free_qty" label="Free Quantity">
                <Input placeholder="1" />
              </Form.Item>*/}
                      </Panel>
                    </Collapse>
                  </div>
                </Affix>
              </Form>
              <CustomColumnsForm
                onRef={ref => (this.custom_column = ref)}
                onAPICalled={this.getSuggestedColumns}
              />
              <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
              <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
            </TabPane>
            <TabPane
              className="pt-0"
              tab={
                store.get('price_list') == 1 ? (
                  <>
                    {' '}
                    <Tooltip
                      title={
                        !this.state.saved
                          ? "It will be enabled once the product is created. Click on 'Add Item' and then 'Edit' to add price lists."
                          : 'Manage Different price lists of product.'
                      }
                    >
                      <span>Price Lists</span>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    Price Lists <BizBadge />
                  </>
                )
              }
              key="2"
              disabled={!this.state.saved}
            >
              {this.state.saved && (
                <>
                  <Card className="mb-4">
                    <ProductPriceList
                      product_id={this.state.selectedData.product_id}
                      variant_id={0}
                    />

                    {this.props.price_lists.length == 0 && !this.state.price_loading && (
                      <div className="center mt-4">
                        <AddPriceList
                          type="dashed"
                          block
                          icon={<PlusOutlined className="font-weight-bold text-primary" />}
                          buttonText="Add New Price List"
                          buttonClassName="border-blue-700"
                          textClassName="font-weight-bold text-primary"
                          onPriceListAdded={this.refreshPriceList}
                        />
                      </div>
                    )}
                    {this.state.price_loading && (
                      <div className="text-center">
                        <Spin />
                      </div>
                    )}
                  </Card>
                  <FeatureAction
                    title="Looking for add prices in bulk?"
                    description="Add prices for items in bulk for single or multiple price lists"
                    onClick={() => {
                      this.handelCloseDrawer()
                      window.open('/products?tab=price_lists')
                    }}
                    btnText="Bulk update price lists"
                  />
                </>
              )}

              <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
            </TabPane>

            <TabPane
              className="pt-0"
              tab={
                <Tooltip
                  title={
                    !this.state.saved
                      ? "It will be enabled once the product is created. Click on 'Add Item' and then 'Edit' to add attachments."
                      : 'Manage attachments of product.'
                  }
                >
                  <span>Attachments</span>
                </Tooltip>
              }
              key="4"
              disabled={!this.state.saved}
            >
              <Card className="mb-4 ">
                {this.state.saved && (
                  <div className="w-full">
                    <Form
                      ref={this.productAttachmentsForm}
                      name="productAttachmentsForm"
                      onFinish={this.onProductAttachmentsFinish}
                    >
                      <Form.Item name="file" valuePropName="file">
                        <Dragger
                          beforeUpload={() => false}
                          listType="picture"
                          fileList={this.state.productAttachments}
                          multiple
                          defaultFileList={this.state.productAttachments}
                          maxCount={5}
                          onRemove={this.onRemoveProductAttachment}
                          onChange={this.handleAttachmentChange}
                          accept={
                            'image/png, image/jpg, image/jpeg , application/pdf , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet '
                          }
                        >
                          {this.state.productAttachments.length < 5 ? (
                            uploadAttachmentsButton
                          ) : (
                            <>Max 5 Files Uploaded </>
                          )}
                        </Dragger>
                      </Form.Item>
                      <p className="text-gray-500 font-weight-medium mt-2 mb-1 font-size-12">
                        * Maximum 5 files can be uploaded ('png', 'jpg', 'jpeg',
                        'xlsx','pdf','doc','docx','xls','csv' are allowed)
                      </p>
                    </Form>
                  </div>
                )}
              </Card>
            </TabPane>
            {this.props.permissions.history == 1 && (
              <TabPane
                className="pt-0"
                tab={
                  <>
                    {' '}
                    <Tooltip
                      title={
                        !this.state.saved
                          ? "It will be enabled once the product is created. Click on 'Add Item' and then 'Edit' to see Activity."
                          : 'View activity of the product.'
                      }
                    >
                      <span>
                        Activity
                        <BetaBadge />{' '}
                      </span>
                    </Tooltip>
                  </>
                }
                key="audit_history"
                disabled={!this.state.saved}
              >
                <Card className="">
                  {this.state.loading ? (
                    <div
                      className="flex justify-center items-center my-5"
                      style={{ height: '100%' }}
                    >
                      <LoadingOutlined style={{ fontSize: 40 }} className="text-primary" spin />
                    </div>
                  ) : (
                    <AuditHistory auditHistory={this.state.product_audit_history} />
                  )}
                </Card>
              </TabPane>
            )}
          </Tabs>

          <EnhancedAlternativeUnits
            onRef={ref => (this.alternative_units = ref)}
            onAPICalled={this.storeAlternativeUnits}
          />
          <MultipleTaxRateForm ref={this.taxRateFormRef} onAPICalled={this.storeMultipleTaxRates} />
          <VariantForm
            onRef={ref => (this.variantForm = ref)}
            onAPICalled={() => this.onFill(this.state.selectedData)}
          />
          <InventoryForm
            onRef={ref => (this.inventoryVariantForm = ref)}
            onAPICalled={() => this.onFill(this.state.selectedData)}
            identifier="product_form"
          />
          <BillOfMaterials
            onRef={ref => (this.bom = ref)}
            onAPICalled={() => this.onFill(this.state.selectedData)}
          />

          <ProductBarcode onRef={ref => (this.barcode = ref)} menu="barcode" />
          <Batches
            onRef={ref => (this.batches = ref)}
            onAPICalled={() => this.onFill(this.state.selectedData)}
          />
          <CategoryForm
            onRef={ref => (this.categoryForm = ref)}
            onAPICalled={() => {
              this.props.dispatch(get_categories())
            }}
          />
          <AIModal ref={this.ai} onContinue={this.onAIContinue} />
          <ConvertToBatchSN
            visible={this.state.convertToBatchSnVisible}
            onCancel={() =>
              this.setState({ convertToBatchSnVisible: false, is_batch_serial_conversion: false })
            }
            onOk={e =>
              this.setState({
                has_batches: e,
                convertToBatchSnVisible: false,
                is_batch_serial_conversion: true,
              })
            }
            convertType={this.state.convertType}
            itemType="product"
          />
        </Drawer>
        <SubscriptionUpdateModal
          showConfirmationModal={this.state.showSubscriptionUpdateModal}
          source={this.state.selectedData.product_name}
          onCancel={() => this.setState({ showSubscriptionUpdateModal: false })}
          callData={() => this.setState({ showSubscriptionUpdateModal: false })}
          productId={this.state.selectedData.product_id}
          variantId={0}
          batchId={0}
          activeSubscriptions={this.state.activeSubscriptions}
        />
        <VisibilityModal
          ref={this.visibilityRef}
          type="Product"
          onFinish={this._onFinish}
          visibility={this.props.product_settings?.visibility ?? true}
        />
        <ExistingProductModal ref={this.existingProductRef} />
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    companyDetailsAdded: state.welcome.company_details_added,
    bankDetailsAdded: state.welcome.bank_details_added,
    productsAdded: state.welcome.products_added,
    permissions: state.permissions.permission,
    integrations: state.permissions.integrations,
    hsn_suggestions: state.document.hsnSuggestions,
    custom_column_suggestions: state.document.productCustomColumnsSuggestion,
    custom_columns: state.document.custom_column_data.custom_columns_not_default,
    custom_columns_active: state.document.custom_column_data.custom_columns_not_default_active,
    invoice_settings: state.document.invoiceSettings,
    column_id_dict: state.document.custom_column_data.column_id_dict,
    product_options: state.document.product_options,
    price_lists: state.document.price_lists,
    tax_rates: state.document.tax_rates,
    zero_tax_types: state.document.zero_tax_types,
    additional_cess_rates: state.document.additional_cess_rates,
    company_details: state.permissions.company_details,
    categories: state.document.categories,
    product_types: state.document.product_types,
    warehouses: state.warehouse.warehouses,
    all_doc_counts: state.document.all_doc_counts,
    top_custom_fields: state.document.top_custom_fields,
    units: state.document.units,
    user: state.user,
    product_settings: state.permissions.productSettings,
    is_branch: state.user?.selectedCompany?.is_branch,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(ProductForm)
