import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

export const Loader = ({ text, visibleText, size }) => {
  return (
    <div
      className="text-center"
      style={{
        height: '100px',
        position: 'absolute',
        top: '50%',
        left: '50%',
      }}
    >
      <Spin indicator={<LoadingOutlined />} size={size} />
      {/* {visibleText && (
        <p>
          <b>{text}</b>
        </p>
      )} */}
    </div>
  )
}

Loader.defaultProps = {
  text: 'Fetching Data...Please wait',
  visibleText: false,
  size: 'large',
}
