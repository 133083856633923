import { StyleSheet } from '@react-pdf/renderer'

export const styles = invoice => {
  let FONT_FACTOR = invoice.invoice_settings.font_size
  let PAPER_SIZE = invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style
  // To select font style if language is English
  if (locale == 'English') {
    locale = font_style
  }
  return StyleSheet.create({
    // BILL TO CUSTOMER / VENDOR
    billTo_headerContainer: {
      '@media max-width: 400': {
        flexDirection: 'column',
      },
      '@media min-width: 400': {
        flexDirection: 'row',
      },
      marginBottom: 4.5 * PAPER_SIZE,
      fontFamily: `helreg_${locale}`,
      lineHeight: 1.4,
      paddingLeft: 4.5,
      paddingRight: 0 * PAPER_SIZE,
    },
    billTo_billTo: {
      marginTop: 0,
      marginBottom: 1.8 * PAPER_SIZE,
      color: '#333333',
      fontFamily: `helbold_${locale}`,
      fontSize: 9 * FONT_FACTOR,
    },
    billTo_block1: {
      width: '50%',
      marginRight: '2%',
      fontSize: 9 * FONT_FACTOR,
    },
    billTo_block2: {
      width: '50%',
      fontSize: 9 * FONT_FACTOR,
    },
    // billTo_block3: {
    //   width: '34%',
    //   marginRight: 0,
    //   fontSize: 9 * FONT_FACTOR,
    // },
    // billTo_block4: {
    //   width: '34%',
    //   marginRight: 0,
    //   fontSize: 9 * FONT_FACTOR,
    // },
    // billTo_block5: {
    //   width: '66%',
    //   marginRight: 0,
    //   fontSize: 9 * FONT_FACTOR,
    // },
    billTo_row: {
      flexDirection: 'row',
      paddingVertical: 0.9 * PAPER_SIZE,
      paddingHorizontal: 0,
    },
    billTo_labels: {
      width: '45%',
      color: '#333333',
      fontFamily: `helbold_${locale}`,
      textAlign: 'left',
      //paddingRight: 18 * PAPER_SIZE,
      fontSize: 9 * FONT_FACTOR,
    },
    billTo_info: {
      width: '54%',
      fontFamily: `helmed_${locale}`,
      fontSize: 9 * FONT_FACTOR,
      textAlign: 'left',
      //paddingRight: 9 * PAPER_SIZE,
      color: '#141414',
    },
    billTo_mb: {
      marginBottom: 1.8 * PAPER_SIZE,
    },
    billTo_mt: {
      marginTop: 2.7 * PAPER_SIZE,
    },
    billTo_mb1: {
      marginBottom: 0,
    },
    billTo_smallFont: {
      fontSize: 9 * FONT_FACTOR,
    },
    billTo_bold: {
      fontFamily: `helbold_${locale}`,
    },

    // COMPANY DETAILS
    company_headerContainer: {
      '@media max-width: 400': {
        flexDirection: 'column',
      },
      '@media min-width: 400': {
        flexDirection: 'row',
      },
      marginBottom: 8 * PAPER_SIZE,
      fontFamily: `helreg_${locale}`,
      borderBottomWidth: 0.9,
      lineHeight: 1.26,
    },
    company_blockC: {
      width: '68%',
      flexDirection: 'row',
      paddingRight: 10 * PAPER_SIZE,
    },
    company_blockI: {
      width: '32%',
      paddingRight: 4.5,
    },
    company_blockCR: {
      width: '32%',
      paddingRight: 0,
    },
    company_blockC2: {
      paddingVertical: 4.5 * PAPER_SIZE,
      paddingHorizontal: 4.5 * PAPER_SIZE,
      flex: 1,
    },
    company_block2: {
      width: '100%',
      marginRight: 0,
    },
    company_invoiceContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    company_invoiceDate: {
      fontSize: 8.5 * FONT_FACTOR,
      color: '#141414',
    },
    company_label: {
      fontSize: 8.5 * FONT_FACTOR,
      color: '#333333',
      textAlign: 'left',
      fontFamily: `helbold_${locale}`,
      // fontFamily: `helitalic_${locale}`,
    },
    company_address: {
      fontSize: 8.5 * FONT_FACTOR,
    },
    company_bold: {
      fontFamily: `helbold_${locale}`,
    },
    company_large: {
      fontSize: 11.7 * FONT_FACTOR,
    },
    company_labels: {
      color: '#333333',
      fontFamily: `helreg_${locale}`,
      fontSize: 8.5 * FONT_FACTOR,
      textAlign: 'right',
    },
    company_info: {
      fontSize: 8.5 * FONT_FACTOR,
      color: '#141414',
      textAlign: 'right',
    },
    company_row: {
      flexDirection: 'row',
      paddingHorizontal: 0,
    },
    company_logo: {
      maxWidth: '63',
      maxHeight: '63',
      borderRadius: 9,
      position: 'relative',
      textAlign: 'center',
      marginHorizontal: 'auto',
      marginTop: 5.4 * PAPER_SIZE,
      marginRight: 4.5 * PAPER_SIZE,
    },
    company_reportTitle: {
      fontFamily: `helbold_${locale}`,
      letterSpacing: 2 * PAPER_SIZE,
      fontSize: 9 * FONT_FACTOR,
      textTransform: 'uppercase',
      textAlign: 'right',
      marginTop: 4.5 * PAPER_SIZE,
    },
    company_reportType: {
      color: '#333333',
      fontFamily: `helmed_${locale}`,
      letterSpacing: 1 * PAPER_SIZE,
      fontSize: 8 * FONT_FACTOR,
      marginBottom: 0.45 * PAPER_SIZE,
      textTransform: 'uppercase',
      textAlign: 'right',
    },
    company_dec: {
      fontFamily: `helmed_${locale}`,
      fontSize: 7 * FONT_FACTOR,
      letterSpacing: 0,
      color: '#333333',
      textTransform: 'uppercase',
      textAlign: 'right',
    },

    //INDEX FILE
    index_page: {
      fontSize: 10 * FONT_FACTOR,
      paddingHorizontal: 27 * PAPER_SIZE,
      lineHeight: 1.3,
      flexDirection: 'column',
      fontFamily: `helreg_${locale}`,
      color: '#141414',
    },
    index_footer: {
      alignContent: 'bottom',
      position: 'absolute',
      fontFamily: `helmed_${locale}`,
      left: 27 * PAPER_SIZE,
      width: '100%',
    },
    index_brandingFooter: {
      alignContent: 'bottom',
      position: 'absolute',
      fontFamily: `helmed_${locale}`,
      bottom: 27 * PAPER_SIZE,
      left: 27 * PAPER_SIZE,
      width: '100%',
      flexDirection: 'row',
    },
    index_logo: {
      width: 'auto',
      height: '27',
      position: 'relative',
      textAlign: 'right',
      marginHorizontal: 'auto',
      marginRight: 0,
    },
    index_brandingLogo: {
      width: '100%',
      textAlign: 'left',
    },
    index_brandingText: {
      width: '100%',
      textAlign: 'right',
    },
    index_footerNote: {
      fontSize: 8.1 * FONT_FACTOR,
      bottom: 18 * PAPER_SIZE,
      left: 27 * PAPER_SIZE,
      alignContent: 'bottom',
      position: 'absolute',
      fontFamily: `helmed_${locale}`,
      marginTop: 0,
    },
    index_alignRight: {
      fontSize: 8.1 * FONT_FACTOR,
      textAlign: 'right',
    },
    index_banner: {
      width: '100%',
      maxHeight: '72',
      position: 'relative',
      textAlign: 'right',
      paddingVertical: 2.7 * PAPER_SIZE,
      marginHorizontal: 'auto',
      marginLeft: 0,
    },
    index_watermark: {
      alignContent: 'center',
      position: 'absolute',
      bottom: '20%',
      left: '3%',
      width: '100%',
      opacity: 0.072 * PAPER_SIZE,
    },

    // BANK DETAILS
    invoiceBankDetails_container: {
      '@media max-width: 400': {
        flexDirection: 'column',
      },
      '@media min-width: 400': {
        flexDirection: 'row',
      },
      marginTop: 9 * PAPER_SIZE,
      lineHeight: 1.5,
      padding: 2 * PAPER_SIZE,
    },
    invoiceBankDetails_heading: {
      marginTop: 0,
      paddingTop: 1.5 * PAPER_SIZE,
      paddingBottom: 1 * PAPER_SIZE,
      color: '#333333',
      fontFamily: `helbold_${locale}`,
      // fontFamily: `helitalic_${locale}`,
    },
    invoiceBankDetails_row: {
      flexDirection: 'row',
      paddingVertical: 0.45 * PAPER_SIZE,
      paddingHorizontal: 0,
    },
    invoiceBankDetails_labels: {
      width: '25%',
      color: '#333333',
      fontFamily: `helreg_${locale}`,
      paddingRight: 4.5,
      fontSize: 8.82 * FONT_FACTOR,
    },
    invoiceBankDetails_info: {
      width: '75%',
      fontFamily: `helmed_${locale}`,
      fontSize: 8.82 * FONT_FACTOR,
      textAlign: 'left',
      paddingRight: 9 * PAPER_SIZE,
      color: '#141414',
    },
    invoiceBankDetails_label2: {
      fontSize: 7.2 * FONT_FACTOR,
      color: '#333333',
      fontFamily: `helreg_${locale}`,
    },
    invoiceBankDetails_bigblock: {
      width: '310',
      flexDirection: 'row',
      marginRight: 29 * PAPER_SIZE,
    },
    invoiceBankDetails_block: {
      width: '108',
      marginRight: 27 * PAPER_SIZE,
    },
    invoiceBankDetails_block2: {
      width: '72',
      marginRight: 0,
    },
    invoiceBankDetails_block3: {
      width: '153',
      marginRight: 20 * PAPER_SIZE,
    },
    invoiceBankDetails_block4: {
      width: '189',
      marginRight: 10 * PAPER_SIZE,
      paddingTop: 10.8 * PAPER_SIZE,
      textAlign: 'right',
    },
    invoiceBankDetails_eblock: {
      width: '80%',
      marginRight: 10 * PAPER_SIZE,
      paddingTop: 10.8 * PAPER_SIZE,
      textAlign: 'left',
    },
    invoiceBankDetails_signature: {
      marginTop: 2.7 * PAPER_SIZE,
      width: 'auto',
      height: '54',
      position: 'relative',
      marginHorizontal: 'auto',
      marginRight: 0.9 * PAPER_SIZE,
    },
    // invoiceBankDetails_bankNotes: {
    //   fontSize: 8.35 * FONT_FACTOR,
    //   marginTop: 2.7 * PAPER_SIZE,
    //   paddingRight: 4.5,
    //   color: '#333333',
    //   textAlign: 'left',
    // },
    invoiceBankDetails_einvoice_block: {
      width: '100%',
      marginRight: 10 * PAPER_SIZE,
      paddingTop: 10.8 * PAPER_SIZE,
      textAlign: 'left',
      flexDirection: 'row',
    },
    invoiceBankDetails_qrcode: {
      width: '100%',
      position: 'relative',
      textAlign: 'right',
      marginHorizontal: 'auto',
      marginRight: 0,
      paddingLeft: 9 * PAPER_SIZE,
    },

    // ITEMS TABLE
    invoiceItemsTable_tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 3.6 * PAPER_SIZE,
      borderBottomWidth: 1.8,
      borderTopWidth: 1,
      fontFamily: `helreg_${locale}`,
      borderColor: '#276EF1',
    },
    invoiceItemsTable_amountinwords: {
      width: '70%',
      textAlign: 'right',
      fontSize: 8 * FONT_FACTOR,
      paddingRight: 4.5,
    },
    invoiceItemsTable_itemsQty: {
      width: '30%',
      textAlign: 'left',
      fontSize: 7.5 * FONT_FACTOR,
      marginRight: 0,
      marginTop: 0,
      paddingLeft: 4.5,
      color: '#333333',
    },
    invoiceItemsTable_row1: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 0,
      marginBottom: 0,
    },
    invoiceItemsTable_bold: {
      fontFamily: `helbold_${locale}`,
    },
    invoiceItemsTable_dc_totals: {
      textAlign: 'right',
      fontSize: 9.9 * FONT_FACTOR,
      paddingRight: 4.5,
      marginTop: 2.7 * PAPER_SIZE,
      color: '#333333',
    },
    invoiceItemsTable_amountPaid: {
      textAlign: 'right',
      fontSize: 9 * FONT_FACTOR,
      marginLeft: 13.5 * PAPER_SIZE,
      paddingRight: 4.5,
      marginTop: 0.9 * PAPER_SIZE,
      color: '#333333',
    },
    invoiceItemsTable_image: {
      width: 9.9 * PAPER_SIZE,
      height: 9.9 * PAPER_SIZE,
      position: 'absolute',
      textAlign: 'right',
      marginTop: 1.8 * PAPER_SIZE,
    },
    invoiceItemsTable_block: {
      flexDirection: 'row',
      marginTop: 0.9 * PAPER_SIZE,
    },
    invoiceItemsTable_balanceBlock: {
      marginVertical: 3.6 * PAPER_SIZE,
    },
    invoiceItemsTable_labels: {
      width: '78%',
      color: '#333333',
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      fontSize: 9 * FONT_FACTOR,
    },
    invoiceItemsTable_info: {
      width: '22%',
      fontSize: 9 * FONT_FACTOR,
      paddingRight: 4.5,
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      color: '#333333',
    },
    invoiceItemsTable_labels2: {
      width: '78%',
      color: '#333333',
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      fontSize: 9 * FONT_FACTOR,
    },
    invoiceItemsTable_payments: {
      width: '78%',
      color: '#333333',
      textAlign: 'right',
      paddingRight: 4.5,
      fontSize: 9 * FONT_FACTOR,
    },
    invoiceItemsTable_info2: {
      width: '22%',
      fontSize: 9 * FONT_FACTOR,
      paddingRight: 4.5,
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      color: '#333333',
    },
    invoiceItemsTable_borderBlock: {
      width: '64%',
    },
    invoiceItemsTable_borderBlock2: {
      width: '36%',
      textAlign: 'right',
      borderBottomWidth: 0.2,
      marginVertical: 1.8 * PAPER_SIZE,
    },
    invoiceItemsTable_BatchName: {
      fontSize: 7.5 * FONT_FACTOR,
      color: '#333333',
      display: 'block',
    },

    // NOTES AND TERMS
    invoiceNotes_titleContainer: {
      flexDirection: 'column',
      marginTop: 9 * PAPER_SIZE,
    },
    invoiceNotes_blockC: {
      width: '100%',
      flexDirection: 'row',
      alignContent: 'center',
    },
    invoiceNotes_blockCA: {
      width: '100%',
    },
    invoiceNotes_blockCAA: {
      width: '100%',
    },
    invoiceNotes_blockC2: {
      paddingRight: 4.5 * PAPER_SIZE,
      flex: 1,
    },
    invoiceNotes_block: {
      flexDirection: 'column',
    },
    invoiceNotes_heading: {
      marginTop: 0,
      fontSize: 8.5 * FONT_FACTOR,
      paddingBottom: 3 * PAPER_SIZE,
      color: '#333333',
      fontFamily: `helbold_${locale}`,
      textAlign: 'left',
    },
    invoiceNotes_customerNotes: {
      fontSize: 8.1 * FONT_FACTOR,
      textAlign: 'left',
    },
    invoiceNotes_terms: {
      fontSize: 7.2 * FONT_FACTOR,
      textAlign: 'left',
    },
    invoiceNotes_mb: {
      marginBottom: 4.5 * PAPER_SIZE,
    },
    invoiceNotes_mt: {
      marginTop: 4.5 * PAPER_SIZE,
    },
    invoiceNotes_receiveSignBlock: {
      width: '32%',
    },
    invoiceNotes_receiveSignText: {
      fontSize: 8.1 * FONT_FACTOR,
      paddingTop: 4.5,
      paddingLeft: 4.5,
      marginTop: 40,
      borderTopWidth: 1,
      borderColor: '#333333',
      textAlign: 'left',
    },
    invoiceNotes_qrcode: {
      width: '18%',
      position: 'relative',
      textAlign: 'left',
      paddingRight: 9 * PAPER_SIZE,
      marginTop: 2 * PAPER_SIZE,
      marginHorizontal: 'auto',
      marginRight: 0,
    },
    invoiceNotes_row: {
      width: '100%',
      flexDirection: 'row',
    },
    invoiceNotes_irn: {
      fontSize: 6.3 * FONT_FACTOR,
    },

    // TABLE FOOTER
    invoiceTableFooter_row: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      alignItems: 'center',
      minHeight: 12 * PAPER_SIZE,
      fontSize: 8.5 * FONT_FACTOR,
      marginTop: 0.9 * PAPER_SIZE,
    },
    invoiceTableFooter_row1: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      alignItems: 'center',
      minHeight: 18 * PAPER_SIZE,
      fontSize: 12.6 * FONT_FACTOR,
      marginTop: 0.9 * PAPER_SIZE,
    },
    invoiceTableFooter_row2: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      alignItems: 'center',
      minHeight: 12 * PAPER_SIZE,
      fontSize: 8.5 * FONT_FACTOR,
      marginTop: 0.9 * PAPER_SIZE,
    },
    invoiceTableFooter_row3: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      alignItems: 'center',
      minHeight: 12 * PAPER_SIZE,
      fontSize: 8.5 * FONT_FACTOR,
      marginTop: 0.9 * PAPER_SIZE,
    },
    invoiceTableFooter_block: {
      width: '72%',
      fontSize: 8 * FONT_FACTOR,
    },
    invoiceTableFooter_block2: {
      width: '28%',
      borderBottomWidth: 0.5,
      borderBottomColor: '#142328',
      marginTop: 2 * PAPER_SIZE,
    },
    invoiceTableFooter_description: {
      width: '78%',
      textAlign: 'right',
      paddingRight: 0,
    },
    invoiceTableFooter_description2: {
      width: '58%',
      textAlign: 'right',
      paddingRight: 0,
    },
    invoiceTableFooter_total: {
      width: '22%',
      textAlign: 'right',
      paddingRight: 4.5,
    },
    invoiceTableFooter_bold: {
      fontFamily: `helbold_${locale}`,
    },
    invoiceTableFooter_proxima: {
      fontFamily: `helbold_${locale}`,
    },

    // TABLE HEADER
    invoiceTableHeader_container: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#276EF1',
      alignItems: 'center',
      minHeight: 12.6 * PAPER_SIZE,
      lineHeight: 1.35,
      color: '#141414',
      paddingVertical: 2.7 * PAPER_SIZE,
      fontSize: 9 * FONT_FACTOR,
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
    },
    invoiceTableHeader_slno_dc: {
      width: '5%',
      paddingLeft: '3px',
      textAlign: 'left',
    },
    invoiceTableHeader_description_dc: {
      width: '78%',
      textAlign: 'left',
    },
    invoiceTableHeader_qty_dc: {
      width: '17%',
      textAlign: 'right',
      paddingRight: 4.5,
    },
    // ITEM HEADERS
    invoiceItemHeader: {
      width: '100%',
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#A1BDCA',
      alignItems: 'center',
      minHeight: 12.6 * PAPER_SIZE,
      color: '#141414',
      paddingVertical: 1.8 * PAPER_SIZE,
      fontSize: 9 * FONT_FACTOR,
      textAlign: 'right',
      fontFamily: `helmed_${locale}`,
    },

    // TABLE ROW
    invoiceTableRow_row: {
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 12.6 * PAPER_SIZE,
      fontSize: 9 * FONT_FACTOR,
      paddingVertical: 1.8 * PAPER_SIZE,
      fontFamily: `helreg_${locale}`,
      textAlign: 'right',
      textOverflow: 'wrap',
    },
    invoiceTableRow_description: {
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: 8.1 * FONT_FACTOR,
      paddingVertical: 2.7 * PAPER_SIZE,
      textOverflow: 'wrap',
    },
    invoiceTableRow_border: {
      borderBottomColor: '#A1BDCA',
      borderBottomWidth: 0.72,
    },
    invoiceTableRow_bold: {
      fontFamily: `helmed_${locale}`,
    },
    invoiceTableRow_med: {
      fontFamily: `helmed_${locale}`,
    },
    invoiceTableRow_slno_dc: {
      width: '5%',
      paddingLeft: '3px',
      textAlign: 'left',
    },
    invoiceTableRow_description_dc: {
      fontFamily: `helmed_${locale}`,
      width: '78%',
      textAlign: 'left',
      paddingRight: 4 * PAPER_SIZE,
    },
    invoiceTableRow_qty_dc: {
      width: '17%',
      textAlign: 'right',
      paddingRight: 4 * PAPER_SIZE,
    },
    invoiceTableRow_image: {
      maxWidth: '72',
      maxHeight: '72',
      borderRadius: 5.4,
      position: 'relative',
      textAlign: 'center',
      marginHorizontal: 'auto',
      marginLeft: 0,
      marginRight: 5.4,
      marginVertical: 1.8,
    },
    invoiceTableRow_block: {
      paddingRight: 0.9 * PAPER_SIZE,
      flex: 1,
    },
  })
}
