import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { Fragment } from 'react'

const borderColor = '#142328'
const styles = StyleSheet.create({
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  left: {
    textAlign: 'left',
  },
  row: {
    flexDirection: 'row',
    fontFamily: 'helreg_English',
    textOverflow: 'wrap',
    fontSize: 9.54,
    borderBottomWidth: 0.36,
    borderBottomColor: '#E3E8EE',
  },
  cell: {
    paddingLeft: 4.5,
    paddingVertical: 5.4,
    paddingRight: 3.6,
    borderRightWidth: 0.36,
    borderRightColor: '#E3E8EE',
  },
  cellHeading: {
    paddingLeft: 4.5,
    paddingVertical: 1.8,
    paddingRight: 3.6,
    borderRightWidth: 0.36,
    borderRightColor: '#E3E8EE',
    fontFamily: 'helbold_English',
  },
  row1: {
    flexDirection: 'row',
    fontFamily: 'helreg_English',
    textOverflow: 'wrap',
    fontSize: 9,
    backgroundColor: '#E3E8EE',
    borderWidth: 0.36,
    borderColor: '#E3E8EE',
  },
  bold: {
    fontFamily: 'helbold_English',
  },
  med: {
    fontFamily: 'helmed_English',
  },
  productdescription: {
    width: '100%',
    flexDirection: 'row',
    textAlign: 'left',
    paddingRight: 4.5,
    paddingLeft: 4.5,
    paddingTop: 5,
    fontSize: 7,
  },
})

const LedgerItems = ({ invoice }) => {
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  var single_col = 100 / 9

  if (invoice.show_pending_docs) {
    single_col = 100 / 7
  }
  var font_size = 9
  const rows = (
    <View>
      <View
        style={{
          ...styles.row1,
          fontSize: font_size,
          borderBottomColor: invoice.company_details.color,
        }}
      >
        <Text style={{ ...styles.cellHeading, width: `${2.5 * single_col}%` }}># Id</Text>
        <Text style={{ ...styles.cellHeading, ...styles.center, width: `${1.2 * single_col}%` }}>
          Date
        </Text>

        {invoice.show_pending_docs ? (
          <>
            <Text
              style={{ ...styles.cellHeading, ...styles.center, width: `${1.1 * single_col}%` }}
            >
              Payment Status
            </Text>
            <Text style={{ ...styles.cellHeading, ...styles.right, width: `${1.4 * single_col}%` }}>
              Total Amount
            </Text>
            <Text style={{ ...styles.cellHeading, ...styles.right, width: `${1.4 * single_col}%` }}>
              Amount Pending
            </Text>
          </>
        ) : (
          <>
            <Text
              style={{ ...styles.cellHeading, ...styles.center, width: `${1.1 * single_col}%` }}
            >
              Mode
            </Text>
            <Text style={{ ...styles.cellHeading, ...styles.right, width: `${1.4 * single_col}%` }}>
              Debit (-)
            </Text>
            <Text style={{ ...styles.cellHeading, ...styles.right, width: `${1.4 * single_col}%` }}>
              Credit (+)
            </Text>
            <Text style={{ ...styles.cellHeading, ...styles.right, width: `${1.4 * single_col}%` }}>
              Closing Balance
            </Text>
          </>
        )}
      </View>

      {invoice.ledger.map((item, index) => (
        <div key={index}>
          <View style={styles.row} key={(index + 1).toString()}>
            <Text style={{ ...styles.cell, width: `${2.5 * single_col}%` }}>
              <Text style={styles.bold}>
                {item.serial_number}
                {item.supplier_invoice_serial_number != ''
                  ? '(' + item.supplier_invoice_serial_number + ')'
                  : ''}
              </Text>
              {item.type != 'Opening Balance' && item.type != '' && item.type != 'Balance' && (
                <Text>, {item.type}</Text>
              )}
              {'\n'}
              <Text>{item.notes != '' && item.notes}</Text>
            </Text>
            <View style={{ ...styles.cell, width: `${1.2 * single_col}%` }}>
              <Text style={styles.center}>{item.payment_date}</Text>
            </View>

            {invoice.show_pending_docs ? (
              <>
                <View style={{ ...styles.cell, ...styles.center, width: `${1.1 * single_col}%` }}>
                  <Text>{item.payment_status}</Text>
                </View>
                <Text
                  style={{
                    ...styles.cell,
                    ...styles.right,
                    ...styles.bold,
                    width: `${1.4 * single_col}%`,
                  }}
                >
                  {item.total_amount.toLocaleString('en-IN', options)}
                </Text>
                <Text
                  style={{
                    ...styles.cell,
                    ...styles.right,
                    ...styles.bold,
                    width: `${1.4 * single_col}%`,
                    backgroundColor: item.payment_type == 'in' ? '#e6f9f0' : '#fce8e6',
                  }}
                >
                  {item.amount_pending.toLocaleString('en-IN', options)}
                </Text>
              </>
            ) : (
              <>
                <View style={{ ...styles.cell, ...styles.center, width: `${1.1 * single_col}%` }}>
                  <Text>{item.payment_mode}</Text>
                </View>
                <Text
                  style={{
                    ...styles.cell,
                    ...styles.right,
                    ...styles.bold,
                    width: `${1.4 * single_col}%`,
                    backgroundColor: '#fce8e6',
                  }}
                >
                  {item.payment_type == 'out'
                    ? item.total_amount.toLocaleString('en-IN', options)
                    : ' '}
                </Text>
                <Text
                  style={{
                    ...styles.cell,
                    ...styles.right,
                    ...styles.bold,
                    width: `${1.4 * single_col}%`,
                    backgroundColor: '#e6f9f0',
                  }}
                >
                  {item.payment_type == 'in'
                    ? item.total_amount.toLocaleString('en-IN', options)
                    : ' '}
                </Text>
                <Text
                  style={{
                    ...styles.cell,
                    ...styles.right,
                    ...styles.med,
                    width: `${1.4 * single_col}%`,
                    color: `${item.balance >= 0 ? 'green' : 'red'}`,
                  }}
                >
                  {Math.abs(item.balance).toLocaleString('en-IN', options)}
                </Text>
              </>
            )}
          </View>
        </div>
      ))}
    </View>
  )
  return <Fragment>{rows}</Fragment>
}

export default LedgerItems
