import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { EnhancedQRCode } from 'components/other/qrCodeFile'
import React, { Fragment } from 'react'
var Barcode = require('react-barcode')

const styles = StyleSheet.create({
  headerContainer: {
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    '@media min-width: 400': {
      flexDirection: 'row',
    },
    marginBottom: 4.5,
    fontFamily: 'helreg_English',
    lineHeight: 1.4,
  },
  row: {
    flexDirection: 'row',
    paddingVertical: 2.7,
    paddingHorizontal: 36,
  },
  label: {
    width: '40%',
    color: '#515154',
    fontFamily: 'helbold_English',
    paddingRight: 36,
    fontSize: 10.8,
  },
  text: {
    width: '60%',
    fontFamily: 'helbold_English',
    fontSize: 10.8,
    textAlign: 'right',
  },
  e_label: {
    width: '30%',
    color: '#515154',
    fontFamily: 'helbold_English',
    paddingRight: 36,
    fontSize: 10.8,
  },
  e_text: {
    width: '70%',
    fontFamily: 'helbold_English',
    fontSize: 8.1,
    textAlign: 'right',
  },
  ewayBill: {
    width: '30%',
    marginTop: 0,
    color: '#333333',
    // fontFamily: 'helbold',
    fontSize: 9,
    marginBottom: 5,
  },
  ewayBill2: {
    marginTop: 0,
    color: '#333333',
    fontFamily: 'helbold_English',
    fontSize: 9,
    marginBottom: 5,
    marginHorizontal: 'auto',
  },
  borderStyle: {
    marginBottom: 2.7,
    borderBottomWidth: 0.9,
    width: '36%',
  },

  block: {
    width: '100%',
    marginBottom: '2%',
    fontSize: 9,
    flexWrap: 'wrap',
    align: 'center',
  },
  block1: {
    width: '32%',
    marginRight: '2%',
    fontSize: 9,
  },
  block2: {
    width: '32%',
    marginRight: '2%',
    fontSize: 9,
  },
  block3: {
    width: '34%',
    marginRight: 0,
    fontSize: 9,
  },
  block4: {
    width: '34%',
    marginRight: 0,
    fontSize: 9,
  },
  block5: {
    width: '66%',
    marginRight: 0,
    fontSize: 9,
  },
  mb: {
    marginBottom: 1.8,
  },
  mb1: {
    marginBottom: 0,
  },
  smallFont: {
    fontSize: 9,
  },
  bold: {
    fontFamily: 'helbold_English',
  },
  upi: {
    width: '4%',
    height: 'auto',
    position: 'relative',
    textAlign: 'center',
    marginHorizontal: 'auto',
    marginTop: 2,
  },
})

const BillTo = ({ invoice }) => {
  var data = invoice.company
  var tax_id_label = invoice.company.tax_id_label
  return (
    <Fragment>
      <Text style={styles.ewayBill2}>{'E-way Bill'}</Text>
      <View style={{ ...styles.upi, width: 80, marginBottom: 10 }}>
        <EnhancedQRCode style={{ ...styles.upi }} value={invoice.eway_bill_details.serial_number} />
      </View>

      <Text style={styles.ewayBill2}>{'E-way Bill Details'}</Text>

      <View style={styles.row}>
        <Text style={styles.label}>E-way Bill No: </Text>
        <Text style={styles.text}>{invoice.eway_bill_details.serial_number}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>E-way Bill Date: </Text>
        <Text style={styles.text}>{invoice.eway_bill_details.eway_bill_date}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Generated By: </Text>
        <Text style={styles.text}>
          {invoice.company.gstin}, {invoice.company.company_name}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Valid Till: </Text>
        <Text style={styles.text}>
          {invoice.eway_bill_details.valid_till == invoice.eway_bill_details.eway_bill_date
            ? '-'
            : invoice.eway_bill_details.valid_till}
        </Text>
      </View>
      {invoice.eway_bill_details.with_irn == 1 && (
        <View style={styles.row}>
          <Text style={styles.e_label}>IRN: </Text>
          <Text style={styles.e_text}>{invoice.einvoice[0].irn}</Text>
        </View>
      )}
      <View style={styles.row}>
        <Text style={styles.label}>Transporter {tax_id_label}: </Text>
        <Text style={styles.text}>
          {invoice.eway_bill_details.transporter_gstin != ''
            ? invoice.eway_bill_details.transporter_gstin
            : 'NA'}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Distance in Kms: </Text>
        <Text style={styles.text}>
          {invoice.eway_bill_details.distance == 0 ? 'Auto' : invoice.eway_bill_details.distance}
        </Text>
      </View>

      <Text style={styles.ewayBill2}>{'PART A'}</Text>
      <View style={styles.row}>
        <Text style={styles.label}>{tax_id_label} of Supplier </Text>
        <Text style={styles.text}>
          {invoice.company.gstin}, {invoice.company.company_name}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Place of Dispatch </Text>
        {invoice.company_shipping_addr_id == -1 && invoice.eway_bill_details.with_irn == 1 ? (
          <Text style={styles.text}>
            {invoice.company.billing_address.city}, {invoice.company.billing_address.state},{' '}
            {invoice.company.billing_address.pincode}
          </Text>
        ) : (
          <Text style={styles.text}>
            {invoice.company.shipping_address.city}, {invoice.company.shipping_address.state},{' '}
            {invoice.company.shipping_address.pincode}
          </Text>
        )}
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>{tax_id_label} of Recepient </Text>
        <Text style={styles.text}>
          {invoice.customer.gstin}, {invoice.customer.company_name}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Place of Delivery </Text>
        {invoice.customer_shipping_addr_id == -1 && invoice.eway_bill_details.with_irn == 1 ? (
          <Text style={styles.text}>
            {invoice.customer.billing.city}, {invoice.customer.billing.state},{' '}
            {invoice.customer.billing.pincode}
          </Text>
        ) : (
          <Text style={styles.text}>
            {invoice.customer.shipping.city}, {invoice.customer.shipping.state},{' '}
            {invoice.customer.shipping.pincode}
          </Text>
        )}
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Document No. </Text>
        <Text style={styles.text}>{invoice.eway_bill_details.invoice_serial_number}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Document Date </Text>
        <Text style={styles.text}>{invoice.document_date}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Transaction Type </Text>
        <Text style={styles.text}>{'Regular'}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Value of Goods </Text>
        <Text style={styles.text}>Rs. {invoice.total[0].amount}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>HSN Code </Text>
        <Text style={styles.text}>{invoice.hsn_code}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Reason for Transportation </Text>
        <Text style={styles.text}>{invoice.eway_bill_details.reason}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Transporter </Text>
        <Text style={styles.text}>
          {invoice.eway_bill_details.transporter_gstin} &{' '}
          {invoice.eway_bill_details.transporter_name}
        </Text>
      </View>
    </Fragment>
  )
}

export default BillTo
