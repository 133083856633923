import React from 'react'
import { get_range_name } from 'services/axios'

export default function ShowDateString({ dateString, showInputWidth = true, width = '360px' }) {
  return (
    <span className="md:flex">
      {showInputWidth && (
        <span className="hidden md:block" style={{ width: width }}>
          &nbsp;
        </span>
      )}
      {get_range_name(dateString) != '' ? (
        <span className="font-size-11 text-gray-400 mb-2 pl-11 font-weight-medium">
          Showing data for <strong>{get_range_name(dateString)}</strong>
        </span>
      ) : (
        <span className="ant-form-item-extra">&nbsp;</span>
      )}
    </span>
  )

  // //new
  // return (
  //   <span className="">
  //     {get_range_name(dateString) != '' ? (
  //       <span className="text-gray-400 mb-2 font-weight-medium">
  //         <i className="fa-light fa-calendar-range mr-2"></i><strong>{get_range_name(dateString)}</strong>
  //       </span>
  //     ) : (
  //       <span className="ant-form-item-extra"><i className="fa-light fa-calendar-range mr-2"></i></span>
  //     )}
  //   </span>
  // )
}
