import { Button, Card, Drawer, Form, Input, message, Switch, Tooltip } from 'antd'
import BankNotesEditor from 'components/other/editor'
import { formItemLayout } from 'components/other/utility'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { getAPI, getAPIData } from 'services/jwt'
import store from 'store'

interface NewBankFormProps {
  visible: boolean
  user?: any
  refreshBanks: () => void
  dispatch: any
  get_payment_modes: () => void
  onClose: () => void
  selectedBank?: any
  handleIsFirstBank?: (isFirstBank: boolean, data: any, document_counts: any) => void
  bankData?: any
}

const NewBankForm: React.FC<NewBankFormProps> = ({
  visible,
  user,
  refreshBanks,
  dispatch,
  get_payment_modes,
  onClose,
  selectedBank,
  handleIsFirstBank,
  bankData = [],
}) => {
  const [state, setState] = useState({
    bankData: [] as any[],
    selectedBank: {} as any,
    selected_bank_id: -1,
    upi_id: '',
    upiVerified: false,
    upiLoading: false,
    upiName: '',
    touched: { bank_no: false, confirm: false },
    editorState: EditorState.createEmpty(),
    isFirstBank: false,
  })
  useEffect(() => {
    if (selectedBank && visible) {
      setState(prevState => ({
        ...prevState,
        selectedBank,
        upiVerified: selectedBank.upi !== '',
        upi_id: selectedBank.upi || '',
        editorState: selectedBank.notes
          ? EditorState.createWithContent(stateFromHTML(selectedBank.notes))
          : EditorState.createEmpty(),
      }))

      formRef.current?.setFieldsValue({
        ...selectedBank,
        confirm: selectedBank.bank_no,
      })
    }
  }, [selectedBank, visible])
  const formRef = useRef<any>(null)

  const onFinish = async (values: any) => {
    if (values.upi && !state.upiVerified) {
      message.error('Please verify UPI ID')
      return
    }

    var url = 'add_bank_details'
    var reqData = {}
    if (state.selectedBank.id != undefined) {
      url = 'bank_details'
      reqData = { id: state.selectedBank.id }
    }
    const req = {
      ...values,
      ...reqData,
      notes: stateToHTML(state.editorState.getCurrentContent()),
      upi_name: state.upiName,
    }

    const data = await getAPI('user', url, req)
    if (data) {
      store.set('bank_details_added', true)
      message.success('Updated Successfully')
      dispatch(get_payment_modes())
      refreshBanks()
      if (url == 'add_bank_details') {
        const is_first_bank = bankData?.filter((row: any) => row.id != 0).length == 0
        setState(prevState => ({
          ...prevState,
          isFirstBank: is_first_bank,
        }))
        if (is_first_bank && handleIsFirstBank) {
          handleIsFirstBank(is_first_bank, data, data.document_data)
        }
      }
      setState(prevState => ({
        ...prevState,
        selectedBank: {},
        upiVerified: false,
        editorState: EditorState.createEmpty(),
      }))
      onClose()
    }
  }

  const handleBlur = (field: string) => {
    setState(prevState => ({
      ...prevState,
      touched: { ...prevState.touched, [field]: true },
    }))
  }

  const onFetchBankDetails = async () => {
    if (user?.selectedCompany?.country_code !== 'IN') return

    const ifsc = formRef.current?.getFieldValue('ifsc')
    if (ifsc) {
      try {
        const response = await fetch(`https://ifsc.razorpay.com/${ifsc}`)
        if (response.ok) {
          const bankDetails = await response.json()
          formRef.current?.setFieldsValue({
            bank_name: bankDetails.BANK,
            branch_name: bankDetails.BRANCH,
          })
          message.success('Bank details fetched successfully')
        } else {
          message.error('Invalid IFSC Code! Enter Correct IFSC Code or Fill Manually')
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      message.error('Please enter IFSC code')
    }
  }

  const verifyUPI = async () => {
    if (!state.upi_id) {
      message.error('Please enter UPI ID')
      return
    }

    setState(prevState => ({ ...prevState, upiLoading: true }))
    const res = await getAPIData('payments', `validate_vpa?upi_id=${state.upi_id}`)

    if (res?.response?.success) {
      setState(prevState => ({
        ...prevState,
        upiVerified: true,
        upiName: res.response.data.name,
      }))
    } else {
      message.error(res.response.message)
    }

    setState(prevState => ({ ...prevState, upiLoading: false }))
  }

  return (
    <Drawer
      title={
        <>
          <div className="flex justify-between items-center">
            <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
              {<FormattedMessage id="table.bankDetails" />}
            </span>

            <Button size="middle" type="primary" onClick={() => formRef.current?.submit()}>
              {<FormattedMessage id="button.save&update" />}
            </Button>
          </div>
        </>
      }
      width={isMobile ? '100%' : '50%'}
      height={isMobile ? '90%' : '100%'}
      placement={isMobile ? 'top' : 'right'}
      maskClosable={false}
      closable
      onClose={() => {
        setState(prevState => ({
          ...prevState,
          selectedBank: {},
          upiVerified: false,
        }))
        onClose()
      }}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      open={visible}
      footer={
        <Button size="middle" type="primary" onClick={() => formRef.current?.submit()}>
          <FormattedMessage id="button.save&update" />
        </Button>
      }
      destroyOnClose
    >
      <Card bordered={false} size="default" className="border-radius-small mb-4">
        <Form
          {...formItemLayout}
          layout="vertical"
          labelAlign="left"
          ref={formRef}
          name="bankForm"
          className="addon-form"
          onFinish={onFinish}
          onFinishFailed={() => message.error('Check all details again')}
          initialValues={{ ...state.selectedBank, confirm: state.selectedBank.bank_no }}
        >
          {/* Form Items */}
          <Form.Item
            label={<FormattedMessage id="form.field.accountNumber" />}
            name="bank_no"
            rules={[
              { required: state.selectedBank.id !== 0, message: 'Please fill Bank Account No.' },
            ]}
          >
            <Input
              placeholder="Bank Account No."
              size="middle"
              type="password"
              onBlur={() => handleBlur('bank_no')}
              disabled={
                state.selectedBank.id === state.selected_bank_id || state.selectedBank.id === 0
              }
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            label={<FormattedMessage id="form.field.confirmAccountNumber" />}
            dependencies={['bank_no']}
            hasFeedback={state.touched.bank_no || state.touched.confirm}
            rules={[
              {
                required: state.selectedBank.id != 0,
                message: 'Please confirm your Bank Account No.',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('bank_no') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('The Bank Account No.s that you entered do not match!')
                },
              }),
            ]}
          >
            <Input
              placeholder="Confirm Bank Account No. 1"
              size="middle"
              onBlur={() => handleBlur('confirm')}
              disabled={
                state.selectedBank.id == state.selected_bank_id || state.selectedBank.id == 0
              }
            />
          </Form.Item>
          {/* IFSC Code */}
          <Form.Item
            label={user?.selectedCompany?.labels?.ifsc || 'IFSC Code'}
            name="ifsc"
            rules={[
              {
                required: state.selectedBank.id != 0,
                message: 'Please fill ' + user?.selectedCompany?.labels?.ifsc || 'IFSC Code',
              },
            ]}
          >
            <Input
              placeholder={user?.selectedCompany?.labels?.ifsc || 'IFSC Code'}
              size="middle"
              addonAfter={
                user?.selectedCompany?.country_code == 'IN' && (
                  <Button
                    style={{ backgroundColor: '#f0f0f0' }}
                    className="px-2"
                    onClick={() => onFetchBankDetails()}
                  >
                    Fetch Bank Details
                  </Button>
                )
              }
              disabled={
                state.selectedBank.id == state.selected_bank_id || state.selectedBank.id == 0
              }
            />
          </Form.Item>

          <Form.Item
            label={<FormattedMessage id="form.field.bankName" />}
            name="bank_name"
            rules={[{ required: state.selectedBank.id != 0, message: 'Please fill Bank Name' }]}
          >
            <Input
              placeholder="Bank Name"
              size="middle"
              disabled={
                state.selectedBank.id == state.selected_bank_id || state.selectedBank.id == 0
              }
            />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id="form.field.branchName" />}
            name="branch_name"
            rules={[
              {
                required: state.selectedBank.id != 0,
                message: 'Please fill Branch Name',
              },
            ]}
          >
            <Input
              placeholder="Bank Branch Name"
              size="middle"
              disabled={
                state.selectedBank.id == state.selected_bank_id || state.selectedBank.id == 0
              }
            />
          </Form.Item>

          {user?.selectedCompany?.country_code == 'IN' && (
            <Form.Item
              label={
                <span>
                  <FormattedMessage id="form.field.upi" />{' '}
                  <span className="badge badge-gray ml-1">OPTIONAL</span>
                  <span>
                    <Tooltip
                      title={
                        <span>
                          <span>
                            A UPI ID or VPA (Virtual Payment Address) is a unique ID that is used to
                            make UPI payments in place of bank account details.
                          </span>
                          <br />
                          <span>
                            This UPI ID will be used to generate <strong>Dynamic QR codes</strong>{' '}
                            on the invoices and bills.
                          </span>
                        </span>
                      }
                    >
                      <i className="fa fa-info-circle text-gray-3 ml-1 mr-3" />
                    </Tooltip>
                  </span>
                </span>
              }
              name="upi"
              extra={
                <>
                  {state.upiVerified && (
                    <>
                      <Tooltip title="Verified">
                        <i className="fa-solid fa-circle-check text-success mr-2" />
                      </Tooltip>
                      <span className="font-weight-bold text-success">{`${state.upiName} `}</span>
                    </>
                  )}
                  This UPI ID will be used to generate <strong>Dynamic QR codes</strong> on the
                  invoices and bills.
                </>
              }
            >
              <Input
                placeholder="UPI ID eg. username@okicici"
                size="middle"
                disabled={state.selectedBank.id == 0}
                onChange={e => {
                  setState(prevState => ({ ...prevState, upi_id: e.target.value }))
                  if (state.upiVerified) {
                    setState(prevState => ({ ...prevState, upiVerified: false }))
                  }
                }}
                addonAfter={
                  <>
                    {!state.upiVerified ? (
                      <Button
                        className="px-2"
                        onClick={() => verifyUPI()}
                        loading={state.upiLoading}
                        style={{ backgroundColor: '#f0f0f0' }}
                      >
                        Verify UPI ID
                      </Button>
                    ) : (
                      <></>
                    )}
                  </>
                }
              />
            </Form.Item>
          )}

          {user?.selectedCompany?.country_code == 'IN' && (
            <Form.Item
              label={
                <span>
                  <FormattedMessage id="form.field.upiNumber" />{' '}
                  <span className="badge badge-gray ml-1">OPTIONAL</span>
                </span>
              }
              name="gpay_number"
            >
              <Input
                placeholder="GPay/PhonePe Number (Optional)"
                size="middle"
                disabled={state.selectedBank.id == 0}
              />
            </Form.Item>
          )}
          <Form.Item
            label={
              <span>
                <FormattedMessage id="form.field.openingBalance" />{' '}
                <span className="badge badge-gray ml-1">OPTIONAL</span>
              </span>
            }
            name="opening_balance"
          >
            <Input placeholder="Opening Balance (Optional)" size="middle" />
          </Form.Item>
          <Form.Item name="notes" label={<FormattedMessage id="text.notes" />}>
            <BankNotesEditor
              placeholder="Beneficiary Name, SWIFT Code etc.."
              editorState={state.editorState}
              onChange={(text: any, editorState: any) => {
                setState(prevState => ({ ...prevState, editorState }))
              }}
            />
          </Form.Item>
          <Form.Item
            name="is_default"
            valuePropName="checked"
            label={'Default'}
            extra={<>This will override your previous default bank</>}
          >
            <Switch disabled={state.selectedBank.id == 0} />
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}

export default NewBankForm
