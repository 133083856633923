import { Image, Text, View } from '@react-pdf/renderer'
import { EnhancedQRCode } from 'components/other/qrCodeFile'
import React from 'react'
import { export_terms } from '../export_condition'
import RichText from '../RichText'
import { styles as getStyles } from './styles'

const InvoiceBankDetails = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style
  if (locale == 'English') {
    locale = font_style
  }
  let FONT_FACTOR = invoice.invoice_settings.font_size

  return (
    <>
      <View
        style={[
          styles.invoiceBankDetails_container,
          styles.invoiceBankDetails_borderTop,
          styles.globalContainer,
        ]}
        wrap={false}
      >
        {/* {type != 'Delivery Challan' && ( */}
        <View style={styles.invoiceBankDetails_blockC}>
          <View style={[styles.invoiceBankDetails_blockCA, styles.invoiceBankDetails_borderBottom]}>
            {invoice.bank_details.bank_name != 'Cash' &&
              invoice.bank_details.bank_name != 'COD' &&
              invoice.bank_details.bank_name != 'No Bank' &&
              invoice.bank_details.bank_name != '' &&
              type != 'Delivery Challan' &&
              invoice.document_type != 'purchases' &&
              invoice.document_type != 'purchase_orders' &&
              invoice.document_type != 'purchase_returns' && (
                <>
                  <View>
                    <Text style={styles.invoiceBankDetails_label}>Bank Details:</Text>
                    <View style={styles.invoiceBankDetails_bankRow}>
                      <Text style={styles.invoiceBankDetails_labels}>Bank:</Text>
                      <Text style={styles.invoiceBankDetails_info}>
                        {invoice.bank_details.bank_name}
                      </Text>
                    </View>
                    <View style={styles.invoiceBankDetails_bankRow}>
                      <Text style={styles.invoiceBankDetails_labels}>Account #: </Text>
                      <Text style={styles.invoiceBankDetails_info}>
                        {invoice.bank_details.bank_no}
                      </Text>
                    </View>
                    <View style={styles.invoiceBankDetails_bankRow}>
                      <Text style={styles.invoiceBankDetails_labels}>
                        {invoice.invoice_settings.labels.ifsc}:{' '}
                      </Text>
                      <Text style={styles.invoiceBankDetails_info}>
                        {invoice.bank_details.ifsc}
                      </Text>
                    </View>
                    <View style={styles.invoiceBankDetails_bankRow}>
                      <Text style={styles.invoiceBankDetails_labels}>Branch: </Text>
                      <Text style={styles.invoiceBankDetails_info}>
                        {invoice.bank_details.branch_name}
                      </Text>
                    </View>
                    <View style={styles.invoiceBankDetails_bankRow}>
                      <RichText
                        locale={locale}
                        note={invoice.bank_details.notes}
                        FONT_FACTOR={8.35 * FONT_FACTOR}
                      />
                    </View>
                  </View>
                </>
              )}
            {invoice.rcm == 1 && (
              <View style={styles.invoiceBankDetails_mb}>
                <Text style={styles.invoiceBankDetails_mb}>
                  *Amount of Tax subject to Reverse Charge
                </Text>
              </View>
            )}

            {invoice.immovable_tax_type == 1 && (
              <View style={styles.invoiceBankDetails_mb}>
                <Text style={styles.invoiceBankDetails_mb}>
                  *Place of Supply is determined u/s 12(3) of the IGST Act 2017.
                </Text>
              </View>
            )}

            {invoice.is_export == 1 && invoice.company.gstin.length == 15 && (
              <View style={styles.invoiceBankDetails_mb}>
                <Text style={styles.invoiceBankDetails_label}>
                  *{export_terms[invoice.export_details[0].export_type]}
                </Text>
              </View>
            )}
            {invoice.notes != 'None' && invoice.notes != '' && (
              <View style={styles.invoiceBankDetails_block}>
                <Text style={styles.invoiceBankDetails_label}>Notes:</Text>
                <Text style={styles.invoiceBankDetails_text}>{invoice.notes}</Text>
              </View>
            )}

            {invoice.invoice_settings.show_receivers_signature == 1 && (
              <View style={styles.invoiceNotes_receiveSignBlock}>
                <Text
                  style={{
                    ...styles.invoiceNotes_receiveSignText,
                  }}
                >
                  Receiver's Signature{' '}
                </Text>
              </View>
            )}
          </View>

          <View style={[styles.invoiceBankDetails_blockCB, styles.invoiceBankDetails_borderBottom]}>
            {type != 'Delivery Challan' && (
              <View style={styles.invoiceBankDetails_headerContainer2}>
                <View style={styles.invoiceBankDetails_block}>
                  {invoice.upi != '' && (
                    <>
                      <Text style={styles.invoiceBankDetails_label}>Pay using UPI: {'\n'}</Text>
                      <Text style={styles.invoiceBankDetails_label}>{invoice.upi_id}</Text>

                      <View style={styles.invoiceBankDetails_block2}>
                        <EnhancedQRCode
                          style={styles.invoiceBankDetails_upi}
                          value={`${invoice.upi}`}
                        />
                      </View>
                    </>
                  )}
                </View>
              </View>
            )}
          </View>

          <View
            style={[
              styles.invoiceBankDetails_blockCC,
              styles.invoiceBankDetails_borderBottom,
              styles.invoiceBankDetails_borderLeft,
            ]}
          >
            <View style={styles.invoiceBankDetails_block}>
              <View>
                <Text
                  style={
                    invoice.company.company_name.length > 40
                      ? {
                          ...styles.invoiceBankDetails_label2,
                          fontSize: (6.66 * 40) / invoice.company.company_name.length,
                        }
                      : styles.invoiceBankDetails_label2
                  }
                >
                  For {invoice.company.company_name}{' '}
                </Text>
                {invoice.signature != '' && invoice.signature != null && (
                  <Image
                    style={styles.invoiceBankDetails_signature}
                    src={{
                      uri: invoice.signature,
                      method: 'GET',
                      headers: { 'Cache-Control': 'no-cache' },
                    }}
                    crossorigin="anonymous"
                  />
                )}
                <Text
                  style={{
                    ...styles.invoiceBankDetails_label2,
                    marginTop: invoice.signature != '' ? 10 : 54,
                  }}
                >
                  Authorized Signatory{' '}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      {invoice.terms != 'None' && invoice.terms != '' && type != 'Delivery Challan' && (
        <View style={styles.invoiceBankDetails_blockCD}>
          <Text style={styles.invoiceBankDetails_label}>Terms and Conditions:</Text>
          <Text style={styles.invoiceBankDetails_text}>{invoice.terms}</Text>
        </View>
      )}
      {/* )} */}
    </>
  )
}

export default InvoiceBankDetails
