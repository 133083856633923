import { Button, Card } from 'antd'
import MyEditor from 'components/other/editor'
import { EditorState, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import React, { Component, useState } from 'react'

const RichTextEditor = (params, width, className, title, isGridEditable, setDescription) => {
  const editRef = React.createRef()
  const [record, setRecord] = useState(params.data)
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromHTML(record.description || '')),
  )

  const addNewLine = () => {
    const newState = RichUtils.insertSoftNewline(editRef.current.getEditorState())
    setEditorState(newState)
    editRef.current.updateEditorState(newState)
  }

  return (
    <Card
      title={title ?? ''}
      // bordered={true}
      style={width == 0 ? {} : { width: width ? width : 600 }}
      onKeyDownCapture={e => {
        if (e.key == 'Enter') {
          e.stopPropagation()
          e.preventDefault()
          addNewLine()
        }
      }}
      extra={
        (title ?? '') != '' && (
          <div>
            <Button
              size="small"
              type="transparent"
              onClick={() => {
                if (isGridEditable == false) {
                  params.onValueChange(stateToHTML(editorState.getCurrentContent()))
                  params.stopEditing()
                } else {
                  setDescription(editorState, record.key)
                }
              }}
            >
              Save
            </Button>
            <Button
              size="small"
              className="ml-2"
              type="danger"
              onClick={() => {
                if (isGridEditable == false) {
                  params.stopEditing()
                } else {
                  setDescription(editorState, record.key)
                }
              }}
            >
              Discard
            </Button>
          </div>
        )
      }
    >
      <MyEditor
        ref={editRef}
        editorState={editorState}
        onChange={(text, editorState) => {
          setEditorState(editorState)
          setRecord({ ...record, description: stateToHTML(editorState.getCurrentContent()) })
          // setDescription(editorState, record.key)
        }}
        editorClassName={className ?? ''}
        showFromState={true}
        showSaveButton={(title ?? '') == '' ? true : false}
        onSave={editorState => {
          if (isGridEditable == false) {
            params.onValueChange(stateToHTML(editorState.getCurrentContent()))
            params.stopEditing()
          } else {
            setDescription(editorState, record.key)
          }
        }}
        onDiscard={editorState => {
          if (isGridEditable == false) {
            params.stopEditing()
          } else {
            setDescription(editorState, record.key)
          }
        }}
      />
    </Card>
  )
}

export default RichTextEditor
