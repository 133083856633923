const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  COMPANY: 'user/COMPANY',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  MOBILE_LOGIN: 'user/MOBILE_LOGIN',
  VERIFY_OTP: 'user/VERIFY_OTP',
  PAGE_LOADING: 'user/PAGE_LOADING',
  SET_USER_DETAILS: 'user/SET_USER_DETAILS',
  GET_COMPANIES: 'user/GET_COMPANIES',
}

export default actions
