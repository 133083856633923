import { UploadOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Tabs, Upload, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { getMultipartAPI } from 'services/jwt'
import store from 'store'

const Support = forwardRef((props, ref) => {
  const [showSupportModal, setShowSupportModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [closable, setClosable] = useState(true)
  const [displayMessage, setDisplayMessage] = useState('')
  const [type, setType] = useState('')
  const [placeholder, setPlaceholder] = useState('')
  const [fileList, setFileList] = useState([])
  const [feedback, setFeedback] = useState('')
  const [subject, setSubject] = useState('')
  const [email, setEmail] = useState('')
  const { showNewMessage } = useIntercom()

  useImperativeHandle(ref, () => ({
    showModal,
  }))

  const showModal = () => {
    setShowSupportModal(true)
    setType('Feedback')
    setEmail(store.get('email'))
    setPlaceholder(
      'Please provide your feedback here, describing it in detail. If possible, include screenshots or links to help us evaluate it more efficiently.',
    )
    setDisplayMessage(
      'Like Swipe or have any feedback for us? Use the form below to send us your comments or report any problems you experienced using the app. We read all feedback carefully, and do our best to improve the app every day!',
    )
  }
  const closeModal = () => {
    setShowSupportModal(false)
    setFileList([])
    setFeedback('')
    setSubject('')
  }
  const onTabChange = key => {
    switch (key) {
      case '1':
        setType('Issue')
        setPlaceholder(
          'Please provide your feedback here, describing it in detail. If possible, include screenshots or links to help us evaluate it more efficiently.',
        )
        setDisplayMessage(
          'Like Swipe or have any feedback for us? Use the form below to send us your comments or report any problems you experienced using the app. We read all feedback carefully, and do our best to improve the app every day!',
        )
        break
      case '2':
        setType('Feedback')
        setPlaceholder(
          'Please provide your feedback here, describing it in detail. If possible, include screenshots or links to help us evaluate it more efficiently.',
        )
        setDisplayMessage(
          'Like Swipe or have any feedback for us? Use the form below to send us your comments or report any problems you experienced using the app. We read all feedback carefully, and do our best to improve the app every day!',
        )
        break
      case '3':
        setType('Suggestion/Question')
        setPlaceholder(
          'Please provide your suggestions here, describing it in detail. If possible, include screenshots or links to help us evaluate it more efficiently.',
        )
        setDisplayMessage(
          'We are always looking for ways to improve Swipe. Please write your suggestion/question in the box below.',
        )
        break
      default:
        setType('')
        break
    }
  }
  const onFinish = async () => {
    if (email == '') {
      message.error('Please provide your email address!')
      return
    }
    if (subject == '') {
      message.error('Please Enter Title')
      return
    }
    if (feedback == '') {
      message.error('Please Write Description')
      return
    }
    const formData = new FormData()

    for (let k in fileList) {
      formData.append(k, fileList[k])
      // console.log(k)
    }

    formData.append('feedback_type', type)
    formData.append('feedback', feedback)
    formData.append('subject', subject)
    formData.append('email', email)
    setLoading(true)
    const data = await getMultipartAPI('utils', 'send_feedback', formData)
    setLoading(false)
    if (data?.success) {
      message.success(type + ' Submitted Successfully')
      closeModal()
    }
  }

  const handleHelpAndSupport = () => {
    if (store.get('paid') == 1) {
      showNewMessage('Hello Swipe. I need help with')
    } else {
      window.open(
        `https://api.whatsapp.com/send?phone=918121335436&text=Hello%20Swipe! I need help with `,
        '_blank',
      )
    }
  }

  return (
    <div>
      <Modal
        className="support-modal"
        keyboard={false}
        maskClosable={true}
        centered
        title={<p>Contact Swipe Support</p>}
        width={'45%'}
        open={showSupportModal}
        closable={closable}
        destroyOnClose
        onCancel={e => {
          closeModal()
        }}
        closeIcon={<i className="fa-solid fa-lg fa-close" />}
        footer={null}
        loading={loading}
      >
        <div className="">
          <Tabs
            defaultActiveKey="1"
            tabPosition="top"
            style={{ height: '100%' }}
            onChange={onTabChange}
          >
            {/* <Tabs.TabPane tab="Issue" key="1"></Tabs.TabPane> */}
            <Tabs.TabPane tab="Feedback" key="2"></Tabs.TabPane>
            {/* <Tabs.TabPane tab="Suggestion/Question" key="3"></Tabs.TabPane> */}
            <Tabs.TabPane tab="Contact Us" key="4"></Tabs.TabPane>
          </Tabs>
          <div>
            {type != '' && (
              <div>
                <span className="font-size-16  text-gray-500">{displayMessage}</span>
                <div>
                  <Input
                    placeholder="Email"
                    onChange={e => setEmail(e.target.value.toLowerCase())}
                    defaultValue={email}
                    className={'bg-white mb-4 mt-3'}
                    size="medium"
                  />
                  <Input
                    placeholder="Title"
                    onChange={e => setSubject(e.target.value)}
                    className="bg-white mb-4 mt-3"
                    size="medium"
                  />

                  <TextArea
                    placeholder={placeholder}
                    autoSize={{ minRows: 10 }}
                    className="bg-white p-2"
                    onChange={e => setFeedback(e.target.value)}
                  />

                  <div className="flex justify-between items-center mt-3">
                    {/* attachment file here */}
                    <Upload
                      fileList={fileList}
                      onRemove={file => {
                        const index = fileList.indexOf(file)
                        const newFileList = fileList.slice()
                        newFileList.splice(index, 1)
                        setFileList(newFileList)
                      }}
                      beforeUpload={file => {
                        if (fileList.length > 0) {
                          message.error('You can only upload one file')
                          return false
                        }
                        if (file.size > 10 * 1024 * 1024) {
                          message.error('File size should be less than 10MB')
                          return false
                        }
                        setFileList([...fileList, file])

                        return false
                      }}
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                    <div className="flex items-center">
                      <Button type="primary" size="large" onClick={onFinish}>
                        Submit {type}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {type == '' && (
              <div>
                <div className="flex justify-center items-center">
                  <div className="">
                    <div>
                      <span className="font-size-16  text-gray-500">
                        We are here to help you. Our Customer Support team will get back to you as
                        soon as possible.
                      </span>
                    </div>
                    <div className="mt-3 flex justify-center items-center">
                      <Button
                        onClick={handleHelpAndSupport}
                        type={store.get('paid') == 1 ? 'default' : 'success'}
                      >
                        {store.get('paid') == 1 ? (
                          <div className="flex justify-center items-center gap-2">
                            <img
                              src={'./resources/images/intercom.svg'}
                              alt="intercom-icon"
                              width={16}
                              height={16}
                            />
                            <span className="font-bold">Intercom</span>
                          </div>
                        ) : (
                          <>
                            <i className="fa-brands font-weight-bold fa-whatsapp mr-2" />
                            <span>Whatsapp</span>
                          </>
                        )}
                      </Button>
                      <div className="ml-3">
                        or Send us an email at{' '}
                        <a href="mailto:support@getswipe.in" className="text-primary">
                          support@getswipe.in
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
})

export default Support
