import { Alert, Button, Card, Layout, Modal } from 'antd'
import classNames from 'classnames'
import BusinessAnalytics from 'components/BusinessAnalytics'
import Tutorial from 'components/Tutorial'
import InfoComponent from 'components/badges/InfoComponent'
import Banners from 'components/cleanui/layout/Banners'
import Footer from 'components/cleanui/layout/Footer'
import Menu from 'components/cleanui/layout/Menu'
import SupportChat from 'components/cleanui/layout/SupportChat'
import TopBar from 'components/cleanui/layout/TopBar'
import PageLoading from 'components/loaders/PageLoading'
import { GetVersion, fetchUserUpdates } from 'pages/firebasejs'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import store from 'store'
import { CONSTANTS } from 'utils/constants'
import { motion } from 'framer-motion'

const isWindowContext = typeof window !== 'undefined'

const mapStateToProps = ({ settings, user, document }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: true,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: true,
  isGrayTopbar: settings.isGrayTopbar,
  isPageLoading: user.pageLoading,
  user: user,
  all_doc_counts: document.all_doc_counts,
})

export function MainLayout({
  children,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
  isPageLoading,
  user,
  all_doc_counts,
}) {
  const [userUpdates, setUserUpdates] = useState([])
  const [visible, setVisible] = useState(false)
  const [versionVisible, setVersionVisible] = useState(false)

  useEffect(() => {
    getUserUpdates()
    getCurrentVersion()
    const urlParams = new URLSearchParams(window.location.search)
    const tab = urlParams.get('tab')
    store.set('user_tab', tab)
  }, [])

  async function getUserUpdates() {
    // var data1 = await getAPIData('utils', 'user_updates')
    // setUserUpdates(data1.user_updates)
    try {
      var data1 = await fetchUserUpdates()
      if (data1 && data1.length > 0) {
        let updates = data1.filter(item => item.text != '' && item.show == true)
        setUserUpdates(updates)
        setVisible(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function getCurrentVersion() {
    const data = await GetVersion()
    if (data?.success) {
      if (store.get('version') != data.version) {
        store.set('version', data.version)
        setVersionVisible(true)
      }
    }
  }

  if (isWindowContext) {
    if (!document.cookie.includes('show_notifications')) {
      var expires = new Date(Date.now() + 86400 * 1000).toUTCString()
      document.cookie = 'show_notifications=true; expires=' + expires + ';path=/;'
    }

    if (document.cookie.includes('show_notifications') && visible) {
      try {
        //get the cookie value
        var show_notifications = document.cookie
          .split('; ')
          .find(row => row.startsWith('show_notifications'))
          .split('=')[1]
        if (show_notifications == 'false') {
          setVisible(false)
        }
      } catch (e) {
        console.log(e)
      }
    }

    //call only if notification_called cookie is not set
    if (!document.cookie.includes('notification_called')) {
      //expire cookie in 3hrs
      expires = new Date(Date.now() + 3 * 60 * 60 * 1000).toUTCString()
      document.cookie = 'notification_called=true;  expires=' + expires + ';path=/;'
      getUserUpdates()
    }

    if (!document.cookie.includes('version_called')) {
      var expires = new Date(Date.now() + 900 * 1000).toUTCString()
      document.cookie = 'version_called=true; expires=' + expires + ';path=/;'
      getCurrentVersion()
    }
  }

  const messageText = "You don't have permission to edit data. Contact Kumar for assistance."

  return !isPageLoading ? (
    <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
      <Layout.Header
        className={classNames('cui__layout__header', {
          cui__layout__fixedHeader: isTopbarFixed,
          cui__layout__headerGray: isGrayTopbar,
        })}
      >
        <TopBar />
      </Layout.Header>
      <Layout
        className={classNames({
          cui__layout__contentMaxWidth: isContentMaxWidth,
          cui__layout__appMaxWidth: isAppMaxWidth,
          cui__layout__grayBackground: isGrayBackground,
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
        })}
      >
        <Tutorial />
        <Menu />

        <Layout className="">
          {userUpdates.length > 0 && visible && (
            <div className="px-4 pr-1 m-0 w-full">
              <InfoComponent
                description={
                  <a href={userUpdates[0].link} target="_blank">
                    {userUpdates[0].text}
                  </a>
                }
                type="info"
                learnMore="link"
                learnMoreText=""
                closable={true}
                onClose={() => {
                  var expires = new Date(Date.now() + 3600 * 1000).toUTCString()
                  document.cookie = 'show_notifications=false; expires=' + expires + ';path=/;'
                  setVisible(false)
                }}
              />
            </div>
          )}

          <Modal
            title={
              <span>
                <i className="fa-solid fa-exclamation-circle mr-1"></i>
                New Update Available
              </span>
            }
            open={versionVisible}
            footer={null}
            closable={false}
            maskClosable={false}
          >
            <div className="flex justify-between items-center">
              <span className="font-size-16">New Update available. Please Refresh the page</span>
              <span>
                <Button
                  type="action"
                  size="large"
                  onClick={() => {
                    window.location.reload(true)
                  }}
                >
                  Refresh
                </Button>
              </span>
            </div>
          </Modal>

          <motion.div
            initial={{ opacity: 0 }} // Start with the element fully transparent
            animate={{ opacity: 1 }} // Fade in to full opacity
            transition={{ duration: 0.7 }} // Adjust the duration of the fade-in
            className=""
          >
            <Layout.Content>
              {/*   {isWindowContext && window.location.href.includes('create') || isWindowContext && window.location.href.includes('edit') || isWindowContext && window.location.href.includes('convert') ? (
              <div className="cui__utils__content">{children}</div>
            ) : (*/}
              {store.get('is_support_login') && store.get('is_support_login') == true && (
                <Alert className="mb-3" message={<b>{messageText}</b>} type="warning" />
              )}
              {!isMobile &&
                store.get('days_left') <= 15 &&
                !window.location.pathname.includes('onlineorders') && (
                  <Card size="xsmall" className="bg-notify-danger border-radius-small mb-3">
                    <div className="text-center">
                      <div className="text-danger">
                        <i
                          className="fa fa-exclamation-triangle fa-beat mr-2 text-left"
                          aria-hidden="true"
                        ></i>
                        <span className="text-black font-weight-medium mr-2">
                          {store.get('days_left') > 0
                            ? `Your subscription is expiring in ${store.get('days_left')} ${
                                store.get('days_left') == 1 ? 'day' : 'days'
                              }!`
                            : `Your subscription has expired!`}
                        </span>
                        <Button
                          onClick={event => window.open(window.location.origin + '/pricing')}
                          type="default"
                          size="default"
                          shape="round"
                          className="font-weight-bold px-3"
                        >
                          {CONSTANTS.PRICING_BUTTON}
                        </Button>
                      </div>
                    </div>
                  </Card>
                )}
              {store.get('paid') == 0 &&
                store.get('days_left') > 0 &&
                !window.location.pathname.includes('onlineorders') &&
                !window.location.hostname.includes('mac.getswipe.in') && (
                  <Card size="xsmall" className="bg-card-azure border-radius-small mb-3">
                    <div className="text-center font-size-18">
                      <span className="text-black font-weight-bold mr-2">
                        Enjoy customizable templates,{' '}
                        {user?.selectedCompany?.country_code == 'IN' ? 'GST' : ''} reports, and
                        premium support! 🌟🌟🌟
                      </span>
                      <Button
                        type="default"
                        size="default"
                        shape="round"
                        onClick={event => window.open(window.location.origin + '/pricing')}
                        className="font-weight-bold px-3"
                      >
                        {CONSTANTS.PRICING_BUTTON}
                      </Button>
                    </div>
                  </Card>
                )}
              {(window.location.pathname.includes('list') ||
                window.location.pathname.includes('expenses')) &&
                store.get('role') == 'admin' &&
                all_doc_counts?.invoice > 0 && <BusinessAnalytics />}

              <Card size="layout" className="border-radius-small height-90vh">
                <div className="cui__utils__content">{children}</div>
              </Card>

              {/*)}*/}
              {store.get('paid') == 0 &&
                isWindowContext &&
                user?.selectedCompany?.country_code == 'IN' && (
                  <div className="mt-2">{window.location.href.includes('list') && <Banners />}</div>
                )}
            </Layout.Content>
          </motion.div>

          {/*<Card>*/}

          {store.get('is_paid_company') == 0 && <SupportChat />}
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    </div>
  ) : (
    <PageLoading />
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
