import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'topBar.issuesHistory': 'История заданий',
  'topBar.projectManagement': 'Управление проектом',
  'topBar.typeToSearch': 'Поиск...',
  'topBar.findPages': 'Поиск страниц...',
  'topBar.actions': 'Действия',
  'topBar.status': 'Статус',
  'topBar.profileMenu.hello': 'నమస్తే',
  'topBar.profileMenu.billingPlan': 'బిల్లింగ్ ప్రణాళిక',
  'topBar.profileMenu.role': 'పాత్ర',
  'topBar.profileMenu.email': 'ఇ-మెయిల్',
  'topBar.profileMenu.phone': 'ఫోన్',
  'topBar.profileMenu.editProfile': 'ప్రొఫైల్‌ను సవరించండి',
  'topBar.profileMenu.logout': 'లాగ్ అవుట్',
  'sales.tab.invoices': 'ఇన్వాయిస్లు',
  'sales.tab.pos': 'పోస్ బిల్లులు',
  'sales.heading': 'సేల్స్',
  'sales.tab.allTransactions': 'అన్ని లావాదేవీలు',
  'sales.tab.pending': 'పెండింగ్‌లో ఉన్న బిల్లులు',
  'sales.tab.paid': 'చెల్లించిన బిల్లులు',
  'sales.tab.refund': 'రిఫండ్ బిల్లులు',
  'sales.tab.returns': 'రిటర్న్ బిల్లులు',
  'sales.tab.cancelled': 'రద్దు చేసిన బిల్లులు',

  'expenses.heading': 'ఖర్చులు',
  'expenses.addExpense': 'Add an Expense Now',

  'button.download': 'డౌన్‌లోడ్',
  'button.import': 'దిగుమతి చేయండి',
  'button.createInvoice': 'ఇన్వాయిస్ సృష్టించండి',
  'button.createExpense': 'Create Expense',
  'button.sales': 'Create Invoice',
  'button.sales_returns': 'Create Sales Return/Credit Note',
  'button.purchases': 'Create Purchase',
  'button.purchase_orders': 'Create Purchase Order',
  'button.purchase_returns': 'Create Purchase Return/Debit Note',
  'button.estimates': 'Create Quotation',
  'button.pro_forma_invoices': 'Create Pro Forma Invoice',
  'button.delivery_challans': 'Create Delivery Challan',
  'button.expense_report': 'Expense Report',
  'button.settings': 'Settings',
  'button.goToPos': 'పోస్ కు వెళ్ళండి',
  'menuLeft.sales': 'సేల్స్',
  'menuLeft.invoices': 'Invoices',
  'menuLeft.sales_returns': 'Credit Notes',
  'menuLeft.purchases': 'పర్చేసేస్',
  'menuLeft.purchase_orders': 'Purchase Orders',
  'menuLeft.timeline': 'Payments Timeline',
  'menuLeft.purchase_returns': 'Purchase Returns',
  'menuLeft.estimates': 'ఎస్టిమేట్స్',
  'menuLeft.pro_forma_invoices': 'Pro Forma Invoices',
  'menuLeft.delivery_challans': 'Delivery Challans',
  'menuLeft.payments': 'పేమెంట్స్',
  'menuLeft.inventory': 'ఇన్వెంటరీ',
  'menuLeft.products': 'ప్రొడక్ట్స్',
  'menuLeft.vendors': 'విక్రేతలు',
  'menuLeft.customers': 'కస్టమర్లు',
  'menuLeft.expenses': 'ఖర్చులు',
  'menuLeft.analytics': 'విశ్లేషణలు',
  'menuLeft.sales_summary': 'అమ్మకాల సారాంశం',
  'menuLeft.reports': 'Reports',
  'menuLeft.onlineorders': 'ఆన్‌లైన్ ఆర్డర్‌లు',
  'menuLeft.settings': 'Settings',
  'menuLeft.tutorials': 'Tutorials',
  'menuLeft.ewaybills': 'ఇ వే బిల్లులు',

  'table.amount': 'అమౌంట్',
  'table.status': 'స్థితి',
  'table.date': 'తేదీ',
  'table.send': 'ఇతరులకు పంపండి',
  'table.action': 'యాక్షన్',
  'table.customer': 'కస్టమర్',
  'table.description': 'description',
  'table.expenseId': 'Expense #',
  'table.mode': 'Payment Mode',

  totalAmount: 'మొత్తం రాబడి',
  'button.view': 'బిల్లు చూడండి',
  'button.recordPayment': 'చెల్లింపును రికార్డ్ చేయండి',
  'button.deliveryChallan': 'డెలివరీ చలాన్',
  'button.thermalPrint': 'థర్మల్ ప్రింట్',
  'button.edit': 'సవరించండి',
  'button.cancel': 'రద్దు చేయండి',
  'button.settings': 'Settings',
  // Login
  welcome: 'Swipe కు స్వాగతం',
  '10digit': '10 అంకెల మొబైల్ నంబర్',
  willSendOTP: 'మేము ఈ నంబర్‌కు OTP పంపుతాము',
  continue: 'మొబైల్ నంబర్‌తో కొనసాగించండి',
  termsMessage: 'కొనసాగించడం ద్వారా మీరు అంగీకరిస్తున్నారు మా',
  mobileError: 'దయచేసి మీ 10 అంకెల మొబైల్ నంబర్‌ను నమోదు చేయండి',
  otpSent: 'ఇచ్చిన మొబైల్‌కు OTP పంపబడింది.',
  confirmOTP: 'OTP ని నిర్ధారించండి',
  terms: 'నిబంధనలు',
  policy: 'విధానాలు',
  help: 'సహాయం / మద్దతు కోసం',
}

export default {
  locale: 'Telugu',
  localeAntd,
  messages,
}
