import { Image, Text, View } from '@react-pdf/renderer'
import { EnhancedQRCode } from 'components/other/qrCodeFile'
import React from 'react'
import RichText from '../RichText'
import { styles as getStyles } from './styles'

const InvoiceBankDetails = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style
  if (locale == 'English') {
    locale = font_style
  }
  let FONT_FACTOR = invoice.invoice_settings.font_size

  return (
    <View>
      <View style={styles.invoiceBankDetails_container} wrap={false}>
        <View style={styles.invoiceBankDetails_bigblock}>
          {invoice.upi != '' && type != 'Delivery Challan' && invoice.document_type != 'purchases' && (
            <View style={styles.invoiceBankDetails_block}>
              <Text style={styles.invoiceBankDetails_heading}>Pay using UPI: {'\n'}</Text>
              <EnhancedQRCode style={styles.invoiceBankDetails_upi} value={`${invoice.upi}`} />
            </View>
          )}
          {invoice.bank_details.bank_name != 'Cash' &&
            invoice.bank_details.bank_name != 'COD' &&
            invoice.bank_details.bank_name != 'No Bank' &&
            invoice.bank_details.bank_name != '' &&
            type != 'Delivery Challan' &&
            invoice.document_type != 'purchases' &&
            invoice.document_type != 'purchase_orders' &&
            invoice.document_type != 'purchase_returns' && (
              <>
                <View>
                  <Text style={styles.invoiceBankDetails_heading}>Bank Details:</Text>
                  <View style={styles.invoiceBankDetails_row}>
                    <Text style={styles.invoiceBankDetails_labels}>Bank:</Text>
                    <Text style={styles.invoiceBankDetails_info}>
                      {invoice.bank_details.bank_name}
                    </Text>
                  </View>
                  <View style={styles.invoiceBankDetails_row}>
                    <Text style={styles.invoiceBankDetails_labels}>Account #: </Text>
                    <Text style={styles.invoiceBankDetails_info}>
                      {invoice.bank_details.bank_no}
                    </Text>
                  </View>
                  <View style={styles.invoiceBankDetails_row}>
                    <Text style={styles.invoiceBankDetails_labels}>
                      {invoice.invoice_settings.labels.ifsc}:{' '}
                    </Text>
                    <Text style={styles.invoiceBankDetails_info}>{invoice.bank_details.ifsc}</Text>
                  </View>
                  <View style={styles.invoiceBankDetails_row}>
                    <Text style={styles.invoiceBankDetails_labels}>Branch: </Text>
                    <Text style={styles.invoiceBankDetails_info}>
                      {invoice.bank_details.branch_name}
                    </Text>
                  </View>
                  <View style={styles.invoiceBankDetails_row}>
                    <RichText
                      locale={locale}
                      note={invoice.bank_details.notes}
                      FONT_FACTOR={8.35 * FONT_FACTOR}
                    />
                  </View>
                </View>
              </>
            )}
        </View>

        <View style={styles.invoiceBankDetails_block4}>
          <Text
            style={[
              invoice.company.company_name.length > 40
                ? { fontSize: (6.66 * 40) / invoice.company.company_name.length }
                : styles.invoiceBankDetails_label2,
            ]}
          >
            For {invoice.company.company_name}{' '}
          </Text>

          {invoice.signature != '' && invoice.signature != null && (
            <Image
              style={styles.invoiceBankDetails_signature}
              src={{
                uri: invoice.signature,
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
              }}
              crossorigin="anonymous"
            />
          )}

          <Text
            style={{
              ...styles.invoiceBankDetails_label2,
              marginTop: invoice.signature != '' ? 10 : 40,
            }}
          >
            Authorized Signatory{' '}
          </Text>
        </View>
      </View>
    </View>
  )
}

export default InvoiceBankDetails
