import QRCode from 'qrcode.react'
import React, { Component } from 'react'

export class QRCodeFile extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  render() {
    return (
      <QRCode
        value={this.props.value}
        size={120}
        renderAs={'svg'}
        bgColor={'#FFFFFF'}
        fgColor={'#000000'}
        imageSettings={{
          src: '/favicon.png',
          x: null,
          y: null,
          height: 18,
          width: 18,
          excavate: true,
        }}
      />
    )
  }
}

export class EnhancedQRCode extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <QRCodeFile {...this.props} />
  }
}
