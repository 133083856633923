import { Text, View } from '@react-pdf/renderer'
import { Fragment } from 'react'
import { HIDE_PRICES } from '../utils'
import { styles as getStyles } from './styles'

const borderColor = '#276EF1'

const InvoiceTableBlankSpace = ({ rowsCount, invoice, type }) => {
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size
  const hide_hsn = invoice.invoice_settings.hide_hsn

  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 8 : 9
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.8 : no_of_default_cols
  let no_of_with_out_tax_cols = invoice.invoice_settings.hide_qty ? 5 : 6
  no_of_with_out_tax_cols = hide_hsn ? no_of_with_out_tax_cols - 0.8 : no_of_with_out_tax_cols
  var single_col = ((1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)).toFixed(2)
  var single_col2 = (1 * 100) / (no_of_with_out_tax_cols + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (9 * 9)) / (10 + invoice.custom_col_names.length)
  var font_size2 = (FONT_FACTOR * (9 * 15)) / (16 + invoice.custom_col_names.length)
  const blankRows = rowsCount > 0 ? Array(rowsCount).fill(0) : []
  const rows = blankRows.map((x, i) => (
    <Fragment key={i}>
      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <>
          {(invoice.with_tax == 0 || invoice.company.gstin.length < 15) && (
            <View style={[styles.invoiceTableBlankSpace_row]}>
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${0.2 * single_col2}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${2 * single_col2}%`,
                }}
              >
                {'  '}
              </Text>

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${0.8 * single_col2}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${single_col2}%`,
                  }}
                >
                  {'  '}
                </Text>
              ))}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${single_col2}%`,
                }}
              >
                {'  '}
              </Text>

              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${single_col2}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  borderRight: 'none',
                  width: `${single_col2}%`,
                }}
              >
                {'  '}
              </Text>
            </View>
          )}

          {invoice.with_tax == 1 && invoice.company.gstin.length == 15 && (
            <View style={[styles.invoiceTableBlankSpace_row]}>
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'left',
                  width: `${0.4 * single_col}%`,
                }}
              >
                {' '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'left',
                  width: `${2.6 * single_col}%`,
                }}
              >
                {' '}
              </Text>

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{ ...styles.invoiceTableBlankSpace_cell, width: `${single_col}%` }}
                >
                  {' '}
                </Text>
              ))}

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${0.8 * single_col}%`,
                  }}
                >
                  {' '}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${1.2 * single_col}%`,
                }}
              >
                {' '}
              </Text>
              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${0.9 * single_col}%`,
                  }}
                >
                  {' '}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${single_col}%`,
                }}
              >
                {' '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${1.1 * single_col}%`,
                }}
              >
                {' '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  paddingRight: 4.5,
                  borderRight: 'none',
                  width: `${single_col}%`,
                }}
              >
                {' '}
              </Text>
            </View>
          )}
        </>
      )}
    </Fragment>
  ))
  return (
    <Fragment>
      <View style={styles.globalContainer}>{rows}</View>
      <View fixed style={styles.invoiceTableBlankSpace_block2}></View>
    </Fragment>
  )
}

export default InvoiceTableBlankSpace
