import { Tooltip } from 'antd'
import React from 'react'

export default function PlayIcon() {
  return (
    <Tooltip placement="right" title={<span className="font-weight-bolder">Watch quick demo</span>}>
      <span style={{ cursor: 'pointer' }}>
        <i className="fa-sharp fa-solid text-plum fa-sm align-center fa-circle-play ml-2 pt-1"></i>
      </span>
    </Tooltip>
  )
}
