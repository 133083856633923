import { Button, Card, Col, Drawer, Form, Input, Row, Space, message } from 'antd'
import SectionHeader from 'components/other/sectionHeader'
import { formItemLayout } from 'components/other/utility'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { getAPI } from 'services/jwt'

const InvoiceLabels = forwardRef((props, ref) => {
  const formRef = React.createRef()

  const [visible, setVisible] = useState(false)

  useImperativeHandle(ref, () => ({
    show_drawer,
  }))
  const show_drawer = () => {
    setVisible(true)
  }

  const closeDrawer = () => {
    setVisible(false)
  }

  const onFinish = async values => {
    const req = {
      labels: values.labels,
    }
    const data = await getAPI('utils', 'update_pdf_labels', req)
    if (data?.success) {
      message.success(data.message)
      closeDrawer()
    }
  }

  return (
    <Drawer
      open={visible}
      onClose={() => closeDrawer()}
      width={'50%'}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      title={'Invoice Labels'}
      destroyOnClose={true}
      extra={
        <Space>
          <Button
            onClick={() => formRef.current.submit()}
            type="primary"
            className="ml-2 font-weight-bold"
          >
            Save
          </Button>
        </Space>
      }
      footer={
        <>
          <Button
            onClick={() => formRef.current.submit()}
            type="primary"
            className="ml-2 font-weight-bold"
          >
            Save
          </Button>
          <Button onClick={() => closeDrawer()} type="danger" className="ml-2">
            Close
          </Button>
        </>
      }
    >
      <Form
        {...formItemLayout}
        labelAlign="left"
        ref={formRef}
        name="settingForm"
        onFinish={onFinish}
        onFinishFailed={() => message.error('Check All Details Again')}
        initialValues={{
          labels: props?.labels,
        }}
      >
        <Card bordered={true} className="border-radius-small mb-4">
          <Row gutter={18}>
            {props.labels &&
              Object.entries(props.labels)
                .filter(([key, label]) => !key.toLowerCase().includes('title'))
                .map(([key, label]) => (
                  <Col span={12}>
                    <Form.Item
                      label={key
                        .split('_')
                        .map(part => part.charAt(0).toUpperCase() + part.slice(1))
                        .join(' ')}
                      name={['labels', key]}
                      rules={[
                        {
                          required: true,
                          message: `${key
                            .split('_')
                            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
                            .join(' ')} cannot be empty`,
                        },
                      ]}
                    >
                      <Input className="w-full" disabled={props.fieldsDisabled} />
                    </Form.Item>
                  </Col>
                ))}
          </Row>
        </Card>
        <SectionHeader title="Titles in PDFs" />
        <Card bordered={true} className="border-radius-small mb-4">
          <Row gutter={18}>
            {props.labels &&
              Object.entries(props.labels)
                .filter(([key, label]) => key.toLowerCase().includes('title'))
                .map(([key, label]) => (
                  <Col span={12}>
                    <Form.Item
                      label={key
                        .split('_')
                        .map(part => part.charAt(0).toUpperCase() + part.slice(1))
                        .join(' ')}
                      name={['labels', key]}
                      rules={[
                        {
                          required: true,
                          message: `${key
                            .split('_')
                            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
                            .join(' ')} cannot be empty`,
                        },
                      ]}
                    >
                      <Input className="w-full" disabled={props.fieldsDisabled} />
                    </Form.Item>
                  </Col>
                ))}
          </Row>
        </Card>
      </Form>
    </Drawer>
  )
})

export default InvoiceLabels
