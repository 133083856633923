import { Tooltip } from 'antd'
import React from 'react'

export default function InfoIcon(props) {
  return (
    <span>
      <Tooltip
        title={
          <span>
            <span>{props.text}</span>
            <br />
          </span>
        }
        placement="right"
      >
        <i className="fa-solid fa-circle-question text-gray-400 ml-1" />
      </Tooltip>
    </span>
  )
}
