import { ConfigProvider, theme, notification } from 'antd'
import React, { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import english from './locales/english'
import hindi from './locales/hindi'
import telugu from './locales/telugu'
// import tamil from './locales/zh-CN'

const locales = {
  English: english,
  Hindi: hindi,
  Telugu: telugu,
  // 'Tamil': tamil,
}

const mapStateToProps = ({ settings }) => ({ settings })

const Localization = ({ children, settings }) => {
  const currentLocale = locales[settings.locale]

  useEffect(() => {
    // Configure global settings for notifications
    notification.config({
      closeIcon: <i className="fa-solid fa-lg fa-xmark"></i>, // Custom close icon
    })
  }, []) // Empty dependency array to run once on mount

  return (
    <ConfigProvider
      locale={currentLocale.localeAntd}
      theme={{
        algorithm: settings.theme == 'default' ? theme.defaultAlgorithm : theme.darkAlgorithm,
        token:
          settings.theme == 'default'
            ? {
                colorPrimary: '#2754ff',
                colorInfo: '#2754ff',
                colorError: '#e11900',
                colorTextBase: '#000000',
                colorBorder: '#e8e8ed', //e8e8ed
                colorBorderSecondary: '#F4F4F9',
                colorBgSpotlight: '#1d1d1f',
                boxShadowSecondary: '0 0 9px rgba(0,0,0,0.1)',
                colorText: '#1d1d1f',
                colorBgMask: 'rgba(0,0,0,0.1)',
                colorTextSecondary: '#6e6e73',
                colorTextTertiary: '#86868b',
                colorTextQuaternary: '#AEAEB2',
                colorBgLayout: '#f5f5f7',
                fontSize: 14,
                fontFamily: '$base-font-family',
              }
            : {
                colorPrimary: '#5276ff',
                colorInfo: '#5276ff',
                colorError: '#e11900',
                boxShadowSecondary: '0 0 9px rgba(0,0,0,0.1)',
                colorBgContainer: '#212121',
                colorBgSpotlight: '#2D2D2E',
                colorTextLightSolid: '#f5f5f7',
                colorBgElevated: '#2D2D2E',
                fontSize: 14,
                fontFamily: '$base-font-family',
                components: {
                  Card: {},
                },
              },
      }}
    >
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

export default connect(mapStateToProps)(Localization)
