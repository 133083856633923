import { Button, Card, Carousel, Image } from 'antd'
import { history } from 'index'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { getAPIData } from 'services/jwt'

class Banners extends Component {
  constructor(props) {
    super(props)
    this.state = {
      banners: [],
    }
  }
  async componentDidMount() {
    try {
      var data = await getAPIData('utils', 'landing_page')
      this.setState({ banners: data.landing_page[1]['data'] })
    } catch (e) {
      console.log(e)
    }
  }

  redirectTo = url => {
    // if url incudes redirect then redirect to that url
    if (url.includes('redirect')) {
      var redirect_url = url.split('redirect=')[1]
      window.open(redirect_url, '_blank').focus()
    } else if (url.includes('action')) {
      // if url includes action then perform that action
      var action = url.split('action=')[1]
      return true
    } else {
      history.push(url)
    }
  }

  render() {
    return (
      <div>
        <Carousel
          autoplay
          className=""
          infinite={true}
          slidesToShow={isMobile ? 1 : 2}
          speed={500}
          swipeToSlide
          autoplaySpeed={10000}
          draggable
          arrows
          style={{ cursor: 'pointer' }}
        >
          {this.state.banners.map((item, index) => (
            <div className="pr-3 pt-3 pb-4">
              <Card
                size="small"
                bordered={false}
                className="card-shadow-3 bg-card-white border-radius-small"
              >
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col justify-between w-70p">
                    <div className="flex flex-col ">
                      <div>
                        <span className="font-size-16 font-weight-bold text-gray-900">
                          {item.title}
                        </span>
                        <p className="font-size-13 text-gray-500 mt-0 mb-0">{item.sub_title}</p>
                      </div>
                    </div>
                    <div className="flex font-size-14">
                      <Button
                        // onClick={() => this.WelcomeCompanyForm.onAdd()}
                        onClick={event => {
                          item.web_action
                            ? this.redirectTo(item.web_action)
                            : event.stopPropagation()
                        }}
                        className="mt-3 px-0 font-weight-bold bg-white"
                        type="transparent"
                        size="small"
                      >
                        <span style={{ color: item.btn_color }}>
                          {item.action_button}
                          <i className="ml-1 font-size-12 fa fa-solid fa-chevron-right" />
                        </span>
                      </Button>
                    </div>
                  </div>

                  <div className="flex flex-col float-right w-30p">
                    <Image src={item.image} preview={false} height={81} className="" />
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
}

export default Banners
