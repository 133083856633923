import React, { Component } from 'react'

export class BizBadge extends Component {
  render() {
    return (
      // <span className="badge align-text-top badge-gold badge-collapsed-hidden font-italic font-weight-bolder ml-2">
      //   <i className="fa-solid fa-rocket-launch"></i>BIZ
      // </span>

      <span>
        <i className="fa-regular fa-lock-keyhole ml-2"></i>
      </span>
    )
  }
}

export default BizBadge
