import { Card, Carousel, Image, Modal } from 'antd'
import { Component } from 'react'
import { isMobile } from 'react-device-detect'

class ProductImagesCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      productImages: [],
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  onFillImages = product_images => {
    this.setState({ productImages: product_images, visible: true })
  }

  render() {
    return (
      <Modal
        centered
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        open={this.state.visible}
        onCancel={() => {
          this.setState({ visible: false })
        }}
        destroyOnClose
        width={'60%'}
        footer={[]}
      >
        <Carousel
          autoplay
          className=""
          infinite={false}
          slidesToShow={this.state.productImages?.length == 1 ? 1 : isMobile ? 1 : 2}
          speed={500}
          swipeToSlide
          autoplaySpeed={10000}
          draggable
          arrows
          style={{ cursor: 'pointer' }}
        >
          {this.state.productImages?.map((item, index) => (
            <div className="pr-3 pt-3 pb-4">
              <Card
                size="small"
                bordered={false}
                className="card-shadow-3 bg-card-white border-radius-small"
              >
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col float-right w-30p">
                    <Image src={item.url} preview={false} />
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </Carousel>
      </Modal>
    )
  }
}

export default ProductImagesCarousel
