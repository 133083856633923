import { Button, Checkbox, Input, Modal, message } from 'antd'
import { useState } from 'react'

interface Props {
  visible: boolean
  onCancel: () => void
  onOk: (convertType: string) => void
  convertType: string
  itemType: 'product' | 'variant'
}

export default function ConvertToBatchSN({
  visible,
  onCancel,
  onOk,
  convertType,
  itemType,
}: Props) {
  const [confirmed, setConfirmed] = useState(false)
  const [name_typed, setNameTyped] = useState(false)

  const onClose = () => {
    onCancel()
    setConfirmed(false)
    setNameTyped(false)
  }

  const onConfirm = () => {
    onOk(convertType)
    setConfirmed(false)
    setNameTyped(false)
  }

  return (
    <Modal
      open={visible}
      title={<div className="text-lg font-bold">Warning</div>}
      maskClosable={false}
      footer={
        name_typed && (
          <>
            <span className="text-danger font-weight-bold float-left">
              This action is irreversible.
            </span>

            <Button key="back" onClick={onClose}>
              Cancel
            </Button>

            <Button className="font-weight-bold" type="danger" onClick={onConfirm}>
              Confirm
            </Button>
          </>
        )
      }
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      onCancel={onClose}
      width={720}
    >
      <div>
        <div className="mt-2">
          <p className="text-danger mt-3 mb-1 font-size-14 pl-1">
            <p className="font-weight-bold">
              Converting to batch / serial no will delete the this {itemType} and create a new one.
            </p>
          </p>

          <ul className="text-gray-600 font-size-14 p-2">
            <li>
              The current {itemType} inventory will be removed and will no longer be accessible
              after the conversion.
            </li>
            <li>
              The new {itemType} will be created with the same details as the existing {itemType}.
            </li>
          </ul>

          <div className="mt-2 ml-3">
            <Checkbox
              checked={confirmed}
              onChange={e => {
                setConfirmed(e.target.checked)
              }}
            >
              <span className="font-size-14 text-gray-600">
                Yes, I read and understood the above information and I am sure that I want to
                proceed
              </span>
            </Checkbox>
          </div>
        </div>

        {confirmed ? (
          <div className="mt-5">
            <div className="text-gray-700 font-weight-bold">
              Still want to continue?
              <p className="text-gray-500 mt-0 mb-1 font-size-12">
                Please enter <span className="text-danger font-weight-bold font-size-16">Yes</span>
                {'  '}
                in the input field below to confirm
              </p>
            </div>

            <Input
              className="mt-1 text-danger font-weight-bold"
              size="middle"
              placeholder="Yes"
              type="text"
              style={{ width: '50%', height: '30px' }}
              onChange={e => {
                e.target.value === 'Yes' ? setNameTyped(true) : setNameTyped(false)
              }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </Modal>
  )
}
