import {
  GET_DEFAULT_URL,
  GET_PERMISSIONS,
  SET_ALL_USERS,
  SET_BEACON,
  SET_COMPANY_DETAILS,
  SET_CUSTOMER_SETTINGS,
  SET_DEFAULT_URL,
  SET_INTEGRATIONS,
  SET_PERMISSIONS,
  SET_PRODUCT_SETTINGS,
  SET_VENDOR_SETTINGS,
} from './types'

const initialState = {
  permission: {},
  default_url: '',
  integrations: {},
  all_users: [],
  company_details: {},
  is_beacon_loaded: false,
  productSettings: {},
  customerSettings: {},
  vendorSettings: {},
}

export default function permissions(state = initialState, action) {
  switch (action.type) {
    case SET_PERMISSIONS:
      state.permission = action.payload
      return { ...state }
    case GET_PERMISSIONS:
      return state.permission

    case SET_DEFAULT_URL:
      return { ...state, default_url: action.payload }

    case GET_DEFAULT_URL:
      return state.default_url
    case SET_INTEGRATIONS:
      return { ...state, integrations: action.payload }
    case SET_ALL_USERS:
      return { ...state, all_users: action.payload }
    case SET_COMPANY_DETAILS:
      return { ...state, company_details: action.payload }
    case SET_BEACON:
      return { ...state, is_beacon_loaded: action.payload }
    case SET_PRODUCT_SETTINGS:
      return { ...state, productSettings: action.payload }
    case SET_CUSTOMER_SETTINGS:
      return { ...state, customerSettings: action.payload }
    case SET_VENDOR_SETTINGS:
      return { ...state, vendorSettings: action.payload }
    default:
      return state
  }
}
