const actions = {
  ADD_TO_CART: 'cart/ADD_TO_CART',
  CHANGE: 'cart/CHANGE',
  UPDATE_PRODUCTS: 'cart/UPDATE_PRODUCTS',
  MINUS: 'cart/MINUS',
  PLUS: 'cart/PLUS',
  SELECT_VARIANT: 'cart/SELECT_VARIANT',
  UPDATE_FEATURED_PRODUCTS: 'cart/UPDATE_FEATURED_PRODUCTS',
  UPDATE_BEST_SELLING_PRODUCTS: 'cart/UPDATE_BEST_SELLING_PRODUCTS',
}

export default actions
