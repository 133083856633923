import React, { useRef, useState } from 'react'
import { Select, Button } from 'antd'
import AddressForm from 'components/forms/addressForm'
import { Address } from 'redux/types'

type SelectAddressProps = {
  address: Address[]
  selectedAddress: Address
  addressType: string
  invoiceDetails: any
  onChangeAddress: (value: number) => void
  onFinish: () => void
  disabled: boolean
}

const SelectAddress = (props: SelectAddressProps) => {
  const addressFormRef = useRef<{
    showAddressDrawer: (type: string, address: any, partyDetails: any) => void
  } | null>(null)
  return (
    <>
      <Select
        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
        placeholder={'Select address'}
        optionFilterProp="children"
        style={{ minWidth: '100%', maxWidth: 300 }}
        listHeight={200}
        dropdownStyle={{ minWidth: '360px', maxWidth: '360px' }}
        value={props.selectedAddress ? props.selectedAddress?.addr_id : undefined}
        filterOption={(input: any, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={e => props.onChangeAddress(e)}
        disabled={props.disabled}
        dropdownRender={menu => (
          <>
            {menu}
            <div className="bg-gray-50">
              <Button
                block
                type="link-2"
                className="font-weight-bolder text-left my-2 arrow-transition"
                onClick={e => {
                  e.stopPropagation()
                  addressFormRef?.current?.showAddressDrawer(
                    props.addressType,
                    {
                      addr_id: -1,
                    },
                    {
                      party_id: props.invoiceDetails.party_id,
                      party_type: props.invoiceDetails.party_type,
                      is_party_edit: true,
                    },
                  )
                }}
              >
                <i className="fa-solid fa-circle-plus mr-2"></i>
                Add Customer Shipping address
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>
            </div>
          </>
        )}
      >
        {props.address?.length > 0 && (
          <>
            {props.address &&
              props.address.map(
                (item: any, i: number) =>
                  item &&
                  item.is_delete == 0 && (
                    <Select.Option key={i} value={item.addr_id} className="relative group">
                      <span>
                        {item.line1}, {item.line2}, {item.city}, {item.state}, {item.pincode}
                      </span>
                      <i
                        className="fa-solid fa-pen absolute right-2 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer hover:text-blue-500"
                        onClick={e => {
                          e.stopPropagation()
                          addressFormRef?.current?.showAddressDrawer(props.addressType, item, {
                            party_id: props.invoiceDetails.party_id,
                            party_type: props.invoiceDetails.party_type,
                            is_party_edit: true,
                          })
                        }}
                      ></i>
                    </Select.Option>
                  ),
              )}
          </>
        )}
      </Select>
      <AddressForm ref={addressFormRef} onFinish={props.onFinish} />
    </>
  )
}

export default SelectAddress
