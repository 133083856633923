import actions from './actions'

const initialState = {
  company_details_added: false,
  bank_details_added: false,
  products_added: false,
  invoices_created: false,
}

export default function welcomeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_WELCOME_DETAILS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
