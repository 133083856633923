export const export_terms = {
  'Export under bond/LUT':
    'Supply meant for Export under bond or letter of undertaking without Payment of Integrated Tax',
  'Export with IGST': 'Supply Meant for Export on Payment of Integrated Tax',
  'SEZ with IGST Payment': 'Supply meant for SEZ with Payment of Integrated Tax',
  'SEZ without IGST Payment': 'Supply meant for SEZ under LUT without Payment of Integrated Tax',
}
export const get_invoice_title = (
  doc_type,
  document_title,
  with_tax,
  gstn = '',
  item = '',
  invoice,
) => {
  if (doc_type == 'purchase_returns') {
    return invoice.invoice_settings.labels.purchase_return_title.toUpperCase()
  } else if (doc_type == 'pro_forma_invoices') {
    return invoice.invoice_settings.labels.pro_forma_invoice_title.toUpperCase()
  } else if (doc_type == 'sales_returns') {
    return invoice.invoice_settings.labels.sales_return_title.toUpperCase()
  } else if (doc_type == 'delivery_challans') {
    return invoice.invoice_settings.labels.delivery_challan_title.toUpperCase()
  } else if (doc_type == 'purchase_orders') {
    return invoice.invoice_settings.labels.purchase_order_title.toUpperCase()
  } else if (doc_type == 'purchases') {
    return invoice.invoice_settings.labels.purchase_title.toUpperCase()
  } else if (doc_type == 'sales' || doc_type == 'pos') {
    if (document_title == 'Bill of Supply') {
      return invoice.invoice_settings.labels.bill_of_supply_title.toUpperCase()
    } else if (gstn.length == 15 && with_tax) {
      return item != 'Delivery Challan'
        ? invoice.invoice_settings.labels.tax_invoice_title.toUpperCase()
        : invoice.invoice_settings.labels.delivery_challan_title.toUpperCase()
    } else {
      return item != 'Delivery Challan'
        ? invoice.is_export == 1
          ? invoice.invoice_settings.labels.export_invoice_title.toUpperCase()
          : invoice.invoice_settings.labels.invoice_title.toUpperCase()
        : invoice.invoice_settings.labels.delivery_challan_title.toUpperCase()
    }
  } else if (doc_type == 'estimates') {
    if (document_title == 'Quotation') {
      return invoice.invoice_settings.labels.quotation_title.toUpperCase()
    } else {
      return document_title.toUpperCase()
    }
  }
}

export const get_invoice_type = (doc_type, item) => {
  if (doc_type == 'sales' || doc_type == 'pos') {
    return item != 'Delivery Challan' ? item : ''
  } else {
    return item
  }
}

export const getStateName = state => {
  state = state.split('-')
  if (state.length > 1) {
    state[0] = state[1]
  }
  if (state[0] == 'OTHERTERRITORY') {
    return ''
  }
  return state[0]
}

export const has_multiple_units = items => {
  let has_multiple_units = false
  items.map(item => {
    if (item.conversion_rate != 1) has_multiple_units = true
  })
  return has_multiple_units
}
