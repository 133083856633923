import { Button, Card } from 'antd'
import React from 'react'

interface FeatureActionProps {
  title: string
  description: string
  btnText: string
  onClick: () => void
}

const FeatureAction = ({ title, description, btnText, onClick }: FeatureActionProps) => {
  return (
    <Card
      bordered={false}
      // @ts-ignore
      size="drawer"
      className="border-radius-small mb-4"
    >
      <div className="flex justify-between items-center">
        <div>
          <h6 className="font-weight-bold text-black mb-0 font-size-16">{title}</h6>
          <p className="text-gray-500 font-size-14">{description}</p>
        </div>
        <div className="ml-2">
          <Button type="yellow-2" className="font-weight-bold" onClick={() => onClick()}>
            <i className="fa fa-plus-circle mr-2" />
            {btnText}
          </Button>
        </div>
      </div>
    </Card>
  )
}

export default FeatureAction
