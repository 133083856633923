import React, { Fragment } from 'react'
import { Text, View, Image } from '@react-pdf/renderer'
// import { EnhancedQRCode } from 'components/other/qrCodeFile'
import { getStateName } from '../export_condition'
import { styles as getStyles } from './styles'
import { PlaceOfSupply_2 } from '../InvoicePlaceOfSupply'
import { dispatch_to_doc_types } from '../utils'

const BillTo = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  var data = invoice.gst_details.id == undefined ? invoice.company : invoice.gst_details
  var tax_id_label = invoice.company.tax_id_label
  let export_labels_1 = [
    {
      conversion_factor: 'Conversion Rate',
      is_show: invoice.is_export == 1 || invoice.is_multi_currency == 1,
    },
    {
      currency_code: 'Currency',
      is_show: invoice.is_export == 1 || invoice.is_multi_currency == 1,
    },
    { export_type: 'Export Type', is_show: invoice.is_export == 1 },
    { name: 'Country Name', is_show: invoice.is_export == 1 },
    { shipping_date: 'Shipping Bill Date', is_show: invoice.is_export == 1 },
    { shipping_bill_number: 'Shipping bill #', is_show: invoice.is_export == 1 },
    { shipping_port_code: 'Shipping Port Code', is_show: invoice.is_export == 1 },
  ]

  invoice.invoice_settings.show_conversion_factor != 1 && export_labels_1.shift()

  let export_labels = []

  try {
    if (invoice.company.gstin.length != 15) {
      export_labels_1 = export_labels_1.filter(item => {
        return Object.keys(item)[0] != 'export_type'
      })
    }
    if (
      (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
      invoice.invoice_settings.show_both_currencies != true
    ) {
      export_labels_1 = export_labels_1.filter(item => {
        return (
          invoice.export_details[0][Object.keys(item)[0]] != '' &&
          invoice.export_details[0][Object.keys(item)[0]] != null &&
          item['is_show'] == true
        )
      })
    }
  } catch (e) {
    console.log(e)
  }

  while (export_labels_1.length) export_labels.push(export_labels_1.splice(0, 2))
  var newarray = []
  if (Object.keys(invoice).includes('document_custom_headers')) {
    var new_headers = [...invoice.document_custom_headers]
    while (new_headers.length) newarray.push(new_headers.splice(0, 2))
  }

  return (
    <Fragment>
      <View style={styles.company_column}>
        <View style={styles.company_blockC}>
          <View style={[styles.company_blockCA, styles.company_borderRight]}>
            {invoice.customer.name != null && invoice.customer.name != '' && (
              <View style={styles.company_blockCol}>
                {invoice.document_type == 'purchases' ||
                invoice.document_type == 'purchase_orders' ||
                invoice.document_type == 'purchase_returns' ? (
                  <Text
                    style={styles.company_labels}
                  >{`${invoice.invoice_settings.labels.vendor_details}:`}</Text>
                ) : (
                  <Text
                    style={styles.company_labels}
                  >{`${invoice.invoice_settings.labels.customer_details}:`}</Text>
                )}
                {invoice.customer.name != invoice.customer.company_name ? (
                  <>
                    <Text style={[styles.company_text, styles.company_bold]}>
                      {invoice.customer.name}
                    </Text>
                    <Text style={[styles.company_text, styles.company_bold]}>
                      {invoice.customer.company_name}
                    </Text>
                  </>
                ) : (
                  <Text style={[styles.company_text, styles.company_bold]}>
                    {invoice.customer.company_name}
                  </Text>
                )}

                {invoice.customer.gstin != null && invoice.customer.gstin != '' && (
                  <Text style={[styles.company_labels]}>
                    {tax_id_label}: {invoice.customer.gstin}
                  </Text>
                )}

                {invoice.customer.billing != undefined &&
                  invoice.customer.billing.address_1 != null &&
                  invoice.customer.billing.address_1 != '' && (
                    <>
                      {invoice.document_type != 'purchases' &&
                        invoice.document_type != 'purchase_orders' && (
                          <Text style={styles.company_labels}>
                            {invoice.invoice_settings.labels.customer_billing_address}:
                          </Text>
                        )}
                      {(invoice.document_type == 'purchases' ||
                        invoice.document_type == 'purchase_orders') && (
                        <Text style={styles.company_labels}>
                          {invoice.invoice_settings.labels.vendor_billing_address}:
                        </Text>
                      )}
                      <Text style={styles.company_text}>{invoice.customer.billing.address_1}</Text>
                      <Text style={styles.company_text}>{invoice.customer.billing.address_2}</Text>
                      <Text style={styles.company_text}>
                        {invoice.customer.billing.city != '' && invoice.customer.billing.city}
                        {invoice.is_export != 1 &&
                          invoice.customer.billing.state != '' &&
                          invoice.customer.billing.state != undefined &&
                          invoice.customer.billing.state != null &&
                          (getStateName(invoice.customer.billing.state) != 'OTHERTERRITORY' ||
                            getStateName(invoice.customer.billing.state) != '97-OTHERTERRITORY' ||
                            getStateName(invoice.customer.billing.state) != 'OTHER TERRITORY') &&
                          (invoice.customer.billing.city != '' ? ', ' : '') +
                            getStateName(invoice.customer.billing.state)}
                        {invoice.is_export == 1 &&
                          invoice.customer.billing.country &&
                          invoice.customer.billing.country != 'India' &&
                          '\n' + invoice.customer.billing.country}
                        {invoice.customer.billing.pincode != '' &&
                          invoice.customer.billing.pincode != null &&
                          invoice.customer.billing.pincode != 'None' &&
                          invoice.customer.billing.pincode != undefined &&
                          (invoice.customer.billing.city != '' ||
                          invoice.customer.billing.state != ''
                            ? ', '
                            : '') + invoice.customer.billing.pincode}
                      </Text>
                    </>
                  )}

                <Text style={[styles.company_text2]}>
                  {invoice.customer.phone == ''
                    ? ''
                    : 'Ph: ' +
                      (invoice.customer.dial_code != '91'
                        ? '+' + invoice.customer.dial_code + ' '
                        : '') +
                      invoice.customer.phone}
                </Text>
                <Text style={[styles.company_mb1, styles.company_text2]}>
                  {invoice.customer.email == '' ||
                  invoice.customer.email == undefined ||
                  invoice.customer.email == null
                    ? ''
                    : invoice.customer.email}
                </Text>

                {'custom_party_values' in invoice &&
                  invoice.custom_party_values.map((item, index) => (
                    <Text>
                      <Text style={styles.company_label}>{item.name}: </Text>
                      <Text style={styles.company_text}>{item.value}</Text>
                    </Text>
                  ))}

                {invoice.customer.shipping != undefined &&
                  invoice.customer.shipping.address_1 != null &&
                  invoice.customer.shipping.address_1 != '' && (
                    <>
                      <Text style={styles.company_labels}>
                        {invoice.invoice_settings.labels.customer_shipping_address}:
                      </Text>
                      {invoice.customer.shipping.title != '' && (
                        <Text style={styles.company_text2}>{invoice.customer.shipping.title}</Text>
                      )}
                      <Text style={styles.company_text2}>
                        {invoice.customer.shipping.address_1}
                      </Text>
                      <Text style={styles.company_text2}>
                        {invoice.customer.shipping.address_2}
                      </Text>
                      <Text style={styles.company_text2}>
                        {invoice.customer.shipping.city != '' && invoice.customer.shipping.city}
                        {invoice.is_export != 1 &&
                          invoice.customer.shipping.state != '' &&
                          invoice.customer.shipping.state != undefined &&
                          invoice.customer.shipping.state != null &&
                          (getStateName(invoice.customer.shipping.state) != 'OTHERTERRITORY' ||
                            getStateName(invoice.customer.shipping.state) != '97-OTHERTERRITORY' ||
                            getStateName(invoice.customer.shipping.state) != 'OTHER TERRITORY') &&
                          (invoice.customer.shipping.city != '' ? ', ' : '') +
                            getStateName(invoice.customer.shipping.state)}
                        {invoice.is_export == 1 &&
                          invoice.customer.shipping.country &&
                          invoice.customer.shipping.country != 'India' &&
                          '\n' + invoice.customer.shipping.country}
                        {invoice.customer.shipping.pincode != '' &&
                          invoice.customer.shipping.pincode != null &&
                          invoice.customer.shipping.pincode != 'None' &&
                          invoice.customer.shipping.pincode != undefined &&
                          (invoice.customer.shipping.city != '' ||
                          invoice.customer.shipping.state != ''
                            ? ', '
                            : '') + invoice.customer.shipping.pincode}
                        {invoice.customer.shipping.notes != '' &&
                          '\n' + invoice.customer.shipping.notes}
                      </Text>
                    </>
                  )}
              </View>
            )}
          </View>

          <View style={styles.company_blockCB}>
            <View style={[styles.company_block, styles.company_borderBottom]}>
              <View style={[styles.company_blockC50, styles.company_borderRight]}>
                <View>
                  <Text style={styles.company_labels}>{invoice.document_title} #: </Text>
                  <Text style={[styles.company_text2, styles.company_bold]}>
                    {invoice.serial_number}
                  </Text>
                </View>

                {/*<View style={styles.company_row}>
                  <Text style={styles.company_labels}>{invoice.document_title} #: </Text>
                  <Text style={[styles.company_info2]}>{invoice.serial_number}</Text>
                </View>*/}
              </View>

              <View style={[styles.company_blockC50]}>
                <View>
                  <Text style={styles.company_labels}>Date: </Text>
                  <Text style={[styles.company_text2, styles.company_bold]}>
                    {invoice.document_date}
                  </Text>
                </View>
                {/*<View style={styles.company_row}>
                      <Text style={styles.company_labels}>Date: </Text>
                      <Text style={[styles.company_info2, styles.company_bold]}>{invoice.document_date}</Text>
                    </View>*/}
              </View>
            </View>

            {invoice.document_type == 'purchases' && invoice.supplier_invoice_serial_number != '' && (
              <View style={[styles.company_block, styles.company_borderBottom]}>
                <View style={[styles.company_blockC50, styles.company_borderRight]}>
                  <View>
                    <Text style={styles.company_labels}>Supplier Invoice #: </Text>
                    <Text style={[styles.company_text2, styles.company_bold]}>
                      {invoice.supplier_invoice_serial_number}
                    </Text>
                  </View>
                </View>

                <View style={[styles.company_blockC50]}>
                  <Text style={styles.company_labels}>Supplier Invoice Date: </Text>
                  <Text style={[styles.company_text2, styles.company_bold]}>
                    {invoice.supplier_invoice_date_string}
                  </Text>
                </View>
              </View>
            )}

            <View style={[styles.company_block, styles.company_borderBottom]}>
              {/* <View style={[styles.company_blockC50, styles.company_borderRight]}>
                <Text style={styles.company_labels}>Place of Supply: </Text>
                {invoice.document_type == 'purchases' ||
                invoice.document_type == 'purchase_orders' ||
                invoice.document_type == 'purchase_returns' ||
                invoice.immovable_tax_type == 1 ? (
                  <Text style={[styles.company_text2, styles.company_bold]}>
                    {invoice.company.billing_state}
                  </Text>
                ) : (
                  <Text style={[styles.company_text2, styles.company_bold]}>
                    {invoice.customer.billing.state != '' &&
                      invoice.customer.billing.state != 'OTHERTERRITORY' &&
                      invoice.customer.billing.state != '97-OTHERTERRITORY' &&
                      invoice.customer.billing.state}
                    {invoice.customer.billing.state == '' && invoice.company.billing_state}{' '}
                    {invoice.is_export == 1 &&
                      invoice.customer.billing.state == '97-OTHERTERRITORY' && (
                        <Text style={{ textTransform: 'uppercase' }}>
                          {invoice.export_details[0].name}
                        </Text>
                      )}
                  </Text>
                )}
              </View> */}

              <PlaceOfSupply_2
                invoice={invoice}
                styles={{
                  block: { ...styles.company_blockC50, ...styles.company_borderRight },
                  title: styles.company_labels,
                  text: { ...styles.company_text2, ...styles.company_bold },
                }}
                in_new_line={false}
              />

              {invoice.invoice_settings.show_due_date == 1 && (
                <View style={styles.company_blockC50}>
                  {invoice.document_type != 'delivery_challans' && (
                    <>
                      <Text style={styles.company_labels}>Due Date: </Text>
                      <Text style={[styles.company_text2, styles.company_bold]}>
                        {invoice.document_due_date}
                      </Text>
                    </>
                  )}
                </View>
              )}
            </View>

            {Object.keys(invoice).includes('eway_bill') && invoice.eway_bill.length > 0 && (
              <View style={[styles.company_block, styles.company_borderBottom]}>
                <View style={[styles.company_blockC50, styles.company_borderRight]}>
                  <Text style={styles.company_labels}>Eway Bill #: </Text>
                  <Text style={[styles.company_text2, styles.company_bold]}>
                    {invoice.eway_bill[0].serial_number}
                  </Text>
                </View>

                <View style={[styles.company_blockC50]}>
                  <Text style={styles.company_labels}>Vehicle Number: </Text>
                  <Text style={[styles.company_text2, styles.company_bold]}>
                    {invoice.eway_bill[0].vehicle_number}
                  </Text>
                </View>
              </View>
            )}

            {newarray.map((item, index) => (
              <View style={[styles.company_block, styles.company_borderBottom]}>
                {item.map((item2, index2) => (
                  <Fragment>
                    {index2 == 0 && (
                      <View style={[styles.company_blockC50, styles.company_borderRight]}>
                        <Text style={styles.company_labels}>{item2.label}: </Text>
                        <Text style={[styles.company_text2, styles.company_bold]}>
                          {item2.value}
                        </Text>
                      </View>
                    )}

                    {index2 == 1 && (
                      <View style={[styles.company_blockC50]}>
                        <Text style={styles.company_labels}>{item2.label}:</Text>
                        <Text style={[styles.company_text2, styles.company_bold]}>
                          {item2.value}
                        </Text>
                      </View>
                    )}
                  </Fragment>
                ))}
              </View>
            ))}

            {'export_details' in invoice &&
              invoice.export_details.length > 0 &&
              export_labels.map((item2, index) => (
                <View style={[styles.company_block, styles.company_borderBottom]}>
                  {item2.map((item, index2) => (
                    <Fragment>
                      {Object.keys(invoice.export_details[0]).includes(Object.keys(item)[0]) &&
                        index2 == 0 &&
                        invoice.export_details[0][Object.keys(item)[0]] != '' &&
                        invoice.export_details[0][Object.keys(item)[0]] != null && (
                          <View style={[styles.company_blockC50, styles.company_borderRight]}>
                            <Text style={styles.company_labels}>{item[Object.keys(item)[0]]}:</Text>
                            <Text style={[styles.company_text2, styles.company_bold]}>
                              {invoice.export_details[0][Object.keys(item)[0]]}
                            </Text>
                          </View>
                        )}
                      {Object.keys(invoice.export_details[0]).includes(Object.keys(item)[0]) &&
                        index2 == 1 &&
                        invoice.export_details[0][Object.keys(item)[0]] != '' &&
                        invoice.export_details[0][Object.keys(item)[0]] != null && (
                          <View style={[styles.company_blockC50]}>
                            <Text style={styles.company_labels}>{item[Object.keys(item)[0]]}:</Text>
                            <Text style={[styles.company_text2, styles.company_bold]}>
                              {invoice.export_details[0][Object.keys(item)[0]]}
                            </Text>
                          </View>
                        )}
                    </Fragment>
                  ))}
                </View>
              ))}
            {/*
            {invoice.gst_details.id == undefined &&
              invoice.invoice_settings.show_dispatch_address === 1 &&
              (invoice.document_type == 'purchases' ||
                invoice.document_type == 'purchase_orders' ||
                invoice.document_type == 'purchase_returns') && (
                <>
                  <Text style={styles.company_labels}>Dispatch to:</Text>
                  <Text style={styles.company_text2}>{invoice.company.shipping_address.title}</Text>
                  <Text style={styles.company_text2}>
                    {invoice.company.shipping_address.address_1}
                  </Text>
                  <Text style={styles.company_text2}>
                    {invoice.company.shipping_address.address_2}
                  </Text>
                  <Text style={styles.company_text2}>
                    {invoice.company.shipping_address.city == ''
                      ? ''
                      : invoice.company.shipping_address.city +
                        (invoice.company.shipping_address.state == '' ||
                        invoice.company.shipping_address.state == undefined ||
                        invoice.company.shipping_address.state == null ||
                        getStateName(invoice.company.shipping_address.state) == 'OTHER TERRITORY' ||
                        getStateName(invoice.company.shipping_address.state) ==
                          '97-OTHERTERRITORY' ||
                        getStateName(invoice.company.shipping_address.state) == 'OTHERTERRITORY'
                          ? ''
                          : ', ')}
                    {invoice.company.shipping_address.state == '' ||
                    getStateName(invoice.company.shipping_address.state) == 'OTHER TERRITORY' ||
                    getStateName(invoice.company.shipping_address.state) == '97-OTHERTERRITORY' ||
                    getStateName(invoice.company.shipping_address.state) == 'OTHERTERRITORY'
                      ? ''
                      : getStateName(invoice.company.shipping_address.state) +
                        (invoice.company.shipping_address.pincode == '' ||
                        invoice.company.shipping_address.pincode == undefined ||
                        invoice.company.shipping_address.pincode == null
                          ? ''
                          : ', ')}
                    {invoice.company.shipping_address.pincode == ''
                      ? ''
                      : invoice.company.shipping_address.pincode}
                  </Text>
                </>
              )} */}

            {invoice.gst_details.id == undefined &&
              invoice.company.shipping_address.address_1 != '' &&
              invoice.invoice_settings.show_dispatch_address === 1 && (
                <View style={[styles.company_block]}>
                  <View style={[styles.company_blockC100]}>
                    <Text style={styles.company_labels}>
                      {dispatch_to_doc_types.includes(invoice.document_type)
                        ? invoice.invoice_settings.labels.dispatch_to
                        : invoice.invoice_settings.labels.dispatch_from}
                      :
                    </Text>
                    {invoice.company.shipping_address.title != '' && (
                      <Text style={styles.company_text2}>
                        {invoice.company.shipping_address.title}
                      </Text>
                    )}
                    <Text style={styles.company_text2}>
                      {invoice.company.shipping_address.address_1}
                    </Text>
                    <Text style={styles.company_text2}>
                      {invoice.company.shipping_address.address_2}
                    </Text>
                    <Text style={styles.company_text2}>
                      {invoice.company.shipping_address.city != '' &&
                        invoice.company.shipping_address.city}
                      {invoice.company.shipping_address.state != '' &&
                        invoice.company.shipping_address.state != undefined &&
                        invoice.company.shipping_address.state != null &&
                        (getStateName(invoice.company.shipping_address.state) != 'OTHERTERRITORY' ||
                          getStateName(invoice.company.shipping_address.state) !=
                            '97-OTHERTERRITORY' ||
                          getStateName(invoice.company.shipping_address.state) !=
                            'OTHER TERRITORY') &&
                        (invoice.company.shipping_address.city != '' ? ', ' : '') +
                          getStateName(invoice.company.shipping_address.state)}
                      {invoice.is_export == 1 &&
                        invoice.company.shipping_address.country &&
                        invoice.company.shipping_address.country != 'India' &&
                        '\n' + invoice.company.shipping_address.country}
                      {invoice.company.shipping_address.pincode != '' &&
                        invoice.company.shipping_address.pincode != null &&
                        invoice.company.shipping_address.pincode != 'None' &&
                        invoice.company.shipping_address.pincode != undefined &&
                        (invoice.company.shipping_address.city != '' ||
                        invoice.company.shipping_address.state != ''
                          ? ', '
                          : '') + invoice.company.shipping_address.pincode}
                    </Text>
                  </View>
                </View>
              )}

            {invoice.reference != '' && (
              <View style={[styles.company_block]}>
                <View style={[styles.company_blockC100]}>
                  <Text style={styles.company_labels}>Reference: </Text>
                  <Text style={styles.company_text2}>{invoice.reference}</Text>
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
    </Fragment>
  )
}

export default BillTo
