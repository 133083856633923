import React, { useState } from 'react'
import { Modal, Button, Input, message, Table } from 'antd'
import { deleteAPI, getAPI } from 'services/jwt'

const SubscriptionUpdateModal = props => {
  const [confirmUpdate, setConfirmUpdate] = useState(false)
  const [verified, setVerified] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleConfirmation = async () => {
    if (!confirmUpdate) {
      message.error('Incorrect value in input. Please enter Confirm to proceed.')
      return
    }

    if (!verified) {
      message.error('Please verify to proceed.')
      return
    }

    setLoading(true)

    try {
      var req = {
        product_id: props.productId,
        variant_id: props?.variantId || 0,
        batch_id: props?.batchId || 0,
      }

      const data = await getAPI('product', 'update_subscription_on_product_update', req)
      //   if (data && data.success) {
      message.success(data.message)
      setConfirmUpdate(false)
      setVerified(false)
      props.callData()
      //   }
    } catch (error) {
      message.error(error)
    }
    setLoading(false)
  }

  return (
    <Modal
      title={'Update Subscriptions with new price for "' + props.source + '"'}
      open={props.showConfirmationModal}
      onCancel={() => props.onCancel()}
      width={640}
      loading={loading}
      maskClosable={false}
      centered
      destroyOnClose
      closeIcon={<i className="fa-solid fa-xmark"></i>}
      footer={
        <>
          <Button
            key="back"
            onClick={() => {
              props.onCancel()
              setConfirmUpdate(false)
              props.callData()
            }}
            loading={loading}
          >
            Don't Update
          </Button>

          <Button
            className="font-weight-bold"
            type="danger-3"
            onClick={() => {
              handleConfirmation()
            }}
            loading={loading}
            disabled={!confirmUpdate}
          >
            Update ({props?.activeSubscriptions?.length})
          </Button>
        </>
      }
    >
      <div>
        <p className="text-danger">
          This action will update the subscriptions with new price for "{props.source}" and will be
          affected from upcoming invoices.
        </p>
        {/* <p className="font-weight-bold">
          Active Subscriptions: {props?.activeSubscriptions?.length}
        </p> */}

        {props?.activeSubscriptions?.length > 0 && (
          <Table
            size="small"
            className="mt-3"
            columns={[
              {
                title: 'Serial No.',
                dataIndex: 'serial_number',
              },
              {
                title: 'Upcoming Date',
                dataIndex: 'upcoming_date',
              },
            ]}
            dataSource={props?.activeSubscriptions}
          />
        )}

        <div className="mt-5 mb-2">
          <div className="text-gray-700 font-weight-bold">
            Still want to continue?
            <p className="text-gray-500 mt-0 mb-1 font-size-12">
              Please enter{' '}
              <span className="text-danger font-weight-bold font-size-16">Confirm</span>
              {'  '}
            </p>
          </div>

          <Input
            className="mt-1 text-danger font-weight-bold"
            size="medium"
            placeholder="Confirm"
            type="text"
            style={{ width: '50%', height: '30px' }}
            onChange={e => {
              e.target.value === 'Confirm' ? setConfirmUpdate(true) : setConfirmUpdate(false)
            }}
            onPaste={e => {
              message.error('Paste not allowed !') && e.preventDefault()
              return false
            }}
            onCopy={e => {
              message.error('Copy not allowed !') && e.preventDefault()
              return false
            }}
            disabled={confirmUpdate}
          />
          {/* {confirmUpdate ? (
            <OtpVerification
              className="mt-3"
              onVerify={() => setVerified(true)}
              source={'delete_all_' + props.source}
            />
          ) : (
            ''
          )} */}
        </div>
        <p className="mt-1">Regardless of the below selection, product details will be updated.</p>
      </div>
    </Modal>
  )
}

export default SubscriptionUpdateModal
