import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { export_terms } from '../export_condition'
import { styles as getStyles } from './styles'

const InvoiceNotes = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  return (
    <View style={styles.invoiceNotes_titleContainer}>
      {invoice.rcm == 1 && (
        <View style={styles.invoiceNotes_mb}>
          <Text style={styles.invoiceNotes_heading}>*Amount of Tax subject to Reverse Charge</Text>
        </View>
      )}
      {invoice.immovable_tax_type == 1 && (
        <View style={styles.invoiceNotes_mb}>
          <Text style={styles.invoiceNotes_heading}>
            *Place of Supply is determined u/s 12(3) of the IGST Act 2017.
          </Text>
        </View>
      )}

      {invoice.is_export == 1 && invoice.company.gstin.length == 15 && (
        <View style={styles.invoiceNotes_mb}>
          <Text style={styles.invoiceNotes_heading}>
            *{export_terms[invoice.export_details[0].export_type]}
          </Text>
        </View>
      )}

      {invoice.notes != '' && invoice.notes != 'None' && (
        <View style={styles.invoiceNotes_mb}>
          <Text style={styles.invoiceNotes_heading}>Notes:</Text>
          <Text style={styles.invoiceNotes_customerNotes}>{invoice.notes}</Text>
        </View>
      )}

      {invoice.terms != '' && invoice.terms != 'None' && type != 'Delivery Challan' && (
        <View>
          <Text style={styles.invoiceNotes_heading}>Terms and Conditions:</Text>
          <Text style={styles.invoiceNotes_terms}>{invoice.terms}</Text>
        </View>
      )}

      {invoice.invoice_settings.show_receivers_signature == 1 && (
        <View style={styles.invoiceNotes_receiveSignBlock}>
          <Text
            style={{
              ...styles.invoiceNotes_receiveSignText,
            }}
          >
            Receiver's Signature{' '}
          </Text>
        </View>
      )}
    </View>
  )
}

export default InvoiceNotes
