import { Avatar, Button, Dropdown, Menu } from 'antd'
import { history } from 'index'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { currentAccount, getAPI } from 'services/jwt'
import store from 'store'
import { v4 as uuidv4 } from 'uuid'
import style from '../cleanui/layout/Menu/MenuLeft/style.module.scss'

const mapStateToProps = state => {
  const { user, permissions } = state

  return {
    companies: user.companies,
    user: user,
    permissions: permissions,
  }
}

const ChangeCompany = ({ dispatch, user, permissions, ...props }) => {
  const [visible, setVisible] = useState(false)
  const [stateCompanies, setStateCompanies] = useState(user)
  useEffect(() => {
    setStateCompanies(user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  const handleMenuClick = e => {
    props.onCalled()
    setVisible(false)
    stateCompanies.companies.every(async (element, index, array) => {
      if (element['company_id'] == e.key) {
        // console.log(element, e.key)
        let req = {
          company_id: element.company_id,
        }

        if (!store.get('device_hash')) {
          const device_hash = uuidv4()
          await store.set('device_hash', device_hash)
        }

        const data = await getAPI('user', 'get_token', req)
        if (data?.success) {
          store.set('accessToken', data.token)
          store.set('selectedCompany', element)
        }
        const data2 = await currentAccount()
        await dispatch({
          type: 'user/SET_STATE',
          payload: {
            selectedCompany: element,
          },
        })
        store.set('page', 0)
        window.location.reload()
        return false
      }
      return true
    })
  }
  const updateCompanies = () => {
    return (
      <Menu onClick={handleMenuClick}>
        <Menu.ItemGroup>
          {stateCompanies.companies.map((item, index) => {
            return (
              <Menu.Item key={item.company_id}>
                {item.logo != '' && item.logo != null && item.logo != 'None' && (
                  <Avatar shape="square" size="large" className="mr-2 float-left" src={item.logo} />
                )}
                {(item.logo == '' || item.logo == null || item.logo == 'None') &&
                  item.organization_name.length > 0 && (
                    <Avatar
                      style={{ backgroundColor: '#ff3819' }}
                      shape="square"
                      size="large"
                      className="mr-2 float-left font-weight-bold"
                    >
                      {item.organization_name.charAt(0)}
                    </Avatar>
                  )}
                <span className="font-weight-medium ">
                  <span className="mb-0 pb-0 font-size-14">
                    {item.organization_name}{' '}
                    {item.company_name != item.organization_name && '(' + item.company_name + ')'}{' '}
                    {item.current_company == 1 ? (
                      <i className="fa fa-check-circle ml-2 font-size-21 text-green" />
                    ) : (
                      <></>
                    )}
                  </span>
                  <span>
                    <p className="mt-0 mb-0 font-weight-medium font-size-12">
                      {item.current_company == 1 && (
                        <Link to="/user" className="text-green">
                          Edit Company Details
                        </Link>
                      )}
                    </p>
                  </span>
                </span>

                {/*<span>
                  <span className="float-left">
                    {item.logo != '' && item.logo != null && item.logo != 'None' && (
                      <Avatar shape="square" size="default" className="mr-2" src={item.logo} />
                    )}
                    {(item.logo == '' || item.logo == null || item.logo == 'None') &&
                      item.organization_name.length > 0 && (
                        <Avatar
                          style={{ backgroundColor: '#ff3819' }}
                          shape="square"
                          size="default"
                          className="mr-2 font-weight-bold"
                        >
                          {item.organization_name.charAt(0)}
                        </Avatar>
                      )}
                  </span>
                  <span>
                    {item.organization_name}{' '}
                    {item.company_name != item.organization_name && '(' + item.company_name + ')'}{' '}
                    {item.current_company == 1 ? (
                      <i className="fa fa-check-circle ml-2 pt-2 font-size-21 text-green" />
                    ) : (
                      <></>
                    )}
                  </span>
                </span>
                {item.current_company == 1 && (
                  <span>
                    <Link to="/user">Edit Company Details</Link>
                  </span>
                )}*/}
              </Menu.Item>
            )
          })}

          <Button
            className="mt-1 mb-2 font-weight-bold"
            block
            onClick={() => history.push('/add_new_company')}
          >
            <i className="fa fa-plu mr-2" />
            Want to add a new business?
          </Button>
        </Menu.ItemGroup>
      </Menu>
    )
  }

  const handleVisibleChange = flag => {
    setVisible(flag)
  }

  return (
    <div>
      <Dropdown
        overlay={updateCompanies()}
        overlayStyle={{ minWidth: 360, overflowY: 'auto', maxHeight: 540 }}
        onOpenChange={handleVisibleChange}
        open={visible}
        trigger={['click']}
        placement="bottomLeft"
      >
        <span>
          <a
            className={`font-size-16 font-weight-bold`}
            onClick={e => {
              e.preventDefault()
            }}
          >
            {stateCompanies.selectedCompany.organization_name}{' '}
            {stateCompanies.companies.length != 1 &&
              stateCompanies.selectedCompany.organization_name != undefined && (
                <p className="text-gray-400 font-size-10 mb-0">
                  Change Company <i className="fa fa-chevron-down mr-1" />
                </p>
              )}
          </a>
        </span>
      </Dropdown>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(ChangeCompany))
