import { Col, Divider, Image, Modal, Row } from 'antd'
import ChangeCompany from 'components/changeCompany'
import React, { Component } from 'react'

import { Loader } from 'components/loaders'

export default class NoAccess extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPaymentModal: true,
      loading: false,
    }
  }

  render() {
    return (
      <Modal
        className="promotional-modal"
        width={640}
        keyboard={false}
        maskClosable={false}
        open={this.state.showPaymentModal}
        closable={true}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        footer={null}
        loading={this.state.loading}
        bodyStyle={{ minHeight: '60vh' }}
      >
        {this.state.loading ? (
          <>
            <Loader text={'Changing Company Please Wait....'} visibleText={true} size="medium" />
          </>
        ) : (
          <>
            <Image
              src="/resources/images/upgrade.png"
              preview={false}
              className="m-0 p-0"
              style={{
                padding: 0,
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
              }}
              height={360}
              width={640}
            />
            <Row justify="center" align="middle" className="mt-4 mb-1 pb-1 px-4 ">
              <Col span={24}>
                <h2 className="font-weight-bolder">
                  <span className="font-size-28">Upgrade Plan 🚀</span>
                </h2>
                <p className="text-gray-900 mb-4">
                  You don't have access to this feature. Please contact your admin to upgrade your
                  plan.
                </p>

                {/*;<p className="font-size-21">
             Feel free to reach us at <b>+91 8121 33 5436</b>.<br /> Would really love to hear your feedback.
            </p>*/}

                {/* <span className="float-right">
              <Button
                onClick={event =>
                  window.open(
                    'https://api.whatsapp.com/send?phone=918121335436&text=Hello%20Swipe! I want to upgrade my plan',
                  )
                }
                size="large"
                type="whatsapp"
                className="mr-2 font-size-21 font-weight-bold"
              >
                <i className="fa-brands font-weight-bold fa-whatsapp mr-2" />
                <span>WhatsApp</span>
              </Button>
              <Button size="large" type="primary" onClick={e => {}}>
                <Link to={'/pricing'}>
                  {' '}
                  <span className="font-size-21 font-weight-bold">Check Plans</span>
                </Link>
              </Button>
            </span> */}
              </Col>
            </Row>
            <Divider />
            <Row justify="center" align="middle" className="mt-1 mb-5 pb-5 px-4 ">
              <Col>
                <div className="font-weight-bolder">
                  <span className="font-size-20">Change Company</span>
                </div>
                <p className="text-gray-900 my-2">
                  <ChangeCompany onCalled={() => this.setState({ loading: true })} />
                </p>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    )
  }
}

export class EnhancedNoAccessModal extends React.Component {
  render() {
    return <NoAccess {...this.props} />
  }
}
