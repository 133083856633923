import { Button, Radio, Tooltip } from 'antd'
import BankForm from 'components/forms/onlyBankForm'
import React, { Component } from 'react'
import store from 'store'

class PaymentModes extends Component {
  bankForm = React.createRef()
  constructor(props) {
    super(props)
  }
  getPaymentClass = data => {
    switch (data.toLowerCase()) {
      case 'cash':
        return 'cash'
      case 'card':
        return 'card-payment'
      case 'upi':
        return 'upi'
      case 'emi':
        return 'emi'
      case 'net banking':
        return 'netbanking'
      case 'cheque':
        return 'cheque'
      case 'tds':
        return 'tds'
      case 'tcs':
        return 'tcs'
      default:
        return 'card'
    }
  }

  render() {
    return (
      <div className="payment-modes mt-2">
        <Radio.Group
          onChange={e => {
            this.props.onChange(e)
            store.set('paymentMethod', e.target.value)
          }}
          value={this.props.value}
          // disabled={this.props.disabled == true ? true : false}
        >
          {this.props.data?.map((e, i) => (
            <Tooltip
              title={
                this.props.disabled && e.value != 'Cash' ? (
                  <div className="flex flex-col justify-center items-center">
                    <span>Add bank details to enable</span>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => this.bankForm.onAddFromInvoicePage()}
                    >
                      <i className="fa-solid fa-circle-plus mr-2" aria-hidden="true"></i>
                      Add bank
                    </Button>
                  </div>
                ) : (
                  ''
                )
              }
            >
              <Radio.Button
                key={i}
                value={e.value}
                className={`${this.getPaymentClass(e.value)}  ${
                  this.props.value != e.value ? 'bg-gray-75' : window[this.getPaymentClass(e.value)]
                } `}
                style={{
                  borderRadius: '30px',
                  border: 'none',
                }}
                disabled={this.props.disabled && e.value != 'Cash' ? true : false}
              >
                {e.value}
              </Radio.Button>
            </Tooltip>
          ))}
        </Radio.Group>
        <BankForm refreshBanks={this.props.onFinishAddBank} onRef={ref => (this.bankForm = ref)} />
      </div>
    )
  }
}

export default PaymentModes
