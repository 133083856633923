import { message } from 'antd'
import store from 'store'
import actions from './actions'

import { getNetAmount, getRowTotal, getTaxAmount } from '../../components/other/calculation'

var initialState = {
  products: [],
  cartData: [],
  featured_products: [],
  best_selling_products: [],
}

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_PRODUCTS:
      var cart_data = store.get('cartData') == undefined ? [] : store.get('cartData')
      if (action.items.length > 0) {
        var index = cart_data.findIndex(x => x.company_id != action.items[0]['company_id'])

        if (index != -1) {
          store.set('cartData', [])
        }
      }

      return { ...state, products: action.items }

    case actions.UPDATE_FEATURED_PRODUCTS:
      var cart_data = store.get('cartData') == undefined ? [] : store.get('cartData')
      if (action.items.length > 0) {
        var index = cart_data.findIndex(x => x.company_id != action.items[0]['company_id'])

        if (index != -1) {
          store.set('cartData', [])
        }
      }

      return { ...state, featured_products: action.items }

    case actions.UPDATE_BEST_SELLING_PRODUCTS:
      var cart_data = store.get('cartData') == undefined ? [] : store.get('cartData')
      if (action.items.length > 0) {
        var index = cart_data.findIndex(x => x.company_id != action.items[0]['company_id'])

        if (index != -1) {
          store.set('cartData', [])
        }
      }

      return { ...state, best_selling_products: action.items }

    case actions.ADD_TO_CART:
      var id = action.product_id
      var variant_id = action.variant_id

      var index = state.products.findIndex(x => x['product_id'] == id)
      var featured_index = state.featured_products.findIndex(x => x['product_id'] == id)
      var best_selling_index = state.best_selling_products.findIndex(x => x['product_id'] == id)

      if (index != -1) {
        var item = state.products[index]
      } else if (featured_index != -1) {
        var item = state.featured_products[featured_index]
      } else if (best_selling_index != -1) {
        var item = state.best_selling_products[best_selling_index]
      }

      var cart_data = store.get('cartData') == undefined ? [] : store.get('cartData')
      var cart_index = cart_data.findIndex(
        x => x['new_id'] == String(id) + '_' + String(variant_id),
      )

      if (variant_id != 0) {
        var variant_index = item.variants.findIndex(x => x['id'] == variant_id)
        item.variants[variant_index].qty += 1
        item.variants[variant_index].net_amount = getNetAmount({
          ...item.variants[variant_index],
          tax: item.tax,
          discount: item.discount,
        })
        item.variants[variant_index].tax_amount = getTaxAmount({
          ...item.variants[variant_index],
          tax: item.tax,
          discount: item.discount,
        })
        item.variants[variant_index].total_amount = getRowTotal({
          ...item.variants[variant_index],
          tax: item.tax,
          discount: item.discount,
        })
        var varient_item = {
          ...item,
          ...item.variants[variant_index],
          new_id: String(id) + '_' + String(variant_id),
          product_name: item.product_name,
          variant_name: item.variants[variant_index].name,
          variant_id: variant_id,
        }
        store.set('cartData', [...store.get('cartData'), varient_item])
      } else {
        cart_index != -1 ? (item.qty += 1) : (item.qty = 1)
        item.net_amount = getNetAmount(item)
        item.tax_amount = getTaxAmount(item)
        item.total_amount = getRowTotal(item)
        store.set('cartData', [
          ...store.get('cartData'),
          { ...item, new_id: String(id) + '_' + String(variant_id) },
        ])
      }

      var newProducts = [...state.products]
      if (index != -1) {
        newProducts[index] = item
      }

      var new_featured_products = [...state.featured_products]
      if (featured_index != -1) {
        new_featured_products[featured_index] = item
      }

      var new_best_selling_products = [...state.best_selling_products]
      if (best_selling_index != -1) {
        new_best_selling_products[best_selling_index] = item
      }

      return {
        ...state,
        cartData: store.get('cartData'),
        products: [...newProducts],
        featured_products: [...new_featured_products],
        best_selling_products: [...new_best_selling_products],
      }

    case actions.PLUS:
      var id = action.product_id
      var variant_id = action.variant_id

      var index = state.products.findIndex(x => x['product_id'] == id)
      var featured_index = state.featured_products.findIndex(x => x['product_id'] == id)
      var best_selling_index = state.best_selling_products.findIndex(x => x['product_id'] == id)

      if (index != -1) {
        var item = state.products[index]
      } else if (featured_index != -1) {
        var item = state.featured_products[featured_index]
      } else if (best_selling_index != -1) {
        var item = state.best_selling_products[best_selling_index]
      }

      var cart_data = store.get('cartData') == undefined ? [] : store.get('cartData')
      var cart_index = store
        .get('cartData')
        .findIndex(x => x['new_id'] == String(id) + '_' + String(variant_id))
      var varient_item = store.get('cartData')[cart_index]
      if (item == undefined) {
        item = varient_item
      }

      var newCartData = [...store.get('cartData')]

      if (variant_id != 0) {
        var variant_index = item.variants.findIndex(x => x['id'] == variant_id)
        if (item.variants[variant_index].qty > 999) {
          message.warning('Please contact Store Owner directly')
        } else {
          item.variants[variant_index].qty = parseFloat(item.variants[variant_index].qty)
          item.variants[variant_index].qty += 1
          item.variants[variant_index].net_amount = getNetAmount({
            ...item.variants[variant_index],
            tax: item.tax,
            discount: item.discount,
          })
          item.variants[variant_index].tax_amount = getTaxAmount({
            ...item.variants[variant_index],
            tax: item.tax,
            discount: item.discount,
          })
          item.variants[variant_index].total_amount = getRowTotal({
            ...item.variants[variant_index],
            tax: item.tax,
            discount: item.discount,
          })

          var varient_item = {
            ...item,
            ...item.variants[variant_index],
            new_id: String(id) + '_' + String(variant_id),
            product_name: item.product_name,
            variant_name: item.variants[variant_index].name,
            variant_id: variant_id,
          }

          newCartData[cart_index] = varient_item
          store.set('cartData', [...newCartData])
        }
      } else {
        if (item.qty > 999) {
          message.warning('Please contact Store Owner directly')
        } else {
          item.qty = parseFloat(item.qty)
          item.qty += 1
          item.net_amount = getNetAmount(item)
          item.tax_amount = getTaxAmount(item)
          item.total_amount = getRowTotal(item)

          newCartData[cart_index] = { ...item, new_id: String(id) + '_' + String(variant_id) }
          store.set('cartData', [...newCartData])
        }
      }

      var newProducts = [...state.products]
      if (index != -1) {
        newProducts[index] = item
      }

      var new_featured_products = [...state.featured_products]
      if (featured_index != -1) {
        new_featured_products[featured_index] = item
      }

      var new_best_selling_products = [...state.best_selling_products]
      if (best_selling_index != -1) {
        new_best_selling_products[best_selling_index] = item
      }

      return {
        ...state,
        cartData: [...newCartData],
        products: [...newProducts],
        featured_products: [...new_featured_products],
        best_selling_products: [...new_best_selling_products],
      }

    case actions.MINUS:
      var id = action.product_id
      var variant_id = action.variant_id

      var index = state.products.findIndex(x => x['product_id'] == id)
      var featured_index = state.featured_products.findIndex(x => x['product_id'] == id)
      var best_selling_index = state.best_selling_products.findIndex(x => x['product_id'] == id)

      if (index != -1) {
        var item = state.products[index]
      } else if (featured_index != -1) {
        var item = state.featured_products[featured_index]
      } else if (best_selling_index != -1) {
        var item = state.best_selling_products[best_selling_index]
      }

      var cart_data = store.get('cartData') == undefined ? [] : store.get('cartData')
      var cart_index = store
        .get('cartData')
        .findIndex(x => x['new_id'] == String(id) + '_' + String(variant_id))
      var varient_item = store.get('cartData')[cart_index]

      if (item == undefined) {
        item = varient_item
      }

      var newCartData = [...store.get('cartData')]
      if (variant_id != 0) {
        var variant_index = item.variants.findIndex(x => x['id'] == variant_id)
        if (item.variants[variant_index].qty <= 1) {
          item.variants[variant_index].qty = 0

          newCartData = cart_data.filter(
            item => item.new_id != String(id) + '_' + String(variant_id),
          )
          store.set(
            'cartData',
            cart_data.filter(item => item.new_id != String(id) + '_' + String(variant_id)),
          )
        } else {
          item.variants[variant_index].qty = parseFloat(item.variants[variant_index].qty)
          item.variants[variant_index].qty -= 1
          item.variants[variant_index].net_amount = getNetAmount({
            ...item.variants[variant_index],
            tax: item.tax,
            discount: item.discount,
          })
          item.variants[variant_index].tax_amount = getTaxAmount({
            ...item.variants[variant_index],
            tax: item.tax,
            discount: item.discount,
          })
          item.variants[variant_index].total_amount = getRowTotal({
            ...item.variants[variant_index],
            tax: item.tax,
            discount: item.discount,
          })

          var varient_item = {
            ...item,
            ...item.variants[variant_index],
            new_id: String(id) + '_' + String(variant_id),
            product_name: item.product_name,
            variant_name: item.variants[variant_index].name,
            variant_id: variant_id,
          }

          newCartData[cart_index] = varient_item
          store.set('cartData', [...newCartData])
        }
      } else {
        if (item.qty <= 1) {
          item.qty = 0

          newCartData = cart_data.filter(
            item => item.new_id != String(id) + '_' + String(variant_id),
          )
          store.set('cartData', [...newCartData])
        } else {
          item.qty = parseFloat(item.qty)
          item.qty -= 1
          item.net_amount = getNetAmount(item)
          item.tax_amount = getTaxAmount(item)
          item.total_amount = getRowTotal(item)

          newCartData[cart_index] = { ...item, new_id: String(id) + '_' + String(variant_id) }
          store.set('cartData', [...newCartData])
        }
      }

      var newProducts = [...state.products]
      if (index != -1) {
        newProducts[index] = item
      }

      var new_featured_products = [...state.featured_products]
      if (featured_index != -1) {
        new_featured_products[featured_index] = item
      }

      var new_best_selling_products = [...state.best_selling_products]
      if (best_selling_index != -1) {
        new_best_selling_products[best_selling_index] = item
      }

      return {
        ...state,
        cartData: [...newCartData],
        products: [...newProducts],
        featured_products: [...new_featured_products],
        best_selling_products: [...new_best_selling_products],
      }

    case actions.CHANGE:
      var id = action.product_id
      var variant_id = action.variant_id
      var text = action.text

      var index = state.products.findIndex(x => x['product_id'] == id)
      var featured_index = state.featured_products.findIndex(x => x['product_id'] == id)
      var best_selling_index = state.best_selling_products.findIndex(x => x['product_id'] == id)

      if (index != -1) {
        var item = state.products[index]
      } else if (featured_index != -1) {
        var item = state.featured_products[featured_index]
      } else if (best_selling_index != -1) {
        var item = state.best_selling_products[best_selling_index]
      }

      var cart_data = store.get('cartData') == undefined ? [] : store.get('cartData')
      var cart_index = store
        .get('cartData')
        .findIndex(x => x['new_id'] == String(id) + '_' + String(variant_id))
      var varient_item = store.get('cartData')[cart_index]

      if (item == undefined) {
        item = varient_item
      }

      var newCartData = [...store.get('cartData')]

      if (text > 999) {
        message.warning('Please contact Store Owner directly')
      } else if (isNaN(text)) {
        message.warning('Please enter valid quantity')
      } else {
        if (variant_id != 0) {
          var variant_index = item.variants.findIndex(x => x['id'] == variant_id)

          if (item.variants[variant_index].qty <= 0) {
            item.variants[variant_index].qty = 0

            newCartData = [...store.get('cartData')]
            store.set(
              'cartData',
              cart_data.filter(item => item.new_id != String(id) + '_' + String(variant_id)),
            )
            newCartData = cart_data.filter(
              item => item.new_id != String(id) + '_' + String(variant_id),
            )
          } else {
            item.variants[variant_index].qty = parseFloat(text)
            item.variants[variant_index].net_amount = getNetAmount({
              ...item.variants[variant_index],
              tax: item.tax,
              discount: item.discount,
            })
            item.variants[variant_index].tax_amount = getTaxAmount({
              ...item.variants[variant_index],
              tax: item.tax,
              discount: item.discount,
            })
            item.variants[variant_index].total_amount = getRowTotal({
              ...item.variants[variant_index],
              tax: item.tax,
              discount: item.discount,
            })

            var varient_item = {
              ...item,
              ...item.variants[variant_index],
              new_id: String(id) + '_' + String(variant_id),
              product_name: item.product_name,
              variant_name: item.variants[variant_index].name,
              variant_id: variant_id,
            }

            newCartData[cart_index] = varient_item
            store.set('cartData', [...newCartData])
          }
        } else {
          if (item.qty <= 1) {
            item.qty = 0

            newCartData = [...store.get('cartData')]
            store.set(
              'cartData',
              cart_data.filter(item => item.new_id != String(id) + '_' + String(variant_id)),
            )
            newCartData = cart_data.filter(
              item => item.new_id != String(id) + '_' + String(variant_id),
            )
          } else {
            item.qty = parseFloat(text)
            item.net_amount = getNetAmount(item)
            item.tax_amount = getTaxAmount(item)
            item.total_amount = getRowTotal(item)

            newCartData[cart_index] = { ...item, new_id: String(id) + '_' + String(variant_id) }
            store.set('cartData', [...newCartData])
          }
        }
      }

      var newProducts = [...state.products]
      if (index != -1) {
        newProducts[index] = item
      }

      var new_featured_products = [...state.featured_products]
      if (featured_index != -1) {
        new_featured_products[featured_index] = item
      }

      var new_best_selling_products = [...state.best_selling_products]
      if (best_selling_index != -1) {
        new_best_selling_products[best_selling_index] = item
      }

      return {
        ...state,
        cartData: [...newCartData],
        products: [...newProducts],
        featured_products: [...new_featured_products],
        best_selling_products: [...new_best_selling_products],
      }

    case actions.SELECT_VARIANT:
      var id = action.product_id
      var variant_id = action.variant_id

      var index = state.products.findIndex(x => x['product_id'] == id)
      var featured_index = state.featured_products.findIndex(x => x['product_id'] == id)
      var best_selling_index = state.best_selling_products.findIndex(x => x['product_id'] == id)

      if (index != -1) {
        var item = state.products[index]
        item.variants.map((x, i) =>
          x['id'] == variant_id
            ? (state.products[index].variants[i].selected = 1)
            : (state.products[index].variants[i].selected = 0),
        )
      } else if (featured_index != -1) {
        var item = state.featured_products[featured_index]
        item.variants.map((x, i) =>
          x['id'] == variant_id
            ? (state.featured_products[featured_index].variants[i].selected = 1)
            : (state.featured_products[featured_index].variants[i].selected = 0),
        )
      } else if (best_selling_index != -1) {
        var item = state.best_selling_products[best_selling_index]
        item.variants.map((x, i) =>
          x['id'] == variant_id
            ? (state.best_selling_products[best_selling_index].variants[i].selected = 1)
            : (state.best_selling_products[best_selling_index].variants[i].selected = 0),
        )
      }

      var newProducts = [...state.products]
      if (index != -1) {
        newProducts[index] = item
      }

      var new_featured_products = [...state.featured_products]
      if (featured_index != -1) {
        new_featured_products[featured_index] = item
      }

      var new_best_selling_products = [...state.best_selling_products]
      if (best_selling_index != -1) {
        new_best_selling_products[best_selling_index] = item
      }

      return {
        ...state,
        products: [...newProducts],
        featured_products: [...new_featured_products],
        best_selling_products: [...new_best_selling_products],
      }

    default:
      return state
  }
}
