import { Text, View } from '@react-pdf/renderer'
import { Fragment } from 'react'
import { HIDE_PRICES } from '../utils'
import { styles as getStyles } from './styles'

const borderColor = '#142328'

const InvoiceTableHeader = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size
  const hide_hsn = invoice.invoice_settings.hide_hsn
  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 7 : 8
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.8 : no_of_default_cols
  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)
  var dc_col =
    (1 * 100) / ((invoice.invoice_settings.hide_qty ? 2 : 3) + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (8 * 8)) / (9 + invoice.custom_col_names.length)
  var font_size2 = (FONT_FACTOR * (9 * 15)) / (15 + invoice.custom_col_names.length)

  const rows = (
    <>
      {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
        <>
          <View fixed style={[styles.invoiceTableHeader_container, styles.globalContainer]}>
            <Text
              style={{
                ...styles.invoiceTableHeader_cell,
                textAlign: 'left',
                paddingLeft: 4.5,
                width: `${0.3 * dc_col}%`,
              }}
            >
              {invoice.column_labels.id}
            </Text>
            <Text
              style={{
                ...styles.invoiceTableHeader_cell,
                textAlign: 'left',
                width: `${1.7 * dc_col}%`,
              }}
            >
              {invoice.column_labels.product_name}
            </Text>
            {invoice.custom_col_names.map((item, index) => (
              <Text
                key={index}
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'right',
                  width: `${dc_col}%`,
                }}
              >
                {item}
              </Text>
            ))}
            {invoice.invoice_settings.hide_qty == 0 && (
              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  borderRight: 'none',
                  paddingRight: 4.5,
                  textAlign: 'right',
                  width: `${dc_col}%`,
                }}
              >
                {invoice.column_labels.qty}
              </Text>
            )}
          </View>
        </>
      )}

      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <View fixed style={[styles.invoiceTableHeader_container, styles.globalContainer]}>
          <Text
            style={{
              ...styles.invoiceTableHeader_cell,
              textAlign: 'left',
              fontSize: font_size,
              width: `${0.3 * single_col}%`,
            }}
          >
            {invoice.column_labels.id}
          </Text>
          <Text
            style={{
              ...styles.invoiceTableHeader_cell,
              fontSize: font_size,
              textAlign: 'left',
              width: `${3.1 * single_col}%`,
            }}
          >
            {invoice.column_labels.product_name}
          </Text>

          {invoice.custom_col_names.map((item, index) => (
            <Text
              key={index}
              style={{
                ...styles.invoiceTableHeader_cell,
                textAlign: 'right',
                width: `${single_col}%`,
                fontSize: font_size,
              }}
            >
              {item}
            </Text>
          ))}

          {!hide_hsn && (
            <Text
              style={{
                ...styles.invoiceTableHeader_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${0.8 * single_col}%`,
              }}
            >
              {invoice.invoice_settings.labels.hsn_code}
            </Text>
          )}

          <Text
            style={{
              ...styles.invoiceTableHeader_cell,
              textAlign: 'right',
              fontSize: font_size,
              width: `${0.4 * single_col}%`,
            }}
          >
            Tax
          </Text>
          {invoice.invoice_settings.hide_qty == 0 && (
            <Text
              style={{
                ...styles.invoiceTableHeader_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${0.9 * single_col}%`,
              }}
            >
              {invoice.column_labels.qty}
            </Text>
          )}
          <Text
            style={{
              ...styles.invoiceTableHeader_cell,
              textAlign: 'right',
              fontSize: font_size,
              width: `${1 * single_col}%`,
            }}
          >
            {invoice.column_labels.unit_price}
          </Text>

          <Text
            style={{
              ...styles.invoiceTableHeader_cell,
              textAlign: 'right',
              fontSize: font_size,
              width: `${0.4 * single_col}%`,
            }}
          >
            Per
          </Text>

          <Text
            style={{
              ...styles.invoiceTableHeader_cell,
              textAlign: 'right',
              fontSize: font_size,
              borderRight: 'none',
              paddingRight: 4.5,
              width: `${1.1 * single_col}%`,
            }}
          >
            {invoice.column_labels.total_amount}
          </Text>
        </View>
      )}
    </>
  )
  return <Fragment>{rows}</Fragment>
}

export default InvoiceTableHeader
