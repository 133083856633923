import { Document, Link, Page, StyleSheet, Text } from '@react-pdf/renderer'
import { registerEnglishFont } from 'components/fonts'
import React, { useEffect } from 'react'
import BillTo from './billTo'
import CompanyDetails from './company'
import DocumentTitle from './documentTitle'
const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    paddingTop: 25,
    paddingHorizontal: 30,
    paddingBottom: 40,
    lineHeight: 1.3,
    flexDirection: 'column',
  },
  logo: {
    width: 'auto',
    height: '45',
    position: 'relative',
    marginRight: 'auto',
    textAlign: 'left',
    marginBottom: 5,
  },
  footer: {
    alignContent: 'bottom',
    position: 'absolute',
    fontFamily: 'helmed_English',
    bottom: 20,
    left: 30,
  },
  footerNote: {
    fontSize: 8.1,
    bottom: 10,
    left: 30,
    alignContent: 'bottom',
    position: 'absolute',
    fontFamily: 'helmed_English',
    marginTop: 0,
  },
})

export default function Payment({ data }) {
  useEffect(() => {
    registerEnglishFont('English')
  }, [])

  return (
    <Document>
      <Page
        // orientation={invoice.invoice_settings.pdf_orientation}
        wrap
        size="A4"
        style={styles.page}
      >
        <DocumentTitle title={'Payment Acknowledgement'} color={data.company_details.color} />
        <CompanyDetails invoice={data} />
        <BillTo invoice={data} />
        {/* <DocumentNotes invoice={data} />*/}
        <Link
          href={data.company_details.website}
          target="_blank"
          fixed
          style={{ ...styles.footer, color: data.company_details.color }}
        >
          {data.company_details.invoice_footer}
        </Link>
        <Text fixed style={styles.footerNote}>
          <>This is a computer generated document and requires no signature.</>
        </Text>
      </Page>
    </Document>
  )
}
