import { Button, Modal } from 'antd'
import React, { Component } from 'react'
import { downloadAPI } from 'services/jwt'

export default class ExportModal extends Component {
  constructor(props) {
    super(props)
    this.state = { flag: false, loading: false }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  showModal = () => {
    this.setState({ flag: true })
  }

  onModalChange = e => {
    this.setState({
      value: e.target.value,
    })
  }

  onRangeChange = (date, datesString) => {
    if (datesString[0] != '') this.setState({ date: datesString[0] + ' ' + datesString[1] })
    else this.setState({ date: '' })
  }

  handleDownload = async () => {
    this.setState({ loading: true })
    let req = {}
    if (this.props.menuName == 'inventory') {
      req = {
        warehouse_id: this.props.warehouse_id ?? -1,
      }
    }
    const data = await downloadAPI(this.props.menuName, 'download', req)
    if (data) {
      this.setState({ flag: false, loading: false })
    }
  }

  render() {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    }
    return (
      <Modal
        title="Download Data"
        open={this.state.flag}
        onCancel={() => this.setState({ flag: false })}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        footer={[
          <Button key="back" onClick={() => this.setState({ flag: false })}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={this.handleDownload}
            loading={this.state.loading}
          >
            Download
          </Button>,
        ]}
      >
        <div className="col-12">
          <h6>Download {this.props.menuName == 'product' ? 'Product' : 'Stock'} Template</h6>
        </div>
      </Modal>
    )
  }
}

export class EnhancedExport extends React.Component {
  render() {
    return <ExportModal {...this.props} />
  }
}
