import { Text, View } from '@react-pdf/renderer'

export function mergeItemsWithHeaders(invoice) {
  const { items, document_item_headers } = invoice

  let finalData = []
  let itemIndex = 0
  let headerIndex = 0

  while (itemIndex < items.length || headerIndex < document_item_headers.length) {
    // Check if headers are exhausted or the current item key is less than the current header key
    if (
      headerIndex >= document_item_headers.length ||
      (itemIndex < items.length && itemIndex + 1 < document_item_headers[headerIndex].position)
    ) {
      finalData.push({ ...items[itemIndex++], position: itemIndex - 1 })
    } else {
      finalData.push({ ...document_item_headers[headerIndex++], isHeader: true })
    }
  }

  // Reassign incremental keys
  finalData = finalData.map((item, index) => ({
    ...item,
    key: index + 1,
  }))

  return finalData
}

export default function ItemsHeader({ invoice, styles, i }) {
  const renderHeader = () => (
    <View
      key={i}
      style={{
        ...styles.invoiceItemHeader,
        borderTopWidth: i !== 0 ? 0.5 : 0,
        borderTopColor: invoice.company.color,
        borderBottomColor: invoice.company.color,
      }}
      wrap={true}
    >
      <Text
        style={{
          textAlign: 'left',
          paddingLeft: 4.5,
        }}
      >
        {invoice.itemsWithHeaders[i].header_value}
      </Text>
    </View>
  )

  return renderHeader()
}

export function ItemsHeader2({ invoice, styles, i }) {
  const renderHeader = () => (
    <View
      key={i}
      style={{
        ...styles.invoiceItemHeader,
        borderTopWidth: i !== 0 ? 0.5 : 0,
        borderBottomWidth: 0.5,
        borderTopColor: invoice.company.color,
        borderBottomColor: invoice.company.color,
      }}
      wrap={true}
    >
      <Text
        style={{
          textAlign: 'left',
          paddingLeft: 4.5,
        }}
      >
        {invoice.itemsWithHeaders[i].header_value}
      </Text>
    </View>
  )

  return (
    <View
      style={[
        styles.invoiceTableRow_row,
        styles.globalContainer,
        { borderLeftWidth: 0, borderRightWidth: 0 },
      ]}
    >
      {renderHeader()}
    </View>
  )
}
