import { Button, Popover, Spin, Table, Tooltip } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { getAPI } from 'services/jwt'

const email_status_colors = {
  Processing: 'warning',
  dropped: 'danger',
  bounced: 'danger',
  delivered: 'success',
  deferred: 'danger',
  open: 'success',
  click: 'success',
  processed: 'warning',
  blocked: 'danger',
}

export const EmailStatus = props => {
  const [email_status, setEmailStatus] = useState([])
  const [emailStatusLoading, setEmailStatusLoading] = useState(false)

  const getEmailStatus = async doc_count => {
    setEmailStatusLoading(true)
    const email_status = await getAPI('utils', 'get_email_status', {
      doc_count: doc_count,
      serial_number: props.serial_number,
      document_type: props.document_type,
    })
    if (email_status.success) {
      setEmailStatus(email_status.data)
      //   props.onData(email_status.data)
    }
    setEmailStatusLoading(false)
  }

  useEffect(() => {
    if (props.showStatusTable) {
      getEmailStatus(props.doc_count)
    }
  }, [props.showStatusTable])

  const emailStatusColumns = [
    {
      title: 'To',
      dataIndex: 'email',
      key: 'email',
      render: text => <span className="font-size-14">{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <Button
          type={email_status_colors[record.status]}
          size="small"
          className="mb-0 font-size-14 font-weight-bold mr-1"
        >
          {record.status}
        </Button>
      ),
    },
    {
      title: 'Updated Time',
      dataIndex: 'updated_time',
      key: 'Updated_time',
      render: (_, record) => (
        <span className="font-size-14">
          {dayjs(record.updated_time, 'ddd, DD MMM YYYY HH:mm:ss')
            .add(5.5, 'hours')
            .format('MMM DD YYYY, h:mm:ss a')}
        </span>
      ),
    },
  ]

  return (
    <>
      {props.showStatusTable ? (
        <Table columns={emailStatusColumns} dataSource={email_status} size="small" />
      ) : (
        <Tooltip title="Click to check E-mail status">
          <Popover
            trigger="click"
            placement="right"
            onOpenChange={visible => {
              if (visible) {
                setEmailStatusLoading(true)
                getEmailStatus()
              } else {
                setEmailStatusLoading(false)
              }
            }}
            onClick={e => {
              e.stopPropagation()
            }}
            content={
              <div>
                {emailStatusLoading && <Spin />}
                {!emailStatusLoading && (
                  <div className="mt-2">
                    <Table columns={emailStatusColumns} dataSource={email_status} size="small" />
                  </div>
                )}
              </div>
            }
          >
            {props.emails_status == 2 && (
              <span>
                <i className="fa-regular fa-envelope-dot ml-2 text-danger"></i>
              </span>
            )}
            {props.emails_status == 1 && (
              <span>
                <i className="fa-regular fa-envelope-circle-check ml-2 text-forest"></i>
              </span>
            )}
            {props.emails_status == 0 && (
              <span>
                <i className="fa-regular fa-envelope ml-2 text-gray-500"></i>
              </span>
            )}
          </Popover>
        </Tooltip>
      )}
    </>
  )
}
