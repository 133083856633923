import { combineReducers } from 'redux'
import agent from './agent/reducer'
import bulkedit from './bulkEdit/reducer'
import cart from './cart/reducers'
import document from './document/reducer'
import menu from './menu/reducers'
import permissions from './permissions/reducers'
import settings from './settings/reducers'
import user from './user/reducers'
import warehouse from './warehouses/reducer'
import welcome from './welcome/reducers'
import countries from './countries/reducers'

export default routerReducer =>
  combineReducers({
    router: routerReducer,
    user,
    menu,
    settings,
    welcome,
    cart,
    permissions,
    document,
    bulkedit,
    agent,
    warehouse,
    countries,
  })
