import {
  SET_ALL_USERS,
  SET_DEFAULT_WAREHOUSE,
  SET_WAREHOUSES,
  SET_WAREHOUSE_PERMISSIONS,
  SET_WAREHOUSE_ROLES,
  SET_INVENTORY_CATEGORIES,
  SET_ALL_WAREHOUSES,
} from './types'
const initialState = {
  warehouses: undefined,
  all_users: [],
  warehouse_roles: [],
  labels: [],
  warehouse_permissions: {},
  default_warehouse_id: undefined,
  inventory_categories: undefined,
  stock_in_categories: ['New', 'Return', 'Miscellaneous'],
  stock_out_categories: ['Miscellaneous', 'Damaged', 'Expired', 'Lost', 'Recalled'],
  all_warehouses: [],
}
export default function wareHouseReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WAREHOUSES:
      return {
        ...state,
        warehouses: action.payload,
      }
    case SET_ALL_WAREHOUSES:
      return {
        ...state,
        all_warehouses: action.payload,
      }
    case SET_ALL_USERS:
      return {
        ...state,
        all_users: action.payload,
      }
    case SET_WAREHOUSE_ROLES:
      return {
        ...state,
        warehouse_roles: action.payload.roles,
        labels: action.payload.labels,
      }

    case SET_WAREHOUSE_PERMISSIONS:
      return {
        ...state,
        warehouse_permissions: action.payload,
      }
    case SET_DEFAULT_WAREHOUSE:
      return {
        ...state,
        default_warehouse_id: action.payload,
      }
    case SET_INVENTORY_CATEGORIES:
      return {
        ...state,
        inventory_categories: action.payload,
      }
    default:
      return { ...state }
  }
}
