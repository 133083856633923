import React, { Component } from 'react'

import { Button, Col, Drawer, Modal, Popover, Row, Select, message } from 'antd'
import InfoComponent from 'components/badges/InfoComponent'
import ManageColumns from 'components/other/manageColumns'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import _, { wrap } from 'lodash'
import { connect } from 'react-redux'
import store from 'store'
import { getAPI } from 'services/jwt'
dayjs.extend(utc)

// check if mobile device
const isMobile = window.innerWidth <= 500

class ReportSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openDrawer: false,
      report_settings: this.props.report_settings,
      confirmModal: false,
      purchase_price_type: this.props.purchase_price_type,
      showSettings: true,
      showPurchasePriceReport: this.props.showPurchasePriceReport,
    }

    this.onChangeColumns = _.debounce(this._onChangeColumns, 1000, { trailing: true })
  }
  componentDidMount() {
    if (!this.state.purchase_price_type) {
      this.setState({ showSettings: false })
    }

    if (
      !this.state.showPurchasePriceReport &&
      store.get('reportsPurchasePriceType') == 'purchase'
    ) {
      store.set('reportsPurchasePriceType', 'current')
    }
  }

  _onChangeColumns = checkedValues => {
    const { columns } = this.props
    const newColumns = columns.filter(column => checkedValues.includes(column.dataIndex))
    this.setState({ columns: newColumns }, () => {
      this.onColumnsSave()
    })
  }

  onColumnsSave = async () => {
    const { columns } = this.state

    const req = {
      type: this.props.type,
      columns: columns,
    }
    const data = await getAPI('v2/reports', 'get_columns', req)
    if (data?.success) {
      message.success('Columns updated successfully')
    }

    this.props.updateCurrentColumns(columns)
  }

  render() {
    let windowWidth = window.innerWidth

    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    var purchase_price_type_options = [
      { value: 'sale', label: 'Purchase Price at Sale' },
      { value: 'current', label: 'Current Purchase Price' },
    ]
    if (this.state.showPurchasePriceReport) {
      purchase_price_type_options = [
        { value: 'sale', label: 'Purchase Price at Sale' },
        { value: 'current', label: 'Current Purchase Price' },
        { value: 'purchase', label: 'Purchase Report' },
      ]
    }

    return (
      <>
        {/* {windowWidth >= 1600 && this.props.columns.length > 0 && (
          <Popover
            content={
              <ManageColumns
                onChange={columns => this.onChangeColumns(columns)}
                columns={this.props.columns}
                onSave={this.onColumnsSave}
              />
            }
            trigger={['click']}
            placement="leftBottom"
          >
          <Button
            type="primary"
            className="mr-2"
            style={{
              border: '1px solid #d9d9d9',
            }}
            onClick={() => this.props.onManageColumns()}
          >
            <span>
              <i className="fa-solid font-weight-bold fa-sliders mr-2"></i>
              Manage Columns
              <i className="fa-regular fa-chevron-down ml-2  text" />
            </span>
          </Button>
          </Popover>
        )} */}

        {Object.keys(this.props.tooltips).length > 0 && (
          <Popover
            // defaultOpen={
            //   store.get('reportsTooltips') == undefined
            //     ? true
            //     : store.get('reportsTooltips')
            // }
            // placement="bottomRight"
            onOpenChange={e => {
              if (!e) {
                store.set('reportsTooltips', false)
              }
            }}
            overlayStyle={{ width: isMobile ? '300px' : 'wrap-content', padding: '0px' }}
            content={
              <div className="flex flex-col" style={{ overflow: 'scroll', maxHeight: '60vh' }}>
                {Object.keys(this.props.tooltips).map((tooltip, index) => (
                  <Row key={index} className="mb-2 px-2 " gutter={[8, 8]} wrap={true}>
                    <Col
                      className="mt-2"
                      xs={{
                        span: 24,
                      }}
                      sm={{
                        span: 18,
                      }}
                      md={{
                        span: 12,
                      }}
                    >
                      <span className="font-weight-bold">{tooltip} :</span>
                    </Col>
                    <Col
                      className="mt-2"
                      xs={{
                        span: 24,
                      }}
                      sm={{
                        span: 18,
                      }}
                      md={{
                        span: 12,
                      }}
                    >
                      {this.props.tooltips[tooltip]}
                    </Col>
                  </Row>
                ))}
                <div className="font-size-14 text-muted ">
                  <InfoComponent
                    title="Please note"
                    description={
                      <>
                        <ul className="text-gray-500 pl-3 font-size-14 list-disc list-inside">
                          <li className="">
                            *The "Purchase Price at Sale" refers to the original purchase price of
                            the <br />
                            product when it was sold. This value doesn't change unless any
                            modifications <br />
                            made to the item in the invoice.
                          </li>

                          <li>
                            *Profit and loss calculations are contingent upon the specific purchase
                            and sale <br></br> prices recorded in invoices or purchases at the time
                            of their creation.
                            <br></br>The provided reports are intended for informational purposes
                            solely <br></br> and should not be construed as definitive
                            representations of financial outcomes.
                          </li>
                          <li>
                            *Current Purchase Price is the current purchase price of the product. It
                            can be <br></br> edited from the product page.
                          </li>
                        </ul>
                      </>
                    }
                    type="info"
                  />
                  {/* <Col flex="2" className="font-weight-bold">
                    Note:
                  </Col>

                  <Col flex="2">
                    {' '}
                    *The "Purchase Price at Sale" refers to the original purchase price of the{' '}
                    <br></br>
                    product when it was sold. This value doesn't change unless any modifications
                    <br></br>
                    made to the item in the invoice.
                  </Col>

                  <Col flex="2">
                    {' '}
                    *Profit and loss calculations are contingent upon the specific purchase and sale
                    <br></br>
                    prices recorded in invoices or purchases at the time of their creation.<br></br>
                    <br></br> The provided reports are intended for informational purposes solely
                    <br></br>
                    and should not be construed as definitive representations of financial outcomes.
                  </Col>

                  <Col flex="2">
                    {' '}
                    *Current Purchase Price is the current purchase price of the product. It can be
                    <br></br>
                    edited from the product page.
                  </Col> */}
                </div>
              </div>
            }
            trigger={['click']}
            title="Column Description"
            placement="leftBottom"
            zIndex={3000}
          >
            <i
              className="fa-solid fa-info-circle ml-3 mr-2 text-primary mt-2 font-size-21 mb-2"
              style={{ cursor: 'pointer' }}
            />
          </Popover>
        )}
        {(this.state.showSettings || windowWidth < 1600) && this.props.purchase_price_type && (
          <Popover
            defaultOpen={this.state.report_settings}
            onOpenChange={e => {
              if (!e) {
                store.set('reportsSettings', false)
                this.setState({ report_settings: false })
              }
            }}
            title={'Report Settings'}
            content={
              // <Card
              //   title={' Report Settings'}
              //   headStyle={{ backgroundColor: '#f0f2f5' }}
              //   bodyStyle={{ overflowY: 'scroll' }}
              //   className="flex flex-col "
              //   width="100px"
              // >
              <>
                <>
                  {this.props.purchase_price_type && (
                    <Row key="1" className="mb-2 px-2 " gutter={[48, 8]}>
                      <Col
                        className="mt-2"
                        xs={{
                          span: 24,
                        }}
                        sm={{
                          span: 18,
                        }}
                        md={{
                          span: 12,
                        }}
                      >
                        <span className="font-weight-bold">Profit Loss Calculation :</span>
                      </Col>
                      <Col
                        className="mt-2"
                        xs={{
                          span: 24,
                        }}
                        sm={{
                          span: 18,
                        }}
                        md={{
                          span: 12,
                        }}
                      >
                        {' '}
                        <Select
                          style={{ width: wrap(windowWidth < 768 ? '100%' : '50%', 'px') }}
                          defaultValue={this.state.purchase_price_type}
                          className="mr-2"
                          onChange={value => {
                            value == 'sale'
                              ? this.setState({ confirmModal: true, purchase_price_type: value })
                              : value == 'purchase'
                              ? this.props.onChangePurchase('category_wise_profit_loss')
                              : this.props.onChange(value)
                          }}
                          options={purchase_price_type_options}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              </>
            }
            trigger={['click']}
            placement="bottom"
            overlayStyle={{ width: 450, padding: '0px' }}
          >
            {/* Settings */}

            <i
              className="fa-duotone fa-cog ml-3 mr-2 text-gray-400 font-size-21 mb-2"
              style={{ cursor: 'pointer' }}
            />
          </Popover>
        )}
        {/* {(this.state.showSettings || windowWidth < 1600) && this.props.columns.length > 0 && (
          <Button
            type="text"
            className="mr-2"
            style={{
              border: '1px solid #d9d9d9',
            }}
            onClick={() => this.props.onManageColumns()}
          >
            <span>
              <i class="fa-solid fa-list-check "></i>
            </span>
          </Button>
        )} */}

        <Modal
          title="Confirmation"
          open={this.state.confirmModal}
          onOk={() => {
            this.props.onChange(this.state.purchase_price_type)
            this.setState({ confirmModal: false })
          }}
          onCancel={() => this.setState({ confirmModal: false, purchase_price_type: 'current' })}
          zIndex={99999}
        >
          <p className="font-weight-bold">
            Profit Loss calculation using purchase price at sale is available on invoices created
            after 22 July 2023.
          </p>

          <p>Are you sure you want to change the Profit Loss Calculation?</p>
        </Modal>
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    className: ownProps.className ? ownProps.className : 'inline-flex mt-2',
  }
}

export default connect(mapStateToProps)(ReportSettings)
