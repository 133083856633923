import store from 'store'
import actions from './actions'

const STORED_SETTINGS = storedSettings => {
  const settings = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' && key != 'logo' ? item : storedSettings[key]
  })
  return settings
}

const initialState = {
  ...STORED_SETTINGS({
    authProvider: 'jwt', // firebase, jwt
    logo: '/resources/images/logo-w.svg',
    locale: 'English',
    pdf_locale: 'English',
    isSidebarOpen: false,
    isSupportChatOpen: false,
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    menuLayoutType: 'left', // left, top, nomenu
    routerAnimation: 'zoom-fadein', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
    menuColor: '#ffffff', // white, f7fafc, gray
    theme: 'default', // default, dark
    authPagesColor: '#f7fafc', // white, gray, image
    primaryColor: '#2754FF',
    leftMenuWidth: 216,
    isMenuUnfixed: false,
    isMenuShadow: false,
    isTopbarFixed: true,
    isGrayTopbar: false,
    isContentMaxWidth: false,
    isAppMaxWidth: false,
    isGrayBackground: false,
    isCardShadow: true,
    isSquaredBorders: false,
    isBorderless: false,
    footer: 1,
    company_id: -1,
    globalSearchFocus: false,
  }),
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_FOOTER:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
