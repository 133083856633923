import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import {
  Button,
  Card,
  Drawer,
  Table,
  Select,
  Radio,
  Space,
  message,
  Popconfirm,
  Tooltip,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'

const { Option } = Select

const MAX_ALLOWED_TAX_RATES = 3

interface TaxRate {
  tax: number | null
  is_default: boolean
}

interface MultipleTaxRateFormProps {
  onAPICalled: (taxRates: TaxRate[]) => void
  selectedCompany: any
  integrations: any
  allTaxRates: number[]
}

export interface MultipleTaxRateFormRef {
  openDrawer: (initialRates: TaxRate[]) => void
  closeDrawer: () => void
  submitForm: () => void
}

const MultipleTaxRateForm = forwardRef<MultipleTaxRateFormRef, MultipleTaxRateFormProps>(
  ({ onAPICalled, selectedCompany, allTaxRates, integrations }, ref) => {
    const [visible, setVisible] = useState(false)
    const [dataSource, setDataSource] = useState<TaxRate[]>([])
    const [defaultKey, setDefaultKey] = useState<number | null>(null)
    const [taxRateOptions, setTaxRateOptions] = useState<number[]>(allTaxRates)

    useImperativeHandle(ref, () => ({
      openDrawer,
      closeDrawer,
      submitForm,
    }))

    const openDrawer = (initialRates: TaxRate[]) => {
      if (initialRates.length < MAX_ALLOWED_TAX_RATES) {
        initialRates = [...initialRates, { tax: null, is_default: false }]
      }

      setDataSource(initialRates)
      setDefaultKey(initialRates.findIndex(item => item.is_default) ?? null)
      setVisible(true)
    }

    const closeDrawer = () => setVisible(false)

    const submitForm = () => {
      const invalidRates = dataSource.some(item => item.tax === null || item.tax === undefined)

      if (invalidRates) {
        message.error('Tax rate cannot be empty.')
        return
      }

      onAPICalled(dataSource)
      setVisible(false)
      message.success('Tax rates saved successfully!')
    }

    const handleTaxChange = (value: number, index: number) => {
      const updatedData = [...dataSource]
      updatedData[index].tax = value
      setDataSource(updatedData)
    }

    const handleAddRow = () => {
      if (dataSource.length >= MAX_ALLOWED_TAX_RATES) {
        message.warning(`Max ${MAX_ALLOWED_TAX_RATES} tax rates allowed.`)
        return
      }

      setDataSource([...dataSource, { tax: null, is_default: false }])
    }

    const handleRemoveRow = (index: number) => {
      if (dataSource.length === 1) {
        message.warning('At least one tax rate is required.')
        return
      }

      const updatedData = dataSource?.filter((_, idx) => idx !== index)

      // Re-calculate the default key
      const defaultIdx = updatedData.findIndex(item => item.is_default)
      if (defaultIdx === -1) {
        updatedData[0].is_default = true
        setDefaultKey(0)
      } else {
        setDefaultKey(defaultIdx)
      }

      setDataSource(updatedData)
    }

    const handleDefaultChange = (index: number) => {
      setDefaultKey(index)
      const updatedData = dataSource?.map((item, idx) => ({
        ...item,
        is_default: idx === index,
      }))
      setDataSource(updatedData)
    }

    // Filter out used taxRates from the taxRateOptions
    useEffect(() => {
      const usedTaxRates = dataSource?.map(item => item.tax != null && Number(item.tax))
      const filteredOptions = allTaxRates?.filter(item => !usedTaxRates.includes(Number(item)))
      setTaxRateOptions(filteredOptions)
    }, [dataSource])

    useEffect(() => {
      if (!integrations?.multi_tax) {
        setVisible(false)
      }
    })

    const columns = [
      {
        title: 'Tax Rate (%)',
        dataIndex: 'rate',
        width: '50%',
        render: (text: number, record: TaxRate, index: number) => (
          <Select
            placeholder="Select Tax Rate"
            value={record.tax}
            onChange={value => handleTaxChange(value, index)}
            style={{ width: '100%' }}
          >
            {taxRateOptions?.map((rate, i) => (
              <Option key={i} value={rate}>
                <div className="flex justify-between">
                  <span
                    className="font-weight-bold"
                    style={{
                      width: '30px',
                    }}
                  >
                    {rate}
                  </span>
                  {selectedCompany?.country_code == 'IN' && (
                    <span className="text-gray-500">
                      ({rate / 2}% CGST & {rate / 2}% SGST, {rate}% IGST)
                    </span>
                  )}
                </div>
              </Option>
            ))}
          </Select>
        ),
      },
      {
        title: 'Default',
        dataIndex: 'is_default',
        render: (_: boolean, __: TaxRate, index: number) => (
          <Radio checked={defaultKey === index} onChange={() => handleDefaultChange(index)} />
        ),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_: any, __: TaxRate, index: number) => (
          <Space size="middle">
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => handleRemoveRow(index)}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<i className="fa-solid fa-trash text-red-600" />} type="text" danger />
            </Popconfirm>
          </Space>
        ),
      },
    ]

    return (
      <Drawer
        title={<strong>Add Multiple Tax Rates</strong>}
        extra={
          <Space>
            <Button onClick={submitForm} type="primary" className="mr-2 arrow-transition">
              Save
              <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
            </Button>
          </Space>
        }
        width={isMobile ? '100%' : '45%'}
        height={isMobile ? '90%' : '100%'}
        placement={isMobile ? 'top' : 'right'}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        destroyOnClose
        onClose={closeDrawer}
        visible={visible}
        footer={
          <>
            <Button onClick={submitForm} type="primary" className="mr-2 arrow-transition">
              Save
              <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
            </Button>

            <Button onClick={closeDrawer} type="danger">
              Close
            </Button>
          </>
        }
      >
        <Card>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowKey={(_, index: any) => index.toString()}
            footer={() => (
              <Tooltip
                title={
                  dataSource.length >= MAX_ALLOWED_TAX_RATES
                    ? `Max ${MAX_ALLOWED_TAX_RATES} tax rates allowed.`
                    : ''
                }
              >
                <Button
                  type="dashed"
                  onClick={handleAddRow}
                  block
                  disabled={dataSource.length >= MAX_ALLOWED_TAX_RATES}
                  icon={<PlusOutlined />}
                >
                  Add Tax Rate
                </Button>
              </Tooltip>
            )}
          />
        </Card>
      </Drawer>
    )
  },
)

function mapStateToProps(state: any) {
  return {
    allTaxRates: state.document.tax_rates,
    user: state.user,
    integrations: state.permissions.integrations,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(MultipleTaxRateForm)
