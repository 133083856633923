import { getAPI } from 'services/jwt'

class InventoryManager {
  constructor() {}

  static async getBOM(product_id, variant_id, warehouse_id = -1) {
    let products = []
    const req = {
      product_id: product_id,
      variant_id: variant_id,
      warehouse_id: warehouse_id,
    }
    const data = await getAPI('product', 'get_bom', req)
    if (data?.success) {
      products = data.data.map(item => {
        return {
          ...item,
          id: String(item.product_id) + '_' + String(item.variant_id),
          value: item.product_name,
          conversion_rate:
            item.unit_id == 0
              ? 1
              : item.units.filter(unit => unit.unit_id == item.unit_id)[0]?.conversion_rate,
          unit:
            item.unit_id == 0
              ? item.unit
              : item.units.filter(unit => unit.unit_id == item.unit_id)[0]?.alternative_unit,
        }
      })
    }
    return products
  }
}

export default InventoryManager
