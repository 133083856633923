import { Affix, Divider, Table, Timeline } from 'antd'
import { UserAvatar } from './utility'
import InfoComponent from 'components/badges/InfoComponent'
import ShowMoreText from 'react-show-more-text'
import Visibility from './visibility'
import store from 'store'

interface auditHistoryType {
  action: string
  change_log: string
  changes: {
    action: string
    col: string
    from: string
    to: string
    type: string
  }[]
  message: string
  record_time: string
  user_name: string
}

export default function AuditHistory(props: { auditHistory: auditHistoryType[] }) {
  const { auditHistory } = props

  const updateStrings = ['update', 'edit']
  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      width: '25%',
      render: (text: string) => <span className="font-weight-bold">{text}</span>,
    },
    {
      title: 'Field',
      dataIndex: 'col',
      width: '10%',
    },
    {
      title: 'Old Value',
      dataIndex: 'from',
      width: '20%',

      render: (text: string, data: auditHistoryType['changes'][number]) => {
        return {
          props: {
            className: 'bg-negative',
          },
          children: <span>{text}</span>,
        }
      },
    },
    {
      title: 'New Value',
      dataIndex: 'to',
      width: '20%',

      render: (text: string, data: auditHistoryType['changes'][number]) => {
        return {
          props: {
            className: 'bg-positive',
          },
          children: <span>{text}</span>,
        }
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '10%',
    },
  ]
  return (
    <div>
      <InfoComponent
        description="Only changes made after May 15 2024, will be displayed in the audit history."
        type="info"
      />
      {auditHistory.length > 0 ? (
        <Timeline className="p-4 mt-5" style={{ overflowY: 'auto', maxHeight: '50vh' }}>
          {auditHistory.map((activity, index) => (
            <>
              {activity.message !== '' && (
                <Timeline.Item
                  className="mb-2"
                  key={index}
                  dot={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <UserAvatar name={activity.user_name} />
                    </div>
                  }
                >
                  <>
                    <div
                      key={index}
                      className="ml-1"
                      style={
                        store.get('paid') == 0 && index > 1
                          ? {
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              filter: 'blur(5px)',
                            }
                          : {
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }
                      }
                    >
                      <div>
                        <span className="mb-1 font-weight-medium">{activity.message}</span>

                        {activity.change_log != '' && (
                          <p className="text-gray-500 font-size-14 mb-1">
                            {updateStrings.includes(activity.action.toLowerCase()) && (
                              <span className="text-gray-400">Changes in: </span>
                            )}

                            {activity.change_log}
                          </p>
                        )}
                      </div>
                      <p className="font-size-14 text-gray-500 mb-1 float-top">
                        {activity.record_time}
                      </p>
                    </div>
                    {updateStrings.includes(activity.action.toLowerCase()) && (
                      <ShowMoreText
                        lines={1}
                        more={<p className="float-right">Show more</p>}
                        less={<p className="float-right">Show less</p>}
                        className="font-size-12 mb-1"
                        anchorClass="my-anchor-css-class cursor-pointer"
                        truncatedEndingComponent={' '}
                        expanded={false}
                      >
                        <br />
                        <Table
                          columns={columns}
                          dataSource={activity.changes}
                          pagination={false}
                          size="small"
                          style={{ fontSize: '10px' }}
                        />
                      </ShowMoreText>
                    )}

                    {index !== auditHistory.length - 1 && <Divider className="mt-2 mb-0" />}
                  </>
                </Timeline.Item>
              )}
            </>
          ))}
        </Timeline>
      ) : (
        <div className="text-center text-gray-400 font-size-14 my-4">No audit history found</div>
      )}
      {store.get('paid') == 0 && (
        <Affix offsetBottom={30}>
          <Visibility type="activity" className="mb-4" visible="true" />
        </Affix>
      )}
    </div>
  )
}
