import {
  Badge,
  Button,
  Card,
  Divider,
  Dropdown,
  Empty,
  List,
  Menu,
  Radio,
  Skeleton,
  Space,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import { history } from 'index'
import { Component } from 'react'
import { useDispatch } from 'react-redux'
import { getAPI, onClickNotification } from 'services/jwt'
import store from 'store'

class Notifications extends Component {
  constructor(props) {
    super(props)
    this.notificationInterval = null
    this.state = {
      show: false,
      data: [],
      isDropdownVisible: false,
      isHovered: false,
      loading: false,
      notification_status: 'unread',
      notificationsOpened: false,
    }
  }

  componentDidMount() {
    this.getNofitications()
    this.notificationInterval = setInterval(() => {
      this.getNofitications()
    }, 100000)
  }

  componentWillUnmount() {
    //
    clearInterval(this.notificationInterval)
  }

  getNofitications = async (is_clicked = false) => {
    this.setState({ loading: true })
    const data = await getAPI('notifications', 'get_notifications', {
      is_clicked: is_clicked,
      last_seen_time: store.get('notification_seen_time'),
      status: this.state.notification_status,
      num_records: 5,
    })

    if (data && data.success) {
      this.setState({ data: data.notifications })
      if (this.state.notification_status == 'unread' && !is_clicked) {
        this.props.setNotificationCount(data.count_since_last_seen)
      }
    }
    this.setState({ loading: false })
  }

  handleDropdownClick = () => {
    store.set('notification_seen_time', dayjs().format('DD-MM-YYYY hh:mm A'))

    this.setState({ show: false, isDropdownVisible: !this.state.isDropdownVisible })
    this.props.setNotificationCount(0)
  }

  handleDropdownVisibleChange = visible => {
    if (visible) {
      this.getNofitications(true)

      // this.setState({ isHovered: false })
    }
    if (visible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    this.setState({ isDropdownVisible: visible })
  }

  handleTooltipVisibleChange = visible => {
    this.setState({ isHovered: visible })
  }

  render() {
    return (
      <Tooltip
        trigger={['hover']}
        title="Notifications"
        placement="top"
        onOpenChange={this.handleTooltipVisibleChange}
      >
        <Dropdown
          overlay={
            <>
              <Card
                title={
                  <span className={'flex justify-between items-center'}>
                    Notifications
                    <span className="float-right  font-size-12">
                      <Space.Compact block size="small">
                        <Radio.Group
                          size="small"
                          name="radiogroup"
                          optionType="button"
                          buttonStyle="outline"
                          value={this.state.notification_status}
                          options={[
                            {
                              label: 'Unread',
                              value: 'unread',
                            },
                            {
                              label: 'Read',
                              value: 'read',
                            },
                          ]}
                          className="mt-2"
                          onChange={e => {
                            this.setState({ notification_status: e.target.value }, () => {
                              this.getNofitications(true)
                            })
                          }}
                        ></Radio.Group>
                      </Space.Compact>
                    </span>
                  </span>
                }
                loading={this.state.loading}
                className="mt-2"
              >
                <List
                  loading={this.state.loading}
                  itemLayout="horizontal"
                  loadMore={
                    <div className="flex justify-between items-center mt-2">
                      <div
                        className="font-size-12 text-gray-500 font-weight-medium cursor-pointer"
                        onClick={() => {
                          this.setState({ isDropdownVisible: false }, () =>
                            history.push('/userNotifications'),
                          )
                        }}
                      >
                        See all notifications
                      </div>
                      <div
                        className="font-size-12 text-gray-500 font-weight-medium cursor-pointer"
                        onClick={() => {
                          this.setState({ isDropdownVisible: false }, () =>
                            history.push('/user?tab=settings&sub_tab=notifications'),
                          )
                        }}
                      >
                        <Tooltip title="Go to notification preferences">
                          <i className="fa-solid fa-cog fa-fw"></i>
                        </Tooltip>
                      </div>
                    </div>
                  }
                  dataSource={this.state.data}
                  renderItem={item => (
                    <List.Item
                      className="cursor-pointer"
                      actions={[
                        <span className="float-right text-gray-500 font-size-12">
                          {item.sent_time}
                        </span>,
                      ]}
                      onClick={() => {
                        if (onClickNotification(item)) {
                          this.setState(
                            { isDropdownVisible: false },
                            // , () =>
                            // this.props.updateNotificationCount(-1),
                          )
                        }
                      }}
                    >
                      <span className={'flex flex-col'}>
                        <p className="font-size-14 mb-0">
                          <span className="mb-0 pb-0 font-weight-medium">
                            {item.status == 'sent' && (
                              <Badge className="mr-2" size="small" color="blue" dot={true} />
                            )}
                            {item.title}
                          </span>
                        </p>
                        <span className="text-gray-500 font-size-12">{item.message}</span>
                      </span>
                    </List.Item>
                  )}
                />
              </Card>
            </>
          }
          trigger={['click']}
          placement="bottomRight"
          onVisibleChange={this.handleDropdownVisibleChange}
          overlayStyle={{
            width: 400,
            minHeight: '20vh',
          }}
          open={this.state.isDropdownVisible}
          onClick={this.handleDropdownClick}
        >
          <Button type="text" className="px-2 ml-1 rounded-full">
            <Badge
              size="small"
              count={this.props.count < 20 ? this.props.count : undefined}
              dot={this.props.count > 20}
            >
              <span className="font-weight-medium">
                <i className="fa-solid fa-fw fa-bell text-gray-500"></i>
              </span>
            </Badge>
          </Button>
        </Dropdown>
        {this.state.isDropdownVisible && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.25)', // Adjust the opacity as needed
              zIndex: 999, // Ensure the overlay appears below the dropdown
            }}
          />
        )}
      </Tooltip>
    )
  }
}

export default Notifications
