import { Button, Col, Image, Modal, Row } from 'antd'
import FooterComponent from 'components/other/FooterComponent'
import { history } from 'index'
import React, { Component } from 'react'

export default class SubscriptionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flag: false,
      loading: false,
      closable: true,
      showPaymentModal: false,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  showModal = () => {
    this.setState({ showPaymentModal: true })

    if (this.props.document == 'pos') {
      this.setState({ closable: false })
    }
  }

  render() {
    return (
      <Modal
        className="promotional-modal"
        width={640}
        keyboard={false}
        centered
        maskClosable={false}
        open={this.state.showPaymentModal}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        closable={this.state.closable}
        onCancel={e => {
          if (this.props.document != 'pos') {
            this.setState({ showPaymentModal: false })
          }
        }}
        footer={<FooterComponent />}
        //footer={null}
      >
        <Image
          src="/resources/images/upgrade.png"
          //src="../../../resources/images/einvoice.png"
          preview={false}
          className="m-0 p-0"
          style={{
            padding: 0,
            borderTopLeftRadius: '9px',
            borderTopRightRadius: '9px',
          }}
          height={360}
          width={640}
        />
        <Row justify="center" align="middle" className="mt-4 mb-5 px-4 mx-4">
          <Col span={24}>
            <h2 className="font-weight-bolder">
              <span className="font-size-28">Upgrade Plan 🚀</span>
            </h2>
            <p className="text-gray-900 mb-4">
              This is part of our <b>premium plans</b>. Upgrade today and start using!
            </p>
            {/*;<p className="font-size-21">
             Feel free to reach us at <b>+91 8121 33 5436</b>.<br /> Would really love to hear your feedback.
            </p>*/}
            {this.props.document == 'pos' && (
              <p className="font-size-21">
                {' '}
                <b> POS Free Trail has Ended!!! Upgrade Now </b>{' '}
              </p>
            )}

            <span className="flex items-center">
              <Button
                onClick={event => {
                  if (this.props.document != 'pos') {
                    window.open(
                      'https://api.whatsapp.com/send?phone=918121335436&text=Hello%20Swipe! I want to upgrade my plan',
                    )
                  } else {
                    window.open(
                      'https://api.whatsapp.com/send?phone=918121335436&text=Hello%20Swipe! I want to upgrade my POS plan',
                    )
                  }
                }}
                size="large"
                type="whatsapp"
                className="mr-2 font-size-21 font-weight-bold"
              >
                <i className="fa-brands font-weight-bold fa-whatsapp mr-2" />
                <span>WhatsApp</span>
              </Button>

              {window.location.hostname != 'mac.getswipe.in' && (
                <Button
                  size="large"
                  className="font-size-21 font-weight-bold text-white mr-2"
                  type="primary"
                  onClick={e => history.push('/pricing')}
                >
                  Check Plans
                </Button>
              )}

              {this.props.document == 'pos' && (
                <Button
                  size="large"
                  className="font-size-21 font-weight-bold text-white"
                  type="primary"
                  onClick={e => history.push('/list/sales')}
                >
                  Home
                </Button>
              )}
            </span>
          </Col>
        </Row>
      </Modal>
    )
  }
}

export class EnhancedSubscriptionModal extends React.Component {
  render() {
    return <SubscriptionModal {...this.props} />
  }
}
