import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../redux/types'

const SentryComp = () => {
  const userData = useSelector((state: State) => state.user)
  const permissionsData = useSelector((state: State) => state.permissions)

  const getAttributes = (
    userDetails: State['user']['userDetails'],
    companyDetails: State['permissions']['company_details'],
  ) => {
    return {
      id: 'C' + (companyDetails?.company_id ?? '') + 'U' + (userDetails?.user_id ?? ''),
      ip_address: '{{auto}}',
      // email: userDetails?.email,
      username: userDetails?.name,
      user_id: userDetails?.user_id,
      company_id: companyDetails?.company_id,
    }
  }

  useEffect(() => {
    const userAttrs: Sentry.User = getAttributes(
      userData?.userDetails,
      permissionsData?.company_details,
    )
    Sentry.setUser(userAttrs)
  }, [userData, permissionsData])

  return <></>
}

export default SentryComp
