import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    marginBottom: 5,
    flexWrap: 'wrap',
  },
  reportTitle: {
    color: '#276EF1',
    fontFamily: 'helbold_English',
    letterSpacing: 2,
    fontSize: 11.7,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  reportType: {
    color: '#333333',
    fontFamily: 'helmed',
    letterSpacing: 1,
    fontSize: 8,
    marginRight: 0,
    textTransform: 'uppercase',
    textAlign: 'right',
    right: 90,
  },
})

const DocumentTitle = ({ title, color }) => (
  <View style={styles.titleContainer}>
    <Text style={{ ...styles.reportTitle, color: color }}>{title}</Text>
  </View>
)

export default DocumentTitle
