import { CountryActions } from './types'
import { CountrySetActions } from './actions'

interface CountryInfoState {
  info: Partial<{
    [country_code: string]: {
      states?: string[]
    }
  }>
  launch_countries: []
}

const initialState: CountryInfoState = {
  info: {},
  launch_countries: [],
}

export default function countries(state = initialState, action: CountrySetActions) {
  switch (action.type) {
    case CountryActions.SET_COUNTRY_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload, // action.payload contains the new data
        },
      }
    case CountryActions.SET_LAUNCH_COUNTRIES:
      return {
        ...state,
        launch_countries: action.payload,
      }
    default:
      return state
  }
}
