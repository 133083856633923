import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { Fragment } from 'react'

const borderColor = '#142328'
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderWidth: 1,
    alignItems: 'center',
    paddingVertical: 2.7,
    paddingHorizontal: 2.7,
    fontSize: 8.1,
    textAlign: 'left',
    fontFamily: 'helmed_English',
    flexWrap: 'wrap',
  },
  ewayBill2: {
    marginTop: 18,
    color: '#333333',
    fontFamily: 'helbold_English',
    fontSize: 9,
    marginBottom: 9,
    marginHorizontal: 'auto',
  },
  th: {
    color: '#333333',
    paddingRight: '1%',
  },
  tr: {
    color: '#1d1d1f',
    paddingRight: '1%',
  },
})

const VehicleDetails = ({ invoice }) => {
  var single_col2 = (1 * 100) / 8

  const rows = (
    <>
      <Text style={styles.ewayBill2}>{'PART-B Vehicle Details'}</Text>

      <View style={{ ...styles.container }}>
        <Text style={{ ...styles.th, width: `${0.6 * single_col2}%` }}>Mode</Text>

        <Text style={{ ...styles.th, width: `${1.6 * single_col2}%` }}>
          Vehicle /{'\n'}Trans Doc No. / Dt.
        </Text>

        <Text style={{ ...styles.th, width: `${1.2 * single_col2}%` }}>From</Text>

        <Text style={{ ...styles.th, width: `${1.5 * single_col2}%` }}>Entered Date</Text>

        <Text style={{ ...styles.th, width: `${1.4 * single_col2}%` }}>Entered By</Text>

        <Text style={{ ...styles.th, width: `${0.7 * single_col2}%` }}>CEWB No.{'\n'}(If any)</Text>

        <Text style={{ ...styles.th, width: `${single_col2}%` }}>Multi Veh.Info{'\n'}(If any)</Text>
      </View>

      <View style={styles.container}>
        <Text style={{ ...styles.tr, width: `${0.6 * single_col2}%` }}>
          {invoice.eway_bill_details.transport_mode}
        </Text>

        <Text style={{ ...styles.tr, width: `${1.6 * single_col2}%` }}>
          {invoice.eway_bill_details.vehicle_number}/{'\n'}
          {invoice.eway_bill_details.transporter_doc_no} /{' '}
          {invoice.eway_bill_details.transporter_doc_date}
        </Text>

        <Text style={{ ...styles.tr, width: `${1.2 * single_col2}%` }}>
          {invoice.company.shipping_address.city}
        </Text>

        <Text style={{ ...styles.tr, width: `${1.5 * single_col2}%` }}>
          {invoice.eway_bill_details.eway_bill_date}
        </Text>

        <Text style={{ ...styles.tr, width: `${1.4 * single_col2}%` }}>
          {invoice.company.gstin}
        </Text>

        <Text style={{ ...styles.tr, width: `${0.7 * single_col2}%` }}>--</Text>

        <Text style={{ ...styles.tr, width: `${single_col2}%` }}>--</Text>
      </View>
    </>
  )
  return <Fragment>{rows}</Fragment>
}

export default VehicleDetails
