import React from 'react'
import { Text, Image, View } from '@react-pdf/renderer'
import { EnhancedQRCode } from 'components/other/qrCodeFile'
import { export_terms } from '../export_condition'
import RichText from '../RichText'
import { styles as getStyles } from './styles'

const InvoiceBankDetails = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style
  if (locale == 'English') {
    locale = font_style
  }
  let FONT_FACTOR = invoice.invoice_settings.font_size

  return (
    <View>
      {type != 'Delivery Challan' && (
        <View style={styles.invoiceBankDetails_blockC} wrap={false}>
          <View style={styles.invoiceBankDetails_blockCA}>
            {invoice.bank_details.bank_name != 'Cash' &&
              invoice.bank_details.bank_name != 'COD' &&
              invoice.bank_details.bank_name != 'No Bank' &&
              invoice.bank_details.bank_name != '' &&
              type != 'Delivery Challan' &&
              invoice.document_type != 'purchases' &&
              invoice.document_type != 'purchase_orders' &&
              invoice.document_type != 'purchase_returns' && (
                <>
                  <View>
                    <Text style={styles.invoiceBankDetails_label}>Bank Details:</Text>
                    <View style={styles.invoiceBankDetails_bankRow}>
                      <Text style={styles.invoiceBankDetails_labels}>Bank:</Text>
                      <Text style={styles.invoiceBankDetails_info}>
                        {invoice.bank_details.bank_name}
                      </Text>
                    </View>
                    <View style={styles.invoiceBankDetails_bankRow}>
                      <Text style={styles.invoiceBankDetails_labels}>Account #: </Text>
                      <Text style={styles.invoiceBankDetails_info}>
                        {invoice.bank_details.bank_no}
                      </Text>
                    </View>
                    <View style={styles.invoiceBankDetails_bankRow}>
                      <Text style={styles.invoiceBankDetails_labels}>
                        {invoice.invoice_settings.labels.ifsc}:{' '}
                      </Text>
                      <Text style={styles.invoiceBankDetails_info}>
                        {invoice.bank_details.ifsc}
                      </Text>
                    </View>
                    <View style={styles.invoiceBankDetails_bankRow}>
                      <Text style={styles.invoiceBankDetails_labels}>Branch: </Text>
                      <Text style={styles.invoiceBankDetails_info}>
                        {invoice.bank_details.branch_name}
                      </Text>
                    </View>

                    {invoice.upi != '' && (
                      <View style={styles.invoiceBankDetails_bankRow}>
                        {/*<Text style={styles.invoiceBankDetails_labels}>UPI ID: </Text>*/}
                        <Text style={styles.invoiceBankDetails_bankNotes}>
                          UPI ID: {invoice.bank_details.upi}
                        </Text>
                      </View>
                    )}

                    <View style={styles.invoiceBankDetails_row}>
                      <RichText
                        locale={locale}
                        note={invoice.bank_details.notes}
                        FONT_FACTOR={8.1 * FONT_FACTOR}
                      />
                    </View>
                  </View>
                </>
              )}
          </View>

          <View style={styles.invoiceBankDetails_blockCB}>
            {invoice.document_type != 'purchases' &&
              invoice.document_type != 'purchase_orders' &&
              invoice.document_type != 'purchase_returns' && (
                <View>
                  {invoice.upi != '' && (
                    <>
                      {/*<Text style={styles.invoiceBankDetails_label}>Pay using UPI</Text>*/}
                      <View style={styles.invoiceBankDetails_block2}>
                        <EnhancedQRCode
                          style={styles.invoiceBankDetails_upi}
                          value={`${invoice.upi}`}
                        />
                      </View>
                    </>
                  )}
                </View>
              )}
          </View>
        </View>
      )}
    </View>
  )
}

export default InvoiceBankDetails
