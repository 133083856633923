import React, { useEffect, useState } from 'react'

const StorageListener: React.FC = () => {
  const [tokenChanged, setTokenChanged] = useState(false)

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'accessToken') {
        if (event.oldValue !== event.newValue) {
          setTokenChanged(true) // Flag that the token has changed
        }
      }
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && tokenChanged) {
        window.location.reload()
      }
    }

    window.addEventListener('storage', handleStorageChange)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [tokenChanged])

  return null
}

export default StorageListener
