import React, { useEffect, useState } from 'react'

const AvatarStack = ({ count = 4 }) => {
  const [selectedAvatars, setSelectedAvatars] = useState([])

  // Base URL for the S3 bucket
  const baseUrl = 'https://vx-erp-resources.s3.ap-south-1.amazonaws.com/customeravatars/'

  // Generate avatar URLs dynamically
  const avatars = Array.from({ length: 32 }, (_, i) => ({
    src: `${baseUrl}${i + 1}.webp`,
    alt: `User ${i + 1}`,
  }))

  useEffect(() => {
    // Preload all avatar images
    avatars.forEach(avatar => {
      const img = new Image()
      img.src = avatar.src
    })

    // Randomly shuffle the avatars array
    const shuffledAvatars = avatars.sort(() => 0.5 - Math.random())
    // Slice the first `count` avatars
    setSelectedAvatars(shuffledAvatars.slice(0, count))
  }, [count])

  return (
    <div className="my-4">
      <div className="flex -space-x-4 items-center justify-center">
        {selectedAvatars.map((avatar, index) => (
          <img
            key={index}
            src={avatar.src}
            alt={avatar.alt}
            className="w-16 h-16 rounded-full border-2 border-white transform transition-transform duration-200 hover:scale-125"
          />
        ))}
        {/* <img
          src="https://vx-erp-resources.s3.ap-south-1.amazonaws.com/customeravatars/check.jpg"
          className="w-16 h-16 rounded-full border-2 border-white transform transition-transform duration-200 hover:scale-110"
        /> */}
      </div>
      <span className="text-center font-weight-bold">
        <p className="text-lg mt-2">
          <span className="text-gray-900 font-weight-bolder">10 Lakh+ Businesses</span>
          <i className="fa-sharp fa-solid fa-heart text-pink mx-2"></i>
          <span>us.</span>
        </p>
      </span>
    </div>
  )
}

export const AvatarStackSmall = ({ count = 4 }) => {
  const [selectedAvatars, setSelectedAvatars] = useState([])

  // Base URL for the S3 bucket
  const baseUrl = 'https://vx-erp-resources.s3.ap-south-1.amazonaws.com/customeravatars/'

  // Generate avatar URLs dynamically
  const avatars = Array.from({ length: 32 }, (_, i) => ({
    src: `${baseUrl}${i + 1}.webp`,
    alt: `User ${i + 1}`,
  }))

  useEffect(() => {
    // Preload all avatar images
    avatars.forEach(avatar => {
      const img = new Image()
      img.src = avatar.src
    })

    // Randomly shuffle the avatars array
    const shuffledAvatars = avatars.sort(() => 0.5 - Math.random())
    // Slice the first `count` avatars
    setSelectedAvatars(shuffledAvatars.slice(0, count))
  }, [count])

  return (
    <div className="my-0">
      <div className="flex -space-x-2 items-center justify-start">
        {selectedAvatars.map((avatar, index) => (
          <img
            key={index}
            src={avatar.src}
            alt={avatar.alt}
            className="w-12 h-12 rounded-full border-2 border-white transform transition-transform duration-200 hover:scale-125"
          />
        ))}
        {/* <img
          src="https://vx-erp-resources.s3.ap-south-1.amazonaws.com/customeravatars/check.jpg"
          className="w-16 h-16 rounded-full border-2 border-white transform transition-transform duration-200 hover:scale-110"
        /> */}
      </div>
      <span className="text-center font-weight-bold">
        <p className="text-md mt-0">
          <span className="text-gray-900 font-weight-bolder">10 Lakh+ Businesses</span>
          <i className="fa-sharp fa-solid fa-heart text-pink mx-2"></i>
          <span>us.</span>
        </p>
      </span>
    </div>
  )
}

export default AvatarStack
