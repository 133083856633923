import { LoadingOutlined } from '@ant-design/icons'
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Input,
  Popover,
  Select,
  Spin,
  Tabs,
  message,
} from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import MultiSelectDropdown from 'components/other/MultiSelectDropdown'
import Datepicker from 'react-tailwindcss-datepicker'
import { getAPI } from 'services/jwt'
import store from 'store'
import ReportTable from '.'
import ReportSettings from '../reportSettings'

const { RangePicker } = DatePicker
const { TabPane } = Tabs

export interface UserState {
  company_id: string
  selectedCompany: any
  companies: any
  paid: boolean
}

export interface RootState {
  user: UserState
  permissions: any
  countries: any
}

type columnObj = {
  active: boolean
  dataIndex: string
  disabled: true
  label: string
  title: string
  value: string
  width: number
}

interface ReportView {
  report: {
    name: string
    disabled: boolean
    menu_name: string
    type: string
    party_type: string
    index: number
    new: boolean
    categroy: string
    newReport: boolean
    document_type: string | undefined
  }
  callData: (req: any) => any
  data: {
    data: any
    total: any
  }
  columns: { [key: string]: columnObj[] }
  allColumns: { [key: string]: columnObj[] }
  hideComponents: string[]
  hasItemSearch: boolean
  hasPartySearch: boolean
  hasSearch: boolean
  tooltips: { [key: string]: string }[]
  error_data: any | null
  showErrorModal: any
  type: { [key: string]: string }
  customDownloadName: string
  onCustomDownload: () => void
  onTabChangeCallAPI: boolean
  hasBrancheSelection: boolean
  footer: any
  showPurchasePriceSelection: boolean
  showPurchasePriceReport: boolean
  showGroupSelection: boolean
  hasPartyBalanceTypeSelection: boolean
  showBatchExpirySelection: boolean
  hasDatePicker: boolean
  hasCategorySearch: string
  setColumns: (columns: any) => void
  partyTags: string[]
  showPartyTags: boolean
  showProductTypeSelect: boolean
  showBranchSummary: boolean
  warehouses: any[]
  tabWiseAmounts: any
  showJSONDownload: boolean
}

type componentState = {
  num_records: number
  page: number
  total: number
  dates: [string, string]
  company_ids: number[]
  dataSource: any
  loading: boolean
  party_type: 'customer' | 'vendor'
  purchase_price_type: string
  report_settings: boolean
  tooltips: any
  filter: string
  activeTab: any
  tabName: string
  groupBY: string
  partyBalanceType: string
  batchExpiry: number
  search: string
  category: string
  partyTags: []
  productType: string
  showBranchSummary: boolean
  warehouse_id: undefined | number
}

type callDataReq = {
  page: number
  num_records: number
  date_range: string
  party_id: number
  product_id: number
  variant_name: string
  download: number
  sorter: string
  sort_type: string
  company_ids: number[]
  report: string
}

interface NewRange {
  text: string
  period: {
    start: Date
    end: Date
  }
}

export const ReportsView = forwardRef<any, ReportView>((props, ref) => {
  // region Refs
  const gridRef = useRef<any | null>()
  const multiSelectDropdownRef = useRef<any | null>()
  const subscriptionPlanRef = useRef<any | null>()

  // endregion

  // region Variables
  const dateFormat = 'DD-MM-YYYY'

  const isMobile = window.innerWidth < 700

  const user = useSelector((state: RootState) => state.user)
  const stateProps = useSelector((state: RootState) => state)
  const company_details = user.selectedCompany
  const ranges = stateProps.countries.info[user?.selectedCompany?.country_code]?.date_ranges

  const newRanges: { [key: string]: NewRange } = ranges
    ? Object.keys(ranges)?.reduce((acc, key) => {
        if (ranges[key] && ranges[key].length === 2) {
          acc[key] = {
            text: key,
            period: {
              start: ranges[key][0]?.toDate(),
              end: ranges[key][1]?.toDate(),
            },
          }
        }
        return acc
      }, {} as { [key: string]: NewRange })
    : {} // Type assertion

  let business_id = user?.companies?.filter(
    (company: any) => company.company_id == user?.selectedCompany.company_id,
  )
  let business_companies = []

  if (business_id.length > 0) {
    business_id = business_id[0].business_id
    if (business_id != -1) {
      business_companies = user?.companies?.filter(
        (company: any) => company.business_id == business_id,
      )
    }
  }

  const [dates, setDates] = useState<[string, string]>(
    store.get('transactions_default_dates_' + user.company_id)
      ? [
          props.hasDatePicker
            ? dayjs().format(dateFormat)
            : store.get('transactions_default_dates_' + user.company_id).split(' - ')[0],

          store.get('transactions_default_dates_' + user.company_id).split(' - ')[1],
        ]
      : [
          props.hasDatePicker
            ? dayjs().format(dateFormat)
            : dayjs()
                .month(0)
                .startOf('month')
                .format(dateFormat),
          dayjs()
            .month(11)
            .endOf('month')
            .format(dateFormat),
        ],
  )

  const [itemSearch, setItemSearch] = useState<string>('')
  const [partySearch, setPartySearch] = useState<string>('')

  const [partyAutoCompleteData, setPartyAutoCompleteData] = useState<any>([])
  const [itemAutoCompleteData, setItemAutoCompleteData] = useState<any>([])
  const [categroyAutoCompleteData, setCategoryAutoCompleteData] = useState<any>([])

  const [selectedItem, setSelectedItem] = useState<any>({
    name: '',
    id: -1,
    variant_name: '',
    variant_id: 0,
    product_name: '',
  })
  const [selectedParty, setSelectedParty] = useState<any>({ name: '', id: -1 })
  const [selectedCategory, setSelectedCategory] = useState<any>({
    value: '',
    name: '',
    id: '',
    key: '',
  })

  const [branchCompanies, setCompanyIds] = useState<number[]>([company_details.company_id])
  const [fileLoading, setFileLoading] = useState<boolean>(false)
  const [state, setState] = useState<componentState>({
    num_records: 10,
    page: 0,
    total: 0,
    dates: store.get('transactions_default_dates_' + user.company_id)
      ? [
          props.hasDatePicker
            ? dayjs().format(dateFormat)
            : store.get('transactions_default_dates_' + user.company_id).split(' - ')[0],

          store.get('transactions_default_dates_' + user.company_id).split(' - ')[1],
        ]
      : [
          props.hasDatePicker
            ? dayjs().format(dateFormat)
            : dayjs()
                .month(0)
                .startOf('month')
                .format(dateFormat),
          dayjs()
            .month(11)
            .endOf('month')
            .format(dateFormat),
        ],
    party_type: 'customer',
    company_ids: [company_details.company_id],
    dataSource: {},
    loading: false,
    purchase_price_type:
      store.get('reportsPurchasericeType') != ''
        ? store.get('reportsPurchasePriceType')
        : 'current',
    report_settings: false,
    tooltips: {},
    filter: '',
    activeTab: '',
    tabName: '',
    groupBY: 'products',
    partyBalanceType: 'All',
    batchExpiry: -1,
    search: '',
    category: '',
    partyTags: [],
    productType: 'all',
    showBranchSummary: false,
    warehouse_id: undefined,
  })

  const [req, setReq] = useState<callDataReq>({
    num_records: 10,
    page: 0,
    date_range: state.dates[0] + ' - ' + state.dates[1],
    party_id: selectedParty?.id,
    product_id: selectedItem?.id,
    variant_name: selectedItem?.variant_name,
    download: 0,
    sorter: '',
    sort_type: '',
    company_ids: state.company_ids,
    report: state.activeTab,
  })

  const [tabName, setTabName] = useState<string>('')
  const [showBranchSummary, setShowBranchSummary] = useState<boolean>(false)
  const [gridKey, setGridKey] = useState('')

  // endregion

  // region Use Effects
  useEffect(() => {
    setTabName(state.activeTab)
    setGridKey(
      state.activeTab +
        selectedParty?.id +
        selectedItem?.id +
        selectedItem?.variant_name +
        dates[0] +
        dates[1] +
        Math.random(),
    )
  }, [state, selectedItem, selectedParty, dates, branchCompanies, selectedCategory])

  // endregion

  // region Functions

  // region Item search related functions
  const _itemSearchAPI = async (searchText: string) => {
    var allData = []
    if (searchText.length >= 0) {
      const req = { query: searchText }
      const data = await getAPI('product', 'search', req)
      if (data) {
        allData = data.products.map((value: any) => {
          return {
            value: value.product_name + ' ' + (value.variant_name ? value.variant_name : ''),
            name: value.product_name + ' ' + (value.variant_name ? value.variant_name : ''),
            id: value.product_id,
            key: value.product_id + '_' + (value.variant_id ? String(value.variant_name) : '0'),
            product_name: value.product_name ? value.product_name : '',
            variant_name: value.variant_name ? value.variant_name : '',
            variant_id: value.variant_id ? value.variant_id : 0,
          }
        })

        setItemAutoCompleteData(allData)
      }
    }
    if (searchText.length < 1) {
      onItemSelect({}, { name: '', id: -1, variant_name: '', variant_id: 0, product_name: '' })
    }
  }

  const itemSearchAPI = useCallback(_.debounce(_itemSearchAPI, 500, { trailing: true }), [])

  const onItemSearch = (searchText: string) => {
    setItemSearch(searchText)

    itemSearchAPI(searchText)
  }

  const _setSearchText = (search: string) => {
    setState({ ...state, search })
  }
  const setSearchText = useCallback(_.debounce(_setSearchText, 500, { trailing: true }), [])

  const _onItemSelect = (data: any, option: any) => {
    setItemSearch(option.name)
    setItemAutoCompleteData([])
    setSelectedItem(option)
    setState({
      ...state,
      page: 0,
      loading: false,
    })
  }
  const onItemSelect = useCallback(_.debounce(_onItemSelect, 500, { trailing: true }), [])

  // endregion

  // region Party Search related functions

  const onCustomerSearch = async (searchText: string) => {
    setPartySearch(searchText)
    customerSearchAPI(searchText)
  }

  const _onCustomerSelect = (data: any, option: any) => {
    setPartySearch(option.name)
    setPartyAutoCompleteData([])
    setSelectedParty(option)
    setState({
      ...state,
      page: 0,
      loading: false,
    })
  }
  const onCustomerSelect = useCallback(_.debounce(_onCustomerSelect, 500, { trailing: true }), [])

  const _customerSearchAPI = async (searchText: string) => {
    setPartySearch(searchText)
    if (searchText.length >= 0) {
      const req = { query: searchText }
      const data = await getAPI(props.report.party_type, 'search', req)
      if (data) {
        var parties = props.report.party_type == 'customer' ? data.customers : data.vendors
        const allData = parties.map((value: any) => {
          return {
            value: value.name,
            name: value.name,
            id: props.report.party_type == 'customer' ? value.customer_id : value.vendor_id,
            key: props.report.party_type == 'customer' ? value.customer_id : value.vendor_id,
          }
        })
        setPartyAutoCompleteData(allData)
      }
    }
    if (searchText.length < 1) {
      setPartyAutoCompleteData([])
      onCustomerSelect({}, { name: '', id: -1 })
    }
  }
  const customerSearchAPI = useCallback(_.debounce(_customerSearchAPI, 500, { trailing: true }), [])

  // endregion

  const _setDateRange = (dates: any, dateStrings: string | [string, string]) => {
    setDates([dateStrings[0], dateStrings[1]])
    setState({ ...state, dates: [dateStrings[0], dateStrings[1]] })

    store.set(
      'reportsDateRange_' + company_details.company_id,
      dateStrings[0] + ' - ' + dateStrings[1],
    )
  }

  const setDateRange = useCallback(_setDateRange, [])

  const _setBranchCOmpanies = (company_ids: number[]) => {
    setState({ ...state, company_ids: company_ids })
    setCompanyIds(company_ids)
  }
  const setBranchCompanies = useCallback(
    _.debounce(_setBranchCOmpanies, 500, { trailing: true }),
    [],
  )

  const _onCategorySelect = (data: any, option: any) => {
    setSelectedCategory(option)
    setCategoryAutoCompleteData([])
  }

  const onCategorySelect = useCallback(_.debounce(_onCategorySelect, 500, { trailing: true }), [])

  const _categorySearchAPI = async (searchText: string) => {
    if (searchText.length >= 0) {
      const req = { query: searchText, type: props.report.document_type }
      const data = await getAPI(props.hasCategorySearch, 'get_categories', req)
      if (data) {
        var allData = []

        allData = data.categories.map((value: any) => {
          return {
            value: value.category,
            name: value.category,
            id: value.category,
            key: value.category,
          }
        })

        setCategoryAutoCompleteData(allData)
      }
    }
    if (searchText.length < 1) {
      setCategoryAutoCompleteData([])
      onCategorySelect(
        {},
        {
          value: '',
          name: '',
          id: '',
          key: '',
        },
      )
    }
  }
  const categorySearchAPI = useCallback(_.debounce(_categorySearchAPI, 500, { trailing: true }), [])

  const getFilters = (input: { [key: string]: string[] }) => {
    var output: any
    output = {}
    for (const key in input) {
      output[key] = input[key]['values']
    }
    return output
  }
  const onGridReady = async (params: any, report: string) => {
    let dataSource = {
      getRows: async (params: any) => {
        params.api.setGridOption('loading', true)

        let filters = params.request.filterModel

        let reqObj = {
          ...req,
          date_range: dates[0] + ' - ' + dates[1],
          dates: dates,
          party_id: selectedParty?.id,
          party_name: selectedParty?.name,
          product_id: selectedItem?.id,
          product_name: selectedItem?.product_name,
          variant_name: selectedItem?.variant_name,
          variant_id: selectedItem?.variant_id,
          page: params.request.startRow / (params.request.endRow - params.request.startRow),
          num_records: params.request.endRow - params.request.startRow,
          sorter: params.request.sortModel[0]?.sort,
          sort_type: params.request.sortModel[0]?.colId,
          filters: getFilters(filters),
          company_ids: branchCompanies,
          report: state.activeTab,
          purchase_price_type: state.purchase_price_type,
          groupBy: state.groupBY,
          partyBalanceType: state.partyBalanceType,
          batchExpiry: state.batchExpiry,
          search: state.search,
          categoryName: selectedCategory.value,
          partyTags: state.partyTags,
          productType: state.productType,
          showBranchSummary: showBranchSummary,
          warehouse_id: state.warehouse_id,
        }
        let isDateChanged = req.date_range != reqObj.date_range

        setReq(reqObj)
        let response: any
        let data: any[]
        let total_rows: number | undefined = undefined

        report = report || req.report
        if (props.onTabChangeCallAPI == false && report != '' && !isDateChanged) {
          setTimeout(() => {
            report = report || Object.keys(props.data.data)[0]
            data = props.data.data[report]
            total_rows = data?.length
          }, 500)
        } else {
          response = await props.callData(reqObj)
          report = report || Object.keys(response ?? {})?.[0]
          if (report && response) {
            data = response[report]?.data
            total_rows = response[report]?.totals
          }
        }
        setTabName(report)
        params?.api?.setGridOption('loading', false)
        setTimeout(() => {
          params?.success &&
            params?.success({
              rowData: data,
              rowCount: total_rows,
            })
          if (!data?.length || data.length == 0) {
            params.api.showNoRowsOverlay()
          } else {
            params.api.hideOverlay()
          }
        }, 500)
      },
    }

    params.api.setGridOption('serverSideDatasource', dataSource)
  }
  const onColumnChange = (params: any) => {
    let newColumns = gridRef.current.api().getColumnDefs() //retrieves all the columns regardless of the visibility
    newColumns = newColumns.map((col: any, index: number) => {
      return {
        index: index,
        active: !col.hide,
        dataIndex: col.field,
        disabled: col.lockVisible,
        label: col.headerName,
        title: col.headerName,
        value: col.field,
        width: col.width,
      }
    })

    if (!newColumns.some((col: any) => col.active == true)) {
      message.error('At least one column must be selected')
      if (!_.isNil(params?.column?.colId)) {
        params.api.setColumnVisible(params?.column?.colId, true)
      }
      return
    }

    onColumnsSave(newColumns)
  }

  const onColumnsSave = async (columns: any) => {
    const req = {
      type: props.type[tabName] ?? props.type,
      columns: columns,
    }
    const data = await getAPI('v2/reports', 'get_columns', req)
    if (data && data.success) {
      // message.success('Columns updated successfully')
      if (props.setColumns) {
        props.setColumns(columns)
      }
    }
  }

  const onDownload = async (download = 1) => {
    // console.log(gridRef.current.api().getAllDisplayedColumns())
    if (!user.paid) {
      subscriptionPlanRef.current.openModal('reports')
      return
    }

    if (download == 2 && gridRef.current.api().getAllDisplayedColumns().length > 15) {
      message.error('Too many columns selected. Please select less than 15 columns.')
      return
    }
    setFileLoading(true)
    message.info('Downloading file...')

    await props.callData({ ...req, download })
    setFileLoading(false)
  }

  // endregion
  return (
    <>
      {props.report.newReport ? (
        ''
      ) : (
        <div className="mt-2 mb-2 font-size-22 font-weight-bold">{props.report.name}</div>
      )}

      {props.report.type == 'gstr_7' && (
        <p className="mb-4">
          <b>Note:</b> GSTR-7 is a monthly return filed by individuals who deduct{' '}
          <b>TDS under GST</b>. Every GST registered individual who deducts TDS under GST must file
          in Form GSTR-7 by the <b>10th of next month</b>.
        </p>
      )}

      <Spin spinning={fileLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
        <div className="w-full flex mt-2 justify-between items-center">
          <div className="">
            <Input.Group compact>
              {props.hideComponents && !props.hideComponents.includes('rangepicker') && !isMobile && (
                <RangePicker
                  allowClear={false}
                  // @ts-ignore
                  ranges={ranges}
                  onChange={setDateRange}
                  className=" sm:w-96 w-full"
                  style={{
                    // borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                  popupStyle={{
                    maxWidth: '95%',
                  }}
                  panelRender={panel => (
                    <Card
                      bodyStyle={{
                        padding: 0,
                        width: '100%',
                        overflowY: 'scroll',
                      }}
                    >
                      <div>{panel}</div>
                    </Card>
                  )}
                  placement="bottomRight"
                  // defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
                  value={[dayjs(dates[0], dateFormat), dayjs(dates[1], dateFormat)]}
                  format={dateFormat}
                  size="middle"
                />
              )}
              {props.hasSearch == true && (
                <Input
                  type="text"
                  prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
                  className=""
                  placeholder="Search  "
                  onChange={e => {
                    setSearchText(e.target.value)
                  }}
                  style={{
                    width: 300,
                  }}
                />
              )}
              {props.hasItemSearch == true && (
                <AutoComplete
                  options={itemAutoCompleteData}
                  className=" sm:w-96 w-full"
                  style={{ borderRadius: '5px' }}
                  onSelect={onItemSelect}
                  onSearch={onItemSearch}
                  value={itemSearch}
                  placeholder={'Select Item'}
                />
              )}
              {props.hasPartySearch == true && (
                <AutoComplete
                  options={partyAutoCompleteData}
                  className=" sm:w-96 w-full"
                  style={{ borderRadius: '5px' }}
                  onSelect={onCustomerSelect}
                  onSearch={onCustomerSearch}
                  value={partySearch}
                  placeholder={'Select ' + props.report.party_type}
                />
              )}
              {props.hasCategorySearch && (
                <AutoComplete
                  options={categroyAutoCompleteData}
                  style={{ width: 300, borderRadius: '5px' }}
                  onSelect={onCategorySelect}
                  onSearch={categorySearchAPI}
                  onClear={() => {
                    onCategorySelect(
                      {},
                      {
                        value: '',
                        name: '',
                        id: '',
                        key: '',
                      },
                    )
                  }}
                  allowClear
                  // value={this.state.selectedCategory.name}
                  placeholder={'Search Category'}
                />
              )}
              {props.showProductTypeSelect == true && (
                <Select
                  showSearch
                  className="sm:w-auto w-full"
                  style={{ fontSize: '12px' }}
                  placeholder="Product Type"
                  optionFilterProp="children"
                  onChange={e => {
                    setState({ ...state, productType: e })
                  }}
                  value={state.productType}
                  filterOption={(input: string, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value={'all'} key={0}>
                    {'All'}
                  </Select.Option>
                  <Select.Option value={'product'} key={1}>
                    {'Products'}
                  </Select.Option>
                  <Select.Option value={'service'} key={2}>
                    {'Services'}
                  </Select.Option>
                </Select>
              )}
              {props.hasPartyBalanceTypeSelection == true && (
                <Select
                  showSearch
                  className="sm:w-96 w-full"
                  style={{ width: 100, fontSize: '12px' }}
                  placeholder="Credit/Debit"
                  optionFilterProp="children"
                  onChange={e => {
                    setState({ ...state, partyBalanceType: e })
                  }}
                  value={state.partyBalanceType}
                  filterOption={(input: string, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value={'All'} key={0}>
                    {'All'}
                  </Select.Option>
                  <Select.Option value={'credit'} key={1}>
                    {'Credit'}
                  </Select.Option>
                  <Select.Option value={'debit'} key={2}>
                    {'Debit'}
                  </Select.Option>
                </Select>
              )}
              {props.showPartyTags == true && (
                <Select
                  showSearch
                  className="sm:w-96 w-full"
                  style={{ width: 150, fontSize: '12px' }}
                  placeholder="Select tags"
                  mode="multiple"
                  optionFilterProp="children"
                  onChange={e => {
                    setState({ ...state, partyTags: e })
                  }}
                  value={state.partyTags}
                  filterOption={(input: string, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {props.partyTags.map((tag: any, index: number) => (
                    <Select.Option value={tag} key={index}>
                      {tag}
                    </Select.Option>
                  ))}
                </Select>
              )}

              {isMobile && props.hideComponents && !props.hideComponents.includes('rangepicker') && (
                <Datepicker
                  value={{
                    startDate: dayjs(dates[0], dateFormat).toDate(),
                    endDate: dayjs(dates[1], dateFormat).toDate(),
                  }}
                  displayFormat={'DD-MM-YYYY'}
                  useRange={false}
                  readOnly={true}
                  inputClassName="sm:w-96 w-full rounded-r-lg font-small p-1.5 text-center"
                  containerClassName={'sm:w-96 w-full relative mt-0.5 mr-auto mb-2 z-50'}
                  onChange={newValue => {
                    if (newValue?.startDate && newValue?.endDate) {
                      setDates([
                        dayjs(newValue.startDate).format(dateFormat),
                        dayjs(newValue.endDate).format(dateFormat),
                      ])
                    }
                  }}
                  showShortcuts={true}
                  configs={{
                    shortcuts: newRanges,
                  }}
                  popoverDirection="down"
                  separator=" - "
                  required={true}
                />
              )}
              {props.hasDatePicker == true && (
                <DatePicker
                  style={{
                    borderRadius: '5px',
                    cursor: 'pointer',
                    width: 200,
                  }}
                  allowClear={false}
                  format={dateFormat}
                  value={dayjs(dates[0], dateFormat)}
                  onChange={(date, dateString) => {
                    if (typeof dateString == 'string') setDateRange([], [dateString, dates[1]])
                  }}
                />
              )}

              {props.showBatchExpirySelection && (
                <Select
                  defaultValue={state.batchExpiry}
                  style={{ width: 360 }}
                  onChange={(value: number) => {
                    setState({ ...state, batchExpiry: value })
                  }}
                  options={[
                    { value: -1, label: 'All batches' },
                    { value: 30, label: 'Expires in 30 Days' },
                    { value: 60, label: 'Expires in 60 Days' },
                    { value: 90, label: 'Expires in 90 Days' },
                    { value: 120, label: 'Expires in 120 Days' },
                    { value: 180, label: 'Expires in 180 Days' },
                    {
                      value: 0, // -1 is for expired batches
                      label: 'Expired Batches',
                    },
                  ]}
                />
              )}

              {props.showGroupSelection && (
                <Select
                  className=""
                  style={{ width: 300, borderRadius: '5px' }}
                  placeholder="Select Category"
                  onChange={e => {
                    setState({ ...state, groupBY: e })
                  }}
                  value={state.groupBY}
                >
                  <Select.Option value="products">Products wise</Select.Option>
                  <Select.Option value="variants">Variants wise</Select.Option>
                  {stateProps.permissions.integrations.batching && (
                    <Select.Option value="batches">Batches wise</Select.Option>
                  )}
                  {stateProps.permissions.integrations.serial_number && (
                    <Select.Option value="serial_numbers">Serial Numbers wise</Select.Option>
                  )}
                </Select>
              )}
              {props.hasBrancheSelection && business_companies.length > 1 && (
                <MultiSelectDropdown
                  // onRef={ref => (multiSelectDropdown = ref)}
                  // ref={multiSelectDropdownRef}
                  list={business_companies}
                  title="Select Branch"
                  label="organization_name"
                  label_id={'company_id'}
                  onChange={(e: number[]) => {
                    setBranchCompanies(e)
                    // props.callData({ ...req, company_ids: e })
                  }}
                  default_value={company_details.company_id}
                  showLeftBorder={false}
                  showCount={true}
                  classStyles="w-full"
                />
              )}
              {props.warehouses != undefined && props.warehouses.length > 0 && (
                <Select
                  suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                  optionFilterProp="children"
                  showSearch
                  allowClear={true}
                  className="mr-2"
                  style={{
                    width: '200px',
                  }}
                  onChange={value => setState({ ...state, warehouse_id: value })}
                  placeholder={'Select Warehouse'}
                  filterOption={(input, option) =>
                    // @ts-ignore
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  dropdownStyle={{ minWidth: '300px' }}
                  listHeight={200}
                  value={state.warehouse_id}
                >
                  {props.warehouses.map(warehouse => (
                    <Select.Option value={warehouse.warehouse_id}>{warehouse.name}</Select.Option>
                  ))}
                </Select>
              )}

              {props.hasBrancheSelection &&
                business_companies.length > 1 &&
                props.showBranchSummary && (
                  <div className="sm:w-96 w-full mx-2 mt-1">
                    <Checkbox
                      onChange={e => {
                        setState({ ...state, showBranchSummary: e.target.checked })
                        setShowBranchSummary(e.target.checked)
                      }}
                      checked={showBranchSummary}
                    >
                      Show Branch Summary
                    </Checkbox>
                  </div>
                )}
              {isMobile ? (
                <>
                  {(!props.customDownloadName || props.customDownloadName == '') && (
                    <div className="flex flex-wrap	 sm:w-96 w-full">
                      <Button
                        // type="action"
                        className=" ml-1 mt-2 "
                        style={{
                          // width: 145,
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => onDownload(1)}
                        size={'middle'}
                      >
                        <i className="fa-duotone fa-file-excel mr-2" />
                        Download Excel
                      </Button>

                      {props.showJSONDownload && (
                        <Button
                          // type="action-3"
                          className=" ml-2 mt-2"
                          style={{
                            // width: 145,
                            borderRadius: '5px',
                            cursor: 'pointer',
                          }}
                          onClick={() => onDownload(3)}
                          size={'middle'}
                        >
                          <i className="fa-duotone fa-file-pdf mr-2" />
                          Download JSON
                        </Button>
                      )}
                      {props.hideComponents && !props.hideComponents.includes('pdfDownload') && (
                        <Button
                          // type="action-3"
                          className=" ml-2 mt-2"
                          style={{
                            // width: 145,
                            borderRadius: '5px',
                            cursor: 'pointer',
                          }}
                          onClick={() => onDownload(2)}
                          size={'middle'}
                        >
                          <i className="fa-duotone fa-file-pdf mr-2" />
                          Download PDF
                        </Button>
                      )}
                      {props.hideComponents && !props.hideComponents.includes('reportSettings') && (
                        <ReportSettings
                          purchase_price_type={
                            props.showPurchasePriceSelection ? state.purchase_price_type : ''
                          }
                          showPurchasePriceReport={props.showPurchasePriceReport}
                          onChangePurchase={(reportName: string) => {
                            setState({
                              ...state,
                              purchase_price_type: 'purchase',
                              report_settings: false,
                            })
                          }}
                          onChange={(value: string) => {
                            store.set('reportsPurchasePriceType', value)
                            store.set('reportsSettings', false)
                            setState({
                              ...state,
                              purchase_price_type: value,
                              report_settings: false,
                            })
                            // props.callData()
                          }}
                          columns={props.allColumns[tabName] || []}
                          tooltips={props.tooltips || {}}
                          type={props.type[tabName] ?? props.type}
                          updateCurrentColumns={(columns: any[]) => {
                            gridRef.current.api().setGridOption('columns', columns)
                            gridRef.current
                              .api()
                              .refreshServerSide({ route: undefined, purge: undefined })
                          }}
                          onManageColumns={() => {
                            gridRef
                              .current!.api()
                              .setSideBarVisible(!gridRef.current!.api().isSideBarVisible())
                            gridRef.current!.api().openToolPanel('columns')
                          }}
                        />
                      )}
                    </div>
                  )}
                </>
              ) : (
                ''
              )}
            </Input.Group>
          </div>
          {isMobile && props.customDownloadName && props.customDownloadName != '' && (
            <Button
              loading={state.loading}
              // @ts-ignore
              type="action"
              className="mr-2 ml-auto"
              onClick={() => {
                if (!user.paid) {
                  subscriptionPlanRef.current.openModal('reports')
                  return
                }
                props.onCustomDownload()
              }}
            >
              <i className="fa-regular fa-file-download" />
            </Button>
          )}
          {window.innerWidth < 1600 && (
            <div className="">
              <>
                {isMobile ? (
                  ''
                ) : (
                  <>
                    {(!props.customDownloadName || props.customDownloadName == '') && (
                      <Popover
                        content={
                          <div style={{ width: 200 }}>
                            <div className="font-weight-bold">Download</div>
                            <div className="d-flex flex-column">
                              <Button
                                // type="action"
                                className="mx-2 my-2"
                                onClick={() => onDownload(1)}
                                loading={state.loading}
                              >
                                <i className="fa-duotone fa-file-excel mr-2" />
                                Download Excel
                              </Button>
                              {props.hideComponents &&
                                !props.hideComponents.includes('pdfDownload') && (
                                  <Button
                                    // type="action-3"
                                    className="mx-2 my-2"
                                    onClick={() => onDownload(2)}
                                    loading={state.loading}
                                  >
                                    <i className="fa-duotone fa-file-pdf mr-2" />
                                    Download PDF
                                  </Button>
                                )}
                              {props.showJSONDownload && (
                                <Button
                                  // type="action-3"
                                  className="mx-2 my-2"
                                  onClick={() => onDownload(3)}
                                  loading={state.loading}
                                >
                                  <i className="fa-duotone fa-file-pdf mr-2" />
                                  Download JSON
                                </Button>
                              )}
                            </div>
                          </div>
                        }
                        trigger={['click']}
                        placement="bottom"
                      >
                        <Button className="  mr-2">
                          <span className="d-none d-sm-inline mr-2">Download</span>
                          <span>
                            <i className="fa-duotone fa-down-to-line d   mt-1" />
                          </span>
                        </Button>
                      </Popover>
                    )}
                    {props.customDownloadName && props.customDownloadName != '' && (
                      <Button
                        loading={state.loading}
                        // @ts-ignore
                        type="action"
                        className="mr-2 ml-auto"
                        onClick={() => {
                          if (!user.paid) {
                            subscriptionPlanRef.current.openModal('reports')
                            return
                          }
                          props.onCustomDownload()
                        }}
                      >
                        <i className="fa-regular fa-file-download mr-2" />
                        Download
                      </Button>
                    )}
                    {props.hideComponents && !props.hideComponents.includes('reportSettings') && (
                      <ReportSettings
                        purchase_price_type={
                          props.showPurchasePriceSelection ? state.purchase_price_type : ''
                        }
                        showPurchasePriceReport={props.showPurchasePriceReport}
                        onChangePurchase={(reportName: string) => {
                          setState({
                            ...state,
                            purchase_price_type: 'purchase',
                            report_settings: false,
                          })
                        }}
                        onChange={(value: string) => {
                          store.set('reportsPurchasePriceType', value)
                          store.set('reportsSettings', false)
                          setState({
                            ...state,
                            purchase_price_type: value,
                            report_settings: false,
                          })
                          // props.callData()
                        }}
                        columns={props.allColumns[tabName] || []}
                        tooltips={props.tooltips || {}}
                        type={props.type[tabName] ?? props.type}
                        updateCurrentColumns={(columns: any[]) => {
                          gridRef.current.api().setGridOption('columns', columns)
                          gridRef.current
                            .api()
                            .refreshServerSide({ route: undefined, purge: undefined })
                        }}
                        onManageColumns={() => {
                          gridRef
                            .current!.api()
                            .setSideBarVisible(!gridRef.current!.api().isSideBarVisible())
                          gridRef.current!.api().openToolPanel('columns')
                        }}
                      />
                    )}
                  </>
                )}
              </>
            </div>
          )}

          {window.innerWidth >= 1600 ? (
            <>
              {(!props.customDownloadName || props.customDownloadName == '') && (
                <>
                  <Button className="mx-2  " onClick={() => onDownload(1)} loading={state.loading}>
                    <i className="fa-duotone fa-file-excel mr-2" />
                    Download Excel
                  </Button>
                  {props.hideComponents && !props.hideComponents.includes('pdfDownload') && (
                    <Button className="mr-2 " onClick={() => onDownload(2)} loading={state.loading}>
                      <i className="fa-duotone fa-file-pdf mr-2" />
                      Download PDF
                    </Button>
                  )}
                  {props.showJSONDownload && (
                    <Button className="mr-2 " onClick={() => onDownload(3)} loading={state.loading}>
                      <i className="fa-duotone fa-file-pdf mr-2" />
                      Download JSON
                    </Button>
                  )}
                </>
              )}
              <div className="ml-auto ">
                {!state.loading &&
                  props.hideComponents &&
                  !props.hideComponents.includes('reportSettings') && (
                    <>
                      <ReportSettings
                        purchase_price_type={
                          props.showPurchasePriceSelection ? state.purchase_price_type : ''
                        }
                        showPurchasePriceReport={props.showPurchasePriceReport}
                        onChangePurchase={(reportName: string) => {
                          setState({
                            ...state,
                            purchase_price_type: 'purchase',
                            report_settings: false,
                          })
                        }}
                        onChange={(value: string) => {
                          store.set('reportsPurchasePriceType', value)
                          store.set('reportsSettings', false)
                          setState({
                            ...state,
                            purchase_price_type: value,
                            report_settings: false,
                          })
                          // props.callData()
                        }}
                        columns={props.allColumns[tabName] || []}
                        tooltips={props.tooltips || {}}
                        type={props.type[tabName] ?? props.type}
                        updateCurrentColumns={(columns: any[]) => {
                          gridRef.current.api().setGridOption('columns', columns)
                          gridRef.current
                            .api()
                            .refreshServerSide({ route: undefined, purge: undefined })
                        }}
                        onManageColumns={() => {
                          gridRef
                            .current!.api()
                            .setSideBarVisible(!gridRef.current!.api().isSideBarVisible())
                          gridRef.current!.api().openToolPanel('columns')
                        }}
                      />
                    </>
                  )}
                {props.customDownloadName && props.customDownloadName != '' && (
                  <Button
                    loading={state.loading}
                    // @ts-ignore
                    type="action"
                    className="mr-2 float-right"
                    onClick={() => {
                      if (!user.paid) {
                        subscriptionPlanRef.current.openModal('reports')
                        return
                      }
                      props.onCustomDownload()
                    }}
                  >
                    <i className="fa-regular fa-file-download mr-2" />
                    {}
                    Download Report
                  </Button>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div>
          <Tabs
            defaultActiveKey="0"
            className="mt-2"
            onChange={e => {
              setState({ ...state, activeTab: e })
              // setTabName(e)
            }}
            tabBarExtraContent={
              Object.keys(props.error_data || {}).length > 0 && (
                <div className="float-right">
                  <Button
                    type="link"
                    className="mr-2"
                    onClick={() => {
                      props.showErrorModal(props.error_data)
                    }}
                  >
                    <i className="fa-solid fa-info-circle mr-2 text-danger" />
                  </Button>
                </div>
              )
            }
            activeKey={tabName}
          >
            {Object.keys(props.data.data).map((report, index) => {
              return <TabPane tab={report.toUpperCase().replace('_', ' ')} key={report}></TabPane>
            })}
          </Tabs>
          <div className="mb-4">
            <ReportTable
              loading={state.loading}
              gridKey={gridKey}
              onReady={async (event: any) => {
                onGridReady(event, tabName)
              }}
              columns={props.allColumns[tabName] || []}
              sortable={false}
              ref={gridRef}
              rowId={false}
              readOnlyEdit={true}
              tooltipText={'item_info'}
              statusBar={props.footer}
              showTotals={props.tabWiseAmounts ? props.tabWiseAmounts[tabName] : null}
              // onColumnMoved={(column: string, toIndex: number) =>
              //   console.log('columns moved', column, toIndex)
              // }
              // onColumnVisible={() => {
              //   onColumnVisible()
              // }}
              onColumnChange={(params: any) => {
                onColumnChange(params)
              }}
            />
          </div>
        </div>{' '}
      </Spin>
      <hr />
      {/* <EnhancedSubscriptionModal onRef={ref => (subscription = ref)} /> */}
      <SubscriptionPlanModal ref={subscriptionPlanRef} />
    </>
  )
})
