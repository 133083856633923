import { useState, useEffect, createRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import ItemPriceListForm from '../forms/itemPriceListForm'
import { Button, Table, message } from 'antd'
import { getAPI } from 'services/jwt'
import { FLOATOPTIONS } from 'utils/constants'
import store from 'store'

export default function ProductPriceList({ product_id, variant_id, batch_id = 0 }) {
  const [priceLoading, setPriceLoading] = useState(false)
  const [itemPrices, setItemPrices] = useState([])
  const priceLists = useSelector(state => state.document.price_lists)
  const priceListFormRef = createRef()
  useEffect(() => {
    if (store.get('price_list') != 0) {
      getPriceListData()
    }
  }, [priceLists])

  useEffect(() => {
    getPriceListData()
  }, [product_id, variant_id])

  const onPriceListEdit = data => {
    data = {
      price_list_id: data.price_list_id,
      name: data.name,
      price: data.price,
      price_with_tax: data.price_with_tax,
      tax: data.tax,
      discount: data.discount,
    }
    priceListFormRef.current.showDrawer(data, product_id, variant_id, batch_id)
  }
  const onPriceListDelete = async data => {
    var req = {
      product_id: product_id,
      variant_id: variant_id,
      batch_id,
      price_list_id: data.price_list_id,
    }
    var data = await getAPI('product', 'delete_item_prices', req)
    if (data?.success) {
      message.success('Price list deleted successfully')
      getPriceListData()
    } else {
      message.error('Error in deleting price list')
    }
  }
  const priceListColumns = [
    { title: <FormattedMessage id="table.name" />, dataIndex: 'name', key: 'name' },

    {
      title: <FormattedMessage id="table.price" />,
      dataIndex: 'price',
      key: 'price',

      render: (text, data) => {
        return data.price > 0 ? (
          <span>{data.price.toLocaleString('en-IN', FLOATOPTIONS)}</span>
        ) : (
          <span>-</span>
        )
      },
    },
    {
      title: <FormattedMessage id="table.priceWithTax" />,
      dataIndex: 'price_with_tax',
      key: 'price_with_tax',
      render: (text, data) => {
        return data.price_with_tax > 0 ? (
          <span>{data.price_with_tax.toLocaleString('en-IN', FLOATOPTIONS)}</span>
        ) : (
          <span>-</span>
        )
      },
    },
    {
      title: <FormattedMessage id="table.tax" />,
      dataIndex: 'tax',
      key: 'tax',
      render: (text, data) => {
        return data.tax > 0 ? (
          <span>{data.tax.toLocaleString('en-IN', FLOATOPTIONS)}</span>
        ) : (
          <span>-</span>
        )
      },
    },
    {
      title: <FormattedMessage id="table.discount" />,
      dataIndex: 'discount',
      key: 'discount',
      render: (text, data) => {
        return data.discount > 0 ? (
          <span>{data.discount.toLocaleString('en-IN', FLOATOPTIONS)}</span>
        ) : (
          <span>-</span>
        )
      },
    },
    {
      title: <FormattedMessage id="table.actions" />,
      dataIndex: 'tax',
      key: 'product_id',
      render: (text, data) =>
        !data.is_null ? (
          <>
            <Button
              type="warning"
              size="small"
              onClick={() => onPriceListEdit(data)}
              className="mr-2"
            >
              <i className="fa fa-edit mr-1" />
              <span className="hidden sm:inline font-weight-bold">
                <FormattedMessage id="button.edit" />
              </span>
            </Button>
            <Button
              type="danger"
              size="small"
              onClick={() => onPriceListDelete(data)}
              className="mr-2"
            >
              <i className="fa fa-edit mr-1" />
              <span className="hidden sm:inline font-weight-bold">
                <FormattedMessage id="button.delete" />
              </span>
            </Button>
          </>
        ) : (
          <Button
            type="primary"
            size="small"
            onClick={() => onPriceListEdit(data)}
            className="mr-2"
          >
            ADD
          </Button>
        ),
      responsive: ['md'],
    },
  ]

  const getPriceListData = async () => {
    const req = {
      product_id,
      variant_id,
      batch_id,
    }
    setPriceLoading(true)

    const data2 = await getAPI('product', 'get_price_lists_data', req)
    if (data2?.success) {
      setItemPrices(data2.product_details?.[0]?.item_prices ?? [])

      let product_item_prices = data2?.product_details?.[0]?.item_prices ?? []
      let item_prices = []
      priceLists.map(price => {
        let item_price = product_item_prices.find(item => {
          const batchLevel = item.batch_id == batch_id
          const productLevel = item.batch_id == 0
          return item.price_list_id == price.price_list_id && (batch_id ? batchLevel : productLevel)
        })
        if (item_price) {
          let is_null =
            item_price.price === 0 &&
            item_price.price_with_tax === 0 &&
            item_price.tax === 0 &&
            item_price.discount === 0

          item_prices.push({ ...item_price, is_null })
        } else {
          item_prices.push({
            price_list_id: price.price_list_id,
            price: 0,
            price_with_tax: 0,
            discount: 0,
            tax: 0,
            name: price.name,
            is_null: true,
          })
        }
      })
      setItemPrices(item_prices)
      setPriceLoading(false)
    }
  }
  return (
    <div className="my-2">
      <Table
        columns={priceListColumns}
        dataSource={itemPrices}
        loading={priceLoading}
        size="small"
        pagination={{ pageSize: 5, defaultPageSize: 5 }}
      />
      <ItemPriceListForm ref={priceListFormRef} getPriceListData={getPriceListData} />
    </div>
  )
}
