import { Button, Input } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAPI } from 'services/jwt'
import store from 'store'

export class OTPVerification extends Component {
  formRefAddress = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      otp_sent: false,
      otp_verified: false,
      otp: '',
      input_value: store.get('mobile'),
    }
  }
  componentDidMount() {
    // this.props.onRef(this)
  }
  handleOTP = async () => {
    let req = {}
    if (!this.state.otp_sent && !this.state.otp_verified) {
      req = {
        mobile: store.get('mobile'),
        send_otp: true,
        verify_otp: false,
        otp: '',
        source: this.props.source,
      }
      const data = await getAPI('user', 'otp_verification', req)
      if (data) {
        if (data?.success) {
          this.setState({
            otp_sent: true,
            input_value: '',
          })
        }
      }
    } else if (this.state.otp_sent && !this.state.otp_verified) {
      req = {
        mobile: store.get('mobile'),
        send_otp: false,
        verify_otp: true,
        otp: this.state.otp,
        source: this.props.source,
      }
      const data = await getAPI('user', 'otp_verification', req)
      if (data) {
        if (data?.success) {
          this.setState(
            {
              otp_verified: true,
            },
            () => this.props.onVerify(),
          )
        }
      }
    }
  }

  render() {
    const { columns } = this.props
    return (
      <>
        <div className="mt-3">
          {!this.state.otp_verified ? (
            <>
              <p className="text-gray-500 mt-0 mb-1 font-size-14">
                OTP Verification is required to continue.
              </p>
              <Input.Group compact className="">
                <Input
                  placeholder={!this.state.otp_sent ? 'Mobile Number' : 'Enter OTP'}
                  onChange={e =>
                    this.setState({ otp: e.target.value, input_value: e.target.value })
                  }
                  style={{ width: '50%', height: '30px' }}
                  value={this.state.input_value}
                  disabled={!this.state.otp_sent && !this.state.otp_verified}
                />
                <Button onClick={this.handleOTP} size="default" className="mr-2 font-weight-bold">
                  {!this.state.otp_sent && !this.state.otp_verified ? 'Request OTP' : 'Verify OTP'}
                </Button>
              </Input.Group>
            </>
          ) : (
            <div className="mt-3 text-success">
              <i className="fa fa-check-circle mr-2" />
              OTP Verified Successfully
            </div>
          )}
        </div>
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    states: state.document.states,
  }
}

export default connect(mapStateToProps)(OTPVerification)
