import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Col, Input, Modal, Row, Tabs, Upload } from 'antd'
import SignatureCanvas from 'react-signature-canvas'

import FontSignatures from './fontSignatures'

const { TabPane } = Tabs

class SignatureOptions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      signature: '',
      uploadedSignature: null,
      previewImage: '',
      previewVisible: false,
      previewTitle: '',
    }
  }

  onRemove = () => {
    this.props.setUploadedSignature('')
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  handleCancel = () => this.setState({ previewVisible: false })

  render() {
    const penColor = this.props.theme == 'default' ? '#000000' : '#ffffff'

    return (
      <Tabs
        activeKey={this.props.signMethod}
        onChange={this.props.setSignMethod}
        style={{ width: '100%' }}
      >
        <TabPane tab="Upload" key="upload">
          <Modal
            open={this.state.previewVisible}
            title={this.state.previewTitle}
            footer={null}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            onCancel={this.handleCancel}
          >
            <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
          </Modal>
          <Upload
            name="image"
            listType="picture-card"
            className="avatar-uploader"
            // showUploadList={false}
            customRequest={this.props.customRequest}
            fileList={this.props.fileList}
            onRemove={this.onRemove}
            onPreview={this.handlePreview}
          >
            {this.props.uploadedSignature != '' && this.props.uploadedSignature != undefined
              ? // <img
                //   src={this.state.selectedSignature.image}
                //   alt="avatar"
                //   style={{ width: '100%' }}
                // />
                null
              : this.props.uploadButton}
          </Upload>
        </TabPane>
        <TabPane tab="Type" key="type">
          <Input
            placeholder="Signature"
            value={this.props.typedSignature}
            onChange={this.props.setTypedSignature}
          />
          <FontSignatures
            typedSignature={this.props.typedSignature}
            setFontSignatureImg={this.props.setFontSignatureImg}
          />
        </TabPane>
        <TabPane tab="Draw" key="draw">
          <Row>
            <Col span={24}>
              <SignatureCanvas
                penColor={penColor}
                canvasProps={{ width: 500, height: 250, className: 'sigCanvas' }}
                ref={this.props.signatureCanvas}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button onClick={() => this.props.signatureCanvas.current.clear()}>Clear</Button>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    )
  }
}

const mapStateToProps = state => {
  const { settings } = state

  return {
    theme: settings.theme,
  }
}

export default connect(mapStateToProps)(SignatureOptions)
