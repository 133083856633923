// eslint-disable-next-line no-unused-vars
import { Affix, Button, Card, Divider, Layout } from 'antd'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import { getAPI } from 'services/jwt'
import { DOCUMENT_SHORTCUTS } from 'utils/constants'
// import { motion } from 'framer-motion'

const PublicLayout = ({ children }) => {
  const [is_paid, setIsPaid] = React.useState(true)
  const [company_id, setCompanyID] = React.useState(0)

  useEffect(() => {
    let url = window.location.pathname
    url = url.split('/')
    var hash_id = url[url.length - 1]

    // if reports page
    if (url[url.length - 1] == 'reports') {
      setIsPaid(true)
      return
    } else if (url.length == 2) {
      const queryParams = queryString.parseUrl(window.location.href)?.query
      if (queryParams?.id) {
        const menu_name_shortcut = queryParams?.id?.substring(0, 2)
        if (DOCUMENT_SHORTCUTS[menu_name_shortcut]) {
          hash_id = queryParams?.id?.substring(2)
          get_paid_status(hash_id)
        }
      }
    } else {
      get_paid_status(hash_id)
    }
  }, [])
  const get_paid_status = async id => {
    if (id != '') {
      const data = await getAPI('utils', 'paid_status_public', { hash_id: id })
      if (data) {
        setIsPaid(data.paid_status == 1)
        setCompanyID(data.company_id)
      }
    }
  }

  return (
    <Layout
      className="bg-gray-50 bg-cover bg-center"
      // style={{
      //   backgroundImage:
      //     'url(https://vx-erp-resources.s3.ap-south-1.amazonaws.com/indiaflag10.webp)',
      // }}
    >
      {/* <motion.div
        initial={{ x: '100vw' }} // Start off-screen to the right
        animate={{ x: 0 }} // Slide in to its normal position
        transition={{ type: 'spring', stiffness: 300, damping: 30 }} // Smooth spring transition
        className="w-full h-full flex items-center justify-center p-5"
      > */}
      <Layout.Content className="mt-0 mb-4">
        <div className="cui__utils__content">{children}</div>
      </Layout.Content>
      {/* </motion.div> */}

      {is_paid == false && (
        <Affix offsetBottom={isMobile ? 9 : 18}>
          <div className="flex justify-center">
            {/*On Mobile*/}
            <Card className="bg-card-azure border-radius-medium mb-3 px-4 block sm:hidden">
              <div className="flex flex-col items-center">
                <div className="">
                  <span className="font-weight-bolder text-black font-size-18">
                    Create invoices in 10 seconds 🤩
                  </span>
                </div>

                <div className="">
                  <Button
                    type="sign-up"
                    size=""
                    onClick={event => window.open('https://getswipe.in?_campaignname=DocLinkFree')}
                    className="font-weight-bolder mt-3"
                  >
                    Try now for free 🚀
                  </Button>
                </div>
              </div>
            </Card>

            {/*On web*/}
            <Card className="mx-5 bg-card-azure border-radius-medium width-50p hidden sm:block">
              <div className="flex flex-row justify-between items-center">
                <div className="mr-4">
                  <div className="flex flex-row items-center">
                    <div className="mr-4">
                      <img
                        //src="/resources/images/swipe-white.svg"
                        src="/resources/images/swipe-black.svg"
                        className="height-32 pl-4"
                        alt="Swipe"
                      />
                    </div>

                    <div className="">
                      <span className="font-weight-bolder font-size-24 text-black">
                        Easily create invoices in 10 Seconds 🤩
                      </span>
                      <p className="mt-0 mb-0 font-size-16 text-black">
                        and share them with your customers!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mr-4">
                  <Button
                    type="sign-up"
                    size="large"
                    onClick={event => window.open('https://getswipe.in?_campaignname=DocLinkFree')}
                    className="font-weight-bolder float-right"
                  >
                    Try now for free 🚀
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </Affix>
      )}

      {/* removing this condition keeping for all companies. (is_paid == true && company_id > 400000))*/}

      <div className="flex justify-center items-center mb-16 mt-4">
        <Link
          to={{ pathname: 'https://getswipe.in?_campaignname=DocLinkPaid' }}
          target="_blank"
          className="flex items-center p-0 font-size-13"
        >
          <span className="mr-1 text-gray-300 font-weight-medium">Powered by</span>
          <img
            src="/resources/images/logo_swipe_gray.svg"
            className="h-5 align-bottom mr-1"
            alt="Swipe"
          />
        </Link>
        <Divider type="vertical" />
        <p className="font-size-13 mb-0 text-gray-300 ml-1">
          <Link
            to={{ pathname: 'https://getswipe.in/terms' }}
            target="_blank"
            className="font-weight-medium text-gray-300"
          >
            {' '}
            <FormattedMessage id="terms" />
          </Link>{' '}
          &{' '}
          <Link
            to={{ pathname: 'https://getswipe.in/policy' }}
            target="_blank"
            className="font-weight-medium text-gray-300"
          >
            <FormattedMessage id="Privacy" />
          </Link>
        </p>
      </div>
    </Layout>
  )
}

export default withRouter(PublicLayout)
