import React, { useState, useRef } from 'react'
import { Modal, Button, Form, Input, message, ButtonType } from 'antd'
import { getAPI } from 'services/jwt'
import BizBadge from 'components/badges/biz'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import store from 'store'

interface AddPriceListProps {
  type?: ButtonType
  block?: boolean
  icon?: React.ReactNode
  buttonText?: string
  buttonClassName?: string
  textClassName?: string
  slimButton?: boolean
  onPriceListAdded?: () => void
}

const AddPriceList: React.FC<AddPriceListProps> = ({
  type,
  block,
  icon,
  buttonText,
  buttonClassName,
  textClassName,
  slimButton,
  onPriceListAdded,
}) => {
  const [loading, setLoading] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [priceListName, setPriceListName] = useState('')
  const formRef = useRef<any>(null)
  const subscriptionPlanRef = useRef<any>(null)

  const addPriceList = async (priceListName: string) => {
    setLoading(true)
    const data = await getAPI('product', 'add_price_list', { price_list_name: priceListName })
    if (data && data.success) {
      message.success('Price List added')
      setShowAddModal(false)

      if (onPriceListAdded) {
        onPriceListAdded()
      }
    }
    setLoading(false)
  }

  const handleClick = () => {
    if (store.get('price_list') === 1) {
      addPriceList(priceListName)
    } else {
      subscriptionPlanRef.current.openModal('price_list')
    }
  }

  return (
    <>
      <SubscriptionPlanModal ref={subscriptionPlanRef} />

      {slimButton ? (
        <a onClick={() => setShowAddModal(true)} className="font-weight-bold mr-2">
          <i className="fa fa-plus-circle mr-2" />
          Price List
          {store.get('price_list') === 0 && <BizBadge />}
        </a>
      ) : (
        <Button
          type={type}
          onClick={() => setShowAddModal(true)}
          className={buttonClassName}
          block={block}
          icon={icon}
        >
          <span className={textClassName}>{buttonText}</span>
          {store.get('price_list') === 0 && <BizBadge />}
        </Button>
      )}

      <Modal
        open={showAddModal}
        footer={
          <Button type="primary" onClick={handleClick} loading={loading}>
            Add Price List
            {store.get('price_list') === 0 && <BizBadge />}
          </Button>
        }
        onCancel={() => setShowAddModal(false)}
      >
        <Form labelAlign="left" ref={formRef} name="productForm" layout="vertical">
          <Form.Item
            name="price_list_name"
            label="Name"
            rules={[{ required: true, message: 'Please fill price list name' }]}
          >
            <Input
              placeholder="Enter Price List Name"
              autoFocus
              onChange={e => setPriceListName(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

AddPriceList.defaultProps = {
  type: 'primary',
  block: false,
  icon: null,
  buttonText: 'Add Price List',
  buttonClassName: 'mb-5',
  textClassName: '',
  slimButton: false,
  onPriceListAdded: () => {},
}

export default AddPriceList
