import { Button, Image, Input, Modal, message } from 'antd'
import OtpVerification from 'components/other/otpVerification' // Import any additional components you need
import React, { useState } from 'react'
import { deleteAPI } from 'services/jwt'

const DeleteConfirmationModal = props => {
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [verified, setVerified] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleDeleteAll = async () => {
    if (!confirmDelete) {
      message.error('Incorrect value in input. Please enter Delete to proceed.')
      return
    }

    if (!verified) {
      message.error('Please verify to proceed.')
      return
    }

    setLoading(true)

    try {
      const data = await deleteAPI(props.apiBlueprint, props.apiFunction)
      if (data && data.success) {
        message.success(data.message)
        setConfirmDelete(false)
        setVerified(false)
        setLoading(false)
        props.callData(0, 12)
      }
    } catch (error) {
      message.error(error)
      setLoading(false)
    }
  }

  return (
    <Modal
      title={'Are you sure you want to delete all ' + props.source + '?'}
      visible={props.showDeleteAllModal}
      onCancel={() => props.onCancel()}
      width={640}
      loading={loading}
      centered
      destroyOnClose
      closeIcon={<i className="fa-solid fa-xmark"></i>}
      footer={
        confirmDelete && verified ? (
          <>
            <Button
              key="back"
              onClick={() => {
                props.onCancel()
                setConfirmDelete(false)
              }}
              loading={loading}
            >
              Cancel
            </Button>

            <Button
              className="font-weight-bold"
              type="danger-3"
              onClick={() => {
                handleDeleteAll()
              }}
              loading={loading}
            >
              Confirm
            </Button>
          </>
        ) : (
          <></>
        )
      }
    >
      <div>
        <p className="text-danger">
          Deleting {props.source} won't impact your transactions. This action is irreversible, and
          your invoices, purchases, and other transactions will remain intact.
        </p>

        <span className="flex justify-center">
          <Image
            src="https://vx-erp-resources.s3.ap-south-1.amazonaws.com/danger.webp"
            preview={false}
            className="m-0 p-0"
            style={{
              padding: 0,
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
            }}
            height={200}
          />
        </span>

        <div className="mt-5">
          <div className="text-gray-700 font-weight-bold">
            Still want to continue?
            <p className="text-gray-500 mt-0 mb-1 font-size-12">
              Please enter <span className="text-danger font-weight-bold font-size-16">Delete</span>
              {'  '}
              in the input to delete all {props.source}.
            </p>
          </div>

          <Input
            className="mt-1 text-danger font-weight-bold"
            size="medium"
            placeholder="Delete"
            type="text"
            style={{ width: '50%', height: '30px' }}
            onChange={e => {
              e.target.value === 'Delete' ? setConfirmDelete(true) : setConfirmDelete(false)
            }}
            onPaste={e => {
              message.error('Paste not allowed !') && e.preventDefault()
              return false
            }}
            onCopy={e => {
              message.error('Copy not allowed !') && e.preventDefault()
              return false
            }}
            disabled={confirmDelete}
          />
          {confirmDelete ? (
            <OtpVerification
              className="mt-3"
              onVerify={() => setVerified(true)}
              source={'delete_all_' + props.source}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </Modal>
  )
}

export default DeleteConfirmationModal
