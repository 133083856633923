import {
  GET_CUSTOMER_SETTINGS,
  GET_PRODUCT_SETTINGS,
  GET_VENDOR_SETTINGS,
  SET_CUSTOMER_SETTINGS,
  SET_PRODUCT_SETTINGS,
  SET_VENDOR_SETTINGS,
} from './types'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getAPIData } from 'services/jwt'
export function* get_product_settings() {
  const data = yield call(async () => await getAPIData('utils', 'product_settings'))
  if (data?.success) {
    yield put({ type: SET_PRODUCT_SETTINGS, payload: data.product_settings })
  }
}
export function* get_customer_settings() {
  const data = yield call(async () => await getAPIData('v2/customer', 'settings'))
  if (data?.success) {
    yield put({ type: SET_CUSTOMER_SETTINGS, payload: data.customer_settings })
  }
}
export function* get_vendor_settings() {
  const data = yield call(async () => await getAPIData('v2/vendor', 'settings'))
  if (data?.success) {
    yield put({ type: SET_VENDOR_SETTINGS, payload: data.vendor_settings })
  }
}
export default function* permissions() {
  yield all([takeEvery(GET_PRODUCT_SETTINGS, get_product_settings)])
  yield all([takeEvery(GET_CUSTOMER_SETTINGS, get_customer_settings)])
  yield all([takeEvery(GET_VENDOR_SETTINGS, get_vendor_settings)])
}
