import React, { Component } from 'react'
import { Tabs, Checkbox, AutoComplete } from 'antd'
import style from './style.module.scss'

const { TabPane } = Tabs

export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      autocompleteData: [],
    }
  }

  onSelect = data => {
    this.setState({ visible: true })
  }

  onSearch = searchText => {
    this.setState({
      autocompleteData: !searchText
        ? []
        : [
            { value: searchText },
            { value: searchText + ' ' + searchText },
            { value: searchText + ' ' + searchText + ' ' + searchText },
          ],
    })
  }

  render() {
    return (
      <div>
        <div className="m-2">
          <AutoComplete
            options={this.state.autocompleteData}
            style={{ width: '100%', borderRadius: '5px' }}
            onSelect={this.onSelect}
            onSearch={this.onSearch}
            placeholder="Search"
          />
        </div>
        <Tabs className={`${style.tabs} kit-tabs-bordered`} defaultActiveKey="1">
          <TabPane tab="Outlets" key="1">
            <div className="text-gray-6 mb-2">
              <ul className="list-unstyled">
                <li>
                  <a href="#" className="list-group-item list-group-item-action">
                    <Checkbox>SR Nagar</Checkbox>
                  </a>
                </li>
                <li>
                  <a href="#" className="list-group-item list-group-item-action">
                    <Checkbox>Madhapur</Checkbox>
                  </a>
                </li>
                <li>
                  <a href="#" className="list-group-item list-group-item-action">
                    <Checkbox>Banjara Hills</Checkbox>
                  </a>
                </li>
                <li>
                  <a href="#" className="list-group-item list-group-item-action">
                    <Checkbox>Gachibowli</Checkbox>
                  </a>
                </li>
              </ul>
            </div>
          </TabPane>
          <TabPane tab="Products" key="2">
            <div className="text-gray-6 mb-3">
              <ul className="list-unstyled">
                <li className="mb-2">
                  <Checkbox>Iphone X</Checkbox>
                </li>
                <li className="mb-2">
                  <Checkbox>Samsung Galaxy S20</Checkbox>
                </li>
                <li className="mb-2">
                  <Checkbox>OnePlus 8T</Checkbox>
                </li>
                <li className="mb-2">
                  <Checkbox>Vivo</Checkbox>
                </li>
              </ul>
            </div>
          </TabPane>
          {this.props.status && (
            <TabPane tab="Status" key="3">
              <div className="text-gray-6 mb-3">
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <Checkbox>Status 1</Checkbox>
                  </li>
                  <li className="mb-2">
                    <Checkbox>Status 2</Checkbox>
                  </li>
                  <li className="mb-2">
                    <Checkbox>Status 3</Checkbox>
                  </li>
                  <li className="mb-2">
                    <Checkbox>Status 4</Checkbox>
                  </li>
                </ul>
              </div>
            </TabPane>
          )}
        </Tabs>
      </div>
    )
  }
}

// const FilterHome = () => {
//   return (

//   )
// }

// export default FilterHome
