import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row, message } from 'antd'
import html2canvas from 'html2canvas'
import styles from './fontSignatures.module.scss'

const fontStyles = [
  'Buffalo',
  'Pacifico',
  'Creattion',
  'Scriptin',
  'MrDeHaviland',
  'MonsieurLaDoulaise',
  'MrsSaintDelafield',
  'TheQueenthine',
  'Julietta-Messie',
]
const DEFAULT_SIGNATURE_INPUT = 'Signature'

const FontSignatures = ({ typedSignature, setFontSignatureImg }) => {
  const [signatureInput, setSignatureInput] = useState(null)
  const [selectedSignature, setSelectedSignature] = useState(null)

  const handleSelectSignature = useCallback(async index => {
    setSelectedSignature(index)
    const signatureElement = document.getElementById(`signature-${index}`)
    if (signatureElement) {
      try {
        const canvas = await html2canvas(signatureElement)
        const dataUrl = canvas.toDataURL('image/png')
        setFontSignatureImg(dataUrl)
      } catch (error) {
        message.error('Image not supported')
      }
    }
  }, [])

  useEffect(() => {
    if (!typedSignature) {
      setSignatureInput(DEFAULT_SIGNATURE_INPUT)
    } else {
      setSignatureInput(typedSignature)
    }
    setSelectedSignature(null)
  }, [typedSignature])

  return (
    <>
      {[...Array(3)].map((_, rowIndex) => (
        <Row key={rowIndex} gutter={[16, 16]}>
          {[...Array(3)].map((_, colIndex) => {
            const fontIndex = rowIndex * 3 + colIndex
            const font = fontStyles[fontIndex]

            return (
              <Col
                key={colIndex}
                span={8}
                style={{
                  textAlign: 'center',
                  border: selectedSignature === fontIndex ? '0.05rem solid' : 'none',
                  cursor: 'pointer',
                }}
              >
                <div
                  id={`signature-${fontIndex}`}
                  className={styles.signatureFont}
                  style={{ fontFamily: font }}
                  onClick={() => handleSelectSignature(fontIndex)}
                >
                  {signatureInput}
                </div>
              </Col>
            )
          })}
        </Row>
      ))}
    </>
  )
}

export default FontSignatures
