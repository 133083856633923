import { Alert } from 'antd'
import React from 'react'

class InfoComponent extends React.Component {
  render() {
    const {
      className,
      title,
      description,
      learnMoreLink,
      learnMoreText,
      type,
      closable,
    } = this.props

    // const iconStyle = {
    //   color:
    //     type === 'success'
    //       ? 'lightgreen'
    //       : type === 'info'
    //       ? 'blue'
    //       : type === 'warning'
    //       ? 'gold'
    //       : type === 'error'
    //       ? 'red'
    //       : 'white',
    // }

    const icon = (
      <span>
        {type === 'success' && <i className="fa-solid text-forest fa-check-circle" />}
        {type === 'info' && <i className="fa-regular text-primary fa-info-circle" />}
        {type === 'warning' && <i className="fa-regular fa-exclamation-triangle" />}
        {type === 'error' && <i className="fa-regular text-danger fa-exclamation-circle" />}
        {!['success', 'info', 'warning', 'error'].includes(type) && (
          <i className="fa fa-exclamation-circle" />
        )}
      </span>
    )

    return (
      <div className={className}>
        <Alert
          onClose={this.props?.onClose}
          message={
            <span>
              {title != '' && <span className="font-weight-bold"> {title} </span>}
              {description != '' && <p className="mb-0 mt-1">{description}</p>}
              {learnMoreLink != '' && learnMoreLink != null && (
                <p className="mb-0 mt-2">
                  <a
                    className="pt-4 mb-0"
                    href={learnMoreLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="font-weight-medium">
                      {learnMoreText == '' ? 'Learn more' : learnMoreText}
                    </span>
                    <i className="fa-regular fa-arrow-up-right ml-2"></i>
                  </a>
                </p>
              )}
            </span>
          }
          type={type}
          icon={icon}
          showIcon={true}
          closable={closable}
          className="custom-alert"
        />
      </div>
    )
  }
}

export default InfoComponent

// action={
//             <Space>
//               {/*<Button type="text" size="small" ghost>
//                 Done
//               </Button>*/}
//               <span>Learn More</span>
//             </Space>
//           }
