import {
  GET_CATEGORIES,
  GET_CUSTOMER_COLUMNS,
  GET_VENDOR_COLUMNS,
  SET_BULK_EDIT_LOADING,
  SET_CATEGORIES,
  SET_CUSTOMER_COLUMNS,
  SET_VENDOR_COLUMNS,
} from './types'
export const getCustomerColumns = () => ({
  type: GET_CUSTOMER_COLUMNS,
})
export const setCustomerColumns = columns => ({
  type: SET_CUSTOMER_COLUMNS,
  payload: columns,
})
export const getVendorColumns = () => ({
  type: GET_VENDOR_COLUMNS,
})
export const setVendorColumns = columns => ({
  type: SET_VENDOR_COLUMNS,
  payload: columns,
})

export const getCategories = () => ({
  type: GET_CATEGORIES,
})
export const setCategories = categories => ({
  type: SET_CATEGORIES,
  payload: categories,
})
export const setBulkEditLoading = loading => ({
  type: SET_BULK_EDIT_LOADING,
  payload: loading,
})
