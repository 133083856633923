import { Card } from 'antd'
import React, { useEffect, useState } from 'react'
import { AvatarStackSmall } from './AvatarStack'
import store from 'store'
import { useIntercom } from 'react-use-intercom'

const FooterComponent = () => {
  const { boot, shutdown, hide, show, showNewMessage, update } = useIntercom()

  const [randomImage, setRandomImage] = useState('')
  useEffect(() => {
    let random_Image =
      'https://raw.githubusercontent.com/nishanta96/images/main/team/team/' +
      (Math.floor((Math.random() * 100) % 13) + 1) +
      '.png'
    setRandomImage(random_Image)
  }, [])
  return (
    <>
      <Card size="document" className="bg-gray-75 border-radius-none" bordered={false}>
        <div className="flex items-center justify-between h-full">
          <div>
            <AvatarStackSmall />
          </div>
          <a
            onClick={() => {
              if (store.get('paid') == 1) {
                showNewMessage('Hello Swipe! I want to renew my subscription')
              } else {
                window.open(
                  'https://api.whatsapp.com/send?phone=918121335436&text=Hello%20Swipe! I want to upgrade',
                )
              }
            }}
          >
            <div className="flex items-center">
              <span className="mr-4">
                <span className="font-size-14 lh-normal">Have more questions?</span>
                <p className="font-size-14 lh-normal">
                  <span className="text-primary">Talk to a Swipe specialist</span>
                </p>
              </span>
              <span className="">
                <img
                  src={randomImage}
                  alt="loading..."
                  className="rounded-full object-cover h-12 w-12"
                />
              </span>
            </div>
          </a>
        </div>
      </Card>
    </>
  )
}
export default FooterComponent
