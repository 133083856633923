import { Document, Font, Image, Link, Page, Text, View } from '@react-pdf/renderer'
import { registerFont } from 'components/fonts'
import React from 'react'
import { get_invoice_title, get_invoice_type } from '../export_condition'
import BillTo from './billTo_2'
import CompanyDetails from './company'
import InvoiceTitle from './invoiceTitle'
import InvoiceFooter from './invoiceFooter.js'
import InvoiceBankDetails from './invoiceBankDetails'
import InvoiceItemsTable from './invoiceItemsTable'
import InvoiceNotes from './invoiceNotes'
import { styles as getStyles } from './styles'
export default function Invoice({ invoice }) {
  const hyphenationCallback = word => {
    return word.split('')
  }

  registerFont(invoice)
  Font.registerHyphenationCallback(hyphenationCallback)

  var styles = getStyles(invoice)
  var PAPER_SIZE = invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8
  return (
    <Document>
      {invoice.type.map((item, index) => (
        <Page
          orientation={invoice.invoice_settings.pdf_orientation}
          wrap
          size={invoice.invoice_settings.paper_size}
          key={index}
          style={{
            ...styles.index_page,
            paddingTop:
              18 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_top ? invoice.invoice_settings.margin_top : 0),
            paddingBottom:
              36 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_bottom
                ? invoice.invoice_settings.margin_bottom
                : 0) +
              (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
            // paddingLeft: !!invoice.invoice_settings.margin_left
            //   ? invoice.invoice_settings.margin_left
            //   : 10,
            // paddingRight: !!invoice.invoice_settings.margin_right
            //   ? invoice.invoice_settings.margin_right
            //   : 10,
          }}
        >
          <InvoiceTitle
            invoice={invoice}
            title={get_invoice_title(
              invoice.document_type,
              invoice.document_title,
              invoice.with_tax,
              invoice.company.gstin,
              item,
              invoice,
            )}
            type={get_invoice_type(invoice.document_type, item)}
            color={invoice.company.color}
          />
          <View
            style={{
              // paddingTop: 18 * PAPER_SIZE,
              //  + (!!invoice.invoice_settings.margin_top ? invoice.invoice_settings.margin_top : 0),
              // paddingBottom:
              //   36 * PAPER_SIZE +
              //   (!!invoice.invoice_settings.margin_bottom
              //     ? invoice.invoice_settings.margin_bottom
              //     : 0) +
              //   (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
              // paddingHorizontal: 9 * PAPER_SIZE,
              paddingLeft: !!invoice.invoice_settings.margin_left
                ? invoice.invoice_settings.margin_left
                : 10,
              paddingRight: !!invoice.invoice_settings.margin_right
                ? invoice.invoice_settings.margin_right
                : 10,
            }}
          >
            <CompanyDetails
              invoice={invoice}
              title={get_invoice_title(
                invoice.document_type,
                invoice.document_title,
                invoice.with_tax,
                invoice.company.gstin,
                item,
                invoice,
              )}
              type={get_invoice_type(invoice.document_type, item)}
              color={invoice.company.color}
            />

            <BillTo invoice={invoice} type={item} />

            {item == 'Delivery Challan' && <InvoiceNotes invoice={invoice} type={item} />}
            <InvoiceItemsTable invoice={invoice} type={item} />

            <View style={styles.index_brandingLogo}>
              <Link src="https://getswipe.in" target="_blank">
                <Image
                  style={styles.global_banner}
                  src={{
                    uri:
                      'https://vx-erp-resources.s3.ap-south-1.amazonaws.com/random/Screenshot+2024-11-27+at+11.38.39%E2%80%AFAM.png',
                    //uri: '/resources/images/banner3.png',
                    method: 'GET',
                    headers: { 'Cache-Control': 'no-cache' },
                  }}
                  crossorigin="anonymous"
                />
              </Link>
            </View>
            {/*<InvoiceBankDetails invoice={invoice} type={item} />*/}

            {item != 'Delivery Challan' && <InvoiceNotes invoice={invoice} type={item} />}
            <View style={styles.index_brandingLogo}>
              <Link src="https://getswipe.in" target="_blank">
                <Image
                  style={styles.global_banner}
                  src={{
                    uri:
                      'https://vx-erp-resources.s3.ap-south-1.amazonaws.com/random/Screenshot+2024-11-27+at+11.00.21%E2%80%AFAM.png',
                    //uri: '/resources/images/banner3.png',
                    method: 'GET',
                    headers: { 'Cache-Control': 'no-cache' },
                  }}
                  crossorigin="anonymous"
                />
              </Link>
            </View>
          </View>

          <InvoiceFooter invoice={invoice} />
        </Page>
      ))}
    </Document>
  )
}
