import { StyleSheet } from '@react-pdf/renderer'

export const styles = invoice => {
  let FONT_FACTOR = invoice.invoice_settings.font_size
  let PAPER_SIZE = invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style
  // To select font style if language is English
  if (locale == 'English') {
    locale = font_style
  }
  return StyleSheet.create({
    globalContainer: {
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderColor: '#1d1d1f',
    },
    global_mt: {
      marginTop: 3.6 * PAPER_SIZE,
    },
    global_mb: {
      marginBottom: 3.6 * PAPER_SIZE,
    },
    global_borderTop: {
      borderTopWidth: 0.72,
    },
    global_borderBottom: {
      borderBottomWidth: 0.72,
    },
    global_borderRight: {
      borderRightWidth: 0.72,
    },
    global_borderLeft: {
      borderLeftWidth: 0.72,
    },

    // INVOICE TITLE
    invoiceTitle_row: {
      flexDirection: 'row',
      marginBottom: 5 * PAPER_SIZE,
    },
    invoiceTitle_labels: {
      width: '50%',
      textAlign: 'left',
      fontFamily: `helbold_${locale}`,
      letterSpacing: 2,
      fontSize: 8.1 * FONT_FACTOR,
      textTransform: 'uppercase',
    },
    invoiceTitle_info: {
      width: '50%',
      fontFamily: `helreg_${locale}`,
      fontSize: 7.2 * FONT_FACTOR,
      letterSpacing: 1,
      textAlign: 'right',
      color: '#333333',
    },
    invoiceTitle_dec: {
      fontFamily: `helmed_${locale}`,
      fontSize: 7 * FONT_FACTOR,
      letterSpacing: 0,
      color: '#333',
      textTransform: 'uppercase',
      textAlign: 'left',
      marginBottom: 2 * PAPER_SIZE,
      marginTop: -5 * PAPER_SIZE,
    },
    invoiceTitle_qrcode: {
      width: '54%',
      position: 'relative',
      textAlign: 'right',
      // marginTop: 2 * PAPER_SIZE,
      marginHorizontal: 'auto',
      marginRight: 0 * PAPER_SIZE,
    },

    invoiceTitle_qr_header_off: {
      width: '27%',
      textAlign: 'left',
      position: 'relative',
      marginHorizontal: 'auto',
      marginLeft: 0 * PAPER_SIZE,
      marginVertical: 2.7 * PAPER_SIZE,
    },

    // INVOICETITLE DETAILS
    invoiceTitle_headerContainer: {
      '@media max-width: 400': {
        flexDirection: 'column',
      },
      '@media min-width: 400': {
        flexDirection: 'row',
      },
      marginBottom: 2.7 * PAPER_SIZE,
      fontFamily: `helreg_${locale}`,
      // borderBottomWidth: 0.9,
      lineHeight: 1.26,
    },
    invoiceTitle_blockC: {
      width: '68%',
      flexDirection: 'row',
      paddingRight: 10 * PAPER_SIZE,
    },
    invoiceTitle_blockI: {
      width: '32%',
      flexDirection: 'row',
      textAlign: 'right',
      // paddingRight: 4.5,
    },
    invoiceTitle_blockCR: {
      width: '32%',
      paddingRight: 0,
    },
    invoiceTitle_blockC2: {
      paddingVertical: 4.5 * PAPER_SIZE,
      paddingHorizontal: 4.5 * PAPER_SIZE,
      flex: 1,
    },
    invoiceTitle_block2: {
      width: '100%',
      marginRight: 0,
    },
    invoiceTitle_invoiceContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    invoiceTitle_invoiceDate: {
      fontSize: 8.5 * FONT_FACTOR,
      color: '#141414',
    },
    invoiceTitle_label: {
      fontSize: 8.5 * FONT_FACTOR,
      color: '#141414',
      textAlign: 'left',
      fontFamily: `helbold_${locale}`,
      // fontFamily: `helitalic_${locale}`,
    },
    invoiceTitle_address: {
      fontSize: 8.5 * FONT_FACTOR,
    },
    invoiceTitle_bold: {
      fontFamily: `helbold_${locale}`,
    },
    invoiceTitle_large: {
      fontSize: 11.7 * FONT_FACTOR,
    },
    // invoiceTitle_labels: {
    //   color: '#141414',
    //   fontFamily: `helreg_${locale}`,
    //   fontSize: 8.5 * FONT_FACTOR,
    //   textAlign: 'right',
    // },
    // invoiceTitle_info: {
    //   fontSize: 8.5 * FONT_FACTOR,
    //   color: '#141414',
    //   textAlign: 'right',
    // },
    invoiceTitle_row: {
      flexDirection: 'row',
      paddingHorizontal: 0,
    },
    invoiceTitle_logo: {
      maxWidth: '72',
      maxHeight: '54',
      position: 'relative',
      textAlign: 'center',
      marginHorizontal: 'auto',
      marginTop: 4.5 * PAPER_SIZE,
      marginRight: 4.5 * PAPER_SIZE,
    },
    invoiceTitle_reportTitle: {
      fontFamily: `helbold_${locale}`,
      letterSpacing: 2 * PAPER_SIZE,
      fontSize: 8 * FONT_FACTOR,
      textTransform: 'uppercase',
      textAlign: 'right',
      // marginTop: 4.5 * PAPER_SIZE,
    },
    invoiceTitle_reportType: {
      color: '#141414',
      fontFamily: `helmed_${locale}`,
      letterSpacing: 1 * PAPER_SIZE,
      fontSize: 7 * FONT_FACTOR,
      marginBottom: 0.45 * PAPER_SIZE,
      textTransform: 'uppercase',
      textAlign: 'right',
    },
    invoiceTitle_dec: {
      fontFamily: `helmed_${locale}`,
      fontSize: 7 * FONT_FACTOR,
      letterSpacing: 0,
      color: '#333',
      textTransform: 'uppercase',
      textAlign: 'right',
    },

    // COMPANY DETAILS
    company_blockC: {
      width: '100%',
      flexDirection: 'row',
      alignContent: 'center',
    },
    company_blockC2: {
      paddingRight: 4.5 * PAPER_SIZE,
      flex: 1,
    },
    company_blockC50: {
      width: '50%',
      paddingHorizontal: 4.5 * PAPER_SIZE,
      paddingVertical: 2.7 * PAPER_SIZE,
    },
    company_blockC100: {
      width: '100%',
      paddingHorizontal: 4.5 * PAPER_SIZE,
      paddingVertical: 4.5 * PAPER_SIZE,
    },
    company_blockCA: {
      width: '50%',
    },
    company_blockCB: {
      width: '50%',
    },
    company_borderTop: {
      borderTopWidth: 0.72,
    },
    company_borderBottom: {
      borderBottomWidth: 0.72,
    },
    company_borderRight: {
      borderRightWidth: 0.72,
    },
    company_borderLeft: {
      borderLeftWidth: 0.72,
    },
    company_block: {
      flexDirection: 'row',
    },
    company_blockRow: {
      flexDirection: 'row',
      paddingHorizontal: 4.5 * PAPER_SIZE,
      paddingVertical: 4.5 * PAPER_SIZE,
    },
    company_blockCol: {
      flexDirection: 'column',
      paddingHorizontal: 4.5 * PAPER_SIZE,
      paddingVertical: 4.5 * PAPER_SIZE,
    },
    company_block3: {
      marginRight: 75 * PAPER_SIZE,
    },
    company_invoiceContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    company_text: {
      fontSize: 8.1 * FONT_FACTOR,
      color: '#141414',
    },
    company_text2: {
      // marginTop: 2 * PAPER_SIZE,
      fontSize: 8.1 * FONT_FACTOR,
      color: '#141414',
    },
    company_row: {
      flexDirection: 'row',
      paddingVertical: 0.9 * PAPER_SIZE,
      paddingHorizontal: 0,
    },
    company_labels: {
      color: '#141414',
      fontFamily: `helmed_${locale}`,
      textAlign: 'left',
      paddingRight: 9 * PAPER_SIZE,
      fontSize: 8.1 * FONT_FACTOR,
    },
    company_info2: {
      width: '100%',
      fontFamily: `helmed_${locale}`,
      fontSize: 8.1 * FONT_FACTOR,
      textAlign: 'right',
      color: '#141414',
    },
    company_irn: {
      fontSize: 7.2 * FONT_FACTOR,
      color: '#141414',
    },
    company_invoiceDate: {
      fontSize: 8.1 * FONT_FACTOR,
      color: '#141414',
    },
    company_address: {
      fontSize: 8.1 * FONT_FACTOR,
    },

    company_column: {
      width: '100%',
      flexDirection: 'column',
    },
    company_bold: {
      fontFamily: `helbold_${locale}`,
    },
    company_large: {
      fontSize: 9.9 * FONT_FACTOR,
    },
    company_logo: {
      maxWidth: '72',
      maxHeight: '72',
      position: 'relative',
      textAlign: 'center',
      marginHorizontal: 'auto',
      marginRight: 9 * PAPER_SIZE,
    },

    //BILL TO CUSTOMER/VENDOR
    billTo_blockC: {
      width: '100%',
      flexDirection: 'row',
      alignContent: 'center',
      marginBottom: 3.6,
    },
    billTo_blockC50: {
      width: '50%',
    },
    billTo_padding: {
      paddingRight: 4.5,
    },
    billTo_blockC100: {
      width: '100%',
    },
    billTo_blockCA: {
      width: '50%',
      marginRight: '2%',
      fontSize: 8.5 * FONT_FACTOR,
    },
    billTo_blockCB: {
      width: '50%',
    },
    billTo_reference: {
      paddingLeft: 4.5,
    },
    billTo_block1: {
      width: '50%',
      marginRight: '2%',
      fontSize: 8.5 * FONT_FACTOR,
    },
    billTo_block2: {
      width: '50%',
      marginRight: '2%',
      fontSize: 8.5 * FONT_FACTOR,
    },
    billTo_block3: {
      width: '34%',
      marginRight: 0,
      fontSize: 8.5 * FONT_FACTOR,
    },
    billTo_block4: {
      width: '34%',
      marginRight: 0,
      fontSize: 8.5 * FONT_FACTOR,
    },
    billTo_block5: {
      width: '66%',
      marginRight: 0,
      fontSize: 8.5 * FONT_FACTOR,
    },
    billTo_row: {
      flexDirection: 'row',
    },
    billTo_label: {
      fontFamily: `helbold_${locale}`,
      textAlign: 'left',
      fontSize: 8.5 * FONT_FACTOR,
      color: '#141414',
      paddingRight: 4.5 * PAPER_SIZE,
    },
    billTo_labels: {
      // width: '45%',
      color: '#141414',
      fontFamily: `helbold_${locale}`,
      textAlign: 'left',
      // paddingRight: 18 * PAPER_SIZE,
      fontSize: 8.5 * FONT_FACTOR,
    },
    billTo_info: {
      // width: '55%',
      fontSize: 8.5 * FONT_FACTOR,
      textAlign: 'left',
      //paddingRight: 9 * PAPER_SIZE,
      color: '#141414',
    },
    billTo_info2: {
      fontSize: 8.5 * FONT_FACTOR,
      textAlign: 'left',
      paddingRight: 9 * PAPER_SIZE,
      color: '#141414',
    },
    billTo_mb: {
      marginBottom: 1.8 * PAPER_SIZE,
    },
    billTo_smallFont: {
      fontSize: 8.5 * FONT_FACTOR,
    },
    billTo_bold: {
      fontFamily: `helbold_${locale}`,
    },
    billTo_billTo: {
      marginTop: 0,
      marginBottom: 0 * PAPER_SIZE,
      color: '#141414',
      fontFamily: `helbold_${locale}`,
      fontSize: 8.5 * FONT_FACTOR,
    },
    billTo_bold: {
      fontFamily: `helbold_${locale}`,
    },
    billTo_med: {
      fontFamily: `helmed_${locale}`,
    },
    billTo_mt: {
      marginTop: 3.6 * PAPER_SIZE,
    },
    billTo_mb: {
      marginBottom: 3.6 * PAPER_SIZE,
    },

    //INDEX
    index_page: {
      fontSize: 9 * FONT_FACTOR,
      paddingHorizontal: 21 * PAPER_SIZE,
      lineHeight: 1.3,
      flexDirection: 'column',
      fontFamily: `helreg_${locale}`,
      color: '#141414',
    },
    index_container: {
      borderWidth: 1,
      borderColor: '#1d1d1f',
    },
    index_logo: {
      width: 'auto',
      height: '36',
      position: 'relative',
      marginRight: 'auto',
      textAlign: 'left',
      marginBottom: 5 * PAPER_SIZE,
    },
    index_footer: {
      alignContent: 'bottom',
      position: 'absolute',
      fontFamily: `helmed_${locale}`,
      left: 21 * PAPER_SIZE,
      width: '100%',
    },
    index_footerNote: {
      fontSize: 8.1 * FONT_FACTOR,
      bottom: 18 * PAPER_SIZE,
      left: 21 * PAPER_SIZE,
      alignContent: 'bottom',
      position: 'absolute',
      fontFamily: `helmed_${locale}`,
      marginTop: 0,
    },
    index_alignRight: {
      fontSize: 8.1 * FONT_FACTOR,
      textAlign: 'right',
    },
    index_brandingFooter: {
      alignContent: 'bottom',
      position: 'absolute',
      fontFamily: `helmed_${locale}`,
      bottom: 27 * PAPER_SIZE,
      left: 21 * PAPER_SIZE,
      width: '100%',
      flexDirection: 'row',
      marginRight: 4.5 * PAPER_SIZE,
    },
    index_brandingLogo: {
      width: '100%',
      textAlign: 'right',
    },
    index_logo: {
      width: 'auto',
      height: 27 * PAPER_SIZE,
      position: 'relative',
      marginHorizontal: 'auto',
      marginRight: 0,
    },
    index_banner: {
      width: '100%',
      maxHeight: '72',
      position: 'relative',
      textAlign: 'right',
      paddingVertical: 2.7 * PAPER_SIZE,
      marginHorizontal: 'auto',
      marginLeft: 0,
    },
    index_watermark: {
      alignContent: 'center',
      position: 'absolute',
      bottom: '20%',
      left: '3%',
      width: '100%',
      opacity: 0.072,
    },

    //BANK DETAILS
    invoiceBankDetails_headerContainer: {
      flexDirection: 'column',
      fontFamily: `helreg_${locale}`,
      marginBottom: '22',
      lineHeight: 1.26,
    },
    invoiceBankDetails_headerContainer2: {
      flexDirection: 'column',
      fontFamily: `helreg_${locale}`,
      marginBottom: '9',
      lineHeight: 1.26,
    },
    invoiceBankDetails_blockC: {
      width: '100%',
      flexDirection: 'row',
      alignContent: 'center',
    },
    invoiceBankDetails_blockCA: {
      width: '100%',
    },
    invoiceBankDetails_blockCB: {
      width: '25%',
      // paddingRight: 4.5 * PAPER_SIZE,
    },
    invoiceBankDetails_block: {
      flexDirection: 'column',
    },
    invoiceBankDetails_block3: {
      marginRight: 75 * PAPER_SIZE,
    },
    invoiceBankDetails_invoiceContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    invoiceBankDetails_bankRow: {
      flexDirection: 'row',
      paddingVertical: 0.45 * PAPER_SIZE,
      paddingHorizontal: 0,
    },
    invoiceBankDetails_labels: {
      width: '25%',
      color: '#333333',
      fontFamily: `helreg_${locale}`,
      paddingRight: 4.5,
      fontSize: 8.82 * FONT_FACTOR,
    },
    invoiceBankDetails_info: {
      width: '75%',
      fontFamily: `helmed_${locale}`,
      fontSize: 8.82 * FONT_FACTOR,
      paddingRight: 4.5,
      textAlign: 'left',
      color: '#141414',
    },
    invoiceBankDetails_text: {
      fontSize: 8.1 * FONT_FACTOR,
      color: '#141414',
    },
    invoiceBankDetails_text2: {
      marginTop: 2 * PAPER_SIZE,
      fontSize: 8.1 * FONT_FACTOR,
      color: '#141414',
    },
    invoiceBankDetails_invoiceDate: {
      fontSize: 8.1 * FONT_FACTOR,
      color: '#141414',
    },
    invoiceBankDetails_label: {
      fontSize: 8.1 * FONT_FACTOR,
      color: '#141414',
      textAlign: 'left',
      fontFamily: `helbold_${locale}`,
      marginBottom: 1.8 * PAPER_SIZE,
      // fontFamily: `helitalic_${locale}`,
    },
    invoiceBankDetails_address: {
      fontSize: 8.1 * FONT_FACTOR,
    },
    invoiceBankDetails_row: {
      width: '100%',
      flexDirection: 'row',
    },
    invoiceBankDetails_column: {
      width: '100%',
      flexDirection: 'column',
    },
    invoiceBankDetails_bold: {
      fontFamily: `helbold_${locale}`,
    },
    invoiceBankDetails_block2: {
      width: '63',
      textAlign: 'right',
      paddingRight: 4.5,
      marginRight: 0 * PAPER_SIZE,
    },
    // invoiceBankDetails_bankNotes: {
    //   fontSize: 8.1 * FONT_FACTOR,
    //   marginTop: 2.7 * PAPER_SIZE,
    //   paddingRight: 4.5,
    //   color: '#333333',
    //   textAlign: 'left',
    // },
    invoiceBankDetails_upiId: {
      fontSize: 7.2 * FONT_FACTOR,
      textAlign: 'right',
    },
    invoiceBankDetails_upi: {
      position: 'relative',
      width: '4%',
      textAlign: 'left',
      marginTop: 4.5 * PAPER_SIZE,
      padding: 18 * PAPER_SIZE,
    },
    invoiceBankDetails_einvoice_block: {
      width: '100%',
      marginRight: 10 * PAPER_SIZE,
      paddingTop: 10.8 * PAPER_SIZE,
      textAlign: 'left',
      flexDirection: 'row',
    },
    invoiceBankDetails_qrcode: {
      width: '100%',
      position: 'relative',
      textAlign: 'left',
      paddingRight: 9 * PAPER_SIZE,
    },

    // NOTES and TERMS
    invoiceNotes_blockC: {
      width: '100%',
      flexDirection: 'row',
      alignContent: 'center',
    },
    invoiceNotes_blockC75: {
      width: '75%',
      padding: 4.5 * PAPER_SIZE,
    },
    invoiceNotes_blockC25: {
      width: '25%',
      padding: 4.5 * PAPER_SIZE,
    },
    invoiceNotes_block: {
      flexDirection: 'column',
    },
    invoiceNotes_label: {
      fontSize: 8.1 * FONT_FACTOR,
      color: '#141414',
      textAlign: 'left',
      fontFamily: `helbold_${locale}`,
      marginBottom: 1.8 * PAPER_SIZE,
      // fontFamily: `helitalic_${locale}`,
    },
    invoiceNotes_text: {
      fontSize: 8.1 * FONT_FACTOR,
      color: '#141414',
    },
    invoiceNotes_label2: {
      fontSize: 7.2 * FONT_FACTOR,
      color: '#333333',
      textAlign: 'right',
      fontFamily: `helreg_${locale}`,
    },
    invoiceNotes_signature: {
      marginTop: 2.7 * PAPER_SIZE,
      width: '99',
      position: 'relative',
      textAlign: 'right',
      marginHorizontal: 'auto',
      marginRight: 4.5 * PAPER_SIZE,
    },
    invoiceNotes_receiveSignBlock: {
      width: '32%',
      textAlign: 'right',
      marginVertical: 'auto',
      marginBottom: 0,
    },
    invoiceNotes_receiveSignText: {
      fontSize: 8.1 * FONT_FACTOR,
      paddingTop: 2.7,
      paddingLeft: 2.7,
      marginTop: 27,
      borderTopWidth: 1,
      borderColor: '#333333',
      textAlign: 'left',
    },

    //ITEM TABLE
    invoiceItemsTable_tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderTopWidth: 1,
      borderColor: '#1d1d1f',
    },
    invoiceItemsTable_amountinwords: {
      textAlign: 'right',
      fontSize: 8 * FONT_FACTOR,
      paddingHorizontal: 4.5 * PAPER_SIZE,
    },
    invoiceItemsTable_balance: {
      paddingVertical: 2.7 * PAPER_SIZE,
      color: '#343435',
    },
    invoiceItemsTable_row1: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      alignItems: 'center',
      height: 22 * PAPER_SIZE,
      fontSize: 11 * FONT_FACTOR,
      marginBottom: 1 * PAPER_SIZE,
    },
    invoiceItemsTable_bold: {
      fontFamily: `helbold_${locale}`,
    },
    invoiceItemsTable_dc_totals: {
      paddingVertical: 2.7 * PAPER_SIZE,
      paddingRight: 4.5,
      textAlign: 'right',
      color: '#343435',
    },
    invoiceItemsTable_borderTop: {
      borderTopWidth: 0.72,
      borderColor: '#1d1d1f',
    },
    invoiceItemsTable_amountPaid: {
      textAlign: 'right',
      fontSize: 9 * FONT_FACTOR,
      marginLeft: 13.5 * PAPER_SIZE,
      paddingRight: 4.5,
      marginTop: 0.9 * PAPER_SIZE,
      color: '#343435',
    },
    invoiceItemsTable_image: {
      width: 9.9 * PAPER_SIZE,
      height: 9.9 * PAPER_SIZE,
      position: 'absolute',
      textAlign: 'right',
      marginTop: 1.8 * PAPER_SIZE,
    },
    invoiceItemsTable_block: {
      flexDirection: 'row',
      marginTop: 0.9 * PAPER_SIZE,
    },
    invoiceItemsTable_balanceBlock: {
      marginVertical: 3.6 * PAPER_SIZE,
      borderBottomWidth: 1,
      borderColor: '#1d1d1f',
    },
    invoiceItemsTable_labels: {
      width: '78%',
      color: '#515154',
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      fontSize: 9 * FONT_FACTOR,
    },
    invoiceItemsTable_info: {
      width: '22%',
      fontSize: 9 * FONT_FACTOR,
      paddingRight: 4.5,
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      color: '#4F5454',
    },
    invoiceItemsTable_labels2: {
      width: '78%',
      color: '#343435',
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      fontSize: 9 * FONT_FACTOR,
    },
    invoiceItemsTable_info2: {
      width: '22%',
      fontSize: 9 * FONT_FACTOR,
      paddingRight: 4.5,
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      color: '#343435',
    },
    invoiceItemsTable_borderBlock: {
      width: '64%',
    },
    invoiceItemsTable_borderBlock2: {
      width: '36%',
      textAlign: 'right',
      borderBottomWidth: 0.2,
      borderColor: '#515154',
      marginTop: 2 * PAPER_SIZE,
    },
    invoiceItemsTable_BatchName: {
      fontSize: 7.5 * FONT_FACTOR,
      color: '#4F5454',
      display: 'block',
    },

    //BLANKSPACE
    invoiceTableBlankSpace_row: {
      flexDirection: 'row',
      fontSize: 8.1 * FONT_FACTOR,
      textAlign: 'center',
      fontFamily: `helmed_${locale}`,
    },
    invoiceTableBlankSpace_cell: {
      paddingLeft: 4.5,
      paddingVertical: 2.7 * PAPER_SIZE,
      paddingRight: 1.8 * PAPER_SIZE,
      borderRightWidth: 0.54,
      borderRightColor: '#1d1d1f',
    },
    invoiceTableBlankSpace_block2: {
      width: '100%',
      borderBottomWidth: 1,
      borderBottomColor: '#1d1d1f',
    },

    // TABLE FOOTER
    invoiceTableFooter_blockC: {
      width: '100%',
      flexDirection: 'row',
      alignContent: 'center',
      borderBottomWidth: 0.72,
      borderColor: '#141414',
    },
    invoiceTableFooter_blockCC: {
      width: '100%',
    },
    invoiceTableFooter_blockCA: {
      width: '60%',
      padding: 4.5 * PAPER_SIZE,
    },
    invoiceTableFooter_blockCB: {
      width: '40%',
    },
    invoiceTableFooter_row: {
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 12 * PAPER_SIZE,
      fontSize: 8.1 * FONT_FACTOR,
    },
    invoiceTableFooter_row1: {
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 18 * PAPER_SIZE,
      fontSize: 12.6 * FONT_FACTOR,
      borderBottomWidth: 0.72,
      borderBottomColor: '#1d1d1f',
    },
    invoiceTableFooter_row2: {
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 13 * PAPER_SIZE,
      fontSize: 8.1 * FONT_FACTOR,
      marginBottom: 1 * PAPER_SIZE,
      borderBottomWidth: 0.72,
      borderBottomColor: '#1d1d1f',
    },
    invoiceTableFooter_row3: {
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 13 * PAPER_SIZE,
      fontSize: 8.1 * FONT_FACTOR,
      borderBottomWidth: 0.72,
      borderColor: '#1d1d1f',
    },
    invoiceTableFooter_block: {
      width: '100%',
      borderBottomWidth: 0.72,
      borderBottomColor: '#1d1d1f',
    },
    invoiceTableFooter_amountinwords: {
      fontSize: 8.1 * FONT_FACTOR,
      marginBottom: 2,
    },
    invoiceTableFooter_itemsQty: {
      fontSize: 8.1 * FONT_FACTOR,
    },
    invoiceTableFooter_description: {
      width: '45%',
      textAlign: 'right',
      paddingRight: 0,
    },
    invoiceTableFooter_total: {
      width: '55%',
      textAlign: 'right',
      paddingRight: 4.5,
    },
    invoiceTableFooter_labels: {
      width: '45%',
      color: '#4F5454',
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      paddingLeft: 4.5,
    },
    invoiceTableFooter_info: {
      width: '55%',
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      color: '#4F5454',
      paddingRight: 4.5,
    },
    invoiceTableFooter_payments: {
      width: '100%',
      color: '#343435',
      textAlign: 'right',
      paddingRight: 4.5,
      fontFamily: `helmed_${locale}`,
      fontSize: 8.1 * FONT_FACTOR,
    },
    invoiceTableFooter_image: {
      width: 9.9 * PAPER_SIZE,
      height: 9.9 * PAPER_SIZE,
      position: 'absolute',
      textAlign: 'right',
      marginTop: 1.8 * PAPER_SIZE,
      marginRight: 1.8 * PAPER_SIZE,
    },
    invoiceTableFooter_bold: {
      fontFamily: `helbold_${locale}`,
    },
    invoiceTableFooter_proxima: {
      fontFamily: `helbold_${locale}`,
    },

    //TABLE HEADER
    invoiceTableHeader_container: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#1d1d1f',
      color: '#141414',
      fontSize: 8.1 * FONT_FACTOR,
      textAlign: 'center',
      fontFamily: `helmed_${locale}`,
      textOverflow: 'wrap',
    },
    invoiceTableHeader_cell: {
      paddingLeft: 4.5,
      paddingVertical: 2.7 * PAPER_SIZE,
      paddingRight: 1.8 * PAPER_SIZE,
      borderRightWidth: 0.54,
      borderRightColor: '#1d1d1f',
    },
    invoiceTableHeader_slno_dc: {
      width: '5%',
      paddingLeft: '3px',
      textAlign: 'left',
    },
    invoiceTableHeader_description_dc: {
      fontFamily: `helmed_${locale}`,
      width: '78%',
      textAlign: 'left',
      paddingRight: 4 * PAPER_SIZE,
    },
    invoiceTableHeader_qty_dc: {
      width: '17%',
      textAlign: 'right',
      paddingRight: 4 * PAPER_SIZE,
    },

    // ITEM HEADER
    invoiceItemHeader: {
      color: '#141414',
      fontSize: 8.1 * FONT_FACTOR,
      textAlign: 'left',
      fontFamily: `helmed_${locale}`,
      textOverflow: 'wrap',
      flexDirection: 'row',
      width: '100%',
      paddingVertical: 1.8 * PAPER_SIZE,
      backgroundColor: '#f9f9f9',
    },

    //TABLE ROW
    invoiceTableRow_row: {
      flexDirection: 'row',
      fontFamily: `helreg_${locale}`,
      // textOverflow: 'wrap',
    },
    invoiceTableRow_cell: {
      paddingLeft: 4.5,
      paddingVertical: 1.8 * PAPER_SIZE,
      paddingRight: 1.8 * PAPER_SIZE,
      borderRightWidth: 0.54,
      borderRightColor: '#1d1d1f',
    },
    invoiceTableRow_bold: {
      fontFamily: `helbold_${locale}`,
    },
    invoiceTableRow_med: {
      fontFamily: `helmed_${locale}`,
    },
    invoiceTableRow_image: {
      maxWidth: 54 * PAPER_SIZE,
      maxHeight: 54 * PAPER_SIZE,
      borderRadius: 4.5,
      position: 'relative',
      textAlign: 'center',
      marginHorizontal: 'auto',
      marginLeft: 0,
      marginRight: 3.6 * PAPER_SIZE,
      marginVertical: 2.7 * PAPER_SIZE,
    },
  })
}
