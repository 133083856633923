import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Affix,
  AutoComplete,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  Tooltip,
  Upload,
  message,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import axios from 'axios'
import SaveChangesModal from 'components/modal/saveChangesModal'
import CopyText from 'components/other/CopyText'
import SectionHeader from 'components/other/sectionHeader'
import {
  UserAvatar,
  dotOptions,
  formItemLayout,
  getBase64,
  getPincodeDetails,
  getStatusClass,
} from 'components/other/utility'
import Visibility from 'components/other/visibility'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { setVendorColumns } from 'redux/bulkEdit/actions'
import { getCountryInfo } from 'redux/countries/actions'
import {
  getCountries,
  getStates,
  getTdsSections,
  get_all_doc_counts,
  get_tcs_sections,
  set_all_doc_counts,
} from 'redux/document/actions'
import { getAPI, getAPIData, getMultipartAPI } from 'services/jwt'
import store from 'store'
import { ADMIN, CONSTANTS } from 'utils/constants'
import SettingForm from './customColumns'
import AddressForm from './addressForm'
import NewBadge from 'components/badges/new'
import VisibilityModal from 'components/modal/visibility'
import { getVendorSettings } from 'redux/permissions/actions'
const { Panel } = Collapse
const { Option } = Select

var emptyVendorData = {
  vendor_id: '',
  name: '',
  phone: '',
  gst: 0,
  email: '',
  gstin: '',
  company_name: '',
  billing: {
    address_1: '',
    address_2: '',
    city: '',
    state: undefined,
    pincode: '',
    country: undefined,
  },
  shipping_address: [],
  opening_balance: undefined,
  balance: 0,
  opening_balance_type: 1,
  dial_code: '',
  tags: [],
  notes: '',
  linked_customer: '',
  credit_limit: 0,
  price_list_id: undefined,
  profile_image: '',
}

export class VendorForm extends Component {
  formRef = React.createRef()
  subscriptionPlanRef = React.createRef()
  addressFormRef = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  saveChangesRef = React.createRef()
  visibilityRef = React.createRef()
  constructor(props) {
    super(props)
    emptyVendorData.dial_code = props?.user?.selectedCompany?.dial_code
    this.state = {
      shippingAddress: true,
      isFirstTime: false,
      isEdit: false,
      isEdit: false,
      states: [],
      loading: false,
      selectedData: { ...emptyVendorData },
      fetchLoading: false,
      opening_balance: undefined,
      opening_balance_type: 1,
      custom_fields: [],
      empty_custom_fields: {},
      is_tds: false,
      is_tcs: false,
      phone: '',
      dial_code: props?.user?.selectedCompany?.dial_code,
      countries: [],
      customerData: [],
      search_customer: '',
      open: false,
      linked_customer: -1,
      cc_emails: [],
      tags: [],
      timeout: null,
      selected_tags: [],
      show_gstin_exist: false,
      add_vendor_with_existing_gstin: false,
      selected_country_code: '',
      existing_vendors: [
        {
          name: '',
          phone: '',
          email: '',
          gstin: '',
        },
      ],
      initialData: {},
      visibility_modal_showed: false,
      is_ecom: false,
    }
  }

  onChangeOpeningBalanceType = e => {
    this.setState({ opening_balance_type: e.target.value })
  }

  onChangeOpeningBalance = e => {
    this.setState({ opening_balance: e.target.value })
  }

  async componentDidMount() {
    this.props.onRef(this)
    if (!this.props.all_doc_counts) {
      this.props.dispatch(get_all_doc_counts())
    }
    if (this.props?.tds_sections?.length == 0) {
      await this.props.dispatch(getTdsSections())
    }
    if (this.props?.tcs_sections?.length == 0) {
      await this.props.dispatch(get_tcs_sections())
    }
    if (this.props?.countries?.length == 0) {
      await this.props.dispatch(getCountries())
    }
    if (this.props?.states?.length == 0) {
      await this.props.dispatch(getStates())
    }
    if (this.props.is_branch && Object.keys(this.props.vendor_settings).length == 0) {
      this.props.dispatch(getVendorSettings())
    }

    this.setState({
      selected_country_code: this.props?.user?.selectedCompany?.country_code,
    })
    this._onSaveChanges = this._onSaveChanges.bind(this)
    this.onSaveChanges = _.throttle(this._onSaveChanges, 2000)
  }

  componentWillUnmount() {
    this.props.onRef(null)
    this.onSaveChanges.cancel()
  }

  onRefreshCustomFields = async () => {
    const get_custom_fields = await getAPI('utils', 'get_custom_party_fields', {
      party_type: 'vendor',
    })
    if (get_custom_fields?.success) {
      this.props.dispatch(setVendorColumns(get_custom_fields.custom_fields))
      let custom_fields = {}
      get_custom_fields.custom_fields.map(field => {
        custom_fields[field.custom_field_id] = ''
      })
      this.setState({
        custom_fields: get_custom_fields.custom_fields,
        empty_custom_fields: custom_fields,
      })
    }
  }
  checkCustomValues = (obj1, obj2) => {
    if (this.get_columns_list().length == 0) {
      return false
    }
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) {
      return true
    }

    for (let key of keys1) {
      if (!obj2.hasOwnProperty(key)) {
        return true
      }
    }

    for (let key of keys2) {
      if (!obj1.hasOwnProperty(key)) {
        return true
      }
    }

    for (let key of keys1) {
      return obj1[key] !== obj2[key]
    }

    return false
  }
  checkAddress = (obj1, obj2) => {
    return (
      obj1.address_1 !== obj2.address_1 ||
      obj1.address_2 !== obj2.address_2 ||
      obj1.city !== obj2.city ||
      obj1.pincode !== obj2.pincode ||
      obj1.state !== obj2.state ||
      obj1.country !== obj2.country
    )
  }
  checkChangedValues = values => {
    return (
      this.state.initialData &&
      (this.state.initialData.name != values.name ||
        this.state.initialData.email != values.email ||
        this.state.initialData.phone != this.state.phone ||
        this.state.initialData.company_name != values.company_name ||
        this.state.initialData.gstin != values.gstin ||
        this.state.initialData.dial_code != this.state.dial_code ||
        this.checkCustomValues(this.state.initialData.custom_values, values.custom_values) ||
        this.checkAddress(this.state.initialData.billing, values.billing))
    )
  }

  onFinish = values => {
    if (this.state.isEdit && this.checkChangedValues(values)) {
      this.saveChangesRef.current.showModal(values)
    } else {
      this.onSaveChanges(values)
    }
  }

  _onSaveChanges = async values => {
    this.setState({ loading: true })

    let tags = []
    for (let i = 0; i < this.state.selected_tags?.length; i++) {
      var t = this.state.tags?.find(tag => tag.tag_name == this.state.selected_tags[i])
      var tag = { tag_name: this.state.selected_tags[i], id: '' }
      if (t) {
        tag.id = t.tag_id
      } else {
        tag.id = -1
      }
      tags.push(tag)
    }
    let custom_fields = {}
    this.get_columns_list().map(item => {
      if (item.field_type == 'date' || item.field_type == 'datetime') {
        custom_fields[item.custom_field_id] = values['custom_values'][item.custom_field_id]
          ? values['custom_values'][item.custom_field_id].format(
              item.field_type == 'date' ? this.dateFormat : 'DD-MM-YYYY HH:mm:ss',
            )
          : ''
      } else {
        custom_fields[item.custom_field_id] = values['custom_values'][item.custom_field_id]
          ? values['custom_values'][item.custom_field_id]
          : ''
      }
    })
    if (
      this.props.is_branch &&
      this.props.permissions.role == ADMIN &&
      !this.state.isEdit &&
      !this.state.visibility_modal_showed
    ) {
      this.setState({ visibility_modal_showed: true }, () =>
        this.visibilityRef.current.showModal(values),
      )
      return
    }

    var reqData = {
      ...values,
      tags: tags,
      phone: this.state.phone,
      dial_code: this.state.dial_code,
      billing: {
        ...values.billing,
        address_1: values.billing.address_1,
        address_2: values.billing.address_2,
        city: values.billing.city,
        state: values.billing.state == undefined ? '' : values.billing.state,
        country: values.billing.country == undefined ? '' : values.billing.country,
      },
      shipping_address: this.state.selectedData.shipping_address,
      opening_balance: this.state.opening_balance == undefined ? 0 : this.state.opening_balance,
      opening_balance_type: this.state.opening_balance_type,
      linked_customer: this.state.linked_customer,
      cc_emails: this.state.cc_emails.join(','),
      custom_values: custom_fields,
      custom_fields,
      profile_image: this.state.selectedData.profile_image,
      is_ecom: this.state.is_ecom,
    }

    var url = 'add'
    if (this.state.isEdit) {
      reqData = {
        id: this.state.selectedData.vendor_id,
        ...reqData,
        is_tds: this.state.is_tds,
        is_tcs: this.state.is_tcs,
        tcs_section_id: this.state.is_tcs ? reqData.tcs_section_id : -1,
        tds_section_id: this.state.is_tds ? reqData.tds_section_id : 0,
      }
      url = 'update'
    }
    if (this.state.is_tds && !reqData.tds_section_id) {
      message.error('Please select TDS section')
      this.setState({ loading: false })
      return
    }
    if (this.state.is_tcs && !reqData.tcs_section_id) {
      message.error('Please select TCS section')
      this.setState({ loading: false })
      return
    }

    // check GSTIN existance
    if (
      reqData.gstin != undefined &&
      reqData.gstin != '' &&
      !this.state.add_vendor_with_existing_gstin
    ) {
      const gstin_exist = await getAPI('v2/vendor', 'check_gstin', {
        gstin: reqData.gstin,
      })
      if (
        (gstin_exist?.existing_vendors.length > 0 && !this.state.isEdit) ||
        (this.state.isEdit && gstin_exist.existing_vendors.length > 1)
      ) {
        message.error(this.props?.user?.selectedCompany?.tax_id_label + ' already exist')
        this.setState({
          show_gstin_exist: true,
          loading: false,
          existing_vendors: gstin_exist.existing_vendors,
        })
        return
      }
    }

    const data = await getAPI('v2/vendor', url, reqData)
    this.setState({ loading: false })
    if (data) {
      this.setState({ visible: false })
      this.props.onAPICalled(data.id, values.name)
      message.success('Vendor added successfully')
      const payload = {
        ...this.props.all_doc_counts,
        vendors: 1,
      }

      this.props.dispatch(set_all_doc_counts(payload))
    }
  }

  onAdd = async (vendor_name = '') => {
    const tags = await getAPIData('v2/customer', 'tags')
    if (tags && tags.success) {
      this.setState({ tags: tags.tags, all_tags: tags.tags })
    }

    this.onRefreshCustomFields()
    emptyVendorData.billing.country = this.props?.country_info?.[
      this.state.selected_country_code
    ]?.name
    if (this.state.isEdit || this.state.isFirstTime) {
      this.formRef.current.setFieldsValue({ ...emptyVendorData })
      if (this.state.custom_fields.length > 0) {
        this.formRef.current.setFieldsValue({
          custom_values: this.state.empty_custom_fields,
        })
      }
      // this.formRef.current?.resetFields()
      this.setState({
        isEdit: false,
        visible: true,
        opening_balance: emptyVendorData.opening_balance,
        opening_balance_type: emptyVendorData.opening_balance > 0 ? 2 : 1,
        cc_emails: [],
        is_tds: false,
        tds_selected_id: undefined,
        notes: '',
        search_customer: '',
        linked_customer: -1,
        is_tcs: false,
        tcs_selected_id: undefined,
        dial_code: this.props?.user?.selectedCompany?.dial_code,
      })
    } else {
      this.setState(
        {
          visible: true,
          isFirstTime: true,
          opening_balance: emptyVendorData.opening_balance,
          opening_balance_type: emptyVendorData.opening_balance > 0 ? 2 : 1,
          cc_emails: [],
          search_customer: '',
          linked_customer: -1,
          dial_code: this.props?.user?.selectedCompany?.dial_code,
        },
        () => {
          this.formRef.current.setFieldsValue({ ...emptyVendorData, name: vendor_name })
        },
      )
    }
  }

  onFill = async data => {
    var req = {
      id: data.vendor_id,
    }
    this.onRefreshCustomFields()
    var data = await getAPI('vendor', 'get_details', req)
    var tags = await getAPIData('v2/customer', 'tags')
    if (data && data.success) {
      data = data.vendor_details[0]
    }
    if (data?.tcs_section_id == -1) {
      data.tcs_section_id = undefined
    }
    if (data?.tds_section_id == 0) {
      data.tds_section_id = undefined
    }
    let custom_fields = {}
    data['price_list_id'] = data['price_list_id'] == -1 ? undefined : data['price_list_id']
    data['custom_fields'].map(item => {
      if (item.value == '') {
        item.value = undefined
      }
      if (item.field_type == 'date' || item.field_type == 'datetime') {
        // console.log(dayjs(item.value, { format: 'YYYY-MM-DD', strict: true }).isValid())
        const dateFormatRegex = /^\d{2}-\d{2}-\d{4}/
        if (item.value && !dateFormatRegex.test(item.value)) {
          item.value = undefined
        } else {
          item.value = item.value ? dayjs(item.value, this.dateFormat) : undefined
        }
      }
      custom_fields[item.custom_field_id] = item.value
    })
    data['custom_values'] = custom_fields
    tags = data.tags?.map(item => {
      let t = tags.tags?.find(tag => tag.tag_id == item.tag_id)
      if (t) {
        return t.tag_name
      }
    })
    data.cc_emails = data.cc_emails ? data.cc_emails.split(',') : []

    if (this.state.isFirstTime) {
      this.setState(
        {
          selected_tags: tags,
          tags: tags.tags,
          all_tags: tags.tags,
          selectedData: data,
          isEdit: true,
          visible: true,
          opening_balance: data.opening_balance,
          opening_balance_type: data.opening_balance > 0 ? 2 : 1,
          is_tds: data.is_tds == 1,
          phone: data.phone,
          dial_code: data.dial_code,
          linked_customer: data.customer_id,
          search_customer: data.customer_name,
          cc_emails: data.cc_emails,
          is_tcs: data.is_tcs == 1,
          initialData: data,
          is_ecom: data.is_ecom == 1,
        },
        () => {
          if (this.state.custom_fields.length > 0) {
            this.formRef.current.setFieldsValue({
              custom_values: this.state.empty_custom_fields,
            })
          }

          this.formRef.current.setFieldsValue({
            ...data,
            tags: tags,
            linked_customer: data.customer_name,
          })
        },
      )
    } else {
      // console.log(tags)
      this.setState(
        {
          selected_tags: tags,
          selectedData: data,
          isEdit: true,
          isFirstTime: true,
          opening_balance: data.opening_balance,
          opening_balance_type: data.opening_balance > 0 ? 2 : 1,
          is_tds: data.is_tds == 1,
          phone: data.phone,
          dial_code: data.dial_code,
          is_tcs: data.is_tcs == 1,
          is_ecom: data.is_ecom == 1,
          linked_customer: data.customer_id,
          search_customer: data.customer_name,
          cc_emails: data.cc_emails,
          tags: data.tags,
          initialData: data,
          visible: true,
        },
        () => {
          this.formRef.current.setFieldsValue({
            linked_customer: data.customer_name,
            tags: tags,
          })
        },
      )
    }
  }

  onClose = () => {
    this.setState({
      visible: false,
      dial_code: this.props?.user?.selectedCompany?.dial_code,
      phone: '',
      add_vendor_with_existing_gstin: false,
      initialData: {},
    })
  }
  get_columns_list = () => {
    if (this.state.isEdit) return this.state.selectedData.custom_fields
    return this.state.custom_fields.filter(item => item.is_active == 1)
  }

  fetchGSTDetails = async () => {
    this.setState({ fetchLoading: true })
    const gstin = this.formRef.current.getFieldsValue()['gstin']
    var req = {
      gstin: gstin,
    }
    const data = await getAPI('user', 'fetch_gst_details', req)
    if (data) {
      const gst_data = data.response
      this.setState({ is_ecom: data.response.is_ecom })
      this.formRef.current.setFieldsValue({
        billing: gst_data['billing'],
        company_name: gst_data['company_name'],
        pan: gstin.slice(2, 12),
      })
    }
    this.setState({ fetchLoading: false })
  }

  onCustomerSearch = async value => {
    clearTimeout(this.state.timeout)
    this.setState({
      search_customer: value,
      timeout: setTimeout(async () => {
        var req = {
          name: value,
          vendor_id: this.state.isEdit ? this.state.selectedData?.vendor_id : -1,
        }
        const data = await getAPI('utils', 'get_possible_customers', req)
        if (data?.success) {
          let customersData = data.customers.map(value => ({
            ...value,
            key: value.customer_id,
            value: (
              <span className="flex flex-row justify-between" key={value.customer_id}>
                <span className="flex flex-inline flex-row items-center">
                  {value.is_frequent && (
                    <Tooltip title="Frequently used">
                      <div>
                        <i className="fa-regular fa-clock ml-0 mr-2 text-gray-500"></i>
                      </div>
                    </Tooltip>
                  )}
                  <span className="flex flex-row">
                    <span className="flex flex-col">
                      <span className="mb-0 pb-0 font-weight-medium">
                        {value.name + (value.phone != '' ? ' (' + value.phone + ')' : '')}
                      </span>
                      <p className="font-size-12 mb-0">
                        <span className="text-gray-500">{value.gstin}</span> {value.company_name}
                        <span className="float-right text-gray-500">
                          {value.tags && value.tags != '' ? 'Tags: ' + value.tags : ''}
                        </span>
                      </p>
                    </span>
                  </span>
                </span>
                <span
                  className="font-size-12 float-right font-weight-bold"
                  style={{ color: getStatusClass(value.balance + value.opening_balance) }}
                >
                  <span className="font-size-12 mr-1">
                    {this.props?.user?.selectedCompany?.currency_symbol}
                  </span>
                  {Math.round(Math.abs(value.balance + value.opening_balance)).toLocaleString(
                    'en-IN',
                    dotOptions,
                  )}
                </span>
              </span>
            ),
          }))
          this.setState({ customerData: customersData })
        }
      }, 1200),
    })
    this.formRef.current.setFieldsValue({ linked_customer: value })
  }

  onSelect = (data, customerData) => {
    this.setState({
      selectedRow: customerData,
      search_customer: customerData.name,
      linked_customer: customerData.customer_id,
    })
  }
  handelLinkCustomer = link => {
    if (link) {
      if (this.state.linked_customer == -1) {
        message.error('Please select customer')
        return
      }
      let tags = this.state.selectedRow.tags?.map(item => {
        let t = this.state.all_tags?.find(tag => tag.tag_id == item.tag_id)
        if (t) {
          return t.tag_name
        }
      })
      this.formRef.current.setFieldsValue({
        ...this.state.selectedRow,
        balance: 0,
        tags: tags,
        linked_customer: this.state.selectedRow.name,
      })
      this.setState({
        selected_tags: tags,
        show_linking_modal: false,
        phone: this.state.selectedRow.phone,
        dial_code: this.state.selectedRow.dial_code,
        is_tds: this.state.selectedRow.is_tds == 1,
        is_tcs: this.state.selectedRow.is_tcs == 1,
        cc_emails: this.state.selectedRow.cc_emails.split(','),
      })
    } else {
      this.setState({ show_linking_modal: false, linked_customer: -1, search_customer: '' })
      this.formRef.current.setFieldsValue({ linked_customer: '' })
    }
  }
  onWritingPincode = async value => {
    const [success, data] = await getPincodeDetails(value, this.props.states, this.props.countries)
    if (success) {
      this.formRef.current.setFieldsValue({
        billing: {
          state: data.state,
          city: data.city,
        },
      })
    }
  }
  customRequest = async ({ onSuccess, onError, file }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }
    if (file) {
      const form = new FormData()
      form.append('profile_image', file)
      form.append('upload', false)
      form.append('is_delete', false)
      const data = await getMultipartAPI('v2/vendor', 'update_profile_image', form)
      if (data?.success) {
        message.success(data.message)
        this.setState({
          selectedData: {
            ...this.state.selectedData,
            profile_image: data.profile_image,
          },
        })
      }
    }
  }
  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('Only JPG/PNG files are allowed!')
    }
    const isLt1M = file.size / 1024 / 1024 < 3
    if (!isLt1M) {
      message.error('Image size must be less than 3MB!')
    }
    return isJpgOrPng && isLt1M
  }

  onSelectCountry = countryCode => {
    var payload = {
      country_code: countryCode,
    }

    if (countryCode != this.state.selected_country_code) {
      this.formRef?.current?.setFieldsValue({
        billing: {
          state: undefined,
        },
      })
    }

    if (this.props?.user?.selectedCompany?.country_code == 'IN') {
      if (this.props?.user?.selectedCompany?.country_code != countryCode) {
        this.formRef.current.setFieldsValue({
          billing: {
            state: '97-OTHER TERRITORY',
          },
        })
      }
    }
    this.setState(
      {
        selected_country_code: countryCode,
      },
      () => {
        this.props.dispatch(getCountryInfo(payload))
      },
    )
  }

  addressDeleteWhenAddingNewVendor = async (address_type, addr_id) => {
    var remaining_shipping_address = this.state.selectedData.shipping_address.filter(
      item => item.addr_id !== addr_id,
    )
    this.setState({
      selectedData: {
        ...this.state.selectedData,
        shipping_address: remaining_shipping_address,
      },
    })
  }

  addressDelete = async (address_type, addr_id) => {
    var req = {
      address_type: address_type,
      addr_id: addr_id,
      vendor_id: this.state.selectedData.vendor_id,
    }

    var data = await getAPI('v2/vendor', 'delete_address', req)
    if (data?.success) {
      message.success(data.message)
      this.onFill(this.state.selectedData)
    }
  }

  onAddressFinishWhenAddingVendor = async (values, addressType, addr_id, export_customer) => {
    var shipping_address = this.state.selectedData.shipping_address
    var objIndex = this.state.selectedData.shipping_address.findIndex(
      obj =>
        obj.addr_id === addr_id ||
        (obj.line1 == values.line1 &&
          obj.line2 == values.line2 &&
          obj.city == values.city &&
          obj.state == values.state &&
          obj.pincode == values.pincode),
    )
    if (objIndex != -1) {
      shipping_address[objIndex] = values
      shipping_address[objIndex]['addr_id'] = addr_id
      shipping_address[objIndex]['is_edit'] = true
    } else {
      values['is_edit'] = false
      values['addr_id'] = shipping_address.length
      shipping_address.push(values)
    }

    this.setState({
      selectedData: { ...this.state.selectedData, shipping_address: shipping_address },
    })
  }

  onAddressFinish = async (values, addressType, addr_id, export_vendor) => {
    if (!this.state.isEdit) {
      this.onAddressFinishWhenAddingVendor(values, addressType, addr_id)
      return
    }
    if (addr_id >= 0) {
      var shipping_address = this.state.selectedData.shipping_address
      var objIndex = this.state.selectedData.shipping_address.findIndex(
        obj => obj.addr_id === addr_id,
      )

      if (objIndex != -1) {
        shipping_address[objIndex] = values
        shipping_address[objIndex]['addr_id'] = addr_id
      } else {
        values['addr_id'] = addr_id
        shipping_address.push(values)
      }

      this.setState({
        selectedData: { ...this.state.selectedData, shipping_address },
      })
    } else {
      if (addressType == 'shipping') {
        var shipping_address = this.state.selectedData.shipping_address

        this.setState({
          selectedData: {
            ...this.state.selectedData,
            shipping_address: shipping_address,
          },
        })
      }
    }
  }

  render() {
    if (
      this.state.selectedData.profile_image != '' &&
      this.state.selectedData.profile_image != undefined
    ) {
      var fileList = [
        {
          uid: '-1',
          name: 'vendor_profile_image.png',
          status: 'done',
          url: this.state.selectedData.profile_image,
        },
      ]
    } else {
      var fileList = []
    }
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>
          {' '}
          <FormattedMessage id="button.upload" />{' '}
        </div>
      </div>
    )
    return (
      <>
        <Drawer
          title={
            <>
              <div className="flex items-center">
                <div className="mr-auto">
                  <span className="font-weight-bolder text-gray-900 font-size-21 mr-2">
                    {this.state.isEdit ? 'Update Vendor' : 'Add Vendor'}
                  </span>
                </div>
                <div className="">
                  <Button
                    onClick={() => this.formRef.current.submit()}
                    type="primary"
                    className="font-weight-bold"
                    loading={this.state.loading}
                  >
                    Save Vendor
                  </Button>
                </div>
              </div>
            </>
          }
          width={isMobile ? '100%' : '48%'}
          height={isMobile ? '90%' : '100%'}
          placement={isMobile ? 'top' : 'right'}
          closeIcon={<i className="fa-solid mr-4 fa-lg fa-xmark"></i>}
          closable={true}
          maskClosable={false}
          onClose={this.onClose}
          open={this.state.visible}
          footer={
            <>
              <Button
                onClick={() => this.formRef.current.submit()}
                type="primary"
                loading={this.state.loading}
              >
                {this.state.isEdit ? 'Update Vendor' : 'Add Vendor'}
              </Button>
              <Button className="ml-2" onClick={this.onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
            </>
          }
        >
          <Form
            {...formItemLayout}
            labelAlign="left"
            ref={this.formRef}
            name="vendorForm"
            onFinish={this.onFinish}
            initialValues={{
              ...this.state.selectedData,
            }}
          >
            <SectionHeader
              title="Basic Details"
              link={true}
              linkTo={e => {
                this.setState({ show_linking_modal: true }, () => this.onCustomerSearch(''))
              }}
              linkText="Link Customer"
            />
            <Card size="form" bordered={true} className="border-radius-small mb-4">
              <Row gutter={18}>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label={<FormattedMessage id="text.name" />}
                    rules={[
                      {
                        required: true,
                        message: () => (
                          <>
                            {'Please enter name'}
                            {message.error('Please enter name')}
                          </>
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder="Ratan Tata"
                      autoFocus
                      className="w-full"
                      // onBlur={() => this.getPossibleCustomers()}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="phone" label={<FormattedMessage id="text.phone" />}>
                    <div className="flex">
                      <Select
                        showSearch
                        placeholder="Select DialCode"
                        optionFilterProp="children"
                        style={{ width: '100px' }}
                        filterOption={(input, option) =>
                          (option.children[0].props.children + option.children[1].props.children)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        trigger="click"
                        dropdownStyle={{ minWidth: '270px' }}
                        onChange={value => {
                          this.setState({ dial_code: value })
                        }}
                        value={
                          this.state.dial_code == ''
                            ? this.props?.user?.selectedCompany?.dial_code
                            : this.state.dial_code
                        }
                      >
                        {this.props?.countries?.map((item, i) => (
                          <Option key={i} value={item.dial_code}>
                            <span className="font-weight-bold mr-2 w-50">
                              {'+' + item.dial_code}
                            </span>
                            <span className="text-gray-500 w-50">{item.name}</span>
                          </Option>
                        ))}
                      </Select>
                      <InputNumber
                        type="number"
                        placeholder="8121335436"
                        className="w-full ml-2"
                        value={this.state.phone}
                        onChange={e => this.setState({ phone: e })}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="email" label={<FormattedMessage id="text.email" />}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            {/* <div className="flex justify-between items-center mt-4">
            <h6 className="font-weight-bold text-black  mb-0">
              Custom Fields <span className="badge badge-gray pb-1 ml-1">OPTIONAL</span>{' '}
            </h6>
            <a
              onClick={e => {
                store.get('paid') == 0
                  ? this.subscriptionPlanRef.current.openModal("ewaybill", 4)
                  : this.custom_column.onCustomerCustomFields('vendor')
              }}
              type="action-o"
              className="font-weight-bold  sm:block flex justify-end mt-0"
            >
              <i className="fa fa-lg fa-plus-circle mr-2 text-action " />
              Custom Fields
            </a>
          </div> */}
            {/* <Divider className="mb-4" /> */}
            <SectionHeader title="Company Details" />
            <Card bordered={false} size="form" className="border-radius-small mb-4">
              <Row gutter={18}>
                <Col span={12}>
                  <Form.Item name="company_name" label={<FormattedMessage id="text.company" />}>
                    <Input placeholder="ABC Technologies" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {this.props?.user?.selectedCompany?.country_code == 'IN' ? (
                    <Form.Item
                      name="gstin"
                      label={this.props?.user?.selectedCompany?.tax_id_label}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              value == 'NA' ||
                              new RegExp(CONSTANTS.GSTIN_REGEX).test(value)
                            ) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error(
                                'Please enter a valid ' +
                                  this.props?.user?.selectedCompany?.tax_id_label,
                              ),
                            )
                          },
                        }),
                      ]}
                    >
                      <Input.Search
                        placeholder="29AABCT1332L000"
                        enterButton="Fetch Details"
                        loading={this.state.fetchLoading}
                        onChange={e => {
                          if (e.target.value.length == 15) {
                            this.fetchGSTDetails()
                          }
                        }}
                        onSearch={this.fetchGSTDetails}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item name="gstin" label={this.props?.user?.selectedCompany?.tax_id_label}>
                      <Input placeholder="100123456789012" />
                    </Form.Item>
                  )}
                </Col>
                <Col span={24}>
                  <Form.Item label={<FormattedMessage id="text.billingAddress" />}>
                    <Input.Group>
                      <Row gutter={18}>
                        <Col span={12}>
                          <Form.Item name={['billing', 'address_1']}>
                            <Input placeholder="Address Line 1" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name={['billing', 'address_2']}>
                            <Input placeholder="Address Line 2" />
                          </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                          <Form.Item name={['billing', 'city']}>
                            <Input placeholder="City" />
                          </Form.Item>
                        </Col> */}
                        <Col span={12}>
                          <Form.Item name={['billing', 'pincode']}>
                            <InputNumber
                              placeholder={this.props?.user?.selectedCompany?.labels?.pincode}
                              style={{ width: '100%' }}
                              onChange={this.onWritingPincode}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name={['billing', 'state']}>
                            <Select
                              showSearch
                              placeholder={`Select ${this.props?.user?.selectedCompany?.labels?.state}`}
                              optionFilterProp="children"
                              style={{ width: '100%' }}
                              disabled={
                                this.props?.user?.selectedCompany?.country_code == 'IN' &&
                                this.state.selected_country_code != 'IN'
                              }
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {this.props?.country_info?.[
                                this.state.selected_country_code
                              ]?.states?.map((item, i) => (
                                <Option key={i} value={item.name}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name={['billing', 'city']}>
                            <Input placeholder="City" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name={['billing', 'country']}>
                            <Select
                              showSearch
                              placeholder="Select Country"
                              optionFilterProp="children"
                              style={{ width: '100%' }}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onSelect={(value, option) => {
                                this.onSelectCountry(option.code)
                              }}
                            >
                              {this.props?.countries?.map((item, i) => (
                                <Option key={i} value={item.name} code={item.code}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Visibility type="custom_fields" className="mb-4" visible={this.state.visible} />
            {this.get_columns_list().length > 0 && (
              <>
                <SectionHeader
                  title="Custom Fields"
                  link={true}
                  linkTo={e => {
                    store.get('paid') == 0
                      ? this.subscriptionPlanRef.current.openModal('custom_fields')
                      : this.custom_column.onCustomerCustomFields('vendor')
                  }}
                  linkText="Add Custom fields"
                />
                <Card bordered={false} size="form" className="border-radius-small mb-4">
                  <Row gutter={18}>
                    {this.get_columns_list().length > 0 &&
                      this.get_columns_list().map(
                        (item, index) =>
                          item.is_active == 1 && (
                            <Col span={12}>
                              <Form.Item
                                name={['custom_values', item.custom_field_id.toString()]}
                                label={item.name}
                                key={index}
                                rules={[
                                  {
                                    required: item.is_required == 1,
                                    message: () => (
                                      <>
                                        {item.name + ' should not be empty'}
                                        {message.error(item.name + ' should not be empty')}
                                      </>
                                    ),
                                  },
                                ]}
                              >
                                {item.field_type == 'bigtext' ? (
                                  <TextArea rows={1} placeholder={item.name} className="w-full" />
                                ) : item.field_type == 'date' ? (
                                  <DatePicker
                                    placeholder={item.name}
                                    className="w-full"
                                    format={this.dateFormat}
                                  />
                                ) : item.field_type == 'number' ? (
                                  <InputNumber placeholder={item.name} className="w-full" />
                                ) : (
                                  <Input type={'text'} placeholder={item.name} className="w-full" />
                                )}
                              </Form.Item>
                            </Col>
                          ),
                      )}
                  </Row>
                </Card>
              </>
            )}

            <SectionHeader title="Optional Details" />
            <Card bordered={false} size="form" className="border-radius-small mb-4">
              <Row gutter={18}>
                <Col span={24}>
                  <Form.Item
                    label={<FormattedMessage id="text.openingBalance" />}
                    className="mb-4"
                    hidden={this.props.permissions.show_vendor_balance == 0}
                  >
                    <Form.Item name="opening_balance_type">
                      <Radio.Group
                        onChange={this.onChangeOpeningBalanceType}
                        value={this.state.opening_balance_type}
                        className="mb-2"
                      >
                        <Radio value={1}>{<FormattedMessage id="text.debit" />}</Radio>
                        <Radio value={2}>{<FormattedMessage id="text.credit" />}</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name="opening_balance">
                      {this.state.opening_balance_type == 1 && (
                        <>
                          <Input
                            placeholder="Enter Debit Amount"
                            onChange={this.onChangeOpeningBalance}
                            value={this.state.opening_balance}
                            className="text-danger font-weight-bold"
                            suffix={
                              this.state.opening_balance != 0 && (
                                <label className="mt-2" style={{ color: 'red' }}>
                                  <span>
                                    {' '}
                                    <FormattedMessage id="text.vendorPaysYou" />{' '}
                                    <span className="ml-1 mr-1">
                                      {this.props?.user?.selectedCompany?.currency_symbol}
                                    </span>{' '}
                                    {this.state.opening_balance}{' '}
                                  </span>
                                </label>
                              )
                            }
                          />
                        </>
                      )}
                      {this.state.opening_balance_type == 2 && (
                        <>
                          <Input
                            placeholder="Enter Credit Amount"
                            onChange={this.onChangeOpeningBalance}
                            value={this.state.opening_balance}
                            className="text-success font-weight-bold"
                            suffix={
                              this.state.opening_balance != 0 &&
                              this.state.opening_balance != '' && (
                                <label className="mt-2" style={{ color: 'green' }}>
                                  <span>
                                    <FormattedMessage id="text.youPayTheVendor" />{' '}
                                    <span className="ml-1 mr-1">
                                      {this.props?.user?.selectedCompany?.currency_symbol}
                                    </span>{' '}
                                    {this.state.opening_balance}{' '}
                                  </span>
                                </label>
                              )
                            }
                          />
                        </>
                      )}
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="balance"
                    label={<FormattedMessage id="text.balance" />}
                    hidden={this.props.permissions.show_vendor_balance == 0 || this.state.isEdit}
                  >
                    <Input placeholder="" disabled />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="linked_customer"
                    label={'Linked Customer'}
                    extra={
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.setState({ show_linking_modal: true })}
                      >
                        Change Customer
                      </span>
                    }
                  >
                    <Input type="text" value={this.state.search_customer} disabled />
                  </Form.Item>
                </Col>
                {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                  <>
                    <Col span={24}>
                      <Form.Item name="is_tds" label="TDS" className="mt-2">
                        <Switch
                          className="ml-2"
                          size="large"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={this.state.is_tds}
                          onChange={e => {
                            if (e && this.state.is_tcs) {
                              message.error('TCS and TDS cannot be applied together')
                              return
                            }
                            this.setState({ is_tds: e })
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {this.state.is_tds && (
                      <Col span={24}>
                        <Form.Item name={'tds_section_id'} label="TDS Percentage">
                          <Select
                            showSearch
                            placeholder="Select TDS Section"
                            optionFilterProp="children"
                            style={{ width: '100%', whiteSpace: 'nowrap' }}
                            filterOption={(input, option) =>
                              (
                                option.props.children.props.children[0].props.children +
                                ' ' +
                                option.props.children.props.children[1].props.children +
                                ' ' +
                                option.props.children.props.children[2].props.children
                              )
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.props?.tds_sections?.map((item, i) => (
                              <Option key={i} value={item.tds_id}>
                                <div style={{ whiteSpace: 'normal' }}>
                                  <span className="text-gray-700 font-weight-bold mr-2">
                                    {item.tax + '%'}
                                  </span>
                                  <span className="mr-1 font-weight-bold text-gray-700">
                                    {item.section}
                                  </span>
                                  <span className="mr-2 text-gray-500">{item.name}</span>
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={24}>
                      <Form.Item name="is_tcs" label="TCS" className="mt-2">
                        <Switch
                          className="ml-2"
                          size="large"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={this.state.is_tcs}
                          onChange={e => {
                            if (e && this.state.is_tds) {
                              message.error('TCS and TDS cannot be applied together')
                              return
                            }
                            this.setState({
                              is_tcs: e,
                              tcs_selected_section: e ? 1 : undefined,
                            })
                          }}
                        />
                      </Form.Item>
                    </Col>

                    {this.state.is_tcs && (
                      <Col span={24}>
                        <Form.Item name={'tcs_section_id'} label="TCS Percentage">
                          <Select
                            showSearch
                            placeholder="Select TCS Section"
                            optionFilterProp="children"
                            style={{ width: '100%', whiteSpace: 'nowrap' }}
                            value={this.state.tcs_selected_section}
                            filterOption={(input, option) =>
                              (
                                option.props.children.props.children[0].props.children +
                                ' ' +
                                option.props.children.props.children[1].props.children +
                                ' ' +
                                option.props.children.props.children[2].props.children
                              )
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={e => {
                              // console.log(e)
                              this.setState({
                                tcs_selected_section: e,
                              })
                            }}
                          >
                            {this.props?.tcs_sections?.map((item, i) => (
                              <Option key={i} value={item.tds_id}>
                                <div style={{ whiteSpace: 'normal' }}>
                                  <span className="text-gray-700 font-weight-bold mr-2">
                                    {item.tax + '%'}
                                  </span>
                                  <span className="mr-1 font-weight-bold text-gray-700">
                                    {item.section}
                                  </span>
                                  <span className="mr-2 text-gray-500">{item.name}</span>
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </Card>
            {/* DON'T REMOVE THIS COMMENT, WE NEED TO ADD THIS LATER */}
            {/* <SectionHeader
              title={
                <>
                  Shipping Address
                  <NewBadge />
                </>
              }
            />
            <Row className="mb-2">
              {this.state.selectedData?.shipping_address?.map((address, index) => (
                <Card
                  key={index}
                  size="small"
                  bordered={false}
                  style={{
                    width: 300,
                    cursor: 'pointer',
                  }}
                  className="mr-2 mb-2 mr-4 border-radius-small"
                >
                  <div className="">
                    <span>
                      <CopyText
                        label="Shipping Address"
                        text={
                          `${address.line1}${address.line1 ? ',$' : ''}` + // Add newline if line1 is not empty
                          `${address.line2}${address.line2 ? ',$' : ''}` + // Add newline if line2 is not empty
                          `${address.city}${address.city ? ',$' : ''}` + // Add newline if city is not empty
                          `${
                            address.state
                              ? address.state +
                                (address.pincode ? '-' + address.pincode : '') +
                                ',$'
                              : address.pincode
                              ? address.pincode + ',$'
                              : ''
                          }` + // Add state and pincode with correct formatting
                          `${address.country ? address.country : ''}`
                        } // Add country at the end
                      />
                      <p className="mb-1">{address.notes}</p>
                    </span>
                  </div>

                  <a
                    href="#"
                    onClick={e => {
                      this.addressFormRef?.current?.showAddressDrawer('shipping', address, {
                        party_id: this.state.selectedData.vendor_id,
                        party_type: 'vendor',
                        is_party_edit: this.state.isEdit,
                      })
                    }}
                    className="card-link text-action font-weight-bold mr-3"
                  >
                    Edit
                  </a>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={e =>
                      this.state.isEdit
                        ? this.addressDelete('shipping', address.addr_id)
                        : this.addressDeleteWhenAddingNewVendor('shipping', address.addr_id)
                    }
                    onClick={event => event.stopPropagation()}
                    onCancel={event => event.stopPropagation()}
                    placement="bottomLeft"
                  >
                    <span className="text-danger font-weight-bold" style={{ cursor: 'pointer' }}>
                      Delete
                    </span>
                  </Popconfirm>
                </Card>
              ))}
            </Row>
            <Button
              type="add-new-1"
              size="large"
              className="my-2 mb-4 px-4"
              onClick={e => {
                this.addressFormRef?.current?.showAddressDrawer(
                  'shipping',
                  { addr_id: -1 },
                  {
                    party_id: this.state.selectedData.vendor_id,
                    party_type: 'vendor',
                    is_party_edit: this.state.isEdit,
                  },
                )
              }}
            >
              <span className="font-weight-bold font-size-16 text-pink">
                <i className="fa fa-plus-circle fa-lg mr-2" />
                Shipping Address
              </span>
            </Button> */}
            <Affix offsetBottom={70}>
              <div className="upward-collapse-wrapper">
                <Collapse
                  className="custom-upward-collapse bg-card-orange-dark mt-2"
                  bordered={false}
                  defaultActiveKey
                  expandIcon={({ isActive }) => (
                    <span className="">
                      <i
                        className={`fa-solid fa-chevron-down collapse-align-center ${!isActive &&
                          'fa-rotate-270'}`}
                      />
                    </span>
                  )}
                >
                  <Panel
                    header={
                      <>
                        <span className="font-size-16 font-weight-bold">More Details?</span>
                        <p className="text-gray-900 font-size-12 font-weight-normal mb-0">
                          Add CC Emails, Tags, Notes , Price List , Debit Limit{' '}
                        </p>
                      </>
                    }
                    key="1"
                    className="custom-collapse-panel"
                  >
                    <Row gutter={18}>
                      <Col span={12}>
                        <Form.Item name="profile_image" label={'Profile Image'}>
                          <Upload
                            name="image"
                            listType="picture-card"
                            className="avatar-uploader"
                            accept=".png, .jpg, .jpeg"
                            customRequest={this.customRequest}
                            fileList={fileList}
                            beforeUpload={this.beforeUpload}
                            previewFile={file => {
                              if (file.url) {
                                return file.url
                              }
                              return file.preview
                            }}
                            onChange={info => {
                              if (info.file.status === 'removed') {
                                this.setState({
                                  file: null,
                                  selectedData: {
                                    ...this.state.selectedData,
                                    profile_image: '',
                                  },
                                })
                              }
                            }}
                          >
                            {this.state.selectedData.profile_image != '' &&
                            this.state.selectedData.profile_image != undefined
                              ? null
                              : uploadButton}
                          </Upload>
                        </Form.Item>
                      </Col>
                      {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                        <Col span={12}>
                          <Form.Item name="pan" label="PAN No.">
                            <Input placeholder="ABCDE1234F" />
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={12}>
                        <Form.Item
                          name="price_list_id"
                          label="Price List"
                          extra="Price List will be Automatically selected while creating the document (Only on web)"
                        >
                          {/* <InputNumber placeholder="" className="w-full" /> */}
                          <Select
                            placeholder="Select Price List"
                            className="w-full"
                            dropdownStyle={{
                              scrollBehavior: 'max-height',
                            }}
                          >
                            {this.props.price_lists?.map((item, index) => (
                              <Option value={item.price_list_id} key={index}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          name="credit_limit"
                          label="Debit Limit"
                          rules={[
                            {
                              type: 'number',
                              min: 0,
                              message: 'Debit Limit should be a positive number',
                            },
                          ]}
                          extra="Warning will be shown when vendor is exceeding the Debit limit while creating Purchase (Only on web)"
                        >
                          <InputNumber placeholder="" className="w-full" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="tags" label="Tags">
                          <Select mode="tags" onChange={e => this.setState({ selected_tags: e })}>
                            {this.state?.tags?.map((item, index) => (
                              <Option value={item.tag_name} key={index}>
                                {item.tag_name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="notes" label={<FormattedMessage id="text.notes" />}>
                          <TextArea placeholder="Notes" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="cc_emails"
                          label={<span>CC Emails</span>}
                          extra="These emails will be added as CC in all the emails sent to this customer"
                        >
                          {/* <ReactMultiEmail
                          placeholder="Add CC Emails separated by comma"
                          emails={this.state.cc_emails}
                          onChange={_emails => {
                            this.setState({ cc_emails: _emails })
                          }}
                          validateEmail={email => {
                            const ismail = isEmail(email)
                            if (!ismail) {
                              message.error('Please enter valid email')
                            }
                            return ismail // return boolean
                          }}
                          getLabel={(email, index, removeEmail = index) => {
                            return (
                              <div data-tag key={index}>
                                {email}
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                  <i className="fa-regular fa-xmark fa-lg"></i>
                                </span>
                              </div>
                            )
                          }}
                        /> */}
                          <Select
                            mode="tags"
                            value={this.state.cc_emails}
                            placeholder="CC"
                            style={{ width: '100%' }}
                            onChange={_emails => {
                              this.setState({ cc_emails: _emails })
                            }}
                            maxTagCount="responsive"
                            maxTagPlaceholder={omittedValues => (
                              <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                                <span>+{omittedValues.length}...</span>
                              </Tooltip>
                            )}
                          >
                            {this.state.cc_emails.map((email, index) => (
                              <Option key={index} value={email}>
                                {email}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </div>
            </Affix>
          </Form>

          <AddressForm onFinish={this.onAddressFinish} ref={this.addressFormRef} />
          <SettingForm
            onRef={ref => (this.custom_column = ref)}
            onAPICalled={() => {
              this.onRefreshCustomFields()
            }}
          />

          <SaveChangesModal
            ref={this.saveChangesRef}
            onFinish={this.onSaveChanges}
            onClose={this.onClose}
          />
        </Drawer>
        <Modal
          title="Link Customer"
          open={this.state.show_linking_modal}
          closable={false}
          maskClosable={false}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          width={800}
          footer={[
            <Button key="ok" type="primary" onClick={() => this.handelLinkCustomer(true)}>
              Link Customer
            </Button>,
            <Button key="submit" onClick={() => this.handelLinkCustomer(false)}>
              Cancel
            </Button>,
          ]}
        >
          <AutoComplete
            value={this.state.search_customer}
            onSearch={e => this.onCustomerSearch(e)}
            onSelect={this.onSelect}
            placeholder="Search Customer"
            style={{ width: '100%' }}
            options={this.state.customerData}
          />
          <p className="text-danger mt-4">
            * By clicking on link customer we will fill the customer details and the customer will
            be linked to the vendor
          </p>
        </Modal>

        <Modal
          centered
          title={
            <>
              <span className="text-danger font-weight-bold">Are you sure?</span>
            </>
          }
          visible={this.state.show_gstin_exist}
          onOk={() =>
            this.setState({ show_gstin_exist: false, add_vendor_with_existing_gstin: true })
          }
          onCancel={() =>
            this.setState({ show_gstin_exist: false, showDrawer: false }, () =>
              this.props.onExistingVendor(this.state.existing_vendors[0].name),
            )
          }
          okText="Continue adding"
          cancelText="Discard"
          footer={[
            <Button
              type="text"
              className="mr-3 font-weight-bolder"
              onClick={() =>
                this.setState({
                  show_gstin_exist: false,
                  add_vendor_with_existing_gstin: true,
                })
              }
            >
              Add Anyway
            </Button>,
            <Button
              type="primary"
              onClick={() =>
                this.setState({ show_gstin_exist: false, showDrawer: false }, () =>
                  this.props.onExistingVendor(this.state.existing_vendors[0].name),
                )
              }
            >
              Use {this.state.existing_vendors[0].name}
            </Button>,
          ]}
        >
          <p>
            <p className="text-danger font-weight-bold">
              {this.props?.user?.selectedCompany?.tax_id_label} already exists.
            </p>
            <div className="flex my-4">
              <UserAvatar
                name={
                  this.state.existing_vendors[0].company_name != '' &&
                  this.state.existing_vendors[0].company_name != null
                    ? this.state.existing_vendors[0].company_name
                    : this.state.existing_vendors[0].name
                }
              />
              <div>
                <span
                  className="mt-0 mb-2 font-weight-medium text-gray-900"
                  style={{ textOverflow: 'ellipsis' }}
                >
                  {this.state.existing_vendors[0].company_name ||
                    this.state.existing_vendors[0].name}
                </span>
                <span className="mb-2 mt-0" onClick={e => e.stopPropagation()}>
                  <p className="mt-0 mb-0 font-weight-medium">
                    {this.state.existing_vendors[0].phone != '' &&
                      this.state.existing_vendors[0].phone != null && (
                        <span>
                          <CopyText
                            label="Phone Number"
                            text={this.state.existing_vendors[0].phone}
                          />
                        </span>
                      )}
                  </p>
                </span>

                {this.state.existing_vendors[0].company_name != null && (
                  <span className="font-weight-normal" onClick={e => e.stopPropagation()}>
                    <p className="mt-0 mb-0 text-gray-75">
                      <CopyText
                        label="Company Name"
                        text={this.state.existing_vendors[0].company_name}
                      />
                    </p>
                    <p className="mt-0 mb-0 text-gray-75">
                      <CopyText
                        label={this.props?.user?.selectedCompany?.tax_id_label}
                        text={this.state.existing_vendors[0].gstin}
                      />
                    </p>
                  </span>
                )}
              </div>
            </div>
            <>
              Do you want to continue adding vendor with same{' '}
              {this.props?.user?.selectedCompany?.tax_id_label}?
            </>
          </p>
        </Modal>
        <VisibilityModal
          ref={this.visibilityRef}
          type="Vendor"
          onFinish={this.onFinish}
          visibility={this.props.vendor_settings?.visibility ?? true}
        />
      </>
    )
  }
}

class EnhancedFrom extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <VendorForm {...this.props} />
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    states: state.document.states,
    countries: state.document.countries,
    tds_sections: state.document.tdsSections,
    price_lists: state.document.price_lists,
    tcs_sections: state.document.tcsSections,
    all_doc_counts: state.document.all_doc_counts,
    user: state.user,
    country_info: state.countries.info,
    vendor_settings: state.permissions.vendorSettings,
    is_branch: state.user.selectedCompany?.is_branch,
  }
}

export default connect(mapStateToProps)(EnhancedFrom)
