import {
  GET_WAREHOUSES,
  GET_WAREHOUSE_PERMISSIONS,
  GET_WAREHOUSE_ROLES,
  SET_ALL_USERS,
  SET_DEFAULT_WAREHOUSE,
  SET_WAREHOUSES,
  SET_WAREHOUSE_PERMISSIONS,
  SET_WAREHOUSE_ROLES,
  GET_INVENTORY_CATEGORIES,
  SET_INVENTORY_CATEGORIES,
  SET_ALL_WAREHOUSES,
} from './types'

export const get_warehouses = () => {
  return {
    type: GET_WAREHOUSES,
  }
}
export const set_warehouses = data => {
  return {
    type: SET_WAREHOUSES,
    payload: data,
  }
}

export const set_all_warehouses = data => {
  return {
    type: SET_ALL_WAREHOUSES,
    payload: data,
  }
}

export const set_all_users = data => {
  return {
    type: SET_ALL_USERS,
    payload: data,
  }
}

export const get_warehouse_roles = () => {
  return {
    type: GET_WAREHOUSE_ROLES,
  }
}
export const set_warehouse_roles = data => {
  return {
    type: SET_WAREHOUSE_ROLES,
    payload: data,
  }
}

export const set_warehouse_permissions = data => {
  return {
    type: SET_WAREHOUSE_PERMISSIONS,
    payload: data,
  }
}

export const get_warehouse_permissions = () => {
  return {
    type: GET_WAREHOUSE_PERMISSIONS,
  }
}
export const set_default_warehouse = data => {
  return {
    type: SET_DEFAULT_WAREHOUSE,
    payload: data,
  }
}
export const get_inventory_categories = () => {
  return {
    type: GET_INVENTORY_CATEGORIES,
  }
}
export const set_inventory_categories = data => {
  return {
    type: SET_INVENTORY_CATEGORIES,
    payload: data,
  }
}
