import { Input, Select } from 'antd'
import MyEditor from 'components/other/editor'
import { EditorState, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import React, { Component, useState } from 'react'
import { useSelector } from 'react-redux'

const PhoneEditior = params => {
  const countries = useSelector(state => state.document.countries)
  const [dialCode, setDialCode] = useState(params.data.dial_code)
  const [phone, setPhone] = useState(params.data.phone || params.data.mobile || '')

  const saveData = (dial_code, phone) => {
    params.node.updateData({ ...params.data, dial_code, phone })
    params.stopEditing()
  }

  return (
    <>
      <div
        className="flex"
        onKeyDownCapture={e => {
          if (e.key == 'Enter') {
            e.stopPropagation()
            e.preventDefault()
            saveData(dialCode, phone)
          }
        }}
      >
        <Select
          showSearch
          placeholder="Select DialCode"
          optionFilterProp="children"
          style={{ width: '100px' }}
          filterOption={(input, option) =>
            (option.children[0].props.children + option.children[1].props.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          trigger="click"
          dropdownStyle={{ minWidth: '270px' }}
          className="mr-2"
          value={dialCode}
          onChange={e => {
            setDialCode(e)
          }}
        >
          {countries.map((item, i) => (
            <Option key={i} value={item.dial_code}>
              <span className="font-weight-bold mr-2 w-50">{'+' + item.dial_code}</span>
              <span className="text-gray-500 w-50">{item.name}</span>
            </Option>
          ))}
        </Select>
        <Input
          placeholder="Enter Phone Number"
          value={phone}
          type="number"
          style={{ width: '200px' }}
          onChange={e => {
            setPhone(e.target.value)
          }}
          onBlur={e => {
            saveData(dialCode, phone)
          }}
        />
      </div>
    </>
  )
}

export default PhoneEditior
