import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Drawer, Form, Input, Switch, Table, Tabs, Select } from 'antd'
import CustomDeleteModal from 'components/modal/delete/customFieldDelete'
import SectionHeader from 'components/other/sectionHeader'
import { formItemLayout } from 'components/other/utility'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAPI } from 'services/jwt'
import { FormattedMessage } from 'react-intl'
const { TabPane } = Tabs
const DocumentPrefixes = forwardRef((props, ref) => {
  const [document_type, setDocumentType] = useState('')
  const [all_document_prefixes, setAllDocumentPrefixes] = useState([])
  const [visible, setVisible] = useState(false)
  const [show_edit_prefix, setShowEditPrefix] = useState(false)
  const [prefix_data, setPrefixData] = useState({})
  const [is_prefix, setIsPrefix] = useState(true)
  const [activeTabKey, setActiveTabKey] = useState('')
  const user = useSelector(state => state.user)
  const empty_prefix_data = {
    id: -1,
    prefix: '',
    document_type: document_type,
    is_default: false,
    is_delete: false,
  }
  const empty_suffix_data = {
    id: -1,
    suffix: '',
    document_type: document_type,
    is_default: false,
    is_delete: false,
  }
  const prefixFormRef = React.createRef()
  const deleteModalRef = React.createRef()

  const get_document_prefixes = async document_type => {
    const data = await getAPI('utils', 'get_document_prefix', { document_type })
    if (data) {
      if (document_type == 'all') {
        setAllDocumentPrefixes(data.prefixes)
      } else {
        let new_document_prefixes = all_document_prefixes.filter(
          item => item.document_type != document_type,
        )
        new_document_prefixes = [...new_document_prefixes, ...data.prefixes]
        setAllDocumentPrefixes(new_document_prefixes)
      }
    }
  }
  const get_document_suffixes = async document_type => {
    const data = await getAPI('utils', 'get_document_suffix', { document_type })
    if (data) {
      if (document_type == 'all') {
        setAllDocumentPrefixes(data.suffixes)
      } else {
        let new_document_prefixes = all_document_prefixes.filter(
          item => item.document_type != document_type,
        )
        new_document_prefixes = [...new_document_prefixes, ...data.suffixes]
        setAllDocumentPrefixes(new_document_prefixes)
      }
    }
  }
  useImperativeHandle(ref, () => ({
    show_drawer,
  }))
  const show_drawer = (document_type, is_prefix) => {
    if (is_prefix) {
      setActiveTabKey('1')
    } else {
      setActiveTabKey('2')
    }
    setDocumentType(document_type)
    setVisible(true)
    setIsPrefix(is_prefix)
  }
  const update_prefix = async req => {
    const url = is_prefix ? 'document_prefix' : 'document_suffix'
    const data = await getAPI('utils', url, req)
    if (data) {
      if (is_prefix) {
        get_document_prefixes(document_type)
      } else {
        get_document_suffixes(document_type)
      }
    }
  }
  // const edit_prefix = data => {
  //   setPrefixData(data)
  //   setShowEditPrefix(true)
  // }
  const closeDrawer = () => {
    setVisible(false)
    props?.onFinish()
  }
  let columns = [
    {
      title: 'Prefix',
      dataIndex: 'prefix',
      key: 'prefix',
      width: '50%',
    },
    {
      title: 'Suffix',
      dataIndex: 'suffix',
      key: 'suffix',
      width: '50%',
    },
    {
      title: 'Default',
      dataIndex: 'is_default',
      width: '25%',
      render: (text, data) => {
        return (
          <Switch
            disabled={data.is_default}
            checked={data.is_default}
            onChange={() => update_prefix({ ...data, is_default: !data.is_default })}
          />
        )
      },
    },
    {
      title: 'Actions',
      width: '15%',
      render: (text, data) => {
        return (
          <div>
            <Button
              onClick={e => prefixFormRef.current.show_prefix_form(data, is_prefix)}
              className="ml-2"
              type="warning"
              size="small"
            >
              <i className="fa-solid fa-edit"></i>
              <span className="ml-2">
                <FormattedMessage id="button.edit" />
              </span>
            </Button>
            {!data.is_default && (
              // <Popconfirm
              //   title="Sure to delete?"
              //   onConfirm={e => update_prefix({ ...data, is_delete: true })}
              // >
              //   <span className="text-danger font-weight-bold" style={{ cursor: 'pointer' }}>
              //     Delete
              //   </span>
              // </Popconfirm>
              <Button
                className="ml-2"
                onClick={() => deleteModalRef?.current?.showDeleteModal(data, true)}
                size="small"
                type="danger"
              >
                <i className="fa-solid fa-trash"></i>
              </Button>
            )}
          </div>
        )
      },
    },
  ]
  useEffect(() => {
    if (visible) {
      if (activeTabKey == '1') {
        get_document_prefixes('all')
        setIsPrefix(true)
      } else {
        get_document_suffixes('all')
        setIsPrefix(false)
      }
    }
  }, [activeTabKey])
  return (
    <Drawer
      open={visible}
      onClose={() => closeDrawer()}
      width={'50%'}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      title={`Document Prefix/Suffixes`}
    >
      <Tabs
        activeKey={activeTabKey}
        tabBarExtraContent={{
          right: (
            <div data-beacon-id="add_document_prefixes">
              <SectionHeader
                title=""
                link={true}
                linkTo={e => {
                  prefixFormRef.current.show_prefix_form(
                    is_prefix ? empty_prefix_data : empty_suffix_data,
                    is_prefix,
                  )
                }}
                linkText={`Add ${is_prefix ? 'Prefix' : 'Suffix'}`}
                link_beacon_id="add_document_prefixes"
              />
            </div>
          ),
        }}
        onChange={e => {
          setActiveTabKey(e)
        }}
      >
        <TabPane tab="Prefixes" key="1"></TabPane>
        <TabPane tab="Suffixes" key="2"></TabPane>
      </Tabs>
      <Card className="mb-4">
        <Tabs
          activeKey={document_type}
          onChange={e => {
            setDocumentType(e)
          }}
        >
          <TabPane tab="Invoice" key="invoice"></TabPane>
          <TabPane tab="Purchase" key="purchase"></TabPane>
          <TabPane tab="Sales Return" key="sales_return"></TabPane>
          <TabPane tab="Purchase Return" key="purchase_return"></TabPane>
          <TabPane tab="Purchase Order" key="purchase_order"></TabPane>
          {user?.selectedCompany?.country_code == 'IN' && (
            <TabPane tab="Delivery Challan" key="delivery_challan"></TabPane>
          )}
          <TabPane tab="Quotation" key="estimate"></TabPane>
          <TabPane tab="Pro Forma Invoice" key="pro_forma_invoice"></TabPane>
          <TabPane tab="Subscription" key="subscription"></TabPane>
        </Tabs>
        <Table
          dataSource={all_document_prefixes.filter(item => item.document_type == document_type)}
          columns={columns.filter(item => {
            if (is_prefix) {
              return item.key != 'suffix'
            } else {
              return item.key != 'prefix'
            }
          })}
          pagination={false}
        />

        <Button
          type="dashed"
          style={{ borderColor: 'blue' }}
          onClick={() =>
            prefixFormRef.current.show_prefix_form(
              is_prefix ? empty_prefix_data : empty_suffix_data,
              is_prefix,
            )
          }
          block
          icon={<PlusOutlined className="font-weight-bold" style={{ color: 'blue' }} />}
        >
          <span className="font-weight-bold" style={{ color: 'blue' }}>
            Add {is_prefix ? 'Prefix' : 'Suffix'}
          </span>
        </Button>
      </Card>
      <PrefixForm
        ref={prefixFormRef}
        onFinish={() =>
          is_prefix ? get_document_prefixes(document_type) : get_document_suffixes(document_type)
        }
      />
      <CustomDeleteModal ref={deleteModalRef} onFinish={update_prefix} />
    </Drawer>
  )
})

export const PrefixForm = forwardRef((props, ref) => {
  const [prefix, setPrefix] = useState('')
  const [is_prefix, setIsPrefix] = useState(true)
  const [visible, setVisible] = useState(false)
  const [prefix_data, setPrefixData] = useState({})
  const formRef = React.createRef()
  const [prefixList, setPrefixList] = useState([])
  useImperativeHandle(ref, () => {
    return {
      show_prefix_form,
    }
  })
  const getPrefixes = async () => {
    const data = await getAPI('utils', 'get_document_prefix', {
      document_type: prefix_data.document_type,
    })
    if (data) {
      setPrefixList(data.prefixes)
    }
  }
  useEffect(() => {
    if (visible) {
      formRef?.current?.setFieldsValue({
        ...prefix_data,
        default_prefix: prefix_data.default_prefix != '' ? prefix_data.default_prefix : undefined,
      })
    }
    if (visible && !is_prefix) {
      getPrefixes()
    }
  }, [visible])
  const show_prefix_form = (prefix_data, is_prefix) => {
    setPrefixData(prefix_data)
    setVisible(true)
    setIsPrefix(is_prefix)
    setPrefix(is_prefix ? prefix_data.prefix : prefix_data.suffix)
  }
  const submitPrefix = async () => {
    let url = ''
    let payload = {}
    if (is_prefix) {
      url = 'document_prefix'
      payload = {
        ...prefix_data,
        prefix: prefix,
      }
    } else {
      ;(url = 'document_suffix'),
        (payload = {
          ...prefix_data,
          suffix: prefix,
        })
    }

    const data = await getAPI('utils', url, payload)
    if (data?.success) {
      setVisible(false)
      props.onFinish()
    }
  }

  return (
    <Drawer
      title={`Add/Edit ${is_prefix ? 'Prefix' : 'Suffix'}`}
      open={visible}
      onClose={() => setVisible(false)}
      width={'50%'}
      footer={
        <>
          <Button type="primary" onClick={submitPrefix}>
            Submit
          </Button>
        </>
      }
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
    >
      <Card className="mb-4">
        <Form {...formItemLayout} onFinish={submitPrefix} className="" ref={formRef}>
          <Form.Item
            label={`${is_prefix ? 'Prefix' : 'Suffix'}`}
            name={`${is_prefix ? 'prefix' : 'suffix'}`}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please input prefix!',
            //   },
            // ]}
          >
            <Input
              placeholder={`${is_prefix ? 'Prefix' : 'Suffix'}`}
              onChange={e => setPrefix(e.target.value)}
              value={prefix}
            />
          </Form.Item>
          {!is_prefix && (
            <Form.Item name="default_prefix" label="Default Prefix">
              <Select
                placeholder="Select default prefix"
                onChange={e =>
                  setPrefixData({
                    ...prefix_data,
                    default_prefix: e,
                  })
                }
              >
                {prefixList?.map((item, i) => {
                  return (
                    <Option key={i} value={item.prefix}>
                      {item.prefix}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Card>
    </Drawer>
  )
})
export default DocumentPrefixes
