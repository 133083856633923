import { useEffect } from 'react'

export function register(config) {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      // The URL constructor is available in all browsers that support SW.
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
      if (publicUrl.origin !== window.location.origin) {
        // Our service worker won't work if PUBLIC_URL is on a different origin
        // from what our page is served on. This might happen if a CDN is used to
        // serve assets; see https://github.com/facebook/create-react-app/issues/2374
        return
      }

      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

      const isLocalhost = Boolean(
        window.location.hostname === 'localhost' ||
          // [::1] is the IPv6 localhost address.
          window.location.hostname === '[::1]' ||
          // 127.0.0.1/8 is considered localhost for IPv4.
          window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
      )

      const registerValidSW = (swUrl, config) => {
        navigator.serviceWorker
          .register(swUrl)
          .then(registration => {
            registration.onupdatefound = () => {
              const installingWorker = registration.installing
              if (installingWorker == null) {
                return
              }
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                    if (config && config.onUpdate) {
                      config.onUpdate(registration)
                    }
                  } else {
                    if (config && config.onSuccess) {
                      config.onSuccess(registration)
                    }
                  }
                }
              }
            }
          })
          .catch(error => {
            console.error('Error during service worker registration:', error)
          })
      }

      const checkValidServiceWorker = (swUrl, config) => {
        fetch(swUrl)
          .then(response => {
            const contentType = response.headers.get('content-type')
            if (
              response.status === 404 ||
              (contentType != null && contentType.indexOf('javascript') === -1)
            ) {
              navigator.serviceWorker.ready.then(registration => {
                registration.unregister().then(() => {
                  window.location.reload()
                })
              })
            } else {
              registerValidSW(swUrl, config)
            }
          })
          .catch(() => {
            console.log('No internet connection found. App is running in offline mode.')
          })
      }

      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config)
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://bit.ly/CRA-PWA',
          )
        })
      } else {
        registerValidSW(swUrl, config)
      }

      // Cleanup function
      return () => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage)
        }
      }
    }
  }, [])
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister()
    })
  }
}

const handleServiceWorkerMessage = event => {
  if ('data' in event && 'isTrusted' in event.data && event.data.isTrusted) {
    window.location.reload()
  }
}

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      if ('serviceWorker' in navigator) {
        // Get all service workers
        // navigator.serviceWorker.getRegistrations().then(function(registrations) {
        //   for (let registration of registrations) {
        //     console.log('Service worker unregistered', registration)
        //     registration.unregister()
        //   }
        // })

        navigator.serviceWorker
          .register('../firebase-messaging-sw.js', { scope: '/' })
          .then(function(registration) {
            console.log('Firebase messaging service worker registered')
            return registration.scope
          })
          .catch(function(err) {
            console.log('Error registering firebase messaging service worker', err)
            return err
          })

        // navigator.serviceWorker
        //   .register('../sw.js', { scope: '/store' })
        //   .then(function(registration) {
        //     console.log('Service worker registered')
        //     return registration.scope
        //   })
        //   .catch(function(err) {
        //     console.log('Error registering service worker', err)
        //     return err
        //   })

        // navigator.serviceWorker
        //   .register('../sw.js', { scope: '/' })
        //   .then(function(registration) {
        //     console.log('Service worker registered')
        //     return registration.scope
        //   })
        //   .catch(function(err) {
        //     console.log('Error registering service worker', err)
        //     return err
        //   })
      }
    })
  }
}
