import { all } from 'redux-saga/effects'
import agents from './agent/saga'
import bulkedit from './bulkEdit/saga'
import cart from './cart/sagas'
import document from './document/saga'
import menu from './menu/sagas'
import settings from './settings/sagas'
import user from './user/sagas'
import warehouseSaga from './warehouses/saga'
import welcome from './welcome/sagas'
import countries from './countries/saga'
import permissions from './permissions/saga'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    welcome(),
    cart(),
    document(),
    bulkedit(),
    agents(),
    warehouseSaga(),
    countries(),
    permissions(),
  ])
  // yield fork()
}
