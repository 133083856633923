import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  message,
} from 'antd'
import PaymentModes from 'components/other/paymentModes'
import SectionHeader from 'components/other/sectionHeader'
import UploadFiles from 'components/other/uploadFiles'
import { formItemLayout } from 'components/other/utility'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import { PaymentDocument } from 'pages/payment/paymentView'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { getTdsSections, get_payment_modes } from 'redux/document/actions'
import { getAPI } from 'services/jwt'
import BankForm from './onlyBankForm'

const { Panel } = Collapse
const { TextArea } = Input
const { Option } = Select
dayjs.extend(utc)

class PaymentForm extends Component {
  dateFormat = 'DD-MM-YYYY'
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      formFlag: false,
      type: '',
      paid: 0,
      loading: false,
      use_balance: false,
      bal: 0,
      payment_types: [],
      payment_type: 'in',
      name: '',
      banks: [],
      paymentData: '',
      paymentFlag: false,
      advance_payments: [],
      is_settle: false,
      tds_selected_section: 0,
      tds_sections: [],
      net_amount: 0,
      total_amount: 0,
      selectedData: {
        customer: {
          email: '',
        },
      },
      view_document_details: false,
      bank_id: undefined,
      expandedRowKeys: [],
      collapseActiveKey2: '2',
      collapseActiveKey1: '',
      show_settle_warning: false,
      is_advance_payment: false,
      attachments: [],
      initialValuesObject: {},
      paymentModeChanged: false,
    }
    this._onFinish = this._onFinish.bind(this)
    this.onFinish = _.throttle(this._onFinish, 2000)
  }

  async componentDidMount() {
    this.props.onRef(this)

    if (this.props?.payment_types?.length == 0 || this.props?.banks?.length == 0) {
      await this.props.dispatch(get_payment_modes())
    }
    if (this.props?.tds_sections?.length == 0) {
      await this.props.dispatch(getTdsSections())
    }
  }

  componentWillUnmount() {
    this.props.onRef(null)
    this.onFinish.cancel()
  }

  _onFinish = async values => {
    this.setState({ loading: true })
    if (values.bank_id == undefined && this.state.type != 'Cash' && this.state.type != 'TDS') {
      message.error('Please add bank for Non Cash Payment')
      this.setState({ loading: false })
      return
    }
    const resArray = [
      { serial_number: this.state.selectedData.serial_number, amount_paying: values.pay },
    ]
    const document_type = this.state.document_type
    let payment_api = 'create_payment_in'
    let document_name = 'invoices'
    if (document_type == 'purchase') {
      document_name = 'pos'
      payment_api = 'create_payment_out'
    } else if (document_type == 'sales_return') {
      document_name = 'sales_returns'
      payment_api = 'create_payment_out_sales_return'
    } else if (document_type == 'purchase_return') {
      document_name = 'purchase_returns'
      payment_api = 'create_payment_in_purchase_return'
    }

    const req = {
      ...values,
      [document_name]: resArray,
      payment_date: dayjs(values.payment_date).format(this.dateFormat),
      notes: values.notes == null ? '' : values.notes,
      party_id: this.state.selectedData.customer[this.state.typeFlag ? 'customer_id' : 'vendor_id'],
      party_type: this.state.typeFlag ? 'customer' : 'vendor',
      amount: values.pay,
      payment_mode: this.state.type,
      payment_type: this.state.payment_type,
      is_settle: this.state.is_advance_payment,
      advance_payments: this.state.advance_payments,
      section: this.props.tds_sections[this.state.tds_selected_section],
      attachments: this.state.attachments,
    }

    const data = await getAPI('payments', payment_api, req)

    this.setState({ loading: false })
    if (data) {
      message.success(data.message)
      this.setState({ visible: false, attachments: [] })
      this.props.onApicalled()
    } else {
      this.setState({ loading: false })
    }
    this.setState({ view_document_details: false })
  }

  get_advance_payments = async (data, flag, document_type) => {
    var req = {
      party_type: flag ? 'customer' : 'vendor',
      party_id: data.customer[flag ? 'customer_id' : 'vendor_id'],
      payment_type: this.state.payment_type,
      document_type: document_type,
    }

    var doc_serial_number = 'invoice_serial_number'
    if (document_type == 'purchase' || document_type == 'sales_return') {
      doc_serial_number = 'po_serial_number'
    }

    const result = await getAPI('payments', 'advance_payments', req)
    if (result && result.success) {
      var amount_settle = 0
      var total_pending_payment = this.state.selectedData.amount_pending
      var advance_payments = result.payments.map((row, index) => {
        var amount_remaining = row.amount_remaining
        if (amount_remaining <= total_pending_payment) {
          amount_settle = amount_remaining
        } else {
          amount_settle = total_pending_payment
        }

        total_pending_payment -= amount_settle

        return {
          key: index,
          ...row,
          // amount_remaining: row.amount - row.total_amount_paid,
          amount_settle: amount_settle,
          disable: amount_settle > 0 ? false : true,
          [doc_serial_number]: this.state.selectedData.serial_number,
        }
      })
      advance_payments = advance_payments.filter(item => item.amount_remaining > 0)
      this.setState({
        advance_payments: advance_payments,
        type: result?.recent_mode ? result?.recent_mode : 'UPI',
      })
    } else {
      this.setState({
        type: 'UPI',
      })
    }
  }

  onFill = (data, flag, document_type) => {
    let payment_type =
      document_type == 'invoice' || document_type == 'purchase_return' ? 'in' : 'out'
    this.setState(
      {
        collapseActiveKey2: '2',
        collapseActiveKey1: '',
        visible: true,
        formFlag: true,
        selectedData: data,
        typeFlag: flag,
        company_name: data.customer.company_name,
        paid: data.amount_paid,
        pending: data.amount_pending,
        use_balance: false,
        amount: data.total_amount,
        document_date: data.invoice_date,
        document_due_date: data.due_date,
        net_amount: data.net_amount,
        total_amount: data.total_amount,
        bal: data.customer.balance,
        opening_balance: data.customer.opening_balance,
        name: data.customer.name,
        type:
          this.props.banks.length == 1
            ? 'Cash'
            : this.props.payment_types.length > 0
            ? this.props.payment_types[0].value
            : '',
        payment_type: payment_type,
        party_type: flag ? 'customer' : 'vendor',
        document_type: document_type,
        is_advance_payment: false,
      },
      () => {
        let banks = this.props.banks.filter(item => item.bank_name != 'Cash')
        let selectedBankId =
          banks.length > 0
            ? banks.filter(item => item.is_default == 1).length > 0
              ? banks.filter(item => item.is_default == 1)[0].id
              : banks[0].id
            : undefined
        this.formRef?.current?.setFieldsValue({
          ...data,
          [`${this.state.typeFlag ? 'customer' : 'vendor'}_name`]: data.customer.name,
          amount: data.total_amount,
          paid: data.amount_paid,
          payment_date: dayjs(),
          payment_type:
            this.props.banks.length == 1
              ? 'Cash'
              : this.props.payment_types.length > 0
              ? this.props.payment_types[0].value
              : '',
          bank_id: selectedBankId,
          notes: '',
          pay: data.amount_pending,
          use_balance: false,
          send_sms: this.props.invoice_settings.record_payment_sms == 1,
          send_email: this.props.invoice_settings.record_payment_email == 1,
          pending_amount: data.amount_pending,
        })
        this.setState({
          initialValuesObject: {
            pay: data.amount_pending,
            bank_id: selectedBankId,
            notes: '',
          },
        })
        this.get_advance_payments(data, flag, document_type)
      },
    )
  }

  getPayment = async () => {
    const req = {
      serial_number: this.state.selectedData.serial_number,
    }

    const data = await getAPI('payments', 'get_payment', req)

    if (data) {
      this.setState({ paymentData: data }, () => {
        this.setState({ paymentFlag: true })
      })
    }
  }

  getStatusClass = data => {
    if (data < 0) {
      return 'text-danger'
    } else if (data == 0) {
      return 'text-gray-400'
    } else {
      return 'text-forest'
    }
  }

  cancelPaymentReceiptModal = () => {
    this.setState({ paymentFlag: false, paymentData: '' })
  }

  getTotal = (value, key, flag) => {
    return this.state.advance_payments.reduce(function(total, item) {
      if (item.key == key && flag) return Number(total) + Number(value)
      return Number(total) + Number(item.amount_settle)
    }, 0)
  }

  onChangeAdvancePayment = (value, data) => {
    const newData = this.state.advance_payments

    const index = newData.findIndex(item => item.serial_number == data.serial_number)

    if (value == null) {
      newData[index].amount_settle = 0
    } else {
      const total = this.getTotal(value, data.key, true)

      if (total < this.state.selectedData.amount_pending) {
        newData[index].amount_settle = value
        newData.map(item => {
          item.disable = false
        })
      } else if (total == this.state.selectedData.amount_pending) {
        newData[index].amount_settle = value
        newData.map(item => {
          if (item.amount_settle == 0) item.disable = true
          else item.disable = false
        })
      } else {
        const newValue = this.state.selectedData.amount_pending - (total - value)

        if (newValue > newData[index].amount_remaining) {
          newData[index].amount_settle = newData[index].amount_remaining
        } else {
          newData[index].amount_settle = newValue
        }
        newData.map(item => {
          if (item.amount_settle == 0) item.disable = true
          else item.disable = false
        })
      }
    }

    this.setState({ advance_payments: newData })
  }

  onChangeSettle = e => {
    this.setState({ is_settle: e.target.checked })
  }
  handelAdvancePayment = keys => {
    if (keys.length > 0) {
      this.setState({ is_advance_payment: true, collapseActiveKey1: '1', collapseActiveKey2: '' })
    } else {
      this.setState({ is_advance_payment: false, collapseActiveKey1: '', collapseActiveKey2: '2' })
    }
  }

  onClose = () => {
    let currentData = this.formRef.current.getFieldsValue()

    if (currentData.bank_id == undefined) {
      currentData.bank_id = this.state.initialValuesObject.bank_id
    }

    let isValuesChanged = false

    for (let key in this.state.initialValuesObject) {
      if (currentData[key] !== this.state.initialValuesObject[key]) {
        isValuesChanged = true
        break
      }
    }

    if (isValuesChanged || this.state.paymentModeChanged) {
      Modal.confirm({
        title: 'Do you want to exit?',
        content: 'You will lose your unsaved data.',
        icon: <ExclamationCircleOutlined />,
        okText: 'Confirm',
        onOk: () => {
          this.setState({
            visible: false,
            advance_payments: [],
            is_settle: false,
            view_document_details: false,
            attachments: [],
            paymentModeChanged: false,
          })
        },
        onCancel: () => {},
      })
    } else {
      this.setState({
        visible: false,
        advance_payments: [],
        is_settle: false,
        view_document_details: false,
        attachments: [],
        paymentModeChanged: false,
      })
    }
  }

  render() {
    var advance_payments_columns = [
      {
        title: 'Payment',
        dataIndex: 'serial_number',
        key: '1',
        render: (text, data) => (
          <span>
            <span className="text-primary font-size-12 block">{data.document_title}</span>
            {text}
          </span>
        ),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: '2',
        render: (text, data) => <span>{text.toFixed(2)}</span>,
      },
      {
        title: 'Amount Remaining',
        dataIndex: 'amount_remaining',
        key: '3',
        render: (text, data) => <span>{text.toFixed(2)}</span>,
      },
      {
        title: 'Settle Amount',
        dataIndex: 'amount_settle',
        key: '4',
        render: (text, data) => (
          <InputNumber
            disabled={data.disable}
            value={text}
            min={0}
            max={data.amount}
            onChange={e => this.onChangeAdvancePayment(e, data)}
          />
        ),
      },
    ]
    const settledPaymentsColumns = [
      {
        title: 'Serial Number',
        dataIndex: 'doc_serial_number',
        key: '1',
      },
      {
        title: 'Amount',
        dataIndex: 'amount_paid',
      },
    ]

    return (
      <>
        {this.state.paymentData != '' && (
          <Modal
            title="Payment Receipt"
            open={this.state.paymentFlag}
            style={{ top: 20 }}
            width={900}
            onOk={() => this.setState({ paymentFlag: false })}
            onCancel={() => this.cancelPaymentReceiptModal()}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            footer={[
              <Button
                key="back"
                type="default"
                onClick={() => this.setState({ paymentFlag: false })}
              >
                Cancel
              </Button>,
            ]}
          >
            <PaymentDocument data={this.state.paymentData} />
          </Modal>
        )}

        <Drawer
          title={
            <span>
              <span className="lh-normal">
                <span className="font-weight-bolder mb-0">Record Payment for </span>
                <span className="font-weight-bolder text-muted">
                  {this.state.selectedData?.serial_number}
                </span>
                {/*{capitalizeFirstLetters(this.state.document_type)} #*/}
              </span>
              <p className="font-size-13 text-muted font-weight-medium mt-0 mb-0 lh-normal">
                <span>{this.state.document_date}</span>
                {this.state.document_date == this.state.document_due_date && (
                  <span className="font-weight-bold">
                    {', Due on ' + this.state.document_due_date}
                  </span>
                )}
              </p>
            </span>
          }
          width={isMobile ? '100%' : '60%'}
          height={isMobile ? '90%' : '100%'}
          placement={isMobile ? 'top' : 'right'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          onClose={this.onClose}
          open={this.state.visible}
          extra={
            <Space>
              <Button
                onClick={() => this.formRef.current.submit()}
                type="primary"
                className="font-weight-bold arrow-transition"
                loading={this.state.loading}
              >
                {this.state.formFlag ? 'Update Payment' : 'Add Payment'}
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>
            </Space>
          }
          footer={
            <Button
              onClick={() => this.formRef.current.submit()}
              type="primary"
              className="font-weight-bold"
              loading={this.state.loading}
            >
              {this.state.formFlag ? 'Update Payment' : 'Add Payment'}
            </Button>
          }
        >
          <Form
            {...formItemLayout}
            labelAlign="left"
            ref={this.formRef}
            name="paymentForm"
            onFinish={this.onFinish}
          >
            {this.state.advance_payments.length > 0 && (
              <Collapse
                className="site-collapse-custom-collapse bg-card-orange-dark collapse-align-center"
                bordered={false}
                activeKey={this.state.collapseActiveKey1}
                onChange={key => {
                  this.handelAdvancePayment(key)
                }}
                expandIcon={({ isActive }) => {
                  return (
                    <Checkbox
                      checked={this.state.is_advance_payment}
                      onChange={e => {
                        this.setState({ is_advance_payment: !this.state.is_advance_payment })
                        // this.getListPendings(this.state.selectedCustomer)
                      }}
                      style={{ fontSize: '0.9rem' }}
                      // className="mt-2"
                      onClick={e =>
                        this.handelAdvancePayment(this.state.collapseActiveKey1 == '1' ? [] : ['1'])
                      }
                    ></Checkbox>
                  )
                }}
              >
                <Panel
                  header={
                    <>
                      <span className="font-size-18 font-weight-bold">
                        Settle From Advance Payments
                      </span>
                      <p className="text-muted font-size-13 mb-0">
                        You have advance payments from this customer. Click here to Settle from
                        Advance Payments.
                      </p>
                    </>
                  }
                  key="1"
                  className="mb-4"
                >
                  {this.state.selectedData.amount_pending > 0 && (
                    <p className="mb-2">
                      <span className="text-gray-500">Amount Pending</span>
                      <span className="font-weight-bold ml-2">
                        {this.state.selectedData.amount_pending}
                      </span>
                    </p>
                  )}
                  <Row>
                    <Col span={24}>
                      <Table
                        className=""
                        size="small"
                        columns={advance_payments_columns}
                        dataSource={this.state.advance_payments}
                        expandable={{
                          expandedRowRender: record => (
                            <Card className="border-radius-small bg-lblue">
                              <span></span>
                              <Table
                                className="w-full"
                                size="small"
                                columns={settledPaymentsColumns}
                                dataSource={record.setteled_documents}
                                pagination={{ pageSize: 5, showSizeChanger: false }}
                              />
                            </Card>
                          ),
                          rowExpandable: record => record.setteled_documents.length >= 1,
                        }}
                        expandedRowKeys={this.state.expandedRowKeys}
                        onExpand={(expanded, record) => {
                          if (expanded) {
                            this.setState({
                              expandedRowKeys: [record.key],
                            })
                          } else {
                            this.setState({
                              expandedRowKeys: [],
                            })
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            )}

            <SectionHeader
              title={
                <>
                  <span>
                    {this.state.company_name != '' ? this.state.company_name : this.state.name}
                  </span>
                </>
              }
              right={true}
              rightText={
                <>
                  {this.state.selectedData?.customer?.customer_id != 0 && (
                    <p
                      className={`${this.getStatusClass(
                        this.state.bal + this.state.opening_balance,
                      )} mt-0 pt-0 mb-0 font-size-14`}
                    >
                      <span className="font-size-14 mr-1 font-weight-bold">Balance </span>
                      <span className=" font-weight-bold">
                        <span className="font-size-12 ml-1 mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {Math.abs(this.state.bal + this.state.opening_balance)}
                      </span>
                    </p>
                  )}
                </>
              }
            />

            <Collapse
              className="bg-white"
              bordered={false}
              expandIcon={({ isActive }) => (
                <span className="">
                  <i className={`fa-solid fa-chevron-down ml-1 ${!isActive && 'fa-rotate-270'}`} />
                </span>
              )}
              activeKey={this.state.collapseActiveKey2}
              onChange={key => {
                if (key.length > 0) {
                  this.setState({ collapseActiveKey2: '2' })
                } else {
                  this.setState({ collapseActiveKey2: '' })
                }
              }}
            >
              <Panel
                header={
                  <>
                    <span className="font-weight-bold text-gray-500">Payment Details</span>
                  </>
                }
                key="2"
                className="mb-4"
              >
                <Row gutter={18}>
                  <Col span={24}>
                    <Form.Item
                      name="pay"
                      label="Amount to be Recorded"
                      className="mt-4"
                      extra={
                        <>
                          <span>
                            Total Amount {this.props.user?.selectedCompany?.curreny_symbol}{' '}
                            <strong>{this.state.selectedData.total_amount}</strong>
                          </span>
                        </>
                      }
                      rules={[
                        { required: true, message: 'Fill Pay Amount' },
                        { type: 'number', min: 0.1, message: 'Amount should be greater than 0' },
                      ]}
                    >
                      <InputNumber
                        placeholder="Enter Amount to be recorded"
                        className="w-full font-weight-bold"
                        precision={2}
                        size="large"
                        prefix={
                          <span className="text-gray-500">
                            {this.props?.user?.selectedCompany?.currency_symbol}
                          </span>
                        }
                        suffix={
                          <>
                            <span></span>
                          </>
                        }
                      />
                    </Form.Item>
                  </Col>
                  {/*<Col span={12}>
                  <Form.Item name="amount" label="Invoice Amount">
                    <Input
                      className="w-full"
                      disabled
                      style={{ width: 'auto', fontWeight: 500, backgroundColor: '#F7FAFC' }}
                    />
                  </Form.Item>
                </Col>*/}
                  <Col span={24}>
                    <Form.Item name="payment_date" label="Payment Date">
                      <DatePicker
                        allowClear={false}
                        format={this.dateFormat}
                        className="w-full height-32"
                        style={{
                          width: 'auto',
                          cursor: 'pointer',
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="payment_type" label="Payment Type" className="mb-2">
                  {''}
                  {this.state.type != '' && (
                    <PaymentModes
                      data={this.props.payment_types}
                      value={this.state.type}
                      onChange={e => {
                        if (e.target.value == 'TDS') {
                          this.formRef.current.setFieldsValue({
                            pay:
                              (this.state[
                                this.props.tds_sections[this.state.tds_selected_section].apply_on ==
                                'total_amount'
                                  ? 'total_amount'
                                  : 'net_amount'
                              ] *
                                this.props.tds_sections[this.state.tds_selected_section].tax) /
                              100,
                          })
                        } else {
                          if (this.state.type == 'TDS') {
                            this.formRef.current.setFieldsValue({
                              pay: this.state.selectedData.amount_pending,
                            })
                          }
                        }
                        this.setState({ type: e.target.value, paymentModeChanged: true })
                      }}
                    />
                  )}
                </Form.Item>
                {this.state.type == 'TDS' && (
                  <>
                    <Form.Item name="section" label="TDS Section" className="pb-2">
                      <Select
                        suffixIcon={<i className="fa-regular fa-lg fa-chevron-down"></i>}
                        showSearch
                        placeholder="Select tds section"
                        optionFilterProp="children"
                        style={{ width: '100%', whiteSpace: 'nowrap' }}
                        value={this.state.tds_selected_section}
                        defaultValue={0}
                        filterOption={(input, option) =>
                          (
                            option.props.children.props.children[0].props.children +
                            ' ' +
                            option.props.children.props.children[1].props.children +
                            ' ' +
                            option.props.children.props.children[2].props.children
                          )
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={e => {
                          this.setState({
                            tds_selected_section: e,
                          })
                          this.formRef.current.setFieldsValue({
                            pay:
                              ((this.props.tds_sections[e].apply_on == 'net_amount'
                                ? this.state.net_amount
                                : this.state.total_amount) *
                                this.props.tds_sections[e].tax) /
                              100,
                          })
                        }}
                      >
                        {this.props.tds_sections.map((item, i) => (
                          <Option key={i} value={i}>
                            <div style={{ whiteSpace: 'normal' }}>
                              <span className="text-gray-700 font-weight-bold mr-2">
                                {item.tax + '%'}
                              </span>
                              <span className="mr-1 font-weight-bold text-gray-700">
                                {item.section}
                              </span>
                              <span className="mr-2 text-gray-500">{item.name}</span>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                )}

                {this.state.type != 'Cash' && this.state.type != 'TDS' && (
                  <Form.Item name="bank_id" label="Bank" className="pb-2">
                    <Select
                      suffixIcon={<i className="fa-regular fa-lg fa-chevron-down"></i>}
                      placeholder="Select Bank"
                      optionFilterProp="children"
                      style={{ minWidth: '36' }}
                      onChange={e => this.setState({ bank_id: e })}
                      value={this.state.bank_id}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      dropdownRender={menu => (
                        <>
                          <div className="px-1 py-1">
                            {menu}
                            <Button
                              block
                              type="primary"
                              className="font-weight-bolder pb-1"
                              onClick={() => this.bankForm.onAddFromInvoicePage()}
                            >
                              <i className="fa fa-plus mr-1" />
                              Add New Bank
                            </Button>
                          </div>
                        </>
                      )}
                    >
                      {this.props.banks.map((item, i) => {
                        if (item.bank_name != 'Cash') {
                          return (
                            <Option value={item.id} key={i}>
                              {item.bank_name + ' (' + item.bank_no + ')'}
                            </Option>
                          )
                        }
                      })}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item name="notes" label="Notes" className="pb-2">
                  <TextArea placeholder="Your notes on the payment" />
                </Form.Item>

                {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                  <Form.Item
                    name="send_sms"
                    label={this.state.typeFlag ? 'SMS to Customers' : 'SMS to Vendors'}
                    valuePropName="checked"
                    extra={
                      this.state.selectedData?.customer?.phone != null &&
                      this.state.selectedData?.customer?.phone != '' ? (
                        <span className="text-gray-500">
                          {`SMS will be sent to the ${
                            this.state.typeFlag ? 'customer' : 'vendor'
                          } phone:`}
                          <span className="text-primary">
                            {this.state.selectedData?.customer?.phone}
                          </span>
                        </span>
                      ) : (
                        <span className="text-gray-500">
                          <span className="text-danger">
                            {`Please add ${
                              this.state.typeFlag ? 'customer' : 'vendor'
                            } phone number to enable this option`}
                          </span>
                        </span>
                      )
                    }
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked
                      disabled={
                        this.state.selectedData?.customer?.phone == null ||
                        this.state.selectedData?.customer?.phone == ''
                      }
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="send_email"
                  label={this.state.typeFlag ? 'E-Mail to Customers' : 'E-Mail to Vendors'}
                  valuePropName="checked"
                  extra={
                    this.state.selectedData?.customer?.email != null &&
                    this.state.selectedData?.customer?.email != '' ? (
                      <span className="text-gray-500">
                        {`E-Mail will be sent to the ${
                          this.state.typeFlag ? 'customer' : 'vendor'
                        } email:`}
                        <span className="text-primary">
                          {this.state.selectedData?.customer?.email}
                        </span>
                      </span>
                    ) : (
                      <span className="text-gray-500">
                        <span className="text-danger">
                          {`Please add ${
                            this.state.typeFlag ? 'customer' : 'vendor'
                          } email to enable this option`}
                        </span>
                      </span>
                    )
                  }
                >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked
                    disabled={
                      this.state.selectedData?.customer?.email == null ||
                      this.state.selectedData?.customer?.email == ''
                    }
                  />
                </Form.Item>

                {/* Attachments */}
                <UploadFiles
                  attachments={this.state.attachments}
                  maxCount={3}
                  multiple={true}
                  onChange={attachments => this.setState({ attachments })}
                  setLoading={loading => this.setState({ loading })}
                />

                {/* </Card> */}
              </Panel>
            </Collapse>

            <Form.Item
              name="exclusive_notes"
              label={
                <span>
                  <i className="fa-solid fa-fingerprint mr-2"></i>Internal Notes
                </span>
              }
              className="mt-4 pb-2"
              extra={
                'This note is exclusively for internal reference and will not be shown elsewhere.'
              }
            >
              <TextArea placeholder="Enter notes here..." />
            </Form.Item>
          </Form>

          {/* {this.state.advance_payments.length > 0 && (
            <>
              <h6 className="mt-5 text-gray-4 mb-2">
                <Checkbox onChange={this.onChangeSettle}>
                  Click here to Settle from Advance Payments
                </Checkbox>
                <Badge count={'NEW'} className="mb-1" />
              </h6>
              {this.state.is_settle && (
                <Table
                  size="small"
                  columns={advance_payments_columns}
                  dataSource={this.state.advance_payments}
                />
              )}
            </>
          )}
          */}

          <BankForm onRef={ref => (this.bankForm = ref)} />
          <Modal
            open={this.state.show_settle_warning}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            onClose={() => this.setState({ show_settle_warning: false })}
            onCancel={() => this.setState({ show_settle_warning: false })}
            title=""
            footer={[
              <Button onClick={() => this.setState({ show_settle_warning: false })}>Cancel</Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  this.setState({ show_settle_warning: false, collapseActiveKey1: '' })
                }}
              >
                Proceed
              </Button>,
            ]}
          >
            <div>
              <h6 className="text-danger">Warning</h6>
              <p className="text-gray-4">
                You are about to settle the document with advance payments. This action cannot be
                undone if you close this section. Are you sure you want to proceed?
              </p>
            </div>
          </Modal>
        </Drawer>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    payment_types: state.document.paymentModes,
    tds_sections: state.document.tdsSections,
    banks: state.document.bankDetails == null ? [] : state.document.bankDetails,
    invoice_settings: state.document.invoiceSettings,
    user: state.user,
  }
}

export default connect(mapStateToProps)(PaymentForm)
