import { Checkbox, Col, DatePicker, Modal, Row, message } from 'antd'
import ShowDateString from 'components/other/dateString'
import dayjs from 'dayjs'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useSelector } from 'react-redux'
import { config } from 'services/axios'
import { getAPI } from 'services/jwt'
import store from 'store'
const { RangePicker } = DatePicker

const PartyLedgerOptions = forwardRef((props, ref) => {
  const dateFormat = 'DD-MM-YYYY'
  const defaultDate =
    dayjs()
      .month(0)
      .startOf('month')
      .format(dateFormat) +
    ' - ' +
    dayjs()
      .month(11)
      .endOf('month')
      .format(dateFormat)

  const [visible, setVisible] = useState(false)
  const [showPendingDocs, setShowPendingDocs] = useState(false)
  const [showCustomDateRange, setShowCustomDateRange] = useState(false)
  const [page, setPage] = useState(0)
  const [numRecords, setNumRecords] = useState(10)
  const [shareLink, setShareLink] = useState('')
  const { type } = props
  const [isLoading, setIsLoading] = useState(false)
  const [dates, setDates] = useState(defaultDate)

  const country_info = useSelector(state => state.countries.info)
  const user = useSelector(state => state.user)

  const defaultDatePickerRange =
    country_info?.[user?.selectedCompany?.country_code]?.date_ranges['FY 24-25'] ??
    country_info?.[user?.selectedCompany?.country_code]?.date_ranges['This Year']

  useImperativeHandle(ref, () => ({
    openShareModal: (show_pending_docs, num_records, page) => {
      setVisible(true)
      setShowPendingDocs(show_pending_docs ? show_pending_docs : false)
      setNumRecords(num_records)
      setPage(page)
      setShowCustomDateRange(false)
    },
  }))

  useEffect(() => {
    if (visible) {
      // Generate the initial share link when modal opens
      updateShareLink(dates, showCustomDateRange)
    }
  }, [visible, showPendingDocs, showCustomDateRange])

  const updateShareLink = async (newDates, customDateRange) => {
    setIsLoading(true)
    const req = {
      num_records: numRecords,
      page: page,
      id: props.selectedUser.id,
      date: newDates,
      show_pending_docs: showPendingDocs,
      show_custom_date_range: customDateRange,
    }
    const data = await getAPI(props.type, 'ledger', req)
    if (data) {
      const updatedHashId = data.hash_id
      const updatedLink = `${config.url.short_URL}/view/ledger/${props.type}/${updatedHashId}`
      setShareLink(updatedLink)
    }
    setIsLoading(false)
  }

  const onClose = () => {
    setShowPendingDocs(false)
    setShowCustomDateRange(false)
    setVisible(false)
  }

  return (
    <>
      <Modal visible={visible} onCancel={onClose} title="Ledger Settings" footer={null}>
        <Row gutter={24}>
          <Col span={24}>
            {type == 'customer' && (
              <div className="mb-2 ">
                <Checkbox.Group>
                  <Checkbox
                    onChange={e => {
                      setShowPendingDocs(e.target.checked)
                    }}
                    className="mt-4 text-gray-600"
                    checked={showPendingDocs}
                  >
                    Share Pending {type == 'customer' ? 'Invoices' : 'Purchases'} Only
                  </Checkbox>
                  <div className="ml-4 pl-2 text-gray-400 font-size-12">
                    Display only pending {type == 'customer' ? 'Invoices' : 'Purchases'} those are
                    not yet settled.
                  </div>
                </Checkbox.Group>
              </div>
            )}
          </Col>
          <Col span={24}>
            <Checkbox.Group>
              <Checkbox
                onChange={e => {
                  setShowCustomDateRange(e.target.checked)
                  if (e.target.checked) {
                    const defaultRange = defaultDatePickerRange
                      ? [
                          dayjs(defaultDatePickerRange[0], dateFormat),
                          dayjs(defaultDatePickerRange[1], dateFormat),
                        ]
                      : [dayjs(), dayjs()]
                    setDates(defaultRange.map(date => date.format(dateFormat)).join(' - '))
                  } else {
                    setDates(defaultDate)
                  }
                }}
                className="mt-4 text-gray-600"
                checked={showCustomDateRange}
              >
                Share Records in a Custom Date Range
              </Checkbox>
              <div className="ml-4 pl-2 mb-4 text-gray-400 font-size-12">
                Only data of selected date range will be shown to the party.
              </div>
            </Checkbox.Group>
          </Col>
          {showCustomDateRange && (
            <Col span={24} className="mt-2">
              <RangePicker
                allowClear={false}
                size=""
                ranges={country_info?.[user?.selectedCompany?.country_code]?.date_ranges}
                format={dateFormat}
                defaultValue={[
                  dayjs(defaultDatePickerRange[0], dateFormat),
                  dayjs(defaultDatePickerRange[1], dateFormat),
                ]}
                value={
                  dates !== ''
                    ? [
                        dayjs(dates.split(' - ')[0], dateFormat),
                        dayjs(dates.split(' - ')[1], dateFormat),
                      ]
                    : ''
                }
                onChange={(dates, dateStrings) => {
                  setDates(dateStrings[0] + ' - ' + dateStrings[1])
                  updateShareLink(dateStrings[0] + ' - ' + dateStrings[1], showCustomDateRange)
                }}
              />
              <ShowDateString dateString={dates} showInputWidth={false} />
            </Col>
          )}
        </Row>

        <div className="flex flex-col mt-4">
          <div
            className="border rounded px-4"
            style={{
              cursor: 'pointer',
            }}
          >
            <div
              onClick={event => {
                event.stopPropagation()
                window.open(
                  'https://api.whatsapp.com/send/?phone=' +
                    props?.selectedUser?.dial_code +
                    props?.selectedUser?.phone +
                    '&text=Hello%20*' +
                    props?.selectedUser?.name +
                    '*%2C%20%0A%0APlease%20find%20your%20transactions%20here%2C%20%0A' +
                    shareLink +
                    '%0A%0AThanks%0A*' +
                    store.get('company_name') +
                    '*%0A*' +
                    store.get('company_mobile') +
                    '*' +
                    (store.get('paid') == 0
                      ? '%0A%0ASent%20using%20*Swipe%3A%20Simple%20Invoicing%2C%20Billing%2C%20Payments*%20(getswipe.in)'
                      : ''),
                )
              }}
            >
              <div className="flex justify-between items-center my-3">
                <div>
                  <span>
                    <i
                      className="fa-brands fa-whatsapp align-middle  font-size-28 mr-2"
                      style={{
                        color: '#25D366',
                      }}
                    />
                    <span className="font-size-18 align-middle font-weight-bold">
                      Share on Whatsapp
                    </span>
                  </span>
                </div>

                <span>
                  <i className="fa-solid fa-chevron-right text-gray font-size-18"></i>
                </span>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="flex flex-col items-center my-3">
              <div className="spinner-border spinner-border-sm text-primary"></div>
            </div>
          ) : (
            <CopyToClipboard text={shareLink} onCopy={() => message.success('Copied')}>
              <div
                className="flex justify-between items-center my-2 border rounded px-4 py-3"
                style={{
                  cursor: 'pointer',
                }}
              >
                <div className="flex-grow overflow-hidden">
                  <span
                    className="font-size-15 align-middle text-truncate text-primary"
                    style={{
                      display: 'inline-block',
                      maxWidth: 'calc(100% - 30px)',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {shareLink}
                  </span>
                </div>
                <span className="flex-shrink-0 ml-2">
                  <i className="fa-solid fa-copy text-gray-10 font-size-18"></i>
                </span>
              </div>
            </CopyToClipboard>
          )}
          {!showCustomDateRange && (
            <span className="font-size-11 text-gray-400 mb-2 pl-1 font-weight-medium">
              Note: The link contains the <strong>full ledger data</strong>.
            </span>
          )}
        </div>
      </Modal>
    </>
  )
})

export default PartyLedgerOptions
