import { applyMiddleware, compose, createStore } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'
import reducers from './reducers'

const configureStore = (history, sagaMiddleware) => {
  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history,
  })

  // TODO: redux-first-history not able to intercept saga dispatched actions, so using push from history for now

  const store = createStore(
    reducers(routerReducer),
    compose(applyMiddleware(sagaMiddleware), applyMiddleware(routerMiddleware)),
  )
  const reduxHistory = createReduxHistory(store)
  return { store, reduxHistory }
}

export default configureStore
