import React from 'react'
import { Text, Image, View, StyleSheet } from '@react-pdf/renderer'

const PlaceOfSupply = ({ invoice, styles, in_new_line }) => {
  return (
    invoice.company.gstin.length == 15 && (
      <View style={styles.block}>
        <Text>
          <Text style={styles.title}>Place of Supply: {in_new_line && '\n'}</Text>
          {invoice.document_type == 'purchases' ||
          invoice.document_type == 'purchase_orders' ||
          invoice.document_type == 'purchase_returns' ||
          invoice.immovable_tax_type == 1 ? (
            <Text style={styles.text}>{invoice.company.billing_state}</Text>
          ) : (
            <Text style={styles.text}>
              {invoice.customer.billing.state != '' &&
                invoice.customer.billing.state != 'OTHERTERRITORY' &&
                invoice.customer.billing.state != '97-OTHERTERRITORY' &&
                invoice.customer.billing.state != '97-OTHER TERRITORY' &&
                invoice.customer.billing.state + ' '}
              {invoice.customer.billing.state == '' &&
                invoice.company.billing_state != 'OTHERTERRITORY' &&
                invoice.company.billing_state != '97-OTHERTERRITORY' &&
                invoice.company.billing_state != '97-OTHER TERRITORY' &&
                invoice.company.billing_state}
              {invoice.is_export == 1 &&
                (invoice.customer.billing.state == 'OTHERTERRITORY' ||
                  invoice.customer.billing.state == '97-OTHERTERRITORY' ||
                  invoice.customer.billing.state == '97-OTHER TERRITORY') && (
                  <Text style={{ textTransform: 'uppercase' }}>
                    {invoice.export_details[0].name}
                  </Text>
                )}
            </Text>
          )}
        </Text>
      </View>
    )
  )
}

export const PlaceOfSupply_2 = ({ invoice, styles, in_new_line }) => {
  return (
    invoice.company.gstin.length == 15 && (
      <View style={styles.block}>
        <Text style={styles.title}>Place of Supply: {in_new_line && '\n'}</Text>
        {invoice.document_type == 'purchases' ||
        invoice.document_type == 'purchase_orders' ||
        invoice.document_type == 'purchase_returns' ||
        invoice.immovable_tax_type == 1 ? (
          <Text style={styles.text}>{invoice.company.billing_state}</Text>
        ) : (
          <Text style={styles.text}>
            {invoice.customer.billing.state != '' &&
              invoice.customer.billing.state != 'OTHERTERRITORY' &&
              invoice.customer.billing.state != '97-OTHERTERRITORY' &&
              invoice.customer.billing.state != '97-OTHER TERRITORY' &&
              invoice.customer.billing.state + ' '}
            {invoice.customer.billing.state == '' &&
              invoice.company.billing_state != 'OTHERTERRITORY' &&
              invoice.company.billing_state != '97-OTHERTERRITORY' &&
              invoice.company.billing_state != '97-OTHER TERRITORY' &&
              invoice.company.billing_state}
            {invoice.is_export == 1 &&
              (invoice.customer.billing.state == 'OTHERTERRITORY' ||
                invoice.customer.billing.state == '97-OTHERTERRITORY' ||
                invoice.customer.billing.state == '97-OTHER TERRITORY') && (
                <Text style={{ textTransform: 'uppercase' }}>{invoice.export_details[0].name}</Text>
              )}
          </Text>
        )}
      </View>
    )
  )
}

export default PlaceOfSupply
