import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { getAPI, getAPIData } from 'services/jwt'
import {
  GET_CUSTOMER_COLUMNS,
  GET_VENDOR_COLUMNS,
  SET_CATEGORIES,
  SET_CUSTOMER_COLUMNS,
  SET_VENDOR_COLUMNS,
} from './types'
import { setBulkEditLoading } from './actions'
export function* get_customer_columns() {
  const customer_columns_loading = yield select(state => state.bulkedit.customer_columns_loading)
  if (customer_columns_loading) return
  yield put(setBulkEditLoading({ customer_columns_loading: true }))
  const req = {
    party_type: 'customer',
  }
  const data = yield call(async () => await getAPI('utils', 'get_custom_party_fields', req))
  if (data?.success) {
    yield put({ type: SET_CUSTOMER_COLUMNS, payload: data.custom_fields })
  }
}
export function* get_vendor_columns() {
  const vendor_columns_loading = yield select(state => state.bulkedit.vendor_columns_loading)
  if (vendor_columns_loading) return
  yield put(setBulkEditLoading({ vendor_columns_loading: true }))
  const req = {
    party_type: 'vendor',
  }
  const data = yield call(async () => await getAPI('utils', 'get_custom_party_fields', req))
  if (data?.success) {
    yield put({ type: SET_VENDOR_COLUMNS, payload: data.custom_fields })
  }
}

export function* get_categories() {
  const data = yield call(async () => await getAPIData('category', 'get_categories'))
  if (data?.success) {
    yield put({ type: SET_CATEGORIES, payload: data.categories })
  }
}
export default function* document() {
  yield all([
    takeLatest(GET_CUSTOMER_COLUMNS, get_customer_columns),
    takeLatest(GET_VENDOR_COLUMNS, get_vendor_columns),
  ])
}
