export const GET_WAREHOUSES = 'GET_WAREHOUSES'
export const SET_WAREHOUSES = 'SET_WAREHOUSES'
export const SET_ALL_WAREHOUSES = 'SET_ALL_WAREHOUSES'
export const SET_ALL_USERS = 'SET_ALL_USERS'
export const SET_WAREHOUSE_ROLES = 'SET_WAREHOUSE_ROLES'
export const GET_WAREHOUSE_ROLES = 'GET_WAREHOUSE_ROLES'
export const SET_WAREHOUSE_PERMISSIONS = 'SET_WAREHOUSE_PERMISSIONS'
export const GET_WAREHOUSE_PERMISSIONS = 'GET_WAREHOUSE_PERMISSIONS'
export const SET_DEFAULT_WAREHOUSE = 'SET_DEFAULT_WAREHOUSE'
export const GET_INVENTORY_CATEGORIES = 'GET_INVENTORY_CATEGORIES'
export const SET_INVENTORY_CATEGORIES = 'SET_INVENTORY_CATEGORIES'
