import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
  titleContainer: {
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    '@media min-width: 400': {
      flexDirection: 'row',
    },
    marginBottom: 5,
    flexWrap: 'wrap',
  },
  reportTitle: {
    color: '#1d1d1f',
    fontFamily: 'helbold_English',
    letterSpacing: 2,
    fontSize: 12.6,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  reportType: {
    color: '#333333',
    fontFamily: 'helmed_English',
    letterSpacing: 1,
    fontSize: 8,
    marginRight: 0,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  blockC: {
    width: '60%',
    marginRight: 0,
  },
  blockI: {
    width: '20%',
    marginRight: 0,
  },
  logo2: {
    maxHeight: '36',
    position: 'relative',
    textAlign: 'center',
    marginHorizontal: 'auto',
    marginRight: 1.8,
  },
  logo1: {
    maxHeight: '54',
    position: 'relative',
    textAlign: 'center',
    marginHorizontal: 'auto',
    marginLeft: 1.8,
  },
})

const InvoiceTitle = ({ title, invoice }) => (
  <View style={styles.titleContainer}>
    <View style={styles.blockI}>
      <Image
        style={styles.logo1}
        src={{
          uri: '/simha.jpg',
          method: 'GET',
          headers: { 'Cache-Control': 'no-cache' },
        }}
        crossorigin="anonymous"
      />
    </View>
    <View style={styles.blockC}>
      <Text style={styles.reportTitle}>{title}</Text>
      <Text style={styles.reportType}>E Way Bill System</Text>
    </View>
    <View style={styles.blockI}>
      <Image
        style={styles.logo2}
        src={{
          uri: '/gst.jpeg',
          method: 'GET',
          headers: { 'Cache-Control': 'no-cache' },
        }}
        crossorigin="anonymous"
      />
    </View>
  </View>
)

export default InvoiceTitle
