import { Modal } from 'antd'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import ReactPlayer from 'react-player/lazy'

const FeatureVideoModal = forwardRef((props, ref) => {
  const [show_video, setShowVideo] = useState(false)
  const [video_link, setVideoLink] = useState('')
  const showModal = link => {
    setVideoLink(link)
    setShowVideo(true)
  }

  useImperativeHandle(ref, () => ({
    showModal,
  }))
  return (
    <Modal
      className="promotional-modal"
      open={show_video}
      footer={null}
      onCancel={() => setShowVideo(false)}
      width={'72vw'}
      destroyOnClose
      bodyStyle={{
        backgroundColor: '#000',
      }}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      centered={true}
    >
      <ReactPlayer
        url={video_link}
        playing={show_video}
        controls
        loop={show_video}
        playbackRate={0.75}
        width="100%"
        height="80vh"
      />
    </Modal>
  )
})
export default FeatureVideoModal
