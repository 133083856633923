export const GET_STATES = 'GET_STATES'
export const SET_STATES = 'SET_STATES'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_API_LOADING = 'SET_API_LOADING'
export const GET_TDS_SECTIONS = 'GET_TDS_SECTIONS'
export const SET_TDS_SECTIONS = 'SET_TDS_SECTIONS'
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const GET_CUSTOM_COLUMNS = 'GET_CUSTOM_COLUMNS'
export const SET_CUSTOM_COLUMNS = 'SET_CUSTOM_COLUMNS'
export const SET_CURRENCIES = 'SET_CURRENCIES'
export const GET_PAYMENT_MODES = 'GET_PAYMENT_MODES'
export const SET_PAYMENT_MODES = 'SET_PAYMENT_MODES'
export const SET_BANK_DETAILS = 'SET_BANK_DETAILS'
export const SET_INVOICE_SETTINGS = 'SET_INVOICE_SETTINGS'
export const GET_INVOICE_SETTINGS = 'GET_INVOICE_SETTINGS'
export const SET_NOTIFICATION_SETTINGS = 'SET_NOTIFICATION_SETTINGS'
export const GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS'
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT'
export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT'
export const GET_PORT_CODES = 'GET_PORT_CODES'
export const SET_PORT_CODES = 'SET_PORT_CODES'
export const GET_TCS_SECTIONS = 'GET_TCS_SECTIONS'
export const SET_TCS_SECTIONS = 'SET_TCS_SECTIONS'
export const SET_HSN_SUGGESTION = 'SET_HSN_SUGGESTION'
export const GET_HSN_SUGGESTION = 'GET_HSN_SUGGESTION'
export const SET_CUSTOM_COLUMNS_SUGGESTION = 'SET_CUSTOM_COLUMNS_SUGGESTION'
export const GET_CUSTOM_COLUMNS_SUGGESTION = 'GET_CUSTOM_COLUMNS_SUGGESTION'
export const GET_PRODUCT_OPTIONS = 'GET_PRODUCT_OPTIONS'
export const SET_PRODUCT_OPTIONS = 'SET_PRODUCT_OPTIONS'
export const GET_ALL_DOC_COUNTS = 'GET_ALL_DOC_COUNTS'
export const SET_ALL_DOC_COUNTS = 'SET_ALL_DOC_COUNTS'
export const GET_BATCH_SETTINGS = 'GET_BATCH_SETTINGS'
export const SET_BATCH_SETTINGS = 'SET_BATCH_SETTINGS'
export const GET_POS_SETTINGS = 'GET_POS_SETTINGS'
export const SET_POS_SETTINGS = 'SET_POS_SETTINGS'
export const GET_BUSINESS_ANALYTICS = 'GET_BUSINESS_ANALYTICS'
export const SET_BUSINESS_ANALYTICS = 'SET_BUSINESS_ANALYTICS'
export const ADD_INVOICES_DATA = 'ADD_INVOICES_TAB'
export const UPDATE_INVOICES_DATA = 'UPDATE_INVOICES_DATA'
export const REMOVE_INVOICES_DATA = 'REMOVE_INVOICES_DATA'
export const REMOVE_ALL_INVOICES_DATA = 'REMOVE_ALL_INVOICES_DATA'
export const GET_UNITS = 'GET_UNITS'
export const SET_UNITS = 'SET_UNITS'
export const GET_TOP_CUSTOM_FIELDS = 'GET_TOP_CUSTOM_FIELDS'
export const SET_TOP_CUSTOM_FIELDS = 'SET_TOP_CUSTOM_FIELDS'
export const GET_PRICING_PLANS = 'GET_PRICING_PLANS'
export const SET_PRICING_PLANS = 'SET_PRICING_PLANS'
export const SET_PREFIXES = 'SET_PREFIXES'
export const SET_BARCODE_SETTINGS = 'SET_BARCODE_SETTINGS'
export const GET_BARCODE_SETTINGS = 'GET_BARCODE_SETTINGS'
