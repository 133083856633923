import { Button, Modal } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { downloadPDF, getAPIPDF } from '../../services/jwt'

const EwaybillPdf = forwardRef((props, ref) => {
  const [viewPdf, setViewPdf] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pdfData, setPdfData] = useState('')
  const closePdf = () => {
    setPdfData('')
    setViewPdf(false)
  }
  useImperativeHandle(ref, () => ({
    getEwayBillDocument,
  }))
  const arrayBufferToBase64 = buffer => {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }
  useEffect(() => {
    if (pdfData != '') {
      let base64Str = arrayBufferToBase64(pdfData)
      var iframe = document.createElement('iframe')
      iframe.style.width = '100%'
      iframe.style.height = '842pt'
      iframe.src = 'data:application/pdf;base64,' + base64Str
      document.getElementById('ewaybillpdf').innerHTML = ''
      document.getElementById('ewaybillpdf').appendChild(iframe)
    }
  }, [pdfData])

  const getEwayBillDocument = async object => {
    if (!loading) {
      setLoading(true)
      setPdfData('')
      const req = {
        invoice_serial_number: object.invoice_serial_number,
        serial_number: object.serial_number,
        document_type: object.document_type,
        new_hash_id: object.invoice_new_hash_id,
      }
      const data = await getAPIPDF('ewaybills', 'get_ewaybill_document', req)
      if (data?.data) {
        setPdfData(data.data)
        setViewPdf(true)
      }
      setLoading(false)
    }
  }
  return (
    <Modal
      title={
        <>
          <div className="mr-4 d-flex justify-content-between">
            <h5>E-Way Bill</h5>
            <Button onClick={() => downloadPDF(pdfData)} size="medium">
              <i className="fa-regular fa-download" />
            </Button>
          </div>
        </>
      }
      open={viewPdf}
      style={{ top: 20 }}
      width={900}
      onOk={() => closePdf()}
      onCancel={() => closePdf()}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      footer={[
        <Button key="back" type="default" onClick={() => closePdf()}>
          Cancel
        </Button>,
      ]}
    >
      <div id="ewaybillpdf"></div>
    </Modal>
  )
})
export default EwaybillPdf
