import React, { Component } from 'react'

export class ProBadge extends Component {
  render() {
    return (
      <span>
        <i className="fa-regular fa-lock-keyhole ml-2"></i>
      </span>

      // <span className="badge align-text-middle badge-pro badge-collapsed-hidden font-italic font-weight-bolder ml-2">
      //   PRO
      // </span>
    )
  }
}

export default ProBadge
