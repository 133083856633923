import { AutoComplete, Button, Form, Modal, Select, message } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAPI } from 'services/jwt'

const AddHsn = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)
  const [product_id, setProductId] = useState(-1)
  const [product_name, setProductName] = useState('')
  const [hsnCode, setHsnCode] = useState('')
  const [product_type, setProductType] = useState('')
  const formRef = useRef()
  const user = useSelector(state => state.user)

  const showModal = product_data => {
    setProductId(product_data.product_id)
    setProductName(product_data.product_name + ' ' + product_data.variant_name)
    setHsnCode(product_data.hsn_code)
    setProductType(product_data.product_type)
    setVisible(true)
  }
  const [hsn_codes, setHsnCodes] = useState([])
  useImperativeHandle(ref, () => ({
    showModal,
  }))
  const handleHSNSearch = async value => {
    if (value && value != '') {
      var req = {
        query: value,
      }
      var data = await getAPI('utils', 'search_hsn_codes', req)
      setHsnCodes(data?.hsn_codes)
    } else {
      setHsnCodes([])
    }
  }
  const onFinish = async values => {
    var hsn_code = values.hsn_code

    if (hsn_code != '' && hsn_code != undefined) {
      if (product_type === 'Product' && hsn_code.startsWith('99')) {
        message.error('HSN code for product should not start with 99')
        return
      }
      if (product_type === 'Service' && !hsn_code.startsWith('99')) {
        message.error('HSN code for service should start with 99')
        return
      }
      props.addProductHsn(product_id, values.hsn_code)
    } else {
      message.error('Please select a HSN code')
      return
    }
    setVisible(false)
  }
  useEffect(() => {
    // console.log('Form Ref', formRef)
    if (visible && hsnCode != '') {
      formRef.current?.setFieldsValue({ hsn_code: hsnCode })
    }
  }, [visible])
  return (
    <Modal
      open={visible}
      footer={null}
      title={`Add ${user?.selectedCompany?.labels?.hsn_code} for ${product_name}`}
      onCancel={() => setVisible(false)}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
    >
      <Form labelAlign="left" name="hsnForm" ref={formRef} onFinish={onFinish}>
        {/* <Card> */}
        <Form.Item
          name="hsn_code"
          rules={[
            {
              validator: (_, value) => {
                if (product_type === 'Product') {
                  if (value && value.toString().startsWith('99')) {
                    return Promise.reject(
                      new Error('HSN code starting with 99 is reserved for services'),
                    )
                  }
                  return Promise.resolve()
                } else if (product_type === 'Service') {
                  if (value && !value.toString().startsWith('99')) {
                    return Promise.reject(new Error('HSN code for service should start with 99'))
                  }
                  return Promise.resolve()
                }
                return Promise.resolve()
              },
            },
            { max: 8, message: 'HSN code cannot be more than 8 digits' },
          ]}
        >
          <AutoComplete
            options={hsn_codes.map(d => ({
              value: d.hsn_code,
              label: (
                <div style={{ whiteSpace: 'normal' }}>
                  <span className="mr-2 font-weight-bold">{d.hsn_code}</span>
                  {d.description}
                </div>
              ),
            }))}
            placeholder={`${user?.selectedCompany?.labels?.hsn_code} (Optional)`}
            trigger="hover"
            dropdownRender={menu => (
              <div
                className="menu autofill-btn my-select "
                id="style-9"
                style={{
                  overflowY: 'auto',
                }}
              >
                <>{menu}</>
              </div>
            )}
            onSelect={(data, option) => {
              setHsnCode(data)
            }}
            onSearch={handleHSNSearch}
            value={hsnCode}
            onChange={value => {
              value = value || ''

              setHsnCode(value)
            }}
          ></AutoComplete>
        </Form.Item>
        <Button type="primary" onClick={() => formRef.current.submit()}>
          Add {user?.selectedCompany?.labels?.hsn_code}
        </Button>
        {/* </Card> */}
      </Form>
    </Modal>
  )
})

export default AddHsn
