import React, { Fragment } from 'react'
import { Text, View, Font } from '@react-pdf/renderer'
import { getStateName } from '../export_condition'
import { styles as getStyles } from './styles'
import PlaceOfSupply from '../InvoicePlaceOfSupply'
import { dispatch_to_doc_types } from '../utils'

const BillTo = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  let export_labels_1 = [
    {
      conversion_factor: 'Conversion Rate',
      is_show: invoice.is_export || invoice.is_multi_currency,
    },
    {
      currency_code: 'Currency',
      is_show: invoice.is_export || invoice.is_multi_currency,
    },
    {
      export_type: 'Export Type',
      is_show: invoice.is_export,
    },

    { name: 'Country Name', is_show: invoice.is_export },
    { shipping_date: 'Shipping Bill Date', is_show: invoice.is_export },
    { shipping_bill_number: 'Shipping bill #', is_show: invoice.is_export },

    { shipping_port_code: 'Shipping Port Code', is_show: invoice.is_export },
  ]
  invoice.invoice_settings.show_conversion_factor != 1 && export_labels_1.shift()

  let export_labels = []
  try {
    if (invoice.is_multi_currency == 1) {
      export_labels_1 = export_labels_1.filter(item => {
        return (
          invoice.export_details[0][Object.keys(item)[0]] != '' &&
          invoice.export_details[0][Object.keys(item)[0]] != null &&
          item.is_show == true
        )
      })
    }
    if (invoice.company.gstin.length != 15) {
      export_labels_1 = export_labels_1.filter(item => Object.keys(item)[0] != 'export_type')
    }
    if (
      (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
      invoice.invoice_settings.show_both_currencies != true
    ) {
      export_labels_1 = export_labels_1.filter(item => {
        return (
          invoice.export_details[0][Object.keys(item)[0]] != '' &&
          invoice.export_details[0][Object.keys(item)[0]] != null &&
          item.is_show == true
        )
      })
    }
  } catch (e) {
    console.log(e)
  }
  while (export_labels_1.length) export_labels.push(export_labels_1.splice(0, 3))
  var data = invoice.gst_details.id == undefined ? invoice.company : invoice.gst_details
  var tax_id_label = invoice.company.tax_id_label
  return (
    <View>
      <View style={styles.billTo_headerContainer}>
        <View style={styles.billTo_blockC}>
          <View style={styles.billTo_blockC50}>
            {invoice.document_type == 'purchases' ||
            invoice.document_type == 'purchase_orders' ||
            invoice.document_type == 'purchase_returns' ? (
              <Text
                style={styles.billTo_billTo}
              >{`${invoice.invoice_settings.labels.company_details}:`}</Text>
            ) : (
              <Text
                style={styles.billTo_billTo}
              >{`${invoice.invoice_settings.labels.bill_from}:`}</Text>
            )}

            <Text style={[styles.billTo_smallFont, styles.billTo_med]}>
              {invoice.company.company_name}
            </Text>

            {/* company billing address */}
            {invoice.company.billing_address != undefined &&
              invoice.company.billing_address.line1 != null &&
              invoice.company.billing_address.line1 != '' && (
                <>
                  <Text>{invoice.company.billing_address.line1}</Text>
                  <Text>{invoice.company.billing_address.line2}</Text>
                  <Text>
                    {invoice.company.billing_address.city == ''
                      ? ''
                      : invoice.company.billing_address.city +
                        (invoice.company.billing_address.state == '' ||
                        invoice.company.billing_address.state == undefined ||
                        invoice.company.billing_address.state == null ||
                        getStateName(invoice.company.billing_address.state) == 'OTHER TERRITORY' ||
                        getStateName(invoice.company.billing_address.state) ==
                          '97-OTHERTERRITORY' ||
                        getStateName(invoice.company.billing_address.state) == 'OTHERTERRITORY'
                          ? ''
                          : ',')}{' '}
                    {invoice.company.billing_address.state == '' ||
                    getStateName(invoice.company.billing_address.state) == 'OTHER TERRITORY' ||
                    getStateName(invoice.company.billing_address.state) == '97-OTHERTERRITORY' ||
                    getStateName(invoice.company.billing_address.state) == 'OTHERTERRITORY'
                      ? ''
                      : getStateName(invoice.company.billing_address.state) +
                        (invoice.company.billing_address.pincode == '' ||
                        invoice.company.billing_address.pincode == undefined ||
                        invoice.company.billing_address.pincode == null
                          ? ''
                          : ', ')}
                    {invoice.is_export == 1 &&
                      `${invoice.company.billing_address.country}${
                        invoice.company.billing_address.pincode != '' ? ', ' : ''
                      }`}
                    {invoice.company.billing_address.pincode}
                  </Text>
                </>
              )}
          </View>

          <View style={styles.billTo_blockC50}>
            {/* {`${invoice.invoice_settings.labels.customer_billing_address}:`} */}
            {invoice.document_type == 'purchases' ||
            invoice.document_type == 'purchase_orders' ||
            invoice.document_type == 'purchase_returns' ? (
              <Text
                style={styles.billTo_billTo}
              >{`${invoice.invoice_settings.labels.vendor_details}:`}</Text>
            ) : (
              <Text
                style={styles.billTo_billTo}
              >{`${invoice.invoice_settings.labels.bill_to}:`}</Text>
            )}

            {invoice.customer.name != invoice.customer.company_name ? (
              <>
                <Text style={[styles.billTo_smallFont, styles.billTo_med]}>
                  {invoice.customer.name}
                </Text>
                <Text style={[styles.billTo_smallFont, styles.billTo_bold]}>
                  {invoice.customer.company_name}
                </Text>
              </>
            ) : (
              <Text style={[styles.billTo_smallFont, styles.billTo_bold]}>
                {invoice.customer.company_name}
              </Text>
            )}

            {invoice.customer.billing != undefined &&
              invoice.customer.billing.address_1 != null &&
              invoice.customer.billing.address_1 != '' && (
                <>
                  <Text>{invoice.customer.billing.address_1}</Text>
                  <Text>{invoice.customer.billing.address_2}</Text>
                  <Text>
                    {invoice.customer.billing.city != '' && invoice.customer.billing.city}
                    {invoice.is_export != 1 &&
                      invoice.customer.billing.state != '' &&
                      invoice.customer.billing.state != undefined &&
                      invoice.customer.billing.state != null &&
                      (getStateName(invoice.customer.billing.state) != 'OTHERTERRITORY' ||
                        getStateName(invoice.customer.billing.state) != '97-OTHERTERRITORY' ||
                        getStateName(invoice.customer.billing.state) != 'OTHER TERRITORY') &&
                      (invoice.customer.billing.city != '' ? ', ' : '') +
                        getStateName(invoice.customer.billing.state)}
                    {invoice.is_export == 1 &&
                      invoice.customer.billing.country &&
                      invoice.customer.billing.country != 'India' &&
                      '\n' + invoice.customer.billing.country}
                    {invoice.customer.billing.pincode != '' &&
                      invoice.customer.billing.pincode != null &&
                      invoice.customer.billing.pincode != 'None' &&
                      invoice.customer.billing.pincode != undefined &&
                      (invoice.customer.billing.city != '' || invoice.customer.billing.state != ''
                        ? ', '
                        : '') + invoice.customer.billing.pincode}
                  </Text>
                </>
              )}

            {/* customer details */}
          </View>
        </View>

        <View style={styles.billTo_blockC}>
          {/* company shipping address */}
          <View style={styles.billTo_blockC50}>
            {invoice.company.shipping_address != undefined &&
              invoice.company.shipping_address.address_1 != null &&
              invoice.company.shipping_address.address_1 != '' &&
              invoice.invoice_settings.show_dispatch_address === 1 && (
                <>
                  <Text style={styles.billTo_billTo}>
                    {invoice.document_type == 'purchases' ||
                    invoice.document_type == 'purchase_orders' ||
                    invoice.document_type == 'purchase_returns'
                      ? `${invoice.invoice_settings.labels.ship_to}:`
                      : `${invoice.invoice_settings.labels.ship_from}:`}
                  </Text>
                  <Text>{invoice.company.shipping_address.title}</Text>
                  <Text>{invoice.company.shipping_address.address_1}</Text>
                  <Text>{invoice.company.shipping_address.address_2}</Text>
                  <Text>
                    {invoice.company.shipping_address.city != '' &&
                      invoice.company.shipping_address.city}
                    {invoice.company.shipping_address.state != '' &&
                      invoice.company.shipping_address.state != undefined &&
                      invoice.company.shipping_address.state != null &&
                      (getStateName(invoice.company.shipping_address.state) != 'OTHERTERRITORY' ||
                        getStateName(invoice.company.shipping_address.state) !=
                          '97-OTHERTERRITORY' ||
                        getStateName(invoice.company.shipping_address.state) !=
                          'OTHER TERRITORY') &&
                      (invoice.company.shipping_address.city != '' ? ', ' : '') +
                        getStateName(invoice.company.shipping_address.state)}
                    {invoice.is_export == 1 &&
                      invoice.company.shipping_address.country &&
                      invoice.company.shipping_address.country != 'India' &&
                      '\n' + invoice.company.shipping_address.country}
                    {invoice.company.shipping_address.pincode != '' &&
                      invoice.company.shipping_address.pincode != null &&
                      invoice.company.shipping_address.pincode != 'None' &&
                      invoice.company.shipping_address.pincode != undefined &&
                      (invoice.company.shipping_address.city != '' ||
                      invoice.company.shipping_address.state != ''
                        ? ', '
                        : '') + invoice.company.shipping_address.pincode}
                    {invoice.company.shipping_address.notes != '' &&
                      '\n' + invoice.company.shipping_address.notes}
                  </Text>
                </>
              )}

            <View>
              {/* {data.gstin.length == 15 && (
                <Text style={[styles.billTo_mt]}>
                  <Text style={styles.billTo_labels}>Place of Supply: </Text>
                  {invoice.document_type == 'purchases' ||
                  invoice.document_type == 'purchase_orders' ||
                  invoice.document_type == 'purchase_returns' ||
                  invoice.immovable_tax_type == 1 ? (
                    <Text style={styles.billTo_info}>{data.state}</Text>
                  ) : (
                    <Text style={styles.billTo_info}>
                      {invoice.customer.billing.state != '' &&
                        invoice.customer.billing.state != 'OTHERTERRITORY' &&
                        invoice.customer.billing.state != '97-OTHERTERRITORY' &&
                        invoice.customer.billing.state + ' '}
                      {invoice.customer.billing.state == '' && invoice.company.billing_state}
                      {invoice.is_export == 1 &&
                        (invoice.customer.billing.state == 'OTHERTERRITORY' ||
                          invoice.customer.billing.state == '97-OTHERTERRITORY') && (
                          <Text>{invoice.export_details[0].name}</Text>
                        )}
                    </Text>
                  )}
                </Text>
              )} */}
              <Text style={[styles.billTo_mt]}>
                <PlaceOfSupply
                  invoice={invoice}
                  styles={{
                    block: {},
                    title: styles.billTo_labels,
                    text: styles.billTo_info,
                  }}
                  in_new_line={false}
                />
              </Text>

              {invoice.reference != '' && (
                <Text style={[styles.billTo_mt]}>
                  <Text style={styles.billTo_labels}>Reference: </Text>
                  <Text styles={styles.billTo_info}>{invoice.reference}</Text>
                </Text>
              )}

              {Object.keys(invoice).includes('document_custom_headers') &&
                invoice.document_custom_headers.length > 0 && (
                  <View style={[styles.billTo_mt]}>
                    {invoice.document_custom_headers.map((item, index) => (
                      <Text>
                        <Text style={styles.billTo_labels}>{item.label}: </Text>
                        <Text style={styles.billTo_info}>{item.value}</Text>
                      </Text>
                    ))}
                  </View>
                )}

              {'export_details' in invoice &&
                invoice.export_details.length > 0 &&
                export_labels.map((item2, index) => (
                  <View style={styles.billTo_mt}>
                    {item2.map(item => {
                      if (
                        Object.keys(invoice.export_details[0]).includes(Object.keys(item)[0]) &&
                        invoice.export_details[0][Object.keys(item)[0]] != '' &&
                        invoice.export_details[0][Object.keys(item)[0]] != null
                      ) {
                        return (
                          <Text>
                            <Text style={styles.billTo_labels}>{item[Object.keys(item)[0]]}: </Text>
                            <Text style={styles.billTo_info}>
                              {invoice.export_details[0][Object.keys(item)[0]]}
                            </Text>
                          </Text>
                        )
                      }
                    })}
                  </View>
                ))}
            </View>
          </View>

          {/* customer shipping address & Details */}
          <View style={styles.billTo_blockC50}>
            {!dispatch_to_doc_types.includes(invoice.document_type) &&
              invoice.customer.shipping != undefined &&
              invoice.customer.shipping.address_1 != null &&
              invoice.customer.shipping.address_1 != '' && (
                <View style={styles.billTo_mb}>
                  <Text
                    style={styles.billTo_billTo}
                  >{`${invoice.invoice_settings.labels.ship_to}:`}</Text>
                  {invoice.customer.shipping.title != '' && (
                    <Text>{invoice.customer.shipping.title}</Text>
                  )}
                  <Text>{invoice.customer.shipping.address_1}</Text>
                  <Text>{invoice.customer.shipping.address_2}</Text>
                  <Text>
                    {invoice.customer.shipping.city != '' && invoice.customer.shipping.city}
                    {invoice.is_export != 1 &&
                      invoice.customer.shipping.state != '' &&
                      invoice.customer.shipping.state != undefined &&
                      invoice.customer.shipping.state != null &&
                      (getStateName(invoice.customer.shipping.state) != 'OTHERTERRITORY' ||
                        getStateName(invoice.customer.shipping.state) != '97-OTHERTERRITORY' ||
                        getStateName(invoice.customer.shipping.state) != 'OTHER TERRITORY') &&
                      (invoice.customer.shipping.city != '' ? ', ' : '') +
                        getStateName(invoice.customer.shipping.state)}
                    {invoice.is_export == 1 &&
                      invoice.customer.shipping.country &&
                      invoice.customer.shipping.country != 'India' &&
                      '\n' + invoice.customer.shipping.country}
                    {invoice.customer.shipping.pincode != '' &&
                      invoice.customer.shipping.pincode != null &&
                      invoice.customer.shipping.pincode != 'None' &&
                      invoice.customer.shipping.pincode != undefined &&
                      (invoice.customer.shipping.city != '' || invoice.customer.shipping.state != ''
                        ? ', '
                        : '') + invoice.customer.shipping.pincode}
                    {invoice.customer.shipping.notes != '' &&
                      '\n' + invoice.customer.shipping.notes}
                  </Text>
                </View>
              )}

            {invoice.customer.name != null && invoice.customer.name != '' && (
              <View>
                {/*{invoice.document_type == 'purchases' ||
                  invoice.document_type == 'purchase_orders' ||
                  (invoice.document_type == 'purchase_returns' && (
                    <Text style={styles.billTo_billTo}>{`${invoice.invoice_settings.labels.vendor_details}:`}</Text>
                  ))}

                {invoice.customer.name != invoice.customer.company_name ? (
                  <>
                    <Text style={[styles.billTo_smallFont, styles.billTo_bold]}>
                      {invoice.customer.name}
                    </Text>
                    <Text style={[styles.billTo_smallFont, styles.billTo_bold]}>
                      {invoice.customer.company_name}
                    </Text>
                  </>
                ) : (
                  <Text style={[styles.billTo_smallFont, styles.billTo_bold]}>
                    {invoice.customer.company_name}
                  </Text>
                )}*/}

                {invoice.customer.gstin != null && invoice.customer.gstin != '' && (
                  <Text>
                    <Text style={styles.billTo_labels}>{tax_id_label}: </Text>
                    <Text style={styles.billTo_info}>{invoice.customer.gstin}</Text>
                  </Text>
                )}

                {invoice.document_type == 'purchases' &&
                  invoice.supplier_invoice_serial_number != '' && (
                    <Text>
                      <Text>
                        <Text style={styles.billTo_labels}>Supplier Invoice #:</Text>
                        <Text style={styles.billTo_info}>
                          {invoice.supplier_invoice_serial_number}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={styles.billTo_labels}>Supplier Invoice Date:</Text>
                        <Text style={styles.billTo_info}>
                          {invoice.supplier_invoice_date_string}
                        </Text>
                      </Text>
                    </Text>
                  )}

                {invoice.customer.phone != '' && (
                  <Text>
                    <Text style={styles.billTo_labels}>Ph: </Text>
                    <Text style={styles.billTo_info}>
                      {(invoice.customer.dial_code != '91'
                        ? '+' + invoice.customer.dial_code + ' '
                        : '') + invoice.customer.phone}
                    </Text>
                  </Text>
                )}

                {invoice.customer.email != '' && (
                  <Text>
                    <Text style={styles.billTo_labels}>Email: </Text>
                    <Text style={styles.billTo_info}>{invoice.customer.email}</Text>
                  </Text>
                )}

                {'custom_party_values' in invoice && (
                  <View style={styles.billTo_mt}>
                    {invoice.custom_party_values.map((item, index) => (
                      <Text>
                        <Text style={styles.billTo_labels}>{item.name}: </Text>
                        <Text style={styles.billTo_info}>{item.value}</Text>
                      </Text>
                    ))}
                  </View>
                )}

                {Object.keys(invoice).includes('eway_bill') && invoice.eway_bill.length > 0 && (
                  <View style={styles.billTo_mt}>
                    <Text>
                      <Text style={styles.billTo_labels}>Eway Bill # </Text>
                      <Text style={[styles.billTo_info]}>{invoice.eway_bill[0].serial_number}</Text>
                    </Text>

                    <Text>
                      <Text style={styles.billTo_labels}>Vehicle Number: </Text>
                      <Text style={[styles.billTo_info]}>
                        {invoice.eway_bill[0].vehicle_number}
                      </Text>
                    </Text>
                  </View>
                )}
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

export default BillTo
