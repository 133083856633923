import { Card, InputNumber, Row, Table, message } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'

export class PendingTable extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = { data: [], totalPaying: 0, selectedRows: [] }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  getTotal = (value, key, flag) => {
    let data = this.props.settlement_mode == 'manual' ? this.state.selectedRows : this.state.data
    return data.reduce(function(total, item) {
      if (item.key == key && flag) return Number(total) + Number(value)
      return Number(total) + Number(item.amount_paying)
    }, 0)
  }

  getPendingTotal = () => {
    let data = this.props.settlement_mode == 'manual' ? this.state.selectedRows : this.state.data
    return data.reduce(function(total, item) {
      return Number(total) + Number(item.amount_pending)
    }, 0)
  }

  onChange = (value, data) => {
    let newData = this.state.data

    const index = newData.findIndex(item => item.key == data.key)

    if (value == null) {
      newData[index].amount_paying = 0
    } else {
      const total = this.getTotal(value, data.key, true)

      if (total < this.props.amount) {
        newData[index].amount_paying = value
        newData.map(item => {
          item.disable = false
        })
      } else if (total == this.props.amount) {
        newData[index].amount_paying = value
        newData.map(item => {
          if (item.amount_paying == 0) item.disable = true
          else item.disable = false
        })
      } else {
        const newValue = this.props.amount - (total - value)

        if (newValue > newData[index].amount_pending) {
          newData[index].amount_paying = newData[index].amount_pending
        } else {
          newData[index].amount_paying = newValue
        }
        newData.map(item => {
          if (item.amount_paying == 0) item.disable = true
          else item.disable = false
        })
      }
    }
    if (this.props.mode == 'manual') {
      const selectedKey = this.state.selectedRows.map(item => item.key)
      newData = newData.map(item => {
        const index = selectedKey.indexOf(item.key)
        item.disable = index == -1 ? true : false
        return item
      })
    }

    this.setState({
      data: newData,
      totalPaying: this.getTotal(value, data.key, false),
    })
  }

  onAddNew = item => {
    var total = this.props.amount
    const newData = [...item]
    newData.map((item, index) => {
      if (total > 0) {
        if (item.amount_pending >= total) item.amount_paying = total
        else {
          item.amount_paying = item.amount_pending
        }
        total = total - item.amount_pending
        item.disable = false
      } else {
        item.disable = true
        item.amount_paying = 0
      }
      item.key = index
    })
    this.setState({ data: [...newData] })
    this.props.dataUpdate([...newData])
  }
  onAddNewManual = item => {
    const newData = [...item]
    newData.map(item => {
      item.disable = true
      item.amount_paying = 0
    })
    this.setState({ data: [...newData] })
    this.props.dataUpdate([...newData])
  }

  onManualChange = selectedRows => {
    //apply amount to only selected rows in data remaing should be 0
    const newData = this.state.data
    let total = this.props.amount
    let totalPaying = 0
    let Remaining = total

    selectedRows.map(item => {
      //find index of item in selected rows
      const index = newData.findIndex(data => data.key == item.key)
      if (index == -1) {
        item.amount_paying = 0
      } else {
        if (total > 0 && item.amount_paying == 0) {
          if (item.amount_pending >= total) item.amount_paying = total
          else {
            if (item.amount_pending > Remaining) item.amount_paying = Remaining
            else item.amount_paying = item.amount_pending
          }
        } else {
          if (item.amount_paying > 0 && item.amount_paying <= total)
            item.amount_paying = item.amount_paying
          else {
            if (item.amount_pending > Remaining) item.amount_paying = Remaining
            else item.amount_paying = item.amount_pending
          }
        }
        totalPaying = totalPaying + item.amount_paying
        total = total - item.amount_paying
        Remaining = Remaining - item.amount_paying
      }
      item.disable = false
    })
    //update newData
    selectedRows = selectedRows.filter(item => item.amount_paying > 0)

    newData.map(item => {
      const index = selectedRows.findIndex(data => data.key == item.key)
      if (index == -1) {
        item.amount_paying = 0
        item.disable = true
      }
    })
    // newData=newData.filter(item=>item.amount_paying>0)

    this.setState({ data: newData, selectedRows: selectedRows, totalPaying: totalPaying }, () => {
      this.props.onSelectRow(selectedRows)
      this.props.dataUpdate([...newData])
    })
  }

  render() {
    const tableColumn = [
      {
        title: 'Serial Number',
        dataIndex: 'serial_number',
      },
      {
        title: this.props.type ? 'Invoice Date' : 'PO Date',
        dataIndex: this.props.type ? 'invoice_date' : 'po_date',
      },
      {
        title: 'Total Amount (Paid)',
        dataIndex: 'total_amount',
        render: (text, data) => (
          <>
            <span>{text.toFixed(2)}</span>
            {data.amount_paid > 0 && (
              <span className="text-green ml-2">({data.amount_paid.toFixed(2)})</span>
            )}
          </>
        ),
      },
      {
        title: 'Pending Amount',
        dataIndex: 'amount_pending',
        render: (text, data) => (
          <span>
            <span className="mr-1">{this.props?.user?.selectedCompany?.currency_symbol}</span>
            {text.toFixed(2)}
          </span>
        ),
      },
      {
        title: `Amount Paying (${this.props.user.selectedCompany.currency_symbol})`,
        dataIndex: 'amount_paying',
        align: 'right',
        render: (text, data) => (
          <InputNumber
            disabled={data.disable}
            value={text}
            min={0}
            prefix={this.props.user.selectedCompany.currency_symbol}
            max={data.amount_pending}
            onChange={e => this.onChange(e, data)}
          />
        ),
      },
    ]

    const rowSelection = {
      selectedRowKeys: this.state.selectedRows.map(item => item.key),
      onChange: (selectedRowKeys, selectedRows) => {
        this.onManualChange(selectedRows)
        if (this.props.amount == 0) {
          message.error('Please enter amount for settlement')
        }
      },

      //disable row selection
      getCheckboxProps: record => {
        // console.log(record);
        return {
          disabled:
            this.props.amount - this.getTotal('', 0, false) <= 0 &&
            this.state.selectedRows.findIndex(item => item.key == record.key) == -1,
        }
      },
    }

    return (
      <div>
        {this.getTotal('', 0, false) > 0 && (
          <div>
            <Row>
              <Card
                size="small"
                className="mr-2 mb-2 mr-4 border-gray-100 bg-positive border-radius-small"
              >
                <div className="text-gray-3 m-1">
                  <div className="font-size-14 font-weight-bold">Settlement Amount</div>
                  <div className="flex items-end flex-wrap">
                    <div className="font-size-16 font-weight-bolder text-forest">
                      <span className="mr-2">
                        {this.props?.user?.selectedCompany?.currency_symbol}
                      </span>
                      {this.getTotal('', 0, false).toFixed(2)}
                    </div>
                  </div>
                </div>
              </Card>
              <Card
                size="small"
                className="mr-3 mb-2 border-gray-100 bg-negative border-radius-small"
              >
                <div className="text-gray-3 m-1">
                  <div className="font-size-14 font-weight-bold"> Remaining Amount</div>
                  <div className="flex items-end flex-wrap">
                    <div className="font-size-16 font-weight-bolder text-danger">
                      <span className="mr-2">
                        {this.props?.user?.selectedCompany?.currency_symbol}
                      </span>

                      {this.props.amount - this.getTotal('', 0, false).toFixed(2)}
                    </div>
                  </div>
                </div>
              </Card>
            </Row>
          </div>
        )}
        <Table
          size="small"
          dataSource={this.state.data}
          columns={tableColumn}
          pagination={{
            pageSize: 5,
            showSizeChanger: false,
          }}
          rowSelection={this.props.mode == 'manual' ? { type: 'checkbox', ...rowSelection } : null}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(PendingTable)
