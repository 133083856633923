import Icon, { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Switch,
  Tooltip,
  Upload,
  message,
} from 'antd'
import ImgCrop from 'antd-img-crop'
import { formItemLayout, getBase64 } from 'components/other/utility'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get_categories } from 'redux/document/actions'
import { getMultipartAPI } from 'services/jwt'
import store from 'store'

const { TextArea } = Input

class CategoryForm extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      isFirstTime: false,
      isEdit: false,
      formFlag: false,
      selectedData: { category_id: '', category_name: '', description: '', image: '' },
      show_online: false,
      fileList: [],
      imageDeleteModal: false,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  onFinish = async values => {
    this.setState({ loading: true })

    var form_data = new FormData()

    for (var key in this.state.selectedData) {
      form_data.set(key, this.state.selectedData[key])
    }

    for (var key in values) {
      form_data.set(key, values[key])
    }

    if (this.state.file) {
      form_data.append('file', this.state.file)
    }

    var url = this.state.formFlag ? 'update' : 'add'
    if (this.state.formFlag) {
      form_data.append('category_id', this.state.selectedData.category_id)
    }

    const data = await getMultipartAPI('category', url, form_data)

    if (data) {
      this.props.onAPICalled(data, this.state.formFlag)
      this.props.dispatch(get_categories())
      this.setState({
        visible: false,
        file: undefined,
        fileList: [],
        loading: false,
      })
    }
  }

  onAdd = () => {
    this.setState(
      {
        visible: true,
        formFlag: false,
        selectedData: { category_id: '', category_name: '', description: '', image: '' },
      },
      () => {
        this.formRef?.current?.setFieldsValue({
          category_name: '',
          description: '',
        })
      },
    )
  }

  onFill = data => {
    this.setState(
      { visible: true, show_online: data.show_online, formFlag: true, selectedData: data },
      () => {
        this.formRef.current.setFieldsValue({
          ...data,
          file: null,
        })
      },
    )
  }

  customRequest = async ({ onSuccess, onError, file }) => {
    if (file.size / 1024 / 1024 > 3) {
      message.error('Image must smaller than 3MB')
      onError()
      return
    }

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }
    const fileList = [
      {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: file.url || file.preview,
      },
    ]
    this.setState({
      file: file,
      fileList,
      selectedData: { ...this.state.selectedData, image: file.url || file.preview },
    })
    onSuccess('ok')
  }

  render() {
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )

    if (this.state.selectedData.image != '') {
      var fileList = [
        {
          uid: '-1',
          name: 'header.png',
          status: 'done',
          url: this.state.selectedData.image,
        },
      ]
    } else {
      var fileList = []
    }

    return (
      <Drawer
        title="Category"
        placement="right"
        width="45%"
        closable={true}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        onClose={() => this.setState({ visible: false })}
        open={this.state.visible}
        footer={
          <Button onClick={() => this.formRef.current.submit()} type="primary">
            {this.state.formFlag ? 'Update Category' : 'Add Category'}
          </Button>
        }
        extra={
          <Button onClick={() => this.formRef.current.submit()} type="primary">
            {this.state.formFlag ? 'Update Category' : 'Add Category'}
          </Button>
        }
      >
        <Card size="form" bordered={true} className="border-radius-small mb-4">
          <Form
            {...formItemLayout}
            labelAlign="left"
            ref={this.formRef}
            name="categoryForm"
            onFinish={this.onFinish}
            initialValues={{
              category_name: this.state.selectedData.category_name,
              description: this.state.selectedData.description,
            }}
          >
            <span className="flex justify-center">
              <Form.Item name="file" label="Category Image" valuePropName="file">
                <ImgCrop rotationSlider>
                  <Upload
                    accept={'.jpg, .jpeg, .png'}
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader w-200"
                    fileList={fileList}
                    customRequest={this.customRequest}
                    onRemove={() => {
                      this.setState({
                        imageDeleteModal: true,
                      })
                    }}
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                </ImgCrop>
              </Form.Item>
            </span>

            <Form.Item
              name="category_name"
              label="Category"
              autoFocus
              rules={[
                { required: true, message: 'Please fill name' },
                { pattern: /^[^/]*$/, message: 'Category name cannot contain slashes' },
              ]}
            >
              <Input placeholder="Category" autoFocus />
            </Form.Item>

            <Form.Item name="description" label="Description">
              <TextArea placeholder="Item Description" />
            </Form.Item>

            <Form.Item
              name="order_num"
              rules={[
                {
                  required: store.get('online_store') == 1,
                  message:
                    'Please fill Order number. Categories appear in the Online Store based on this order.',
                },
              ]}
              hidden={store.get('online_store') == 0}
              label={
                <span>
                  Order&nbsp;
                  <Tooltip title="Categories appear in the Online Store based on this order.">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              <InputNumber placeholder="Order" min={1} className="w-full" />
            </Form.Item>

            {this.props?.user?.selectedCompany?.country_code == 'IN' && (
              <Form.Item
                name="show_online"
                label="Show in Online Store"
                valuePropName="checked"
                extra="Show or hide the category in catalogue/ online store"
              >
                <Switch
                  style={
                    this.state.show_online
                      ? { backgroundColor: '#15be53' }
                      : { backgroundColor: '#e11900' }
                  }
                  checked={this.state.show_online}
                  onChange={e => this.setState({ show_online: e })}
                  checkedChildren={<span className="font-weight-bold"></span>}
                  unCheckedChildren={<span className="font-weight-bold"></span>}
                />
              </Form.Item>
            )}
          </Form>
        </Card>

        <Modal
          title={' Are you sure ? '}
          open={this.state.imageDeleteModal}
          onCancel={() => this.setState({ imageDeleteModal: false })}
          centered
          footer={[
            <Button
              key="submit"
              type="danger"
              onClick={() => {
                this.setState({
                  selectedData: { ...this.state.selectedData, image: '' },
                  fileList: [],
                  imageDeleteModal: false,
                })
              }}
            >
              Delete
            </Button>,
          ]}
        >
          Category image will be deleted permanently.
        </Modal>
      </Drawer>
    )
  }
}

const mapsStateToProps = state => {
  return { document: state.document, user: state.user }
}

export default connect(mapsStateToProps)(CategoryForm)
