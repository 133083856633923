import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import AgGridTable from 'components/agGrid/agGridTable'
import { Button, Input, Modal, Tooltip } from 'antd'
import AutoFinish from 'components/autoFinish'
import NewBadge from 'components/badges/new'
import ProductForm from 'components/forms/productForm'

interface Product {
  id: number
  item_name: string
  product_name?: string
  qty: number
  tax: number
  unit_price: number
  purchase_unit_price: number
  purchase_price: number
  price_with_tax: number
  discount_amount: number
  discount: number
  description: string
  status: string
  product_id: number
  variant_name?: string
  net_amount: number
  tax_amount: number
  total_amount: number
  price: number
}

interface ProductsTableProps {
  initialData: Product[]
  currencySymbol: string
  taxes: string[]
  onAddItemsToBill: (products: any[]) => void
  onCancel: () => void
}

const UploadedProductsTable = forwardRef<any, ProductsTableProps>((props, ref) => {
  const [products, setProducts] = useState<Product[]>(props.initialData)
  const [selectedRows, setSelectedRows] = useState<Product[]>([])
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const [selectedProduct, setSelectedProduct] = useState<number>(-1)
  const gridRef = useRef<any>()
  const autoFinishRef = useRef<any>()
  const productFormRef = useRef<any>()

  useEffect(() => {
    setProducts(props.initialData)
  }, [props.initialData])

  const onRowSelected = (event: any) => {
    const selectedNodes = event.api.getSelectedNodes()
    const selectedData = selectedNodes.map((node: any) => node.data)
    setSelectedRows(selectedData)
  }

  const onUpdateValue = (data: Product, key: string, newValue: number | string) => {
    // @ts-ignore
    data[key] = newValue
    if (
      [
        'tax',
        'unit_price',
        'price_with_tax',
        'purchase_unit_price',
        'purchase_price',
        'discount',
        'qty',
      ].includes(key)
    ) {
      newValue = parseFloat(newValue as string)

      if (key == 'tax') {
        data['price_with_tax'] = data['unit_price'] * (1 + newValue / 100)
        if (data['purchase_unit_price']) {
          data['purchase_price'] = data['purchase_unit_price'] * (1 + newValue / 100)
        }
      } else if (key == 'unit_price') {
        data['price_with_tax'] = newValue * (1 + data['tax'] / 100)
        data['price'] = data['unit_price']
      } else if (key == 'price_with_tax') {
        data['unit_price'] = (newValue * 100) / (100 + data['tax'])
      } else if (key == 'purchase_unit_price') {
        data['purchase_price'] = newValue * (1 + data['tax'] / 100)
      } else if (key == 'purchase_price') {
        data['purchase_unit_price'] = (newValue * 100) / (100 + data['tax'])
      }

      data['discount_amount'] = data['unit_price'] * (data['discount'] / 100)
      data['net_amount'] = data['unit_price'] * data['qty'] - data['discount_amount']
      data['tax_amount'] = data['net_amount'] * (data['tax'] / 100)
      data['total_amount'] = data['net_amount'] + data['tax_amount'] - data['discount_amount']
    }

    if (key == 'product_id') {
      data['status'] = 'update'
    }

    gridRef.current.updateRowValue(data)

    const updatedData = products.map((product: Product) => {
      if (product.id == data.id) {
        return data
      }
      return product
    })
    setProducts(updatedData)
  }

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'item_name',
      editable: false,
      minWidth: 400,
      suppressHeaderKeyboardEvent: (params: any) => {
        var key = params.event.key
        return (
          key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Enter' || params.event.ctrlKey
        )
      },
      suppressKeyboardEvent: (params: any) => {
        var key = params.event.key
        return (
          key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Enter' || params.event.ctrlKey
        )
      },
      cellRenderer: (params: any) => {
        return (
          <div className="flex flex-column justify-between items-center">
            <AutoFinish
              style={{ width: '70%' }}
              placeholder={'Search Products'}
              // autoFocus={true}
              searchType={'product'}
              ref={autoFinishRef}
              callData={(value: any, option: any) => {
                onUpdateValue(params.data, 'product_id', option.product_id)
                onUpdateValue(params.data, 'item_name', option.product_name)
                onUpdateValue(params.data, 'product_name', option.product_name)
                onUpdateValue(params.data, 'tax', option.tax)

                if (option.variant_name) {
                  onUpdateValue(params.data, 'variant_name', option.variant_name)
                  onUpdateValue(params.data, 'variant_id', option.variant_id)
                } else {
                  onUpdateValue(params.data, 'variant_name', '')
                  onUpdateValue(params.data, 'variant_id', option.variant_id)
                }
              }}
              dropdownMatchSelectWidth={true}
              autoFetchInitialData={false}
              searchText={params.value ?? ''}
            />
            <div>
              {params.data.product_id == -1 && (
                <Tooltip title="New Product, click to add product">
                  <Button
                    size="small"
                    type="text"
                    onClick={() => {
                      setSelectedProduct(params.data.id)
                      productFormRef.current.onAdd(params.value, 1, params.data)
                    }}
                  >
                    <NewBadge />
                  </Button>
                </Tooltip>
              )}

              <Tooltip title="Click to remove the product from the list">
                <Button
                  size="small"
                  type="text"
                  className="ml-1"
                  onClick={() => {
                    setSelectedProduct(params.data.id)
                    setProducts(products.filter((product: Product) => product.id != params.data.id))
                  }}
                >
                  {/* X{' '} */}
                  <i className="fa-solid fa-xmark mx-2 text-danger" />
                </Button>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      editable: true,
      minWidth: 150,
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
        maxLength: 20,
      },
      // cellClassRules: {
      //   'bg-gray-75': 'data.variant_name != null && data.variant_name != ""',
      // },
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      valueFormatter: (params: any) => `${params.value} %`,
      // cellClassRules: {
      //   'bg-gray-75': 'data.variant_name && data.variant_name != null && data.variant_name != ""',
      // },
      minWidth: 150,
      editable: (params: any) => params.data.product_id == -1,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: props.taxes,
        valueListGap: 10,
        valueListMaxHeight: 200,
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
      },
    },
    {
      title: 'Unit Price',
      dataIndex: 'unit_price',
      valueFormatter: (params: any) =>
        `${props.currencySymbol} ${(parseFloat(params.value) || 0).toFixed(2)}`,
      editable: true,
    },

    {
      title: 'Price With Tax',
      dataIndex: 'price_with_tax',
      valueFormatter: (params: any) =>
        `${props.currencySymbol} ${(parseFloat(params.value) || 0).toFixed(2)}`,
    },
    {
      title: 'Discount(%)',
      dataIndex: 'discount',
      editable: true,
      valueFormatter: (params: any) => `${params.value} %`,
    },
    {
      title: 'Net Amount',
      dataIndex: 'net_amount',
      editable: false,
      valueFormatter: (params: any) =>
        `${props.currencySymbol} ${(parseFloat(params.value) || 0).toFixed(2)}`,
    },
    {
      title: 'Tax Amount',
      dataIndex: 'tax_amount',
      editable: false,
      valueFormatter: (params: any) =>
        `${props.currencySymbol} ${(parseFloat(params.value) || 0).toFixed(2)}`,
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      editable: false,
      valueFormatter: (params: any) =>
        `${props.currencySymbol} ${(parseFloat(params.value) || 0).toFixed(2)}`,
    },

    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
      cellEditor: 'richTextEditor',
      cellEditorPopup: true,
      cellEditorPopupPosition: 'under',
      cellEditorParams: {
        maxLength: 100,
      },
      cellRenderer: (params: any) => (
        <div
          style={{ width: '100%', height: '30px', overflow: 'clip' }}
          dangerouslySetInnerHTML={{ __html: params.value }}
        />
      ),
    },
  ]

  useImperativeHandle(ref, () => ({
    getSelectedRows: () => selectedRows,
    getGridApi: () => gridRef.current?.api,
  }))

  const addItemsToBill = (ignoreNewProducts: boolean) => {
    const havNewProducts = products.some((product: Product) => product.product_id == -1)
    if (havNewProducts && !ignoreNewProducts) {
      setConfirmModal(true)
      return
    }

    props.onAddItemsToBill(products.filter((product: Product) => product.product_id != -1))
    setConfirmModal(false)
    setProducts([])
  }

  const setProductID = (product_details: any) => {
    const updatedData = products.map((product: Product) => {
      if (product.id == selectedProduct) {
        return {
          ...product_details,
          ...product,
          product_id: product_details.product_id,
          tax: product_details.tax,
          item_name: product_details.product_name ?? product.item_name,
          price: product.unit_price,
          discount_value: (product.total_amount * product.discount) / 100,
          discount_net_value: (product.net_amount * product.discount) / 100,
          discount_unit_price_value: product.discount_amount,
          discount_price_with_tax_value: (product.price_with_tax * product.discount) / 100,
          is_discount_percent: 1,
          selected_discount_type: 'discount',
          status: 'update',
        }
      }
      return product
    })
    setProducts(updatedData)
  }

  return (
    <div>
      <div className="">
        <AgGridTable
          dataSource={products}
          onReady={async (event: any) => {
            ''
          }}
          columns={columns}
          ref={gridRef}
          onUpdateValue={onUpdateValue}
          onRowSelected={(event: any) => {
            onRowSelected(event)
          }}
          isRowSelectable={(params: any) => {
            return params.data.product_id == -1
          }}
          readOnlyEdit={true}
          singleClickEdit={true}
        />
      </div>
      <div className="flex flex-column mt-2">
        <div>
          {/* <span className="font-weight-bolder">Selected Records:</span> {selectedRows.length} */}
          <span className=" text-gray-700 1 mr-2">
            <div
              className="bg-card-forest mr-2 inline-block border-gray-2"
              style={{ width: '10px', height: '10px' }}
            />{' '}
            {'New '}
          </span>
          <span className=" text-gray-700 1 mr-2 ">
            <div
              className="bg-warning mr-2 inline-block border-gray-2"
              style={{ width: '10px', height: '10px' }}
            />{' '}
            {'Existing '}
          </span>
        </div>

        <div className="flex ml-auto justify-around">
          <Button type="danger" onClick={props.onCancel} className="mr-2">
            Cancel
          </Button>
          <Button type="success" onClick={() => addItemsToBill(false)}>
            Add Items to Bill
          </Button>
        </div>
      </div>

      {/* Confirmation modal */}
      <Modal
        title={'Confirm Changes ? '}
        open={confirmModal}
        onCancel={() => setConfirmModal(false)}
        width={640}
        centered
        closeIcon={<i className="fa-solid fa-xmark"></i>}
        footer={
          <>
            <Button key="back" onClick={() => setConfirmModal(false)}>
              Close
            </Button>
            <Button
              className="font-weight-bold"
              // @ts-ignore
              type={'danger-3'}
              onClick={() => {
                addItemsToBill(true)
              }}
            >
              Proceed Anyway
            </Button>
          </>
        }
      >
        <>
          <span>
            You have new products in the list, only existing products will be added to bill.
            <br />
            <span className="text-danger font-weight-bolder">Do you want to proceed anyway ?</span>
          </span>
        </>
      </Modal>

      <ProductForm
        ref={productFormRef}
        onAPICalled={(newData: Product) => {
          setProductID(newData)
        }}
      />
    </div>
  )
})

export default UploadedProductsTable
