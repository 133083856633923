import { Button, Col, Upload, message } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getMultipartAPI } from 'services/jwt'
import { getBase64 } from './utility'

export class UploadFiles extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {}

  customRequest = async ({ onSuccess, onError, file }) => {
    this.props.setLoading(true)
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }

    const form = new FormData()
    form.append('file', file)

    const data = await getMultipartAPI('utils', 'save_temp_files', form)
    if (data?.success) {
      onSuccess(null, file)

      await this.props.onChange([...this.props.attachments.slice(-2), ...data.attachments])

      message.success(data.message)
    } else {
      message.error(data?.message ?? 'something went wrong!')
      onError()
    }
    this.props.setLoading(false)
  }

  beforeUpload = file => {
    const isLt3M = file.size / 1024 / 1024 < 3
    if (!isLt3M) {
      message.error('File must smaller than 3MB!')
    }
    return isLt3M
  }
  handleChange = ({ file, fileList }) => {
    if (file.status == 'removed') {
      const newFiles = this.props.attachments.filter(
        file1 => file1.uid !== file.uid && file1.url !== file.url,
      )
      this.props.onChange(newFiles)
    }
  }

  render() {
    return (
      <>
        <Col span={24}>
          <Upload
            customRequest={this.customRequest}
            onChange={this.handleChange}
            beforeUpload={this.beforeUpload}
            fileList={this.props.attachments}
            maxCount={this.props.maxCount}
            multiple={this.props.multiple}
          >
            <Button
              type="dashed"
              className="mt-2 mb-2"
              disabled={this.props.attachments.length < this.props.maxCount ? false : true}
            >
              <i className="fa-solid fa-arrow-up-from-bracket mr-2"></i>
              <span>Attach Files (Max: {this.props.maxCount})</span>
            </Button>
          </Upload>
        </Col>
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    states: state.document.states,
  }
}

export default connect(mapStateToProps)(UploadFiles)
