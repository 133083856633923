import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { getAPIData } from 'services/jwt'
import { CountryInfo, GetCountryAction, setCountryInfo } from './actions'
import { CountryActions } from './types'
import dayjs, { Dayjs } from 'dayjs'

interface CountryInfoAPIResponse {
  success: boolean
  country_info: CountryInfo
}

export function* getCountryInfo(action: GetCountryAction) {
  const countries: CountryInfo = yield select(state => state.countries.info)

  const { country_code } = action.payload
  let params_string = ''

  if (country_code in countries) {
    return
  }

  if (country_code !== '') {
    params_string = `?country_code=${country_code}`
  }

  try {
    const data: CountryInfoAPIResponse = yield call(() =>
      getAPIData('utils', `get_country_info${params_string}`),
    )
    if (data?.success) {
      let date_ranges: { [key: string]: Dayjs[] } = {}
      let date_ranges_obj: { [key: string]: any } = {}
      data.country_info?.[country_code]?.date_ranges.forEach((range: any) => {
        date_ranges_obj[range['title']] = range['date_range']
      })

      date_ranges = Object.fromEntries(
        Object.entries(date_ranges_obj).map(([key, range]) => [
          key,
          (range as Dayjs[]).map(date => dayjs(date)),
        ]),
      )
      data.country_info[country_code].date_ranges = date_ranges
      yield put(setCountryInfo(data.country_info))
    }
  } catch (error) {
    console.error('Error fetching country info:', error)
  }
}

export default function* countries() {
  yield all([takeEvery(CountryActions.GET_COUNTRY_INFO, getCountryInfo)])
}
