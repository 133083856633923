import { Button, Tooltip } from 'antd'
import { FREE_USER_EINVOICE_LIMIT, FREE_USER_EWAYBILL_LIMIT } from 'utils/constants'

export default function UpgradeToEinvEwb({
  noOfDocsLeft,
  onUpgradeClick,
  size = 'middle',
  is_ewb = false,
}) {
  var tooltip_text =
    noOfDocsLeft === 0
      ? 'Free quota exceeded. Upgrade to E-Invoicing Plan for unlimited access!'
      : `Only ${noOfDocsLeft} out of ${FREE_USER_EINVOICE_LIMIT} E-Invoices remaining in your free plan. Upgrade to E-Invoicing Plan for unlimited access!`

  var content =
    noOfDocsLeft === 0 ? 'Upgrade Now! 🚀' : `${noOfDocsLeft} / ${FREE_USER_EINVOICE_LIMIT}`

  if (is_ewb) {
    tooltip_text =
      noOfDocsLeft === 0
        ? 'Free quota exceeded. Upgrade to JET Plan for unlimited access!'
        : `Only ${noOfDocsLeft} out of ${FREE_USER_EWAYBILL_LIMIT} E-Waybills remaining in your free plan. Upgrade to JET Plan for unlimited access!`
    content =
      noOfDocsLeft === 0 ? 'Upgrade Now! 🚀' : `${noOfDocsLeft} / ${FREE_USER_EWAYBILL_LIMIT}`
  }

  return (
    <Tooltip title={tooltip_text} placement="top">
      <Button
        type="sign-up"
        size={size}
        className="font-semibold flex items-center gap-2 px-4 py-2 bg-amber-300 rounded-lg"
        onClick={onUpgradeClick}
      >
        <span className="font-semibold">{content}</span>
        {noOfDocsLeft != 0 && <span className="font-bold">Upgrade Now! 🚀</span>}
      </Button>
    </Tooltip>
  )
}
