import React from 'react'
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js'
import { StyleSheet, View, Text, Link } from '@react-pdf/renderer'
import redraft from 'redraft'

const RichText = ({ note, FONT_FACTOR = 8, locale }) => {
  const styles = () =>
    StyleSheet.create({
      headingOne: {
        marginBottom: 0,
        marginTop: 0.9,
        fontFamily: `helreg_${locale}`,
        // lineHeight: 1.35,
        fontSize: FONT_FACTOR,
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      text: {
        //marginBottom: 8,
        fontFamily: `helreg_${locale}`,
        fontSize: FONT_FACTOR,
        lineHeight: 1.35,
      },
      list: {
        //marginBottom: 2,
        // marginLeft: 6,
        width: '100%',
      },
      listItem: {
        width: '100%',
        marginBottom: 0,
      },
      listItemText: {
        fontSize: FONT_FACTOR,
        lineHeight: 1.35,
      },
      bold: {
        fontFamily: `helmed_${locale}`,
      },
      italic: {
        fontFamily: `helitalic_${locale}`,
      },
    })
  const RichTextStyles = styles()

  const HeadingOne = ({ children }) => {
    return (
      <View>
        <Text style={RichTextStyles.headingOne}>{children}</Text>
      </View>
    )
  }

  const UnorderedList = ({ children }) => {
    return <View style={RichTextStyles.list}>{children}</View>
  }

  const UnorderedListItem = ({ children }) => {
    return (
      <View style={RichTextStyles.listItem}>
        <Text style={RichTextStyles.listItemText}>
          • &nbsp;<Text>{children}</Text>
        </Text>
      </View>
    )
  }

  const OrderedList = ({ children }) => {
    return <View style={RichTextStyles.list}>{children}</View>
  }

  const OrderedListItem = ({ children, index }) => {
    return (
      <View style={RichTextStyles.listItem}>
        <Text style={RichTextStyles.listItemText}>
          {index + 1}. &nbsp;<Text>{children}</Text>
        </Text>
      </View>
    )
  }

  const renderers = {
    inline: {
      // The key passed here is just an index based on rendering order inside a block
      BOLD: (children, { key }) => {
        return (
          <Text key={`bold-${key}`} style={RichTextStyles.bold}>
            {children}
          </Text>
        )
      },
      ITALIC: (children, { key }) => {
        return (
          <Text key={`italic-${key}`} style={RichTextStyles.italic}>
            {children}
          </Text>
        )
      },
      UNDERLINE: (children, { key }) => {
        return (
          <Text key={`underline-${key}`} style={{ textDecoration: 'underline' }}>
            {children}
          </Text>
        )
      },
    },
    /**
     * Blocks receive children and depth
     * Note that children are an array of blocks with same styling,
     */
    blocks: {
      unstyled: (children, { keys }) => {
        return children.map((child, index) => {
          return (
            <Text style={RichTextStyles.headingOne} key={keys[index]}>
              <Text style={RichTextStyles.text}>{child}</Text>
            </Text>
          )
        })
      },
      'header-one': (children, { keys }) => {
        return children.map((child, index) => {
          return <HeadingOne key={keys[index]}>{child}</HeadingOne>
        })
      },
      'unordered-list-item': (children, { depth, keys }) => {
        return (
          <UnorderedList key={keys[keys.length - 1]} depth={depth}>
            {children.map((child, index) => (
              <UnorderedListItem key={keys[index]}>{child}</UnorderedListItem>
            ))}
          </UnorderedList>
        )
      },
      'ordered-list-item': (children, { depth, keys }) => {
        return (
          <OrderedList key={keys.join('|')} depth={depth}>
            {children.map((child, index) => (
              <OrderedListItem key={keys[index]} index={index}>
                {child}
              </OrderedListItem>
            ))}
          </OrderedList>
        )
      },
    },
    /**
     * Entities receive children and the entity data
     */
    entities: {
      // key is the entity key value from raw
      LINK: (children, data, { key }) => (
        <Link key={key} src={data.url}>
          {children}
        </Link>
      ),
    },
  }
  var FONT_FACTOR = FONT_FACTOR
  const blocksFromHTML = convertFromHTML(note)
  const initialState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  )

  const editorState = EditorState.createWithContent(initialState)
  const rawContent = convertToRaw(editorState.getCurrentContent())
  const drafted_text = redraft(rawContent, renderers, {
    blockFallback: 'unstyled',
  })
  const full_text = <View style={RichTextStyles.headingOne}>{drafted_text}</View>
  return full_text
}

export default RichText
