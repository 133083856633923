import { Document, Page, StyleSheet } from '@react-pdf/renderer'
import React, { useEffect } from 'react'
import InvoiceTitle from './invoiceTitle'
import PartAB from './part_ab'
import VehicleDetails from './vehicleDetails'

import { registerFont } from 'components/fonts'

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    paddingTop: 25,
    paddingHorizontal: 30,
    paddingBottom: 54,
    lineHeight: 1.3,
    flexDirection: 'column',
  },
  logo: {
    width: 'auto',
    height: '45',
    position: 'relative',
    marginRight: 'auto',
    textAlign: 'left',
    marginBottom: 5,
  },
  footer: {
    alignContent: 'bottom',
    position: 'absolute',
    fontFamily: 'helmed_English',
    bottom: 27,
    left: 30,
  },
  footerNote: {
    fontSize: 8.1,
    bottom: 18,
    left: 30,
    alignContent: 'bottom',
    position: 'absolute',
    fontFamily: 'helmed_English',
    marginTop: 0,
  },
  alignRight: {
    fontSize: 8.1,
    textAlign: 'right',
  },
})

export default function Invoice({ invoice }) {
  useEffect(() => {
    registerFont(invoice, true)
  }, [])

  return (
    <Document>
      <Page
        // orientation={invoice.invoice_settings.pdf_orientation}
        wrap
        size="A4"
        key={1}
        style={{
          ...styles.page,
          // paddingTop:
          //   25 + (!!invoice.invoice_settings.margin_top ? invoice.invoice_settings.margin_top : 0),
          // paddingBottom:
          //   54 +
          //   (!!invoice.invoice_settings.margin_bottom ? invoice.invoice_settings.margin_bottom : 0),
        }}
      >
        <InvoiceTitle title={'E-Way Bill'} invoice={invoice} />
        <PartAB invoice={invoice} />
        <VehicleDetails invoice={invoice} />
      </Page>
    </Document>
  )
}
