import { AutoComplete, Button, DatePicker, Popconfirm, Select, Table } from 'antd'
import { EnhancedEditInventoryTimeline } from 'components/forms/editInventoryTimeline'
import EnhancedInvoiceForm from 'components/forms/invoiceDetails'
import { Loader } from 'components/loaders'
import Experience from 'components/other/experience'
import { get_auto_complete_product_format } from 'components/other/utility'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { get_all_doc_counts } from 'redux/document/actions'
import { get_warehouses } from 'redux/warehouses/actions'
import { getAPI } from 'services/jwt'
import EnhancedProductAnalysis from 'components/modal/productAnalysis'

const { Option } = Select

const { RangePicker } = DatePicker
dayjs.extend(utc)

class TimelineTransactions extends Component {
  dateFormat = 'DD-MM-YYYY'
  constructor(props) {
    super(props)

    this.allType = ['Product Name', 'Product Category']

    this.state = {
      transactions: [],
      num_records: 10,
      page: 0,
      total: 0,
      dates:
        dayjs()
          .month(0)
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .month(11)
          .endOf('month')
          .format(this.dateFormat),
      searchType: 'Product Name',
      search: '',
      timeout: null,
      products_search: '',
      edit: false,
      selectedProduct: -1,
      allow_variants_in_search: 1,
      products: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      batch_id: undefined,
      warehouse_id: undefined,
      search_item: {
        page: 0,
        num_records: 25,
      },
    }
  }

  async componentDidMount() {
    this.props?.onRef(this)

    var data = await getAPI('user', 'allow_variants_in_search')
    if (data?.success) {
      this.setState({ allow_variants_in_search: data.allow_variants_in_search })
    }
    if (!this.props.fetchOnLoad) {
      this.callData(this.state.page, this.state.num_records)
    }
    if (this.props.warehouses == undefined) {
      this.props.dispatch(get_warehouses())
    }
    if (!this.props.all_doc_counts) {
      this.props.dispatch(get_all_doc_counts())
    }
  }

  async callData(page, records) {
    this.setState({ loading: true })
    const req = {
      num_records: records,
      page: page,
      payment_status: this.state.payment_status,
      search: this.state.search,
      search_type: this.state.searchType,
      date: this.state.dates,
      product_id: this.state?.selectedProduct?.product_id ?? this.props?.product_id ?? -1,
      variant_id: this.state?.selectedProduct?.variant_id ?? this.props?.variant_id ?? 0,
      batch_id: this.state?.batch_id ?? this.props?.batch_id ?? 0,
      warehouse_id: this.state?.warehouse_id,
    }

    const data = await getAPI('inventory', 'timeline', req)

    if (data) {
      const results = data.transactions.map((row, index) => ({
        key: index,
        ...row,
      }))
      this.setState({
        transactions: results,
        total: data.total_records,
        page: page,
        edit: data.edit,
        num_records: records,
        transactions_total: data.transactions_total,
        pagination: {
          current: page + 1,
          pageSize: records,
          total: data.total_records,
        },
      })
    }
    this.setState({ loading: false })
  }

  onProductSearch = async (extend = false) => {
    const req = {
      query: this.state.products_search,
      page: this.state.search_item.page,
      num_records: this.state.search_item.num_records,
    }
    if (this.state.allow_variants_in_search == 1) {
      var data = await getAPI('product', 'search', req)
    } else {
      var data = await getAPI('product', 'search_without_variants', req)
    }

    if (data) {
      let allData = data.products.map(value => {
        return {
          ...value,
          value:
            value.product_name +
            (value.variant_name == '' || value.variant_name == null
              ? ''
              : ' ' + value.variant_name),
          // id: value.product_id,
          id:
            String(value.product_id) +
            '_' +
            String(value.variant_id == undefined ? 0 : value.variant_id),
          description: value.description,
          price: value.price,
          price_with_tax: value.price_with_tax,
          tax: value.tax,
          qty: 1,
          qtyinstock: value.qty,
          product_name: value.product_name,
          discount: value.discount,
          discount_value: ((value.price_with_tax * value.discount) / 100).toFixed(2),
          barcode_id: value.barcode_id,
          variant_name: value.variant_name,
        }
      })
      allData = get_auto_complete_product_format(allData, this.props.invoice_settings)
      this.setState({
        products: !extend ? allData : [...this.state.products, ...allData],
      })
    }
  }

  onPaginationChange = current => {
    this.callData(current - 1, this.state.num_records)
  }

  search = e => {
    this.setState({ search: e.target.value }, () => {
      this.callData(this.state.page, this.state.num_records)
    })
  }

  onProductSelect = (string, object) => {
    let batch_id = object.batches.filter(
      batch => batch.batch_no.toLowerCase().indexOf(this.state.products_search.toLowerCase()) >= 0,
    )

    batch_id = batch_id.length > 0 ? batch_id[0].batch_id : undefined
    this.setState(
      {
        batch_id: batch_id,
        selectedProduct: object,
        products_search: object['product_name'],
        search: object['product_name'],
        error: false,
        products: [],
        search_item: {
          ...this.state.search_item,
          page: 0,
        },
      },
      () => {
        this.callData(0, this.state.num_records)
      },
    )
  }

  /* calling from other components to view the timeline */
  onViewTimeline = (product, batch) => {
    this.setState(
      {
        selectedProduct: product,
        products_search: product.product_name,
        batch_id: batch.batch_id,
        products: [],
        search: '',
      },
      () => this.callData(0, this.state.num_records),
    )
  }
  onCloseTimeline = () => {
    this.setState({
      selectedProduct: -1,
      batch_id: undefined,
      search: '',
    })
  }
  getQtyClass = data => {
    if (data < 0) {
      return '#e11900'
    } else if (data == 0 && data < 5) {
      return '#757575'
    } else {
      return '#09864A'
    }
  }
  handleTableChange = (pagination, filters, sorter) => {
    // console.log(pagination, filters, sorter)
    const page = pagination.current - 1
    const num_records = pagination.pageSize
    this.callData(page, num_records)
  }
  showDocumentDrawer = async (document_type, new_hash_id) => {
    if (document_type != 'expense' && document_type != 'indirect_income' && new_hash_id != '') {
      this.invoiceForm.showDocumentDrawer(document_type, new_hash_id)
    }
  }
  deleteManualTransaction = async data => {
    const req = {
      transaction_id: data.id,
    }
    const res = await getAPI('inventory', 'delete_manual_transaction', req)
    if (res.success) {
      this.callData(this.state.page, this.state.num_records)
    }
  }

  findEndProductIndex = () => {
    this.setState(
      {
        search_item: {
          ...this.state.search,
          page: this.state.search_item.page + 1,
        },
      },
      () => {
        this.onProductSearch(true)
      },
    )
  }

  render() {
    const columns = [
      {
        title: <FormattedMessage id="table.item" />,
        dataIndex: 'product_name',
        width: '20%',
        sorter: (a, b) => a.qty - b.qty,
        render: (text, data) => (
          <span>
            <span className="font-weight-bold">
              <span
                className="hover-underline"
                onClick={event => {
                  event.stopPropagation()

                  this.productAnalysis.showModal(data, data.product_id, data)
                }}
              >
                {text}

                {data.variant_name}
              </span>
              <p className="font-size-12 text-gray-500">{data.batch_no}</p>
            </span>
            {data.product_category && data.product_category != '' && (
              <span className="font-weight-bold font-size-10">
                <p className="mt-0 mb-0">
                  {/*                  <span className="text-purple mr-1">{data.product_type}</span>
                   */}{' '}
                  <span className="text-orangeproduct mr-1">
                    {data.product_category.length > 25
                      ? data.product_category.substring(0, 20) + '...'
                      : data.product_category}
                  </span>
                </p>
              </span>
            )}
          </span>
        ),
      },
      Table.EXPAND_COLUMN,
      {
        title: <FormattedMessage id="table.stockIn" />,
        dataIndex: 'qty',
        width: '10%',
        render: (text, data) => {
          return {
            props: {
              className: 'bg-positive',
            },
            children: (
              <>
                {data.stock_option == 'in' && (
                  <span className="font-weight-bolder text-forest">
                    {text}
                    {data.unit != 'OTH' && (
                      <span className="font-size-12 font-weight-bold text-purple ml-2">
                        {data.unit}
                      </span>
                    )}
                  </span>
                )}
              </>
            ),
          }
        },
      },

      {
        title: <FormattedMessage id="table.stockOut" />,
        dataIndex: 'qty',
        width: '10%',
        render: (text, data) => {
          return {
            props: {
              className: 'bg-negative',
            },
            children: (
              <>
                {data.stock_option == 'out' && (
                  <span className="font-weight-bolder text-danger">
                    {text}
                    {data.unit != 'OTH' && (
                      <span className="font-size-12 font-weight-bold text-purple ml-2">
                        {data.unit}
                      </span>
                    )}
                  </span>
                )}
              </>
            ),
          }
        },
      },
      {
        title: <FormattedMessage id="table.source" />,
        dataIndex: 'document_type',
        responsive: ['md'],
        width: '10%',
        render: (text, data) =>
          data.bom_id == 0 ? (
            <span
              className="text-purple font-weight-bold font-size-12 cursor-pointer"
              onClick={() => this.showDocumentDrawer(data.document_type, data.new_hash_id)}
            >
              <p
                className="mt-0 mb-0 text-uppercase hover-underline"
                onClick={e => {
                  e.stopPropagation()
                  this.showDocumentDrawer(data.document_type, data.new_hash_id)
                }}
              >
                {data.document_type}
              </p>
              <p className="mt-0 mb-0 text-gray-700">{data.serial_number}</p>
            </span>
          ) : (
            <span>
              <p className="mt-0 mb-0 text-uppercase">Raw Material</p>
            </span>
          ),
      },
      {
        title: <FormattedMessage id="table.category" />,
        dataIndex: 'category',
        width: '10%',
        responsive: ['md'],
        render: (text, data) => (
          <span>
            <span className="font-size-13">{data.category}</span>
          </span>
        ),
      },
      {
        title: <FormattedMessage id="table.remarks" />,
        dataIndex: 'remarks',
        responsive: ['md'],
        render: (text, data) => (
          <span>
            <span className="font-size-13">{data.remarks}</span>
            {data.transfer_id != -1 && (
              <>
                <span className="font-size-13">
                  <br />
                  Transfered from <span className="font-weight-bold">
                    {data.from_warehouse}
                  </span> to <span className="font-weight-bold">{data.to_warehouse}</span>
                </span>
              </>
            )}
          </span>
        ),
      },
      {
        title: (
          <>
            <span>
              <span className="font-size-12 mt-0 mb-0">
                <FormattedMessage id="table.date" />{' '}
              </span>
              <span className="font-size-10 mb-0 mt-0">/ Updated By</span>
            </span>
          </>
        ),
        dataIndex: 'transaction_date',
        render: (text, data) => (
          <span>
            <span className="font-size-13">{text}</span>
            <span className="text-purple font-weight-bold hidden sm:inline font-size-10">
              <p className="mt-0 mb-0">by {data.name}</p>
            </span>
          </span>
        ),
      },
      {
        title: <FormattedMessage id="table.actions" />,
        dataIndex: '',
        align: 'right',
        hidden: this.state.edit ? false : true,
        render: (text, currentData) => (
          <span className="flex float-right">
            <Button
              type="warning"
              size="small"
              className="mr-2"
              onClick={e => {
                e.stopPropagation()
                this.form.onFill(currentData, true)
              }}
            >
              <i className="fa fa-edit font-size-10 mr-1" />
              <FormattedMessage id="button.edit" />
            </Button>
            {currentData.document_type == 'manual' && currentData.is_bom == 0 && (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={e => this.deleteManualTransaction(currentData)}
                onClick={event => event.stopPropagation()}
                onCancel={event => event.stopPropagation()}
                className="mr-3"
              >
                <Button type="danger" size="small" className="mr-2">
                  <i className="fa fa-sm fa-trash " />
                </Button>
              </Popconfirm>
            )}
          </span>
        ),
        className: 'text-left',
      },
    ].filter(item => !item.hidden)
    if (
      this.state.selectedProduct != -1 &&
      this.state.selectedProduct?.has_batches != 2 &&
      (this.props?.warehouses?.length == 0 || this.state.warehouse_id > 0)
    ) {
      columns.splice(4, 0, {
        title: <FormattedMessage id="table.netQty" />,
        dataIndex: 'net_qty',
        responsive: ['md'],
        render: (text, data) => (
          <span>
            <span
              className={`font-weight-bolder ${Number(text) > 0 ? 'text-forest' : 'text-danger'}`}
            >
              {text ? Number(text).toLocaleString('en-IN') : 0}
            </span>
          </span>
        ),
      })
    }

    const expandedRowRender = record => {
      const columns1 = [
        {
          title: <FormattedMessage id="Product" />,
          dataIndex: 'product_name',
          key: 'product_name',
          width: '40%',
        },

        {
          title: <FormattedMessage id="table.quantity" />,
          dataIndex: 'qty',
          key: 'qty',
          width: '20%',
          render: (text, data) => {
            return {
              props: {
                className: data.stock_option == 'in' ? 'bg-positive' : 'bg-negative',
              },
              children: (
                <>
                  {data.stock_option == 'in' ? (
                    <span className="font-weight-bolder text-forest">
                      {text}
                      {data.unit != 'OTH' && (
                        <span className="font-size-12 font-weight-bold text-purple ml-2">
                          {data.unit}
                        </span>
                      )}
                    </span>
                  ) : (
                    <span className="font-weight-bolder text-danger">
                      {text}
                      {data.unit != 'OTH' && (
                        <span className="font-size-12 font-weight-bold text-purple ml-2">
                          {data.unit}
                        </span>
                      )}
                    </span>
                  )}
                </>
              ),
            }
          },
        },
      ]

      return (
        <>
          {record.is_bom == 1 && (
            <Table
              size="small"
              columns={columns1}
              dataSource={record.bom_items}
              pagination={false}
              className=""
              style={{ cursor: 'pointer' }}
            />
          )}
        </>
      )
    }

    const handleScroll = e => {
      const bottom =
        parseInt(e.target.scrollHeight - e.target.scrollTop) - parseInt(e.target.clientHeight) <= 1
      if (bottom) {
        this.findEndProductIndex()
      }
    }

    return (
      <div>
        <div className="inline-flex items-center float-left mb-4">
          {!this.props?.product_id && (
            <div className="mr-3 flex items-center">
              <AutoComplete
                options={this.state.products}
                className="mr-2 pb-2"
                autoFocus
                style={{ width: '640px', height: '32px' }}
                popupMatchSelectWidth={640}
                dropdownMenuStyle={{ top: 18 }}
                trigger="hover"
                dropdownRender={menu => <>{menu}</>}
                onPopupScroll={handleScroll}
                value={this.state.products_search}
                onSearch={e => {
                  clearTimeout(this.state.timeout)
                  this.setState({
                    products_search: e,
                    search_item: {
                      ...this.state.search_item,
                      page: 0,
                    },
                    timeout: setTimeout(this.onProductSearch, 1200),
                  })
                }}
                onClick={e => {
                  if (this.state.products.length == 0 && this.state.products_search == '') {
                    this.onProductSearch()
                  }
                }}
                onSelect={this.onProductSelect}
                placeholder={`Select Item`}
                prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
              ></AutoComplete>
              {this.state.selectedProduct != -1 && this.state.selectedProduct?.has_batches != 0 && (
                <Select
                  suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                  optionFilterProp="children"
                  showSearch
                  allowClear={true}
                  className="mr-2"
                  style={{
                    width: '200px',
                  }}
                  defaultValue={this.state.batch_id}
                  onChange={value =>
                    this.setState({ batch_id: value }, () =>
                      this.callData(0, this.state.num_records),
                    )
                  }
                  placeholder={'Select Batch'}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  dropdownStyle={{ minWidth: '300px' }}
                  listHeight={200}
                  value={this.state.batch_id}
                >
                  {this.state.selectedProduct.batches?.map(batch => (
                    <Select.Option value={batch.batch_id}>{batch.batch_no}</Select.Option>
                  ))}
                </Select>
              )}
            </div>
          )}

          <RangePicker
            allowClear={false}
            ranges={
              this.props?.country_info?.[this.props?.user?.selectedCompany?.country_code]
                ?.date_ranges
            }
            className="mr-2"
            onChange={(dates, dateStrings) =>
              this.setState({ dates: dateStrings[0] + ' - ' + dateStrings[1] }, () =>
                this.callData(this.state.page, this.state.num_records),
              )
            }
            value={
              this.state.dates
                ? [
                    dayjs(this.state.dates.split(' - ')[0], this.dateFormat),
                    dayjs(this.state.dates.split(' - ')[1], this.dateFormat),
                  ]
                : null
            }
            style={{
              width: 'auto',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            format={this.dateFormat}
          />
          {this.props.warehouses != undefined && this.props.warehouses.length > 0 && (
            <Select
              suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
              optionFilterProp="children"
              showSearch
              allowClear={true}
              className="mr-2"
              style={{
                width: '200px',
              }}
              onChange={value =>
                this.setState({ warehouse_id: value }, () =>
                  this.callData(0, this.state.num_records),
                )
              }
              placeholder={'Select Warehouse'}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownStyle={{ minWidth: '300px' }}
              listHeight={200}
              value={this.state.warehouse_id}
            >
              {this.props.warehouses.map(warehouse => (
                <Select.Option value={warehouse.warehouse_id}>{warehouse.name}</Select.Option>
              ))}
            </Select>
          )}
        </div>
        {this.props.all_doc_counts && this.props.all_doc_counts['products'] == 0 && (
          <Experience type={'inventory'} toUpgrade={false} />
        )}
        {this.props.all_doc_counts && this.props.all_doc_counts['products'] > 0 && (
          <div className="mb-4 ">
            <Table
              data-beacon-id="timeline_inventory"
              columns={columns}
              dataSource={this.state.transactions}
              size="small"
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
              expandable={{
                expandedRowRender: expandedRowRender,
                rowExpandable: record => record.is_bom,
              }}
              onRow={(data, rowIndex) => {
                return {
                  onClick: event => {
                    event.stopPropagation()
                    this.showDocumentDrawer(data.document_type, data.new_hash_id)
                  },
                  style: {
                    cursor: 'pointer',
                  },
                }
              }}
              expandIcon={({ expanded, onExpand, record }) =>
                record.is_bom == 1 && (
                  <Button
                    size="small"
                    type="text"
                    className="float-right"
                    onClick={e => onExpand(record, e)}
                  >
                    {expanded ? (
                      <i className="fa-sharp fa-solid fa-chevron-up"></i>
                    ) : (
                      <span className="">
                        Expand<i className="fa-solid fa-chevron-down ml-1"></i>
                      </span>
                    )}
                  </Button>
                )
              }
              loading={{
                spinning: this.state.loading,
                indicator: <Loader text={'Loading Inventory Timeline...'} visibleText={true} />,
              }}
            />
          </div>
        )}

        {/* {this.state.transactions.length != 0 && (
          // <div className="row mb-2 mt-2">
          <div className="col-xl-12 col-lg-12 text-right mt-2 mb-2">
            <div className="inline-flex items-center float-right">
              <Pagination
                size="small"
                showSizeChanger={false}
                pageSize={this.state.num_records}
                total={this.state.total}
                onChange={this.onPaginationChange}
              />
              <Select
                optionFilterProp="children"
                className="mr-2"
                defaultValue={this.state.num_records}
                onChange={value => this.callData(this.state.page, value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {[10, 20, 50, 100].map((e, i) => (
                  <Option key={i} value={e}>
                    {e}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          // </div>
        )} */}
        <EnhancedInvoiceForm onRef={ref => (this.invoiceForm = ref)} />

        <EnhancedEditInventoryTimeline
          onRef={ref => (this.form = ref)}
          onAPICalled={e => this.callData(this.state.page, this.state.num_records)}
        />
        <EnhancedProductAnalysis
          onRef={ref => (this.productAnalysis = ref)}
          menu="product"
          onProductUpdate={() => {}}
        />
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    invoice_settings: state.document.invoiceSettings,
    warehouses: state.warehouse.warehouses,
    all_doc_counts: state.document.all_doc_counts,
    user: state.user,
    country_info: state.countries.info,
  }
}
export default connect(mapStateToProps)(TimelineTransactions)
