import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { Fragment } from 'react'

const styles = StyleSheet.create({
  headerContainer: {
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    '@media min-width: 400': {
      flexDirection: 'row',
    },
    marginBottom: 4.5,
    fontFamily: 'helreg_English',
    lineHeight: 1.4,
  },
  billTo: {
    marginTop: 0,
    marginBottom: 1.8,
    color: '#333333',
    // fontFamily: 'helitalic',
    fontFamily: 'helbold_English',
    fontSize: 12,
  },
  block1: {
    width: '50%',
    marginRight: '2%',
    fontSize: 12,
  },
  block2: {
    width: '32%',
    marginRight: '2%',
    fontSize: 12,
  },
  block3: {
    width: '34%',
    marginRight: 0,
    fontSize: 12,
  },
  block4: {
    width: '34%',
    marginRight: 0,
    fontSize: 12,
  },
  block5: {
    width: '66%',
    marginRight: 0,
    fontSize: 12,
  },
  mb: {
    marginBottom: 1.8,
  },
  mb1: {
    marginBottom: 0,
  },
  smallFont: {
    fontSize: 12,
  },
  bold: {
    fontFamily: 'helbold_English',
  },
})

const BillTo = ({ invoice }) => (
  <Fragment>
    <View style={styles.headerContainer}>
      {invoice.customer_details.name != null && invoice.customer_details.name != '' && (
        <View style={styles.block1}>
          <Text style={styles.billTo}>
            {invoice.payment_details.payment_type == 'in' ? 'Customer Details:' : 'Vendor Details:'}
          </Text>
          <Text style={[styles.smallFont]}>{invoice.customer_details.name}</Text>
          <Text style={styles.bold}>{invoice.customer_details.company_name}</Text>
          <Text style={[styles.mb1, styles.smallFont, styles.bold]}>
            {invoice.customer_details.gstin}
          </Text>
          <Text style={[styles.mb1, styles.smallFont]}>
            {invoice.customer_details.phone == '' ? '' : 'Ph: ' + invoice.customer_details.phone}
            {invoice.customer_details.email == '' ||
            invoice.customer_details.email == undefined ||
            invoice.customer_details.email == null
              ? ''
              : invoice.customer_details.phone != ''
              ? ', ' + invoice.customer_details.email
              : invoice.customer_details.email}
          </Text>
        </View>
      )}
    </View>
  </Fragment>
)

export default BillTo
