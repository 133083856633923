import { Tooltip } from 'antd'
import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

export default function CopyText(props) {
  const [isCopied, setIsCopied] = useState(false)
  const displayText = String(props.text).replace(/,\$/g, `<br/>`)
  const copiedText = String(props.text).replace(/,\$/g, `\n`)

  return (
    <CopyToClipboard
      text={copiedText}
      onCopy={() => {
        setIsCopied(true)
      }}
    >
      <Tooltip
        className="hover-underline cursor-pointer"
        title={
          isCopied ? (
            <span className="font-weight-bold">
              <i className="fa-solid fa-circle-check text-success mr-1"></i> Copied
            </span>
          ) : (
            <span className="font-weight-bold">Click to copy {props.label}</span>
          )
        }
        onVisibleChange={() => setIsCopied(false)}
      >
        <span dangerouslySetInnerHTML={{ __html: displayText }} />
      </Tooltip>
    </CopyToClipboard>
  )
}
