import { Button, Drawer, message } from 'antd'
import BetaBadge from 'components/badges/beta'
import ProBadge from 'components/badges/pro'
import CouponModal from 'components/modal/coupons'
import { useEffect, useRef, useState } from 'react'
import { getAPI, getAPIData } from 'services/jwt'
import store from 'store'
import CouponsList from './listCoupons'
import { useSelector } from 'react-redux'

export function CouponsDrawer({
  coupons_visible,
  onClose,
  onApplyCoupon,
  customerData,
  total_amount,
}) {
  const currency_symbol = useSelector(state => state.user?.selectedCompany?.currency_symbol)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const coupon = useRef()

  const getCoupons = async () => {
    setLoading(true)
    const coupons_data = await getAPIData('v2/company', 'get_active_coupons')
    if (coupons_data && coupons_data.success) {
      let couponsData = coupons_data.coupons.map((item, index) => ({
        ...item,
        key: index,
        disabled: item.minimum_order_value > total_amount,
        error:
          item.minimum_order_value > total_amount
            ? `Add ${currency_symbol}${parseFloat(item.minimum_order_value - total_amount).toFixed(
                2,
              )} more to avail this coupon`
            : '',
      }))

      // Sort the coupons data based on the disabled status
      couponsData = couponsData.sort((a, b) => {
        return a.disabled - b.disabled
      })
      setData(couponsData)
    }
    setLoading(false)
  }

  const onApply = async coupon => {
    const req = {
      coupon,
      amount: total_amount,
      customers: customerData?.map(item => ({
        ...item,
        customer_id: item.id ?? 0,
        type: 'customer',
      })),
    }
    const verify = await getAPI('v2/company', 'verify_applied_coupon', req)
    if (verify.success) {
      if (verify.valid) {
        onApplyCoupon(coupon, verify.discount, verify.message)
      } else {
        let coupons = data.map(item => {
          if (item.coupon_id == coupon.coupon_id) {
            return {
              ...item,
              error: verify.message,
            }
          } else {
            return item
          }
        })

        setData(coupons)
      }
    }
  }

  useEffect(() => {
    getCoupons()
  }, [coupons_visible])

  return (
    <Drawer
      title="Coupons"
      open={coupons_visible}
      closable={true}
      closeIcon={<i className="fa-solid fa-lg fa-xmark" />}
      onClose={onClose}
      width={500}
      destroyOnClose
      bodyStyle={{
        backgroundColor: '#f5f5f5',
      }}
      extra={
        <Button
          className="mr-2 font-weight-bold"
          type="action"
          onClick={() => {
            store.get('paid') === 0
              ? message.error('Please upgrade your plan to create a coupons')
              : coupon.current?.showModal()
          }}
        >
          <i className="fa fa-badge-percent mr-2" />
          Create a Coupon {store.get('paid') === 0 ? <ProBadge /> : <BetaBadge />}
        </Button>
      }
    >
      <CouponsList data={data} loading={loading} onApply={onApply} total_amount={total_amount} />
      <CouponModal ref={coupon} onApiCalled={async () => await getCoupons()} defaultActive />
    </Drawer>
  )
}
