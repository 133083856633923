import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'
import { State } from '../../redux/types'

const IntercomComp = () => {
  const { boot, update, shutdown } = useIntercom()
  const [isBooted, setIsBooted] = useState(false)
  const userData = useSelector((state: State) => state.user)
  const permissionsData = useSelector((state: State) => state.permissions)
  const docCounts = useSelector((state: State) => state?.document?.all_doc_counts)

  const getAttributes = (
    userDetails: State['user']['userDetails'],
    companyDetails: State['permissions']['company_details'],
    docCounts: State['document']['all_doc_counts'],
  ) => {
    if (!companyDetails?.company_id || !userDetails?.user_id) {
      return false
    }
    const attributes = {
      custom_attribute_key: 'Hi There,',
      user_id: 'C' + (companyDetails?.company_id ?? '') + 'U' + (userDetails?.user_id ?? ''),
      email: userDetails?.email ?? '',
      name: (userDetails?.name ? userDetails?.name + '-' : '') + companyDetails?.company_name,
      user_name: userDetails?.name ?? '',
      phone: userDetails?.mobile ?? '',
      paid: userDetails?.paid ? 'Paid' : 'Free',
      is_paid_company: userData?.selectedCompany?.is_paid_company ? 1 : 0,
      billing: userDetails?.billing ?? '',
      company_id: companyDetails?.company_id ?? '',
      owner: companyDetails?.company_name ?? '',
      gstin: companyDetails?.gstin ?? '',
      firstPaymentDate: userDetails?.start_date ?? '',
      endDate: userDetails?.end_date ?? '',
      einvoices: docCounts?.einvoice ?? -1,
      ewaybills: docCounts?.eway_bill ?? -1,
      export_invoices: docCounts?.export_invoice ?? -1,
    }
    return attributes
  }

  useEffect(() => {
    const customAttributes = getAttributes(
      userData?.userDetails,
      permissionsData?.company_details,
      docCounts,
    )
    // console.log('customAttributes', customAttributes)
    if (customAttributes) {
      if (!isBooted) {
        // console.log('Booting Intercom....')
        boot({ customAttributes })
        setIsBooted(true)
      } else {
        // console.log('Updating Intercom....')
        update({ customAttributes })
      }
    } else {
      // console.log('Shuttingdown Intercom....')
      setIsBooted(false)
      shutdown()
    }
  }, [userData, permissionsData, docCounts])

  return <></>
}

export default IntercomComp
