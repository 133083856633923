import { Image, Link, Text, View } from '@react-pdf/renderer'
import { styles as getStyles } from './styles'

export default function InvoiceFooter({ invoice }) {
  var styles = getStyles(invoice)
  var PAPER_SIZE = invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8
  return (
    <>
      <Link
        href={invoice.company.website}
        target="_blank"
        fixed
        style={{
          ...styles.index_footer,
          width: invoice.user_details.paid == 1 ? '100%' : '80%',
          color: invoice.company.color,
          bottom:
            27 * PAPER_SIZE +
            (!!invoice.invoice_settings.margin_bottom
              ? invoice.invoice_settings.margin_bottom
              : 0) +
            (invoice.invoice_settings.invoice_footer_image != '' ? 63 : 0),
          left: !!invoice.invoice_settings.margin_left ? invoice.invoice_settings.margin_left : 10,
        }}
      >
        {invoice.company.invoice_footer}
      </Link>
      <Text
        fixed
        render={({ pageNumber, totalPages }) => `Page ${pageNumber} / ${totalPages}`}
        style={{
          ...styles.index_footerNote,
          bottom:
            17 * PAPER_SIZE +
            (!!invoice.invoice_settings.margin_bottom
              ? invoice.invoice_settings.margin_bottom
              : 0) +
            (invoice.invoice_settings.invoice_footer_image != '' ? 63 : 0),
          left: !!invoice.invoice_settings.margin_left ? invoice.invoice_settings.margin_left : 10,
        }}
      />
      <Text
        fixed
        style={{
          ...styles.index_footerNote,
          left:
            48 * PAPER_SIZE +
            (!!invoice.invoice_settings.margin_left ? invoice.invoice_settings.margin_left : 10),
          bottom:
            17 * PAPER_SIZE +
            (!!invoice.invoice_settings.margin_bottom
              ? invoice.invoice_settings.margin_bottom
              : 0) +
            (invoice.invoice_settings.invoice_footer_image != '' ? 63 : 0),
        }}
      >
        <Text>{invoice.serial_number + ' '}</Text>
        {invoice.signature == '' && invoice.invoice_settings.digitally_signed == 1 && (
          <Text>This is a computer generated document and requires no signature </Text>
        )}
        {invoice.signature != '' && invoice.invoice_settings.digitally_signed == 1 && (
          <Text>This is a digitally signed document </Text>
        )}
      </Text>

      {invoice.user_details.paid != 1 && (
        <View
          fixed
          style={{
            ...styles.index_brandingFooter,
            bottom:
              17 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_bottom
                ? invoice.invoice_settings.margin_bottom
                : 0) +
              (invoice.invoice_settings.invoice_footer_image != '' ? 63 : 0),
          }}
        >
          <View style={styles.index_brandingLogo}>
            <Text style={styles.index_alignRight}>Powered By</Text>
            <Link src="https://getswipe.in" target="_blank">
              <Image
                style={styles.index_logo}
                src={{
                  uri: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/swipe_unpaid.png',
                  method: 'GET',
                  headers: { 'Cache-Control': 'no-cache' },
                }}
                crossorigin="anonymous"
              />
            </Link>
          </View>
        </View>
      )}

      {/*watermark*/}
      {/* {invoice.user_details.paid != 1 && (
            <Image
              fixed
              style={[styles.index_watermark]}
              src={{
                uri: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/watermark2.png',
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
              }}
              crossorigin="anonymous"
            />
          )} */}

      {invoice.invoice_settings.watermark != '' && invoice.user_details.paid != 0 && (
        <Image
          fixed
          style={[styles.index_watermark]}
          src={{
            uri: invoice.invoice_settings.watermark,
            method: 'GET',
            headers: { 'Cache-Control': 'no-cache' },
          }}
          crossorigin="anonymous"
        />
      )}

      {/* Footer Banner */}
      {invoice.invoice_settings.invoice_footer_image != '' && (
        <View
          fixed
          style={{
            ...styles.index_footer,
            bottom: !!invoice.invoice_settings.margin_bottom
              ? invoice.invoice_settings.margin_bottom
              : 0,
            // left: !!invoice.invoice_settings.margin_left
            //   ? invoice.invoice_settings.margin_left
            //   : 10,
            // right: !!invoice.invoice_settings.margin_right
            //   ? invoice.invoice_settings.margin_right
            //   : 10,
          }}
        >
          <Image
            style={styles.index_banner}
            src={{
              uri: invoice.invoice_settings.invoice_footer_image,
              //uri: '/resources/images/banner3.png',
              method: 'GET',
              headers: { 'Cache-Control': 'no-cache' },
            }}
            crossorigin="anonymous"
          />
        </View>
      )}
    </>
  )
}
