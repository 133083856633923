import { initializeApp, getApps, getApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyBIREKUF69cxvtcR20jiTHsoibyyssKilc',
  authDomain: 'vectorx-business.firebaseapp.com',
  projectId: 'vectorx-business',
  storageBucket: 'vectorx-business.appspot.com',
  messagingSenderId: '448547958118',
  appId: '1:448547958118:web:c8142a4cffc20058892ca7',
  measurementId: 'G-KV3611HNQY',
  databaseURL: 'https://vectorx-business-default-rtdb.firebaseio.com/',
}

export const getFirebaseApp = () => {
  let app
  if (!getApps().length) {
    app = initializeApp(firebaseConfig)
  } else {
    app = getApp()
  }

  return app
}
