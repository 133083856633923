import { Drawer } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import MenuLeft from './MenuLeft'
import style from './style.module.scss'
import { motion } from 'framer-motion'

const mapStateToProps = ({ settings }) => ({
  menuLayoutType: settings.menuLayoutType,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  isMobileView: settings.isMobileView,
  leftMenuWidth: settings.leftMenuWidth,
})

let touchStartPrev = 0
let touchStartLocked = false

const Menu = ({ dispatch, isMobileMenuOpen, isMobileView, menuLayoutType, leftMenuWidth }) => {
  const unify = e => {
    return e.changedTouches ? e.changedTouches[0] : e
  }

  const touchStartHandler = e => {
    const x = unify(e).clientX
    touchStartPrev = x
    touchStartLocked = x > 70
  }

  const touchMoveHandler = e => {
    const x = unify(e).clientX
    const prev = touchStartPrev
    if (x - prev > 50 && !touchStartLocked) {
      toggleMobileMenu()
      touchStartLocked = true
    }
  }

  useEffect(() => {
    // mobile menu touch slide opener

    document.addEventListener('touchstart', touchStartHandler, { passive: false })
    document.addEventListener('touchmove', touchMoveHandler, { passive: false })
    // Cleanup function
    return () => {
      document?.removeEventListener('touchstart', touchStartHandler)
      document?.removeEventListener('touchmove', touchMoveHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleMobileMenu = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  const GetMenu = () => {
    // console.log(isMobileView);
    if (isMobileView) {
      return (
        <div>
          <div
            className={style.handler}
            onClick={toggleMobileMenu}
            onFocus={e => {
              e.preventDefault()
            }}
            onKeyPress={toggleMobileMenu}
            role="button"
            tabIndex="0"
          >
            <div className={style.handlerIcon} />
          </div>
          <Drawer
            closable={true}
            closeIcon={<i className="fa-solid  fa-xmark" />}
            open={isMobileMenuOpen}
            placement="left"
            className={style.mobileMenu}
            onClose={toggleMobileMenu}
            maskClosable
            getContainer={null}
            width={leftMenuWidth}
          >
            <motion.div
              initial={{ opacity: 0 }} // Start with the element fully transparent
              animate={{ opacity: 1 }} // Fade in to full opacity
              transition={{ duration: 1 }} // Adjust the duration of the fade-in
              className=""
            >
              <MenuLeft />
            </motion.div>
          </Drawer>
        </div>
      )
    }
    return <MenuLeft />
  }

  return GetMenu()
}

export default connect(mapStateToProps)(Menu)
