import store from 'store'
import { useEffect } from 'react'

const ReloadOnTabRevisit: React.FC = () => {
  useEffect(() => {
    const MAX_TIME_GAP = 24 * 60 * 60 * 1000 // 24 hours
    const lastHiddenKey = 'lastHiddenTimestamp'

    const handleVisibilityChange = () => {
      if (document?.visibilityState === 'hidden') {
        store.set(lastHiddenKey, new Date().getTime().toString())
      } else if (document?.visibilityState === 'visible') {
        const lastHiddenTimestamp = store.get(lastHiddenKey)
        const now = new Date().getTime()

        if (lastHiddenTimestamp && now - parseInt(lastHiddenTimestamp, 10) > MAX_TIME_GAP) {
          window.location.reload()
        }
      }
    }

    document?.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document?.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return null
}

export default ReloadOnTabRevisit
