import { Layout, Tag } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import Menu from 'components/cleanui/layout/Menu'

import { getAPI } from 'services/jwt'

const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
  footer: settings.footer,
  company_id: settings.company_id,
})

const MainLayout = ({
  children,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
  footer,
  company_id,
}) => {
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    async function getNotifications() {
      var req = {
        company_id: company_id,
      }

      try {
        var data = await getAPI('store', 'notifications', req)
        setNotifications(data.notifications)
      } catch (e) {
        console.log(e)
      }
    }
    getNotifications()
  }, [company_id])

  return (
    <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
      <Layout
        className={classNames({
          cui__layout__contentMaxWidth: isContentMaxWidth,
          cui__layout__appMaxWidth: isAppMaxWidth,
          cui__layout__grayBackground: isGrayBackground,
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
        })}
      >
        {/*<Layout.Header style={{background: '#555'}}>
        <div style={{float: 'left', margin: '16px 0 16px 24px'}} />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          {new Array(15).fill(null).map((_, index) => {
            const key = index + 1;
            return <Menu.Item key={key}>{`nav ${key}`}</Menu.Item>;
          })}
        </Menu>
      </Layout.Header>*/}
        {/*<Layout.Header
            className={classNames('cui__layout__header', {
              cui__layout__fixedHeader: isTopbarFixed,
              cui__layout__headerGray: isGrayTopbar,
            })}
          >
            <TopBar />
          </Layout.Header>*/}
        <Layout>
          {notifications.length > 0 && (
            <Tag
              color="#fdfd96"
              className="px-5 m-0 text-center"
              style={{ borderLeft: 'none', borderRight: 'none', borderRadius: 'none' }}
            ></Tag>
          )}

          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div className="cui__utils__content">{children}</div>
          </Layout.Content>

          {/*<Layout.Footer>{footer == 1 && <Footer />}</Layout.Footer>*/}
        </Layout>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
